import React, { useEffect, useState, useRef, memo } from "react";
import { WebService } from "../../Services/WebService";
import { format } from "date-fns";
import "../Company/Company.css";
import AddEditError from "./AddEditCompany";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Company/Company.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { useDispatch, useSelector } from "react-redux";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";

const CompanyList = () => {
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Company Profile" });
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();
  const { state } = useLocation();

  const fetchcompanyList = async () => {
    const data = await WebService({
      endPoint: `CommonUtility/fetchAll/companyinformation?CompanyId=${StandardConst.YesNo.Yes}`,
      dispatch,
    });
    setRecords(data);
  };
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  const [permission, setPermission] = useState({});
  useEffect(() => {
    setPermission({
      ManageAdd: ActionPermission("Company Info - Add"),
    // ManageDelete: ActionPermission("Roles - Delete"),
    });
  }, [permissionList]);

  // const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  useEffect(() => {
    if ((CompanyInfo?.CompanyId ?? 0) !== 0) {
      Promise.all([fnEdit(CompanyInfo.CompanyId)]);
    }
  }, [CompanyInfo?.CompanyId]);
  useEffect(() => {
    // if (!renderAfterCalled.current) {
    fetchcompanyList();
    // }
    // renderAfterCalled.current = true;
  }, []);

  const columns = [
    {
      Text: "Company Name",
      Value: "CompanyName",
    },
    {
      Text: "Company Type",
      Value: "Type",
    },

    {
      Text: "Email",
      Value: "Email",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },
    {
      Text: "Action",
      key: "CompanyId",
      cssClass: "text-center td-width-100",
      // isVisiable: permission.ManageEdit,
      Template: (
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(e.currentTarget.parentElement.parentElement.getAttribute("data-key"))
            }
            //disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnErrorEdit"
          />
        </>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Company Profile",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = " Company Profile";
  const confirmMessage = MasterPageName + " Deleted successfully";

  return (
    <>
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""} // give sorce page path in this parameter when you use this component the back button will back you to the source page
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderValueStandard}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {MasterPageName}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditError
        callBackEvent={() => fetchcompanyList()}
        ref={addEditModalRef}
      ></AddEditError>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
    </>
  );
};

export default memo(CompanyList);

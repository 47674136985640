import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";
import AddEditAssetType from './AddEditAssetType';
import { Chip } from '@mui/material';

const ManageAssetTypes = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    PageInfo({ pageTitle: "Manage Asset Types" });
    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Asset Type - Add"),
            ManageEdit: ActionPermission("Asset Type - Edit"),
            ManageDelete: ActionPermission("Asset Type - Delete"),
        });
    }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Manage Asset Types";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "Asset Type";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);
    const onDelete = async (Details) => {
        const data = Details.split(',');
        const AssetTypeId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/assettype?AssetTypeId=${AssetTypeId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAssetTypes();
    };

    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    const fetchAssetTypes = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/assettype?CompanyId=${companyInfo.CompanyId}&isReserved=${StandardConst.YesNo.Yes}`,
            dispatch,
        });
        const modifiedArray = data.map((res) => {
            const foundStatus = StandardConst.StatusDropdown.find((r) => r.value === res.Status);
            res.Status = foundStatus?.text;
            return res;
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchAssetTypes();
    }, []);

    const [bData,] = React.useState([
        {
            title: "Assets Management",
            hrefLink: "#",
        },
        {
            title: "Manage Asset Types",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Asset Type Name",
            render: (dr) => {
                return <>
                <span>{dr.AssetTypeName}</span>  &nbsp;&nbsp; {(dr.isReserved === StandardConst.YesNo.Yes) && (
                    <Chip 
                        label="Reserved"
                        sx={{ color: "white", padding: "0px"}}
                        color= "primary"
                        size="small"
                    />
                )}
                </>
            }
        },
        {
            Text: "Description",
            Value: "Description"
        },
        {
            Text: "Icon",
            Value: "Icon"
        },
        {
            Text: "Status",
            Value: "Status",
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <div className="d-flex">
                    {(dr.isReserved === 0 || (dr.isReserved === 1 && Roles[0] === StandardConst.SystemRole.SuperAdmin)) && (
                        <>
                            <ActionButton
                                onClick={() => fnEdit(dr.AssetTypeId)}
                                disabled={!permission.ManageEdit}
                                IconName="Edit"
                                IconTooltip={"Edit Asset Type"}
                                id={`btnAssetTypeEdit_${dr.AssetTypeId}`}
                            />
                            <ActionButton
                                onClick={(e) =>
                                    ref.current.confirmAlert(
                                        "Delete", //Confirm button text
                                        "Are You Sure", // Text if Alert
                                        "Do you want to delete " + dr.AssetTypeName, // Message of Alert
                                        `${dr.AssetTypeId},${dr.AssetTypeName}`  // Endpoint to hit for delete
                                    )}
                                disabled={!permission.ManageDelete}
                                IconName="Delete"
                                IconTooltip={"Delete Asset Type"}
                                id={`btnAssetTypeDelete_${dr.AssetTypeId}`}
                            />
                        </>
                    )}
                </div>
            ),
        },
    ];

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Asset type ${(deletedName!== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.AssetTypeheaderValueNoResults}
                        subHeaderValue={StandardConst.AssetTypesSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditAssetType
                callBackEvent={async () => await fetchAssetTypes()}
                ref={addEditModalRef}
            ></AddEditAssetType>
        </>
    )
}

export default ManageAssetTypes
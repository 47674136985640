import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { 
  Form,
  FormAutoCompleteDropdown,
  FormInputDropdown,
  FormInputText
} from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Alert, IconButton } from "@mui/material";
import ActionButton from "../../Services/ActionButton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const { forwardRef, useState, useImperativeHandle } = React;
const AddEditFormulaGenerator = (prop, ref) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setInvoiceSettingId(0);
        setFormulaGeneratorId(0);
        setDetailsFieldData([]);
        setConditionData([{}]);
        setFormulaData([{}]);
        setFormulaGeneratorData({});
        setShowHideConditionSection(false);
        setShowFormulaExample("");
        setGeneratCondition("");
        setShow(false);
    };
    
    const [showFormulaExample, setShowFormulaExample] = useState();
    const [invoiceSettingId, setInvoiceSettingId] = useState(0);
    const [formulaGeneratorId, setFormulaGeneratorId] = useState(0);
    const [detailsFieldData, setDetailsFieldData] = useState([]);
    const [generatFormula, setGeneratFormula] = useState([]);
    const [generatCondition, setGeneratCondition] = useState("");

    const [conditionData, setConditionData] = useState([{
        RightSideField:'',
    }]);
    const [formulaData, setFormulaData] = useState([{}]);
    const [formulaTypeOnlyForExample, setformulaTypeOnlyForExample] = useState();
    const [formulaGeneratorData, setFormulaGeneratorData] = useState({});
    const [showHideConditionSection, setShowHideConditionSection] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal: async (DetailsFieldsData, InvoiceSettingId, FormulaGeneratorId) => {

            if(FormulaGeneratorId != ""){
                fetchInvoiceFormulaGenerator(FormulaGeneratorId);
                setFormulaGeneratorId(FormulaGeneratorId);
                fetchInvoiceConditions(FormulaGeneratorId, InvoiceSettingId);
                fetchInvoiceFormulas(FormulaGeneratorId, InvoiceSettingId);
            }
            
            setFormulaGeneratorData({InvoiceSettingId: InvoiceSettingId});
            setInvoiceSettingId(InvoiceSettingId);
            setDetailsFieldData(DetailsFieldsData);
            setShow(true);
        },
    }));

    const fetchInvoiceFormulaGenerator = async (FormulaGeneratorId) => {
        await WebService({
            dispatch,
            endPoint: `CommonUtility/Edit/formulagenerator?FormulaGeneratorId=${FormulaGeneratorId}`,
            method: "GET",
        }).then((recGenerator) => {
            setFormulaGeneratorData(recGenerator[0]);
        });
    };

    const fetchInvoiceFormulas = async (FormulaGeneratorId, InvoiceSettingId) => {

        await WebService({
            dispatch,
            endPoint: `Invoice/GetFormulaData/${InvoiceSettingId}`,
            method: "GET",
        }).then((recAllFormulas) => {
            const resFormula = recAllFormulas.filter(item => item.FormulaGeneratorId === FormulaGeneratorId);
            
            const updatedFormulaData = resFormula.map(item => {
              return {
                ...item,
                DestinationField: [{ text: item.DestinationField == null ? "" : item.DestinationField }],
                EquationLeftSideField: [{ text: item.EquationLeftSideField == null ? "" : item.EquationLeftSideField }],
                EquationRightSideField: [{ text: item.EquationRightSideField == null ? "" : item.EquationRightSideField }],
              };
            });
            
            setFormulaData(updatedFormulaData);
        });
    }
  
    const fetchInvoiceConditions = async (FormulaGeneratorId, InvoiceSettingId) => {

        await WebService({
            dispatch,
            endPoint: `CommonUtility/Edit/formulaconditions?FormulaGeneratorId=${FormulaGeneratorId}`,
            method: "GET",
          }).then((FormulaCondition) => {
            if(FormulaCondition.length > 0) {
              for (const oneFormulacondition of FormulaCondition) {
                  oneFormulacondition.RightSideField = [{text: oneFormulacondition.RightSideField == null ? "" : oneFormulacondition.RightSideField}];
              }
              setConditionData(FormulaCondition);
              setShowHideConditionSection(true);
            }
          });
    }
  
    const handleAddRow = () => {
        setConditionData([...conditionData, {
            RightSideField:'',
        }]);
    };
  
    const handleInputChange = (index, value, name) => {
      const newconditionData = [...conditionData];
      newconditionData[index][name] = value;
      setConditionData(newconditionData);
    };
  
    const handleRemoveRow = async (indexToRemove, ConditionId) => {
      var removeFromUI = true;
      if(ConditionId != "") {
        await WebService({
          dispatch,
          endPoint: `CommonUtility/formulaconditions?ConditionId=${ConditionId}`,
          method: "DELETE",
        }).then((rec) => {
        }).catch(err => {
          removeFromUI = false;
        });
      }
      
      if (removeFromUI) {
        const updatedData = [...conditionData];
        updatedData.splice(indexToRemove, 1);
        setConditionData(updatedData);
      }
    };

    const GetFieldType = (findFieldName) => {
        if(findFieldName == ''){
            return '';
        }
        // Use the some method to check if findFieldName matches any field
        const isMatch = detailsFieldData.some(field => field.value === findFieldName);

        if(isMatch){
            return StandardConst.FormulaBoxFilledFieldType.DbField;
        }else{
            return StandardConst.FormulaBoxFilledFieldType.CalculatedField;
        }
    };
      
  
    const saveFormulaAndCondition = async (data) => {
      try {
        var inertedFormulaGeneratorId;
        if(formulaGeneratorId == 0){
          inertedFormulaGeneratorId = await WebService({
            dispatch,
            endPoint: "CommonUtility/formulagenerator",
            body: formulaGeneratorData,
            method: "POST",
          });
          setFormulaGeneratorId(inertedFormulaGeneratorId);
        }else{
            await WebService({
                dispatch,
                endPoint: `CommonUtility/formulagenerator?FormulaGeneratorId=${formulaGeneratorId}`,
                body: formulaGeneratorData,
                method: "PUT",
            });
        }
        
        // here we save the Condition 
        for (const oneConditionData of conditionData) {
            const allConditionFieldsBlank = Object.values(oneConditionData).every(field => field === '');
            if(!allConditionFieldsBlank){
                oneConditionData['FormulaGeneratorId'] = formulaGeneratorId == 0 ? inertedFormulaGeneratorId : formulaGeneratorId;
                oneConditionData['RightSideField'] = oneConditionData.RightSideField[0] == undefined ? "" : oneConditionData.RightSideField[0];
                if (typeof oneConditionData['RightSideField'] === 'object' && oneConditionData['RightSideField'] !== null) {
                    oneConditionData['RightSideField'] = oneConditionData['RightSideField']['text'];
                }
        
                if (oneConditionData?.ConditionId){
                    const updatedFormulaConditionsResponse = await WebService({
                    dispatch,
                    endPoint: `CommonUtility/formulaconditions?ConditionId=${oneConditionData?.ConditionId}`,
                    body: oneConditionData,
                    method: "PUT",
                    });
                
                }else{
        
                    const insertFormulaConditionsResponse = await WebService({
                    dispatch,
                    endPoint: "CommonUtility/formulaconditions",
                    body: oneConditionData,
                    method: "POST",
                    });
                    oneConditionData["ConditionId"] = insertFormulaConditionsResponse;
        
                }
                oneConditionData['RightSideField'] = [{text : oneConditionData['RightSideField']}];
            }
        };

        var formulaCount = 1;
        // here we save the Formula
        
        for (const oneFormulaData of formulaData) {

            const allFormulaFieldsBlank = Object.values(oneFormulaData).every(field => field === '');

            if(!allFormulaFieldsBlank) {
                oneFormulaData['FormulaDetailOrder'] = formulaCount;
                oneFormulaData['FormulaGeneratorId'] = formulaGeneratorId == 0 ? inertedFormulaGeneratorId : formulaGeneratorId;
                oneFormulaData['DestinationField'] = oneFormulaData.DestinationField[0] == undefined ? "" : oneFormulaData.DestinationField[0];
                if (typeof oneFormulaData['DestinationField'] === 'object' && oneFormulaData['DestinationField'] !== null) {
                    oneFormulaData['DestinationField'] = oneFormulaData['DestinationField']['text'];
                    oneFormulaData['DestinationFieldHtmlTag'] = `{{${oneFormulaData['DestinationField']}}}`;
                    oneFormulaData['FieldType'] = GetFieldType(oneFormulaData['DestinationField']);
                }else{
                    oneFormulaData['DestinationFieldHtmlTag'] = `{{${oneFormulaData['DestinationField']}}}`;
                    oneFormulaData['FieldType'] = GetFieldType(oneFormulaData['DestinationField']);
                }
                oneFormulaData['EquationLeftSideField'] = oneFormulaData.EquationLeftSideField[0] == undefined ? "" : oneFormulaData.EquationLeftSideField[0];
                if (typeof oneFormulaData['EquationLeftSideField'] === 'object' && oneFormulaData['EquationLeftSideField'] !== null) {
                    oneFormulaData['EquationLeftSideField'] = oneFormulaData['EquationLeftSideField']['text'];
                    oneFormulaData['LeftSideFieldType'] = GetFieldType(oneFormulaData['EquationLeftSideField']);
                }else{
                    oneFormulaData['LeftSideFieldType'] = GetFieldType(oneFormulaData['EquationLeftSideField']);
                }
                if('EquationRightSideField' in oneFormulaData && oneFormulaData['EquationRightSideField'] !== null){
                    oneFormulaData['EquationRightSideField'] = oneFormulaData.EquationRightSideField[0] == undefined ? "" : oneFormulaData.EquationRightSideField[0];
                    if (typeof oneFormulaData['EquationRightSideField'] === 'object' && oneFormulaData['EquationRightSideField'] !== null) {
                        oneFormulaData['EquationRightSideField'] = oneFormulaData['EquationRightSideField']['text'];
                        oneFormulaData['RightSideFieldType'] = GetFieldType(oneFormulaData['EquationRightSideField']);
                    }else{
                        oneFormulaData['RightSideFieldType'] = GetFieldType(oneFormulaData['EquationRightSideField']);
                    }
                }

                if (oneFormulaData?.FormulaDetailId){
                    const updatedFormulas = await WebService({
                        dispatch,
                        endPoint: `CommonUtility/formulas?FormulaId=${oneFormulaData?.FormulaId}`,
                        body: oneFormulaData,
                        method: "PUT",
                        });

                    const updateFormulaDetailsResponse = await WebService({
                        dispatch,
                        endPoint: `CommonUtility/formuladetails?FormulaDetailId=${oneFormulaData?.FormulaDetailId}`,
                        body: oneFormulaData,
                        method: "PUT",
                    });
                }else{
                    const insertFormulaResponse = await WebService({
                        dispatch,
                        endPoint: "CommonUtility/formulas",
                        body: oneFormulaData,
                        method: "POST",
                    });
                    oneFormulaData["FormulaId"] = insertFormulaResponse;
    
                    if (insertFormulaResponse) {
                        const insertFormulaDetailsResponse = await WebService({
                            dispatch,
                            endPoint: "CommonUtility/formuladetails",
                            body: oneFormulaData,
                            method: "POST",
                        });
                    }
                }
                
                formulaCount++;
            }

        }
  
        handleClose();
        prop.callBackEvent();
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    };

    const handleFormulaTypeChanges = async (selectedFormulaType, index) => {
        setformulaTypeOnlyForExample(selectedFormulaType);
        var updateFormulaData = [...formulaData];
        updateFormulaData[index].FormulaType = selectedFormulaType;
        if (selectedFormulaType === StandardConst.FormulasType.EquationFormula){
            updateFormulaData[index].OperatorOrFunction = StandardConst.EquationFormulaList[0].value;
        }else if (selectedFormulaType === StandardConst.FormulasType.AggregateFunction){
            updateFormulaData[index].OperatorOrFunction = StandardConst.AggregateFunctionList[0].value;
            updateFormulaData[index].EquationRightSideField = null;
            updateFormulaData[index].RightFieldDataType = null;
        }else {
            updateFormulaData[index].OperatorOrFunction = null;
            updateFormulaData[index].EquationRightSideField = null;
            updateFormulaData[index].RightFieldDataType = null;
        }
        setFormulaData(updateFormulaData);
    };

    const renderOperatorOrFunctionDropdown = (formulaType, formulaRowIndex, formulaDataRow, handleFormulaInputChange) => {
        switch (formulaType) {
          case 'EquationFormula':
            return (
                <FormInputDropdown
                    name={`OperatorOrFunction-${formulaRowIndex}`}
                    ddOpt={StandardConst.EquationFormulaList ?? []}
                    isRequired="true"
                    value={formulaDataRow?.OperatorOrFunction}
                    setValue={(val) => handleFormulaInputChange(formulaRowIndex, val, 'OperatorOrFunction')}
                />
            );
      
          case 'AggregateFunction':
            return (
                <FormInputDropdown
                    name={`OperatorOrFunction-${formulaRowIndex}`}
                    ddOpt={StandardConst.AggregateFunctionList ?? []}
                    isRequired="true"
                    value={formulaDataRow?.OperatorOrFunction}
                    setValue={(val) => handleFormulaInputChange(formulaRowIndex, val, 'OperatorOrFunction')}
                />
            );
      
          default:
            return (
                <FormInputDropdown
                    name={`OperatorOrFunction-${formulaRowIndex}`}
                    ddOpt={StandardConst.EquationFormulaList ?? []}
                    isRequired="true"
                    value={formulaDataRow?.OperatorOrFunction}
                    setValue={(val) => handleFormulaInputChange(formulaRowIndex, val, 'OperatorOrFunction')}
                />
            );
        }
    };
    
    const handleAddNewFormulaRow = async () => {
        setFormulaData([...formulaData, {
            FormulaType: StandardConst.FormulasType.EquationFormula,
            DestinationField:'',
            EquationLeftSideField:'',
            EquationRightSideField:''
        }]);
    };

    const handleFormulaRemoveRow = async (indexToRemove, FormulaId, FormulaDetailId) => {

        var removeFromUI = true;
        if(FormulaId != "") {
            await WebService({
                dispatch,
                endPoint: `CommonUtility/formulas?FormulaId=${FormulaId}`,
                method: "DELETE",
            }).then((rec) => {
            }).catch(err => {
                removeFromUI = false;
            });
        }
        
        if (removeFromUI) {
            const updatedData = [...formulaData];
            updatedData.splice(indexToRemove, 1);
            setFormulaData(updatedData);
        }
    };


    useEffect(() => {
        const generatFormulaArray = [];
        
        for (let index = 0; index < formulaData.length; index++) {
            var DestinationFiledValue = "";
            var EquationLeftSideFieldValue = "";
            var OperatorOrFunctionValue = "";
            var EquationRightSideFieldValue = "";
            
            if(formulaData[index]['DestinationField'] !== undefined && formulaData[index]['DestinationField'] !== ""){
                if (typeof formulaData[index]['DestinationField'][0] === 'object' && formulaData[index]['DestinationField'] !== null) {
                    DestinationFiledValue += formulaData[index]['DestinationField'][0]['text'];
                } else {
                    DestinationFiledValue += formulaData[index]['DestinationField'][0];
                }
            }
    
            if(formulaData[index]['EquationLeftSideField'] !== undefined && formulaData[index]['EquationLeftSideField'] !== ""){
                if (typeof formulaData[index]['EquationLeftSideField'][0] === 'object' && formulaData[index]['EquationLeftSideField'] !== null) {
                    EquationLeftSideFieldValue += formulaData[index]['EquationLeftSideField'][0]['text'];
                } else {
                    EquationLeftSideFieldValue += formulaData[index]['EquationLeftSideField'][0];
                }
            }
    
            if(formulaData[index]['OperatorOrFunction'] !== undefined && formulaData[index]['OperatorOrFunction'] !== "" && formulaData[index]['OperatorOrFunction'] !== null){
                OperatorOrFunctionValue += formulaData[index]['OperatorOrFunction'];
            }
    
            if(formulaData[index]['EquationRightSideField'] !== undefined && formulaData[index]['EquationRightSideField'] !== "" && formulaData[index]['EquationRightSideField'] !== null){
                if (typeof formulaData[index]['EquationRightSideField'][0] === 'object' && formulaData[index]['EquationRightSideField'] !== null) {
                    EquationRightSideFieldValue += formulaData[index]['EquationRightSideField'][0]['text'];
                } else {
                    EquationRightSideFieldValue += formulaData[index]['EquationRightSideField'][0];
                }
            }
           
            var generatFormula = "";
            if(DestinationFiledValue !== ""){
                generatFormula += DestinationFiledValue;
                if(EquationLeftSideFieldValue !== ""){
                    if(formulaData[index]['FormulaType'] === StandardConst.FormulasType.AggregateFunction){
                        if(OperatorOrFunctionValue !== ""){
                            generatFormula += " = " + StandardConst.AggregateFunctionList?.find(item => item.value === OperatorOrFunctionValue)?.text + " " + EquationLeftSideFieldValue;
                        }
                    }else{
                        generatFormula += " = " + EquationLeftSideFieldValue
                        if(OperatorOrFunctionValue !== "" && EquationRightSideFieldValue !== ""){
                            generatFormula += " " + OperatorOrFunctionValue + " " + EquationRightSideFieldValue;
                        }
                    }
                }
            }else{
                generatFormula = "";
            }
            generatFormulaArray.push(generatFormula);
        }

        setGeneratFormula(generatFormulaArray);
    }, [formulaData]);

    useEffect(() => {
        var generatConditionStr = "";
        for (let i = 0; i < conditionData.length; i++) {
            if(conditionData[i].LeftSideField !== undefined && conditionData[i].LeftSideField !== "") {
                generatConditionStr += "(" + conditionData[i].LeftSideField;
                if(conditionData[i].RightSideField !== undefined && conditionData[i].RightSideField !== "" && conditionData[i].Operator !== undefined && conditionData[i].Operator !== "") {
                    if(typeof conditionData[i].RightSideField[0] === "object" && conditionData[i].RightSideField !== null) {
                        generatConditionStr += " " + conditionData[i].Operator + " " + conditionData[i].RightSideField[0].text;
                    }else{
                        generatConditionStr += " " + conditionData[i].Operator + " " + conditionData[i].RightSideField[0];
                    }
                }
                generatConditionStr += ")";
                if(i !== (conditionData.length - 1)){
                    generatConditionStr += " " + conditionData[i].JoinOperator + " ";
                }
            }
        }

        setGeneratCondition(generatConditionStr);
    }, [conditionData])

    const handleFormulaInputChange = (index, value, name) => {
        const newFormulaData = [...formulaData];
        newFormulaData[index][name] = value;
        setFormulaData(newFormulaData);
    };

    const ShowFormulaDetails = (formulaType, formulaRowIndex, formulaDataRow, handleFormulaInputChange) => {
        switch (formulaType) {
            case StandardConst.FormulasType.EquationFormula:
                return (
                    <>
                        <td className="align-top">
                            {renderOperatorOrFunctionDropdown(formulaType, formulaRowIndex, formulaDataRow, handleFormulaInputChange)}
                        </td>
                        <td className="align-top">
                            <FormAutoCompleteDropdown
                            name={`EquationRightSideField-${formulaRowIndex}`}
                            data={detailsFieldData}
                            optionText="text"
                            setValue={(val) => (
                                handleFormulaInputChange(formulaRowIndex, val, 'EquationRightSideField')
                            )}
                            value={formulaDataRow?.EquationRightSideField}
                            freeText={true}
                            autoSelectAttributeValue={true}
                            />
                            
                            <FormInputDropdown
                                name={`RightFieldDataType-${formulaRowIndex}`}
                                ddOpt={StandardConst.invoiceFieldType ?? []}
                                isRequired="true"
                                value={formulaDataRow?.RightFieldDataType}
                                setValue={(val) => (
                                    handleFormulaInputChange(formulaRowIndex, val, 'RightFieldDataType')
                                )}
                            ></FormInputDropdown>
                        </td>
                    </>
                )
            break;

            case StandardConst.FormulasType.AggregateFunction:
                return (
                    <>
                        <td className="align-top">
                            {renderOperatorOrFunctionDropdown(formulaType, formulaRowIndex, formulaDataRow, handleFormulaInputChange)}
                        </td>
                        <td></td>
                    </>
                )
                break;
            default:
                return (
                    <>
                        <td></td>
                        <td></td>
                    </>
                )
        }
    };

    const requiredMessage = "This is a required field";
    const schema = yup.object().shape({
        FormulaName: (formulaGeneratorData.FormulaName !== "" && formulaGeneratorData.FormulaName !== undefined) ? null : yup.string().trim().required(requiredMessage),
    });

    return (
    <Modal show={show} onHide={handleClose} size="lg">
        <Form 
        defaultValues={formulaGeneratorData}
        onSubmit={saveFormulaAndCondition}
        validationSchema={schema}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                {(formulaGeneratorData.FormulaGeneratorId ?  "Edit Your Condition or Formula" : "Add Your Condition or Formula")}
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body className="p-4">
                <div className="row mb-3">
                    <div className="col-md-8">
                        <FormInputText 
                            name="FormulaName"
                            label="Give a name to your formula"
                            placeholder="Formula Name"
                            setValue={(val) => setFormulaGeneratorData(prevData => 
                                {return { ...prevData, FormulaName: val }}

                            )}
                            value={formulaGeneratorData.FormulaName}
                            isRequired="true"
                        />
                    </div>
                </div>

                {/* this is for Formula section  -- START */}
                <div className="row">
                    {" "}
                    <Alert
                        severity="info"
                        sx={{ mb: 2 }}
                    >
                        Formula Section
                    </Alert>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        Following is Example of Formula type :-<br></br>
                        {(formulaTypeOnlyForExample === StandardConst.FormulasType.EquationFormula) && (
                            <>
                                <span>1. If Billing mode not equal to FIXED, Amount = Rate * Hours of working (With condition)</span><br></br>
                                <span>2. Net Amount = Gross Amount - Tax</span><br></br>
                                <span>3. Discount = 10% of Total Amount (Discount = Total Amount * 0.1)</span><br></br>
                            </>
                        )}

                        {(formulaTypeOnlyForExample === StandardConst.FormulasType.AggregateFunction) && (
                            <>
                                <span>1. Total Amount = Sum of (all Amounts)</span><br></br>
                            </>
                        )}

                        {(formulaTypeOnlyForExample === StandardConst.FormulasType.AssignmentFunction) && (
                            <>
                                <span>1. If Billing mode equal to FIXED, Total = Rate (With condition)</span><br></br>
                            </>
                        )}
                    </div>
                    <div className="col-md-12">
                    <table className="w-100">
                        <thead>
                            <tr>
                                <th>Formula Type</th>
                                <th>Destination</th>
                                <th>Left Side Field</th>
                                <th>Operator Or Function</th>
                                <th>Right Side Field</th>
                                
                                <th>
                                    <ActionButton
                                    onClick={handleAddNewFormulaRow}
                                    IconName="Add"
                                    IconTooltip={"Add New Item"}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {formulaData.map((formulaDataRow, formulaRowIndex) => (
                            
                                <tr key={formulaDataRow}>
                                    <td className="align-top">
                                        <FormInputDropdown
                                            name={`FormulaType-${formulaRowIndex}`}
                                            ddOpt={StandardConst.TypeOfFormulasList ?? []}
                                            isRequired="true"
                                            setValue={(val) => (
                                                handleFormulaTypeChanges(val, formulaRowIndex)
                                                )}
                                            value={formulaDataRow?.FormulaType}
                                        ></FormInputDropdown>
                                    </td>
                                    <td className="align-top">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-md-10">
                                                <FormAutoCompleteDropdown
                                                    name={`DestinationField-${formulaRowIndex}`}
                                                    data={detailsFieldData}
                                                    optionText="text"
                                                    setValue={(val) => (
                                                        handleFormulaInputChange(formulaRowIndex, val, 'DestinationField')
                                                    )}
                                                    value={formulaDataRow?.DestinationField}
                                                />

                                                <FormInputDropdown
                                                    name={`DataType-${formulaRowIndex}`}
                                                    ddOpt={StandardConst.invoiceFieldType ?? []}
                                                    isRequired="true"
                                                    value={formulaDataRow?.DataType}
                                                    setValue={(val) => (
                                                        handleFormulaInputChange(formulaRowIndex, val, 'DataType')
                                                    )}
                                                ></FormInputDropdown>
                                            </div>
                                            <div className="col-md-2"><label>=</label></div>
                                        </div>
                                    </td>
                                    <td className="align-top">
                                        <FormAutoCompleteDropdown
                                            name={`EquationLeftSideField-${formulaRowIndex}`}
                                            data={detailsFieldData}
                                            optionText="text"
                                            setValue={(val) => (
                                                handleFormulaInputChange(formulaRowIndex, val, 'EquationLeftSideField')
                                            )}
                                            value={formulaDataRow?.EquationLeftSideField}
                                            freeText={true}
                                            autoSelectAttributeValue={true}
                                        />
                                    
                                        <FormInputDropdown
                                            name={`LeftFieldDataType-${formulaRowIndex}`}
                                            ddOpt={StandardConst.invoiceFieldType ?? []}
                                            isRequired="true"
                                            value={formulaDataRow?.LeftFieldDataType}
                                            setValue={(val) => (
                                                handleFormulaInputChange(formulaRowIndex, val, 'LeftFieldDataType')
                                            )}
                                        ></FormInputDropdown>
                                    </td>

                                    {ShowFormulaDetails(formulaDataRow.FormulaType, formulaRowIndex, formulaDataRow, handleFormulaInputChange)}
                                    
                                    <td className="align-top">
                                        <ActionButton
                                        onClick={() => handleFormulaRemoveRow(formulaRowIndex, formulaDataRow?.FormulaId ?? "", formulaDataRow?.FormulaDetailId ?? "")}
                                        IconName="Delete"
                                        IconTooltip={"delete New Item"}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    {generatFormula !== undefined && (
                        <div className="col-md-12">
                            <ul className="p-0 pt-1">
                                {generatFormula.map((generatFormulaRow, index) => (
                                <li key={index}>{generatFormulaRow}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                
                {/* this is for Formula section  -- END */}

                <div className="row">
                    {" "}
                    <Alert
                        severity="info"
                        sx={{ mb: 2 }}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowHideConditionSection(prevState => !prevState);
                                }}
                            >
                                <ArrowDropDownIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        Condition (Apply this condition before running the formula)
                    </Alert>
                </div>
                {(showHideConditionSection) && (
                    <>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                        <th>Left Side Field</th>
                                        <th>Operator</th>
                                        <th>Right Side Field</th>
                                        <th>Join Operator</th>
                                        <th>
                                            {" "}
                                            <ActionButton
                                            onClick={handleAddRow}
                                            IconName="Add"
                                            IconTooltip={"Add New Item"}
                                            />
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {conditionData.map((rowData, rowIndex) => (
                                        
                                            <tr key={rowIndex}>
                                                <td>
                                                    <FormInputDropdown
                                                    name={`LeftSideField-${rowIndex}`}
                                                    ddOpt={detailsFieldData ?? []}
                                                    isRequired="true"
                                                    value={rowData?.LeftSideField}
                                                    setValue={(val) => (
                                                        handleInputChange(rowIndex, val, 'LeftSideField')
                                                    )}
                                                    ></FormInputDropdown>
                                                </td>

                                                <td>
                                                <FormInputDropdown
                                                    name={`Operator-${rowIndex}`}
                                                    ddOpt={StandardConst.ComparisonOperators ?? []}
                                                    isRequired="true"
                                                    value={rowData?.Operator}
                                                    setValue={(val) => (
                                                        handleInputChange(rowIndex, val, 'Operator')
                                                    )}
                                                    ></FormInputDropdown>
                                                </td>

                                                <td>
                                                    <FormAutoCompleteDropdown
                                                    name={`RightSideField-${rowIndex}`}
                                                    data={detailsFieldData}
                                                    optionText="text"
                                                    setValue={(val) => (
                                                        handleInputChange(rowIndex, val, 'RightSideField')
                                                    )}
                                                    value={rowData?.RightSideField}
                                                    freeText={true}
                                                    autoSelectAttributeValue={true}
                                                    />
                                                </td>

                                                <td>
                                                    <FormInputDropdown
                                                    name={`JoinOperator-${rowIndex}`}
                                                    ddOpt={StandardConst.LogicalOperators ?? []}
                                                    isRequired="true"
                                                    value={rowData?.JoinOperator}
                                                    setValue={(val) => (
                                                        handleInputChange(rowIndex, val, 'JoinOperator')
                                                    )}
                                                    ></FormInputDropdown>
                                                </td>
                                                <td>
                                                    <ActionButton
                                                    onClick={() => handleRemoveRow(rowIndex, rowData?.ConditionId ?? "")}
                                                    IconName="Delete"
                                                    IconTooltip={"delete New Item"}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {generatCondition !== "" && (
                            <div className="col-md-12">
                                Run formula if the following condition is TRUE <br/>
                                {generatCondition}
                            </div>
                        )}
                    </>
                )}
                

                <div className="row">
                    <div className="col-md-12">
                        <Button
                        variant="outline-primary"
                        type="submit"
                        className="d-flex float-end"
                        // onClick={() => saveFormulaAndCondition()}
                        >
                        Submit
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Form>
    </Modal>
    );
};

export default forwardRef(AddEditFormulaGenerator);

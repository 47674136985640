import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { WSSuccessAlert } from "../../Services/WSAlert";

const apiUrl = "CommonUtility/static_department";
const { forwardRef, useState, useImperativeHandle } = React;

const AddEditDepartment = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ Department: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id === 0){
        setData({ Department: {} })
      }else {
        setShow(false);
        const data = {
          Department : await WebService({
            endPoint: `CommonUtility/Edit/static_department?DepartmentId=${id || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}))
        }
        setData(data);
        setShow(true);
      }
    },
  }));
  const handleClose = () => setShow(false);
  const schema = yup
    .object()
    .shape({
      DepartmentName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    })
    .required();
  const onSubmit = async (data) => {
    if (data.DepartmentId === undefined) {
      await WebService({
        endPoint: apiUrl,
        body: data,
        dispatch,
      }).then(res => {
        WSSuccessAlert("Success", `${data.DepartmentName} added successfully`)
      });
    } else {
      await WebService({
        endPoint: `${apiUrl}?DepartmentId=${data.DepartmentId}`,
        method: "PUT",
        body: data,
        dispatch,
      }).then(res => {
        WSSuccessAlert("Success", `${data.DepartmentName} updated successfully`)
      });
    }
    handleClose();
    prop.callBackEvent();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data?.Department?.DepartmentId || 0) === 0
            ? "Add Department"
            : "Edit Department"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.Department}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Department Name"
                name="DepartmentName"
                type="text"
                isRequired="true"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnDepartmentSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data?.Department?.DepartmentId || 0) === 0
              ? "Submit"
              : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditDepartment);

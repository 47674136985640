import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputFile, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import { Autocomplete, TextField } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import { DateTime } from "luxon";
import _ from 'underscore';
import { WSSuccessAlert, WSWarningAlert } from "../../Services/WSAlert";
import { format } from 'date-fns';

const AddEditTicket = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ Tickets: {} });
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});
    const [selectedAttachPerson, setSelectedAttachPerson] = useState({text: "", value: null});
    const [uploadPreviewImage, setUploadPreviewImage] = useState("");

    useEffect(() => {
        Promise.all([
            fetchProjects(),
            fetchAgents(),
            fetchAllTicketCategories()
        ])
    }, []);
    
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                const details = {
                    Tickets: {
                        CreationDate: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'),
                    },
                };
                setData(details);
                setSelectedAttachPerson({text: "", value: null});
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            Tickets: await WebService({
                endPoint: `CommonUtility/Edit/tickets?TicketId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        data.Tickets.CreationDate = DateTime.fromISO(data.Tickets.CreationDate).toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
        if(data.Tickets.Status === StandardConst.TicketStatusDropdown[2].value){
            setShowRemarksInput(true);
        }else {
            setShowRemarksInput(false);
        }
        const AttachPerson = await WebService({
            endPoint: `CommonUtility/Edit/ticketcategories?TicketCategoryId=${data.Tickets.TicketCategoryId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        setSelectedAttachPerson({ text: AttachPerson.CategoryName, value: AttachPerson.TicketCategoryId });
        
        setData(data);
    }

    const handleClose = () => {
        setShow(false)
        setData({Tickets : {}});
        setSelectedAttachPerson({});
        setAutoErrorShow(false);
        setShowRemarksInput(false);
        setUploadPreviewImage("");
    };

    const schema = yup
        .object()
        .shape({
            Title: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Status: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Priority: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;

        if(EmployeeId){
            data.CreateByUserIdOrEmployeeId = EmployeeId;
            data.CreateByUserType = StandardConst.UserType.Employee;
        }

        if(UserId){
            data.CreateByUserIdOrEmployeeId = UserId;
            data.CreateByUserType = StandardConst.UserType.User;
        }
        if (selectedAttachPerson.value !== null) {
            setAutoErrorShow(false);
            if (data.File.name) {
                data.Attachment = await WebService({
                    endPoint: "upload/File",
                    dispatch,
                    body: data.File,
                    isFile: true,
                }).then((res) => res.filename);
            }
            data.TicketCategoryId = selectedAttachPerson.value;
            if (data.TicketId === undefined) {
                
                const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/;

                if (timestampRegex.test(data.CreationDate)) {
                    const dateTime = DateTime.fromISO(data.CreationDate, { zone: 'utc+5:30' });
                    const formattedDateTime = dateTime.toFormat('yyyy-MM-dd HH:mm:ss');
                    data.CreationDate = formattedDateTime;
                }

                await WebService({
                    endPoint: "CommonUtility/tickets",
                    body: data,
                    dispatch,
                }).then(res => {
                    WSSuccessAlert("Success", `Ticket ${data.Title} added successfully`);
                });
            } else {
                const timestamp = DateTime.now().toISO({ includeMillis: true });
                data.UpdatedTimestamp = timestamp;
                await WebService({
                    endPoint: `CommonUtility/tickets?TicketId=${data.TicketId}`,
                    method: "PUT",
                    body: data,
                    dispatch,
                }).then(res => {
                    WSSuccessAlert("Success", `Ticket ${data.Title} updated successfully`);
                });
            }
            handleClose();
            props.callBackEvent();
        } else {
            setAutoErrorShow(true);
        }
    }

    const [projectList, setProjectList] = useState([]);

    const fetchProjects = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/projectmaster?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
            requiredLoader: false
        });

        const list = [{text: "", value: null}].concat(data.map((obj) => {
            return { text: obj.ProjectName, value: obj.ProjectId}
        }));
        setProjectList(list);
    }

    const [agentList, setAgentList] = useState([]);

    const fetchAgents = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/agents?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
            requiredLoader: false
        });

        const list = [{text: "", value: null}].concat(data.map((obj) => {
            return { text: obj.FullName, value: obj.AgentId}
        }));
        setAgentList(list);
    }

    const [autoErrorShow, setAutoErrorShow] = useState(false);
    const [ticketCategoriesList, setTicketCategoriesList] = useState([]);

    const fetchAllTicketCategories = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/ticketcategories?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
            requiredLoader : false
        });
        const list = data?.map((item) => {
            return { text: item.CategoryName, value: item.TicketCategoryId, ParentId: item.ParentId }
        });
        setTicketCategoriesList(list);
    }

    // Function to handle change in selected ticket categories
    const handleCategoryChange = (event, selectedOptions, reason, details) => {
        if(reason === StandardConst.MUIAutoCompleteRasonClearOption){
            setSelectedAttachPerson({value: null});
            setAutoErrorShow(true);
        }else {
            (selectedOptions.value === null) ? setAutoErrorShow(true) : setAutoErrorShow(false);
            setSelectedAttachPerson(selectedOptions);
        }
    };

    const [showRemarksInput, setShowRemarksInput] = useState(false)

    const handleChangeStatus = (value) => {
        if(value === 3){
            setShowRemarksInput(true);
        }else {
            setShowRemarksInput(false);
        }
    }


    const handleChangeFile = (file) => {
            // Preview the image
            if (file) {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                    setUploadPreviewImage(e.target.result);
                    setData({
                        Tickets: {
                            Attachment: null
                        }
                    })
                };
                reader.readAsDataURL(file);
            } else {
                setUploadPreviewImage("");
            }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.Tickets && data.Tickets.TicketId || 0) === 0
                        ? "Add Ticket"
                        : "Edit Ticket"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.Tickets}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                    <div className="col-md-12 mb-2">
                            <Autocomplete
                                id="grouped-demo"
                                options={ticketCategoriesList}
                                getOptionLabel={(option) => option?.text}
                                groupBy={(option) => option.ParentId === null ? option.text : ""}
                                defaultValue={selectedAttachPerson.text ? selectedAttachPerson : null}
                                onChange={handleCategoryChange}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Ticket Categories"
                                        placeholder="Ticket Categories"
                                        error={autoErrorShow} // Set error state of TextField
                                        helperText={(autoErrorShow) && "Select atleast one ticket category"} // Display error message if error is true
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Title"
                                name="Title"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                name="Description"
                                label="Description"
                                as="textarea"
                                rows="2"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-12 pb-1">
                            <FormInputDropdown
                                name="Status"
                                ddOpt={StandardConst.TicketStatusDropdown}
                                label="Status"
                                setValue={(value) => handleChangeStatus(Number(value))}
                                isRequired="true"
                            />
                        </div>
                        {
                            showRemarksInput && (
                                <>
                                    <div className="col-md-12">
                                        <FormInputText
                                            name="Remarks"
                                            label="Remarks"
                                            as="textarea"
                                            rows="2"
                                            className="form-control"
                                        />
                                    </div>
                                </>
                            )
                        }
                        <div className="col-md-12 pb-2">
                            <FormInputDropdown
                                name="Priority"
                                ddOpt={StandardConst.TicketPriorityDropdown}
                                label="Priority"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Creation Date"
                                name="CreationDate"
                                type="datetime"
                                max="2999-12-31"
                            />
                        </div>
                        <div className="col-md-12 pb-2">
                            <FormInputDropdown
                                name="ProjectId"
                                ddOpt={projectList}
                                label="Project"
                            />
                        </div>
                        <div className='col-md-12 mb-4' style={{ position: "relative" }}>
                            <div className='position-upload'>
                                <label htmlFor="upload-document" style={{ color: "#5a5a5a" }}>
                                    Attachment
                                    <span className="text-danger"></span>
                                </label>
                                <div style={{ display: "inline-block", position: "absolute", left: "200px", width: "222px" }}>
                                    <FormInputFile
                                        lable="Attachment"
                                        name="File"
                                        type="file"
                                        setValue={(e) => handleChangeFile(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 image-preview" style={{ display: "flex", justifyContent: "center", }}>
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-9">
                                <div className="pic-holder" style={{ border: "none", borderRadius: 0 }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={data.Tickets.Attachment ? `${StandardConst.apiBaseUrl}/uploads/${data.Tickets.Attachment}` : "" || uploadPreviewImage}
                                />
                                {
                                    uploadPreviewImage.includes("data:application/pdf;") || (data && data.Tickets && data.Tickets.Attachment?.endsWith(".pdf")) ?
                                    <embed src={data.Tickets.Attachment ? `${StandardConst.apiBaseUrl}/uploads/${data.Tickets.Attachment}` : "" || uploadPreviewImage} style={{ width: "100%", height: "100%", overflow: "auto", }} />
                                    : ""
                                }
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.Tickets && data.Tickets.TicketId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export const AgentAssignTicket = forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AssignTicket: {} });
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    useEffect(() => {
        fetchAgents();
    }, []);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id > 0) {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AssignTicket: await WebService({
                endPoint: `CommonUtility/Edit/tickets?TicketId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const handleClose = () => {
        setShow(false)
        setData({AssignTicket : {}});
    };

    const [agentList, setAgentList] = useState([]);

    const fetchAgents = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/agents?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
        });
        const list = [{text: "", value: null}].concat(data
            .filter(r => r.FullName !== "")
            .map((obj) => {
            return { text: obj.FullName, value: obj.AgentId}
        }));
        const sortedArray = list.sort((a, b) => {
            const nameA = a.text.toUpperCase();
            const nameB = b.text.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0; // Names are equal
        });
        setAgentList(sortedArray);
    }

    const onSubmit = async (data) => {
        if (data.TicketId) {
            await WebService({
                endPoint: `CommonUtility/tickets?TicketId=${data.TicketId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {(data?.AssignTicket?.AgentId !== undefined && data?.AssignTicket?.AgentId !== null && data?.AssignTicket?.AgentId > 0)
                            ? "Re-Assign Agent"
                            : "Assign Agent"
                        }
                    </Modal.Title>
                </Modal.Header>
                <Form
                    defaultValues={data.AssignTicket}
                    onSubmit={onSubmit}
                >
                    <Modal.Body className="p-4">
                        <div className="row">
                            <div className="col-md-12">
                                <FormInputDropdown
                                    name="AgentId"
                                    ddOpt={agentList}
                                    label="Agent Name"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                            {" "}
                            {(data?.AssignTicket?.AgentId !== undefined && data?.AssignTicket?.AgentId !== null && data?.AssignTicket?.AgentId > 0)
                                ? "Save Changes"
                                : "Submit"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
});

export const AttachTicket = forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AttachTicket: {} });
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [selectedAttachPerson, setSelectedAttachPerson] = useState([]);
    const [ticketId, setTicketId] = useState(0);
    const [AttachUsersData, setAttachUsersData] = useState([]);

    useEffect(() => {
        fetchAllTicketCategories();
    }, [])
    

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id > 0) {
                setShow(false);
                setTicketId(id);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AttachTicket: await WebService({
                endPoint: `CommonUtility/Edit/ticketattach?TicketId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c : {})),
        };
        if(data.AttachTicket.length >0){
            const EmployeeData = await Promise.all(data && data.AttachTicket && data.AttachTicket.map(async (r) => {
                if (r.EmployeeId) {
                    const result = await WebService({
                        endPoint: `CommonUtility/Edit/employees?EmployeeId=${r.EmployeeId || 0}`,
                        dispatch,
                    });
                    return result.length > 0 ? result[0] : {};
                }
            }));
    
            const usersData = await Promise.all(data && data.AttachTicket && data.AttachTicket.map(async (r) => {
                if(r.UserId){
                    const result = await WebService({
                        endPoint: `CommonUtility/Edit/users?UserId=${r.UserId || 0}`,
                        dispatch,
                    });
                    return result.length > 0 ? result[0] : {};
                }
            }));
            const mergeArray = [...EmployeeData, ...usersData].filter(item => item !== undefined);
            setSelectedAttachPerson(mergeArray);
            setAttachUsersData(mergeArray);
            setData(data.AttachTicket[0]);
        }
    }

    const handleClose = () => {
        setShow(false)
        setData({AttachTicket : {}});
        setSelectedAttachPerson([]);
        setAutoErrorShow(false);
    };

    const onSubmit = async (data) => {
        data.TicketId = ticketId;
        if (selectedAttachPerson.length > 0) {
            setAutoErrorShow(false);
            // Remove items from array2 based on array1
            const selectedPersonAttach = _.reject(selectedAttachPerson, (item2) => {
                return _.some(AttachUsersData, (item1) => {
                    if(item1.EmployeeId){
                        return item1.EmployeeId === item2.EmployeeId;
                    }
                    if(item1.UserId){
                        return item1.UserId === item2.UserId;
                    }
                    if(item1.EmployeeId && item1.UserId){
                        return item1.EmployeeId === item2.EmployeeId && item1.UserId === item2.UserId;
                    }
                });
            });

            selectedPersonAttach.map(async (item) => {
                const itemKeys = Object.keys(item);
                const isEmployeeId = itemKeys.some((key) => key === "EmployeeId");
                const isUserId = itemKeys.some((key) => key === "UserId");
                if(isEmployeeId){
                    data.EmployeeId = item.EmployeeId;
                    data.UserId = null;
                }
                if(isUserId){
                    data.UserId = item.UserId;
                    data.EmployeeId = null;
                }
                await WebService({
                        endPoint: "CommonUtility/ticketattach",
                        body: data,
                        dispatch,
                    });
                });
        }else {
            setAutoErrorShow(true);
        }
        handleClose();
        props.callBackEvent();
    }

    const [autoErrorShow, setAutoErrorShow] = useState(false);
    const [ticketCategoriesList, setTicketCategoriesList] = useState([]);

    const fetchAllTicketCategories = async () => {
        let data = await WebService({
            endPoint: `CommonUtility/fetchAll/employees?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
        });
        data = data.filter((obj) => obj.FullName!== null);

        let usersData = await WebService({
            endPoint: `CommonUtility/fetchAll/users?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
        });
        usersData = usersData.filter((obj) => obj.FullName!== null);
        const mergeArray = [...data, ...usersData];
        // Remove Person from here that already selected in dropdown;
        const removedSelectedPersons = _.reject(mergeArray, (item2) => {
            return _.some(AttachUsersData, (item1) => {
                if(item1.EmployeeId){
                    return item1.EmployeeId === item2.EmployeeId;
                }
                if(item1.UserId){
                    return item1.UserId === item2.UserId;
                }
                if(item1.EmployeeId && item1.UserId){
                    return item1.EmployeeId === item2.EmployeeId && item1.UserId === item2.UserId;
                }
            });
        });
        setTicketCategoriesList(removedSelectedPersons);
    }
    // Function to handle change in selected ticket Attach
    const handleCategoryChange = async (event, selectedOptions, reason, details) => {
        (selectedOptions.length === 0) ? setAutoErrorShow(true) : setAutoErrorShow(false);
        setSelectedAttachPerson(selectedOptions);
        // delete the existing attach employees or users
        if(reason === StandardConst.MUIAutocompleteReasonRemoveOption){
            const isEmployeeId = Object.keys(details.option).some((key) => key === "EmployeeId");
            if(isEmployeeId){
                await WebService({
                    endPoint: `CommonUtility/ticketattach?EmployeeId=${details.option.EmployeeId}`,
                    method: "DELETE",
                    dispatch,
                });
            }
            const isUserId = Object.keys(details.option).some((key) => key === "UserId");
            if(isUserId){
                await WebService({
                    endPoint: `CommonUtility/ticketattach?UserId=${details.option.UserId}`,
                    method: "DELETE",
                    dispatch,
                });
            }
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {(data && data.AttachTicket && data.AttachTicket.TicketId || 0) === 0
                            ? "Attach Ticket"
                            : "Attach Ticket"
                        }
                    </Modal.Title>
                </Modal.Header>
                <Form
                    defaultValues={data.AttachTicket}
                    onSubmit={onSubmit}
                >
                    <Modal.Body className="p-4">
                        <div className="row">
                        <div className="col-md-12 mt-2">
                            <Autocomplete
                                multiple
                                id="grouped-demo"
                                options={ticketCategoriesList.sort((a, b) => a.FullName.localeCompare(b.FullName))}
                                getOptionLabel={(option) => option?.FullName}
                                defaultValue={selectedAttachPerson}
                                onChange={handleCategoryChange}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Person Name"
                                        placeholder="Person Name"
                                        error={autoErrorShow} // Set error state of TextField
                                        helperText={(autoErrorShow) && "Atleast one person name you want to select."} // Display error message if error is true
                                    />
                                )}
                            />
                        </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                            {" "}
                            {(data && data.AttachTicket && data.AttachTicket.TicketId || 0) === 0
                                ? "Submit"
                                : "Save Changes"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
});

export default forwardRef(AddEditTicket);
import React from 'react';
import { useLocation } from "react-router-dom";
import { StandardConst } from '../../Services/StandardConst';
import HtmlFileReaderForInvoice from '../../Services/HtmlFileReaderForInvoice';
import { useEffect, useState } from 'react';
import { WebService } from '../../Services/WebService';
import { useDispatch } from "react-redux";
import { Box, Container } from '@mui/material';
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { PageInfo } from '../PageInfo';


const GenerateInvoice = () => {

    const { state } = useLocation();
    const invoiceHeaderId = state?.InvoiceHeaderId;
    const [templateData, setTemplateData] = useState();
    const [invoiceData, setInvoiceData] = useState([]);
    PageInfo({ pageTitle: "View Invoice" });
    const dispatch = useDispatch();

    const [bData, setBData] = React.useState([
      {
        title: "Invoice Management",
        hrefLink: "#",
      },
      {
        title: "Customer Invoice",
        hrefLink: "#",
      },
    ]);
    
    const fetchAllInvoiceSettings = async (invoiceHeaderId) => {
      await WebService({
        dispatch,
        endPoint: `Invoice/FetchAllInvoiceDetails/${invoiceHeaderId}`,
      }).then((recCustomerInvoiceData) => {
        setInvoiceData(recCustomerInvoiceData);
        setTemplateData(recCustomerInvoiceData.SettingDetails.TemplateFile);
      });
    };
    
    useEffect(() => {
      fetchAllInvoiceSettings(invoiceHeaderId);
    }, []);



  return (
    <>
    
    <Container fluid className="base-container">
      <Box
        sx={{
          width: 1,
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <h3 className="ms-4 mt-2">View Invoice</h3>
            <div className="ms-4">
              <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
            </div>
          </div>
        </div>
      </Box>
      <div className="p-3 primary-bg-color"></div>
      <div className="mx-4 mt-2">
          <HtmlFileReaderForInvoice 
              jsonData={invoiceData}
              defaultSelectedTemplate={templateData}
              ShowDownloadBadgeBtn={StandardConst.YesNo.Yes} 
              ShowPrintBadgeBtn={StandardConst.YesNo.Yes} 
          />
      </div>
    </Container>
    </>
  );
};

export default GenerateInvoice;
import * as yup from "yup";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  Form,
  FormAutoCompleteDropdownCard,
  FormInputDropdown,
  FormInputText,
  InputText,
} from "../Form";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { chain, extendOwn } from "underscore";
import Button from "react-bootstrap/esm/Button";
import TopbarComponent from "../../Services/TopbarComponent";
import { Container, Alert, AlertTitle } from "@mui/material";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { PageInfo } from "../PageInfo";
import { UploadOrClickProfilePicture } from "../../Services/ImageUploader";
import { formatCurrentDate } from "../../utils/CommonUtils";
import { useLocation } from "react-router-dom";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { ExcelImport } from "../../Services/ImportExport";
import { format } from "date-fns/esm";
import { addDays } from "date-fns";

const WalkinVisitorRegistrationComponent = memo((ref) => {
  const { state } = useLocation();
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  var frmRef = useRef();
  var dispatch = useDispatch();
  var [stateVisitorDetail, setState] = useState({});
  var [isExpiredEvent, setIsExpiredEvent] = useState(false);
  var CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);
  const [settings, setSettings] = useState({phone: false, email: false, whomTOMeet: false});
  PageInfo({ pageTitle: (state === null || state === undefined) ? "Registration" : "Event Visitor Registration" });
  
  const FetchVisitorTypeList = async () => {
    var EndPoint = `Visitor/Type?CompanyId=${CompanyId}`;
    if(state?.EventId){
      EndPoint = `Event/getEventVisitorDataForDropdown?EventId=${state?.EventId}`;

      await WebService({
        dispatch,
        endPoint: `CommonUtility/Edit/events?EventId=${state?.EventId}`,
      }).then((res) => {
        if(res.length > 0){
          const todayDate = format(new Date(), "yyyy-MM-dd");
          const fromDate = format(new Date(res[0].EventFromDate), "yyyy-MM-dd");
          const toDate = format(new Date(res[0].EventToDate), "yyyy-MM-dd");
          const EventExpire = fromDate <= todayDate && todayDate <= toDate;
          setIsExpiredEvent(!EventExpire);
        }
      });

    }
    WebService({
      dispatch,
      endPoint: EndPoint,
    }).then((visitorTypes) => setState((s) => ({ ...s, visitorTypes })));
  }

  const [EmployeesList, setEmployeesList] = useState([]);

  const FetchEmployeeList = async() => {
    const data = await WebService({
      dispatch,
      endPoint: `Visitor/fetchAllEmployees`
    });
    const list = data?.filter((r) => r.FullName !== null)?.map((res) => {
      return {
          uniqueId: res.EmployeeId,
          heading: res.FullName, 
          description: res.Designation, 
          avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
        }
    });
    setEmployeesList(list);
  }

  const [imageName, setImageName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [guestLink, setGuestLink] = useState({});
  const [selectedGuest, setSelectedGuest] = useState({});
  const [defaultFormData, setDefaultFormData] = useState({});
  const [countFilledGuest, setCountFilledGuest] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const resetFunction = () => {
    setGuestLink({});
    setSelectedGuest({});
    setDefaultFormData({});
  };

  useEffect(() => {
    if(Object.keys(selectedGuest).length > 0) {
      setDefaultFormData({VisitorTypeId : selectedGuest.VisitorTypeId, ContactPerson : selectedGuest.ContactPerson, Access : selectedGuest.Access, Purpose: selectedGuest.Purpose, OfficeLocationId : selectedGuest.OfficeLocationId});
      setIsDataLoaded(true);
    }else{
      setIsDataLoaded(true);
    }
  }, [selectedGuest]);

  useEffect(() => {
    if(countFilledGuest === guestLink?.NumberOfGuest?.length ?? 0) {
      resetFunction();
      setCountFilledGuest(0);
    }
  }, [countFilledGuest]);
  
  
  const onSubmit = async (dt, isThisCallFromBulkSubmission) => {
    isThisCallFromBulkSubmission ??= false;
    var sucessMsg = `${dt.VisitorName} is registered successfully`;
    if(Object.keys(selectedGuest).length > 0) {
      sucessMsg = `${dt.VisitorName} guest of (${selectedGuest.ParentVisitorName}) is registered successfully`;
      dt.ParentVisitorId = selectedGuest.ParentVisitorId;
    }
    if(state){
      dt.EventId = state?.EventId;
    }
    extendOwn(dt, {
      CompanyId,
      ContactPersonForGuest : dt.ContactPerson,
      ContactPerson:
        chain(dt.ContactPerson ?? [])
          .first()
          .value()?.uniqueId ?? null,
      CreatedBy: loggedUser,
    });
    dt.ProfilePicture = imageName;

    var VisitorId = 0;
    if(isThisCallFromBulkSubmission) {
      if(dt?.Email !== undefined && dt.Email !== ''){
        VisitorId = await WebService({
          dispatch,
          endPoint: "Visitor/RegisterVisitorAfterLogin",
          body: dt,
        });
      }
    }else{
      VisitorId = await WebService({
        dispatch,
        endPoint: "Visitor/RegisterVisitorAfterLogin",
        body: dt,
      });
    }


    const formattedDate = formatCurrentDate();

    // agar visitor ko bulk invite kr rhe hai excel sheet upload krke to Visitor ko check in nahi krna hai
    if(!isThisCallFromBulkSubmission){
      if(dt.ContactPerson !== null){
        // await WebService({
        //   dispatch,
        //   endPoint: "Notification/Create",
        //   body: {
        //     Message: `${dt.VisitorName} (Visitor) has Checked In `,
        //     SendTo: [dt.ContactPerson],
        //     Expire: addDays(new Date(), 1),
        //   },
        // });
        
        await WebService({
          endPoint: `Visitor/WhomToMeetPersonSendEmail?EmployeeId=${dt.ContactPerson}&OfficeLocationId=${dt.OfficeLocationId}`,
          body: {
            VisitorName : dt.VisitorName,
          },
          method: 'POST',
        });
      }


      await Promise.all([
        frmRef.current.fnReset({}),
        WebService({
          dispatch,
          endPoint: `Visitor/CheckIn`,
          body: { VisitorId, CheckInDate: formattedDate},
          method: `PATCH`,
        }),
      ]);
    }

    if(settings.guest && settings.GuestNeedSeparatePass && dt.NumberOfGuest > 0 && Object.keys(guestLink).length === 0) {
      setGuestLink({NumberOfGuest: Array.from({ length: dt.NumberOfGuest }, () => ({ GuestName: "" })), ParentVisitorId: VisitorId, ParentVisitorName : dt.VisitorName, VisitorTypeId : dt.VisitorTypeId, ContactPerson : dt.ContactPersonForGuest, Access : dt.Access, Purpose: dt.Purpose, OfficeLocationId : dt.OfficeLocationId});
    }
    if(Object.keys(selectedGuest).length > 0) {
      setCountFilledGuest(prev => prev+1);
      if(selectedGuest?.index !== undefined) {
        guestLink.NumberOfGuest[selectedGuest.index].GuestName = dt.VisitorName;
      }
      setSelectedGuest({});
    }


    setSettings({phone: false, email: false, whomTOMeet: false, guest: false, GuestNeedSeparatePass: false});
    setIsSubmitted(!isSubmitted);
    
    if(!isThisCallFromBulkSubmission){
      frmRef.current.fnReset();
      WSSuccessAlert("Success", sucessMsg);
      setDefaultFormData({});
    }else{
      if(VisitorId > 0){
        dt.msg = StandardConst.APIMessage.Success;
      }else{
        dt.msg = StandardConst.APIMessage.Error;
      }
      return dt;
    }
  };

  useEffect(() => {
    if (CompanyId > 0)
      Promise.all([
        FetchEmployeeList(),
        FetchVisitorTypeList(),
        getsetlocation(),
      ]);
  }, [CompanyId]);
  const [locationData, setlocationData] = useState([]);
  const getsetlocation = () => {
    WebService({
      endPoint: `UserProfile/Fetchlocation`,
      dispatch,
    }).then((data) => {
      setlocationData(
        data.data.map((v) => {
          const words = v.Address.split(' ');
          const truncatedWords = words.slice(0, 3);
          const truncatedAddress = truncatedWords.join(' ');
          const finalAddress = truncatedAddress + ', ' + v.Location;
          return {
            value: v.OfficeLocationId,
            text: finalAddress,
          };
        })
      );
    });
  };
  const validationSchema = yup
    .object()
    .shape({
      VisitorName: yup
        .string()
        .trim()
        .label("Name")
        .required(StandardConst.requiredMessage)
        .max(100),
      VisitorCompany: yup.string().trim().label("Company").max(100),
      Address: yup.string().trim().label("Address").max(300),
      Email: settings.email ? yup.string().trim().label("Email").max(200).email() : null,
      ContactPerson: !settings.whomTOMeet ? 
                    yup
                    .array()
                    .of(
                      yup.object().shape({
                        value: yup.string(),
                        label: yup.string(),
                      })
                    )
                    .typeError(StandardConst.requiredMessage)
                    .min(1, StandardConst.requiredMessage)
                    :null,
      // InvitedBy: yup
      //   .string()
      //   .trim()
      //   .label("Invited By")
      //   .required(StandardConst.requiredMessage),
      VisitorTypeId: yup
        .string()
        .trim()
        .label("Visitor Type")
        .required(StandardConst.requiredMessage),

      ContactNo: settings.phone ? yup.string()
        .required(StandardConst.requiredMessage)
        .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid) :
        null,

      Purpose: yup.string().required(StandardConst.requiredMessage),
      // ScheduleCheckInTime: yup
      //   .date()
      //   .typeError(StandardConst.requiredMessage)
      //   .required(StandardConst.requiredMessage),
      // OfficeLocationId: yup.string().trim().required(StandardConst.requiredMessage),
    })
    .required();
  const MasterPageName = (state === null || state === undefined) ? "Registration" : "Event Visitor Registration";
  const [bData, setBData] = React.useState([
    {
      title: "Visitor Management",
      hrefLink: "#",
    },
    {
      title: (state === null || state === undefined) ? "Registration" : "Event Visitor Registration",
      hrefLink: "#",
    },
  ]);


  const handleChangeVisitorType = async(visitorTypeId) => {

    const visitorTypeData = await WebService({
      dispatch, 
      endPoint: `CommonUtility/fetchAll/static_visitor_type?VisitorTypeId=${visitorTypeId}`,
    }).then((c) => (c.length > 0 ? c[0] : {}));
    
    setSettings((prevSettings) => ({
      ...prevSettings,
      phone: prevSettings.phone || visitorTypeData.WillProvidePhoneNo ? visitorTypeData.WillProvidePhoneNo : false,
      email: prevSettings.email || visitorTypeData.WillProvideEmail ? visitorTypeData.WillProvideEmail : false,
      whomTOMeet: prevSettings.whomTOMeet || visitorTypeData.IsServiceProvider ? visitorTypeData.IsServiceProvider : false,
      guest: prevSettings.NumberOfGuest || visitorTypeData.CanBringOtherGuests ? visitorTypeData.CanBringOtherGuests : false,
      GuestNeedSeparatePass: visitorTypeData.GuestPassAlsoNeeded ? visitorTypeData.GuestPassAlsoNeeded : false,
    }));
  }

  const [visitorBulkSubmissionData, setVisitorBulkSubmissionData] = useState([]);

  const handleFileUpload = async (e) => {

    const uploadedFile = e.target.files[0];
    if (!uploadedFile) {
      WSErrorAlert("Error", "Please upload an Excel file.")
      return;
    }

    // This is Static Columns array Which is in the Excel sheet
    const staticColumns = ["VisitorName", "Email", "ContactNo"];
    // this function for getting Excel file Data
    const excelData = await ExcelImport(uploadedFile, staticColumns);

    if(excelData.length > 0) {
      const result = await Promise.all(excelData.map(async (item) => await onSubmit(item, true)));
      if(result.length === excelData?.length){
        setVisitorBulkSubmissionData(result);
        const successVisitorCount = result.filter(item => item.msg === StandardConst.APIMessage.Success).length;
        const errorVisitorCount = result.filter(item => item.msg === StandardConst.APIMessage.Error).length;
        document.getElementById('file-upload').value = "";
        WSSuccessAlert("Success", `${successVisitorCount} Visitor(s) successfully invited. <br> ${errorVisitorCount} Visitor(s) invitation failed.`);
      }
    }else{
      WSErrorAlert("Error", "No Meaningful data to import please validate column and data with sample excel sheet.");
    }
  };

  if (!isDataLoaded) {
    return <></>;
  }

  return (
    <Container fluid className="base-container">
      <TopbarComponent bData={bData} HeadingText={MasterPageName} />

      <div elevation={0} className="container bg-primary m-0 p-1">
        {(state && !isExpiredEvent) ? (
          <>
            <div class="row justify-content-end">
              <div class="col-auto">
                <Button>
                  <label for="file-upload">
                      <i class="fa fa-cloud-upload"></i>&nbsp; Bulk Visitor Invite
                  </label>
                </Button>
                <input id="file-upload" className="border-0 text-white d-none" type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
              </div>
              <div class="col-auto">
                <a href="/assets/SampleBulkVisitorInvite.xlsx" download>
                  <Button class="btn btn-secondary"><i class="fa fa-download" aria-hidden="true"></i>&nbsp; Download sample file</Button>
                </a>
              </div>
            </div>
          </>
        ) : <div  className="p-3"></div>}
      </div>

      {(visitorBulkSubmissionData.length > 0) && (
        <TableComponent
            columns={[
              {
                Text: "Visitor Name",
                Value: "VisitorName",
              },
              {
                Text: "Email",
                Value: "Email",
              },
              {
                Text: "Contact No.",
                Value: "ContactNo",
              },
              {
                Text: "Action",
                Value: "msg",
                render: (dr) => (dr.msg === StandardConst.APIMessage.Success ? <TaskAltIcon color="success" /> : <CancelIcon color="success" />)
              },
            ]}
            noRecordCss="p-0"
            noRecordFound={
                <NoRecordTemplate
                    headerValue={"No matching visitor record found."}
                    imageUrl={StandardConst.imageNoRecordsFound}
                />
            }
            data={visitorBulkSubmissionData}
            isSearchRequired={true}
            IsAddButtonVisible={false}
        />
      )}
      {(visitorBulkSubmissionData.length === 0) && (
        <div className="row">
          <div className="col-md-6 col-12 p-5">
            {(isExpiredEvent) && (
              <>
                <Alert
                  severity="error"
                  icon={<CancelIcon fontSize="inherit" />}
                >
                  <AlertTitle>Event Expired</AlertTitle>
                  {" "}
                  This event has finished{" "}
                  <strong> — Registration is not allowed</strong>
                </Alert> <br />
              </>
            )}
            <Form
              defaultValues={defaultFormData}
              onSubmit={onSubmit}
              ref={frmRef}
              validationSchema={validationSchema}
            >
              <Row>
                <Col className="col-md-12 col-sm-12 d-flex justify-content-center pb-2">
                  <UploadOrClickProfilePicture
                  UploadedFileName={setImageName}
                  onSubmittedClicked={isSubmitted}
                  >
                  </UploadOrClickProfilePicture>
                </Col>

                {(Object.keys(selectedGuest).length > 0) && (
                  <Col md={12} sm={12}>
                    {/* <label htmlFor="">Guest of : {selectedGuest?.ParentVisitorName}</label> */}
                    <InputText
                      label={"Guest of"}
                      value={selectedGuest?.ParentVisitorName}
                      disabled={true}
                    />
                  </Col>
                )}

                <Col md={12} sm={12}>
                  <FormInputDropdown
                    label="Visitor Type"
                    name="VisitorTypeId"
                    ddOpt={[{}].concat(
                      chain(stateVisitorDetail.visitorTypes ?? [])
                        .map((m) => ({
                          value: m.VisitorTypeId,
                          text: m.TypeName,
                        }))
                        .value()
                    )}
                    isRequired="true"
                    setValue={(currentValue) => handleChangeVisitorType(Number(currentValue))}
                    value={defaultFormData?.VisitorTypeId ?? ""}
                    disabled={Object.keys(selectedGuest).length > 0}
                  />
                </Col>

                <Col md={12} sm={12}>
                  <FormInputText
                    label="Name"
                    name="VisitorName"
                    isRequired="true"
                  />
                </Col>
                <Col md={12} sm={12}>
                  <FormInputText label="Company" name="VisitorCompany" />
                </Col>
                <Col md={12} sm={12}>
                  {" "}
                  {settings.email ? 
                    <FormInputText label="Email" name="Email" />
                  :
                  null
                  }
                </Col>

                <Col md={12} sm={12}>
                  {" "}
                  {
                    settings.phone ? 
                    <FormInputText
                    label="Contact No"
                    name="ContactNo"
                    isRequired="true"
                  />
                    :
                    null
                  }
                </Col>

                <Col md={12} sm={12}>
                  <FormInputText
                    label="Designation"
                    name="Designation"
                  />
                </Col>
                <Col md={12} sm={12}>
                  <FormInputText
                    label="Visitor Address"
                    name="Address"
                    as="textarea"
                  />
                </Col>
                <Col md={12} sm={12}>
                  <FormInputText
                    label="Purpose of Visit"
                    name="Purpose"
                    as="textarea"
                    isRequired="true"
                    value={defaultFormData?.Purpose ?? ""}
                    disabled={Object.keys(selectedGuest).length > 0}
                  />
                </Col>
                {(state === null || state === undefined) && (
                  <>
                    <Col md={12} sm={12}>
                      <FormInputDropdown
                        label="Office Location"
                        name="OfficeLocationId"
                        ddOpt={locationData}
                        isRequired="true"
                        value={defaultFormData?.OfficeLocationId ?? ""}
                        disabled={Object.keys(selectedGuest).length > 0}
                      />
                    </Col>
                    <Col md={12} sm={12}>
                    {
                      !settings.whomTOMeet ?
                        <>
                          <FormAutoCompleteDropdownCard
                            name="ContactPerson"
                            data={chain(EmployeesList ?? [])}
                            label="Whom To Meet"
                            optionText="heading"
                            isRequired="true"
                            disabled={Object.keys(selectedGuest).length > 0}
                            value={defaultFormData?.ContactPerson !== undefined ? defaultFormData?.ContactPerson[0] ?? "" : ""}
                          />
                        </>
                        :
                        null
                    }
                  </Col>

                    <Col md={12} sm={12}>
                      <FormInputDropdown
                        label="Access"
                        name="Access"
                        ddOpt={StandardConst.VisitorRegistration}
                        value={defaultFormData?.Access ?? ""}
                        disabled={Object.keys(selectedGuest).length > 0}
                      />
                    </Col>

                    {(settings.guest && Object.keys(selectedGuest).length === 0) ? 
                      <Col md={12} sm={12}>
                          <FormInputText label="Number of guests" name="NumberOfGuest" />
                      </Col>
                    :
                    null
                    }
                  </>
                )}

              </Row>
              {/* <FormInputText
            label="Schedule CheckIn Time"
            name="ScheduleCheckInTime"
            type="date"
            min={DateTime.now().plus({ day: 1 }).toJSDate()}
          /> */}

              {/* <FormInputDropdown
            label="Invited By"
            name="InvitedBy"
            ddOpt={[{}].concat(
              chain(state.employees ?? [])
                .map((m) => ({
                  value: m.EmployeeId,
                  text: m.FullName,
                }))
                .value()
            )}
          /> */}

              {Object.keys(guestLink).length > 0 && 
                <div className="col-12 mt-2">
                  {guestLink.NumberOfGuest.map((guest, index) => {
                    return <><Button onClick={() => setSelectedGuest({...guestLink, index: Number(index)})}>{guest?.GuestName !== '' ? guest?.GuestName : `Add Your Guest ${index + 1}`}</Button>&nbsp;&nbsp;</>
                  })}
                </div>
              }


              {(!isExpiredEvent) && (
                <Row className="mt-2 mb-5">
                  <Col className="d-flex justify-content-end">
                    <Button
                      className="mx-2"
                      type="reset"
                      onClick={() => {resetFunction();frmRef.current.fnReset()}}
                      variant="outline-danger"
                    >
                      Reset
                    </Button>
                    <Button type="Submit" variant="outline-primary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
          
          <div className="col-md-6 p-5 d-none d-sm-block">
            <div className="card1">
              <div className="row px-3 justify-content-center  mt-5 mb-5 border-line pt-5">
                <img
                  src={`${StandardConst.ProjectImagesInPublicAssets}/vistorSelfCheckin.png`}
                  className="bg-image "
                  alt="loading..."
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
});

export default WalkinVisitorRegistrationComponent;

import React, { useEffect, useRef, useState } from 'react'
import EventCoordinator from './EventCoordinator'
import SnackbarComponent from '../../Services/SnackbarComponent'
import { useDispatch, useSelector } from "react-redux"
import { PageInfo } from '../PageInfo'
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { WebService } from '../../Services/WebService'
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent'
import Button from "react-bootstrap/Button";
import { StandardConst } from '../../Services/StandardConst'
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton'
import { useLocation } from "react-router-dom";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { Box, Container, Typography, Stack } from "@mui/material";
import { Form, FormInputDropdown } from '../Form'

const ManageEventCoordinators = () => {

    const refSnackbar = useRef();
    const ref = useRef();
    const addEditModalRef = useRef();
    const MasterPageName = "Manage Events Coordinators";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "Event Coordinator";
    const dispatch = useDispatch();
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Manage Events Coordinators" });
    const { state } = useLocation();
    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [effectCount, setEffectCount] = useState(0);
    useEffect(() => {
        SetPermission({
            ManageAdd: true, // ActionPermission("Visitor Type - Add"),
            ManageEdit: true, // ActionPermission("Visitor Type - Edit"),
            ManageDelete: true, // ActionPermission("Visitor Type - Delete"),
        });
    }, [permissionList]);


    const fetchManageEventsCoordinators = async (EventId) => {
        const data = await WebService({
            endPoint: `EventCoordinators/EventCoordinatorsList?EventId=${EventId ? EventId : state?.EventId}`,
            dispatch,
        });
        setRecords(data);
        setEffectCount((prevCount) => prevCount + 1);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        if (effectCount < 5) {
            fetchManageEventsCoordinators();
        }
    }, [effectCount]);

    const onDelete = async (EventCoordinatorId) => {
        await WebService({
            endPoint: `CommonUtility/eventcoordinators?EventCoordinatorId=${EventCoordinatorId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchManageEventsCoordinators();
    };

    const columns = [
        {
            Text: "Coordinator Name",
            Value: "FullName",
        },
        {
            Text: "Email",
            Value: "Email"
        },
        {
            Text: "Phone",
            Value: "Phone"
        },
        {
            Text: "Person Type",
            render: (dr) => (
                <> 
                    {(dr.EmployeeId) > 0 ? "Employee" : ""}
                    {(dr.UserId) > 0 ? "External" : ""}
                </>
            )
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    {
                        dr.EmployeeId === 0 && (
                            <ActionButton
                                onClick={() => fnEdit(dr.EventCoordinatorId)}
                                disabled={!permission.ManageEdit}
                                IconName="Edit"
                                id={`btnEventCoordinatorEdit_${dr.EventCoordinatorId}`}
                            />
                        )
                    }
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.FullName, // Message of Alert
                                dr.EventCoordinatorId // Endpoint to hit for delete
                            )
                        }
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        id={`btnEventCoordiantorDelete_${dr.EventCoordinatorId}`}
                    />
                </>
            ),
        },
    ]

    const [bData, setBData] = React.useState([
        {
            title: "Manage Events",
            hrefLink: "/ManageEvents",
        },
        {
            title: "Manage Events Coordinators",
            hrefLink: "#",
        },
    ]);

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    const Roles = useSelector((s) => s.auth.AssignRole ?? {});

    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});

    var userType = "";
    var ShowAllEventRecord = false;
    var EmployeeOrUserId;

    if(EmployeeId){
        userType = StandardConst.UserType.Employee;
        EmployeeOrUserId = EmployeeId
    }else if(UserId){
        userType = StandardConst.UserType.User;
        EmployeeOrUserId = UserId;
    }

    if(Roles?.some(element => element === StandardConst.SystemRole.Company) || Roles?.some(element => element === StandardConst.SystemRole.Receptionist) || Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin)){
        ShowAllEventRecord = true;
        if(Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin)) {
            userType = StandardConst.UserType.SuperAdmin;
        }
        // isEventCoordinator = false;
        // isEmployeeEventCoordinator = false;
    }else if(Roles?.some(element => element === StandardConst.SystemRole.EventCoordinator)){
        ShowAllEventRecord = false;
    }

    const [EventList, setEventList] = useState([]);

    const fetchEventList = async () => {
        const data = await WebService({
            endPoint : `Event/EventList?CompanyId=${StandardConst.YesNo.Yes}&ShowAllEventRecord=${ShowAllEventRecord}&userType=${userType}&EmployeeOrUserId=${EmployeeOrUserId}`,
            dispatch,
        });

        const list = data.map((r) => {
            return { text: r.EventName, value: r.EventId }
        })
        setEventList(list);
    }

    const handleCurrentEventId = async (currentEventValue) => {
        if(state) {
            state.EventId = currentEventValue;
        }
        await fetchManageEventsCoordinators(currentEventValue);
    }

    useEffect(() => {
        fetchEventList();
    }, [userType, ShowAllEventRecord]);

    return (
        <>
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
                            <div className="ms-4">
                                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                            </div>
                        </div>
                    </div>
                </Box>
                <div></div>
                <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
                <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
                <Form>
                    <Stack spacing={2} alignItems="center" direction="row" sx={{ margin: "0.5rem 0 0.5rem 1.5rem" }}>
                        <Typography
                            sx={{ fontFamily: "sans-serif", fontSize: "14px" }}
                        >
                            Event Name
                        </Typography>
                        <Stack sx={{ width: '200px' }}>
                            <FormInputDropdown
                                name="EventId"
                                ddOpt={EventList}
                                value={state?.EventId}
                                setValue={(currentEventId) => { handleCurrentEventId(Number(currentEventId)) }}
                            />
                        </Stack>
                    </Stack>
                </Form>
                <TableComponent
                    columns={columns}
                    noRecordCss="p-0"
                    noRecordFound={
                        <NoRecordTemplate
                            headerValue={StandardConst.ManageEventCoordinatorsheaderValueNoResults}
                            subHeaderValue={StandardConst.ManageEventCoordinatorsSubHeaderRole}
                            imageUrl={StandardConst.imageNoRecordsFound}
                            actionButton={
                                <>
                                    {permission.ManageAdd && (
                                        <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                                            Add {AddButtonText}
                                        </Button>
                                    )}
                                </>
                            }
                        />
                    }
                    data={records}
                    isSearchRequired={true}
                    onAddEvent={() => fnEdit()}
                    title={AddButtonText}
                    IsAddButtonVisible={permission?.ManageAdd}
                    allowSerialNo={true}
                />
                <EventCoordinator
                    ref={addEditModalRef}
                    callBackEvent={async () => await fetchManageEventsCoordinators()}
                    EventId={state?.EventId}
                />
            </Container>
        </>
    )
}

export default ManageEventCoordinators
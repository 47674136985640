import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function CustomSeparator(bData) {
  bData.bData[0].visible ??= true;
  var nevigate = useNavigate();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="#/Dashboard"
      onClick={() => nevigate("#/Dashboard")}
    >
      <HomeIcon sx={{ mr: 0.5 }} style={{ fontSize: "0.8rem" }} />
    </Link>,

    bData.bData[0].visible && (
      <Link
        style={{ fontSize: "0.8rem" }}
        underline="hover"
        key="2"
        color="inherit"
        onClick={() => nevigate(bData.bData[0].hrefLink)}
      >
        {bData.bData[0].title}
      </Link>
    ),

    <Typography key="3" color="text.primary" style={{ fontSize: "0.8rem" }}>
      {bData.bData[1].title}
    </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

import React, { useEffect, memo, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import TableComponent from "../../Services/TableComponent";
import { useNavigate } from "react-router-dom";
import CheckBox from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import Pending from "@mui/icons-material/Pending";
import { Tab, Tabs, Box, AppBar, Toolbar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme, styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import ActionButton from "../../Services/ActionButton";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import SnackbarComponent from "../../Services/SnackbarComponent";


const EmployeeListComponent = () => {
  const refSnackbar = useRef();
  const deleteRef = useRef();
  PageInfo({ pageTitle: "Employee List" });
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const [state,setState]=useState();

  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
  const [permission, SetPermission] = useState({});

  useEffect(() => {
    SetPermission({
        ApprovedEmployeeDelete: ActionPermission("Approved Employees - Delete")
    });
}, [permissionList]);

  const fetchEmployees = async () => {
    WebService({
      dispatch,
      endPoint:`User/EmployeeList?where=StatusId eq ${StandardConst.Status.Approved}`
    }).then(r => setState(r));
  };
  useEffect(()=>{
    fetchEmployees();
  },[]);
  const columns = [
    {
      Text: "Full Name",
      Value: "FullName",
    },
    {
      Text: "Code",
      Value: "EmployeeCode",
    },
    {
      Text: "Email",
      Value: "Email",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },
    {
      Text: "Designation",
      Value: "Designation",
    },
    {
      Text: "Manager",
      Value: "Manager",
    },
    {
      Text: "Action",
      render: (dr) => (
        <div className="d-flex">
          <button
            className="btn btn-default text-primary"
            id="btnRegistrationApprovalView"
            onClick={() => fnEdit(dr.EmployeeId)}
          >
            <i className="fa fa-eye"></i>
          </button>
          <ActionButton
              onClick={(e) =>
                  deleteRef.current.confirmAlert(
                      "Delete", //Confirm button text
                      "Are You Sure", // Text if Alert
                      "Do you want to delete this employee " + dr.FullName, // Message of Alert
                      dr.EmployeeId  // Endpoint to hit for delete
                  )}
              disabled={!permission.ApprovedEmployeeDelete}
              IconName="Delete"
              IconTooltip={"Delete Employee"}
          />
        </div>
      ),
    },
  ];

  const onDelete = async (EmployeeId) => {
    await WebService({
        endPoint: `CommonUtility/employees?EmployeeId=${EmployeeId}`,
        method: "DELETE",
        dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchEmployees();
  };

  const fnEdit = async (EmpId) => {
    navigate("/Common/Profile", { state: { EmpId } });
  };
  const topbar = (
    <Topbar
      bData={[
        {
          title: "Employee",
          hrefLink: "#",
        },
        {
          title: "Employee List",
          hrefLink: "#",
        },
      ]}
      HeadingText="Employee List"
    ></Topbar>
  );
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={StandardConst.EmployeeDeleteConfirmationMsg} />
      <DeleteConfirmAlert ref={deleteRef} confirmEvent={(v) => onDelete(v)} />
      <Container
        fluid
        style={{
          padding: "0px",
          // "background-color": "#FFF",
          borderRadius: "5px ",
          margin: "10px",
          backgroundColor: "#fff",
        }}
      >
        {topbar}
        <Box>
          <TableComponent
            columns={columns}
            noRecordCss="p-0"
            noRecordFound={
              <NoRecordTemplate
                headerValue="No results found"
                subHeaderValue="Looks like you don't have any approved results !!!"
                imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
              />
            }
            data={state}
            onAddEvent={() => fnEdit()}
            IsAddButtonVisible={false}
            isSearchRequired={true}
            allowSerialNo={true}
          />
        </Box>
      </Container>
    </>
  );
};

export default memo(EmployeeListComponent);

import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { WSSuccessAlert } from "../../Services/WSAlert";

const apiUrl = "User/OfCustomer";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditUser = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ User: {}, CustomerList: [] });

  useImperativeHandle(ref, () => ({
    openModal: async (UserId) => {
      UserId ??= 0;
      setShow(true);
      if(UserId === 0){
        setData({ User: {} });
        fetchCustomer();
      }else {
        setShow(false);
        await WebService({
          dispatch,
          endPoint: `CommonUtility/Edit/users?UserId=${UserId || 0}`,
        }).then((r) => setData((dt) => ({ ...dt, User: r[0] })));
        setShow(true);
      }
    },
  }));
  
  const fetchCustomer = async () =>
    await WebService({
      dispatch,
      endPoint: "Customer/Fetch",
      requiredLoader: false,
    }).then((r) => setData((dt) => ({ ...dt, CustomerList: r })));
  useEffect(() => { Promise.all([fetchCustomer()]); }, []);
  const handleClose = () => setShow(false);

  const requiredMessage = StandardConst.requiredMessage;
  const numberError = StandardConst.numberError;
  const mobileMessage = StandardConst.mobileMessage;
  const schema = yup
    .object()
    .shape({
      CustomerId: yup.string().label("Company").trim().required(requiredMessage),
      FullName: yup.string().label("Name").trim().required(requiredMessage),
      Email: yup
        .string()
        .label("Email")
        .trim()
        .required(requiredMessage)
        .email(),

      Phone: yup.string()
        .required(StandardConst.requiredMessage)
        .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid),
    })
    .required();
  const onSubmit = async (body) => {
    await WebService({
      endPoint: `${apiUrl}/Submit`,
      body,
      dispatch,
    }).then(res => {
      if(body.UserId !== undefined){
        WSSuccessAlert("Success", `${body.FullName} updated successfully`)
      }else{
        WSSuccessAlert("Success", `${body.FullName} added successfully`)
      }
    });

    handleClose();
    prop.callBackEvent();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.User?.UserId ?? 0) === 0
            ? "Add User"
            : "Edit User"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data?.User??{}}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputDropdown
                label="Company Name"
                name="CustomerId"
                isRequired="true"
                ddOpt={[{}].concat(data?.CustomerList?.map(m=>({
                    text: m.Name,
                  value: m.CustomerId
                })))}
              />
            </div>
            <div className="col-md-12 mt-1">
              <FormInputText
                label="Name"
                name="FullName"
                type="text"
                isRequired="true"
              />
            </div>
            <div className="col-md-12">
              <FormInputText
                label="Email"
                name="Email"
                type="text"
                isRequired="true"
              />
            </div>
            <div className="col-md-12">
              <FormInputText
                label="Ph."
                name="Phone"
                type="text"
                isRequired="true"
              />
            </div>

            <div className="col-md-12">
              <FormInputText
                name="Address"
                label="Address"
                as="textarea"
                rows="2"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnCustomerSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data.User.UserId || 0) === 0 ? "Save Customer" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditUser);

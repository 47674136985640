import React, { memo, useEffect, useRef, useState } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import { Form, FormInputDropdown, FormInputFile, FormInputText } from "../Form";
import Button from "react-bootstrap/esm/Button";
import {
  Alert,
  Box,
  Container,
  Divider,
  IconButton,
  styled,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import AddEditInvoiceSection from "./AddEditInvoiceSection";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { filter, map } from "underscore";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import ActionButton from "../../Services/ActionButton";
import AddEditFormulaGenerator from "./AddEditFormulaGenerator";
import { PageInfo } from "../PageInfo";
const InvoiceSettingsComponent = () => {
  const { state } = useLocation();
  const [invoiceSettingState, setInvoiceSettingState] = useState([]);
  const [invoiceTemplateData, setInvoiceTemplateData] = useState([]);
  const [invoiceSettingIdState, setInvoiceSettingId] = useState();
  const [deleteField, setdeleteField] = useState();
  let settingPageTitle = "";
  if(state.clickMode === StandardConst.FormMode.Edit){
    settingPageTitle = "Edit Invoice Settings"
  }else if(state.clickMode === StandardConst.FormMode.View){
    settingPageTitle = "View Invoice Settings";
  } else {
    settingPageTitle = "Add Invoice Settings";
  }
  PageInfo({ pageTitle: settingPageTitle });
  const [template, setTemplate] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [allTax, setAllTax] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addEditModalRef = useRef();
  const AddEditFormulaGeneratorModalRef = useRef();
  const [detailsKeyList, setDetailsKeyList] = useState([]);
  const [formulaGeneratorData, setFormulaGeneratorData] = useState([]);
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});

  const fetchAllInvoiceSettings = async (InvoiceSettingId) => {
    await WebService({
      dispatch,
      endPoint: `Invoice/Setting/Header/FetchById/${InvoiceSettingId}`,
    }).then((r) => {
      setInvoiceSettingState(r);
      const detailsArray =  filter(r?.Details, item => item.Section === "Details" || item.Section === "Bottom")
      .map(v => ({
        value: v.FieldName,
        text: v.FieldName
      }));

      // detailsArray.unshift({ value: "", text: "" });

      setDetailsKeyList(detailsArray);
    });
  };
  const fetchAllTemplate = async () => {
    await WebService({ dispatch, endPoint: `Invoice/Template/FetchAll` }).then(
      (r) => {
        setTemplate(
          map(r, (v) => ({
            value: v.TemplateId || "" || null || undefined,
            text: v.TemplateName,
          }))
        );
      }
    );
  };
  const fetchAllCurrency = async () => {
    await WebService({
      endPoint: `Currency/FetchCurrency`,
      
      dispatch,
    }).then((r) => {
      setCurrencyData(
        map(r, (v) => ({
          value: v.CurrencyId || "" || null || undefined,
          text: `${v.Symbol} - ${v.Description}`,
        }))
      );
    });
  };

  const fetchAllTax = async () => {
    await WebService({
      endPoint: `Invoice/TaxMaster/FetchAll`,
      dispatch,
    }).then((r) => {
      setAllTax(
        map(r, (v) => ({
          value: v.TaxId || "" || null || undefined,
          text: `${v.TaxName} - ${v.TaxPercentage}`,
        }))
      );
    });
  };

  const [belowAddBtnShow, setBelowAddBtnShow] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const getAllCompaniesList = async () => {
    await WebService({
      endPoint: `CommonUtility/fetchAll/companyinformation`,
      dispatch,
    }).then((companyList) => {

      const companyListArray = map(companyList, (oneCompany) => ({
        value: oneCompany.CompanyId || "" || null || undefined,
        text: oneCompany.CompanyName,
      }));

      companyListArray.unshift({ value: 0, text: "All Companies" });
      setCompanyList(companyListArray);
    });
  };

  const onSubmit = async (data) => {
    const endPointHeader = state?.InvoiceSettingId == 0
      ? `Invoice/Setting/Header/Insert`
      : `Invoice/Setting/Header/UpdateById/${state?.InvoiceSettingId}`;

    await WebService({
      dispatch,
      endPoint: endPointHeader,
      body: data,
      method: state?.InvoiceSettingId == 0 ? "POST" : "PUT",
    }).then((r) => {
      // state?.InvoiceSettingId == 0
      //   ? navigate(`/InvoiceSettings`)
      //   : setInvoiceSettingId(r);
      setInvoiceSettingId(r);
      fetchAllInvoiceSettings(r);
      fetchInvoiceHtmlFile(r);
      setBelowAddBtnShow(true);
    });
  };

  const fetchInvoiceHtmlFile = async (invoiceSettingId) => {
    if (invoiceSettingId > 0) {
      await WebService({ 
        dispatch, 
        endPoint: `CommonUtility/Edit/invoice_template?InvoiceSettingId=${invoiceSettingId} OR IsReserved=1`
      })
      .then(
        (r) => {
          setInvoiceTemplateData(r);
        }
      );
    }
  };

  const uploadFile = async (fileData) => {

    var fileName = fileData.name.replace(/([A-Z])/g, '-$1').slice(1);

    // Remove all characters after the first dot
    fileName = fileName.split('.')[0];
  
    var AddTemplateData = [];
    AddTemplateData["InvoiceSettingId"] = invoiceSettingIdState;
    AddTemplateData["TemplateName"] = fileName;

    if(fileData.size > 0){
      await WebService({
        endPoint: "upload/File?destination=InvoiceTemplates",
        dispatch,
        body: fileData,
        isFile: true,
      }).then((res) => {
        AddTemplateData['TemplateFile'] = res.filename;
      });
      await WebService({
        endPoint: `Invoice/Template/Insert`,
        method: "POST",
        body: AddTemplateData,
        dispatch,
      }).then((r) => {
        // setdeleteField(r);
        fetchInvoiceHtmlFile(invoiceSettingIdState);
      });
    }
      // data = omit(data, "File");
  };

  const schema = yup.object().shape({
    SettingName: yup.string().trim().required(StandardConst.requiredMessage),
    // InvoicePrefix: yup.string().trim().required(StandardConst.requiredMessage),
    InvoiceNumberFormat: yup.string().trim().required(StandardConst.requiredMessage),
    DueDays: yup.string().trim().required(StandardConst.requiredMessage),
    CurrencyId: yup.string().trim().required(StandardConst.requiredMessage),
  });

  useEffect(() => {
    Promise.all([
      fetchAllInvoiceSettings(state?.InvoiceSettingId),
      setInvoiceSettingId(state?.InvoiceSettingId),
      fetchAllTemplate(),
      fetchAllCurrency(),
      fetchAllTax(),
      fetchInvoiceHtmlFile(state?.InvoiceSettingId),
      fetchInvoiceFormulas(state?.InvoiceSettingId),
    ]);
  }, []);
  // }, [deleteField]);

  useEffect(() => {
    if(Roles[0] === StandardConst.SystemRole.SuperAdmin){
      getAllCompaniesList();
    }
  }, [Roles]);

  const fetchInvoiceFormulas = async (InvoiceSettingId) => {
    const FormulaGeneratorRec = await WebService({
      dispatch,
      endPoint: `CommonUtility/Edit/formulagenerator?InvoiceSettingId=${InvoiceSettingId}`,
      method: "GET",
    })

    setFormulaGeneratorData(FormulaGeneratorRec);
  };

  // const MasterPageName = "Add Invoice Settings";
  const MasterPageName = settingPageTitle;
  const [bData, setBData] = React.useState([
    {
      title: "Invoice Management",
      hrefLink: "#",
    },
    {
      title: "Invoice Settings",
      hrefLink: "#",
    },
  ]);



  const handleRemoveFields = async (InvoiceCustomFieldId) => {

    await WebService({
      endPoint: `CommonUtility/invoice_custom_fields?InvoiceCustomFieldId=${InvoiceCustomFieldId}`,
      method:"DELETE",
      dispatch,
    }).then((r) => {
      setdeleteField(InvoiceCustomFieldId);
    });
    fetchAllInvoiceSettings(invoiceSettingIdState);
    fetchInvoiceHtmlFile(invoiceSettingIdState);
    setInvoiceSettingId(invoiceSettingIdState);
  };

  const handleRemoveTemplate = async(templateId) => {
    await WebService({
      endPoint: `Invoice/Template/DeleteById/${templateId}`,
      method:"DELETE",
      dispatch,
    }).then((r) => {
      setdeleteField(templateId);
      // fetchInvoiceHtmlFile(state?.InvoiceSettingId);
    });
  };

  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));
  const ActionMenu = ({ data, section }) => {
    data ??= null;
    section ??= null;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <React.Fragment>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Add Custom Column">
            <IconButton
              color="inherit"
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <AddCircleIcon sx={{ width: 25, height: 25 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => {
              invoiceSettingState.Section = section;
              fnEdit(0, data);
            }}
          >
            <ListItemIcon>
              <DataSaverOnIcon fontSize="small" />
            </ListItemIcon>
            Add Predefined Columns
          </MenuItem>
          <MenuItem
            onClick={() => {
              invoiceSettingState.Section = section;
              fnEdit(0, data);
            }}
          >
            <ListItemIcon>
              <PlaylistAddCircleIcon fontSize="small" />
            </ListItemIcon>
            Add Custom Columns
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  const fnEdit = async (invoiceCustomFieldId, section, invoiceSettingId="") => {
    var maxOrderNumber = 0;
    const sortedSectionData = filter(invoiceSettingState?.Details, {
      Section: section,
    }).sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder);
    if(sortedSectionData.length > 0){
      maxOrderNumber = sortedSectionData[sortedSectionData.length - 1].SectionWiseDisplayOrder;
    }

    await addEditModalRef.current.openModal(invoiceCustomFieldId || 0, section, maxOrderNumber, invoiceSettingId);
  };

  const fuAddEditFormulaGenerator = async (FieldData, InvoiceSettingId="", FormulaGeneratorId="") => {
    await AddEditFormulaGeneratorModalRef.current.openModal(FieldData, InvoiceSettingId, FormulaGeneratorId);
  }

  const handleRemoveFormulaGeneratorRow = async (FormulaGeneratorId, RemoveIndex) => {
    var removeFromUI = true;
    if(FormulaGeneratorId != "") {

      await WebService({
        dispatch,
        endPoint: `CommonUtility/formulas?FormulaGeneratorId=${FormulaGeneratorId}`,
        method: "DELETE",
      })

      await WebService({
        dispatch,
        endPoint: `CommonUtility/formulaconditions?FormulaGeneratorId=${FormulaGeneratorId}`,
        method: "DELETE",
      })

      await WebService({
        dispatch,
        endPoint: `CommonUtility/formulagenerator?FormulaGeneratorId=${FormulaGeneratorId}`,
        method: "DELETE",
      }).then((rec) => {
      }).catch(err => {
        removeFromUI = false;
      });
    }
    
    if (removeFromUI) {
      const updatedData = [...formulaGeneratorData];
      updatedData.splice(RemoveIndex, 1);
      setFormulaGeneratorData(updatedData);
    }

  }


  const DownloadInvoiceFile = async (fileName) => {
    const invoiceHTMLResponse = await fetch(`${StandardConst.apiBaseUrl}/Invoice/getInvoiceTemplateContents/${fileName}`);
    const invoiceHTMLContent = await invoiceHTMLResponse.text();

    const blob = new Blob([invoiceHTMLContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'download.html';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  const handleFieldsOrdring = async (invoiceSectionData, clickedArrow) => {

    const sectionInvoiceSettingData = filter(invoiceSettingState?.Details, {
      Section: invoiceSectionData.Section,
    })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder);

    const maxOrderNumber = sectionInvoiceSettingData[sectionInvoiceSettingData.length - 1].SectionWiseDisplayOrder;

    const minOrderNumber = sectionInvoiceSettingData[0].SectionWiseDisplayOrder;

    const checkOrder = clickedArrow == StandardConst.ArrowClicked.Up ? invoiceSectionData.SectionWiseDisplayOrder - 1 : invoiceSectionData.SectionWiseDisplayOrder + 1 ;

    if(checkOrder >= minOrderNumber && checkOrder <= maxOrderNumber){
      const clickedRowIndex = sectionInvoiceSettingData.findIndex(item => 
        item.InvoiceCustomFieldId === invoiceSectionData.InvoiceCustomFieldId);

      const nextUpdateIndex = clickedArrow == StandardConst.ArrowClicked.Up ? clickedRowIndex - 1 : clickedRowIndex + 1;
      const nextUpdatInvoiceCustomFieldId = sectionInvoiceSettingData[nextUpdateIndex].InvoiceCustomFieldId;
      const nextUpdatedOrderNumber = sectionInvoiceSettingData[nextUpdateIndex].SectionWiseDisplayOrder;

      await WebService({
        dispatch,
        endPoint: `Invoice/UpdateOrdringInInvoiceColumnFields`,
        method: "PUT",
        body: {
          ClickedUpdatedInvoiceCustomFieldId: invoiceSectionData.InvoiceCustomFieldId, 
          ClickedUpdatedOrderNumber: invoiceSectionData.SectionWiseDisplayOrder, 
          NextUpdatedInvoiceCustomFieldId: nextUpdatInvoiceCustomFieldId, 
          NextUpdatedOrderNumber: nextUpdatedOrderNumber, 
        }
        // body: invoiceSectionData
      }).then((response) => {
        if(response > 0){
          setInvoiceSettingState(prevState => {
            const updatedDetails = [...prevState.Details];
            var firstCheckIndex = updatedDetails.findIndex(item => 
              item.InvoiceCustomFieldId === nextUpdatInvoiceCustomFieldId
            );
    
            updatedDetails[firstCheckIndex].SectionWiseDisplayOrder = invoiceSectionData.SectionWiseDisplayOrder;
    
            var secondCheckIndex = updatedDetails.findIndex(item => 
              item.InvoiceCustomFieldId === invoiceSectionData.InvoiceCustomFieldId
            );
    
            updatedDetails[secondCheckIndex].SectionWiseDisplayOrder = nextUpdatedOrderNumber;
    
            return { ...prevState, details: updatedDetails };
          });
    
        }
      });
    }
  };
  

  return (
    <Container fluid className="base-container">
      <Box
        sx={{
          width: 1,
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
            <div className="ms-4">
              <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
            </div>
          </div>
        </div>
      </Box>
      <div className="p-3 primary-bg-color"></div>
      <div className="mx-4 mt-2">
        {Object.keys(invoiceSettingState).length > 0 && (
          <Form
            defaultValues={invoiceSettingState}
            onSubmit={onSubmit}
            validationSchema={schema}
          >
            <div className="row">
              <div className="col-4" style={{position: "relative", top: "5px"}}>
                <FormInputText
                  name="SettingName"
                  isRequired="true"
                  label="Invoice Template"
                  type="text"
                  value={invoiceSettingState.SettingName}
                />
              </div>
              {/* <div className="col-4">
                <FormInputDropdown
                  name="TemplateId"
                  ddOpt={template ?? []}
                  label="Select HTML File"
                  isRequired="true"
                  value={invoiceSettingState.TemplateId}
                ></FormInputDropdown>
              </div> */}
              <div className="col-4"  style={{position: "relative", top: "5px"}}>
                <FormInputDropdown
                  name="IsShowPayment"
                  ddOpt={StandardConst.YesOrNo}
                  label="Show Payments"
                  value={invoiceSettingState.IsShowPayment}
                ></FormInputDropdown>
              </div>
              <div className="col-4" style={{ position: "relative"}}>
                <FormInputText
                  name="InvoicePrefix"
                  // isRequired="true"
                  label="Invoice Number Prefix"
                  type="text"
                  value={invoiceSettingState.InvoicePrefix}
                />
                {/* <span className="text-danger" style={{position: "absolute", top: "8px", right: "218px"}}>＊</span> */}
              </div>
            </div>
            <div className="row" style={{position: "relative", top: "-10px"}}>
              <div className="col-4" style={{ position: "relative", top: "-8px"}}>
                <FormInputDropdown
                  name="InvoiceNumberFormat"
                  ddOpt={StandardConst.InvoiceNumberFormat}
                  label=" Invoice number format"
                  // isRequired="true"
                  value={invoiceSettingState.InvoiceNumberFormat}
                ></FormInputDropdown>
                  <span className="text-danger" style={{position: "absolute", top: "8px", right: "222px"}}>＊</span>
              </div>
              <div className="col-4" style={{position: "relative", top: "5px"}}>
                <FormInputText
                  name="DueDays"
                  // isRequired="true"
                  label="Payment Due Days"
                  type="text"
                  value={invoiceSettingState.DueDays}
                />
                <span className="text-danger" style={{position: "absolute", top: "8px", right: "200px"}}>＊</span>
              </div>
              <div className="col-4">
                <FormInputDropdown
                  name="CurrencyId"
                  ddOpt={currencyData ?? []}
                  label="Default Currency"
                  isRequired="true"
                  value={invoiceSettingState.CurrencyId}
                ></FormInputDropdown>
              </div>
            </div>
            <div className="row mt-1" style={{ position: "relative", top: "-28px"}}>
              <div className="col-4">
                <FormInputDropdown
                  name="DiscountType"
                  ddOpt={StandardConst.DiscountType}
                  label="Discount Type"
                  value={invoiceSettingState.DiscountType}
                ></FormInputDropdown>
              </div>
              {/* <div className="col-4">
                <FormInputDropdown
                  name="TaxId"
                  ddOpt={allTax}
                  label="Default Tax"
                  isRequired="true"
                  value={invoiceSettingState.TaxId}
                ></FormInputDropdown>
              </div> */}
              {Roles[0] === StandardConst.SystemRole.SuperAdmin && (
              <div className="col-4">
                <FormInputDropdown
                  name="CompanyId"
                  ddOpt={companyList}
                  label="Available For"
                  isRequired="true"
                  value={invoiceSettingState.IsReserved ==  StandardConst.YesNo.Yes ? StandardConst.YesNo.No : invoiceSettingState.CompanyId}
                ></FormInputDropdown>
              </div>
              )}
            </div>
            {(state.clickMode !== StandardConst.FormMode.View) && (
              <>
                <div className="row mt-2">
                  <div className="col-12">
                    <Button
                      variant="outline-primary"
                      type="submit"
                      className="d-flex float-end"
                    >
                      <i className="fa fa-plus-circle me-2 mt-1"></i>
                      {state?.InvoiceSettingId == 0
                        ? "Add Invoice Settings"
                        : "Update Invoice Settings"}
                    </Button>
                  </div>
                </div>
              </>
            )}
            <div className="row mt-1">
              {" "}
              <Alert
                severity="info"
                sx={{ mb: 2 }}
              >
                HTML keys List  
                {`  -  `}

                  <Tooltip title={`Include this key {{CompanyLogo}} in your html file where you want to display Company Logo`}>
                    <label>{`{{${StandardConst.InvoiceLogoUrlKeyName.CompanyLogo}}}, `}&nbsp;</label>
                  </Tooltip>
                  <Tooltip title={`Include this key {{InvoiceNo}} in your html file where you want to display Invoice No`}>
                    <label>{`{{InvoiceNo}}, `}&nbsp;</label>
                  </Tooltip>
                  <Tooltip title={`Include this key {{InvoiceDate}} in your html file where you want to display Invoice Date`}>
                  <label>{`{{InvoiceDate}}, `}&nbsp;</label>
                  </Tooltip>
                  <Tooltip title={`Include this key {{InvoiceDueDate}} in your html file where you want to display Invoice Due Date`}>
                  <label>{`{{InvoiceDueDate}}`}</label>
                  </Tooltip>
                {/* </div> */}
              </Alert>
            </div>
            <div>
            
            </div>

            <div className="my-4">
              <Root>
                <Divider>
                  <span>&nbsp;&nbsp;Invoice Preview&nbsp;&nbsp;</span>
                </Divider>
              </Root>
            </div>
            {invoiceSettingIdState  > 0 && (
              <div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      {" "}
                      <Alert
                        severity="info"
                        action={
                          (state.clickMode !== StandardConst.FormMode.View) && (
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              // invoiceSettingState.Section = "Company";
                              fnEdit(0, StandardConst.InvoiceSectionNames.Company, invoiceSettingState.InvoiceSettingId);
                            }}
                          >
                            <AddCircleIcon fontSize="inherit" />
                          </IconButton>
                          )
                        }
                        sx={{ mb: 2 }}
                      >
                        Company section of invoice
                      </Alert>
                    </div>
                    <div className="row mb-3">
                      {filter(invoiceSettingState?.Details, {
                        Section: "Company",
                      })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder).map((inputField, index) => (
                        <div className="row">
                          <div className="col-12">
                            <div
                              className="row d-flex align-items-center my-1"
                              key={inputField?.InvoiceCustomFieldId}
                            >
                              <div className="col-3 d-flex align-items-center">
                                {inputField?.FieldLabel}

                                {inputField?.FieldIsRequired !== 0 && (
                                  <StarIcon
                                    color="error"
                                    sx={{ fontSize: 10, marginBottom: 1 }}
                                    className="me-2"
                                  />
                                )}
                              </div>
                              <div className="col-2 d-flex align-items-center text-muted">
                                {inputField?.FieldType}
                              </div>

                                <div className="col-5 d-flex align-items-center text-muted">
                                  <Tooltip title={`Include this key {{${inputField?.FieldName}}} in your html file where you want to display ${inputField?.FieldLabel}`}>
                                      <span>{`{{${inputField?.FieldName}}}`}</span>
                                  </Tooltip>
                                </div>
                              <div className="col-2 d-flex justify-content-center align-items-center">
                                {(state.clickMode !== StandardConst.FormMode.View) && (
                                  <>
                                    <IconButton>
                                      <EditIcon
                                        color="info"
                                        sx={{ fontSize: 15 }}
                                        className="mx-1"
                                        onClick={() =>
                                          fnEdit(
                                            inputField?.InvoiceCustomFieldId,
                                            inputField?.Section
                                          )
                                        }
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <DeleteIcon
                                        color="error"
                                        sx={{ fontSize: 15 }}
                                        onClick={() => handleRemoveFields(inputField?.InvoiceCustomFieldId)}
                                      />
                                    </IconButton>
                                  
                                    <IconButton>
                                      <ArrowUpwardIcon
                                        color="info"
                                        sx={{ fontSize: 15 }}
                                        className="mx-1"
                                        onClick={() => handleFieldsOrdring(inputField, "UP")}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <ArrowDownwardIcon
                                        color="info"
                                        sx={{ fontSize: 15 }}
                                        className="mx-1"
                                        onClick={() => handleFieldsOrdring(inputField, "DOWN")}
                                      />
                                    </IconButton>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      {" "}
                      <Alert
                        severity="info"
                        action={
                          (state.clickMode !== StandardConst.FormMode.View) && (
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                fnEdit(0, StandardConst.InvoiceSectionNames.Customer, invoiceSettingState.InvoiceSettingId);
                              }}
                            >
                              <AddCircleIcon fontSize="inherit" />
                            </IconButton>
                          )
                        }
                        sx={{ mb: 2 }}
                      >
                        Customer details in invoice
                      </Alert>
                    </div>
                    <div className="row mb-3">
                      {filter(invoiceSettingState?.Details, {
                        Section: "Customer",
                      })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder).map((inputField, index) => (
                        <div className="row">
                          <div className="col-12">
                            <div
                              className="row d-flex align-items-center my-1"
                              key={inputField?.InvoiceCustomFieldId}
                            >
                              <div className="col-3 d-flex align-items-center">
                                {inputField?.FieldLabel}

                                {inputField?.FieldIsRequired !== 0 && (
                                  <StarIcon
                                    color="error"
                                    sx={{ fontSize: 10, marginBottom: 1 }}
                                    className="me-2"
                                  />
                                )}
                              </div>
                              <div className="col-2 d-flex align-items-center text-muted">
                                {inputField?.FieldType}
                              </div>
                              
                                <div className="col-5 d-flex align-items-center text-muted">
                                  <Tooltip title={`Include this key {{${inputField?.FieldName}}} in your html file where you want to display ${inputField?.FieldLabel}`}>
                                    <span>{`{{${inputField?.FieldName}}}`}</span>
                                  </Tooltip>
                                </div>
                              <div className="col-2 d-flex justify-content-center align-items-center">
                                {(state.clickMode !== StandardConst.FormMode.View) && (
                                  <>
                                    <IconButton>
                                      <EditIcon
                                        color="info"
                                        sx={{ fontSize: 15 }}
                                        className="mx-1"
                                        onClick={() =>
                                          fnEdit(
                                            inputField.InvoiceCustomFieldId,
                                            inputField.Section
                                          )
                                        }
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <DeleteIcon
                                        color="error"
                                        sx={{ fontSize: 15 }}
                                        onClick={() => handleRemoveFields(inputField.InvoiceCustomFieldId)}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <ArrowUpwardIcon
                                        color="info"
                                        sx={{ fontSize: 15 }}
                                        className="mx-1"
                                        onClick={() => handleFieldsOrdring(inputField, "UP")}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <ArrowDownwardIcon
                                        color="info"
                                        sx={{ fontSize: 15 }}
                                        className="mx-1"
                                        onClick={() => handleFieldsOrdring(inputField, "DOWN")}
                                      />
                                    </IconButton>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-5">
                    <div className="row"></div>
                  </div>
                </div>

                <div className="row">
                  {" "}
                  <Alert
                    severity="info"
                    action={
                      (state.clickMode !== StandardConst.FormMode.View) && (
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            fnEdit(0, StandardConst.InvoiceSectionNames.Details, invoiceSettingState.InvoiceSettingId);
                          }}
                        >
                          <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                      )
                    }
                    sx={{ mb: 2 }}
                  >
                    Invoice Details (Repeated Rows)
                  </Alert>
                </div>
                <div className="row mb-3">
                  {filter(invoiceSettingState?.Details, {
                    Section: "Details",
                  })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder).map((inputField, index) => (
                    <div className="col">
                      <div
                        className="row d-flex align-items-center justify-content-center my-1"
                        key={inputField?.InvoiceCustomFieldId}
                      >
                        <div className="h6">
                          {inputField?.FieldLabel}
                          
                          {inputField?.FieldIsRequired !== 0 && (
                            <StarIcon
                              color="error"
                              sx={{ fontSize: 10, marginBottom: 1 }}
                              className="me-2"
                            />
                          )}
                        </div>
                        <div className="text-muted">{inputField?.FieldType}</div>
                        
                          <div className="text-muted">
                            <Tooltip title={`Include this key {{${inputField?.FieldName}}} in your html file where you want to display ${inputField?.FieldLabel}`}>
                              <span>{`{{${inputField?.FieldName}}}`}</span>
                            </Tooltip>
                          </div> 
                          {(state.clickMode !== StandardConst.FormMode.View) && (
                            <>
                              <div>
                                <IconButton>
                                  <EditIcon
                                    color="info"
                                    sx={{ fontSize: 15 }}
                                    className="mx-1"
                                    onClick={() =>
                                      fnEdit(
                                        inputField.InvoiceCustomFieldId,
                                        inputField.Section
                                      )
                                    }
                                  />
                                </IconButton>
                                <IconButton>
                                  <DeleteIcon
                                    color="error"
                                    sx={{ fontSize: 15 }}
                                    onClick={() => handleRemoveFields(inputField.InvoiceCustomFieldId)}
                                  />
                                </IconButton>
                              </div>
                              <div>
                                <IconButton>
                                  <ArrowBackIcon
                                    color="info"
                                    sx={{ fontSize: 15 }}
                                    className="mx-1"
                                    onClick={() => handleFieldsOrdring(inputField, "UP")}
                                  />
                                </IconButton>
                                <IconButton>
                                  <ArrowForwardIcon
                                    color="info"
                                    sx={{ fontSize: 15 }}
                                    className="mx-1"
                                    onClick={() => handleFieldsOrdring(inputField, "DOWN")}
                                  />
                                </IconButton>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {" "}
                  <Alert
                    severity="info"
                    action={
                      (state.clickMode !== StandardConst.FormMode.View) && (
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            fnEdit(0, StandardConst.InvoiceSectionNames.Bottom, invoiceSettingState.InvoiceSettingId);
                          }}
                        >
                          <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                      )
                    }
                    sx={{ mb: 2 }}
                  >
                    Bottom section of invoice
                  </Alert>
                </div>
                <div className="row mb-3">
                  {filter(invoiceSettingState?.Details, {
                    Section: "Bottom",
                  })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder).map((inputField, index) => (
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="row d-flex align-items-center my-1"
                          key={inputField?.InvoiceCustomFieldId}
                        >
                          <div className="col-3 d-flex align-items-center">
                            {inputField?.FieldLabel}

                            {inputField?.FieldIsRequired !== 0 && (
                              <StarIcon
                                color="error"
                                sx={{ fontSize: 10, marginBottom: 1 }}
                                className="me-2"
                              />
                            )}
                          </div>
                          <div className="col-2 d-flex align-items-center text-muted">
                            {inputField?.FieldType}
                          </div>
                          
                            <div className="col-5 d-flex align-items-center text-muted">
                              <Tooltip title={`Include this key {{${inputField?.FieldName}}} in your html file where you want to display ${inputField?.FieldLabel}`}>
                                <span>{`{{${inputField?.FieldName}}}`}</span>
                              </Tooltip>
                            </div>
                          <div className="col-2 d-flex justify-content-center align-items-center">
                            {(inputField?.IsReserved == StandardConst.YesNo.No && state.clickMode !== StandardConst.FormMode.View) && (
                              <>
                                <IconButton>
                                  <EditIcon
                                    color="info"
                                    sx={{ fontSize: 15 }}
                                    className="mx-1"
                                    onClick={() =>
                                      fnEdit(
                                        inputField.InvoiceCustomFieldId,
                                        inputField.Section
                                      )
                                    }
                                  />
                                </IconButton>
                                <IconButton>
                                  <DeleteIcon
                                    color="error"
                                    sx={{ fontSize: 15 }}
                                    onClick={() => handleRemoveFields(inputField.InvoiceCustomFieldId)}
                                  />
                                </IconButton>
                              </>
                            )}
                            {(state.clickMode !== StandardConst.FormMode.View) && (
                              <>
                                <IconButton>
                                  <ArrowUpwardIcon
                                    color="info"
                                    sx={{ fontSize: 15 }}
                                    className="mx-1"
                                    onClick={() => handleFieldsOrdring(inputField, "UP")}
                                  />
                                </IconButton>
                                <IconButton>
                                  <ArrowDownwardIcon
                                    color="info"
                                    sx={{ fontSize: 15 }}
                                    className="mx-1"
                                    onClick={() => handleFieldsOrdring(inputField, "DOWN")}
                                  />
                                </IconButton>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* this is upload HTML File section to upload invoice html file */}
                <div className="row">
                  {" "}
                  <Alert
                    severity="info"
                    sx={{ mb: 2 }}
                  >
                    Upload Invoice HTML File(s)
                  </Alert>
                </div>
                <div className="row mb-3">
                  {(state.clickMode !== StandardConst.FormMode.View) && (
                    <div className="col-md-3">
                        <FormInputFile
                            name="HtmlFile"
                            extensionsAccepted={StandardConst.AcceptExtensionsInUploads.InvoiceHtmlFileExtension}
                            setValue = {(val) =>{
                              uploadFile(val);
                            }}
                        />
                    </div>
                  )}
                  {invoiceTemplateData?.map((template, index) => (
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="row d-flex align-items-center my-1"
                          key={template?.TemplateId}
                        >
                          <div className="col-5 d-flex align-items-center" >
                              {template?.TemplateName}
                              <ActionButton
                                sx={{ fontSize: 15 }}
                                onClick={() => DownloadInvoiceFile(template.TemplateFile)}
                                IconName="Download"
                              />
                          </div>
                          <div className="col-3"></div>
                          <div className="col-2"></div>
                          
                          <div className="col-2 d-flex justify-content-center align-items-center">
                            {(state.clickMode !== StandardConst.FormMode.View) && (
                              <ActionButton
                                sx={{ fontSize: 15 }}
                                onClick={() => handleRemoveTemplate(template.TemplateId)}
                                IconName="Delete"
                                disabled={template.IsReserved == 1 ? true : false}
                                id="btnCalculationComponentDelete"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {" "}
                  <Alert
                    severity="info"

                    action={
                      (state.clickMode !== StandardConst.FormMode.View) && (
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            fuAddEditFormulaGenerator(detailsKeyList, invoiceSettingState.InvoiceSettingId)
                          }}
                        >
                          <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                      )
                    }
                    
                    sx={{ mb: 2 }}
                  >
                    Formula Generator
                  </Alert>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    {formulaGeneratorData?.map((formulaGeneratorRow, formulaGeneratorIndex) => (
                      <div className="row">
                        <div className="col-md-5">
                            <label>{formulaGeneratorRow.FormulaName}</label>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-2"></div>
                        <div className="col-2 d-flex justify-content-center align-items-center">
                          {(state.clickMode !== StandardConst.FormMode.View) && (
                            <>
                              <IconButton>
                                <EditIcon
                                  color="info"
                                  sx={{ fontSize: 15 }}
                                  className="mx-1"
                                  onClick={() =>
                                    fuAddEditFormulaGenerator(detailsKeyList, invoiceSettingState.InvoiceSettingId, formulaGeneratorRow.FormulaGeneratorId)
                                  }
                                />
                              </IconButton>
                              <IconButton>
                                <DeleteIcon
                                  color="error"
                                  sx={{ fontSize: 15 }}
                                  onClick={() => handleRemoveFormulaGeneratorRow(formulaGeneratorRow.FormulaGeneratorId, formulaGeneratorIndex)}
                                />
                              </IconButton>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            )}
          </Form>
        )}
      </div>

      <AddEditFormulaGenerator
        callBackEvent={() => fetchInvoiceFormulas(invoiceSettingIdState)}
        ref={AddEditFormulaGeneratorModalRef}
      >
      </AddEditFormulaGenerator>

      <AddEditInvoiceSection
        callBackEvent={() => fetchAllInvoiceSettings(invoiceSettingIdState)}
        ref={addEditModalRef}
      ></AddEditInvoiceSection>
      {
        ((invoiceSettingIdState || belowAddBtnShow) && (state.clickMode !== StandardConst.FormMode.View)) && (
          <Box textAlign="right" sx={{ margin: '10px' }}>
        <Button variant="outline-primary" className="d-flex float-end" onClick={() => { navigate('/InvoiceSettings')}}>Save Settings</Button>
        </Box>
        ) 

      }
    </Container>
  );
};

export default memo(InvoiceSettingsComponent);

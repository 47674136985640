import React, { memo, useEffect, useRef, useState } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { find } from "underscore";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../Services/ActionButton";
import Cookies from "universal-cookie";

import StaticListComponent from "../../Services/StaticListComponent";
import { StandardConst } from "../../Services/StandardConst";
import { DateTime } from "luxon";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import SnackbarComponent from "../../Services/SnackbarComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import Button from "react-bootstrap/esm/Button";
import jsPDF from "jspdf";
import { dateFormatFunction } from "../../Services/StandardFunctions";
const cookies = new Cookies();
const CustomerInvoiceComponent = () => {
  const [state, setState] = useState({});
  PageInfo({ pageTitle: "Customer Invoice" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editInvoiceRef = useRef();
  const ref = useRef();
  const refSnackbar = useRef();
  const [confirmMessage, setConfirmMessage] = useState(
    "Invoice Deleted successfully"
  );

  const fetchAllInvoice = async () => {
    await WebService({ dispatch, endPoint: `Invoice/Fetch` }).then((r) => {
      setState(r);
    });
  };
  useEffect(() => {
    Promise.all([fetchAllInvoice()]);
  }, []);
  const [jsonData, setJsonData] = useState([]);
  const fnDownloadInvoicePdf = async (invoiceHeaderId) => {

    await WebService({
      dispatch,
      endPoint: `Invoice/FetchAllInvoiceDetails/${invoiceHeaderId}`,
    }).then((recCustomerInvoiceData) => {
      setJsonData(recCustomerInvoiceData);
      handleReadFile(recCustomerInvoiceData.SettingDetails.TemplateFile);
      handleDownloadPdf();
    });
  };

  const [htmlContent, setHtmlContent] = useState("");
  const handleReadFile = async (fileName) => {
    try {
      const HTMLResponse = await fetch(`${StandardConst.apiBaseUrl}/Invoice/getInvoiceTemplateContents/${fileName}`);
      const HTMLContent = await HTMLResponse.text();
      setHtmlContent(HTMLContent);
    } catch (error) {
      console.error("Error fetching HTML file:", error);
    }
  };

  let downloadfileName = "";

  const replaceValues = (content) => {
    let modifiedContent = content;
    
    Object.keys(jsonData).forEach(key => {
      // this is to replace Template Setting information.
      if (key === 'SettingDetails') {
        downloadfileName = jsonData[key].InvoiceNo;
        Object.keys(jsonData[key]).forEach(keyName => {
          modifiedContent = modifiedContent.replace(
            new RegExp(`{{${keyName}}}`, "g"),
            ((keyName == "InvoiceDate" || keyName == "InvoiceDueDate") ? dateFormatFunction(jsonData[key][keyName]) : jsonData[key][keyName])??""
          );
        })
      }

      // this is to replace Company information.
      if (key === StandardConst.InvoiceSectionNames.Company) {
        Object.keys(jsonData[key]).forEach(keyName => {
          jsonData[key].map((cValue) => {
            modifiedContent = modifiedContent.replace(
              new RegExp(`{{${cValue.FieldName}}}`, "g"),
              cValue.Value??""
            );
          })
        })
      }

      if (key === StandardConst.InvoiceSectionNames.Bottom) {
        Object.keys(jsonData[key]).forEach(keyName => {
          jsonData[key].map((cValue) => {
            modifiedContent = modifiedContent.replace(
              new RegExp(`{{${cValue.FieldName}}}`, "g"),
              cValue.Value??""
            );
          })
        })
      }

      // this is to replace Customer information.
      if (key === StandardConst.InvoiceSectionNames.Customer) {
        Object.keys(jsonData[key]).forEach(keyName => {
          jsonData[key].map((cuValue) => {
            modifiedContent = modifiedContent.replace(
              new RegExp(`{{${cuValue.FieldName}}}`, "g"),
              cuValue.Value??""
            );
          })
        })
      }

      // this is replace for Details information.
      if (key === StandardConst.InvoiceSectionNames.Details) {
          //Here we get columns count
          var columnCount = jsonData[key][0]?.InvoiceDetailColumns.length;

          // Here we fill only head on the table.
          for (var i = 0; i < columnCount; i++) {
            modifiedContent = modifiedContent.replace(new RegExp(`{{${jsonData[key][0]?.InvoiceDetailColumns[i].FieldName}${StandardConst.DetailsHeadingSuffix.Heading}}}`, "g"), jsonData[key][0]?.InvoiceDetailColumns[i].Name??"");
          }
            
          // Create a DOMParser
          const parser = new DOMParser();
          // Parse the HTML content
          const doc = parser.parseFromString(modifiedContent, 'text/html');

          const BodyHeading = doc.getElementById("InvoiceDetailRows");
          
          var replaceBody = ``;
          Object.keys(jsonData[key]).forEach(index => {

            var OneDataRow = jsonData[key][index];
            
            if (BodyHeading) {
              var trElement = BodyHeading.outerHTML;
              for (var j = 0; j < columnCount; j++) {
                trElement = trElement.replace(new RegExp(`{{${OneDataRow?.InvoiceDetailColumns[j].FieldName}}}`, "g"), OneDataRow.InvoiceDetailColumns[j]?.Value??"");
              }
              replaceBody += trElement;
            }
          });

          if (BodyHeading) {
            BodyHeading.outerHTML = replaceBody;
            modifiedContent = doc.documentElement.outerHTML;
          }
      }
    });

    return modifiedContent;
};

    const handleDownloadPdf = async() => {
      const doc = new jsPDF({
        format: "a4",
        unit: "px",
      });
      if(htmlContent){
        doc.html(replaceValues(htmlContent), {
          x: 0,
          y: 0,
          async callback(doc) {
            await doc.save(downloadfileName);
          },
          html2canvas: { scale: 0.4 }
        });
      }
    };
  const MasterPageName = "Customer Invoice";
  const [bData, setBData] = React.useState([
    {
      title: "Invoice Management",
      hrefLink: "#",
    },
    {
      title: "Customer Invoice",
      hrefLink: "#",
    },
  ]);

  const fnView = async (InvoiceHeaderId) => {
    navigate(`/GenerateInvoice`, { state: { InvoiceHeaderId } });
  };
  const fnEdit = async (InvoiceHeaderId) => {
    navigate(`/Invoice`, { state: { InvoiceHeaderId } });
  };
  const fnAdd = async (InvoiceHeaderId) => {
    navigate(`/AddInvoice`, { state: { InvoiceHeaderId } });
  };
  const onDelete = async (InvoiceHeaderId) => {
    await WebService({
      endPoint: `Invoice/Delete/${InvoiceHeaderId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchAllInvoice();
  };

  const onSent = async (InvoiceHeaderId) => {
    await WebService({
      endPoint: `Invoice/Header/InvoiceSent/${InvoiceHeaderId}`,
      method: "PUT",
      dispatch,
    });
    setConfirmMessage("Invoice Sent Successfully");
    refSnackbar.current.setOpenSnackBar();
    await fetchAllInvoice();
  };

  const publishInvoice = async (InvoiceHeaderId) => {
    await WebService({
      endPoint: `CommonUtility/invoice_headers?InvoiceHeaderId=${InvoiceHeaderId}`,
      method: "PUT",
      body: {InvoiceStatus: StandardConst.Status.Published},
      dispatch,
    }).then((res) => {
      setConfirmMessage("Invoice Published Successfully");
      refSnackbar.current.setOpenSnackBar();
    });
    await fetchAllInvoice();
  };

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {

    SetPermission({
        InvoiceGenerate: ActionPermission("Invoice - Generate"),
        InvoiceEdit: ActionPermission("Invoice - Edit"),
        InvoiceView: ActionPermission("Invoice - View"),
        InvoiceDelete: ActionPermission("Invoice - Delete"),
        InvoicePrint: ActionPermission("Invoice - Print"),
        InvoiceDownload: ActionPermission("Invoice - Download"),
        InvoiceMail: ActionPermission("Invoice - Mail"),
        InvoicePublish: ActionPermission("Invoice - Download"),
    });
  }, [permissionList]);

  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        records={state}
        columns={[
          {
            Text: "Invoice Number",
            Value: "InvoiceNo",
            render: (dr) => (
              <>
                <span>
                  <i
                    className="fa fa-file-pdf-o text-danger font-size-13 cursor-pointer"
                    onClick={() => fnDownloadInvoicePdf(dr.InvoiceHeaderId)}
                  />
                  &nbsp;
                  {dr.InvoiceNo}{" "}
                </span>
              </>
            ),
          },
          {
            Text: "Customer",
            Value: "CustomerName",
          },

          {
            Text: "Status",
            render: (dr) => (
              <>
                <span>
                  {
                    find(StandardConst.InvoiceStatus, {
                      value: dr.InvoiceStatus,
                    }).text
                  }
                </span>
                {/* <InputDropdown
                  name="InvoiceStatus"
                  ddOpt={StandardConst.InvoiceStatus}
                  value={dr.InvoiceStatus}
                /> */}
              </>
            ),
          },
          {
            Text: "Invoice Date",
            render: (dr) => (
              <>
                {dr.InvoiceDate != null &&
                  DateTime.fromISO(dr.InvoiceDate).toFormat("dd-MM-yy")}
              </>
            ),
          },
          {
            Text: "Due Date",
            render: (dr) => (
              <>
                {dr.InvoiceDueDate != null &&
                  DateTime.fromISO(dr.InvoiceDueDate).toFormat("dd-MM-yy")}
              </>
            ),
          },
          {
            Text: "Sent On",
            render: (dr) => (
              <>
                <span>
                  {dr.SentOn != null &&
                    DateTime.fromISO(dr.SentOn).toFormat("dd-MM-yy")}
                </span>{" "}
                {dr.IsViewed == 1 && (
                  <i className="fa fa-eye text-primary font-size-13" />
                )}
              </>
            ),
          },
          {
            Text: "Total",
            cssClass: "text-end",
            render: (dr) => (
              <>
                {dr.Symbol}
                {dr.TotalAmount}
              </>
            ),
          },
          {
            Text: "Tax",
            cssClass: "text-end",
            render: (dr) => (
              <>
                {dr.Symbol}
                {dr.TaxAmount}
              </>
            ),
          },
          {
            Text: "Net",
            cssClass: "text-end",
            render: (dr) => (
              <>
                {dr.Symbol}
                {dr.NetAmount}
              </>
            ),
          },
          {
            Text: "Action",
            cssClass: "text-end",
            render: (dr) => (
              <>
                {(permission.InvoiceView) && (
                  <ActionButton
                    onClick={() => fnView(dr.InvoiceHeaderId)}
                    disabled={!permission.InvoiceView}
                    IconName="View"
                    sx={{ color: "#9A9A9A" }}
                    id="btnIdCardView"
                  />
                )}
                {(permission.InvoiceEdit) && (
                  <ActionButton
                    onClick={() => fnEdit(dr.InvoiceHeaderId)}
                    disabled={!permission.InvoiceEdit}
                    IconName="Edit"
                    id="btnIdCardView"
                  />
                )}

                {(permission.InvoiceDelete) && (
                  <ActionButton
                    onClick={(e) =>
                      ref.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        "Do you want to delete Invoice", // Message of Alert
                        dr.InvoiceHeaderId // Endpoint to hit for delete
                      )
                    }
                    disabled={!permission.InvoiceDelete}
                    IconName="Delete"
                    id="btnProjectDelete"
                  />
                )}
                {/* {(permission.InvoiceMail) && (
                  <ActionButton
                    onClick={() => onSent(dr.InvoiceHeaderId)}
                    disabled={!permission.InvoiceMail}

                    IconName="EmailResend"
                    id="btnIdCardView"
                  />
                )} */}
                {(dr.InvoiceStatus !== StandardConst.Status.Published && permission.InvoicePublish) && (
                  <ActionButton
                    onClick={() => publishInvoice(dr.InvoiceHeaderId)}
                    disabled={!permission.InvoicePublish}
                    IconName="Publish"
                    id="btnIdInvoicePublish"
                  />
                )}
              </>
            ),
          },
        ]}
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderValueStandard}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.InvoiceGenerate && (
                  <Button id="NoRecordFoundAddCustomerInvoice" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {MasterPageName}
                  </Button>
                )}
              </>
            }
          />
        }
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        isSearchRequired={true}
        onAddEvent={() => fnAdd()}
        IsAddButtonVisible={permission?.InvoiceGenerate}
        allowSerialNo={true}
      ></StaticListComponent>
    </>
  );
};

export default memo(CustomerInvoiceComponent);

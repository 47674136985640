import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Container from "react-bootstrap/esm/Container";
import { useDispatch, useSelector } from "react-redux";
import { map, sortBy } from "underscore";
import { WebService } from "../../../Services/WebService";
import IconButton from "@mui/material/IconButton";
import { Delete, Edit } from "@mui/icons-material";
import { DateTime } from "luxon";
import Topbar from "../../../Services/TopbarComponent";
import { pink } from "@mui/material/colors";
import TableComponent from "../../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../../PageInfo";
import { NoRecordTemplate } from "../../../Services/TableComponent";
import { StandardConst } from "../../../Services/StandardConst";
import { useNavigate } from "react-router-dom";
import SnackbarComponent from "../../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../../Services/AlertComponent";
import DisplayNumberFormatComponent from "../../../Services/DisplayNumberFormatComponent";
import { WSSuccessAlert } from "../../../Services/WSAlert";

const SalaryPackage = () => {
  const MasterPageName = "Employee Package List";
  PageInfo({ pageTitle: MasterPageName });

  const refPackageList = useRef();
  const [bData, setBData] = React.useState([
    {
      title: "Payroll Management",
      hrefLink: "#",
    },
    {
      title: MasterPageName,
      hrefLink: "#",
    },
  ]);

  return (
    <>
      <Container fluid className="base-container">
        <Topbar bData={bData} HeadingText={MasterPageName}></Topbar>
        <PackageList ref={refPackageList} />
      </Container>
    </>
  );
};

const PackageList = forwardRef(({}, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const afterRender = useRef(false);
  const refSnackbar = useRef();
  const refDelete = useRef();

  const endPoint = "EmployeePackage";
  const confirmMessage = " Deleted successfully";

  const [dataSet, setDataSet] = useState([]);
  const [permission] = useState({
    ManageAdd: ActionPermission("Salary Package - Add"),
    ManageEdit: ActionPermission("Salary Package - Edit"),
  });
  const fetchList = () => {
    WebService({ endPoint, dispatch }).then((d) => {
      d = map(d, (m) => {
        if (m.ToDate !== null) {
          if (DateTime.fromISO(m.ToDate) < DateTime.fromISO(m.FromDate)) {
            m.ToDate = m.FromDate;
          }
        }
        return m;
      });
      setDataSet(sortBy(sortBy(d || [], "EmpName"), "FromDate").reverse());
    });
  }

  const fnDelete = (id) => {
    WebService({
      endPoint: `${endPoint}/${id}`,
      method: "DELETE",
      dispatch,
    }).then((_) => {
      WSSuccessAlert("Success", "Employee package deleted successfully");
      fetchList()
    });
  };
  useEffect(() => {
    if (!afterRender.current) {
      fetchList();
    }
    afterRender.current = true;
  }, []);
  
  useImperativeHandle(ref, () => ({
    fetchList,
  }));

  var columns = [
    {
      Text: "Person",
      Value: "EmpName",
    },
    {
      Text: "Package Start Date",
      DateFormat: "yyyy-MM-dd",
      Value: "FromDate",
    },
    {
      Text: "Package End Date",
      DateFormat: "yyyy-MM-dd",
      Value: "ToDate",
    },
    {
      Text: "Salary Structure",
      Value: "TemplateName",
    },
    {
      Text: "Payment Frequency",
      Value: "SalaryPaymentFrequency",
    },
    {
      Text: "CTC",
      Value: "CTC",
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <DisplayNumberFormatComponent Number={dr.CTC} Currency={dr.Symbol} />
            </div>
          </>
        )
      }
    },
    {
      Text: "Total Earning / Month",
      Value: "TotalEarning",
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <DisplayNumberFormatComponent Number={dr.TotalEarning} Currency={dr.Symbol} />
            </div>
          </>
        )
      }
    },
    {
      Text: "Total Deduction / Month",
      Value: "TotalDeduction",
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <DisplayNumberFormatComponent Number={dr.TotalDeduction} Currency={dr.Symbol} />
            </div>
          </>
        )
      }
    },
    {
      Text: "Action",
      render: (obj) => (
        <>
          {DateTime.fromISO(obj.FromDate) <= DateTime.now() && (
            <>
              <div className="d-flex">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => navigate('/AddEditEmpSalaryPackage', { state : { EmployeePackageId: obj.EmployeePackageId } })}
                >
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() =>
                    refDelete.current.confirmAlert(
                      "Delete", //Confirm button text
                      "Are You Sure", // Text if Alert
                      "Do you want to delete ", // Message of Alert
                      obj.EmployeePackageId
                    )
                  }
                  size="small"
                  sx={{ color: pink[500] }}
                  // onClick={() => fnDelete(obj.EmployeePackageId)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </div>
            </>
          )}
        </>
      ),
    },
  ];


  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={refDelete} confirmEvent={(v) => fnDelete(v)} />
      <TableComponent
        columns={columns}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.FeedEmployeeSalaryheaderValueNoResults}
            subHeaderValue={StandardConst.FeedEmployeeSalarySubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
          />
        }
        data={dataSet}
        onAddEvent={() => navigate('/AddEditEmpSalaryPackage')}
        IsAddButtonVisible={true}
        isSearchRequired={true}
        allowSerialNo={true}
      />
    </>
  );
});

export default SalaryPackage;

import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import _, {  } from "underscore";
import CompanyInfoWide from "../../Services/CompanyInfoWide";
import Cookies from "universal-cookie";
import DisplayNumberFormatComponent from "../../Services/DisplayNumberFormatComponent";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { format } from "date-fns/esm";
const boxCommonStyles = {
  "& > :not(style)": {
    m: 1,
  },
};
const commonStyles = {
  border: 1,
  borderBottom: 1,
  borderColor: "#A6A6A6",
  p: 2,
};
const ReportTemplate = (props) => {

  const renderCustomField = (item, Symbol) => {
    if(item.FieldValue !== null){
      if (item.FieldType === StandardConst.dataTypes.Date) {
        return format(new Date(item.FieldValue), "yyyy-MM-dd");
      } 
      else if (item.FieldType === StandardConst.dataTypes.Currency) {
        return (
          <DisplayNumberFormatComponent
            Number={item.FieldValue}
            Currency={Symbol}
          />
        );
      } 
      else {
        return item.FieldValue;
      }
    }else{
      return "-";
    }
    
  }

  return (
    <>
      {(props.data.StatusId === StandardConst.Status.Published) ? (
        <iframe src={`${StandardConst.payslipPDF}/${props.data.PublishedPayslipPath}`} title="PDF Viewer" style={{ width: "100%", height: "610px" }}></iframe>
      ): (
        <>
          <Row className="text-center">
            <CompanyInfoWide section1={3} section2={9} isMobileVisible={true} />
          </Row>
          <Box
            sx={{
              ...boxCommonStyles,
            }}
          >
            <Paper
              square
              elevation={0}
              sx={{
                ...commonStyles,
                p: 0,
              }}
            >
              
                Payslip for the Month of {props.data.PayMonth}
              <div className="p-2">
                <Row>
                  <Col md={6}>
                    <Row className="square border-end">
                      <Col className="col-6">Name</Col>
                      <Col className="col-6">{props.data.FullName}</Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col className="col-6">Employee Code</Col>
                      <Col className="col-6">{props.data.EmployeeCode}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row className=" square border-end">
                      <Col className="col-6">Joining Date</Col>
                      <Col className="col-6">{props.data.DOJ}</Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col className="col-6">Bank Name</Col>
                      <Col className="col-6">{props.data.BankName}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row className=" square border-end">
                      <Col className="col-6">Designation</Col>
                      <Col className="col-6">{props.data.Designation}</Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col className="col-6">Bank Account No</Col>
                      <Col className="col-6 text-break">{props.data.BankAccountNo}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row className=" square border-end">
                      <Col className="col-6">Department</Col>
                      <Col className="col-6">{props.data.DepartmentName !== null ? props.data.DepartmentName : "-"}</Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className=" square border-end">
                      <Col className="col-6">Location</Col>
                      <Col className="col-6">{props.data.Location}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row className=" square border-end">
                      <Col className="col-6">Effective Days</Col>
                      <Col className="col-6">{props.data.TotalWorkingDays}</Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className=" square border-end">
                      <Col className="col-6">Leave without Pay</Col>
                      <Col className="col-6">0</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col className="col-6">Month</Col>
                      <Col className="col-6">{props.data.PayMonth}</Col>
                    </Row>
                  </Col>
                  {props.data.BankDetailCustomFieldData.map(item => 
                  <Col md={6}>
                    <Row>
                      <Col className="col-6">{item.FieldLabel}</Col>
                      <Col className="col-6">{renderCustomField(item, props.data.Symbol)}</Col>
                    </Row>
                  </Col>)}
                  {props.data.EmployeePayslipCustomFieldData.map(item => <Col md={6}>
                    <Row>
                      <Col className="col-6">{item.FieldLabel}</Col>
                      <Col className="col-6">{renderCustomField(item, props.data.Symbol)}</Col>
                    </Row>
                  </Col>)}
                </Row>
              </div>
            </Paper>
          </Box>
          <Box
            sx={{
              ...boxCommonStyles,
            }}
          >
            <Paper
              square
              elevation={0}
              sx={{
                ...commonStyles,
                p: 0,
              }}
            >
              {" "}
              <div className="container">
                <Row>
                  <Col md={6} className="p-0">
                    {/* <Alert severity="info" sx={{}}> */}
                      Earnings
                    {/* </Alert> */}
                    <div className="p-2">
                      <Row className="square border-end">
                        <Col>
                          {_.map(
                            _.where(props.data.Components, {
                              EarningOrDeductionType: "Earning",
                            }),
                            (m) => (
                              <>
                                <Row>
                                  <Col className="col-7">
                                    {m.EarningOrDeductionName}
                                  </Col>
                                  <Col className="col-5 text-end">
                                    <DisplayNumberFormatComponent
                                      Number={m.CalculatedAmount}
                                      Currency={props.data.Symbol}
                                    />
                                  </Col>
                                </Row>
                              </>
                            )
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6} className="p-0">
                    {/* <Alert severity="info" sx={{}}> */}
                      Deduction
                    {/* </Alert> */}
                    <div className="p-2">
                      <Row className="square">
                        <Col>
                          {_.map(
                            _.where(props.data.Components, {
                              EarningOrDeductionType: "Deduction",
                            }),
                            (m) => (
                              <>
                                <Row>
                                  <Col className="col-7">
                                    {m.EarningOrDeductionName}
                                  </Col>

                                  <Col className="col-5 text-end">
                                  <DisplayNumberFormatComponent
                                      Number={m.CalculatedAmount}
                                      Currency={props.data.Symbol}
                                    />
                                  </Col>
                                </Row>
                              </>
                            )
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Paper>
          </Box>
          {/* <Box
            sx={{
              ...boxCommonStyles,
            }}
          >
            <Paper
              square
              elevation={0}
              sx={{
                ...commonStyles,
              }}
            >
              <Alert severity="info" sx={{}}>
                Earnings
              </Alert>
              <Row>
                <Col>
                  {_.map(
                    _.where(props.data.Components, {
                      EarningOrDeductionType: "Earning",
                    }),
                    (m) => (
                      <>
                        <Row>
                          <Col className="col-8">{m.EarningOrDeductionName}</Col>
                          <Col className="col-4 text-end">
                            <DisplayNumberFormatComponent
                              Number={m.CalculatedAmount}
                            />
                          </Col>
                        </Row>
                      </>
                    )
                  )}
                </Col>
              </Row>

              <Row>
                <Alert severity="info" sx={{}}>
                  Deduction
                </Alert>
                <Col>
                  {_.map(
                    _.where(props.data.Components, {
                      EarningOrDeductionType: "Deduction",
                    }),
                    (m) => (
                      <>
                        <Row>
                          <Col className="col-8">{m.EarningOrDeductionName}</Col>

                          <Col className="col-4 text-end">
                            <DisplayNumberFormatComponent
                              Number={m.CalculatedAmount}
                            />
                          </Col>
                        </Row>
                      </>
                    )
                  )}
                </Col>
              </Row>
            </Paper>
          </Box> */}

          <Box
            sx={{
              ...boxCommonStyles,
            }}
          >
            <Paper
              square
              elevation={0}
              sx={{
                ...commonStyles,
              }}
            >
              <Row className="p-0">
                <Col className="p-1 col-md-6 col-12 square border-end">
                  <Row>
                    <Col className="col-md-8 col-7">
                      {/* <strong> */}
                        Total Earnings :
                      {/* </strong> */}
                    </Col>
                    <Col className="text-end col-md-4  col-5">
                      <strong>
                        <DisplayNumberFormatComponent
                          Number={props.data.GrossSalary}
                          Currency={props.data.Symbol}
                        />
                      </strong>
                    </Col>
                  </Row>
                </Col>
                <Col className="p-1 col-md-6 col-12">
                  <Row>
                    <Col className="col-md-8  col-7">
                      {/* <strong> */}
                        Total Deductions :
                      {/* </strong> */}
                    </Col>
                    <Col className="col-md-4 text-end  col-5">
                      <strong>
                        <DisplayNumberFormatComponent
                          Number={props.data.TotalDeductions}
                          Currency={props.data.Symbol}
                        />
                      </strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="p-1 fw-bold square  col-md-6 col-7">
                  Net pay for the month:
                </Col>
                <Col className="p-1 text-end col-md-6 col-5">
                  <span>
                    <strong>
                      <DisplayNumberFormatComponent Number={props.data.NetSalary} Currency={props.data.Symbol}/>
                    </strong>
                  </span>
                </Col>
              </Row>
            </Paper>
          </Box>
        </>
      )}
    </>
  );
};

export default ReportTemplate;

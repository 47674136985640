import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import Button from "react-bootstrap/Button";
import { PageInfo } from '../PageInfo';
import { useDispatch, useSelector } from "react-redux"
import { WebService } from '../../Services/WebService';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { StandardConst } from '../../Services/StandardConst';
import AddEditEventAttributes from './AddEditEventAttributes';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { useLocation } from "react-router-dom";
import ActionButton from '../../Services/ActionButton';



const ManageEventsAttributes = () => {

    const refSnackbar = useRef();
    const ref = useRef();
    const addEditModalRef = useRef();
    const MasterPageName = "Manage Event Attributes";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "Event Attribute";
    const dispatch = useDispatch();
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Manage Event Attributes" });
    const {state } = useLocation();
    const permissionList = useSelector((s) => s.auth.PermissionList??[]);
    const [permission, SetPermission] = useState({});
    useEffect(() => {
    SetPermission({
      ManageAdd: true, // ActionPermission("Visitor Type - Add"),
      ManageEdit: true, // ActionPermission("Visitor Type - Edit"),
      ManageDelete: true, // ActionPermission("Visitor Type - Delete"),
    });
    }, [permissionList]);


    const fetchEventAttributesList = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/eventattributes?EventId=${state.EventId}`,
            dispatch,
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect( () => {
        fetchEventAttributesList();
    }, []);

    const onDelete = async (EventAttributeId) => {
        await WebService({
            endPoint: `CommonUtility/eventattributes?EventAttributeId=${EventAttributeId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchEventAttributesList();
    };

    const columns = [
        {
            Text: "Attribute",
            Value: "Attribute"
        },
        {
            Text: "Description",
            Value: "Description"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                <ActionButton
                    onClick={() => fnEdit(dr.EventAttributeId)}
                    disabled={!permission.ManageEdit}
                    IconName="Edit"
                    IconTooltip={"Edit Event"}
                    id={`btnEventsEdit_${dr.EventId}`}
                />
                <ActionButton
                    onClick={(e) =>
                    ref.current.confirmAlert(
                      "Delete", //Confirm button text
                      "Are You Sure", // Text if Alert
                      "Do you want to delete " + dr.Attribute, // Message of Alert
                      dr.EventAttributeId  // Endpoint to hit for delete
                    )}
                    disabled={!permission.ManageDelete}
                    IconName="Delete"
                    IconTooltip={"Delete Event"}
                    id={`btnEventsDelete_${dr.EventId}`}
                />
                </>
            ),
        },

    ]

    const [bData, setBData] = React.useState([
        {
            title: "Manage Events",
            hrefLink: "/ManageEvents",
        },
        {
            title: "Manage Events Attribute",
            hrefLink: "#",
        },
    ]);

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.ManageEventAttributesheaderValueNoResults}
                        subHeaderValue={StandardConst.ManageEventAttributesSubHeaderRole}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                                    Add {AddButtonText}
                                    </Button>
                                )}
                                </>
                            }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditEventAttributes 
                ref={addEditModalRef}
                callBackEvent={async () => await fetchEventAttributesList()}
                EventId={state.EventId}
            />
    </>
    )
}

export default ManageEventsAttributes
import * as yup from "yup";
import PropTypes from "prop-types";
import useAutocomplete from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import React, { memo, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { WebService } from "../../Services/WebService";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import { Form, FormAutoCompleteDropdownCard, FormInputDropdown, FormInputText } from "../Form";
import Button from "react-bootstrap/esm/Button";
import { DateTime } from "luxon";
import { Box, Container } from "@mui/material";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { PageInfo } from "../PageInfo";
import { WSSuccessAlert } from "../../Services/WSAlert";

//Refer from https://mui.com/material-ui/react-autocomplete/
const Root = styled("div")(
  ({ theme }) => `
  `
);
const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
`;

const CreateNotificationComponent = () => {
  var resetValue = {
    SendTo: "AllEmployees",
    Message: null,
    Expire: DateTime.now().endOf("day").toJSDate()
  };
  var frmRef = useRef();
  var dispatch = useDispatch();
  var [state, setState] = useState({
    data: resetValue,
  });

  const [EmployeesList, setEmployeesList] = useState([]);

  var fetchEmployeeList = () => {
    WebService({
      dispatch,
      endPoint: `Visitor/fetchAllEmployees`,
    }).then((r) => {
      setState((s) => ({ ...s, Employees: r }));
      const emplist = r?.filter((r) => r.FullName !== null)?.map((res) => {
        return {
            ...res,
            EmployeeId: res.EmployeeId,
            heading: res.FullName,
            description: res.Designation,
            avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
        }
      });
      setEmployeesList(emplist);
    });
  }
  useEffect(() => {
    fetchEmployeeList();
  }, []);

  const onSubmit = async (data) => {
    data.Expire = DateTime.fromJSDate(data.Expire).endOf("day").toJSDate();
    await WebService({
      dispatch,
      endPoint: "Notification/Create",
      body: {
        Message: data.Message,
        SendTo: (data.SendTo === "AllEmployees" ? state.Employees : data.EmployeeId).map(
          (m) => m.EmployeeId
        ),
        Expire: data.Expire,
      },
    });
    frmRef.current.fnReset();
    WSSuccessAlert("Success", "Notification created successfully.");
    setState((s) => ({ ...s, data: resetValue }));
  };

  const schema = yup.object().shape({
    Message: yup
      .string()
      .trim()
      .label("Message")
      .required(StandardConst.requiredMessage),
    Expire: yup
      .date()
      .label("Expire")
      .typeError(StandardConst.requiredMessage)
      .required(StandardConst.requiredMessage),
  });
  const [bData, setBData] = React.useState([
    {
      title: "Notifications",
      hrefLink: "#",
    },
    {
      title: "Create Notifications",
      hrefLink: "#",
    },
  ]);
  PageInfo({ pageTitle: "Create Notifications" });
  const MasterPageName = "Create Notifications";

  return (
    <>
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h3 className="ms-4 mt-2">{MasterPageName}</h3>
              <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
            </div>
          </div>
        </Box>
        <div elevation={0} className="p-3 bg-primary"></div>
        <div className="mx-4 mt-2">
          <Form
            defaultValues={state?.data}
            onSubmit={onSubmit}
            validationSchema={schema}
            ref={frmRef}
          >
            <div className="row">
              <div className="col-12">
                <span>Enter Message</span>
              </div>
              <div className="col-12">
                <FormInputText name="Message" as="textarea" rows={10} />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="col-12">
                  <Label>Expire After</Label>
                </div>
                <div className="col-12">
                  <FormInputText
                    type="date"
                    name="Expire"
                    min={DateTime.now().toSQLDate()}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="col-12">
                  <Label>Will Deliver To</Label>
                </div>
                <div className="col-12">
                  <FormInputDropdown
                    name="SendTo"
                    ddOpt={[
                      {
                        value: "AllEmployees",
                        text: "All Employees"
                      },
                      {
                        value: "SpecificEmployees",
                        text: "Specific Employees",
                      },
                    ]}
                    setValue={(v) =>
                      setState((s) => ({
                        ...s,
                        data: { ...s.data, SendTo: v },
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            {state.data.SendTo === "SpecificEmployees" && (
              <Root className="my-1">
                <div className="row">
                  <div className="col-12">
                    <span>Select Employee</span>
                  </div>
                  <div className="col-12">
                    <FormAutoCompleteDropdownCard
                      name="EmployeeId"
                      data={EmployeesList}
                      // label="Select Employee"
                      optionText="heading"
                      // placeholder="Choose a Employee"
                      labelCss="text-dark"
                    />
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12">
                    <FormAutoCompleteDropdownCard
                      name="EmployeeId"
                      data={EmployeesList}
                      label="Select Employee"
                      optionText="heading"
                      placeholder="Choose a Employee"
                      labelCss="text-dark"
                    />
                  </div>
                </div> */}
              </Root>
            )}
            <div className="row mt-2 pb-3">
              <div className="col-12">
                <Button
                  variant="outline-primary"
                  type="submit"
                  className="d-flex float-end"
                >
                  <i className="fa fa-send me-2 mt-1"></i> Send Notification
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default memo(CreateNotificationComponent);

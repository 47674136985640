import React, { memo, useRef } from "react";
import { Form, FormInputText } from "../Form";
import { StandardConst } from "../../Services/StandardConst";
import { DateTime } from "luxon";
import * as yup from "yup";
import Button from "react-bootstrap/esm/Button";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const SuperAdminNotificationComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuList = useSelector((state) => state.auth.MenuList);

  // This code for check this page have permission to see this login person or not
  const currentURL = location.pathname.substring(1); // or location.href if you need the full URL
  if(menuList.length > 1) {
    if(!menuList.some(menuList => menuList.Route === currentURL)){
      navigate("/Dashboard");
    }
  }

  const dispatch=useDispatch();
  const frmSignupRef=useRef();
  const onSubmit = async (body) => {
    body.Expire = DateTime.fromJSDate(body.Expire).endOf("day").toJSDate();
    await WebService({ 
        dispatch, 
        endPoint: `Notification/Create`,
        body
    });
    await frmSignupRef.current.fnReset((s) => ({
      ...s,
      Message: null,
      Expire: null,
    }));
  };
  const schema = yup
    .object()
    .shape({
      Message: yup
        .string()
        .trim()
        .label("Message")
        .required(StandardConst.requiredMessage),
      Expire: yup
        .date()
        .label("Expire")
        .typeError(StandardConst.requiredMessage)
        .required(StandardConst.requiredMessage),
    })
    .required();
  return (
    <>
      <Form onSubmit={onSubmit} validationSchema={schema} ref={frmSignupRef}>
        <FormInputText name="Message" label="Message" as="textarea" rows={3} />
        <FormInputText
          type="date"
          name="Expire"
          label="Expire"
          min={DateTime.now().toSQLDate()}
        />
        <Button variant="outline-primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default memo(SuperAdminNotificationComponent);

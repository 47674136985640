import Modal from "react-bootstrap/Modal";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { WebService } from "../../../Services/WebService";
import { useDispatch } from "react-redux";
import TableComponent from "../../../Services/TableComponent";
import { findIndex } from "underscore";
import { CheckRadioInput } from "../../Form";

const AssignStandardKRAToJobFunctionComponent = ({}, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [kraList, setKraList] = useState(false);
  const [jobFunction, setJobFunction] = useState(false);
  useImperativeHandle(ref, () => ({
    openModal: async (JobFunctionId) => {
      const p1 = WebService({
        dispatch,
        endPoint: `JobFunction/StandardKRA/${JobFunctionId}`,
      }).then((r) => setKraList(r));
      const p2 = WebService({
        dispatch,
        endPoint: `CommonUtility/staticjobfunctions?where=JobFunctionId eq ${JobFunctionId}`,
      })
        .then((r) => (r.length > 0 ? r[0] : {}))
        .then((r) => setJobFunction(r));
      setShow(true);
      await Promise.all([p1, p2]);
    },
  }));
  const handleClose = () => setShow(false);
  const fnAssign = async (dr, isSelected) => {
    var index = findIndex(kraList, { KRAId: dr.KRAId });
    kraList[index].Selected = isSelected ? 1 : 0;
    var Opt = {
      endPoint: `CommonUtility/standardkraforjobfunction`,
      dispatch
    };
    if (isSelected) {
      Opt.body = {
        JobFunctionId: jobFunction.JobFunctionId,
        KRAId: dr.KRAId,
      };
    } else {
      Opt.method = "DELETE";
      Opt.endPoint += `?JobFunctionId=${jobFunction.JobFunctionId}&KRAId=${dr.KRAId}`;
    }
    var p1 = WebService(Opt)
      .then(() => {
        setKraList(kraList);
      })
      .catch(() => {
        kraList[index].Selected = isSelected ? 0 : 1;
        setKraList(kraList);
      });
    Promise.all([p1]);
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign KRA to {jobFunction.JobFunction}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <TableComponent
            isSearchRequired={true}
            columns={[
              { Text: "Short Description", Value: "KRAShortDescription" },
              { Text: "Description", Value: "KRADescription" },
              {
                Text: "Assigned",
                render: (dr) => (
                  <>
                    <CheckRadioInput
                      isRadio={false}
                      value={dr.Selected === 1}
                      setValue={(v) => fnAssign(dr, v)}
                    />
                  </>
                ),
              },
            ]}
            data={kraList}
            allowSerialNo={true}
          ></TableComponent>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default forwardRef(AssignStandardKRAToJobFunctionComponent);

import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, Box, AppBar, Alert, ToggleButtonGroup, ToggleButton, Autocomplete, TextField, Grid, Chip, CardHeader, IconButton, Avatar } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { StandardConst } from '../../Services/StandardConst';
import { Form, FormCheckRadioInput, FormInputDropdown, FormInputFile, FormInputText, InputDropdown, InputText } from '../Form';
import Button from "react-bootstrap/Button";
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from '../../Services/ActionButton';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import DescriptionIcon from '@mui/icons-material/Description';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { chunk, map, range } from "underscore";
import QRCodeGeneratorDownloadComponent from '../../Services/QRCodeGeneratorDownloadComponent';
import { WSErrorAlert, WSSuccessAlert } from '../../Services/WSAlert';
import BootstrapModal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import * as yup from "yup";
import { endOfDay, format } from 'date-fns';
import CurrencyInput from 'react-currency-input-field';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate } from 'date-fns';
import JobViewModal from './JobViewModal';
import { CurrencyFormat } from '../../Services/DisplayCurrencyFormat';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const JobApplicantProfile = () => {
    PageInfo({ pageTitle: "Applicant Profile" });

    const refJobViewModal = useRef();
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});

    const [jobApplicantDetailsData, setJobApplicantDetailsData] = useState({});

    const [bData] = useState([
        {
            title: "Recruitment",
            hrefLink: "#",
        },
        {
            title: "Applicant Profile",
            hrefLink: "#",
        },
    ]);

    const getJobApplicantData = async (JobApplicantId) => {
        await WebService({
            endPoint: `Recruitment/fetchJobApplicantDetails?JobApplicantId=${JobApplicantId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? setJobApplicantDetailsData(res[0]) : null);
    }

    const [jobApplyData, setJobApplyData] = useState([]);

    const getJobApplyData = async (JobApplicantId) => {
        await WebService({
            endPoint: `Recruitment/fetchJobApplyDataForApplicant?JobApplicantId=${JobApplicantId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? setJobApplyData(res) : null);
    }
    
    useEffect(() => {
        if(state?.JobApplicantId){
            getJobApplicantData(state?.JobApplicantId);
            getJobApplyData(state?.JobApplicantId);
        }
    }, [state?.JobApplicantId]);

    const loggedEmployeeId = useSelector((s) => s.auth.LoggedUser ?? 0);
    const loggedUserId = useSelector((s) => s.auth.LoggedCustomer ?? 0);

    const [noteData, setNoteData] = useState({})

    const getNoteData = async (JobApplicationId) => {
        let EndPoint = `CommonUtility/Edit/jobapplicationsnotes?JobApplicationId=${JobApplicationId}&EmployeeId=${loggedEmployeeId}`;
        if(loggedUserId > 0){
            EndPoint = `CommonUtility/Edit/jobapplicationsnotes?JobApplicationId=${JobApplicationId}&UserId=${loggedUserId}`;
        }
        await WebService({
            endPoint : EndPoint,
            method : "GET"
        }).then(res => res.length ? setNoteData(res[0]) : null);
    };


    useEffect(() => {
        if(state?.JobApplicationId){
            getNoteData(state?.JobApplicationId);
        }
    }, [state?.JobApplicationId, loggedEmployeeId, loggedUserId]);

    const onNoteSubmit = async (data) => {
        data.JobApplicationId = state?.JobApplicationId;

        await WebService({
            endPoint: `Recruitment/jobApplicationAddNote`,
            body: data ,
            method: 'POST',
            dispatch
        }).then(msg => {
            WSSuccessAlert('Success', msg);
        })
    };
    
    const schema = yup.object().shape({
        Note: yup.string().trim().label("Note").required(),
    });

    return (
        <>
            <Container
                fluid
                className="base-container"
                style={{minHeight: '800px'}}
            >
                {(Roles.length > 0) && (
                    <Topbar bData={bData} HeadingText="Applicant Profile"></Topbar>
                )}

                <div className="container bg-primary p-3 d-flex justify-content-end">
                    {/* <Button onClick={() => navigate("/AddEditJobPosting")}>Add Job Post</Button> */}
                </div>

                <div className='h-100' style={{backgroundColor: '#f4f8f9'}}>
                    <div className='m-4'>
                        {(Object.keys(jobApplicantDetailsData).length > 0) && (
                            <div className="row">
                                {/* Left div */}
                                <div className="col-md-3">
                                    <div className="bg-white m-0 p-3 h-100">
                                        <div className='p-1'>
                                            <div className="p-1 d-flex justify-content-center align-items-center">
                                                <Avatar
                                                    alt={jobApplicantDetailsData?.FullName}
                                                    src={`${StandardConst.uploadImages}${
                                                        jobApplicantDetailsData?.ProfileImage ?? "user_placeholder.jpg"
                                                    }`}
                                                    sx={{ width: 100, height: 100 }}
                                                />
                                            </div>
                                        
                                            <span className='text-primary d-flex justify-content-center align-items-center'><b>{jobApplicantDetailsData?.FullName}</b></span>
                                            <span className='text-muted d-flex justify-content-center align-items-center' style={{fontSize: "12px"}}><b>{jobApplicantDetailsData?.Position}</b></span>
                                        </div>

                                        {(jobApplicantDetailsData?.Description !== null) && (
                                            <div class="row justify-content-center mt-3">
                                                <div class="col-md-12">
                                                    <p class="text-center text-muted" style={{fontSize: '14px'}}>
                                                        {jobApplicantDetailsData?.Description}
                                                    </p>
                                                </div>
                                            </div>
                                        )}

                                        {(jobApplicantDetailsData.ApplicantSkills) && (
                                            <div className='mt-3'>
                                                {/* <span className='text-dark'><b>Skills</b></span> <br /> */}
                                                <p className='h6 mb-1'><b>Skills</b></p>
                                                {jobApplicantDetailsData.ApplicantSkills?.split(',').map((ApplicantSkill) => (
                                                    <>
                                                        <Chip 
                                                            label={ApplicantSkill}
                                                            // icon={<FavoriteIcon sx={{color: "green", floatRight: "1px"}}/>}
                                                            sx={{ color: "#0d6efd", padding: "0px", borderRadius: "50px", backgroundColor: "rgba(173, 216, 230,0.2)", marginBottom: "8px", border: "1px solid lightgray"}}
                                                            size="small"
                                                        />
                                                        &nbsp;
                                                    </>
                                                ))}
                                            </div>
                                        )}

                                        <div className='mt-3'>
                                            {/* <span className='text-dark'><b>Skills</b></span> <br /> */}
                                            <p className='h6 mb-1'><b>Add Notes</b></p>
                                            <div className="col-md-12">
                                                <Form 
                                                    defaultValues={noteData}
                                                    onSubmit={onNoteSubmit}
                                                    validationSchema={schema}

                                                >
                                                    <FormInputText
                                                        name="Note"
                                                        // label="Add"
                                                        placeholder="Add notes for future reference"
                                                        as="textarea"
                                                        rows="4"
                                                    />
                                                    <Button type='submit' className='w-100 mt-2' style={{fontSize: '10px'}} size='small'><b>Add Note</b></Button>
                                                </Form>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div class="card">
                                                <p className='h6 p-3'><b>Applied jobs</b></p>
                                                <ul class="list-group list-group-flush">
                                                    {jobApplyData.map(job => 
                                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                                            {job.Title} <Chip 
                                                                        label={job.Status}
                                                                        sx={{ color: "white", padding: "0px"}}
                                                                        color= "primary"
                                                                        size="small"
                                                                    /> <ActionButton onClick={() => 
                                                            navigate('/FixInterview', {state: { JobId: job.JobId, JobApplicantId : job.JobApplicantId}})
                                                            } IconName="FixInterview" id="btnIdFixInterview" title="Schedule Interview" />
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Right div */}
                                <div className="col-md-9">
                                    <div className="row m-0">
                                        <div className="col-md-12 mb-4 bg-white py-3">
                                            <p className='h6'><b>Basic Information</b></p>
                                            <div className="p-2">
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <p className='text-muted mb-0' style={{fontSize : "10px"}}><b>AGE</b></p>
                                                        <p className='mb-0' style={{fontSize : "10px"}}><b>{jobApplicantDetailsData?.Age}</b></p>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <p className='text-muted mb-0' style={{fontSize : "10px"}}><b>YEAR OF EXPERIENCE</b></p>
                                                        <p className='mb-0' style={{fontSize : "10px"}}><b>{jobApplicantDetailsData?.YearOfExperience}</b></p>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <p className='text-muted mb-0' style={{fontSize : "10px"}}><b>PHONE</b></p>
                                                        <p className='mb-0' style={{fontSize : "10px"}}><b>{jobApplicantDetailsData?.Phone}</b></p>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <p className='text-muted mb-0' style={{fontSize : "10px"}}><b>CTC</b></p>
                                                        <p className='mb-0' style={{fontSize : "10px"}}><b>{jobApplicantDetailsData?.ExpectedCTC}</b></p>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <p className='text-muted mb-0' style={{fontSize : "10px"}}><b>LOCATION</b></p>
                                                        <p className='mb-0' style={{fontSize : "10px"}}><b>{jobApplicantDetailsData?.Location}</b></p>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <p className='text-muted mb-0' style={{fontSize : "10px"}}><b>EMAIL</b></p>
                                                        <p className='mb-0' style={{fontSize : "10px"}}><b>{jobApplicantDetailsData?.Email}</b></p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-8 py-2">
                                                        {(jobApplicantDetailsData.ResumeAttachment !== null) && (
                                                            <a target='_new' href={`${StandardConst.JobApplicantResume}/${jobApplicantDetailsData.ResumeAttachment}`} download>
                                                                <Button style={{fontSize: '10px', marginRight: '25px', }} size='small'> <i class="fa fa-download" aria-hidden="true"></i> &nbsp;<b>Download Resume</b></Button>
                                                            </a>
                                                        )}

                                                        {(jobApplicantDetailsData.CoverLetter !== null) && (
                                                            <a target='_new' href={`${StandardConst.JobApplicantResume}/${jobApplicantDetailsData.CoverLetter}`} download>
                                                                <Button style={{fontSize: '10px', marginRight: '25px', }} size='small'> <i class="fa fa-download" aria-hidden="true"></i> &nbsp;<b>Download Cover Letter</b></Button>
                                                            </a>
                                                        )}

                                                        <a href={`tel:${jobApplicantDetailsData?.Phone}`}>
                                                            <ActionButton
                                                                IconName="Phone"
                                                                id="btnIdJobView"
                                                                title="Call"
                                                                iconSize="large"
                                                            />
                                                        </a>
                                                        <a href={`mailto:${jobApplicantDetailsData?.Email}`}>
                                                            <ActionButton
                                                                IconName="Mail"
                                                                id="btnIdJobView"
                                                                title="Email"
                                                                iconSize="large"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {(jobApplicantDetailsData.EmployementHistory.length > 0) && (
                                            <div className="col-md-12 bg-white py-3 mb-4">
                                                <p className='h6 mb-3'><b>Experience</b></p>
                                                {jobApplicantDetailsData.EmployementHistory.map((item, index) => (
                                                    <div className="row pb-3">
                                                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                                                        <Avatar
                                                            alt={item?.CompanyName}
                                                            src={item?.CompanyName}
                                                            sx={{ width: 50, height: 50, backgroundColor: StandardConst.ColorPalette[index % StandardConst.ColorPalette.length]}}
                                                        />
                                                        </div>
                                                        <div className="col-md-11">
                                                            <span className='text-primary d-flex justify-content-start align-items-center'><b>{item?.CompanyName}</b></span>
                                                            <span className='text-muted d-flex justify-content-start align-items-center' style={{fontSize: "12px"}}><b>{item?.Designation}</b></span>
                                                            <span className='text-muted d-flex justify-content-start align-items-center' style={{fontSize: "12px"}}>{format(new Date(item?.FromDate), 'MMM yyyy')} - {format(new Date(item?.ToDate), 'MMM yyyy')}</span>

                                                            {/* This div element is only for border */}
                                                            <div className='border w-100 mt-3'></div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {(jobApplicantDetailsData.ApplicantEducationalDetails.length > 0) && (
                                            <div className="col-md-12 bg-white py-3 mb-4">
                                                <p className='h6 mb-3'><b>Educational Details</b></p>
                                                {jobApplicantDetailsData.ApplicantEducationalDetails.map((item, index) => (
                                                    <div className="row pb-3">
                                                        <div className="col-md-11">
                                                            <span className='text-primary d-flex justify-content-start align-items-center'><b>{item?.DegreeOrCourse}</b></span>
                                                            <span className='text-muted d-flex justify-content-start align-items-center' style={{fontSize: "12px"}}><b>{item?.PercentageOrGrade}</b></span>
                                                            <span className='text-muted d-flex justify-content-start align-items-center' style={{fontSize: "12px"}}>{format(new Date(item?.PassingMonthYear), 'MMM yyyy')}</span>

                                                            {/* This div element is only for border */}
                                                            <div className='border w-100 mt-3'></div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {(jobApplicantDetailsData.ApplicantCertifications.length > 0) && (
                                            <div className="col-md-12 bg-white py-3 mb-4">
                                                <p className='h6 mb-3'><b>Certificates</b></p>
                                                {jobApplicantDetailsData.ApplicantCertifications.map((item, index) => (
                                                    <div className="row pb-3">
                                                        <div className="col-md-2 d-flex justify-content-center align-items-center p-0">

                                                            {(item?.CertificationAttachment !== null) && (
                                                                <>
                                                                    <div>
                                                                        <iframe 
                                                                            src={`${StandardConst.JobApplicantCertification}/${item?.CertificationAttachment}`} 
                                                                            title="Certificate" 
                                                                            style={{ width: '100px', height: 'auto', border: 'none' }} 
                                                                        />
                                                                        <a target='_blank' href={`${StandardConst.JobApplicantCertification}/${item?.CertificationAttachment}`}>
                                                                            <ActionButton
                                                                                IconName="View"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            )}
                                                        
                                                        </div>
                                                        <div className="col-md-10">
                                                            <span className='text-primary d-flex justify-content-start align-items-center'><b>{item?.CertifiationName}</b></span>
                                                            <span className='text-muted d-flex justify-content-start align-items-center' style={{fontSize: "12px"}}><b>{item?.CertificationBody}</b></span>
                                                            <span className='text-muted d-flex justify-content-start align-items-center' style={{fontSize: "12px"}}>{format(new Date(item?.CertificationMonthYear), 'MMM yyyy')}</span>

                                                            {/* This div element is only for border */}
                                                            <div className='border w-100 mt-3'></div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </>
    );
}

export default JobApplicantProfile;
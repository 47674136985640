import Topbar from "../../../Services/TopbarComponent";
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../../Services/TableComponent";
import { WebService } from "../../../Services/WebService";
import "../../Static/Static.css";
import AddEditTemplate from "./AddEditTemplate";
import Button from "react-bootstrap/esm/Button";
import { ActionPermission, PageInfo } from "../../PageInfo";
import ActionButton from "../../../Services/ActionButton";
import SnackbarComponent from "../../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../../Services/AlertComponent";
import { NoRecordTemplate } from "../../../Services/TableComponent";
import { StandardConst } from "../../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../../Services/ConfigurationFlow";
import SalaryTemplateComponent from "./SalaryTemplateComponents";
import { IconButton, Tooltip } from "@mui/material";
//import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldMoreIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
const SalaryTemplate = () => {
  const { state } = useLocation();
  const tableComponentRef = useRef();
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Pre-defined Salary Structures" });
  var dispatch = useDispatch();
  var [data, setData] = useState([]);
  var afterRender = useRef(false);
  const fetchSalaryList = async () => {
    WebService({ dispatch, endPoint: "SalaryTemplate/Fetch" }).then((r) =>
      setData(r)
    );
    dispatch(ConfigurationflowStatusUpdate());
  };

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission('Salary - Template Add'),
      ManageEdit: ActionPermission("Salary - Template Edit"),
      // ManageDelete: ActionPermission("Roles - Delete"),
    });
  }, [permissionList]);


  const onDelete = async (TemplateId) => {
    await WebService({
      endPoint: `SalaryTemplate/Delete/${TemplateId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchSalaryList();
  };

  const addEditModalRef = useRef();
  useEffect(() => {
    if (afterRender.current === false) {
      fetchSalaryList();
    }
    afterRender.current = true;
  }, []);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  var columns = [
    {
      Text: "Salary Structure Name",
      Value: "TemplateName",
    },
    {
      Text: "Description",
      Value: "Description",
    },
    {
      Text: "Action",
      key: "TemplateId",
      cssClass: "text-center",

      isVisiable: permission.ManageEdit,
      style: { padding: "0px", width: "130px" },
      render: (dr) => (
        <>
          <ActionButton
            onClick={(e) => fnEdit(dr.TemplateId)}
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnSalaryTemplateEdit"
          />
          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                dr.TemplateId // Endpoint to hit for delete
              )
            }
            // disabled={!permission.ManageDelete}
            IconName="Delete"
            id="btnSalaryTemplateDelete"
          />
          <Tooltip title="Manage Salary Head">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={() => {
                tableComponentRef.current.expandClickEvent(dr);
              }}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  const MasterPageName = "Pre-defined Salary Structures";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Salary Structures";
  const [timeLineData, setTimeLineData] = React.useState([
    {
      title: "Salary Structure Heads",
      hrefLink: "/SalaryComponent",
      description: "Create salary structure based on employee profile",
      step: "b-previous",
    },
    {
      title: "Pre-defined Salary Structures",
      hrefLink: "/Template",
      description: "Define employee salary structures",
      step: "b-current",
    },
    {
      title: "Feed Employee Salary",
      hrefLink: "/SalaryPackage",
      description: "Define employee paylisp",
      step: "b-upcoming",
    },
    {
      title: "Generate Payslip",
      hrefLink: "/Payslip",
      description: "Generate employee payslip",
      step: "b-upcoming",
    },
    {
      title: "Download Payslip",
      hrefLink: "/Payslip",
      description: "Download employee payslip",
      step: "b-upcoming",
    },
  ]);
  const templateRef = useRef();
  const [bData] = React.useState([
    {
      title: "Payroll Management",
      hrefLink: "#",
    },
    {
      title: "Pre-defined Salary Structures",
      hrefLink: "#",
    },
  ]);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <Container
        style={{
          backgroundColor: "#FFF",
          borderRadius: "10px ",
        }}
      >
        <Topbar bData={bData} HeadingText={MasterPageName}></Topbar>
        <div className="row px-2">
          <div className="col-md-12">
            <Row className="mt-3">
              <TableComponent
                ref={tableComponentRef}
                isExpandButtonVisiable={false}
                columns={columns}
                title={AddButtonText}
                noRecordCss="p-0"
                BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""}
                noRecordFound={
                  <NoRecordTemplate
                    headerValue={
                      StandardConst.PredefinedSalaryStructuresheaderValueNoResults
                    }
                    subHeaderValue={
                      StandardConst.PredefinedSalaryStructuresSubHeaderRole
                    }
                    imageUrl={StandardConst.imageNoRecordsFound}
                    actionButton={
                      <>
                        {permission.ManageAdd && (
                          <Button
                            id="NoRecordFoundAddSalaryStructure"
                            variant="outline-primary"
                            onClick={() => fnEdit(0)}
                          >
                            Add {AddButtonText}
                          </Button>
                        )}
                      </>
                    }
                  />
                }
                data={data}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                onAddEvent={() => fnEdit()}
                allowSerialNo={true}
                toExpend={(dr) =>
                  setTimeout(() => {
                    templateRef.current.fetchByParent(dr.TemplateId);
                  })
                }
              >
                <SalaryTemplateComponent ref={templateRef} />
              </TableComponent>
            </Row>
          </div>
          {/* <div className="col-md-3 d-none d-lg-block ">
            <Row className="border-start mt-3">
              <div
                style={{ height: "64px" }}
                className="primary-bg-color p-3  text-center"
              >
                <span className="text-white">
                  <strong>Progress</strong>
                </span>
              </div>

              <PayrollProgressComponent timeLineData={timeLineData} />
            </Row>
          </div> */}
        </div>
      </Container>
      <AddEditTemplate
        callBackEvent={() => fetchSalaryList()}
        ref={addEditModalRef}
      ></AddEditTemplate>
    </>
  );
};

export default SalaryTemplate;

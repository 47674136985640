import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { Form, FormAutoCompleteDropdownCard, InputDatePicker, InputDropdown } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import _, { extend, findWhere, map, pick, reduce } from "underscore";
import TableComponent from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import TopbarComponent from "../../Services/TopbarComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import DisplayNumberFormatComponent, {
  DisNumFormatWoCurrency,
} from "../../Services/DisplayNumberFormatComponent";
import WSCircularProgress from "../../Services/WSCircularProgress";
import SquareIcon from "@mui/icons-material/Square";
import { Alert, Box, Collapse, Tooltip } from "@mui/material";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WSLinearProgressWithCap from "../../Services/WSLinearProgressWithCap";
import WSLinearProgress from "../../Services/WSLinearProgress";

import { sortBy } from "underscore";
import Swal from "sweetalert2";
import TeamEntryDetails from "./TeamEntryDetails";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import ExpandLessSharpIcon from "@mui/icons-material/ExpandLessSharp";

const TeamDetailComponent = () => {
  const MasterPageName = "Approve TimeSheet";
  PageInfo({ pageTitle: MasterPageName });
  var dispatch = useDispatch();

  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
    team: 0,
  });

  const roundOf = (num, position) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
      Math.pow(10, 2)
    );
  };

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  const loggedEmployeeId = useSelector(s => s.auth.LoggedUser ?? 0);
  const Roles = useSelector(s => s.auth.AssignRole ?? []);
  const isManager = Roles?.some(RoleId => RoleId === StandardConst.SystemRole.Manager ?? 0); 
  const isCompany = Roles?.some(RoleId => RoleId === StandardConst.SystemRole.Company ?? 0);
  const isHR = Roles?.some(RoleId => RoleId === StandardConst.SystemRole.HR ?? 0);

  useEffect(() => {
    SetPermission({
      EveryoneTimesheetApprove: ActionPermission("Timesheet - Approve Everyones Timesheet"),
      TeamTimesheetApprove: ActionPermission("Timesheet - Approve Team Timesheet"),
    });
    // if(filter.member === loggedEmployeeId && isManager && !isCompany){
    //   SetPermission({
    //     ...permission,
    //     TeamTimesheetApprove: false,
    //   });
    // }

    // HasManagerLoggedEmployeeId();
  }, [permissionList]);

  // const HasManagerLoggedEmployeeId = async() => {
  //   const [data] = await Promise.all([
  //     WebService({
  //       endPoint: `CommonUtility/fetchAll/static_project_roles?CompanyId=${StandardConst.YesNo.Yes}`,
  //       dispatch,
  //     })
  //   ]);
  // }

  const [teams, setTeams] = useState([]);
  const [projects, setProjects] = useState([]);
  const [reports, setReports] = useState([]);
  const [taskReports, setTaskReports] = useState([]);
  const afterRender = useRef(false);
  useEffect(() => {
    // if (!afterRender.current) {
      Promise.all([fetchDesignation(), fetchProject()]);
    // }
    // afterRender.current = true;
  }, [permission]);
  const fetchDesignation = async () => {
    const v1 = await WebService({
      dispatch,
      endPoint:
        "CommonUtility/static_project_roles?orderBy=DisplayDescription&select=DisplayDescription,ProjectRoleId",
    });
    if (v1.length > 0)
      setTeams(
        [{ value: 0, text: "Everyone" }].concat(
          map(v1, (m) => ({
            value: m.ProjectRoleId,
            text: m.DisplayDescription,
          }))
        )
      );
  };
  const fetchProject = async () => {
    let EndPoint = "Project/MyProjectList"
    if(permission?.EveryoneTimesheetApprove){
      EndPoint = `CommonUtility/projectmaster`;
    }
    const v1 = await WebService({
      dispatch,
      endPoint: EndPoint,
    });
    if (v1.length > 0) {
      setProjects(
        map(v1, (m) => ({
          value: m.ProjectId,
          text: m.ProjectName,
          // Symbol: m.Symbol,
        }))
      );
      setFilter((filter) => ({ ...filter, project: v1[0].ProjectId }));
    }
  };

  const fetchReport = async (search = filter) => {
    const endPoint = `Report/Timesheet/Member?startDate=${search.FromDate}&endDate=${search.ToDate}&projectId=${search.project}&memberId=${search.member}`;
    const results = await WebService({ dispatch, endPoint });
    const uniqueMonths = new Set();
    const filteredResult = await Promise.all(results.map(async(obj) => {
      const formattedDate = DateTime.fromISO(obj.Date).toFormat("yyyy-MM-dd");
      const month = formattedDate.substring(5, 7);
      if (!uniqueMonths.has(month)) {
        uniqueMonths.add(month);
        return obj;
      }
      if(obj.BillingMode === StandardConst.BilingMode.FixedPerMonth.replace(/\s/g, "")){
        obj.Amount = 0; // Return 0 for duplicate months
      }
      return obj;
    }));
    setReports(filteredResult);
  };

  const fetchTaskSummary = async (search = filter) => {
    const endPoint = `Report/Timesheet/Member/Task?${generateQueryStringByObject(
      pick(search, ["FromDate", "ToDate", "project", "member"])
    )}`;
    const results = await WebService({ dispatch, endPoint });
    setTaskReports(results);
  };
  useEffect(() => {
    if (
      (filter.FromDate ?? "").toString().length > 0 &&
      (filter.ToDate ?? "").toString().length > 0 &&
      (filter.project ?? 0) != 0 &&
      (filter.member ?? 0) != 0
    ) {
      Promise.all([fetchReport(), fetchTaskSummary()]);
    }
  }, [filter, teams]);
  const [budgetVisiable, setBudgetVisiable] = useState(false);
  const [memberList, setMemberList] = useState([]);
  //#region Change Project
  const ChangeProjectEvent = async () => {

    let EndPoint = `Project/Team/Member/Under?ProjectId=${filter?.project ?? 0}`
    if(permission?.EveryoneTimesheetApprove){
      EndPoint = `Project/fetchTeamMembersByProjectId?ProjectId=${filter?.project ?? 0}`;
    }

    await WebService({
      dispatch,
      endPoint: EndPoint,
    }).then(async (members) => {
      const roles = await WebService({
        dispatch,
        endPoint: `CommonUtility/project_team?expand=static_project_roles&where=project_team.ProjectId eq ${
          filter?.project ?? 0
        }&select=project_team.EmployeeId, static_project_roles.DisplayDescription`,
      });
      members = map(members, (m) =>
        extend(m, {
          Role:
            findWhere(roles, { EmployeeId: m.EmployeeId })
              ?.DisplayDescription ?? "",
        })
      );
      setMemberList(members);
    });
    await WebService({
      dispatch,
      endPoint: `Report/Timesheet/Team/BudgetVisiable?ProjectId=${filter.project}`,
    }).then((r) => {
      setBudgetVisiable(r);
    });
  };
  useEffect(() => {
    if ((filter?.project ?? 0) !== 0) {
      Promise.all([ChangeProjectEvent()]);
    }
  }, [filter.project]);
  //#endregion
  //#region Time Frame
  const [timeFrame, setTimeFrame] = useState(StandardConst.timeFrame.Week);
  const setDateByTimeFrame = (fromDate) => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.plus({ day: 6 }).toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: (fromDate.day > 15
            ? fromDate.endOf("month")
            : fromDate.plus({ day: 14 })
          ).toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("month").toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("quarter").toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("year").toSQLDate(),
        }));
        break;
      }
      //#endregion
    }
  };
  useEffect(() => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.now().minus({
          day: DateTime.now().weekday - 1,
        });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        const currentDay = DateTime.now().day;
        let fromDate = DateTime.now().startOf("month");
        if (currentDay > 15) fromDate = fromDate.plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(DateTime.now().startOf("month"));
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(DateTime.local().startOf("quarter"));
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(DateTime.now().startOf("year"));
        break;
      }
      //#endregion
    }
  }, [timeFrame]);
  const fnPrevious = () => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.fromSQL(filter.FromDate).minus({ day: 7 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        let fromDate = DateTime.fromSQL(filter.FromDate);
        fromDate =
          fromDate.day > 15
            ? fromDate.startOf("month")
            : fromDate.minus({ month: 1 }).plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ month: 1 })
        );
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ month: 3 })
        );
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ year: 1 })
        );
        break;
      }
      //#endregion
    }
  };
  const fnNext = () => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.fromSQL(filter.FromDate).plus({ day: 7 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        let fromDate = DateTime.fromSQL(filter.FromDate);
        fromDate =
          fromDate.day > 15
            ? fromDate.plus({ month: 1 }).startOf("month")
            : fromDate.plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).plus({ month: 1 })
        );
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).plus({ month: 3 })
        );
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(DateTime.fromSQL(filter.FromDate).plus({ year: 1 }));
        break;
      }
      //#endregion
    }
  };
  //#endregion
  const filterableViewComponent = (
    <>
      <Row className="mt-3 mx-2">
        <Col className="d-flex flex-row align-items-center p-0" md={9}>
          <ButtonGroup size="small" aria-label="small button group">
            <Button
              size="sm"
              variant="outline-primary"
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "hide" : ""
              }
              onClick={fnPrevious}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              variant="outline-primary"
              size="sm"
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "hide" : ""
              }
              onClick={fnNext}
            >
              <ArrowForwardIcon />
            </Button>
          </ButtonGroup>
          <span className="mx-2 h4">
            <strong>
              This&nbsp;
              {`${timeFrame}: `}
            </strong>
            {`${DateTime.fromSQL(filter.FromDate).toFormat(
              "dd MMM"
            )} - ${DateTime.fromSQL(filter.ToDate).toFormat("dd MMM yyyy")}`}
          </span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between mx-1 pt-2">
        <Col md={7}>
          <Row>
            <Col md={3} className="pt-1">
              <InputDropdown
                labelCss="text-center"
                setValue={setTimeFrame}
                ddOpt={map(StandardConst.timeFrame, (text, _) => ({
                  value: text,
                  text,
                }))}
              />
            </Col>
            <Col
              md={3}
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "p-0 " : "d-none"
              }
            >
              <InputDatePicker
                labelCss="text-center"
                value={filter.FromDate}
                setValue={(v) => setFilter({ ...filter, FromDate: v })}
                max={(filter.ToDate ?? "") == "" ? undefined : filter.ToDate}
              />
            </Col>
            <Col
              md={3}
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "" : "d-none"
              }
            >
              <InputDatePicker
                labelCss="text-center"
                value={filter.ToDate}
                setValue={(v) => setFilter({ ...filter, ToDate: v })}
                min={
                  (filter.FromDate ?? "") == "" ? undefined : filter.FromDate
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={5} className="p-0">
          <Row>
            <Col md={6} className="p-0 d-none">
              <InputDropdown
                labelCss="text-center"
                className="form-control"
                ddOpt={teams || []}
                value={filter.team}
                setValue={(v) => setFilter({ ...filter, team: v })}
              />
            </Col>
            <Col md={5}>
              <InputDropdown
                labelCss="text-center"
                className="form-control"
                ddOpt={projects || []}
                value={filter.project}
                setValue={(v) => setFilter({ ...filter, project: parseInt(v) })}
              />
            </Col>
            <Col md={7}>
              <Form>
                <FormAutoCompleteDropdownCard
                  name="EmployeeId"
                  data={sortBy(memberList || [], "FullName").map((res) => ({
                    EmployeeId: res.EmployeeId,
                    heading: res.FullName,
                    description: res.Role,
                    avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
                  }))}
                  optionText="heading"
                  value={filter.member}
                  setValue={(v) => setFilter({ ...filter, member: parseInt(v[0].EmployeeId) })}
                  isRequired="true"
                />
                </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
  const reportSummaryComponent = (
    <>
      <hr className="mx-2"></hr>
      <Row className="px-4 p-3">
        <Col className="d-flex flex-row  justify-content-between" md={5}>
          <div className="d-flex flex-column text-left">
            <div className="small">Total Hours</div>
            <div className="h3">
              <strong>
                <DisNumFormatWoCurrency
                  Number={roundOf(
                    reduce(reports, (m, v) => m + v.TimeInHour, 0),
                    2
                  )}
                />
              </strong>
            </div>{" "}
          </div>
          <div className="d-flex flex-column text-left">
            <div className="small">Billable Hours</div>
            <div className="h3">
              <strong>
                <DisNumFormatWoCurrency
                  Number={roundOf(
                    reduce(reports, (m, v) => m + v.Billable, 0),
                    2
                  )}
                />
              </strong>
            </div>{" "}
          </div>

          <div className="d-flex flex-column text-left">
            <div className="small">Non-Billable Hours</div>
            <div className="h3">
              <strong>
                {" "}
                <DisNumFormatWoCurrency
                  Number={roundOf(
                    reduce(reports, (m, v) => m + v.NonBillable, 0),
                    2
                  )}
                />
              </strong>
            </div>{" "}
          </div>
        </Col>
        <Col md={1}></Col>
        <Col className="d-flex flex-row  justify-content-between" md={6}>
          <div className="d-flex flex-row text-left mt-2 m">
            <div>
              <WSCircularProgress
                Billable={
                  (roundOf(
                    reduce(reports, (m, v) => m + v.Billable, 0),
                    2
                  ) /
                    roundOf(
                      reduce(reports, (m, v) => m + v.TimeInHour, 0),
                      2
                    )) *
                  100
                }
              />
            </div>
            <div className="ms-2 small">
              <SquareIcon
                sx={{ color: `${StandardConst.wsBillableColor}`, fontSize: 20 }}
              />
              <span>Billable</span>
              <br></br>
              <SquareIcon
                sx={{
                  color: `${StandardConst.wsNonBillableColor}`,
                  fontSize: 20,
                }}
              />
              <span>Non-Billable</span>
            </div>{" "}
          </div>
          {budgetVisiable && (
            <>
              <div className="d-flex flex-column text-left mx-3 text-nowrap">
                <div className="small">Billable Mode</div>
                <div className="medium">
                  <strong>
                      {
                        reports.reduce((m, v) => {
                          const billingMode = v.BillingMode.replace(/\s/g, "");
                          return StandardConst.BilingMode[billingMode] || "NA";
                        }, "NA")
                      }
                  </strong>
                </div>{" "}
              </div>
              <div className="d-flex flex-column text-left">
                <div className="small">Billable Invoice</div>
                <div className="h3">
                  <strong>
                    {" "}
                    <DisplayNumberFormatComponent
                      Currency={
                        findWhere(projects ?? [], {
                          value: filter.project ?? 0,
                        })?.Symbol ?? ""
                      }
                      Number={roundOf(
                        reduce(reports, (m, v) => m + Number(v.Amount), 0),
                        2
                      )}
                    />
                  </strong>
                </div>{" "}
              </div>
              <div className="d-flex flex-column text-left">
                <div className="small">Billable Amount</div>
                <div className="h3">
                  <strong>
                    {" "}
                    <DisplayNumberFormatComponent
                      Currency={
                        findWhere(projects ?? [], {
                          value: filter.project ?? 0,
                        })?.Symbol ?? ""
                      }
                      Number={roundOf(
                        reduce(reports, (m, v) => m + Number(v.Amount), 0),
                        2
                      )}
                    />
                  </strong>
                </div>{" "}
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
  const teamEntryDetailsRef = useRef();
  const reportInGridComponent = (
    <>
      <Row>
        <Col>
          <TableComponent
            data={reports}
            columns={[
              {
                Text: "Date",
                render: (dr) => (
                  <span>{DateTime.fromISO(dr.Date).toFormat("dd-MMM-yy")}</span>
                ),
                cssClass: "td-width-1",
              },
              {
                Text: "Hours",
                render: (dr) => <DisNumFormatWoCurrency Number={dr.TimeInHour} />,
                cssClass: "td-width-1 text-center",
              },
              {
                Text: "",
                render: (dr) => (
                  <Tooltip
                    placement="top"
                    title={`Billable: ${dr.Billable} | Non-Billable: ${dr.NonBillable}`}
                  >
                    <span style={{ height: "26px", display: "block" }}>
                      <WSLinearProgressWithCap
                        Billable={
                          dr.TimeInHour > dr.Capacity
                            ? (dr.Billable / dr.TimeInHour) * 100
                            : (dr.Billable / dr.Capacity) * 100
                        }
                        NonBillable={
                          dr.TimeInHour > dr.Capacity
                            ? (dr.NonBillable / dr.TimeInHour) * 100
                            : (dr.NonBillable / dr.Capacity) * 100
                        }
                        Capacity={dr.TimeInHour > dr.Capacity ? true : false}
                      />
                    </span>
                  </Tooltip>
                ),
              },
              {
                Text: "Capacity",
                render: (dr) => <DisNumFormatWoCurrency Number={dr.Capacity} />,
                cssClass: "td-width-1 text-center",
              },
              {
                Text: "Billable(H)",
                render: (dr) => <DisNumFormatWoCurrency Number={dr.Billable} />,
                cssClass: "td-width-1 text-center",
              },
              {
                Text: "Non-Billable (H)",
                render: (dr) => (
                  <DisNumFormatWoCurrency Number={dr.NonBillable} />
                ),
                cssClass: "td-width-2 text-center",
              },
              {
                Text: "Status",
                cssClass: `td-width-1 text-center`,

                render: (dr) => {
                  if (dr.StatusId === StandardConst.Status.Approved) {
                    return <span className="badge rounded-pill text-bg-success">Approved</span>;
                  } else if (dr.StatusId === StandardConst.Status.Pending) {
                    return <span className="badge rounded-pill text-bg-warning">Pending</span>;
                  } else if (dr.StatusId === StandardConst.Status.Reject) {
                    return <span className="badge rounded-pill text-bg-danger">Rejected</span>;
                  } else {
                    return null;
                  }
                }
              },
            ]}
            noRecordCss="p-0"
            tableCss="ws-bg-grey-light p-0"
            headerCss="ws-bg-grey-light-2"
            noRecordFound={
              <NoRecordTemplate
                headerValue="No timesheets submitted for APPROVAL"
                subHeaderValue="Team members have not yet submitted the timesheets for your APPROVAL. Once they submit the timesheets, they will appear for your approval."
                imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
              />
            }
            IsAddButtonVisible={false}
            isSearchRequired={true}
            toExpend={(dr) =>
              setTimeout(() => {
                teamEntryDetailsRef.current.fetchByParent(dr);
              })
            }
          >
            <TeamEntryDetails ref={teamEntryDetailsRef}  callBackFunction={fetchReport} />
          </TableComponent>
        </Col>
      </Row>
    </>
  );
  const [open, setOpen] = React.useState(false);
  const reportInGridTaskComponent = (
    <>
      <Box sx={{ width: "100%", marginTop: "10px", marginBottom: "0px" }}>
        <Alert
          className="mb-0"
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(open == true ? false : true);
              }}
            >
              {open == true ? (
                <ExpandLessSharpIcon fontSize="inherit" />
              ) : (
                <ExpandMoreSharpIcon fontSize="inherit" />
              )}
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Timesheet Task Wise Summary for The Period of{" "}
          {`${DateTime.fromSQL(filter.FromDate).toFormat(
            "dd MMM"
          )} - ${DateTime.fromSQL(filter.ToDate).toFormat("dd MMM yyyy")}`}
        </Alert>
      </Box>
      <Collapse in={open == true ? true : false}>
        <Row className="mb-4">
          <Col>
            <TableComponent
              data={taskReports}
              columns={[
                {
                  Text: "Task Summary",
                  cssClass: "w-50",
                  render: (dr) => <span>{dr.Task}</span>,
                },

                {
                  Text: "Hours",
                  cssClass: "w-10",
                  render: (dr) => (
                    <DisNumFormatWoCurrency Number={dr.TimeInHour} />
                  ),
                },

                {
                  Text: "Total",
                  cssClass: "w-10",
                  render: (dr) => (
                    <DisNumFormatWoCurrency
                      Number={roundOf(
                        reduce(reports, (m, v) => m + v.TimeInHour, 0),
                        2
                      )}
                    />
                  ),
                },
                {
                  Text: "Percentage",
                  cssClass: "w-10",
                  render: (dr) => (
                    <>
                      <DisNumFormatWoCurrency
                        Number={
                          (dr.TimeInHour /
                            reduce(reports, (m, v) => m + v.TimeInHour, 0)) *
                          100
                        }
                      />
                      <span> %</span>
                    </>
                  ),
                },
                {
                  Text: "Progress",
                  render: (dr) => (
                    <WSLinearProgress
                      Billable={
                        (dr.TimeInHour /
                          roundOf(
                            reduce(reports, (m, v) => m + v.TimeInHour, 0),
                            2
                          )) *
                        100
                      }
                    />
                  ),
                },
                // {
                //   Text: "Total",
                //   cssClass: "w-10",
                //   render: (dr) => (
                //     <DisNumFormatWoCurrency
                //       Number={roundOf(
                //         reduce(reports, (m, v) => m + v.TimeInHour, 0),
                //         2
                //       )}
                //     />
                //   ),
                // },
              ]}
              noRecordCss="p-0"
              headerCss="table-header-grey"
              noRecordFound={
                <NoRecordTemplate
                  headerValue="No results found"
                  subHeaderValue="Looks like you don't have any results for the filter applied!!!"
                  imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                />
              }
              IsAddButtonVisible={false}
            ></TableComponent>
          </Col>
        </Row>
      </Collapse>
    </>
  );
  const [bData, setBData] = React.useState([
    {
      title: "Time Sheet",
      hrefLink: "#",
    },
    {
      title: "Approve Timesheet",
      hrefLink: "#",
    },
  ]);
  //#region Display Success Information
  const handleSweetAlert = (
    isSuccess = true,
    message = "Successfully accepeted your request"
  ) => {
    Swal.fire({
      title: isSuccess ? "Success" : "Error",
      //text: message,
      html: message,
      icon: isSuccess ? "success" : "error",
      confirmButtonText: "OK",
      customClass: {
        confirmButton: "mx-1 btn btn-outline-danger",
      },
      buttonsStyling: false,
    });
  };
  //#endregion
  //#region Approval
  const ApproveEvent = async (data = filter) => {
    const endPoint = "Report/TimeSheet/Approve";
    const body = {
      FromDate: data?.FromDate ?? DateTime.now().toSQLDate(),
      ToDate: data?.ToDate ?? DateTime.now().toSQLDate(),
      Member: data?.member ?? 0,
      Project: data?.project ?? 0,
    };
    const method = "PUT";
    await WebService({ dispatch, endPoint, body, method });
    handleSweetAlert(
      true,
      `Timesheet approved for <b>${
        findWhere(memberList, { EmployeeId: body.Member })?.FullName
      }<br/>${DateTime.fromSQL(body.FromDate).toFormat(
        "dd-MMM-yy"
      )}</b> to <b>${DateTime.fromSQL(body.ToDate).toFormat(
        "dd-MMM-yy"
      )} </b><br/><b>${
        findWhere(projects ?? [], { value: body.Project })?.text
      }</b>`
    );
    await fetchReport();
  };
  //#endregion
  return (
    <>
      <Container fluid className="base-container">
        <TopbarComponent bData={bData} HeadingText={MasterPageName} />
        <div elevation={0} className="p-3 primary-bg-color"></div>
        <div>{filterableViewComponent}</div>

        {reportSummaryComponent}
        <hr className="mx-2 p-0 m-1"></hr>
        {permission.EveryoneTimesheetApprove || permission.TeamTimesheetApprove ?
          <div className="mx-2 my-2">
          {(reports || []).length > 0 && (
            <Alert
              action={
                <Button
                  variant="outline-success"
                  size="sm"
                  onClick={() => ApproveEvent()}
                >
                  APPROVE TIME SHEET
                </Button>
              }
            >
              <strong>Approve Timesheet - </strong> This will approve entire
              Timesheet
            </Alert>
          )}
        </div>
        : null}
        <div className="mx-2">{reportInGridComponent}</div>

        <div className="mx-2">{reportInGridTaskComponent}</div>
      </Container>
    </>
  );
};

export default TeamDetailComponent;

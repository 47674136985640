import '../css/App.css'
import '../css/Auth.css'
import { useDispatch } from 'react-redux'
import { setActiveTab } from '../features/auth/authSlice'
import { Alert } from './Alert'
import { Camera } from './Camera'
import { FormTabs } from './FormTabs'
import { Loader } from './Loader'
import { Register } from './Register'
import { useEffect } from 'react'

export const FaceCapture = ({EmployeeId, onUpdateFunction}) => {
    onUpdateFunction ??= null;
    EmployeeId ??= null;
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setActiveTab('register'));
    }, []);

    const updateData = (msg) => {
        if(onUpdateFunction !== null){
            onUpdateFunction(msg);
        }
    }

    return (
        <>
            <Loader />
            <div className="container FaceContainer wrap-login100">
                <div className="row FaceRecognitionBackgroundColor">
                    <h1 className='text-white' style={{fontFamily:"Sans-serif", textAlign: "center"}}>Register Face</h1>
                    {/* <ul className="nav nav-tabs">
                        <li className={'nav-item active'}>Register Face</li>
                    </ul> */}
                    <div className="col-md-12 l-side position-relative" style={window.innerWidth <= 600 ? {height : '500px'} : {}} >
                        <Camera />                        
                    </div>
                    <div className="r-side">
                        
                        {/* <div className='form-container'> */}
                            <Alert />
                            <Register EmployeeId={EmployeeId} updateFunction={updateData}/>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import StaticListComponent from '../../Services/StaticListComponent';
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import { StandardConst } from '../../Services/StandardConst';
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import SnackbarComponent from '../../Services/SnackbarComponent';
import { WebService } from '../../Services/WebService';
import { PageInfo } from '../PageInfo';
import _ from "underscore";
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Container, Box, Stack } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { DateRange } from '../Dashboard/Company/CompanyDashboard';

const AssetAssignmentReport = () => {

    const dispatch = useDispatch();
    const ref = useRef();
    const refSnackbar = useRef();
    PageInfo({ pageTitle: "Asset Reports" });
    const navigate = useNavigate();
    const [bData, setBData] = React.useState([
        {
            title: "Reports",
            hrefLink: "#",
        },
        {
            title: "Asset Reports",
            hrefLink: "#",
        },
    ]);
    const MasterPageName = "Asset Report";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "Asset Report";
    const [records, setRecords] = useState([]);

    const fetchAllAssignedAsstes = async () => {
        const startDate = condition.StartDate.endOf("day").setZone("utc").toSQL().substring(0, 10)
        const data = await WebService({
            endPoint: `AssetAssignment/fetchAllAssignedReport?StartDate=${startDate}`,
            dispatch,
        })

        const modifiedArray = await Promise.all(data.map(async(item) => {
            if(item.EmployeeId){
                item.PersonName = item.EmployeeName;
            }
            if(item.UserId){
                item.PersonName = item.UserName;
            }
            if(item.assetStatus){
                const foundStatus = StandardConst.AssetStatusDropdown?.find(obj => obj?.value === item?.assetStatus);
                item.assetStatus = foundStatus?.text
            }

            const imageArray = await WebService({
                endPoint: `CommonUtility/assetimages?AssetId=${item.AssetId || 0}`,
                dispatch,
            });
            imageArray.map((img) => {
                if(img.AssetId === item.AssetId) {
                    _.extend(item, { image: img.AssetImageName });
                }
            });
            return  item;
        }));
        setRecords(modifiedArray);
    }

    const [condition, setCondition] = useState({});

    useEffect(() => {
        if(condition.StartDate){
            fetchAllAssignedAsstes();
        }
    }, [condition.StartDate, condition.EndDate]);

    const columns = [
        {
            Text: "Asset Name",
            Value: "AssetName",
            render: (dr) => {
                return (
                    <>
                        <img
                            src={dr.image ? `${StandardConst.apiBaseUrl}/uploads/assetImages/${dr.image}` :
                                `${StandardConst.apiBaseUrl}/uploads/3720094.png`
                            }
                            style={{ width: "100px", height: "100px", objectFit: "contain" }}
                        />
                        <span onClick={() => navigate("/AddAssetInformation", { state: { AssetId: dr.AssetId}})} style={{cursor: "pointer"}} className='px-3'>{dr.AssetName}</span>
                    </>
                )
            }
        },
        {
            Text: "Person",
            Value: "PersonName",
        },
        {
            Text: "Issue Date",
            Value: "IssueDate",
            DateFormat: "dd-MM-yyyy",
        },
        {
            Text: "Status",
            Value: "assetStatus",
        },
    ]

    const filterComponent = (
        <>
            <Stack component="container">
                <div className="primary-bg-color p-1 px-4">
                    <Stack direction="row" className='row'>
                        <div className="col-10">
                            <Stack alignItems="center" justifyContent="center">
                                <div elevation={0} className="text-white">
                                    <div className="d-flex justify-content-end align-items-center">
                                        <DateRange
                                            ButtonColor={"white"}
                                            type="day"
                                            setDateRange={({ StartDate, EndDate }) =>
                                                setCondition((dt) => ({ ...dt, StartDate, EndDate }))
                                            }
                                        />
                                    </div>
                                </div>
                            </Stack>
                        </div>
                        {/* <div className="col-2 d-flex align-items-center justify-content-end">
                            <Stack alignItems="end" justifyContent="end">
                                <Box sx={{ display: { md: "flex" } }}>
                                {(permission?.ManageAdd) && (
                                    <Button
                                        color="primary"
                                        id={`Add_${AddButtonText}`}
                                        onClick={() => fnEdit(0)}
                                    >
                                        <div className="d-flex align-items-center">
                                            <i className="fa fa-plus-circle me-2"></i>{" "}
                                            <div className="d-none d-md-block">
                                                {`Add ${AddButtonText}`}
                                            </div>
                                        </div>
                                    </Button>
                                )}
                                </Box>
                            </Stack>
                        </div> */}
                    </Stack>
                </div>
            </Stack>
        </>
    );


    return (
        <>
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
                            <div className="ms-4">
                                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                            </div>
                        </div>
                    </div>
                </Box>
                <div></div>
                {filterComponent}
                <TableComponent
                    columns={columns}
                    data={records}
                    bData={bData}
                    MasterPageName={MasterPageName}
                    AddButtonText={AddButtonText}
                    noRecordCss="p-0"
                    noRecordFound={
                        <NoRecordTemplate
                            headerValue={StandardConst.AssetAssignedReportheaderValueNoResults}
                            subHeaderValue={StandardConst.AssetAssignSubHeader}
                            imageUrl={StandardConst.imageNoRecordsFound} />
                    }
                    isSearchRequired={true}
                    allowSerialNo={true}
                />
                </Container>
        </>
    );
}

export default AssetAssignmentReport;
import React, { useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, InputDropdown, FormInputDropdown, FormCheckRadioInput, FormInputSelect } from "../Form";
import * as yup from "yup";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import SweetAlert from "sweetalert2";
import { Alert } from "@mui/material";
import { DateTime } from "luxon";
import { findWhere } from "underscore";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import Applyleave from "./Applyleave";
import { StandardConst } from "../../Services/StandardConst";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditleave = (prop, ref) => {
  const formRef = useRef();
  const leaveToDateRef = useRef();
  const dispatch = useDispatch(); // to understand
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ ApplyLeave: {} });
  const [empid, setEmployeeId] = useState();
  const [empContact, setEmployeePhone] = useState();

  const EnumLeaveDuration = {
    'Full day leave': 1,
    'Half day leave': 0.5,
    'Quater day leave': 0.25
  }

  // const [formdate, setStarFormtDate] = useState();
  // const [todate, setLeaveToDate] = useState();
  // const [ApplyleaveId, setApplyleaveId] = useState();
  // const [minDate, setmintDate] = useState(new Date(formdate));
  // const [selectedleave, setleavetypeId] = useState();
  // const [account, setAccount] = useState({
  //   LeaveFromDate: "",
  //   LeaveToDate: "",
  //   reason: "",
  //   leavetype: "",
  // });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      // setLeaveToDate(null);
      // setStarFormtDate(null);
      setShow(true);

      if(id == StandardConst.YesNo.No){
        setShow(false);

          var TodaysDate = new Date().toISOString();

          const data = await WebService({
            endPoint: `Applyleave/Fetch/${id || 0}`,
            dispatch,
          });

          data ??= {};
          data.ApplyLeave ??= {};
          data.ApplyLeave.LeaveDuration = EnumLeaveDuration["Full day leave"]; // Default Selected value of the dropdown
          data.ApplyLeave.LeaveFromDate = DateTime.fromISO(TodaysDate).toSQLDate();
          data.ApplyLeave.LeaveToDate = DateTime.fromISO(TodaysDate).toSQLDate();
          data.ApplyLeave.LeaveToDateDisabled = (data?.ApplyLeave?.LeaveFromDate ?? "") === "" ? true : false;

          data.ApplyLeave.leavedays ??= 0;
          data.leaveType = data.leaveType.map((v) => {
            return {
              value: v.LeaveId,
              text: v.LeaveName,
              IsReserved: v.IsReserved,
            };
          });
          var msDiff =
            new Date(data.ApplyLeave.LeaveToDate).getTime() -
            new Date(data.ApplyLeave.LeaveFromDate).getTime(); //Future date - current date
          var dayscount = Math.floor(msDiff / (1000 * 60 * 60 * 24));
          data.ApplyLeave.LeaveTypeId ??=
            data.leaveType.length > 0 ? data.leaveType[0].value : null;
          setData(data);
          // setApplyleaveId(id);
          setEmployeeId(data?.EmployeeData[0]?.EmployeeId);
          setEmployeePhone(data?.EmployeeData[0]?.EmergencyPhone);
          setShow(true);
        }
    },
  }));
  const handleClose = () => {
    setShow(false);
    setData({ ApplyLeave: {} });
  }
  const requiredMessage = "This is a required field";
  const numberError = "Character is not valid";
  const mobileMessage = "Mobile should be 10 Digit";
  const schema = yup
    .object()
    .shape({
      LeaveFromDate: yup.string().trim().required(requiredMessage),
      LeaveToDate: yup.string().trim().required(requiredMessage),
      Remarks: yup.string().trim().required(requiredMessage),
      Phone: yup
        .number()
        .required(requiredMessage)
        .typeError(numberError)
        .min(1000000000, mobileMessage),
      leavedays: yup
        .number()
        .label("No. of Leave")
        .max(data.Balance ?? 1000),
    })
    .required();
  //#region Leave Balance Fetch
  const fetchLeaveBalance = (leaveType, date) => {
    if (findWhere(data.leaveType, { value: leaveType })?.IsReserved ?? false) {
      setData((data) => ({ ...data, Balance: null }));
    } else {
      WebService({
        dispatch,
        endPoint: `Applyleave/Fetch/LeaveBalance/${leaveType}/${date}`,
      }).then((r) => setData((data) => ({ ...data, Balance: r ?? 0 })));
    }
  };
  useEffect(() => {
    if (
      (data?.ApplyLeave?.LeaveTypeId ?? null) !== null &&
      (data?.ApplyLeave?.LeaveFromDate ?? "") !== ""
    ) {
      fetchLeaveBalance(
        data.ApplyLeave.LeaveTypeId,
        data.ApplyLeave.LeaveFromDate
      );
    }
  }, [data.ApplyLeave.LeaveTypeId, data.ApplyLeave.LeaveFromDate]);
  //#endregion
  //#region Data Clear
  useEffect(() => {
    if (!show) {
      setData({ ...data, ApplyLeave: {} });
    }
  }, [show]);
  //#endregion

  //#region No of Days Calculation
  const makeToDateSameAsFromDate = async () => {
    await formRef.current?.fnReset((formValues) => ({
      ...formValues,
      LeaveToDate: data.ApplyLeave.LeaveFromDate,
    }));
  };

 useEffect(() => {
   // agar kisine leave duration half day ya quater select kiya to To date same as From date ho jayegi
   makeToDateSameAsFromDate();
 }, [data.ApplyLeave?.LeaveFromDate, data.ApplyLeave.LeaveDuration]);
  //#endregion

  //#region No of Days Calculation
  const leaveDaysCalculation = async () => {
    var days = 0;

    if (data?.ApplyLeave?.LeaveToDate == null || data?.ApplyLeave?.LeaveToDate == "") {
      data.ApplyLeave.LeaveToDate = data.ApplyLeave.LeaveFromDate
    }

    if(data.ApplyLeave.LeaveDuration < 1){
      days = data.ApplyLeave.LeaveDuration;
    }else if (
      (data?.ApplyLeave?.LeaveFromDate ?? "") !== "" &&
      (data?.ApplyLeave?.LeaveToDate ?? "") !== ""
    ) {
      const diffInMs =
        new Date(data.ApplyLeave.LeaveToDate) -
        new Date(data.ApplyLeave.LeaveFromDate);
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      days = diffInDays + 1;
    }

        // jab unpaid leave hoga us time pr data object ke ander Balance Field Null hoga
    if (days > data.Balance && data.Balance != null) {
      days = data.Balance;
    }
    await formRef.current?.fnReset((formValues) => ({
      ...formValues,
      leavedays: days,
    }));
  };

  useEffect(() => {
    // agar kisine leave duration half day ya quater select kiya to To date same as From date ho jayegi
    leaveDaysCalculation();
  }, [data.ApplyLeave?.LeaveFromDate, data.ApplyLeave?.LeaveToDate, data.ApplyLeave.LeaveDuration]);
  //#endregion

  // const onChangeDate = async () => {
  //   const IsReserved = findWhere(data.leaveType, {
  //     value: parseInt(selectedleave),
  //   })?.IsReserved;
  //   if (IsReserved == 0) {
  //     const responsedata = await WebService({
  //       endPoint: `CommonUtility/employeeleavetransactions?where=EmployeeId eq ${
  //         empid || 0
  //       } AND LeaveTypeId eq ${selectedleave || 0}`,
  //       dispatch,
  //     });
  //     if (responsedata.length > 0) {
  //       if ((responsedata[0].ValidFormDate || "") !== "")
  //         responsedata[0].ValidFormDate = format(
  //           new Date(responsedata[0].ValidFormDate),
  //           "yyyy-MM-dd"
  //         );
  //       setStarFormtDate(responsedata[0]?.ValidFormDate);
  //       formRef.current.fnReset((formValues) => ({
  //         ...formValues,
  //         LeaveFromDate: responsedata[0]?.ValidFormDate,
  //       }));
  //     }
  //     await WebService({
  //       dispatch,
  //       endPoint: `CommonUtility/employeeleavebalances?where=EmployeeId eq ${
  //         empid ?? 0
  //       } and LeaveTypeId eq ${selectedleave ?? 0}`,
  //     }).then((c) => {
  //       if (c.length > 0) {
  //         setMaxTakenLeaveDays(c[0].Balance);
  //       }
  //     });
  //   } else {
  //     setStarFormtDate(null);
  //     formRef.current.fnReset((formValues) => ({
  //       ...formValues,
  //       LeaveFromDate: null,
  //     }));
  //     setMaxTakenLeaveDays(10000);
  //   }
  // };
  const [maxTakenLeaveDays, setMaxTakenLeaveDays] = useState(0);
  const onSubmit = async (data) => {
    const getLeaveDetails = await WebService({
      endPoint : `Applyleave/fetchAppliedLeave?fromLeaveDate=${data.LeaveFromDate}`,
      method : 'GET',
    });

    if(getLeaveDetails.length > 0) {
      if(getLeaveDetails[0].NoOfLeaves >= 1){
        WSErrorAlert("Error", "You are already taken full day leave");
        return;
      }else if(getLeaveDetails[0].NoOfLeaves < 1){
        WSErrorAlert("Error", `You are already taken leave`);
      }
    }

    var SuccessMsg = `From ${data.LeaveFromDate} To ${data.LeaveToDate}`;
    
    // if (data.leavedays >= 1) {
    //   const diffInMs = new Date(data.LeaveToDate) - new Date(data.LeaveFromDate);
    //   const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    //   data.leavedays = diffInDays + 1;
    // }else{
      
    // }
    
    if(data.leavedays == EnumLeaveDuration["Half day leave"]){
      SuccessMsg = `For ${data.LeaveFromDate} ( ${Object.keys(EnumLeaveDuration)[1]} )`;
      data.LeaveToDate = data.LeaveFromDate;
    }else if(data.leavedays == EnumLeaveDuration["Quater day leave"]){
      SuccessMsg = `For ${data.LeaveFromDate} ( ${Object.keys(EnumLeaveDuration)[2]} )`;
      data.LeaveToDate = data.LeaveFromDate;
    }

    if (data.leavedays != 0) {
      data.slackMessage = `Leave Application submitted From ${data.LeaveFromDate} To ${data.LeaveToDate}.`;
      data.EmployeeId = empid;
      await WebService({
        endPoint: "Applyleave/Submit",
        body: data,
        dispatch,
      });
      WSSuccessAlert(
        "Success",
        `Leave Application ${SuccessMsg} Applied Successfully.`
      );

      setShow(true);
      handleClose();
      prop.callBackEvent();
    }

  };

  const FormateDate = (DateString) => {
      // Get the year, month, and day components
      const year = DateString.getFullYear();
      const month = String(DateString.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const day = String(DateString.getDate()).padStart(2, '0');

      // Create the formatted date string
      return `${year}-${month}-${day}`;
  }

  const ChangeLeaveDuration = (element) => {
    var ChangedLeaveDuration = 1;
    var diableLeaveToDate = false;
    if(element.value == "0.25"){
      ChangedLeaveDuration = 0.25;
      diableLeaveToDate = true;
    }else if(element.value == "0.5"){
      ChangedLeaveDuration = 0.5;
      diableLeaveToDate = true;
    }
    setData((prevState) => (
      {
      ...prevState,
      ApplyLeave: {
        ...prevState.ApplyLeave,
        // LeaveToDate: data.ApplyLeave.LeaveFromDate,
        LeaveDuration : ChangedLeaveDuration,
        LeaveToDateDisabled : diableLeaveToDate,
      },
    }))

    
  }
  
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={handleClose}
      centered
      className="container-fluid"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.ApplyLeave.LeavesId || 0) === 0
            ? "Apply Leaves"
            : data.ApplyLeave.ApprovalStatusId == 3
            ? "Leave Already Approved. You Can't Edit"
            : "Edit Applied Leaves"}
        </Modal.Title>
      </Modal.Header>

      <Form
        defaultValues={data.ApplyLeave}
        onSubmit={onSubmit}
        validationSchema={schema}
        ref={formRef}
      >
        <Modal.Body className="p-0">
          <div>
            {data.Balance != null && (
              <>
                <Alert severity={data.Balance > 0 ? "success" : "warning"}>
                  Leave Balance: {data.Balance}
                </Alert>
                {data.Balance == 0 && (
                  <Alert severity="error">
                    Your do not have sufficiant leave balance
                  </Alert>
                )}
              </>
            )}
          </div>

          <div className="p-4">
            <div>
              <FormInputDropdown
                name="LeaveTypeId"
                ddOpt={data.leaveType}
                setValue={(val) => {
                  setData((data) => ({
                    ...data,
                    ApplyLeave: {
                      ...data.ApplyLeave,
                      LeaveTypeId: parseInt(val),
                      LeaveToDate: null,
                    },
                  }));
                  leaveToDateRef.current.fnReset(null);
                }}
                label="Leave Type"
                isRequired="true"
              ></FormInputDropdown>
            </div>

            <div className="row mt-1">
              <div className="col-md-4">
                <label style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>Leave Date<span className="text-danger">＊</span></label>
              </div>
              <div className="col-md-4 paddinginputleft_0 inputadding">
                <FormInputText
                  name="LeaveFromDate"
                  setValue={(val) =>
                    setData((data) => ({
                      ...data,
                      ApplyLeave: {
                        ...data.ApplyLeave,
                        LeaveFromDate: val,
                        LeaveToDate: val,
                      },
                    }))
                  }
                  type="date"
                  // min={
                  //   formdate ? new Date(formdate).toISOString().split("T")[0] : ""
                  // }
                  // max={data?.ApplyLeave?.LeaveToDate}
                  isRequired="true"
                />
              </div>

              <div className="col-md-4">
                <FormInputText
                  name="LeaveToDate"
                  type="date"
                  value={data.ApplyLeave.LeaveToDate}
                  setValue={(val) =>
                        setData((data) => ({
                          ...data,
                          ApplyLeave: {
                            ...data.ApplyLeave,
                            LeaveToDate: val,
                          },
                        }))
                      }
                  disabled={
                    // (data?.ApplyLeave?.LeaveFromDate ?? "") === "" ? true : false
                    data.ApplyLeave.LeaveToDateDisabled
                  }
                  min={data?.ApplyLeave?.LeaveFromDate}
                  max={
                    data.Balance == null
                      ? null
                      : DateTime.fromSQL(data.ApplyLeave.LeaveFromDate)
                          .plus({ days: Math.ceil(data.Balance) - 1 })
                          .toSQLDate()
                  }
                  isRequired="true"
                  ref={leaveToDateRef}
                />
              </div>
            </div>

            <div>
              <FormInputSelect
                name="LeaveDuration"
                optionArr={EnumLeaveDuration}
                onChange={(e)=>{ChangeLeaveDuration(e.target)}}
                isRequired={true}
              />
            </div>
            <div>
              <FormInputText
                type="number"
                disabled
                name="leavedays"
                label="No. of Leave"
                isRequired="true"
              />
            </div>
            <div>
              <FormInputText
                name="Phone"
                label="Emergency Phone Number"
                type="text"
                minLength={10}
                maxLength={10}
                value={data.ApplyLeave.LeavesId > 0 ? data.Phone : empContact}
                isRequired="true"
              />
            </div>
            <div>
              <FormInputText
                name="Remarks"
                label="Reason for Leave"
                as="textarea"
                isRequired="true"
              />
            </div>
            <FormInputText
              name="EmployeeId"
              value={empid}
              type="hidden"
            ></FormInputText>
            <FormInputText
              name="leavedays"
              type="hidden"
              isRequired="true"
            ></FormInputText>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            id="btnSubmitleave"
            type="submit"
            disabled={data.ApplyLeave.ApprovalStatusId == 3 ? true : false}
          >
            {(data.ApplyLeave.LeavesId || 0) === 0 ? "Apply" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditleave);

import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import "./IDCard.css";
const ViewIDCard = forwardRef(({}, ref) => {
  const [show, setShow] = useState(false);
  const [IdCardinfoDetails, setIdCardInfoDetails] = useState([]);
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    openModal: async (empId) => {
      const data = await WebService({
        endPoint: `EmployeeIdCard/FetchActiveIdCard/${empId}`,
        dispatch,
      });
      setIdCardInfoDetails(data.IdCardDetails[0]);
      setShow(true);
    },
  }));
  const handleClose = () => setShow(false);
  const Print = () => {
    let printContents = document.getElementById("EmpIdCard").innerHTML;

    var newWin = window.open("", "Print-Window");
    newWin.document.open();
    newWin.document.write(
      '<html> <head><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet"><link href="https://assets.wiseofficeday.com/wod/css/IDCard.css" rel="stylesheet"></head><body onload="window.print()">' +
        printContents +
        "</body></html>"
    );
    newWin.document.close();

    setTimeout(function () {
      newWin.close();
    }, 50);
  };
  return (
    <>
      <div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          show={show}
          onHide={handleClose}
          centered
          className="container-fluid"
        >
          <Modal.Header closeButton>
            <Modal.Title>ID Card</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="container" id="EmpIdCard">
              {/* <div className="row">
                <div className=" offset-lg-4 main-section col-lg-5 col-sm-5 col-5 text-center">
                  <div className="row mb-2">
                    <div className="col-lg-12 col-sm-12 col-12 profile-header d-flex justify-content-center">
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            IdCardinfoDetails.Logo != ""
                              ? `${StandardConst.apiBaseUrl}/uploads/${
                                  IdCardinfoDetails.Logo ??
                                  "user_placeholder.jpg"
                                }`
                              : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                          }
                          className="circle img-thumbnail company-img"
                        />
                        <span className="text-white h5 ms-2 text-center">
                          {IdCardinfoDetails.CompanyName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row user-detail ">
                    <div className="col-lg-12 col-sm-12 col-12">
                      <img
                        src={
                          IdCardinfoDetails.ProfileImage != ""
                            ? `${StandardConst.apiBaseUrl}/uploads/${
                                IdCardinfoDetails.ProfileImage ??
                                "user_placeholder.jpg"
                              }`
                            : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                        }
                        className="rounded-circle img-thumbnail"
                      />
                      <h5>
                        {(IdCardinfoDetails.FullName ?? "").toUpperCase()}{" "}
                      </h5>
                      <h6>
                        {(IdCardinfoDetails.EmployeeCode ?? "").toUpperCase()}{" "}
                      </h6>
                      <span>
                        <i className="fa fa-map-marker me-2" aria-hidden="true"></i>
                        {IdCardinfoDetails.PermanentAddress}
                        <br />
                        <div
                          className={`${
                            IdCardinfoDetails.Phone != null
                              ? "d-inline"
                              : "d-none"
                          }`}
                        >
                          <i className="fa fa-mobile me-2" aria-hidden="true"></i>
                          {IdCardinfoDetails.Phone}
                        </div>
                      </span>
                      <hr />
                      <i className="fa fa-map-marker me-2" aria-hidden="true"></i>
                      {IdCardinfoDetails.Address}
                    </div>
                  </div>

                  <div className="row user-social-detail">
                    <div className="col-lg-12 col-sm-12 col-12 text-center">
                      <button
                        className="btn btn-success px-4"
                        type="button"
                        onClick={Print}
                      >
                        {" "}
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="id-card-tag"></div>
              <div className="id-card-tag-strip"></div>
              <div className="id-card-hook"></div> */}
              <div className="id-card-holder my-3">
                <div className="id-card">
                  <div className="header">
                    <img
                      src={
                        IdCardinfoDetails.Logo != ""
                          ? `${StandardConst.apiBaseUrl}/uploads/${
                              IdCardinfoDetails.Logo ?? "user_placeholder.jpg"
                            }`
                          : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                      }
                      alt=""
                    />
                  </div>
                  <h5> {IdCardinfoDetails.CompanyName}</h5>
                  <div className="photo">
                    <img
                      src={
                        IdCardinfoDetails.ProfileImage != ""
                          ? `${StandardConst.apiBaseUrl}/uploads/${
                              IdCardinfoDetails.ProfileImage ??
                              "user_placeholder.jpg"
                            }`
                          : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                      }
                      className="rounded-circle img-thumbnail"
                      alt=""
                    />
                  </div>
                  <h5>{(IdCardinfoDetails.FullName ?? "").toUpperCase()} </h5>
                  <span>
                    {(IdCardinfoDetails.EmployeeCode ?? "").toUpperCase()}{" "}
                  </span>
                  <h6> {IdCardinfoDetails.PermanentAddress}</h6>
                  <div
                    className={`${
                      IdCardinfoDetails.Phone != null ? "d-inline" : "d-none"
                    }`}
                  >
                    <i className="fa fa-mobile me-2" aria-hidden="true"></i>
                    {IdCardinfoDetails.Phone}
                  </div>
                  <hr />
                  <span> {IdCardinfoDetails.Address}</span>
                </div>
              </div>
            </div>
            <Modal.Footer>
              <button
                className="btn btn-success px-4"
                type="button"
                onClick={Print}
              >
                {" "}
                Print
              </button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
});

export default ViewIDCard;

import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Alert} from "@mui/material";
import QRCodeGeneratorDownloadComponent from '../../Services/QRCodeGeneratorDownloadComponent';

const ManageVehicles = () => {
    // const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    // const [permission, SetPermission] = useState({});
    const [vehicleData, setVehicleData] = useState([]);
    PageInfo({ pageTitle: "Manage Vehicles" });
    // useEffect(() => {
    //     SetPermission({
    //         ManageAdd: ActionPermission("Vehicle - Add"),
    //         ManageEdit: ActionPermission("Vehicle - Edit"),
    //         ManageDelete: ActionPermission("Vehicle - Delete"),
    //         ManageQRCode: ActionPermission("Vehicle - QR Code"),
    //     });
    // }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Manage Vehicles";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();
    const navigate = useNavigate();
    const AddButtonText = "Vehicle";
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.companyInfo ?? {});

    const onDelete = async (VehicleId) => {
        await WebService({
            endPoint: `CommonUtility/vehicleregistration?VehicleId=${VehicleId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchVehicleList();
    };

    const fetchVehicleList = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/vehicleregistration`,
            dispatch,
        });
        setVehicleData(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchVehicleList();
    }, []);

    const [bData ] = React.useState([
        {
            title: "Vehicle Management",
            hrefLink: "#",
        },
        {
            title: "Manage Vehicles",
            hrefLink: "#",
        },
    ]);

    const GenerateQRRef = useRef();

    const columns = [
        {
            Text: "Make",
            Value: "Make",
        },
        {
            Text: "Model",
            Value: "Model"
        },
        {
            Text: "Year",
            DateFormat: "yyyy",
            Value: "Year",
        },
        {
            Text: "Registration Number",
            Value: "RegistrationNumber"
        },
        {
            Text: "VIN",
            Value: "VIN"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            // isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => navigateAddEditVehiclePage(dr.VehicleId)}
                        // disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Vehicle"}
                        id={`btnVehicleEdit_${dr.VehicleId}`}
                    />
                    <ActionButton
                        onClick={async () => await GenerateQRRef.current.openModal(`Vehicle,${dr.Guid}`)}
                        IconName="QR"
                        IconTooltip={"QR Code"}
                        id="btnVehicleQRCode"
                        // disabled={!permission.ManageQRCode}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete this vehicle.", // Message of Alert
                                dr.VehicleId  // Endpoint to hit for delete
                            )}
                        // disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Vehicle"}
                        id={`btnVehicleDelete_${dr.VehicleId}`}
                    />
                </>
            ),
        },
    ];

    const navigateAddEditVehiclePage = (id) => navigate("/VehicleRegistration", { state: { VehicleId: id}});

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={vehicleData}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.ManageVehicleheaderValueNoResults}
                        subHeaderValue={StandardConst.ManageVehicleSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {/* {permission.ManageAdd && ( */}
                                    <Button variant="outline-primary" onClick={() => navigateAddEditVehiclePage(null)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                {/* )} */}
                            </>
                        }
                    />
                }
                onAddEvent={() => navigateAddEditVehiclePage(null)}
                // IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <GenerateQR ref={GenerateQRRef} />
        </>
    )
}


const GenerateQR = forwardRef(({}, ref) => {
    const [show, setShow] = useState(false);
    const [qrData, setQrData] = useState(false);
    const handleClose = () => setShow(false);
    useImperativeHandle(ref, () => ({
        openModal: async (qrData) => {
            setQrData(qrData);
            setShow(true);
        },
    }));

    return (
        <Modal
            size="xs"
            centered
            show={show}
            onHide={handleClose}
            className="container-fluid"
        >
            <Modal.Header closeButton>
                <Modal.Title>QR Code for vehicle</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 m-0">
                <Alert severity="success">
                    Download this QR Code. Scan this QR Code getting vehicle details.
                </Alert>
                <div className="p-3 m-0">
                    <QRCodeGeneratorDownloadComponent data={qrData} showWodLogo={false} showLogo={false} EventLogo={null} Text="Powered by wiseofficeday.com" />
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default ManageVehicles
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "./StandardConst";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Alert, AlertTitle, Box, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import { ActionPermission } from "../Component/PageInfo";
import { dateFormatFunction }from "./StandardFunctions.jsx";
import DisplayNumberFormatComponent from "./DisplayNumberFormatComponent.jsx";
import { Delete } from "@mui/icons-material";
import { Form, FormInputDropdown, FormInputText } from "../Component/Form.jsx";
import Row from "react-bootstrap/esm/Row.js";
import Col from "react-bootstrap/esm/Col.js";
import {
  chain,
  each,
  extend,
  findWhere,
  map,
  mapObject,
  omit,
  reduce,
  where,
} from "underscore";
import { DateTime } from "luxon";
import * as yup from "yup";
import { WSErrorAlert, WSSuccessAlert } from "./WSAlert.jsx";
import { WebService } from "./WebService.jsx";
import ActionButton from "./ActionButton.jsx";
import { roundOff } from "./UtilityService.jsx";


const HtmlFileReaderForInvoice = ({ jsonData, defaultSelectedTemplate, ShowDownloadBadgeBtn, ShowPrintBadgeBtn, Message, MessageAlertTitle }) => {
  ShowPrintBadgeBtn ??= 0;
  Message ??= "";
  MessageAlertTitle ??= "";
  ShowDownloadBadgeBtn ??= 0;
  const [htmlContent, setHtmlContent] = useState("");
  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
        InvoiceGenerate: ActionPermission("Invoice - Generate"),
        InvoiceEdit: ActionPermission("Invoice - Edit"),
        InvoiceView: ActionPermission("Invoice - View"),
        InvoiceDelete: ActionPermission("Invoice - Delete"),
        InvoicePrint: ActionPermission("Invoice - Print"),
        InvoiceDownload: ActionPermission("Invoice - Download"),
        InvoiceMail: ActionPermission("Invoice - Mail"),
        InvoicePublish: ActionPermission("Invoice - Publish"),
        InvoiceAddPayment: ActionPermission("Invoice - Add Payment"), // IAP true,
        InvoiceDeletePayment: ActionPermission("Invoice - Delete Payment"), // IDP true,
    });
  }, [permissionList]);

  let downloadfileName = "";

  const formatCurrency = (currencyCode, localeCode, value) => {
     return new Intl.NumberFormat(localeCode, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(Number(value));
  };

  const replaceValues = (content) => {
      let modifiedContent = content;
      const currencyCode = jsonData?.SettingDetails?.CurrencyCode;
      const localeCode = jsonData?.SettingDetails?.LocaleCode;
      Object.keys(jsonData).forEach(key => {
        // this is to replace Template Setting information.
        if (key === 'SettingDetails') {
          downloadfileName = jsonData[key].InvoiceNo;
          Object.keys(jsonData[key]).forEach(keyName => {
            modifiedContent = modifiedContent.replace(
              new RegExp(`{{${keyName}}}`, "g"),
              (((keyName == "InvoiceDate" || keyName == "InvoiceDueDate") ? dateFormatFunction(jsonData[key][keyName]) : (keyName === "TaxAmount" || keyName === "NetAmount" || keyName === "TotalAmount") ? formatCurrency(currencyCode, localeCode, jsonData[key][keyName]) : jsonData[key][keyName]))??""
            );
          })
        }

        // this is to replace Company information.
        if (key === StandardConst.InvoiceSectionNames.Company) {
          Object.keys(jsonData[key]).forEach(keyName => {
            jsonData[key].map((cValue) => {
              modifiedContent = modifiedContent.replace(
                new RegExp(`{{${cValue.FieldName}}}`, "g"),
                ((cValue.FieldType === StandardConst.dataTypes.Currency) ? formatCurrency(currencyCode, localeCode, cValue.Value) : cValue.Value)??""
              );
            })
          })
        }

        if (key === StandardConst.InvoiceSectionNames.Bottom) {
          Object.keys(jsonData[key]).forEach(keyName => {
            jsonData[key].map((cValue) => {
              modifiedContent = modifiedContent.replace(
                new RegExp(`{{${cValue.FieldName}}}`, "g"),
                ((cValue.FieldType === StandardConst.dataTypes.Currency) ? formatCurrency(currencyCode, localeCode, cValue.Value) : cValue.Value)??""
              );
            })
          })
        }

        // this is to replace Customer information.
        if (key === StandardConst.InvoiceSectionNames.Customer) {
          Object.keys(jsonData[key]).forEach(keyName => {
            jsonData[key].map((cuValue) => {
              modifiedContent = modifiedContent.replace(
                new RegExp(`{{${cuValue.FieldName}}}`, "g"),
                ((cuValue.FieldType === StandardConst.dataTypes.Currency) ? formatCurrency(currencyCode, localeCode, cuValue.Value) : cuValue.Value)??""
              );
            })
          })
        }

        // this is replace for Details information.
        if (key === StandardConst.InvoiceSectionNames.Details) {
            //Here we get columns count
            var columnCount = jsonData[key][0]?.InvoiceDetailColumns.length;

            // Here we fill only head on the table.
            for (var i = 0; i < columnCount; i++) {
              modifiedContent = modifiedContent.replace(new RegExp(`{{${jsonData[key][0]?.InvoiceDetailColumns[i].FieldName}${StandardConst.DetailsHeadingSuffix.Heading}}}`, "g"), jsonData[key][0]?.InvoiceDetailColumns[i].Name??"");
            }
              
            // Create a DOMParser
            const parser = new DOMParser();
            // Parse the HTML content
            const doc = parser.parseFromString(modifiedContent, 'text/html');

            const BodyHeading = doc.getElementById("InvoiceDetailRows");
            
            var replaceBody = ``;
            Object.keys(jsonData[key]).forEach(index => {

              var OneDataRow = jsonData[key][index];
              
              if (BodyHeading) {
                var trElement = BodyHeading.outerHTML;
                for (var j = 0; j < columnCount; j++) {
                  trElement = trElement.replace(new RegExp(`{{${OneDataRow?.InvoiceDetailColumns[j].FieldName}}}`, "g"), ((OneDataRow.InvoiceDetailColumns[j]?.FieldType === StandardConst.dataTypes.Currency) ? formatCurrency(currencyCode, localeCode, OneDataRow.InvoiceDetailColumns[j]?.Value) : OneDataRow.InvoiceDetailColumns[j]?.Value) ??"");
                }
                replaceBody += trElement;
              }
            });

            if (BodyHeading) {
              BodyHeading.outerHTML = replaceBody;
              modifiedContent = doc.documentElement.outerHTML;
            }
        }
      });

      return modifiedContent;
  };


  const handleDownloadPdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(replaceValues(htmlContent), {
      x: 0,
      y: 0,
      async callback(doc) {
        await doc.save(downloadfileName);
      },
      html2canvas: { scale: 0.4 }
    });
  };

  const handlePrint = () => {
    const modifiedContent = replaceValues(htmlContent);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(modifiedContent);
    printWindow.document.close();
    const openPrint = () => {
        printWindow.print();
    };
    printWindow.addEventListener('load', openPrint);
  };

  const handleReadFile = async (fileName) => {
    try {
      const badgeHTMLResponse = await fetch(`${StandardConst.apiBaseUrl}/Invoice/getInvoiceTemplateContents/${fileName}`);
      const badgeHTMLContent = await badgeHTMLResponse.text();
      setHtmlContent(badgeHTMLContent);
    } catch (error) {
      console.error("Error fetching HTML file:", error);
    }
  };

  useEffect(() => {
    handleReadFile(defaultSelectedTemplate);
  });

  const commonStyles = {
    border: 1,
    borderBottom: 1,
    borderColor: "primary.main",
    p: 2,
  };

  const boxCommonStyles = {
    "& > :not(style)": {
      m: 1,
    },
  };
  const dispatch = useDispatch();
  const [invoicePaymentData, setInvoicePaymentData] = useState([]);

  const fetchInvoicePaymentData = async () => {
    const InvoiceHeaderId = jsonData?.SettingDetails?.InvoiceHeaderId;
    await WebService({ 
      endPoint : `CommonUtility/Edit/invoicepayments?InvoiceHeaderId=${InvoiceHeaderId}`,
    }).then((res) => {
      setInvoicePaymentData(res);
    })
  };

  useEffect(() => {
    fetchInvoicePaymentData();
  }, [jsonData?.SettingDetails?.InvoiceHeaderId]);

  const totalDueAmt = () =>{
    const TotalAmount = jsonData?.Bottom?.find(item => item.FieldName === StandardConst.BottomSectionFixedFieldName.TotalAmount)?.Value ?? 0;
    return roundOff(
      (TotalAmount ?? 0) -
        (reduce(
          invoicePaymentData ?? [],
          (m, v) => m + parseFloat(v?.PaidAmount ?? "0"),
          0
        ) ?? 0),
      2
    );
  };

  const addPayment = async (data) => {
    data.InvoiceHeaderId = jsonData?.SettingDetails?.InvoiceHeaderId;
    data.StatusId = StandardConst.Status.Paid;
    if(data.InvoiceHeaderId !== undefined && data.InvoiceHeaderId > 0) {
      await WebService({ 
        endPoint : "CommonUtility/invoicepayments", 
        method : "POST",
        body : data,
        dispatch
      }).then((response) => {
        fetchInvoicePaymentData();
        formRef.current.fnReset({});
        WSSuccessAlert("Success", "Payment sucessfully completed");
      });
    }else{
      WSErrorAlert("Error", "No such invoice found");
    }
  };

  const schema = yup
    .object()
    .shape({
      PaymentTrasactionNo: yup.string().required(StandardConst.requiredMessage),
      PaymentDate: yup
        .date()
        .label("Payment Date")
        .typeError("Enter valid date")
        .required(StandardConst.requiredMessage)
        // .when([], (_, passSchema) =>
        //   passSchema.min(DateTime.fromISO(data.ToDate).toFormat("yyyy-MM-dd"))
        // )
        ,
      PaidAmount: yup
        .number()
        .label("Paid Amount")
        .typeError(StandardConst.requiredMessage)
        .required(StandardConst.requiredMessage)
        .min(0.01)
        .when([], (_, passSchema) => passSchema.max(totalDueAmt()))
        ,
    })
    .required();

  const displayDateFormat = (dt, format) => {
      if (dt === undefined || dt === null) return null;
      return DateTime.fromISO(dt).toFormat(format);
  };

  const formRef = useRef();

  const fnRemove = async (InvoicePaymentId) => {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/invoicepayments?InvoicePaymentId=${InvoicePaymentId}`,
      method: "DELETE",
    }).then(res => {
      fetchInvoicePaymentData();
      WSSuccessAlert("Success", "Payment details removed successfully");
    });
   
  };

  const Payment = (
    <>
      <Box
        sx={{
          ...boxCommonStyles,
        }}
      >
        <Paper
          square
          elevation={0}
          sx={{
            ...commonStyles,
            p: 0,
          }}
        >
          <Alert severity="info" sx={{}}>
            Payment Details
          </Alert>
          {invoicePaymentData.length > 0 && (
            <div className="table-responsive p-0" style={{ overflow: "none" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Trasaction No</TableCell>
                    <TableCell>Method</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    {permission.InvoiceDeletePayment && (
                      <TableCell align="right"> Action</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(invoicePaymentData ?? [], (m, i) => (
                    <TableRow>
                      <TableCell align="left">
                        {displayDateFormat(m.PaymentDate, "dd-MM-yyyy")}
                      </TableCell>
                      <TableCell>{m.PaymentTrasactionNo}</TableCell>
                      <TableCell>{m.PaymentMethod}</TableCell>
                      <TableCell align="right">
                        {" "}
                        {/* {m.PaidAmount} */}
                        {formatCurrency(jsonData?.SettingDetails?.CurrencyCode, jsonData?.SettingDetails?.LocaleCode, m.PaidAmount)}
                        {/* <DisplayNumberFormatComponent Number={m.PaidAmount} Currency={data?.Symbol}/> */}
                      </TableCell>
                      {permission.InvoiceDeletePayment && (
                        <TableCell align="right">
                          {" "}
                          <ActionButton
                            onClick={() => fnRemove(m.InvoicePaymentId)}
                            IconName="Delete"
                            IconTooltip={"Delete payment details"}
                        />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>         
          )}
        </Paper>
      </Box>

      {permission.InvoiceAddPayment && totalDueAmt() > 0 && (
        <Box
          sx={{
            ...boxCommonStyles,
          }}
        >
          <Paper
            square
            elevation={0}
            sx={{
              ...commonStyles,
            }}
          >
            <Form
              defaultValues={{}}
              onSubmit={addPayment}
              validationSchema={schema}
              ref={formRef}
            >
              <Row>
                <Col className="col-md-6 col-12">
                  <FormInputText
                    name="PaymentDate"
                    type="date"
                    // min={DateTime.fromISO(data.ToDate).toFormat("yyyy-MM-dd")}
                    label="Payment Date"
                  />
                </Col>
                <Col className="col-md-6 col-12">
                  {" "}
                  <FormInputText
                    label="Transaction No."
                    name="PaymentTrasactionNo"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-md-6 col-12">
                  <FormInputDropdown
                    label="Method"
                    name="PaymentMethod"
                    ddOpt={[
                      { value: "NEFT", text: "NEFT" },
                      { value: "Cash", text: "Cash" },
                    ]}
                  />
                </Col>
                <Col className="col-md-6 col-12">
                  {" "}
                  <FormInputText
                    label="Amount"
                    type="number"
                    name="PaidAmount"
                  />
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col className="text-end">
                  {" "}
                  <Button type="submit" variant="outline-primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Paper>
        </Box>
      )}
      <p className="text-center italic">
        *This is machine generated payslip and doesn't require signature
      </p>
    </>
  );

const htmlCode = `<div className="mt-2 mb-2">
                    <div dangerouslySetInnerHTML= __html:${ replaceValues(htmlContent)} 
                  </div>`;
  return (
    <>
      <div className="d-flex flex-column">
        {(Message != "") && (
          <Alert
            severity="success"
            icon={<CheckCircleIcon fontSize="inherit" />}
          >
            {(MessageAlertTitle != "") && (
              <AlertTitle>{MessageAlertTitle}</AlertTitle>
            )}
            <span>
              <strong>
                {Message}
              </strong>
            </span>
          </Alert>
        )}

        <iframe srcDoc={htmlCode} style={{width: '100%', height: '800px'}}></iframe>

        {Payment}

        <div className="row p-3">
          {(ShowPrintBadgeBtn == 1 && permission?.InvoicePrint) && (
            <div className="col-md-6 col-6">
              <Button
                onClick={() => handlePrint()}
                variant="outline-primary"
                size="sm"
              >
                Print Invoice
              </Button>
            </div>
          )}

          {(ShowDownloadBadgeBtn == 1 && permission?.InvoiceDownload) && (
            <div className="col-md-6 col-6">
              <Button
                onClick={handleDownloadPdf}
                variant="outline-primary"
                className="float-end"
                size="sm"
              >
                <CloudDownloadIcon color="inherit" sx={{ fontSize: 20 }} />
                &nbsp; Download Invoice
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HtmlFileReaderForInvoice;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TablePagination,
  alpha,
  styled,
} from "@mui/material";
import {
  reduce,
} from "underscore";
import { StandardConst } from "./StandardConst";
import { WebService } from "./WebService";
import { InputText } from "../Component/Form";
import { ActionPermission } from "../Component/PageInfo";
import { useEffect } from "react";

export const MyTeam = () => {
    var ActiveClass = null;
    const [Dashboard, setDashboard] = useState([]);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(3);
    const afterRender = React.useRef(false);
    const dispatch = useDispatch();

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});

    useEffect(() => {
        SetPermission({
            ShowAllMember : ActionPermission("My Team - All Employees"),
        });
    }, [permissionList]);

    const fetchData = async (searchText) => {
      searchText ??= "";
      let EndPoint = `Project/Team/Member?search=${searchText}`;
      if(permission?.ShowAllMember){
        EndPoint = `Project/Team/AllMember?search=${searchText}`;
      }

      const v1 = await WebService({
        // dispatch,
        endPoint: EndPoint,
      });
      setDashboard(v1);
    };

    function handleChangePage(event, newpage) {
      setpg(newpage);
    }
    function handleChangeRowsPerPage(event) {
      setrpg(parseInt(event.target.value, 10));
      setpg(0);
    }
    useEffect(() => {
      fetchData();
    }, [permission]);
    const Search = styled("div")(({ theme }) => ({
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    }));
    const SearchIconWrapper = styled("div")(({ theme }) => ({
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }));
  
    return (
      <>
        <div className="card card-cd mb-3">
          <div className="d-flex flex-row mx-4 mt-4">
            <div className="col-4 d-flex justify-content-start">
              <h5 className="card-title">My Team</h5>
            </div>
            <div className="col-8 d-flex justify-content-end">
              <InputText
                setValue={async (v) => await fetchData(v ?? "")}
                placeholder="Search Team"
              />
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex flex-row mx-2 bg-light  overflow-auto">
              {Dashboard.slice(pg * rpg, pg * rpg + rpg).map((item) => (
                <div className="col-md-4 col-sm-4 col-12 p-1 d-flex align-items-stretch bg-light">
                  <div className="d-none">
                    {
                      (ActiveClass = [
                        item.Status === StandardConst.YesNo.Yes ? "success" : "warning",
                      ].join(" "))
                    }
                  </div>
  
                  <div className="card card-team border border-1 border-dark border-opacity-25 rounded shadow-2">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <div className="icon-image-up">
                            <img
                              className="rounded"
                              src={
                                item.ProfileImage != ""
                                  ? `${StandardConst.apiBaseUrl}/uploads/${
                                      item.ProfileImage ?? "user_placeholder.jpg"
                                    }`
                                  : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                              }
                            />
                          </div>
                          <div className="ms-2 c-details">
                            <h6 className="mb-0">{item.FullName}</h6>{" "}
                            <span className="small">{item.Designation}</span>
                          </div>
                        </div>
                        <div>
                          <span
                            className={`bordered accent badge badge-pill bg-soft-${ActiveClass} text-${ActiveClass} me-2`}
                          >
                            {item.Status === StandardConst.YesNo.Yes ? "Active" : "Inactive"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="small">
                            {(item.Roles ?? []).length > 0 && (
                              <p className="ws-font-10 mb-0">
                                {" "}
                                {reduce(
                                  item.Roles,
                                  (m, v) =>
                                    `${m} | ${v.ProjectName} (${v.DisplayDescription})`,
                                  ""
                                ).substring(2)}
                              </p>
                            )}
                            {(item.Roles ?? []).length < 1 && (
                              <p className="text-muted mb-0">
                                {" "}
                                {item.Designation}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-muted d-flex justify-content-around">
                      <button className="btn">
                        <a href={`mailto:${item.Email}`}>
                          <i className="fa fa-envelope"></i>
                        </a>
                      </button>
                      <button className="btn">
                        <a href={`tel:${item.Phone}`}>
                          <i className="fa fa-phone"></i>
                        </a>
                      </button>
                      <button className="btn">
                        <i className="fa fa-comments"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
  
            <TablePagination
              rowsPerPageOptions={[4]}
              component="div"
              count={Dashboard.length}
              rowsPerPage={rpg}
              page={pg}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </>
    );
};
import React, { useEffect, useState } from "react";
import "./HumanResourceDashboard.css";
import Button from "react-bootstrap/esm/Button";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
} from "recharts";
import { StandardConst } from "../../../Services/StandardConst";
import { WebService } from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, TablePagination, alpha, styled } from "@mui/material";
import {
  AttendanceSummary,
  ContactUs,
  DateRange,
  EmployeeOnLeave,
  NotificationIcon,
  ProfileSummary,
  SystemMaintenanceShowYellowBar,
} from "../Company/CompanyDashboard";
import { generateQueryStringByObject } from "../../../Services/UtilityService";
import { findWhere, first, map, reduce } from "underscore";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import DisplayNumberFormatComponent from "../../../Services/DisplayNumberFormatComponent";
import { PageInfo } from "../../PageInfo";
import {
  EmployeeAnniversaries,
  EmployeeAttendanceDayWise,
  EmployeeBirthdayCarousel,
  EmployeeLeaveStatus,
} from "../Employee/EmployeeDashboard";
import { ConfigurationActions } from "../DashboardComponent";
import { InputText } from "../../Form";
import { MyTeam } from "../../../Services/MyTeam";

const HumanResourceDashboard = () => {
  PageInfo({ pageTitle: "Dashboard" });

  return (
    <>
      <div className="container">
        <SystemMaintenanceShowYellowBar/>
        <div className="card card-cd mb-3 mt-3">
          <div className="card-body p-0 p-2">
            <div className="d-flex flex-column flex-lg-row flex-sm-row">
              <ProfileSummary className="col-md-5 col-sm-9" />
              <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
              <AttendanceSummary className="col-md-4 col-sm-3 d-flex justify-content-center align-items-center text-center" />
              <div className="vr my-2 col-md-1 col-sm-1 d-flex justify-content-end d-none d-md-inline"></div>
              <NotificationIcon className="col-md-3 col-sm-3 d-flex justify-content-end pe-3" />
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-md-4 col-sm-6 col-xs-6 d-flex align-items-stretch">
            <EmployeeCount />
          </div>
          <div className="col-md-4 col-sm-6 col-xs-6">
            <EmployeeOrigin />
          </div>
          {/* <div className="col-md-3 col-sm-6 col-xs-6">
            <EmployeePosition />
          </div> */}
          <div className="col-md-4 col-sm-6 col-xs-6 card card-cd mb-3">
            <EmployeeAttendance />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div
              className="row"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="col-md-8 col-sm-12 col-xs-12">
                <EmployeeLeaveStatus />
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <EmployeeAttendanceDayWise />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <PendingLeaves />
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <EmployeeSalaryStatus />
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <EmployeeApprovalPending />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <ConfigurationActions />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <MyTeam />
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeBirthdayCarousel />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeAnniversaries />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <EmployeeOnLeave />
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <ContactUs />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EmployeeCount = () => {
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [employeeOrigin, setEmployeeOrigin] = useState([]);
  const [noDataEmployeeOrigin, setNoDataEmployeeOrigin] = useState([
    { name: "Employees", value: 1 },
    { name: "New", value: 1 },
  ]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/OldNewEmployeeSummary?CompanyId=${
        companyInfo.CompanyId
      }&StartDate=${condition.StartDate.toUTC()
        .toSQL()
        .substring(0, 23)}&EndDate=${condition.EndDate.toUTC()
        .toSQL()
        .substring(0, 23)}`,
    }).then((r) => {
      setEmployeeOrigin([
        { name: "Employees", value: (r.totalCount ?? 0) - (r.totalNew ?? 0) },
        { name: "New", value: r.totalNew ?? 0 },
      ]);
    });
  };
  const [condition, setCondition] = useState({});
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0 && condition.StartDate !== undefined)
      Promise.all([fetchData()]);
  }, [companyInfo, condition]);

  const COLORS = [
    StandardConst.GraphColors.blue,
    StandardConst.GraphColors.green,
    StandardConst.GraphColors.lightGrey,
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        className="font-size-13"
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const NoDataRenderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return <></>;
  };
  return (
    <>
      <div className=" card card-cd mb-3 w-100">
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-6 d-flex justify-content-start">
            <h5 className="card-title">Employees</h5>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <DateRange
              setDateRange={({ StartDate, EndDate }) =>
                setCondition((dt) => ({ ...dt, StartDate, EndDate }))
              }
            />
          </div>
        </div>

        <div>
          {(employeeOrigin[0]?.value > 0 || employeeOrigin[1]?.value > 0) && (
            <div className="row d-flex flex-row">
              <div className="col-md-7 col-sm-12 d-flex flex-row justify-content-center d-flex justify-content-center align-items-center">
                <PieChart width={150} height={150}>
                  <Pie
                    data={employeeOrigin}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={60}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {employeeOrigin.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </div>
              <div className="col-md-5 col-sm-12 d-flex flex-md-column justify-content-center">
                <div className="d-flex flex-row my-2 font-size-13">
                  <div>
                    <Box
                      sx={{
                        width: 5,
                        height: 20,
                        backgroundColor: StandardConst.GraphColors.blue,
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column px-1">
                    <span>
                      Employees -{" "}
                      <strong>
                        {" "}
                        {
                          findWhere(employeeOrigin, { name: "Employees" })
                            ?.value
                        }
                      </strong>
                    </span>
                    {/* <span>
                      <strong>
                        {" "}
                        {
                          findWhere(employeeOrigin, { name: "Employees" })
                            ?.value
                        }
                      </strong>
                    </span> */}
                  </div>
                </div>

                <div className="d-flex flex-row my-2 font-size-13 ">
                  <div>
                    <Box
                      sx={{
                        width: 5,
                        height: 20,
                        backgroundColor: StandardConst.GraphColors.green,
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column px-1">
                    <span>
                      New Join -{" "}
                      <strong>
                        {" "}
                        {findWhere(employeeOrigin, { name: "New" })?.value}
                      </strong>
                    </span>
                    {/* <span>
                      <strong>
                        {" "}
                        {findWhere(employeeOrigin, { name: "New" })?.value}
                      </strong>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          )}

          {employeeOrigin[0]?.value <= 0 && employeeOrigin[1]?.value <= 0 && (
            <div className="row d-flex flex-row">
              <div className="col-md-7 col-sm-12 d-flex flex-row justify-content-center align-items-center">
                <PieChart width={150} height={150}>
                  <Pie
                    data={noDataEmployeeOrigin}
                    labelLine={false}
                    label={NoDataRenderCustomizedLabel}
                    outerRadius={60}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {noDataEmployeeOrigin.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[2]} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
              <div className="col-md-5 col-sm-12 d-flex flex-md-column justify-content-center">
                <div className="d-flex flex-row my-2 font-size-13">
                  <div>
                    <Box
                      sx={{
                        width: 5,
                        height: 40,
                        backgroundColor: StandardConst.GraphColors.lightGrey,
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column px-1">
                    <span>Employees</span>
                    <span>
                      <strong>0</strong>
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-row my-2 font-size-13">
                  <div>
                    <Box
                      sx={{
                        width: 5,
                        height: 40,
                        backgroundColor: StandardConst.GraphColors.lightGrey,
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column px-1">
                    <span>New Join</span>
                    <span>
                      <strong>0</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const EmployeeOrigin = () => {
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [employeeOrigin, setData] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/DesignationSummary?${generateQueryStringByObject({
        CompanyId: companyInfo.CompanyId,
      })}`,
    }).then((r) => {
      setData(
        map(r, (m) => ({
          name: m.Designation,
          value: m.NoOfEmployee,
        }))
      );
    });
  };
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0) Promise.all([fetchData()]);
  }, [companyInfo]);

  const COLORS = [
    StandardConst.GraphColors.blue,
    StandardConst.GraphColors.green,
    StandardConst.GraphColors.yellow,
    StandardConst.GraphColors.info,
    StandardConst.GraphColors.red,
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        className="font-size-13"
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <>
      {/* <div className="card card-cd mb-3  d-flex align-items-center text-center"> */}
      <div className="card card-cd mb-3">
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-md-6 col-sm-6 ms-2 d-flex justify-content-start align-items-center">
            <h5 className="card-title">Position</h5>
          </div>
          <div className="col-md-6 col-sm-6 d-flex justify-content-end"></div>
        </div>

        <div className="card-body card-body-cd p-0 mb-2">
          <div className="row d-flex flex-row">
            {/* <div className="col-md-7 col-sm-12 d-flex flex-row justify-content-center align-items-center"> */}
            <div className="col-7 d-flex flex-row justify-content-center align-items-center">
              <PieChart width={300} height={150}>
                <Pie
                  data={employeeOrigin}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={60}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {employeeOrigin.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </div>
            {/* <div className="col-md-5 col-sm-12 d-flex flex-md-column justify-content-center"> */}
            <div className="col-5">
              {employeeOrigin?.map((m, index) => (
                <div className="d-flex flex-row align-items-center font-size-13" key={index}>
                  <div>
                    <Box
                      sx={{
                        width: 5,
                        height: 20,
                        backgroundColor: COLORS[index % COLORS.length],
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column justify-content-start align-items-start p-1">
                    <span>
                      {m.name} - <strong> {m.value}</strong>
                    </span>
                    {/* <span>
                      <strong> {m.value}</strong>
                    </span> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EmployeePosition = () => {
  const employeePosition = [
    { name: "Location A", value: 400 },
    { name: "Location B", value: 300 },
    { name: "Location C", value: 300 },
    { name: "Location D", value: 200 },
    { name: "Location E", value: 278 },
    { name: "Location F", value: 189 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  return (
    <>
      <div className="card card-cd mb-3  d-flex align-items-center text-center">
        <div className="d-flex flex-row mx-4 mt-2 d-flex align-items-start">
          <h5 className="card-title">Position</h5>
          <hr></hr>
        </div>

        <div className="card-body card-body-cd p-0">
          <div className="d-flex flex-column">
            <PieChart width={300} height={150}>
              <Pie
                data={employeePosition}
                innerRadius={50}
                outerRadius={60}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {employeePosition.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      </div>
    </>
  );
};

const EmployeeAttendance = () => {
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [employeeAttendance, setEmployeeAttendance] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Attendance/DayWiseSummary?${generateQueryStringByObject({
        startTime: condition.StartDate.setZone("utc").toSQL().substring(0, 23),
        endTime: condition.EndDate.setZone("utc").toSQL().substring(0, 23),
        CompanyId: companyInfo.CompanyId,
      })}`,
    }).then((r) => {
      setEmployeeAttendance([
        { name: "Leave", value: parseInt(r.Leaves ?? "0") },
        { name: "Present", value: parseInt(r.Attend ?? "0") },
        { name: "Abesent", value: parseInt(r.Absent ?? "0") },
      ]);
    });
  };
  const [condition, setCondition] = useState({});
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0 && condition.StartDate !== undefined)
      Promise.all([fetchData()]);
  }, [companyInfo, condition]);

  const COLORS = [
    StandardConst.GraphColors.blue,
    StandardConst.GraphColors.green,
    StandardConst.GraphColors.yellow,
    "#FF8042",
  ];

  return (
    <>
      <div>
        <div className="d-flex flex-row m-2 mt-2">
          <div className="col-5 d-flex justify-content-start">
            <h5 className="card-title">Attendance</h5>
          </div>
          <div className="col-7 d-flex justify-content-end">
            <DateRange
              type="day"
              setDateRange={({ StartDate, EndDate }) =>
                setCondition((dt) => ({ ...dt, StartDate, EndDate }))
              }
            />
          </div>
        </div>

        <div>
          <div className="row d-flex flex-row">
            <div className="col-md-7 col-sm-12 d-flex flex-row justify-content-center align-items-center">
              <PieChart width={150} height={150}>
                <Pie
                  data={employeeAttendance}
                  innerRadius={50}
                  outerRadius={60}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {employeeAttendance.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </div>
            <div className="col-md-5 col-sm-12 d-flex flex-md-column justify-content-center">
              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.green,
                    }}
                  />
                </div>

                <div className="d-flex flex-column p-1">
                  <span>
                    Present -{" "}
                    <strong>
                      {" "}
                      {
                        findWhere(employeeAttendance, { name: "Present" })
                          ?.value
                      }
                    </strong>
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.blue,
                    }}
                  />
                </div>

                <div className="d-flex flex-column p-1">
                  <span>
                    Leave -{" "}
                    <strong>
                      {" "}
                      {findWhere(employeeAttendance, { name: "Leave" })?.value}
                    </strong>
                  </span>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center font-size-13">
                <div>
                  <Box
                    sx={{
                      width: 5,
                      height: 20,
                      backgroundColor: StandardConst.GraphColors.yellow,
                    }}
                  />
                </div>

                <div className="d-flex flex-column p-1">
                  <span>
                    Abesent -{" "}
                    <strong>
                      {" "}
                      {
                        findWhere(employeeAttendance, { name: "Abesent" })
                          ?.value
                      }
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EmployeeSalaryStatus = () => {
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const dispatch = useDispatch();
  const [employeeSalaryStatus, setEmployeeSalaryStatus] = useState([]);
  const [limit, setLimit] = useState(5);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `Report/SalaryPendingStatus?${generateQueryStringByObject({
        CompanyId: companyInfo.CompanyId,
        StartDate: DateTime.now()
          .startOf("month")
          .toUTC()
          .toSQL()
          .substring(0, 23),
        EndDate: DateTime.now().endOf("month").toUTC().toSQL().substring(0, 23),
      })}`,
    }).then((r) => {
      setEmployeeSalaryStatus(
        map(r, (m) => {
          if (m.EmployeeImage !== null)
            m.EmployeeImage = `${StandardConst.uploadImages}${m.EmployeeImage}`;
          m.Month = DateTime.fromISO(m.FromDate).toLocal().toFormat("MMM-yy");
          return m;
        })
      );
    });
  };
  useEffect(() => {
    if ((companyInfo.CompanyId ?? 0) !== 0) Promise.all([fetchData()]);
  }, [companyInfo]);

  return (
    <div className="card card-cd mb-3">
      <div className="d-flex flex-row mx-4 mt-4">
        <div className="col-8 d-flex justify-content-start">
          <h5 className="card-title">Salary Pending Status</h5>
        </div>
        <div className="col-4 d-flex justify-content-end">
          Total : {employeeSalaryStatus.length ?? 0}
        </div>
      </div>
      <div className="card-body card-body-cd">
        {employeeSalaryStatus.length <= 0 && (
          <>
            <div className="d-flex justify-content-center justify-items-center">
              <span className="text-muted text-center">
                <b>No Salary Pending Status Found</b>
              </span>
            </div>
          </>
        )}
        {employeeSalaryStatus.length > 0 && (
          <>
            {first(employeeSalaryStatus, limit).map((row, index) => (
              <div className="d-flex flex-column" key={index}>
                <div className="row py-1">
                  <div className="col-2">
                    {" "}
                    <Avatar
                      alt={row?.Employee}
                      src={row?.EmployeeImage}
                      sx={{
                        width: 40,
                        height: 40,
                      }}
                      loading="lazy"
                    />
                  </div>
                  <div className="col-4">
                    <span className="h6 font-size-13 m-0 text-center">
                      {row?.Employee}
                    </span>
                    <h6 className="font-size-13 text-success">
                      {" "}
                      {row?.Designation}
                    </h6>
                  </div>
                  <div className="col-3">
                    <span className="text-success font-size-13">Month</span>
                    <h6 className="font-size-13">{row.Month}</h6>
                  </div>
                  <div className="col-3 text-right">
                    <span
                      className={`badge  ${
                        row.SalaryStatus == "Pending"
                          ? "text-bg-danger"
                          : row.SalaryStatus == "Processed"
                          ? "text-bg-success"
                          : "text-bg-primary"
                      }`}
                    >
                      {row.SalaryStatus}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {employeeSalaryStatus.length > limit && (
              <Button
                className="w-100"
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  setLimit((l) => l + 5);
                }}
              >
                More 5
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const EmployeeApprovalPending = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employeeApprovalPending, setEmployeeApprovalPending] = useState([]);
  const fetchData = async () => {
    await WebService({
      endPoint: `CommonUtility/employees?${generateQueryStringByObject({
        where: `employees.StatusId eq ${StandardConst.Status.Pending} and employees.Step eq 1`,
        select: "employees.FullName,staticstatus.Status, employees.CreateAt",
        expand: "staticstatus",
      })}`,
      dispatch,
    }).then((r) => {
      setEmployeeApprovalPending(
        map(r, (m) => ({
          Employee: m.FullName,
          Status: m.Status,
          EmployeeImage: `${StandardConst.ProjectImagesInPublicAssets}/avatar1.png`,
          SubmittedOn: DateTime.fromISO(m.CreateAt)
            .toLocal()
            .toFormat("dd-MMM-yy"), //"10-MAY-23",
        }))
      );
    });
  };
  useEffect(() => {
    Promise.all([fetchData()]);
  }, []);

  return (
    <div className="card card-cd mb-3">
      <div className="d-flex flex-row mx-4 mt-4">
        <div className="col-8 d-flex justify-content-start">
          <h5 className="card-title">Pending Approval</h5>
        </div>
        <div className="col-4 d-flex justify-content-end">
          Total : {employeeApprovalPending.length ?? 0}
        </div>
      </div>
      <div className="card-body card-body-cd">
        {employeeApprovalPending.length <= 0 && (
          <>
            <div className="d-flex justify-content-center justify-items-center">
              <span className="text-muted text-center">
                <b>No Pending Approval Found</b>
              </span>
            </div>
          </>
        )}
        {employeeApprovalPending.length > 0 && (
          <>
            {employeeApprovalPending.map((row, index) => (
              <div className="d-flex flex-column" key={index}>
                <div className="row py-1">
                  <div className="col-2">
                    <Avatar
                      alt={row?.Employee}
                      src={row?.EmployeeImage}
                      sx={{
                        width: 40,
                        height: 40,
                      }}
                      loading="lazy"
                    />
                  </div>
                  <div className="col-4">
                    <span className="h6 font-size-13 m-0 text-center">
                      {row?.Employee}
                    </span>
                    <br />
                    <span
                      className={`font-size-13 ${
                        row.Status == "Rejected"
                          ? "text-anger"
                          : row.Status == "Approved"
                          ? "text-success"
                          : "text-primary"
                      }`}
                    >
                      {row?.Status}
                    </span>
                  </div>
                  <div className="col-3 p-0">
                    <span className="text-primary font-size-13">Submitted</span>
                    <h6 className="font-size-13">{row?.SubmittedOn}</h6>
                  </div>
                  <div className="col-3 text-right p-1">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => navigate(`/RegistrationApproval`)}
                    >
                      Approve
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const PendingLeaves = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pendingLeaves, setPendingLeaves] = useState([]);
  const fetchData = async () => {
    await WebService({
      dispatch,
      endPoint: `ApplyLeave/Fetch/AllLeave?${generateQueryStringByObject({
        where: `StatusId eq ${StandardConst.Status.Applied}`,
        setect:
          "ProfileImage, LeavesId, FullName, LeaveFromDate, LeaveName, LeaveToDate, NoOfLeaves",
      })}`,
    }).then((r) => {
      setPendingLeaves(
        map(r, (m) => ({
          Id: m.LeavesId,
          Employee: m.FullName,
          EmployeeImage:
            m.ProfileImage === null
              ? ""
              : `${StandardConst.uploadImages}${m.ProfileImage}`,
          LeaveType: m.LeaveName,
          From: DateTime.fromISO(m.LeaveFromDate).toFormat("dd-MMM"),
          To: DateTime.fromISO(m.LeaveToDate).toFormat("dd-MMM"),
          Days: `${m.NoOfLeaves} Day${m.NoOfLeaves > 1 ? "s" : ""}`,
        }))
      );
    });
  };
  useEffect(() => {
    Promise.all([fetchData()]);
  }, []);

  return (
    <div className="card card-cd mb-3">
      <div className="d-flex flex-row mx-4 mt-4">
        <div className="col-8 d-flex justify-content-start">
          <h5 className="card-title">Pending Leaves</h5>
        </div>
        <div className="col-4 d-flex justify-content-end">
          Total : {pendingLeaves?.length ?? 0}
        </div>
      </div>

      <div className="card-body card-body-cd">
        {pendingLeaves.length <= 0 && (
          <>
            <div className="d-flex justify-content-center justify-items-center">
              <span className="text-muted text-center">
                <b>No Pending Leaves Found</b>
              </span>
            </div>
          </>
        )}
        {pendingLeaves.length > 0 && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Employee</TableCell>

                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Days</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingLeaves.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <div className="d-flex flex-row">
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          alt={row.Employee ?? ""}
                          src={row.EmployeeImage}
                        />
                        <span className="ms-2"> {row.Employee ?? ""}</span>
                      </div>
                    </TableCell>
                    <TableCell align="center">{row.LeaveType}</TableCell>
                    <TableCell align="center">
                      {row.To ?? ""}&nbsp;-&nbsp;
                      {row.From ?? ""}
                    </TableCell>

                    <TableCell align="center">{row.Days ?? ""}</TableCell>
                    <TableCell align="right">
                      {" "}
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => {
                          navigate(`/LeaveApproval?search=Pending`);
                        }}
                      >
                        Approve
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default HumanResourceDashboard;

import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "mx-1 btn btn-outline-success",
    cancelButton: "mx-1 btn btn-outline-danger",
  },
  buttonsStyling: false,
});
export const WSSuccessAlert = (Title, Message) => {
  Swal.fire({
    title: Title,
    text: Message,
    icon: "success",
    timer: 3000,
    showCloseButton: true,
    showConfirmButton:false,
    html: Message
  });
};
export const WSWarningAlert = (Title, Message) => {
  Swal.fire({
    title: Title,
    text: Message,
    icon: "warning",
    timer: 3000,
    showCloseButton: true,
    showConfirmButton:false
  });
};
export const WSErrorAlert = (Title, Message) => {
  swalWithBootstrapButtons.fire({
    title: Title,
    text: Message,
    icon: "error",
    showCloseButton: true
  });
};
export const WSInfoAlert = (Title, Message) => {
  swalWithBootstrapButtons.fire({
    title: Title,
    text: Message,
    icon: "info",
    showCloseButton: true
  });
};

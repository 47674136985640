import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown, FormAutoCompleteDropdownCard } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission } from "../PageInfo";
import { StandardConst } from "../../Services/StandardConst";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditBankDetails = (prop, ref) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const AssignRole = useSelector((s) => s.auth.AssignRole);
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [employee, setEmployee] = useState(false);
  const permission = {
    ManageSearchAllUser: ActionPermission("Bank Details - Search All User"),
  };
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ BankDetails: {} });
  const [customFieldsData, setCustomFieldsData] = useState([]);

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id > 0){
        setShow(false);
        await fetchData(id);
        setShow(true);
      }
    },
  }));

  const [EmployeesList, setEmployeesList] = useState([]);

  const fetchUserList = async () => {
    const userData = await WebService({
      endPoint: 'FinanceTemplate/userList',
      method: 'GET',
      dispatch,
      requiredLoader: false
    });

    const userList = userData?.filter((r) => r.FullName !== null)?.map((res) => {
      return {
          EmployeeId: res.EmployeeId,
          heading: res.FullName, 
          description: res.Designation, 
          avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
        }
    });
    setEmployeesList(userList);
  };
  
  const fetchData = async(id) => {
    const data = await WebService({
      endPoint: `FinanceTemplate/Fetch/${id || 0}`,
      dispatch,
    });

    data.finance.EmployeeId = [EmployeesList?.find(item => item?.EmployeeId === data?.finance?.EmployeeId)];
    setData(data);
  }

  const handleClose = () => {
    setShow(false);
    setData({ BankDetails: {} });
  }
  
  const checkEmployee = () => {
    AssignRole.map((element) => {
      if(element === StandardConst.SystemRole.Employee){
        setEmployee(true);
      }
      return null;
    });
  }

  useEffect(() => {
    Promise.all([
      fetchUserList(),
      checkEmployee()
    ])
  }, []);

  useEffect(() => {
    if(companyInfo?.CountryId){
      fetchCustomfields();
    }
  }, [companyInfo]);

  const requiredMessage = "This is a required field";
  const staticBankDetailSchema = {
    NameInAccount: yup.string().trim().required(requiredMessage),
    BankName: yup.string().trim().required(requiredMessage),
    BankAccountNo: yup
      .string()
      .max(34, "Max 34 digit allowed")
      .required(requiredMessage)
      .matches(/^[0-9]+$/, "Must be only digits"),
    EmployeeId: !employee && yup
    .array()
    .of(
        yup.object().shape({
            heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
    )
    .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
    .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
  }
  const [schemaState, setSchemaState] = useState({});
  const schema = yup.object().shape(schemaState);

  const fetchCustomfields = async () => {
    return await WebService({
      endPoint: `FinanceTemplate/fetchBankDetailCustomFields?CountryId=${companyInfo?.CountryId}`,
      dispatch,
      requiredLoader: false
    }).then((res) => {
      res.map(item => {
          if(item.FieldIsRequired === StandardConst.YesNo.Yes) {
              if(item.FieldType === StandardConst.dataTypes.String){
                  staticBankDetailSchema[item.FieldName] = yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg);
              }else if(item.FieldType === StandardConst.dataTypes.Date){
                  staticBankDetailSchema[item.FieldName] = yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg);
              }else if(item.FieldType === StandardConst.dataTypes.Number || item.FieldType === StandardConst.dataTypes.Number){
                  staticBankDetailSchema[item.FieldName] = yup.number().typeError(StandardConst.ValidationMessages.InvalidCharMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg);
              }
          }
      })
      setSchemaState(staticBankDetailSchema);
      const sortedDataToAscendingOrder = res.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      setCustomFieldsData(sortedDataToAscendingOrder);
      return res;
    });
  };

  const onSubmit = async (data) => {

    if (permission.ManageSearchAllUser === false) {
      data.EmployeeId = loggedUser;
    }else if(data.EmployeeId !== undefined) {
      data.EmployeeId = data.EmployeeId[0].EmployeeId;
    }

    let FinanceId = data.FinanceId;
    if (data.FinanceId === undefined) {
      if(employee) data.EmployeeId = loggedUser;
      FinanceId = await WebService({
        endPoint: "FinanceTemplate/Create",
        body: data,
        dispatch,
      });
    } else {
      customFieldsData.map(item => item.FieldValue = data[item.FieldName]);
      await WebService({
        endPoint: `CommonUtility/employeefinance?FinanceId=${data.FinanceId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }

    if(FinanceId !== undefined){
      await saveCustomFieldData(FinanceId);
    }

    handleClose();
    prop.callBackEvent();
  };

  const saveCustomFieldData = async (FinanceId) => {
    if((customFieldsData.length > 0) && (FinanceId !== null)){
        const AllCustomFieldsValueDelete = await WebService({
            endPoint: `CommonUtility/bankdetailcustomfieldvalues?FinanceId=${FinanceId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => true);
        
        if(AllCustomFieldsValueDelete){
            customFieldsData.map(async (item) => {
                item['FinanceId'] = FinanceId;
                await WebService({
                    endPoint: `CommonUtility/bankdetailcustomfieldvalues`,
                    body: item,
                    method: 'POST',
                    dispatch,
                })
            })
        }
    }
  };


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data?.finance?.FinanceId || 0) === 0
            ? "Add Bank Details"
            : "Edit Bank Details"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.finance}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <div>
          <Modal.Body className="p-4">
            <div className="row">
              {permission.ManageSearchAllUser === true && (
                <div className="col-md-12 mb-1">
                  <FormAutoCompleteDropdownCard
                    name="EmployeeId"
                    data={EmployeesList}
                    label="Select Employee"
                    optionText="heading"
                    value={data?.finance?.EmployeeId !== undefined ? data?.finance?.EmployeeId[0] : ''}
                    isRequired="true"
                    placeholder="Choose a Employee"
                  />
                </div>
              )}
              <div className="col-md-12 mt-1">
                <FormInputText
                  label="Name In Account"
                  name="NameInAccount"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  label="Bank Name"
                  name="BankName"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  label="Bank Account Number"
                  name="BankAccountNo"
                  type="text"
                  isRequired="true"
                />
              </div>

              <div className="col-md-12">
                <FormInputDropdown
                  name="StatusId"
                  ddOpt={StandardConst.ActiveInActiveStatusIdDropdown}
                  label="Status"
                ></FormInputDropdown>
              </div>
              {(customFieldsData.length > 0) && (
                customFieldsData.map(CustomField => {
                  const FieldType = CustomField.FieldType;
                  // const FieldValue = (CustomField.FieldValue !== undefined) ? CustomField.FieldValue : "";
                  if(FieldType !== StandardConst.dataTypes.Date){
                      return <div className="col-md-12">
                          <FormInputText
                              label={CustomField.FieldLabel}
                              name={CustomField.FieldName}
                              type={FieldType}
                              isRequired={CustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                              setValue={(val) => CustomField['FieldValue'] = val}
                          />
                      </div>
                  }else{
                      return <div className="col-md-12">
                          <FormInputText
                              label={CustomField.FieldLabel}
                              name={CustomField.FieldName}
                              type="date"
                              isRequired={CustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                              setValue={(val) => CustomField['FieldValue'] = val}
                          />
                      </div>
                  }
                })
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="btnBankDetailsSubmit"
              variant="outline-primary"
              type="submit"
            >
              {" "}
              {(data?.finance?.FinanceId || 0) === 0 ? "Submit" : "Save Changes"}{" "}
            </Button>
          </Modal.Footer>
        </div>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditBankDetails);

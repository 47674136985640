import React, { useCallback, useEffect, useRef, useState } from "react";
import "./ProfileImageUpload.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { WebService } from "./WebService";
import { useDispatch } from "react-redux";
import { IconButton, Tooltip, Button } from "@mui/material";
import { StandardConst } from "./StandardConst";
import Modal from "react-bootstrap/Modal";
import Webcam from 'react-webcam';
import ActionButton from "./ActionButton";
import { WSWarningAlert } from "./WSAlert";

const ProfileImageUpload = ({ UploadText, UploadedFileName }) => {
  UploadText ??= "Upload Image";
  const [imageFile, setImageFile] = useState(null);

  const [imageURL, setImageURL] = useState("");
  const dispatch = useDispatch();

  const handleUpload = () => {
    console.log("Uploading image:", imageFile);
  };

  const saveFile = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageURL(reader.result);
      };
      reader.readAsDataURL(file);

      console.log("Uploading image:", imageFile);
    }
    const userphotodata = await WebService({
      endPoint: "upload/File",
      body: e.target.files[0],
      dispatch,
      isFile: true,
    }).then((res) => {
      console.log("Uploading image:", res.filename);
      UploadedFileName(res.filename);
    });
  };

  return (
    <div className="profile-image-container">
      <div className="profile-image-wrapper">
        {imageURL ? (
          <img className="profile-image" src={imageURL} alt="Profile" />
        ) : (
          <div className="upload-placeholder">{UploadText}</div>
        )}
        <input
          type="file"
          accept="image/*"
          className="file-input"
          onChange={saveFile}
        />
        {imageURL && (
          <CloudUploadIcon
            sx={{ fontSize: 40 }}
            color="primary"
            className="upload-button"
            onClick={handleUpload}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileImageUpload;


const ShowProfileImageUploadedByCapture = ({UploadText, captureImageUrl, onClick}) => {
  UploadText ??= "Capture Image";

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="profile-image-container" onClick={handleClick}>
      <div className="profile-image-wrapper">
        {captureImageUrl ? (
          <img className="profile-image" src={captureImageUrl} alt="Profile" />
        ) : (
          <div className="upload-placeholder">{UploadText}</div>
        )}
      </div>
    </div>
  );
};

const UploadOrClickProfilePicture = ({endPointUrl, UploadedFileName, ShowProfilePicture, onSubmittedClicked, ShowSrcUrl}) => {

  const [imageURL, setImageURL] = useState("");
  const [captureFileName, setCaptureFileName] = useState("");
  const [uploadedFile, setuploadedFile] = useState();
  var dispatch = useDispatch();
  const [ShowPictureInSrc, setShowPictureInSrc] = useState(
    ShowProfilePicture ? 
    `${StandardConst.apiBaseUrl}/uploads/visitorPhotos/${ShowProfilePicture}`
    : false
    );

    useEffect(() => {
      if(ShowSrcUrl){
        setShowPictureInSrc(`${ShowSrcUrl}/${ShowProfilePicture}`)
      }
    },[ShowSrcUrl]);

  const [show, setShow] = useState(false);

  const webcamRef = useRef(null);



  const handleUploadProfilePicture = async(e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setImageURL(e.target.result);
        setCaptureFileName("");
        setShowPictureInSrc("");
      };
      reader.readAsDataURL(file);
    }else {
      setImageURL("");
    }
    await WebService({
      endPoint: endPointUrl ? endPointUrl : "upload/File?destination=visitorPhotos",
      body: e.target.files[0],
      dispatch,
      isFile: true,
    }).then((res) => {
      setuploadedFile(res.filename);
      UploadedFileName(res.filename);
    });
  }

  const openCaptureModal = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptureFileName(imageSrc);
    captureImageUpload(imageSrc);
    setShow(false);
  }, [webcamRef]);


  const [uploadCaptureImage, setUploadCaptureImage ] = useState("");


  function base64ToBlob(base64, mime) {
    mime = mime || '';
    const imageString = base64.replace("data:image/jpeg;base64,", "")
    const byteCharacters = atob(imageString);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  }

  const captureImageUpload = async(imageData) => {
    const base64Data = imageData;
    const mime = 'image/jpeg'; 
    const blob = base64ToBlob(base64Data, mime);
    const file = new File([blob], 'image.jpeg', { type: mime });
      
    await WebService({
      endPoint: endPointUrl ? endPointUrl : "upload/File?destination=visitorPhotos",
      body: file,
      dispatch,
      isFile: true,
    })
    .then((res) => {
      setUploadCaptureImage(res.filename);
      UploadedFileName(res.filename);
      setShowPictureInSrc("");
    });
  }
  
  useEffect(() => {
        setCaptureFileName("");
        setImageURL("");
  }, [onSubmittedClicked]);

  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const toggleCamera = () => {
    setIsFrontCamera(prevState => !prevState);
  };
  
  return (
    <>
      <div className="pic-holder">
                    <img
                      className="pic rounded-circle"
                      src={
                        imageURL || captureFileName || ShowPictureInSrc ? ShowPictureInSrc || captureFileName || imageURL
                        : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                      }
                      alt="loading..."
                    />
                    <input
                      className="uploadProfileInput"
                      type="file"
                      name="File"
                      id="uploadProfilePicture"
                      accept="image/*"
                      style={{ opacity: "0", display: "contents" }}
                      onChange={handleUploadProfilePicture}
                    />
                    <label
                      htmlFor="newVisitorPhoto"
                      className="upload-file-block"
                    >
                      <div className="text-center">
                        <div className="mb-1">
                        <Tooltip title="Capture Image">
                            <IconButton>
                            <i className="fa fa-camera fa-2x"
                            style={{ fontSize: '1.3em', color:"white" }}
                              onClick={() => { openCaptureModal(); }}
                            >
                            </i>
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div>
                        <Tooltip title="Upload Image">
                            <IconButton>
                              <label htmlFor="uploadProfilePicture">
                              <i className="fa fa-upload fa-2x" style={{fontSize: '1.3em', color: "white"}}></i>
                              </label>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </label>
                  </div>


                  {/* model here  */}
                  <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                    {StandardConst.CaptureYourPhoto}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-4">
                <div className="row">
                  <div className="col-md-12">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{ facingMode: isFrontCamera ? StandardConst.Camera.FrontCamera : { exact: StandardConst.Camera.BackCamera } }}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  id="btnDepartmentSubmit"
                  variant="outlined"
                  onClick={handleCapture}
                >
                  {" "}
                  { StandardConst.CapturePicture}
                </Button>
                <ActionButton
                  onClick={() => toggleCamera()}
                  IconName="SwitchCameraLarge"
                  IconTooltip="Switch Camera"
                  id="SwitchCamera"
                />
              </Modal.Footer>
            </Modal>
    </>
  )
}


const ReactangleShapeOfUploadPicture = ({endPointUrl, UploadedFileName, ShowProfilePicture, onSubmittedClicked, ShowSrcUrl, multipleSelect}) => {

  const [imageURL, setImageURL] = useState("");
  const [captureFileName, setCaptureFileName] = useState("");
  const [uploadedFile, setuploadedFile] = useState();
  var dispatch = useDispatch();
  const [ShowPictureInSrc, setShowPictureInSrc] = useState(
    ShowProfilePicture ? 
      ShowSrcUrl
    : false
    );

    useEffect(() => {
      if(ShowSrcUrl){
        setShowPictureInSrc(`${ShowSrcUrl}/${ShowProfilePicture}`)
      }
    },[ShowSrcUrl]);
  const [show, setShow] = useState(false);

  const webcamRef = useRef(null);



  const handleUploadProfilePicture = async(e) => {

    // this is for multiple photos 
    if (e.target.files.length > 1) {
      const selectedFiles = Array.from(e.target.files);
      const multipleFiles = await Promise.all(selectedFiles.map(async (file) => {
        if (file.type === "application/pdf") {
          WSWarningAlert("Pdf", "Pdf file can't upload here");
        } else {
          if (file) {
            const reader = new FileReader();
            reader.onloadend = (e) => {
              // setImageURL(e.target.result);
              setCaptureFileName("");
              setShowPictureInSrc("");
            };
            reader.readAsDataURL(file);
          } else {
            setImageURL("");
          }
          const res = await WebService({
            endPoint: endPointUrl,
            body: file,
            dispatch,
            isFile: true,
          });
          setuploadedFile(res.filename);
          return res.filename;
        }
      }));
      UploadedFileName(multipleFiles);
    } else {
      const file = e.target.files[0];
      if (file.type === "application/pdf") {
        WSWarningAlert("Pdf", "Pdf file can't upload here");
      } else {
        // this is for single photo
        if (file) {
          const reader = new FileReader();
          reader.onloadend = (e) => {
            setImageURL(e.target.result);
            setCaptureFileName("");
            setShowPictureInSrc("");
          };
          reader.readAsDataURL(file);
        } else {
          setImageURL("");
        }
        await WebService({
          endPoint: endPointUrl,
          body: e.target.files[0],
          dispatch,
          isFile: true,
        }).then((res) => {
          setuploadedFile(res.filename);
          if (multipleSelect) {
            UploadedFileName([res.filename]);
          } else {
            UploadedFileName(res.filename);
          }
        });
      }
    }
  }

  const openCaptureModal = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptureFileName(imageSrc);
    captureImageUpload(imageSrc);
    setShow(false);
  }, [webcamRef]);


  const [uploadCaptureImage, setUploadCaptureImage ] = useState("");


  function base64ToBlob(base64, mime) {
    mime = mime || '';
    const imageString = base64.replace("data:image/jpeg;base64,", "")
    const byteCharacters = atob(imageString);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  }

  const captureImageUpload = async(imageData) => {
    const base64Data = imageData;
    const mime = 'image/jpeg'; 
    const blob = base64ToBlob(base64Data, mime);
    const file = new File([blob], 'image.jpeg', { type: mime });
      
    await WebService({
      endPoint: endPointUrl,
      body: file,
      dispatch,
      isFile: true,
    })
    .then((res) => {
      setUploadCaptureImage(res.filename);
      UploadedFileName(res.filename);
      setShowPictureInSrc("");
    });
  }
  
  useEffect(() => {
        setCaptureFileName("");
        setImageURL("");
  }, [onSubmittedClicked]);


  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const toggleCamera = () => {
    setIsFrontCamera(prevState => !prevState);
  };
  
  return (
    <>
      <div className="pic-holder" 
      style={{
        borderRadius: "8px",
        width: "260px",
        border: "2px solid #e7eaf0"
      }}>
                    <img
                      src={
                        imageURL || captureFileName || ShowPictureInSrc ? ShowPictureInSrc || captureFileName || imageURL
                        : `${StandardConst.apiBaseUrl}/uploads/placeholder-image-default.png`
                      }
                      alt="loading..."
                    />
                    <input
                      className="uploadProfileInput"
                      type="file"
                      name="File"
                      id="uploadProfilePicture"
                      accept="image/*"
                      style={{ opacity: "0", display: "contents" }}
                      onChange={handleUploadProfilePicture}
                      multiple
                    />
                    <label
                      htmlFor="newVisitorPhoto"
                      className="upload-file-block"
                    >
                      <div className="text-center">
                        <div className="mb-1">
                        <Tooltip title="Capture Image">
                            <IconButton>
                            <i className="fa fa-camera fa-2x"
                            style={{ fontSize: '1.3em', color:"white" }}
                              onClick={() => { openCaptureModal(); }}
                            >
                            </i>
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div>
                        <Tooltip title="Upload Image">
                            <IconButton>
                              <label htmlFor="uploadProfilePicture">
                              <i className="fa fa-upload fa-2x" style={{fontSize: '1.3em', color: "white"}}></i>
                              </label>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </label>
                  </div>


                  {/* model here  */}
                  <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                    {StandardConst.CaptureYourPhoto}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-4">
                <div className="row">
                  <div className="col-md-12">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{ facingMode: isFrontCamera ? StandardConst.Camera.FrontCamera : { exact: StandardConst.Camera.BackCamera } }}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  id="btnDepartmentSubmit"
                  variant="outlined"
                  onClick={handleCapture}
                >
                  {" "}
                  { StandardConst.CapturePicture}
                </Button>
                <ActionButton
                  onClick={() => toggleCamera()}
                  IconName="SwitchCameraLarge"
                  IconTooltip="Switch Camera"
                  id="SwitchCamera"
                />
              </Modal.Footer>
            </Modal>
    </>
  )
}

export { ShowProfileImageUploadedByCapture, UploadOrClickProfilePicture, ReactangleShapeOfUploadPicture }

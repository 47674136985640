import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { Radio } from "@mui/material";
import { WSErrorAlert } from "../../Services/WSAlert";
const { forwardRef, useState, useImperativeHandle } = React;

const AddEditSubscriptionDetail = (props, ref) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showDiscountInput, setShowDiscountInput] = useState({});
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [data, setData] = useState({ SubscriptionDetails: {} });
    const [PricePerUserValue, setPricePerUserValue] = useState(0);
    const [DefaultFillFinalPrice, setDefaultFillFinalPrice] = useState(0);
    const [selectedDiscountTypeRadio, setSelectedDiscountTypeRadio] = useState(StandardConst.DiscountTypeRadio.Fixed);
    const [DiscountValue, setDiscountValue] = useState(0);
    const [showFixedDiscountValue, setShowFixedDiscountValue] = useState(0);
    
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            setSelectedDiscountTypeRadio(StandardConst.DiscountTypeRadio.Fixed);
            if (id === 0) {
                setData({ SubscriptionDetails: {} })
            } else {
                setShow(false);
                const data = {
                    SubscriptionDetails: await WebService({
                        endPoint: `CommonUtility/Edit/subscription_details?SubscriptionDetailId=${id || 0}`,
                        dispatch,
                    }).then((c) => (c.length > 0 ? c[0] : {}))
                }

                setPricePerUserValue(data?.SubscriptionDetails.PricePerUser);
                setDiscountValue(data?.SubscriptionDetails.Discount);
                setDefaultFillFinalPrice(data?.SubscriptionDetails.FinalPrice);
                setSelectedDiscountTypeRadio(data?.SubscriptionDetails.DiscountInPercentage > 0 ? StandardConst.DiscountTypeRadio.Percentage : StandardConst.DiscountTypeRadio.Fixed);
                setShowFixedDiscountValue(data?.SubscriptionDetails.Discount);
                setData(data);
                setShow(true);
            }
        },
    }));


    const handleClose = () => {
        setShow(false);
        setDefaultFillFinalPrice(0);
        setPricePerUserValue(0);
        setDiscountValue(0);
    }

    const schema = yup
        .object()
        .shape({
            SubType: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Duration: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            UserLimitFrom: yup.number().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            UserLimitTo: yup.number().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            PricePerUser: yup.number().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            FinalPrice: yup.number().default(DefaultFillFinalPrice).required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Discount: showDiscountInput.TextInput ? yup.number().required(StandardConst.ValidationMessages.RequiredFieldMsg) : null,
            DiscountInPercentage: showDiscountInput.PercetageInput ? yup.number().required(StandardConst.ValidationMessages.RequiredFieldMsg) : null,
        })
        .required();

    const onSubmit = async (data) => {
        if(parseFloat(data.Discount) > parseFloat(data.PricePerUser)){
            WSErrorAlert("Error", "Discount value not more than Price Per User value");
            return false;
        }
        
        data.SubscriptionId = props.SubscriptionId
        data.FinalPrice = DefaultFillFinalPrice;
        if(showDiscountInput.TextInput){
            data.DiscountInPercentage = 0;
        }
        if(showDiscountInput.PercetageInput){
            data.Discount = showFixedDiscountValue;
        }
        
        if (data.SubscriptionDetailId === undefined) {
            await WebService({
                endPoint: "CommonUtility/subscription_details",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/subscription_details?SubscriptionDetailId=${data.SubscriptionDetailId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    };


    const handleChange = (event) => {
        setSelectedDiscountTypeRadio(event.target.value);
        setShowFixedDiscountValue(0);
        setDefaultFillFinalPrice(0);
    };

    useEffect(() => {
        if(selectedDiscountTypeRadio === StandardConst.DiscountTypeRadio.Percentage){
            setShowDiscountInput({PercetageInput: true, TextInput: false});
        }else {
            setShowDiscountInput({PercetageInput: false, TextInput: true});
        }
    }, [selectedDiscountTypeRadio]);

    const handleChangeDiscountInputValue = (DiscountValue) => {
        if(DiscountValue === undefined || DiscountValue === ''){
            DiscountValue = 0;
        }
        if(parseFloat(DiscountValue) > parseFloat(PricePerUserValue)){
            WSErrorAlert("Error", "Discount value not more than Price Per User value");
        }else{
            setDiscountValue(DiscountValue);
            if(showDiscountInput.TextInput){
                const _finalPrice = PricePerUserValue - DiscountValue;
                setShowFixedDiscountValue(DiscountValue);
                setDefaultFillFinalPrice(_finalPrice);
            }else if(showDiscountInput.PercetageInput){
                const _finalPrice = PricePerUserValue - (PricePerUserValue * DiscountValue) / 100;
                setData((prevData) => ({
                    ...prevData,
                    SubscriptionDetails: {
                      ...prevData.SubscriptionDetails,
                      Discount: (PricePerUserValue * DiscountValue) / 100 // Update Discount field only
                    }
                }));
                setShowFixedDiscountValue((PricePerUserValue * DiscountValue) / 100);
                setDefaultFillFinalPrice(_finalPrice);
            }
        }
    }

    const handleChangePricePerUser = (PricePerUserValue) => {
        setPricePerUserValue(PricePerUserValue);
        if(showDiscountInput.TextInput){
            const _finalPrice = PricePerUserValue - DiscountValue;
            setDefaultFillFinalPrice(_finalPrice);
        }else if(showDiscountInput.PercetageInput){
            const _finalPrice = PricePerUserValue - (PricePerUserValue * DiscountValue) / 100
            setDefaultFillFinalPrice(_finalPrice);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data?.SubscriptionDetails?.SubscriptionDetailId || 0) === 0
                        ? "Add Subscription Details"
                        : "Edit Subscription Details"}
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.SubscriptionDetails}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12 pb-1">
                            <FormInputDropdown
                                name="SubType"
                                ddOpt={StandardConst.SubType}
                                label="Sub Type"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12 pb-1">
                            <FormInputDropdown
                                name="Duration"
                                ddOpt={StandardConst.Duration}
                                label="Duration"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-5 d-flex align-items-center" style={{ color: "#5a5a5a" }}>
                                    User Limit
                                    <span className="text-danger">＊</span>
                                </div>
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-6" style={{paddingLeft: "3px"}}>
                                            <FormInputText
                                                name="UserLimitFrom"
                                                type="number"
                                                isRequired="true"
                                            />
                                        </div>
                                        <div className="col-md-6" style={{paddingLeft: "3px"}}>
                                            <FormInputText
                                                name="UserLimitTo"
                                                type="number"
                                                isRequired="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Price Per User"
                                name="PricePerUser"
                                type="currency"
                                isRequired="true"
                                setValue={(v) => handleChangePricePerUser(v)}
                                locale={props.SubscriptionCurrencyData.LocaleCode}
                                currencyCode={props.SubscriptionCurrencyData.CurrencyCode}
                            />
                        </div>
                        <div className="col-md-12 discount-radio-buttons d-inline-block" style={{ color: "#5a5a5a" }}>
                            <div className="row d-flex">
                                <div className="col-md-5 d-flex align-items-center">
                                    Discount Type
                                    <span className="text-danger">＊</span>
                                </div>
                                <div className="col-md-7 p-0">
                                    <label htmlFor={StandardConst.DiscountTypeRadio.Fixed}>
                                        <Radio
                                            checked={selectedDiscountTypeRadio === StandardConst.DiscountTypeRadio.Fixed}
                                            onChange={handleChange}
                                            value={StandardConst.DiscountTypeRadio.Fixed}
                                            name="DiscountType"
                                            inputProps={{ 'aria-label': StandardConst.DiscountTypeRadio.Fixed }}
                                            id={StandardConst.DiscountTypeRadio.Fixed}
                                        />
                                        {StandardConst.DiscountTypeRadio.Fixed}
                                    </label>
                                    <label htmlFor={StandardConst.DiscountTypeRadio.Percentage}>
                                        <Radio
                                            checked={selectedDiscountTypeRadio === StandardConst.DiscountTypeRadio.Percentage}
                                            onChange={handleChange}
                                            value={StandardConst.DiscountTypeRadio.Percentage}
                                            id={StandardConst.DiscountTypeRadio.Percentage}
                                            name="DiscountType"
                                            inputProps={{ 'aria-label': StandardConst.DiscountTypeRadio.Percentage }}
                                        />
                                        {StandardConst.DiscountTypeRadio.Percentage}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {
                                showDiscountInput.PercetageInput && (
                                    <>
                                        <FormInputText
                                            label="Discount In Percentage"
                                            name="DiscountInPercentage"
                                            type="number"
                                            isRequired="true"
                                            setValue={(v) => handleChangeDiscountInputValue(v)}
                                        />
                                    </>
                                )
                            }
                            
                            <FormInputText
                                label="Discount"
                                name="Discount"
                                type="currency"
                                isRequired="true"
                                setValue={(v) => handleChangeDiscountInputValue(v)}
                                locale={props.SubscriptionCurrencyData?.LocaleCode}
                                currencyCode={props.SubscriptionCurrencyData?.CurrencyCode}
                                disabled={showDiscountInput.TextInput ? false : true}
                                value={showFixedDiscountValue}
                                predefaultValue={true}
                            />
                                   
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Final Price"
                                name="FinalPrice"
                                type="currency"
                                value={DefaultFillFinalPrice}
                                isRequired="true"
                                disabled={true}
                                locale={props.SubscriptionCurrencyData?.LocaleCode}
                                currencyCode={props.SubscriptionCurrencyData?.CurrencyCode}
                                predefaultValue={true}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id="btnSubmit"
                        variant="outline-primary"
                        type="submit"
                    >
                        {" "}
                        {(data?.SubscriptionDetails?.SubscriptionDetailId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default forwardRef(AddEditSubscriptionDetail);

/* #region Import */
import React, { memo, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebService } from "../../Services/WebService";
import "./LogIn.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, setAssignRole, setCompanyInfo, setMenu, setPermission } from "../../Services/authSlice";
import FooterComponent from "../Layout/FooterComponent";
import SendMail from "./SendMail";
import * as yup from "yup";
import { StandardConst } from "../../Services/StandardConst";
import { Form, FormInputText } from "../Form";
import Button from "react-bootstrap/esm/Button";
import { PageInfo } from "../PageInfo";
import Cookies from "universal-cookie";
import loginImage from '../../Images/loginImage.png';


/* #endregion */
const NonCompanyUserLogin = () => {
    
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [modalShow, setModalShow] = useState(false);
    PageInfo({ pageTitle: "User Sign In" });
    const navigate = useNavigate();
    const [loginStatus, setLoginStatus] = useState("");
    const [emailError, setEmailError] = useState("");
    const [LoginData, setLoginData] = useState();
    const dispatch = useDispatch();
    const requiredMessage = "This is a required field";

    const schema = yup
        .object()
        .shape({
            Email: yup
                .string()
                .trim()
                .required(requiredMessage)
                .matches(
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    "Please enter a valid email"
                ),
            Password: yup.string().trim().required(requiredMessage),
        })
        .required();

    const SubmitData = async (LoginData) => {
        // LoginData.CompanyId = (CompanyInfo ?? {}).CompanyId;
        WebService({
            endPoint: "LogIn/User/Validate/Unknown",
            method: "POST",
            body: LoginData,
            dispatch,
        }).then((res) => {
            dispatch(login(res));
            navigate("/Dashboard");
        });
    };
    const onRegister = () => {
        navigate(`/JobApplicantForm`);
    };

    const cookies = new Cookies();

    useEffect(() => {
        cookies.set("company", JSON.stringify({}), { path: "/" });
        dispatch(setCompanyInfo({}));
    }, []);


    return (
        <>
            <section
                className="loginMobile_view p-2 p-md-5"
                style={{ backgroundColor: "#eee" }}
            >
                <div className="row d-flex justify-content-center align-items-center h-100 ">
                    <div className="login_card_padding card text-black p-4">
                        <div className="container-fluid px-md-5 px-lg-1 px-xl-5">
                            <div className="border-0  no-gutter">
                                <div className="row">
                                    <div className="col-md-2">
                                            <img
                                                src={`${StandardConst.ProjectImagesInPublicAssets}/WiseOfficeDayLogo.png`}
                                                className="logo img-responsive rounded mx-auto d-block"
                                                alt="logo"
                                            />
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-6 d-none d-lg-block">
                                        <div className="card1">
                                            <div className="row px-3 justify-content-center  mt-5 mb-5 border-line">
                                                <img
                                                    src={loginImage}
                                                    className="image bg-image "
                                                    alt="image "
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 px-1 mt-3">
                                        <div className="">
                                            <div className="row px-6 mb-4">
                                                <h1
                                                    style={{
                                                        fontSize: "15px",
                                                        textAlign: "center",
                                                        marginTop: "20px",
                                                        color: "",
                                                    }}
                                                >
                                                    {loginStatus}
                                                </h1>

                                                <div className="line d-none d-lg-block "></div>
                                                <span className="or text-center d-none d-lg-block">
                                                    Sign in
                                                </span>
                                                <span className="text-center d-none d-sm-block d-lg-none">
                                                    <strong>Sign in</strong>
                                                </span>
                                                <div className="line d-none d-lg-block"></div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <Form
                                                    onSubmit={SubmitData}
                                                    validationSchema={schema}
                                                    defaultValues={LoginData}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <FormInputText
                                                                label="Email"
                                                                name="Email"
                                                                type="text"
                                                                isRequired="true"
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt-2">
                                                            <FormInputText
                                                                label="Password"
                                                                name="Password"
                                                                type="password"
                                                                isRequired="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-md-12 mt-2 d-flex justify-content-end">
                                                            <Button
                                                                type="submit"
                                                                id="btnLogin"
                                                                className="w-60"
                                                                variant="outline-primary"
                                                            >
                                                                Login
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                            {/* <div className="row px-3 px-6 mb-3">
                          <div>
                            <label className="mb-1">
                              <h6 className="mb-0 text-sm">Email Address</h6>
                            </label>
                            <input
                              className="mb-0"
                              type="text"
                              id="btnEmail"
                              name="Email"
                              onChange={(e) => validateEmail(e)}
                              placeholder="Enter a valid email address"
                            />
                          </div>
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {emailError}
                          </span>
                        </div>

                        <div className="row px-3 px-6 ">
                          <div>
                            <label className="mb-1">
                              <h6 className="mb-0 text-sm">Password</h6>
                            </label>
                            <input
                              type="password"
                              id="btnPassword"
                              placeholder="password"
                              name="Password"
                            />
                          </div>
                        </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 px-3">
                                        {/* {((CompanyInfo ?? {}).IsDefault ?? 0) == 1 && (
                      <>
                        <p className="text-muted mb-4 bold text-center">
                          If you are a company or Organization
                          <Link
                            id="btnCompanyRegister"
                            to="/"
                            className="left"
                          >
                            <strong className="text-danger">
                              {" "}
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Click For Company Registration
                            </strong>
                          </Link>
                        </p>
                      </>
                    )} */}
                                    </div>
                                    <div className="col-md-6 px-3">
                                        <div className="col-md-12">
                                            {" "}
                                            <a
                                                href="javascript:;"
                                                className="mb-0 text-sm"
                                                id="btnForgotPassword"
                                                onClick={() => setModalShow(true)}
                                            >
                                                <i className="fa fa-key" aria-hidden="true"></i> Forgot
                                                Password?
                                            </a>
                                        </div>
                                        <SendMail
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                        />
                                        <div className="col-md-12 ">
                                            {" "}
                                            <small className="font-weight-bold">
                                                <i className="fa fa-user-plus" aria-hidden="true"></i>{" "}
                                                Are you a new applicant?{" "}
                                                <a
                                                    className="text-danger"
                                                    id="btnRegister"
                                                    onClick={onRegister}
                                                >
                                                    Register here
                                                </a>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </section>
            <div className="fixed-bottom">
                <div className="bg-blue py-2">
                    <div className="row d-flex justify-content-between px-3">
                        <div className="col-md-5 col-12 social-contact ml-4 ml-sm-auto">
                        <Link
                            className="link-route"
                            to={`/JobApplicantForm`}
                            id="btnFooterRegister"
                        >
                            Register
                        </Link>
                        <span className="vertical-line mx-2"></span>
                        <Link
                            className="link-route"
                            to={`/NonCompanyUserLogin`}
                            id="btnFooterlogin"
                        >
                            Login
                        </Link>
                        </div>
                        <small className="col-md-5 col-12 ml-4 ml-sm-5 mb-2 py-0">
                        Copyright &copy; {new Date().getFullYear()}. All rights reserved.
                        </small>
                        <div className="col-md-2 justify-content-end col-12 social-contact ml-4 ml-sm-auto">
                        <a href={(CompanyInfo ?? {}).facebookUrl}>
                            <span className="fa fa-facebook me-4 text-sm"></span>
                        </a>
                        <a href={(CompanyInfo ?? {}).twitterUrl}>
                            <span className="fa fa-twitter me-4 mr-sm-5 text-sm"></span>
                        </a>
                        <a href={(CompanyInfo ?? {}).linkedInUrl}>
                            <span className="fa fa-linkedin me-4 text-sm"></span>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(NonCompanyUserLogin);

import React, { memo, useEffect, useRef, useState } from "react";
import "./taxInvoiceStyles.css";
import { WebService } from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../../Services/StandardConst";
import {
  Form,
  FormAutoCompleteDropdown,
  FormInputDropdown,
  FormInputText,
} from "../../Form";
import Button from "react-bootstrap/esm/Button";
import { Box, Container, Tooltip, styled } from "@mui/material";
import * as yup from "yup";
import { filter, map, omit } from "underscore";

import "react-datepicker/dist/react-datepicker.css"; // Import the date picker CSS
import { DateTime } from "luxon";
import { DynamicInvoiceDetailSection, DynamicCompanySection, DynamicCustomerSection } from "./InvoiceSection";
import { PageInfo } from "../../PageInfo";

const InvoiceAddComponent = () => {
  const [invoiceDetailsState, setInvoiceDetailsState] = useState({});

  const { state } = useLocation();
  const [template, setTemplate] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [currencyDropdown, setCurrencyDropdown] = useState([]);
  const [allTax, setAllTax] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addEditModalRef = useRef();
  const [invoiceSettings, setinvoiceSettings] = useState([]);
  const [cutomerList, setCutomerList] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [customerId, setCustomerId] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [billingStartDateState, setBillingStartDateState] = useState(
    DateTime.now().toJSDate()
  );
  const [projectId, setProjectId] = useState();
  const [billingEndDateState, setBillingEndDateState] = useState();
  PageInfo({ pageTitle: "Create New Invoice" });
  // this state for show or hide Customer Details section
  const [showCustomer, setShowCustomer] = useState(true);

  const [invoiceTemplateData, setInvoiceTemplateData] = useState([]);

  const [invoiceDate, setInvoiceDate] = useState('');
  const [dueDays, setDueDays] = useState('');
  const [invoiceDueDate, setInvoiceDueDate] = useState('');
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});

  const fetchInvoiceHtmlFile = async (invoiceSettingId) => {
    if (invoiceSettingId > 0) {
      await WebService({ 
        dispatch, 
        endPoint: `CommonUtility/Edit/invoice_template?InvoiceSettingId=${invoiceSettingId} OR IsReserved=1`
      })
      .then(
        (r) => {
          setInvoiceTemplateData(
            map(r, (v) => ({
              value: v.TemplateId || "" || null || undefined,
              text: v.TemplateName,
            }))
          );
        }
      );
    }
  };

  const fetchAllInvoiceSettings = async (settingsId, StartDate, EndDate, CustomerId, ProjectId, onSubmitInvoiceDataObj=null) => {
    await WebService({
      dispatch,
      endPoint: `Invoice/Setting/Header/FetchById/${settingsId}/${StartDate}/${EndDate}/${CustomerId}/${ProjectId}`,
    }).then((r) => {
      // set default data
      r.BillingStartDate = DateTime.now().toJSDate();
      r.InvoiceDate = DateTime.now().toJSDate();
      r.InvoiceDueDate = calculateInvoiceDueDate(DateTime.now().toJSDate(), r.DueDays);
      if(r.InvoiceNumberFormat === "Manual" && r.InvoiceNo === null) {
        r.InvoiceNo = r.InvoicePrefix;
      }
      // onSubmitInvoiceDataObj field hum fech detail button me jo function call hai baha se set kr rhe hai quki jo form me humne data bhara hai bo hi humari state me set ho jaye or baki jagah se ye call na ho. ye bala data humko kebal fetch detail button ke click me hi milega
      if(onSubmitInvoiceDataObj !== null) {
        r.DiscountType = onSubmitInvoiceDataObj.DiscountType;
        r.TemplateId = onSubmitInvoiceDataObj.TemplateId;
        r.CurrencyId = onSubmitInvoiceDataObj.CurrencyId;
        r.BillingStartDate = onSubmitInvoiceDataObj.BillingStartDate;
        r.BillingEndDate = onSubmitInvoiceDataObj.BillingEndDate;
        r.DueDays = onSubmitInvoiceDataObj.DueDays;
        r.InvoiceDate = onSubmitInvoiceDataObj.InvoiceDate;
        r.InvoiceDueDate = onSubmitInvoiceDataObj.InvoiceDueDate;
      }else{
        // this state set here for reset of formula data
        setFormulaDataState([]);
      }
      setInvoiceDetailsState(r);
      fetchInvoiceHtmlFile(settingsId);
    });
  };

  const fetchInvoice = async () => {
    setInvoiceDetails();
    await WebService({
      dispatch,
      endPoint: `Invoice/Fetch/${state?.InvoiceHeaderId ?? 0}`,
    }).then((r) => {
      setInvoiceDetails(r);
    });
  };
  const fetchCustomer = async () => {
    await WebService({
      endPoint: `Customer/Fetch`,
      dispatch,
    }).then((r) => {
      setCutomerList(
        map(r, (v) => ({
          CustomerId: v.CustomerId || "" || null || undefined,
          Name: v.PersonName,
        }))
      );
    });
  };

  const fetchAllTemplate = async () => {
    const endPoint = (Roles[0] === StandardConst.SystemRole.SuperAdmin) ? `CommonUtility/fetchAll/invoice_settings` : `CommonUtility/fetchRecordsByStatus/invoice_settings/'${StandardConst.Status.Active}'?IsReserved=${StandardConst.YesNo.Yes}&CompanyId=${StandardConst.YesNo.Yes}`;

    await WebService({
      dispatch,
      endPoint: endPoint
    }).then((r) => {
      fetchAllInvoiceSettings(r[0].InvoiceSettingId, null, null);
      setTemplate(
        map(r, (v) => ({
          value: v.InvoiceSettingId || "" || null || undefined,
          text: v.SettingName,
        }))
      );
    });
  };

  const fetchProjectCustomerWise = async (CustomerId) => {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/projectmaster?where=CustomerId eq ${
        CustomerId ?? null
      }`,
    }).then((r) => {
      setProjectData(
        map(r, (v) => ({
          value: v.ProjectId || "" || null || undefined,
          text: v.ProjectName,
        }))
      );
    });
  };
  const fetchAllCurrency = async () => {
    await WebService({
      endPoint: `Currency/FetchCurrency`,
      dispatch,
    }).then((currencyList) =>  {
      setCurrencyData(currencyList);
      setCurrencyDropdown(map(currencyList, (v) => ({
        value: v.CurrencyId || "" || null || undefined,
        text: `${v.Symbol} - ${v.Description}`,
        description: `${v.Description}`,
        symbol: `${v.Symbol} `,
      })))
    });
  };

  const fetchAllTax = async () => {
    await WebService({
      endPoint: `Invoice/TaxMaster/FetchAll`,
      dispatch,
    }).then((r) => {
      setAllTax(
        map(r, (v) => ({
          value: v.TaxId || "" || null || undefined,
          text: `${v.TaxName} - ${v.TaxPercentage}`,
          taxName: `${v.TaxName}`,
          taxPercentage: v.TaxPercentage,
        }))
      );
    });
  };
  const onSubmit = async (data) => {
        await WebService({
      dispatch,
      endPoint: "#",
      body: {},
    });
  };
  const requiredMessage = "This is a required field";
  const schema = yup.object().shape({
    InvoiceSettingId: yup.string().trim().required(requiredMessage),
    InvoiceDate: yup
      .date()
      .typeError(StandardConst.requiredMessage)
      .required(StandardConst.requiredMessage),
    DueDays: invoiceDetailsState.DueDays ? null : yup.number().required(requiredMessage),
    InvoiceDueDate: invoiceDetailsState.InvoiceDueDate ? null : yup
      .date()
      .typeError(StandardConst.requiredMessage)
      .required(StandardConst.requiredMessage),  
    BillingStartDate: yup
      .date()
      .typeError(StandardConst.requiredMessage)
      .required(StandardConst.requiredMessage),
    BillingEndDate: yup
      .date()
      .typeError(StandardConst.requiredMessage)
      .required(StandardConst.requiredMessage),
    CurrencyId: yup.string().trim().required(requiredMessage),
    // DiscountType: yup.string().trim().required(requiredMessage),
    // TaxId: yup.string().trim().required(requiredMessage),
    TemplateId: yup.string().trim().required(StandardConst.requiredMessage),
  });

  useEffect(() => {
    Promise.all([
      // fetchAllInvoiceSettings(1, null, null),
      fetchCustomer(),
      fetchAllCurrency(),
      fetchAllTax(),
    ]).then(() => fetchInvoice());
  }, []);

  useEffect(() => {
    if (Roles.length > 0) {
      fetchAllTemplate();
    }
  }, [Roles]);

  const MasterPageName = "Create New Invoice";
  const [bData, setBData] = React.useState([
    {
      title: "Invoice Management",
      hrefLink: "#",
    },
    {
      title: "Customer Invoice",
      hrefLink: "#",
    },
  ]);

  //Creating dynamic inputs
  const [inputFields, setInputFields] = useState([{ value: "" }]);

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const formatDateString = (inputDateString) => {
    const [day, month, year] = inputDateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  const [detailsData, setDetailsData] = useState({ Details: [] });
  const [bottomData, setBottomData] = useState({});

  const [emptyFields, setEmptyFields] = useState([]);

  const saveInvoiceSection = async () => {
    var FormSubmitOrNot = true;
    var EmptyFieldsArr = [{DetailSectonValidation : []}];

    companyData?.map((item) => {
      if(item.Value === "" && item.FieldIsRequired === 1){
        EmptyFieldsArr.push(item.FieldName);
        FormSubmitOrNot = false;
      }
    });

    customerData?.map((item) => {
      if(item.Value === "" && item.FieldIsRequired === 1){
        EmptyFieldsArr.push(item.FieldName);
        FormSubmitOrNot = false;
      }
    });
    
    bottomData?.map((item) => {
      if(item.Value === "" && item.FieldIsRequired === 1){
        EmptyFieldsArr.push(item.FieldName);
        FormSubmitOrNot = false;
      }
    });

    detailsData?.Details?.map((item, index) => {
      const details = [];
      item.Details?.map((ObjDetails) => {
        if(ObjDetails.Value === "" && ObjDetails.FieldIsRequired === 1){
          details.push(ObjDetails.FieldName);
          FormSubmitOrNot = false;
        }
      });
      EmptyFieldsArr[0].DetailSectonValidation[index] = details;
    });

    setEmptyFields(EmptyFieldsArr);
    
    const jsonData = {
      Details: companyData.concat(customerData).concat(detailsData.Details).concat(bottomData),
    };

    if(FormSubmitOrNot === true && invoiceDetailsState.InvoiceNo !== ""){
      const updatedJsonData = omit(invoiceDetailsState, "Details");
      updatedJsonData.Details = jsonData.Details;
      updatedJsonData.CustomerId = customerId ?? null;
      updatedJsonData.ProjectId = projectId;
      updatedJsonData.TotalAmount = parseInt(bottomData.find(item => item.FieldName === StandardConst.BottomSectionFixedFieldName.TotalAmount)?.Value)?.toFixed(2);
      updatedJsonData.TaxAmount = parseInt(bottomData.find(item => item.FieldName === StandardConst.BottomSectionFixedFieldName.TaxAmount)?.Value)?.toFixed(2);
      updatedJsonData.NetAmount = parseInt(bottomData.find(item => item.FieldName === StandardConst.BottomSectionFixedFieldName.NetAmount)?.Value)?.toFixed(2);
      updatedJsonData.TaxId = document.getElementById("TaxId").value;
      updatedJsonData.DueDay = updatedJsonData.DueDays;
      // if(invoiceDetailsState?.InvoiceNo === null && invoiceDetailsState?.InvoiceNumberFormat === "Manual") {
      //   updatedJsonData.InvoiceNo = document.getElementById("ManualNumberFormat").value;
      // }

      await WebService({
        dispatch,
        method: "POST",
        endPoint: `Invoice/Generate`,
        body: updatedJsonData,
      }).then((InvoiceHeaderId) => {
        navigate(`/GenerateInvoice`, { state : { InvoiceHeaderId } });
      });
    }
  };

  //Function to retrieve invoice based on condition
  const fetchInvoiceDetails = (data) => {
    const onSubmitInvoiceData = {
      DiscountType : invoiceDetailsState.DiscountType,
      TemplateId : Number(data.TemplateId),
      CurrencyId : Number(invoiceDetailsState.CurrencyId),
      BillingStartDate : data.BillingStartDate,
      BillingEndDate : data.BillingEndDate,
      DueDays : Number(invoiceDetailsState.DueDays),
      InvoiceDate : data.InvoiceDate,
      InvoiceDueDate : invoiceDetailsState.InvoiceDueDate
    }

    const StartDate = DateTime.fromJSDate(
      new Date(data.BillingStartDate)
    ).toFormat("yyyy-MM-dd");
    const EndDate = DateTime.fromJSDate(new Date(data.BillingEndDate)).toFormat(
      "yyyy-MM-dd"
    );

    fetchAllInvoiceSettings(invoiceDetailsState.InvoiceSettingId, StartDate, EndDate, customerId, projectId, onSubmitInvoiceData);
    GetAllConditionAndFormulaData(invoiceDetailsState.InvoiceSettingId);
  };

  const [conditionDataState, setConditionDataState] = useState([]);
  const [formulaDataState, setFormulaDataState] = useState([]);

  const GetAllConditionAndFormulaData = async (InvoiceSettingId) => {
    const formulaPreConditionData = await WebService({
            dispatch,
            method: "GET",
            endPoint: `Invoice/GetFormulaPreConditions/${InvoiceSettingId}`,
    });
    setConditionDataState(formulaPreConditionData);

    const formulaData = await WebService({
          dispatch,
          method: "GET",
          endPoint: `Invoice/GetFormulaData/${InvoiceSettingId}`,
    });
    setFormulaDataState(formulaData);
  }

  // Function to update the companyData state in the parent component
  const handleUpdateCompanyData = (updatedData) => {
    setCompanyData(updatedData);
  };

  // Function to update the Customer state in the parent component
  const handleUpdateCustomerData = (updatedData) => {
    setCustomerData(updatedData);
  };

  const updateJsonData = (newJsonData) => {
    setDetailsData(newJsonData);
  };

  const updateBottomSectionData = (newBottomSectionJsonData) => {
    setBottomData(newBottomSectionJsonData);
  };

  const calculateInvoiceDueDate = (invoiceDate, dueDays) => {
    if(invoiceDate){
      const newInvoiceDueDate = new Date(invoiceDate);
        newInvoiceDueDate.setDate(newInvoiceDueDate.getDate() + parseInt((dueDays == 0) ? 0 : dueDays));
      return newInvoiceDueDate.toISOString().split('T')[0];
    }else{
      return '';
    }
  }

  return (
    <Container fluid className="base-container">
      <Box
        sx={{
          width: 1,
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
            <div className="ms-4">
              <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
            </div>
          </div>
        </div>
      </Box>
      <div className="p-3 primary-bg-color"></div>
      {(Object.keys(invoiceDetailsState).length > 0) && (
        <div className="mx-4 mt-2">
          <Form
            defaultValues={invoiceDetailsState}
            onSubmit={fetchInvoiceDetails}
            validationSchema={schema}
          >
            <div className="row">
              <div className="col-4">
                <FormInputDropdown
                  name="InvoiceSettingId"
                  ddOpt={template ?? []}
                  label="Invoice Setting"
                  isRequired="true"
                  setValue={(v) => {
                    fetchAllInvoiceSettings(v);
                  }}
                ></FormInputDropdown>
              </div>
              <div className="col-4">
                <FormAutoCompleteDropdown
                  name="Customer"
                  data={cutomerList}
                  label="Select Customer"
                  optionText="Name"
                  setValue={(v) => {
                    setCustomerId(v[0].CustomerId);
                    fetchProjectCustomerWise(v[0].CustomerId);
                  }}
                />
              </div>
              <div className="col-4">
                <FormInputDropdown
                  name="ProjectId"
                  ddOpt={projectData ?? []}
                  label="Select Project"
                  // isRequired="true"
                  setValue={(v) => {
                    setProjectId(parseInt(v));
                  }}
                ></FormInputDropdown>
              </div>
            </div>
            {/* <div className="row" >
              <div className="col-4">
                <FormInputText
                  name="InvoicePrefix"
                  isRequired="true"
                  label="Invoice Number Prefix"
                  type="text"
                  value={invoiceDetailsState.InvoicePrefix}
                />
              </div>
              <div className="col-4">
                <FormInputDropdown
                  name="InvoiceNumberFormat"
                  ddOpt={StandardConst.InvoiceNumberFormat}
                  label="Invoice number format"
                  isRequired="true"
                  value={invoiceDetailsState.InvoiceNumberFormat}
                ></FormInputDropdown>
              </div>
              <div className="col-4">
                <FormInputDropdown
                  name="IsShowPayment"
                  ddOpt={StandardConst.YesOrNo}
                  label="Payments"
                  isRequired="true"
                  value={invoiceDetailsState.IsShowPayment}
                ></FormInputDropdown>
              </div>
            </div> */}
            <div className="row">
              <div className="col-4">
                <FormInputText
                  label="Invoice Date"
                  id="InvoiceDate"
                  name="InvoiceDate"
                  type="date"
                  min={DateTime.now().toJSDate()}
                  isRequired="true"
                  setValue={(val) => (
                    setInvoiceDetailsState((prevState) => ({
                      ...prevState,
                      InvoiceDate: val,
                      InvoiceDueDate : calculateInvoiceDueDate(val, invoiceDetailsState.DueDays ?? 0)
                    }))
                    // setInvoiceDate(val)
                  )}
                  value={invoiceDetailsState.InvoiceDate}
                />
              </div>
              <div className="col-4">
                <FormInputText
                  name="DueDays"
                  id="DueDays"
                  isRequired="true"
                  label="Invoice due in (days)"
                  type="text"
                  setValue={(val) => (
                    setInvoiceDetailsState((prevState) => ({
                      ...prevState,
                      DueDays: Number(val),
                      InvoiceDueDate : calculateInvoiceDueDate(invoiceDetailsState.InvoiceDate, Number(val))
                    }))
                    // setDueDays(val)
                  )}
                  value={invoiceDetailsState.DueDays}
                  // setValue={(val) => (
                  //   setDueDays(val)
                  // )}
                />
              </div>
              <div className="col-4">
                <FormInputText
                  label="Invoice Due Date"
                  id="InvoiceDueDate"
                  name="InvoiceDueDate"
                  type="date"
                  min={DateTime.now().toJSDate()}
                  isRequired="true"
                  disabled={true}
                  value={invoiceDetailsState.InvoiceDueDate}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-4 d-none">
                <FormInputDropdown
                  name="TaxId"
                  id="TaxId"
                  ddOpt={allTax}
                  label="Tax"
                  isRequired="true"
                  value={invoiceDetailsState.TaxId}
                ></FormInputDropdown>
              </div>
              <div className="col-4">
                <FormInputText
                  label="Billing Start Date"
                  name="BillingStartDate"
                  type="date"
                  // min={DateTime.now().toJSDate()}
                  isRequired="true"
                  setValue={(val)=> {
                    setInvoiceDetailsState((prevState) => ({
                      ...prevState,
                      BillingStartDate: val,
                    }));
                  }}
                  // onChange={(v) =>
                  //   setBillingStartDateState(DateTime.fromJSDate(new Date(v)))
                  // }
                  // value={invoiceDetailsState.BillingStartDate}
                />
              </div>
              <div className="col-4">
                <FormInputText
                  label="Billing End Date"
                  name="BillingEndDate"
                  type="date"
                  min={billingStartDateState}
                  isRequired="true"
                  setValue={(val)=> {
                    setInvoiceDetailsState((prevState) => ({
                      ...prevState,
                      BillingEndDate: val,
                    }));
                  }}
                  // onChange={(v) =>
                  //   setBillingEndDateState(DateTime.fromJSDate(new Date(v)))
                  // }
                  // value={billingEndDateState}
                />
              </div>
              <div className="col-4">
                <FormInputDropdown
                  name="CurrencyId"
                  id="CurrencyId"
                  ddOpt={currencyDropdown ?? []}
                  label="Currency"
                  isRequired="true"
                  value={invoiceDetailsState.CurrencyId}
                  setValue={(val)=> {
                    setInvoiceDetailsState((prevState) => ({
                      ...prevState,
                      CurrencyId: val,
                    }));
                  }}
                ></FormInputDropdown>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <FormInputDropdown
                  name="DiscountType"
                  id="DiscountType"
                  ddOpt={StandardConst.DiscountType}
                  label="Discount Type"
                  // isRequired="true"
                  setValue={(val)=> {
                    setInvoiceDetailsState((prevState) => ({
                      ...prevState,
                      DiscountType: val,
                    }));
                  }}
                  value={invoiceDetailsState.DiscountType}
                ></FormInputDropdown>
              </div>
              <div className="col-4">
                <FormInputDropdown
                  name="TemplateId"
                  ddOpt={invoiceTemplateData ?? []}
                  label="Invoice Template"
                  isRequired="true"
                  setValue={(val)=> {
                    setInvoiceDetailsState((prevState) => ({
                      ...prevState,
                      TemplateId: val,
                    }));
                  }}
                ></FormInputDropdown>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Button
                  variant="outline-primary"
                  className="d-flex float-end"
                  type="submit"
                  id="fetch_invoice_details"
                  // onClick={() => fetchInvoiceDetails()}
                >
                  <i className="fa fa-plus-circle me-2 mt-1"></i> Fetch Detail
                </Button>
              </div>
            </div>
          </Form>

          <div className="tax-invoice">
            <span className="h5 text-primary">
              {/* Invoice# {invoiceDetailsState?.InvoiceNo}  */}
              Invoice# { invoiceDetailsState?.InvoiceNumberFormat === "Manual" ? 
                <Form>
                  <div className="manualInvoiceNoInput position-relative" style={{ width: "360px"}}>
                    <FormInputText
                      id="ManualNumberFormat"
                      name="ManualNumberFormat"
                      label="Manual Invoice No"
                      type="text"
                      isRequired="true"
                      value={invoiceDetailsState?.InvoicePrefix}
                      setValue={(val)=> {
                        setInvoiceDetailsState((prevData)=> ({...prevData, InvoiceNo: val}))
                      }}
                    />

                    {/* Here we show Validation msg which is form of tooltip */}
                    <div className={`position-absolute top-50 end-0 translate-middle ${ (invoiceDetailsState?.InvoiceNo === "") ? "" : "d-none"}`}>
                        <Tooltip
                          arrow
                          disableFocusListener
                          title={StandardConst.ValidationMessages.RequiredFieldMsg}
                        >
                          <i className="fa fa-exclamation-circle me-2" style={{color: "red"}} ></i>
                        </Tooltip>
                    </div>
                  </div>
                </Form>
              // "ankit"
              : invoiceDetailsState?.InvoiceNo}
            </span>
            <div className="row mt-4">
              <div className="col-6">
                <span className="h5 mb-4"> Company Details</span>
                <div className="mt-4">
                  <DynamicCompanySection
                    data={filter(invoiceDetailsState?.Details, {
                      Section: "Company",
                    })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder)}
                    companyDetails={invoiceDetailsState?.RequestedCompanyData}
                    onUpdateData={handleUpdateCompanyData}
                    currencyData={currencyData.find(
                      (item) => item.CurrencyId === invoiceDetailsState?.CurrencyId
                    )}
                    // setSaveSubmitBtnClicked={setSaveSubmitBtnClicked}
                    emptyFields={emptyFields}
                  />
                </div>
              </div>
              <div className="col-6">
                <span className="h5"> Customer Details</span>
                <div className="mt-4">
                  {showCustomer && (
                    <DynamicCustomerSection
                      data={filter(invoiceDetailsState?.Details, {
                        Section: "Customer",
                      })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder)}
                      customerDetails={invoiceDetailsState?.RequestedCustomerData}
                      onUpdateData={handleUpdateCustomerData}
                      currencyData={currencyData.find(
                        (item) => item.CurrencyId === invoiceDetailsState?.CurrencyId
                      )}
                      // setSaveSubmitBtnClicked={setSaveSubmitBtnClicked}
                      emptyFields={emptyFields}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              {billingEndDateState != null ? (
                <>
                  <DynamicInvoiceDetailSection
                    data={filter(invoiceDetailsState?.Details, {
                      Section: StandardConst.InvoiceSectionNames.Details,
                    })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder)}
                    initialJsonData={invoiceDetailsState?.RequestedDetailsData}
                    updateJsonData={updateJsonData}
                    currencyData={currencyData.find(
                      (item) => item.CurrencyId === Number(invoiceDetailsState?.CurrencyId)
                    )}
                    conditionData={conditionDataState}
                    formulaData={formulaDataState}
                    DataBottomSection={filter(invoiceDetailsState?.Details, {
                      Section: StandardConst.InvoiceSectionNames.Bottom,
                    })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder)}
                    updateBottomSectionData={updateBottomSectionData}
                    // setSaveSubmitBtnClicked={setSaveSubmitBtnClicked}
                    emptyFields={emptyFields}
                    invoiceSettingId = {invoiceDetailsState?.InvoiceSettingId}
                  />
                </>
              ) : (
                <>
                  <DynamicInvoiceDetailSection
                    data={filter(invoiceDetailsState?.Details, {
                      Section: StandardConst.InvoiceSectionNames.Details,
                    })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder)}
                    jsonData={detailsData}
                    initialJsonData={invoiceDetailsState?.RequestedDetailsData}
                    updateJsonData={updateJsonData}
                    currencyData={currencyData.find(
                      (item) => item.CurrencyId === Number(invoiceDetailsState?.CurrencyId)
                    )}
                    conditionData={conditionDataState}
                    formulaData={formulaDataState}
                    DataBottomSection={filter(invoiceDetailsState?.Details, {
                      Section: StandardConst.InvoiceSectionNames.Bottom,
                    })?.sort((a, b) => a.SectionWiseDisplayOrder - b.SectionWiseDisplayOrder)}
                    updateBottomSectionData={updateBottomSectionData}
                    // setSaveSubmitBtnClicked={setSaveSubmitBtnClicked}
                    emptyFields={emptyFields}
                    invoiceSettingId = {invoiceDetailsState?.InvoiceSettingId}
                  />
                </>
              )}
            </div>

            <div className="row mt-4">
              <div className="col-10 d-flex float-end"></div>
              <div className="col-2 d-flex float-end">
                <Button
                  variant="outline-primary"
                  type="submit"
                  className="d-flex float-end"
                  onClick={() => saveInvoiceSection()}
                  id="submit-invoice"
                >
                  <i className="fa fa-plus-circle me-2 mt-1"></i> Save Invoice
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default memo(InvoiceAddComponent);

import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import { PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, Box, AppBar, Alert, ToggleButtonGroup, ToggleButton } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { StandardConst } from '../../Services/StandardConst';
import { Form, FormCheckRadioInput, FormInputDropdown, FormInputFile, FormInputText } from '../Form';
import Button from "react-bootstrap/Button";
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from '../../Services/ActionButton';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import DescriptionIcon from '@mui/icons-material/Description';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { chunk, map, range } from "underscore";
import QRCodeGeneratorDownloadComponent from '../../Services/QRCodeGeneratorDownloadComponent';
import { WSErrorAlert, WSSuccessAlert } from '../../Services/WSAlert';
import BootstrapModal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { ModalShowImagesInCarousel } from './ModalShowImagesInCarousel';
import * as yup from "yup";
import { format } from 'date-fns';
import CurrencyInput from 'react-currency-input-field';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';


const VehicleRegistration = () => {
    PageInfo({ pageTitle: "Vehicle Details" });

    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [companyCurrencyData, setCompanyCurrencyData] = useState({});

    const fetchCurrencyData = async () => {
        const companyInformation = await WebService({
            endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${companyInfo.CompanyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        const currencyInformation = await WebService({
            endPoint: `CommonUtility/Edit/staticcurrency?CurrencyId=${companyInformation.CurrencyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        const currencyLocaleCode = await WebService({
            endPoint: `CommonUtility/Edit/countries?CountryId=${currencyInformation.CountryId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        currencyInformation.LocaleCode = currencyLocaleCode.LocaleCode;
        setCompanyCurrencyData(currencyInformation);
    };


    useEffect(() => {
        if(companyInfo?.CurrencyId){
            fetchCurrencyData();
        }
    }, [companyInfo]);

    // Common for all tabs - Ref and State declaration - START
    const ViewImageInCarouselRef = useRef();

    const [value, setValue] = useState(0);
    const [vehicleId, setVehicleId] = useState(state?.VehicleId || null);
    // Common for all tabs - Ref and State declaration - END

    // Registration Tab -- State declaration --- START
    const [vehicleRegistrationData, setVehicleRegistrationData] = useState({});
    const [vehicleConditionDropdown, setVehicleConditionDropdown] = useState([]);
    const [vehicleCustomFieldData, setVehicleCustomFieldData] = useState([]);
    const [officeLocationDropdown, setOfficeLocationDropdown] = useState([]);
    const [fuelTypesDropdown, setFuelTypesDropdown] = useState([]);
    const [acquisitionSourceDropdown, setAcquisitionSourceDropdown] = useState([]);
    
    // Registration Tab -- State declaration --- END

    // Insurance Tab -- Ref and State declaration --- START
    const InsuranceDetailFormRef = useRef();
    const InsuranceDetailDeleteRef = useRef();

    const [vehicleInsuranceDetailsData, setVehicleInsuranceDetailsData] = useState([]);
    const [vehicleCoverageTypeData, setVehicleCoverageTypeData] = useState([]);
    // Insurance Tab -- Reft and State declaration --- END

    // Document Tab -- Ref and State declaration --- START
    const DocumentDetailDeleteRef = useRef();
    const DocumentDetailFormRef = useRef();

    const [vehicleDocumentDetailsData, setVehicleDocumentDetailsData] = useState([]);
    // Document Tab -- Ref and State declaration --- END
    
    // Financial Tab -- State declaration --- START
     const [vehicleFinancialDetailData, setVehicleFinancialDetailData] = useState([]);
    // Financial Tab -- State declaration --- END

    // Fuel Tab -- Ref and State declaration --- START
    const FuelDetailDeleteRef = useRef();
    const FuelDetailFormRef = useRef();

    const [vehicleFuelDetailsData, setVehicleFuelDetailsData] = useState([]);
    // Fuel Tab -- Ref and State declaration --- END

    // Driver Tab -- Ref and State declaration --- START
    const DriverDeleteRef = useRef();
    const DriverFormRef = useRef();

    const [vehicleDriverData, setVehicleDriverData] = useState([]);
    const [companyDriverData, setCompanyDriverData] = useState([]);
    
    // Driver Tab -- Ref and State declaration --- END

    // Vehicle Certification Tab -- Ref and State declaration --- START
    const VehicleCertificationDeleteRef = useRef();
    const VehicleCertificationFormRef = useRef();

    const [vehicleCertificationData, setVehicleCertificationData] = useState([]);
    const [staticVehicleCertificationTypeData, setStaticVehicleCertificationTypeData] = useState([]);
    // Vehicle Certification Tab -- Ref and State declaration --- END

    const [bData] = useState([
        {
            title: "Vehicle Management",
            hrefLink: "#",
        },
        {
            title: "Vehicle Details",
            hrefLink: "#",
        },
    ]);

    const handleChange = (event, newValue) => {
        if(vehicleId !== null){
            setValue(newValue);
        }else{
            WSErrorAlert("Error", "Please Fill vehicle registration page first.");
            setValue(0);
        }
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0, mt: 0 }}>
                        <span>{children}</span>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const viewImagesInCarousel = async (dbInfoObj, VehicleInsuranceDetailId) => await ViewImageInCarouselRef.current.openModal(dbInfoObj, VehicleInsuranceDetailId || 0);

    // Vehicle Registration Tab - Functions -- START
    const staticRegistrationSchema = {
        Make: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        Model: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        Year: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
        RegistrationNumber: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    }
    const [registrationSchemaState, setRegistrationSchemaState] = useState({});
    const registrationSchema = yup.object().shape(registrationSchemaState);

    const FetchVehicleData = async (VehicleId) => {
        const getCustomFieldValues = await WebService({
            endPoint: `Vehicle/getCustomFieldValues/?VehicleId=${VehicleId}`,
            // dispatch,
        });
        // This is for Select current Office Location for this vehicle
        const selectedOfficeLocationId = await WebService({
            endPoint: `CommonUtility/Edit/vehicleofficelocation?VehicleId=${VehicleId}`,
            // dispatch,
        }).then((res) => {
            if(res.length > 0){
                return res.find(item => item.ToDate === null)?.OfficeLocationId;
            }else{
                return null;
            }
        });

        // this for set vehicle data in registration form tab
        await WebService({
            endPoint: `CommonUtility/Edit/vehicleregistration?VehicleId=${VehicleId}`,
            dispatch,
        }).then((res) => {
            if(selectedOfficeLocationId !== null){
                res[0].OfficeLocationId = selectedOfficeLocationId;
            }

            if(getCustomFieldValues.length > 0){
                getCustomFieldValues.forEach(value => {
                    res[0][value.FieldName] = value.FieldValue;
                });
            }
            setVehicleRegistrationData(res[0]);
        });
    }

    const fetchVehicleConditionList = async () => {
        await WebService({
            endPoint: `CommonUtility/staticvehiclecondition`,
            // dispatch,
        }).then((result) => {
            setVehicleConditionDropdown(result.map(item => ({value : item.VehicleConditionId, text: item.VehicleCondition})))
        })
    };

    const saveCustomFieldData = async (vehicleId) => {
        if((vehicleCustomFieldData.length > 0) && (vehicleId !== null)){
            const AllVehicleCustomFieldsValueDelete = await WebService({
                endPoint: `CommonUtility/vehiclecustomfieldvalues?vehicleId=${vehicleId}`,
                method: "DELETE",
                dispatch,
            }).then((res) => true);
            
            if(AllVehicleCustomFieldsValueDelete){
                vehicleCustomFieldData.map(async (item) => {
                    item['VehicleId'] = vehicleId;
                    await WebService({
                        endPoint: `CommonUtility/vehiclecustomfieldvalues`,
                        body: item,
                        method: 'POST',
                        dispatch,
                    })
                })
            }
        }
    };

    const onSubmitVehicleRegistrationTab = async (data) => {
        data.Year = format(new Date(data.Year), "yyyy-MM-dd");
        data.CompanyId = CompanyInfo.CompanyId;
        let onSubmitVehicleId = vehicleId;
        if (vehicleId !== null){
            await WebService({
                endPoint: `CommonUtility/vehicleregistration?VehicleId=${vehicleId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Vehicle details successfully updated.");
            })
        }else{
            onSubmitVehicleId = await WebService({
                endPoint: `CommonUtility/vehicleregistration`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    WSSuccessAlert("Success", "Vehicle successfully registered.");
                    setVehicleId(result);
                    return result;
                }
            })
        }

        if(onSubmitVehicleId){
            await saveCustomFieldData(onSubmitVehicleId);
            if(officeLocationDropdown.length > 0){
                await WebService({
                    endPoint: `Vehicle/assignVehicleOfficeLocation`,
                    body: {VehicleId: onSubmitVehicleId, OfficeLocationId: data.OfficeLocationId},
                    method: 'POST',
                    dispatch,
                })
            }
        }
    };

    const fetchVehicleCustomFieldsData = async () => {
        return await WebService({
            endPoint: `CommonUtility/vehiclecustomfields`,
            dispatch,
        }).then((res) => {
            res.map(item => {
                if(item.FieldIsRequired === StandardConst.YesNo.Yes) {
                    if(item.FieldType === StandardConst.dataTypes.String){
                        staticRegistrationSchema[item.FieldName] = yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg);
                    }else if(item.FieldType === StandardConst.dataTypes.Date){
                        staticRegistrationSchema[item.FieldName] = yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg);
                    }else if(item.FieldType === StandardConst.dataTypes.Number || item.FieldType === StandardConst.dataTypes.Number){
                        staticRegistrationSchema[item.FieldName] = yup.number().typeError(StandardConst.ValidationMessages.InvalidCharMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg);
                    }
                }
            })
            setRegistrationSchemaState(staticRegistrationSchema);
            const sortedDataToAscendingOrder = res.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            setVehicleCustomFieldData(sortedDataToAscendingOrder);
            return res;
        });
    }

    const fetchCompanyOfficesList = async () => {
        await WebService({
            endPoint: `CommonUtility/officelocation`,
            // dispatch,
        }).then((res) => {
            if(res.length > 0) {
                const companyOffices = res.map(item => ({text: item.Address, value: item.OfficeLocationId}));
                setOfficeLocationDropdown(companyOffices);
            }
        })
    }

    const fetchFuelTypes = async () => {
        await WebService({
            endPoint: `CommonUtility/staticvehiclefueltypes`,
            // dispatch,
        }).then((res) => {
            if(res.length > 0) {
                const staticFuelTypes = res.map(item => ({text: item.FuelType, value: item.VehicleFuelTypeId}));
                setFuelTypesDropdown(staticFuelTypes);
            }
        })
    };

    const fetchAcquisitionSource = async () => {
        await WebService({
            endPoint: `CommonUtility/staticvehicleacquisitionsource`,
            // dispatch,
        }).then((res) => {
            if(res.length > 0) {
                const staticAcquisitionSource = res.map(item => ({text: item.AcquisitionSource, value: item.VehicleAcquisitionSourceId}));
                setAcquisitionSourceDropdown(staticAcquisitionSource);
            }
        })
    };
    // Vehicle Registration Tab - Functions -- END

    // Insurance Tab - Functions -- START
    const openInsuranceDetailsModal = async (VehicleInsuranceDetailId) => await InsuranceDetailFormRef.current.openModal(VehicleInsuranceDetailId || 0);
    
    const FetchVehicleInsuranceDetailData = async (vehicleId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/vehicleinsurancedetails?VehicleId=${vehicleId}`,
            dispatch,
        }).then((res) => {
            setVehicleInsuranceDetailsData(res);
        });
    }

    const onInsuranceDetailDelete = async (VehicleInsuranceDetailId) => {
        await WebService({
            endPoint: `CommonUtility/vehicleinsurancedetails?VehicleInsuranceDetailId=${VehicleInsuranceDetailId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => {
            WSSuccessAlert('Success', 'Successfully deleted.');
            FetchVehicleInsuranceDetailData(vehicleId);
        })
    };

    const fetchStaticVehicleCoverageType = async () => {
        await WebService({
            endPoint: `CommonUtility/staticvehiclecoveragetypes`,
            dispatch,
        }).then((res) => {
            if(res.length > 0){
                setVehicleCoverageTypeData(res);
            }
        })
    }

    const vehicleInsuranceColumns = [
        {
            Text: "Insurance Company",
            Value: "InsuranceCompany"
        },
        {
            Text: "Policy Number",
            Value: "PolicyNumber"
        },
        {
            Text: "Coverage Type",
            render: (dr) => {
                if(dr.VehicleCoverageTypeId){
                    return vehicleCoverageTypeData.find(item => item.VehicleCoverageTypeId === dr.VehicleCoverageTypeId).CoverageType
                }else{
                    return ""
                }
            }
        },
        {
            Text: "Insurance Expiry Date",
            DateFormat: "dd-MMM-yyyy",
            Value: "InsuranceExpiryDate"
        },
        {
            Text: "Action",
            key: "VehicleInsuranceDetailId ",
            cssClass: "text-end",
            // isVisiable:
            // permission.ManageReject ||
            // permission.ManageApprove ||
            // permission.ManageView,
            render: (dr) => (
            <>
                <ActionButton
                    onClick={() => viewImagesInCarousel({tableName: 'vehicleinsurancedocuments', fileFildName: 'DocumentPath', compareFildName: 'VehicleInsuranceDetailId', folderPath: StandardConst.vehicleInsuranceDocument }, dr.VehicleInsuranceDetailId) }
                    IconName="View"
                    id="btnView"
                />
                <ActionButton
                    onClick={() => openInsuranceDetailsModal(dr.VehicleInsuranceDetailId)}
                    IconName="Edit"
                    id="btnEdit"
                />
                <ActionButton
                    onClick={(e) =>
                        InsuranceDetailDeleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        `Do you want to delete ${dr.InsuranceCompany} detail`, // Message of Alert
                        dr.VehicleInsuranceDetailId // Endpoint to hit for delete
                        )}
                    IconName="Delete"
                    id="btnDelete"
                />
            </>
            ),
        },
    ];
    // Insurance Tab - Functions -- END

    // Document Tab - Functions -- START
    const openDocumentDetailsModal = async (VehicleDocumentDetailId) => await DocumentDetailFormRef.current.openModal(VehicleDocumentDetailId || 0);
    
    const FetchVehicleDocumentDetailData = async (vehicleId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/vehicledocumentdetails?VehicleId=${vehicleId}`,
            // dispatch,
        }).then((res) => {
            setVehicleDocumentDetailsData(res);
        });
    }

    const onDocumentDetailDelete = async (VehicleDocumentDetailId) => {
        await WebService({
            endPoint: `CommonUtility/vehicledocumentdetails?VehicleDocumentDetailId=${VehicleDocumentDetailId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => {
            WSSuccessAlert('Success', 'Successfully deleted.');
            FetchVehicleDocumentDetailData(vehicleId);
        })
    };

    const vehicleDocumentColumns = [
        {
            Text: "Document Name",
            Value: "DocumentName"
        },
        {
            Text: "Document Number",
            Value: "DocumentNumber"
        },
        {
            Text: "Document Description",
            Value: "DocumentDescription"
        },
        {
            Text: "Action",
            key: "VehicleInsuranceDetailId ",
            cssClass: "text-end",
            // isVisiable:
            // permission.ManageReject ||
            // permission.ManageApprove ||
            // permission.ManageView,
            render: (dr) => (
            <>
                <ActionButton
                    onClick={() => viewImagesInCarousel({tableName: 'vehicledocuments', fileFildName: 'VehicleDocumentPath', compareFildName: 'VehicleDocumentDetailId', folderPath: StandardConst.vehicleDocuments }, dr.VehicleDocumentDetailId) }
                    IconName="View"
                    id="btnViewDocument"
                />
                <ActionButton
                    onClick={() => openDocumentDetailsModal(dr.VehicleDocumentDetailId)}
                    IconName="Edit"
                    id="btnEditDocument"
                />
                <ActionButton
                    onClick={(e) =>
                        DocumentDetailDeleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        `Do you want to delete ${dr.DocumentName} detail`, // Message of Alert
                        dr.VehicleDocumentDetailId // Endpoint to hit for delete
                        )}
                    IconName="Delete"
                    id="btnDeleteDocument"
                />
            </>
            ),
        },
    ];
    // Document Tab - Functions -- END

    // Financial Tab - Functions -- START
    const FetchVehicleFinancialDetailData = async (vehicleId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/vehiclefinancialdetails?VehicleId=${vehicleId}`,
            dispatch,
        }).then((res) => {
            if(res.length > 0) {
                setVehicleFinancialDetailData(res[0]);
            }
        });
    }
    
    const onSubmitVehicleFinancialDetailTab = async (data) => {
        const checkRecordExistOrNot = await WebService({
            endPoint: `CommonUtility/Edit/vehiclefinancialdetails?VehicleId=${vehicleId}`,
            // dispatch,
        })

        if(checkRecordExistOrNot.length > 0) {
            await WebService({
                endPoint: `CommonUtility/vehiclefinancialdetails?VehicleId=${vehicleId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Vehicle financial detail successfully updated.");
            })
        }else{
            data.VehicleId = vehicleId;
            await WebService({
                endPoint: `CommonUtility/vehiclefinancialdetails`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    WSSuccessAlert("Success", "Vehicle financial detail successfully registered.");
                    FetchVehicleFinancialDetailData(vehicleId);
                }
            })
        }
    };

    const financialSchema = yup.object().shape({
        InitialCost: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        Depreciation: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        BookValue: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        ResaleValue: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });
    // Financial Tab - Functions -- END

    // Fuel Tab - Functions -- START
    const openFuelDetailsModal = async (VehicleFuelHistoryId) => await FuelDetailFormRef.current.openModal(VehicleFuelHistoryId || 0);

    const FetchVehicleFuelDetailData = async (vehicleId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/vehiclefuelhistory?VehicleId=${vehicleId}`,
            dispatch,
        }).then((res) => {
            const SortedResult = res.sort((a, b) => {
                const dateComparison = new Date(a.RefuelingDate) - new Date(b.RefuelingDate);
                if (dateComparison !== StandardConst.YesNo.No) {
                    return dateComparison;
                }
                return b.OdometerReading - a.OdometerReading;
            });
            setVehicleFuelDetailsData(SortedResult);
        });
    }

    const onFuelDetailDelete = async (VehicleFuelHistoryId) => {
        await WebService({
            endPoint: `CommonUtility/vehiclefuelhistory?VehicleFuelHistoryId=${VehicleFuelHistoryId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => {
            WSSuccessAlert('Success', 'Successfully deleted.');
            FetchVehicleFuelDetailData(vehicleId);
        })
    };

    const vehicleFuelColumns = [
        {
            Text: "Refueling Date",
            DateFormat: "dd-MMM-yyyy",
            Value: "RefuelingDate"
        },
        {
            Text: "Odometer Reading",
            Value: "OdometerReading"
        },
        {
            Text: "Fuel In Litre",
            Value: "FuelInlitre"
        },
        {
            Text: "Mileage",
            Value: "Mileage"
        },
        {
            Text: "Action",
            key: "VehicleInsuranceDetailId ",
            cssClass: "text-end",
            // isVisiable:
            // permission.ManageReject ||
            // permission.ManageApprove ||
            // permission.ManageView,
            render: (dr) => (
            <>
                <ActionButton
                    onClick={() => openFuelDetailsModal(dr.VehicleFuelHistoryId)}
                    IconName="Edit"
                    id="btnEditFuel"
                />
                <ActionButton
                    onClick={(e) =>
                        FuelDetailDeleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        "Do you want to delete this fuel detail", // Message of Alert
                        dr.VehicleFuelHistoryId // Endpoint to hit for delete
                        )}
                    IconName="Delete"
                    id="btnDeleteFuel"
                />
            </>
            ),
        },
    ];
    // Fuel Tab - Functions -- END

    // Driver Tab - Functions -- START
     const openDriverModal = async (VehicleDriverAssignmentId) => await DriverFormRef.current.openModal(VehicleDriverAssignmentId || 0, vehicleRegistrationData?.OfficeLocationId || null);

     const FetchVehicleDriverData = async (VehicleId) => {
         await WebService({
             endPoint: `CommonUtility/Edit/vehicledriverassignment?VehicleId=${VehicleId}`,
             dispatch,
         }).then((res) => {
             setVehicleDriverData(res);
         });
     }
 
     const onDriverDelete = async (VehicleDriverAssignmentId) => {
         await WebService({
             endPoint: `CommonUtility/vehicledriverassignment?VehicleDriverAssignmentId=${VehicleDriverAssignmentId}`,
             method: "DELETE",
             dispatch,
         }).then((res) => {
             WSSuccessAlert('Success', 'Successfully deleted.');
             FetchVehicleDriverData(vehicleId);
         })
     };


     const FetchCompanyDriverData = async () => {
        await WebService({
            endPoint: `CommonUtility/companydriversinformation`,
            dispatch,
        }).then((res) => {
            setCompanyDriverData(res);
        });
     }
     
     const vehicleDriverColumns = [
        {
            Text: "Driver",
            render: (dr) => companyDriverData?.find(item => item.DriverId === dr.DriverId).DriverName
        },
        {
            Text: "Full Time",
            render: (dr) => StandardConst.YesOrNo.find(item => item.value === dr.FullTime).text
        },
        {
            Text: "Assigned From Date",
            render: (dr) => {
                const AssignedDate = dr.AssignedFromDate.split("T")
                const dateString = `${AssignedDate[0]}${dr.ShiftFromTime ? `T${dr.ShiftFromTime}` : ``}`;
                const dateTime = new Date(dateString);
                const formattedDate = format(dateTime, 'dd-MMM-yyyy');
                let formattedTime = "";
                if(dr.ShiftFromTime){
                    formattedTime = " " + format(dateTime, 'hh:mm a');
                }

                return formattedDate + " " + formattedTime
            }
        },

        {
            Text: "Assigned To Date",
            render: (dr) => {
                const AssignedDate = dr.AssignedToDate.split("T")
                const dateString = `${AssignedDate[0]}${dr.ShiftToTime ? `T${dr.ShiftToTime}` : ``}`;
                const dateTime = new Date(dateString);
                const formattedDate = format(dateTime, 'dd-MMM-yyyy');
                let formattedTime = "";
                if(dr.ShiftToTime){
                    formattedTime = " " + format(dateTime, 'hh:mm a');
                }

                return formattedDate + " " + formattedTime
            }
        },
        {
            Text: "Action",
            key: "VehicleDriverAssignmentId",
            cssClass: "text-end",
            // isVisiable:
            // permission.ManageReject ||
            // permission.ManageApprove ||
            // permission.ManageView,
            render: (dr) => (
            <>
                <ActionButton
                    onClick={() => openDriverModal(dr.VehicleDriverAssignmentId)}
                    IconName="Edit"
                    id="btnEditFuel"
                />
                <ActionButton
                    onClick={(e) =>
                        DriverDeleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        `Do you want to remove ${companyDriverData?.find(item => item.DriverId === dr.DriverId).DriverName} from the vehicle ${vehicleRegistrationData?.RegistrationNumber}`, // Message of Alert
                        dr.VehicleDriverAssignmentId // Endpoint to hit for delete
                        )}
                    IconName="Delete"
                    id="btnDeleteFuel"
                />
            </>
            ),
        },
     ];
     // Driver Tab - Functions -- END

    // Vehicle Certification Tab - Functions -- START
    const openVehicleCertificationModal = async (VehicleComplianceAndCertificationId) => await VehicleCertificationFormRef.current.openModal(VehicleComplianceAndCertificationId || 0);
    
    const FetchVehicleCertificationData = async (vehicleId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/vehiclecomplianceandcertifications?VehicleId=${vehicleId}`,
            dispatch,
        }).then((res) => {
            setVehicleCertificationData(res);
        });
    }

    const onVehicleCertificationDelete = async (VehicleComplianceAndCertificationId) => {
        await WebService({
            endPoint: `CommonUtility/vehiclecomplianceandcertifications?VehicleComplianceAndCertificationId=${VehicleComplianceAndCertificationId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => {
            WSSuccessAlert('Success', 'Successfully deleted.');
            FetchVehicleCertificationData(vehicleId);
        })
    };

    const vehicleCertificationColumns = [
        {
            Text: "Vehicle Certificate Type Id",
            // Value: "VehicleCertificateTypeId"
            render: (dr) =>  {
                return staticVehicleCertificationTypeData.find(item => item.VehicleCertificateTypeId === dr.VehicleCertificateTypeId).VehicleCertificateType;
            }
        },
        {
            Text: "Description",
            Value: "Description"
        },
        {
            Text: "Action",
            key: "VehicleComplianceAndCertificationId ",
            cssClass: "text-end",
            // isVisiable:
            // permission.ManageReject ||
            // permission.ManageApprove ||
            // permission.ManageView,
            render: (dr) => (
            <>
                <ActionButton
                    onClick={() => viewImagesInCarousel({tableName: 'vehiclecertificationfiles', fileFildName: 'FilePath', compareFildName: 'VehicleComplianceAndCertificationId', folderPath: StandardConst.VehicleCertificationDocuments }, dr.VehicleComplianceAndCertificationId) }
                    IconName="View"
                    id="btnViewDocument"
                />
                <ActionButton
                    onClick={() => openVehicleCertificationModal(dr.VehicleComplianceAndCertificationId)}
                    IconName="Edit"
                    id="btnEditDocument"
                />
                <ActionButton
                    onClick={(e) =>
                        VehicleCertificationDeleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        `Do you want to delete ${staticVehicleCertificationTypeData.find(item => item.VehicleCertificateTypeId === dr.VehicleCertificateTypeId).VehicleCertificateType} detail`, // Message of Alert
                        dr.VehicleComplianceAndCertificationId // Endpoint to hit for delete
                        )}
                    IconName="Delete"
                    id="btnDeleteDocument"
                />
            </>
            ),
        },
    ];

    const fetchStaticVehicleCertificationTypeList = async () => {
        await WebService({
            endPoint: `CommonUtility/staticvehiclecertificatetypes`,
            // dispatch,
        }).then((res) => {
            setStaticVehicleCertificationTypeData(res);
        })
    };
    // Vehicle Certification Tab - Functions -- END

    useEffect(() => {
        Promise.all([
            fetchVehicleCustomFieldsData(),
            fetchStaticVehicleCertificationTypeList(),
            fetchVehicleConditionList(),
            fetchCompanyOfficesList(),
            fetchFuelTypes(),
            fetchAcquisitionSource(),
            fetchStaticVehicleCoverageType(),
            FetchCompanyDriverData()
        ])
    }, []);

    useEffect(() => {
        if (vehicleId !== null){
            Promise.all([
                FetchVehicleData(vehicleId),
                FetchVehicleInsuranceDetailData(vehicleId),
                FetchVehicleDocumentDetailData(vehicleId),
                FetchVehicleFinancialDetailData(vehicleId),
                FetchVehicleCertificationData(vehicleId),
                FetchVehicleFuelDetailData(vehicleId),
                FetchVehicleDriverData(vehicleId)
            ])
        }
    }, [vehicleId]);

    return (
        <>
            {/* common carousel image for all tabs */}
            <ModalShowImagesInCarousel ref={ViewImageInCarouselRef} />
            <Container
                fluid
                style={{
                    padding: "0px",
                    borderRadius: "5px ",
                    margin: "10px",
                    backgroundColor: "#fff",
                    width: "90%"
                }}
            >
                <Topbar bData={bData} HeadingText={"Vehicle Details"}></Topbar>
                <Box>
                    <AppBar
                        position="static"
                        color="info"
                        elevation={0}
                        className="Maintab_Custome mb-2"
                        style={{ maxHeight: "40px", height: "40px" }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            style={{
                                maxHeight: "40px",
                                height: "40px",
                            }}
                            id="tabs"
                        >
                            <Tab
                                icon={<LocalShippingIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Vehicle Registration"
                                label={window.innerWidth <= 600 ? '' : "Vehicle Registration"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="General"
                            />
                            <Tab
                                icon={<NoCrashIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Insurance Details"
                                label={window.innerWidth <= 600 ? '' : "Insurance Details"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="PurchaseDetails"
                            />
                            <Tab
                                icon={<DescriptionIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Documents"
                                label={window.innerWidth <= 600 ? '' : "Documents"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="Documents"
                            />
                            <Tab
                                icon={<AssignmentIndIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Financial Details"
                                label={window.innerWidth <= 600 ? '' : "Financial Details"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="FinancialDetails"
                            />
                            <Tab
                                icon={<LocalGasStationIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Fuel Information"
                                label={window.innerWidth <= 600 ? '' : "Fuel Information"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="FuelInformation"
                            />
                            <Tab
                                icon={<SummarizeIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Compliance and Certification"
                                label={window.innerWidth <= 600 ? '' : "Compliance / Certification"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="ComplianceAndCertification"
                            />
                            <Tab
                                icon={<PersonIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Driver"
                                label={window.innerWidth <= 600 ? '' : "Driver"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="Driver"
                            />
                        </Tabs>
                    </AppBar>
                        <SwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Container className='mt-2'>
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Form 
                                                defaultValues={vehicleRegistrationData}
                                                onSubmit={onSubmitVehicleRegistrationTab}
                                                validationSchema={registrationSchema}
                                            >
                                                <div className="card shadow-none footer-widget">
                                                    <Alert severity="info" sx={{ width: "95%" }}>Basic Information</Alert>
                                                    <div className="card-body">
                                                        <div className="row mt-1">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Registration Number"
                                                                        name="RegistrationNumber"
                                                                        type="text"
                                                                        isRequired="true"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Make"
                                                                        name="Make"
                                                                        type="text"
                                                                        isRequired="true"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        name="Model"
                                                                        label="Model"
                                                                        type="text"
                                                                        isRequired="true"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Year"
                                                                        name="Year"
                                                                        type="dateYear"
                                                                        portalId="root"
                                                                        isRequired="true"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="VIN (Vehicle Identification Number)"
                                                                        name="VIN"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="License Plate Number"
                                                                        name="LicensePlateNumber"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Warranty"
                                                                        name="Warranty"
                                                                        type="text"
                                                                    />
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Warranty Expiry Date"
                                                                        name="WarrantyExpiryDate"
                                                                        type="date"
                                                                        // min={DateTime.now().toJSDate()}
                                                                        // isRequired="true"
                                                                        // setValue={(val)=> {
                                                                        //     setInvoiceDetailsState((prevState) => ({
                                                                        //     ...prevState,
                                                                        //     BillingStartDate: val,
                                                                        //     }));
                                                                        // }}
                                                                        portalId="root"
                                                                    />
                                                                </div>

                                                                <div className="col-md-12 pb-2">
                                                                    <FormInputDropdown
                                                                        label="Fuel Type"
                                                                        name="VehicleFuelTypeId"
                                                                        ddOpt={fuelTypesDropdown}
                                                                    />
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Fuel Capacity"
                                                                        name="FuelCapacity"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                {(officeLocationDropdown.length > 0) && (
                                                                    <div className="col-md-12">
                                                                        <FormInputDropdown
                                                                            label="Office Location"
                                                                            name="OfficeLocationId"
                                                                            ddOpt={officeLocationDropdown}
                                                                        />
                                                                    </div>
                                                                )}
                                                                
                                                            </div>
                                                            <div className="mx-n4 my-2">
                                                                <Alert severity="info" sx={{ width: "99%" }}>Ownership and Acquisition</Alert>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Purchase Date"
                                                                        name="PurchaseDate"
                                                                        type="date"
                                                                        // min={DateTime.now().toJSDate()}
                                                                        // isRequired="true"
                                                                        // setValue={(val)=> {
                                                                        //     setInvoiceDetailsState((prevState) => ({
                                                                        //     ...prevState,
                                                                        //     BillingStartDate: val,
                                                                        //     }));
                                                                        // }}
                                                                        portalId="root"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Purchase Price"
                                                                        name="PurchasePrice"
                                                                        type="currency"
                                                                        locale={companyCurrencyData?.LocaleCode}
                                                                        currencyCode={companyCurrencyData?.CurrencyCode}
                                                                        isRequired="true"
                                                                    />
                                                                </div>
                                                                <div className="col-md-12 pb-1">
                                                                    <FormInputDropdown
                                                                        label="Vehicle Condition"
                                                                        name="VehicleConditionId"
                                                                        ddOpt={vehicleConditionDropdown}
                                                                    />
                                                                </div>
                                                                <div className="col-md-12 pb-2">
                                                                    <FormInputDropdown
                                                                        label="Acquisition Source"
                                                                        name="VehicleAcquisitionSourceId"
                                                                        ddOpt={acquisitionSourceDropdown}
                                                                    />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <FormInputText
                                                                        label="Additional Note"
                                                                        name="AdditionalNote"
                                                                        as="textarea"
                                                                        rows="2"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {vehicleCustomFieldData.length > 0 && (
                                                                <>
                                                                    <div className="mx-n4 my-2">
                                                                        <Alert severity="info" sx={{ width: "99%" }}>Other</Alert>
                                                                    </div>
                                                                    <div className="row">
                                                                        {vehicleCustomFieldData.map(vehicleCustomField => {
                                                                            const FieldType = vehicleCustomField.FieldType;
                                                                            // const FieldValue = (vehicleCustomField.FieldValue !== undefined) ? vehicleCustomField.FieldValue : "";
                                                                            if(FieldType !== StandardConst.dataTypes.Date){
                                                                                return <div className="col-md-12">
                                                                                    <FormInputText
                                                                                        label={vehicleCustomField.FieldLabel}
                                                                                        name={vehicleCustomField.FieldName}
                                                                                        type={FieldType}
                                                                                        isRequired={vehicleCustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                                                                                        // value={FieldValue}
                                                                                        setValue={(val) => vehicleCustomField['FieldValue'] = val}
                                                                                    />
                                                                                </div>
                                                                            }else{
                                                                                return <div className="col-md-12">
                                                                                    <FormInputText
                                                                                        label={vehicleCustomField.FieldLabel}
                                                                                        name={vehicleCustomField.FieldName}
                                                                                        type="date"
                                                                                        portalId="root"
                                                                                        isRequired={vehicleCustomField.FieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                                                                                        // value={FieldValue}
                                                                                        setValue={(val) => vehicleCustomField['FieldValue'] = val}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <Button
                                                                variant="outline-primary"
                                                                type="submit"
                                                                id="btnSubmit"
                                                                className="px-4 float-end mt-4 mb-1"
                                                                style={{ marginRight: "27px" }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        {(vehicleId !== null) && (
                                            <div className="col-12 col-md-4">
                                                <div id="Vehicle-QRCode">
                                                    <div className="Heading mb-4">
                                                        <Alert severity="info">Vehicle QR-Code</Alert>
                                                    </div>
                                                    <div className='qr-code'>
                                                        <QRCodeGeneratorDownloadComponent data={`Vehicle,${vehicleRegistrationData.Guid}`} showWodLogo={false} showLogo={false} EventLogo={null} Text="Powered by wiseofficeday.com" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    
                                </Container>
                            </TabPanel>
                            
                            {/* Insurance Details tab */}
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <DeleteConfirmAlert ref={InsuranceDetailDeleteRef} confirmEvent={(v) => onInsuranceDetailDelete(v)} />
                                <ModalAddInsuranceDetails ref={InsuranceDetailFormRef} VehicleId={vehicleId} callBackEvent={async () => await FetchVehicleInsuranceDetailData(vehicleId)}/>
                                <Container className='mt-2'>
                                    <TableComponent
                                        columns={vehicleInsuranceColumns}
                                        data={vehicleInsuranceDetailsData}
                                        noRecordCss="p-0"
                                        noRecordFound={
                                        <NoRecordTemplate
                                            headerValue="No insurance details found."
                                            subHeaderValue="Please fill out the insurance details"
                                            imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                        />
                                        }
                                        onAddEvent={() => openInsuranceDetailsModal(0)}
                                        IsAddButtonVisible={true}
                                        isSearchRequired={true}
                                        // initialSearchContent={searchParams.get("search") ?? ""}
                                        allowSerialNo={true}
                                    />
                                </Container>
                            </TabPanel>

                            {/* Documents tab */}
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                <DeleteConfirmAlert ref={DocumentDetailDeleteRef} confirmEvent={(v) => onDocumentDetailDelete(v)} />
                                <ModalAddDocumentDetails ref={DocumentDetailFormRef} VehicleId={vehicleId} callBackEvent={async () => await FetchVehicleDocumentDetailData(vehicleId)}/>
                                <Container className='mt-2'>
                                    <TableComponent
                                        columns={vehicleDocumentColumns}
                                        data={vehicleDocumentDetailsData}
                                        noRecordCss="p-0"
                                        noRecordFound={
                                        <NoRecordTemplate
                                            headerValue="No Document details found."
                                            subHeaderValue="Please fill out the document details"
                                            imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                        />
                                        }
                                        onAddEvent={() => openDocumentDetailsModal(0)}
                                        IsAddButtonVisible={true}
                                        isSearchRequired={true}
                                        // initialSearchContent={searchParams.get("search") ?? ""}
                                        allowSerialNo={true}
                                    />
                                </Container>
                            </TabPanel>

                             {/* Financial Details tab */}
                            <TabPanel value={value} index={3} dir={theme.direction}>
                                <Container className='mt-2'>
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Form 
                                                defaultValues={vehicleFinancialDetailData}
                                                onSubmit={onSubmitVehicleFinancialDetailTab}
                                                validationSchema={financialSchema}
                                            >
                                                <div className="card shadow-none footer-widget">
                                                    <div className="card-body">
                                                        <div className="row mt-1">
                                                            {/* <div className="col-md-12">
                                                                <div className="row m-0 p-0 mt-2">
                                                                    <div className="col-5 m-0 p-0">
                                                                        <label htmlFor="">Cost</label>
                                                                    </div>
                                                                    <div className="col-7 m-0 p-0">
                                                                        <CurrencyInput
                                                                            className="text-end"
                                                                            intlConfig={{locale: companyCurrencyData?.LocaleCode, currency: companyCurrencyData?.CurrencyCode}}
                                                                            allowNegativeValue={false} // Remove if negative values are allowed
                                                                            decimalScale={2}
                                                                            name='Cost'
                                                                            style={{ height: '34px' }}
                                                                            onValueChange={(value) => setCost(value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-md-12">
                                                                <FormInputText
                                                                    label="Initial Cost"
                                                                    name="InitialCost"
                                                                    type="currency"
                                                                    locale={companyCurrencyData?.LocaleCode}
                                                                    currencyCode={companyCurrencyData?.CurrencyCode}
                                                                    isRequired="true"
                                                                />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <FormInputText
                                                                    name="Depreciation"
                                                                    label="Depreciation"
                                                                    type="currency"
                                                                    locale={companyCurrencyData?.LocaleCode}
                                                                    currencyCode={companyCurrencyData?.CurrencyCode}
                                                                    isRequired="true"
                                                                />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <FormInputText
                                                                    label="Book Value"
                                                                    name="BookValue"
                                                                    type="currency"
                                                                    locale={companyCurrencyData?.LocaleCode}
                                                                    currencyCode={companyCurrencyData?.CurrencyCode}
                                                                    isRequired="true"
                                                                />
                                                            </div>
                                                            <div className="col-md-12">
                                                                <FormInputText
                                                                    label="Resale Value"
                                                                    name="ResaleValue"
                                                                    type="currency"
                                                                    locale={companyCurrencyData?.LocaleCode}
                                                                    currencyCode={companyCurrencyData?.CurrencyCode}
                                                                    isRequired="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Button
                                                                variant="outline-primary"
                                                                type="submit"
                                                                id="btnSubmit"
                                                                className="px-4 float-end mt-4 mb-1"
                                                                style={{ marginRight: "27px" }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    
                                </Container>
                            </TabPanel>

                            {/* Fuel Information */}
                            <TabPanel value={value} index={4} dir={theme.direction}>
                                <DeleteConfirmAlert ref={FuelDetailDeleteRef} confirmEvent={(v) => onFuelDetailDelete(v)} />
                                <ModalAddFuelDetails ref={FuelDetailFormRef} VehicleId={vehicleId} lastRecord={vehicleFuelDetailsData[vehicleFuelDetailsData.length - 1]} callBackEvent={async () => await FetchVehicleFuelDetailData(vehicleId)}/>
                                <Container className='mt-2'>
                                    <TableComponent
                                        columns={vehicleFuelColumns}
                                        data={vehicleFuelDetailsData}
                                        noRecordCss="p-0"
                                        noRecordFound={
                                        <NoRecordTemplate
                                            headerValue="No fuel details found."
                                            subHeaderValue="Please fill out the fuel details"
                                            imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                        />
                                        }
                                        onAddEvent={() => openFuelDetailsModal(0)}
                                        IsAddButtonVisible={true}
                                        isSearchRequired={true}
                                        // initialSearchContent={searchParams.get("search") ?? ""}
                                        allowSerialNo={true}
                                    />
                                </Container>
                            </TabPanel>
                            
                            {/* Compliance and Certification TAB */}
                            <TabPanel value={value} index={5} dir={theme.direction}>
                                <DeleteConfirmAlert ref={VehicleCertificationDeleteRef} confirmEvent={(v) => onVehicleCertificationDelete(v)} />
                                <ModalAddVehicleCertification ref={VehicleCertificationFormRef} VehicleId={vehicleId} callBackEvent={async () => await FetchVehicleCertificationData(vehicleId)}/>
                                <Container className='mt-2'>
                                    <TableComponent
                                        columns={vehicleCertificationColumns}
                                        data={vehicleCertificationData}
                                        noRecordCss="p-0"
                                        noRecordFound={
                                        <NoRecordTemplate
                                            headerValue="No Vehicle Certification found."
                                            subHeaderValue="Please fill out the Vehicle Certification"
                                            imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                        />
                                        }
                                        onAddEvent={() => openVehicleCertificationModal(0)}
                                        IsAddButtonVisible={true}
                                        isSearchRequired={true}
                                        // initialSearchContent={searchParams.get("search") ?? ""}
                                        allowSerialNo={true}
                                    />
                                </Container>
                            </TabPanel>

                            {/* Driver */}
                            <TabPanel value={value} index={6} dir={theme.direction}>
                                <DeleteConfirmAlert ref={DriverDeleteRef} confirmEvent={(v) => onDriverDelete(v)} />
                                <ModalAddDriver ref={DriverFormRef} VehicleId={vehicleId} callBackEvent={async () => await FetchVehicleDriverData(vehicleId)}/>
                                <Container className='mt-2'>
                                    <TableComponent
                                        columns={vehicleDriverColumns}
                                        data={vehicleDriverData}
                                        noRecordCss="p-0"
                                        noRecordFound={
                                        <NoRecordTemplate
                                            headerValue="No Driver found."
                                            subHeaderValue="Please fill out the Driver"
                                            imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                        />
                                        }
                                        onAddEvent={() => openDriverModal(0)}
                                        IsAddButtonVisible={true}
                                        isSearchRequired={true}
                                        // initialSearchContent={searchParams.get("search") ?? ""}
                                        allowSerialNo={true}
                                        hideDefaultAddLabel={true}
                                        title={"Assign Driver"}
                                    />
                                </Container>
                            </TabPanel>
                        </SwipeableViews>
                </Box>
            </Container>
        </>
    );
}

const ModalAddInsuranceDetails = forwardRef(({ VehicleId, callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [insuranceDetailData, setInsuranceDetailData] = useState({});
    const [selectedInsuranceDocumentFiles, setSelectedInsuranceDocumentFiles] = useState([]);
    const [dbInsuranceDocumentFiles, setDbInsuranceDocumentFiles] = useState([]);
    const [vehicleCoverageTypes, setVehicleCoverageTypes] = useState([]);


    useImperativeHandle(ref, () => ({
        openModal: async (InsuranceDetailId) => {
            await WebService({
                endPoint: `CommonUtility/staticvehiclecoveragetypes`,
                dispatch,
            }).then((res) => {
                if(res.length > 0){
                    const vehicleCoverageTypesData = res.map(item => ({text : item.CoverageType, value : item.VehicleCoverageTypeId}))
                    setVehicleCoverageTypes(vehicleCoverageTypesData);
                }
            })

            if(InsuranceDetailId > 0){
                await WebService({
                    endPoint: `CommonUtility/Edit/vehicleinsurancedetails?VehicleInsuranceDetailId=${InsuranceDetailId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setInsuranceDetailData(res[0]);
                    }
                })

                await WebService({
                    endPoint: `CommonUtility/Edit/vehicleinsurancedocuments?VehicleInsuranceDetailId=${InsuranceDetailId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setDbInsuranceDocumentFiles(res);
                    }
                })
            }

            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setSelectedInsuranceDocumentFiles([]);
        setInsuranceDetailData({});
        setDbInsuranceDocumentFiles([]);
        setVehicleCoverageTypes([]);
    };

    const onSubmitVehicleInsuranceDetail = async (data) => {
        handleClose();

        let VehicleInsuranceDetailId = data.VehicleInsuranceDetailId;

        if(data.VehicleInsuranceDetailId !== undefined){
            await WebService({
                endPoint: `CommonUtility/vehicleinsurancedetails?VehicleInsuranceDetailId=${data.VehicleInsuranceDetailId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Vehicle insurance detail successfully updated.");
            })
        }else{
            data.VehicleId = VehicleId;
            await WebService({
                endPoint: `CommonUtility/vehicleinsurancedetails`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    VehicleInsuranceDetailId = result;
                    WSSuccessAlert("Success", "Vehicle insurance detail successfully saved.");
                }
            })
        }
        if(VehicleInsuranceDetailId > 0 && selectedInsuranceDocumentFiles.length > 0){
            selectedInsuranceDocumentFiles?.map(async (file) => {
                const insuranceFileName = await WebService({
                    endPoint: "upload/File?destination=VehicleInsuranceDocument",
                    dispatch,
                    body: file,
                    isFile: true,
                }).then((res) => res.filename);


                await WebService({
                    endPoint: "CommonUtility/vehicleinsurancedocuments",
                    body: {
                            VehicleInsuranceDetailId : VehicleInsuranceDetailId,
                            DocumentPath : insuranceFileName
                        },
                    dispatch,
                });
            });
        }

        callBackEvent();
    };

    const handleInsuranceFileUpload = (files) => {
        const mergedFiles = selectedInsuranceDocumentFiles.concat(Array.from(files));
        setSelectedInsuranceDocumentFiles(mergedFiles);
        document.getElementById("InputInsuranceFile").value = '';
    };

    const handleRemoveInsuranceDocument = (indexToRemove) => {
        const updatedFiles = selectedInsuranceDocumentFiles.filter((file, index) => index !== indexToRemove);
        setSelectedInsuranceDocumentFiles(updatedFiles);
    };

    const handleDBRemoveInsuranceDocument = async (VehicleInsuranceDocumentId, indexToRemove) => {
        await WebService({
            endPoint: `CommonUtility/vehicleinsurancedocuments?VehicleInsuranceDocumentId=${VehicleInsuranceDocumentId}`,
            method: "DELETE",
            // dispatch,
        }).then((res) => {
            const updatedData = dbInsuranceDocumentFiles.filter((file, index) => index !== indexToRemove);
            setDbInsuranceDocumentFiles(updatedData);
        }).catch((err) => {
            console.error(err);
            WSErrorAlert('Error', 'Please try to delete after some time later.');
        });
    };

    const insuranceSchema = yup.object().shape({
        InsuranceCompany: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        PolicyNumber: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        // PolicyNumber: yup.number().typeError("type number").required(StandardConst.ValidationMessages.RequiredFieldMsg),
        InsuranceExpiryDate: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });
    
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(insuranceDetailData).length > 0) ? "Edit Insurance Details" : "Add Insurance Details"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={insuranceDetailData}
                        onSubmit={onSubmitVehicleInsuranceDetail}
                        validationSchema={insuranceSchema}
                    >
                        <div className="row">
                            
                            <div className="col-md-12">
                                <FormInputText
                                    label="Insurance Company"
                                    name="InsuranceCompany"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Policy Number"
                                    name="PolicyNumber"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12 pb-2">
                                <FormInputDropdown
                                    label="Coverage Type"
                                    name="VehicleCoverageTypeId"
                                    ddOpt={vehicleCoverageTypes}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Insurance Expiry Date"
                                    name="InsuranceExpiryDate"
                                    type="date"
                                    isRequired="true"
                                />
                            </div>

                            <div className="col-md-12">
                                <FormInputFile
                                    label="Upload Insurance Document"
                                    name="InsuranceDocument"
                                    setValue={(files) => { handleInsuranceFileUpload(files) }}
                                    selectMultipleFiles={true}
                                    id="InputInsuranceFile"
                                />
                                <div className="row">
                                    {selectedInsuranceDocumentFiles.map((file, index) => (
                                        <div class = "col-sm-6 col-md-3" key={index}>
                                            <a target='_blank' href={URL.createObjectURL(file)} className = "thumbnail">
                                                {(file.type === "application/pdf") ? (
                                                    <img src={`${StandardConst.ProjectImagesInPublicAssets}/pdf_icon.png`} alt={`file-${index}`}/>
                                                ) : (
                                                    <img src={URL.createObjectURL(file)} alt={`file-${index}`}/>
                                                )}
                                            </a>
                                            <div className="text-dark bg-opacity-25 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => handleRemoveInsuranceDocument(index)}>
                                                <ActionButton
                                                    IconName="Delete"
                                                    id={`Btn_Delete_Tumbnail_${index}`}
                                                />
                                            </div>

                                        </div>
                                    ))}

                                    {dbInsuranceDocumentFiles.map((fileData, i) => (
                                        <div class = "col-sm-6 col-md-3" key={fileData.VehicleInsuranceDocumentId}>
                                            <a target='_blank' href={`${StandardConst.vehicleInsuranceDocument}${fileData.DocumentPath}`} className = "thumbnail">
                                                {(fileData.DocumentPath.split('.').pop() === "pdf") ? (
                                                    <img src={`${StandardConst.ProjectImagesInPublicAssets}/pdf_icon.png`} alt={`file-${fileData.VehicleInsuranceDocumentId}`}/>
                                                ) : (
                                                    <img src={`${StandardConst.vehicleInsuranceDocument}${fileData.DocumentPath}`} alt={`file-${fileData.VehicleInsuranceDocumentId}`}/>
                                                )}
                                            </a>
                                            <div className="text-dark bg-opacity-25 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => handleDBRemoveInsuranceDocument(fileData.VehicleInsuranceDocumentId, i)}>
                                                <ActionButton
                                                    IconName="Delete"
                                                    id={`Btn_Delete_Tumbnail_${fileData.VehicleInsuranceDocumentId}`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                                    
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(insuranceDetailData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

const ModalAddDocumentDetails = forwardRef(({ VehicleId, callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [documentDetailData, setDocumentDetailData] = useState({});
    const [selectedDocumentFiles, setSelectedDocumentFiles] = useState([]);
    const [dbDocumentsFiles, setDbDocumentsFiles] = useState([]);
    const [vehicleDocumentTypes, setVehicleDocumentTypes] = useState([]);


    useImperativeHandle(ref, () => ({
        openModal: async (DocumentDetailId) => {
            await WebService({
                endPoint: `CommonUtility/staticvehicledocumenttypes`,
                dispatch,
            }).then((res) => {
                if(res.length > 0){
                    const vehicleDocumentTypeData = res.map(item => ({text : item.DocumentType, value : item.VehicleDocumentTypeId}))
                    setVehicleDocumentTypes(vehicleDocumentTypeData);
                }
            })
            if(DocumentDetailId > 0){
                await WebService({
                    endPoint: `CommonUtility/Edit/vehicledocumentdetails?VehicleDocumentDetailId=${DocumentDetailId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setDocumentDetailData(res[0]);
                    }
                })

                await WebService({
                    endPoint: `CommonUtility/Edit/vehicledocuments?VehicleDocumentDetailId=${DocumentDetailId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setDbDocumentsFiles(res);
                    }
                })
            }

            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setSelectedDocumentFiles([]);
        setDocumentDetailData({});
        setDbDocumentsFiles([]);
    };

    const onSubmitVehicleDocumentDetail = async (data) => {
        handleClose();

        let VehicleDocumentDetailId = data.VehicleDocumentDetailId;

        if(data.VehicleDocumentDetailId !== undefined){
            await WebService({
                endPoint: `CommonUtility/vehicledocumentdetails?VehicleDocumentDetailId=${data.VehicleDocumentDetailId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Vehicle document detail successfully updated.");
            })
        }else{
            data.VehicleId = VehicleId;
            await WebService({
                endPoint: `CommonUtility/vehicledocumentdetails`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    VehicleDocumentDetailId = result;
                    WSSuccessAlert("Success", "Vehicle document detail successfully saved.");
                }
            })
        }
        if(VehicleDocumentDetailId > 0 && selectedDocumentFiles.length > 0){
            selectedDocumentFiles?.map(async (file) => {
                const documentFileName = await WebService({
                    endPoint: "upload/File?destination=VehicleDocuments",
                    // dispatch,
                    body: file,
                    isFile: true,
                }).then((res) => res.filename);


                await WebService({
                    endPoint: "CommonUtility/vehicledocuments",
                    body: {
                            VehicleDocumentDetailId : VehicleDocumentDetailId,
                            VehicleDocumentPath : documentFileName
                        },
                    // dispatch,
                });
            });
        }

        callBackEvent();
    };

    const handleDocumentFileUpload = (files) => {
        const mergedFiles = selectedDocumentFiles.concat(Array.from(files));
        setSelectedDocumentFiles(mergedFiles);
        document.getElementById("InputDocumentFile").value = '';
    };

    const handleRemoveDocument = (indexToRemove) => {
        const updatedFiles = selectedDocumentFiles.filter((file, index) => index !== indexToRemove);
        setSelectedDocumentFiles(updatedFiles);
    };

    const handleDBRemoveDocument = async (VehicleDocumentId, indexToRemove) => {
        await WebService({
            endPoint: `CommonUtility/vehicledocuments?VehicleDocumentId=${VehicleDocumentId}`,
            method: "DELETE",
            // dispatch,
        }).then((res) => {
            const updatedData = dbDocumentsFiles.filter((file, index) => index !== indexToRemove);
            setDbDocumentsFiles(updatedData);
        }).catch((err) => {
            console.error(err);
            WSErrorAlert('Error', 'Please try to delete after some time later.');
        });
    };
    
    const documentSchema = yup.object().shape({
        DocumentName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        DocumentNumber: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(documentDetailData).length > 0) ? "Edit Document Details" : "Add Document Details"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={documentDetailData}
                        onSubmit={onSubmitVehicleDocumentDetail}
                        validationSchema={documentSchema}
                    >
                        <div className="row">
                            <div className="col-md-12 pb-1">
                                <FormInputDropdown
                                    label="Document Type"
                                    name="VehicleDocumentTypeId"
                                    ddOpt={vehicleDocumentTypes}
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Document Name"
                                    name="DocumentName"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Document Number"
                                    name="DocumentNumber"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Document Description"
                                    name="DocumentDescription"
                                    as="textarea"
                                    rows="2"
                                    className="form-control"
                                />
                            </div>

                            <div className="col-md-12">
                                <FormInputFile
                                    label="Upload Documents"
                                    name="Document"
                                    setValue={(files) => { handleDocumentFileUpload(files) }}
                                    selectMultipleFiles={true}
                                    id="InputDocumentFile"
                                />
                                <div className="row">
                                    {selectedDocumentFiles.map((file, index) => (
                                        <div class = "col-sm-6 col-md-3" key={index}>
                                            <a target='_blank' href={URL.createObjectURL(file)} className = "thumbnail">
                                                {(file.type === "application/pdf") ? (
                                                    <img src={`${StandardConst.ProjectImagesInPublicAssets}/pdf_icon.png`} alt={`file-${index}`}/>
                                                ) : (
                                                    <img src={URL.createObjectURL(file)} alt={`file-${index}`}/>
                                                )}
                                            </a>
                                            <div className="text-dark bg-opacity-25 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => handleRemoveDocument(index)}>
                                                <ActionButton
                                                    IconName="Delete"
                                                    id={`Btn_Delete_Documents_Tumbnail_${index}`}
                                                />
                                            </div>

                                        </div>
                                    ))}

                                    {dbDocumentsFiles.map((fileData, i) => (
                                        <div class = "col-sm-6 col-md-3" key={fileData.VehicleDocumentId}>
                                            <a target='_blank' href={`${StandardConst.vehicleDocuments}${fileData.VehicleDocumentPath}`} className = "thumbnail">
                                                {(fileData.VehicleDocumentPath.split('.').pop() === "pdf") ? (
                                                    <img src={`${StandardConst.ProjectImagesInPublicAssets}/pdf_icon.png`} alt={`file-${fileData.VehicleDocumentId}`}/>
                                                ) : (
                                                    <img src={`${StandardConst.vehicleDocuments}${fileData.VehicleDocumentPath}`} alt={`file-${fileData.VehicleDocumentId}`}/>
                                                )}
                                            </a>
                                            <div className="text-dark bg-opacity-25 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => handleDBRemoveDocument(fileData.VehicleDocumentId, i)}>
                                                <ActionButton
                                                    IconName="Delete"
                                                    id={`Btn_Delete_Documents_Tumbnail_${fileData.VehicleDocumentId}`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                                    
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(documentDetailData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

const ModalAddFuelDetails = forwardRef(({ VehicleId, lastRecord, callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [fuelDetailData, setFuelDetailData] = useState({});
    const [formErrMsg, setFormErrMsg] = useState(null);

    useImperativeHandle(ref, () => ({
        openModal: async (VehicleFuelHistoryId) => {
            if(VehicleFuelHistoryId > 0){
                await WebService({
                    endPoint: `CommonUtility/Edit/vehiclefuelhistory?VehicleFuelHistoryId=${VehicleFuelHistoryId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setFuelDetailData(res[0]);
                    }
                })
            }
            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setFuelDetailData({});
        setFormErrMsg(null);
    };

    const onSubmitFuelDetail = async (data) => {
        if(data.OdometerReading <= lastRecord?.OdometerReading){
            setFormErrMsg(StandardConst.VehicleFuelFormErrMsg);
            return false;
        }
        
        handleClose();
        
        data.RefuelingDate = format(new Date(data.RefuelingDate), "yyyy-MM-dd");
        if(data.VehicleFuelHistoryId !== undefined){
            await WebService({
                endPoint: `CommonUtility/vehiclefuelhistory?VehicleFuelHistoryId=${data.VehicleFuelHistoryId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Fuel detail successfully updated.");
            })
        }else{
            data.VehicleId = VehicleId;
            await WebService({
                endPoint: `CommonUtility/vehiclefuelhistory`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    WSSuccessAlert("Success", "Fuel detail successfully saved.");
                }
            })
        }

        await WebService({
            endPoint: `Vehicle/UpdateVehicleMileage?VehicleId=${VehicleId}`,
            method: 'PUT',
            dispatch,
        }).then((result) => {
            if(result > 0){
                WSSuccessAlert("Success", "UPDATE MILEAGE.");
            }
        })

        callBackEvent();
    };

    const fuelSchema = yup.object().shape({
        RefuelingDate: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
        OdometerReading: yup.number().typeError(StandardConst.ValidationMessages.InvalidCharMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
        FuelInlitre: yup.number().typeError(StandardConst.ValidationMessages.InvalidCharMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });
    
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(fuelDetailData).length > 0) ? "Edit Fuel Details" : "Add Fuel Details"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={fuelDetailData}
                        onSubmit={onSubmitFuelDetail}
                        validationSchema={fuelSchema}
                    >
                        {(formErrMsg !== null) && (
                            <div className='mb-2'>
                                <Alert severity="error">
                                    {formErrMsg}
                                </Alert>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-md-12">
                                <FormInputText
                                    label="Refueling Date"
                                    name="RefuelingDate"
                                    type="date"
                                    min={lastRecord?.RefuelingDate}
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12 pb-2">
                                <div className="row">
                                    <div className="col-5">
                                        <label className='form-label' style={{fontFamily: "sans-serif", fontSize: "14px"}}>Last odometer reading</label>
                                    </div>
                                    <div className="col-7 p-0 d-flex align-items-center justify-content-start">
                                        <label className="m-0">{(lastRecord?.OdometerReading) ? lastRecord?.OdometerReading : " - "}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Odometer Reading"
                                    name="OdometerReading"
                                    type="text"
                                    isRequired="true"
                                    setValue={(val) => (val <= lastRecord.OdometerReading) ? setFormErrMsg(StandardConst.VehicleFuelFormErrMsg) : setFormErrMsg(null)}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Fuel In Litre"
                                    name="FuelInlitre"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                                    
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(fuelDetailData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

const ModalAddDriver = forwardRef(({ VehicleId, callBackEvent }, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [vehicleDriverAssociationData, setVehicleDriverAssociationData] = useState({weekDays: StandardConst.weekDays});
    const [selectedDays, setSelectedDays] = useState([]);
    const [fullTime, setFullTime] = useState(false);
    const [driversDropdown, setDriversDropdown] = useState([]);
    const [assignedFromDate, setAssignedFromDate] = useState(null);


    useImperativeHandle(ref, () => ({
        openModal: async (VehicleDriverAssignmentId, VehicleOfficeLocationId) => {
            let EndPoint = `Vehicle/getDriverListOfficeLocationOrCompany`;
            if(VehicleOfficeLocationId !== null) {
                EndPoint += `?OfficeLocationId=${VehicleOfficeLocationId}`;
            }
            await WebService({
                endPoint: EndPoint,
                dispatch,
            }).then(res => setDriversDropdown(res.map(item => ({value: item.DriverId, text: item.DriverName}))));

            if(VehicleDriverAssignmentId > 0){
                await WebService({
                    endPoint: `CommonUtility/Edit/vehicledriverassignment?VehicleDriverAssignmentId=${VehicleDriverAssignmentId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        if(res[0].FullTime === StandardConst.YesNo.Yes){
                            setFullTime(true);
                        }
                        if (res && res[0] && res[0].WorkingDays !== null) {
                            setSelectedDays(res[0].WorkingDays.split(",").map(day => parseInt(day)));
                        }
                        res[0].weekDays = StandardConst.weekDays;
                        if(res[0].ShiftFromTime !== null) {
                            res[0].ShiftFromTime = format(new Date("2022-01-01T" + res[0].ShiftFromTime), 'hh:mm');
                        }

                        if(res[0].ShiftToTime !== null) {
                            res[0].ShiftToTime = format(new Date("2022-01-01T" + res[0].ShiftToTime), 'hh:mm');
                        }
                        setAssignedFromDate(res[0].AssignedFromDate);
                        setVehicleDriverAssociationData(res[0]);
                    }
                })
            }
            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setVehicleDriverAssociationData({weekDays: StandardConst.weekDays});
        setSelectedDays([]);
        setDriversDropdown([]);
        setFullTime(false);
        setAssignedFromDate(null);
    };

    const onSubmitDriverAssociation = async (data) => {
        handleClose();
        
        if(data.FullTime === null){
            data.FullTime = false;
        }
        if(data.FullTime){
            data.ShiftFromTime = null;
            data.ShiftToTime = null;
        }

        if(selectedDays.length > 0){
            data.WorkingDays = selectedDays.join(",");
        }else{
            WSErrorAlert('Error', 'Please choose at least one duty day.');
            return 1;
        }

        if(data.FullTime){
            data.FullTime = 1;
        }else{
            data.FullTime = 0;
        }

        data.AssignedFromDate = format(new Date(data.AssignedFromDate), "yyyy-MM-dd");
        data.AssignedToDate = format(new Date(data.AssignedToDate), "yyyy-MM-dd");

        if(data.VehicleDriverAssignmentId !== undefined){
            await WebService({
                endPoint: `CommonUtility/vehicledriverassignment?VehicleDriverAssignmentId=${data.VehicleDriverAssignmentId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Driver detail successfully updated.");
            })
        }else{
            data.VehicleId = VehicleId;
            await WebService({
                endPoint: `CommonUtility/vehicledriverassignment`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    WSSuccessAlert("Success", "Driver detail successfully saved.");
                }
            })
        }

        callBackEvent();
    };


    const handleFormat = (event, newFormats) => {
      setSelectedDays(newFormats);
    };

    const WsToggleButton = styled(ToggleButton)(({ selectedcolor }) => ({
        "&.Mui-selected, &.Mui-selected:hover": {
          color: "white",
          backgroundColor: selectedcolor,
        },
    }));

    const requiredMessage = "This is a required field";
    const schema = yup.object().shape({
        AssignedFromDate: yup.date().typeError(requiredMessage).required(requiredMessage),
        AssignedToDate: yup.date().typeError(requiredMessage).required(requiredMessage),

        ShiftFromTime: (!fullTime) ? yup
                .mixed()
                .test('is-time-valid', 'Time is not valid', (value) => {
                    if (!value) return false; // don't empty values
                    return true;
                })
                .typeError(requiredMessage)
                .required(requiredMessage) : null,

        ShiftToTime: (!fullTime) ? yup
            .mixed()
            .test('is-time-valid', 'End Time cannot be less than start time', (value, { parent }) => {
                if (!value) return false;
                const ShiftFromTime = yup.string().required().matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format').validateSync(parent.ShiftFromTime);
                let isValidTimeFormat = yup.string().required().matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format').isValidSync(value);
                    if (isValidTimeFormat && value <= ShiftFromTime) {
                        const assignedToDate = format(new Date(parent.AssignedToDate), "dd-MM-yyyy");
                        const assignedFromDate = format(new Date(parent.AssignedFromDate), "dd-MM-yyyy");
                        if (assignedToDate && assignedFromDate && assignedToDate === assignedFromDate) {
                            return false;
                        }
                        return true;
                    }
                    return true;
            })
            .typeError(requiredMessage)
            .required(requiredMessage) : null
      });
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(vehicleDriverAssociationData?.VehicleDriverAssignmentId !== undefined) ? "Change Driver Detail" : "Assign Driver"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    {(driversDropdown.length > 0) ? (
                        <Form 
                            defaultValues={vehicleDriverAssociationData}
                            onSubmit={onSubmitDriverAssociation}
                            validationSchema={schema}
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <FormInputDropdown
                                        label="Driver"
                                        name="DriverId"
                                        ddOpt={driversDropdown}
                                        isRequired="true"
                                    />
                                </div>

                                <div className="col-md-12">
                                    <FormInputText
                                        label="Duty Start Date"
                                        name="AssignedFromDate"
                                        type="date"
                                        max="2999-12-31"
                                        setValue={(val) => setAssignedFromDate(val)}
                                        isRequired="true"
                                    />
                                </div>

                                <div className="col-md-12">
                                    <FormInputText
                                        label="Duty End Date"
                                        name="AssignedToDate"
                                        type="date"
                                        min={assignedFromDate}
                                        max="2999-12-31"
                                        disabled={assignedFromDate ? false : true}
                                        isRequired="true"
                                    />
                                </div>

                                <div className="col-md-12">
                                    <FormCheckRadioInput
                                        label="Drive Full Day"
                                        name="FullTime"
                                        isRadio={false}
                                        setValue={(val) => setFullTime(val) }
                                    />
                                </div>
                                {(!fullTime) && (
                                    <>
                                        <div className="col-md-12">
                                            <FormInputText
                                                label="Shift Start Time"
                                                name="ShiftFromTime"
                                                type="time"
                                                isRequired="true"
                                            />
                                        </div>

                                        <div className="col-md-12">
                                            <FormInputText
                                                label="Shift End Time"
                                                name="ShiftToTime"
                                                type="time"
                                                isRequired="true"
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="col-md-12">
                                    <div className="float-start col-md-5 text-wrap">
                                        <label className="form-label">
                                            <div className="d-flex justify-content-start  ">
                                                <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                                                Duty Days
                                                </div>
                                                <div className="text-danger">＊</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="float-end col-md-7">
                                        <ToggleButtonGroup
                                        color="primary"
                                        size="small"
                                        value={selectedDays}
                                        onChange={handleFormat}
                                        aria-label="text formatting"
                                        >
                                        {vehicleDriverAssociationData?.weekDays?.map((wd) => {
                                            return (
                                            <WsToggleButton
                                                selectedcolor={StandardConst.wsPrimaryColor}
                                                value={wd.value}
                                                aria-label="bold"
                                                style={{
                                                marginLeft: "3px",
                                                height: "35px",
                                                width: "35px",
                                                }}
                                            >
                                                {wd.ShortCode}
                                            </WsToggleButton>
                                        );
                                        })}
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                        
                                <div className='col-md-12'>
                                    <Button
                                        variant="outline-primary"
                                        type="submit"
                                        id="btnSubmit"
                                        className="px-4 float-end mt-4 mb-1"
                                        style={{ marginRight: "27px" }}
                                    >
                                        {(vehicleDriverAssociationData?.VehicleDriverAssignmentId !== undefined) ? "Save Changes" : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    ) : (
                        <NoRecordTemplate
                            headerValue="No Driver Available"
                            subHeaderValue="Please add atleast one driver to be assigned to the vehicle."
                            imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                            cssClass="p-md-0"
                            actionButton={
                                <>
                                    {/* {permission.ManageAdd && ( */}
                                        <Button variant="outline-primary" onClick={() => navigate("/VehicleDriverAssignment", { state : {OpenAddModal : true}})}>
                                            Add Driver
                                        </Button>
                                    {/* )} */}
                                </>
                            }
                        />
                    )}
                    
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

const ModalAddVehicleCertification = forwardRef(({ VehicleId, callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [documentDetailData, setVehicleCertificationData] = useState({});
    const [selectedDocumentFiles, setSelectedDocumentFiles] = useState([]);
    const [dbDocumentsFiles, setDbDocumentsFiles] = useState([]);
    const [vehicleCertificationDropdown, setVehicleCertificationDropdown] = useState([]);
    


    useImperativeHandle(ref, () => ({
        openModal: async (VehicleComplianceAndCertificationId) => {
            await WebService({
                endPoint: `CommonUtility/staticvehiclecertificatetypes`,
                dispatch,
            }).then((res) => {
                if(res.length > 0){
                    setVehicleCertificationDropdown(res.map((item) =>({text: item.VehicleCertificateType, value: item.VehicleCertificateTypeId})));
                }
            })

            if(VehicleComplianceAndCertificationId > 0){
                await WebService({
                    endPoint: `CommonUtility/Edit/vehiclecomplianceandcertifications?VehicleComplianceAndCertificationId=${VehicleComplianceAndCertificationId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setVehicleCertificationData(res[0]);
                    }
                })

                await WebService({
                    endPoint: `CommonUtility/Edit/vehiclecertificationfiles?VehicleComplianceAndCertificationId=${VehicleComplianceAndCertificationId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setDbDocumentsFiles(res);
                    }
                })
            }

            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setSelectedDocumentFiles([]);
        setVehicleCertificationData({});
        setDbDocumentsFiles([]);
    };

    const onSubmitVehicleVehicleCertification = async (data) => {
        handleClose();

        let VehicleComplianceAndCertificationId = data.VehicleComplianceAndCertificationId;

        if(data.VehicleComplianceAndCertificationId !== undefined){
            await WebService({
                endPoint: `CommonUtility/vehiclecomplianceandcertifications?VehicleComplianceAndCertificationId=${data.VehicleComplianceAndCertificationId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Vehicle certification detail successfully updated.");
            })
        }else{
            data.VehicleId = VehicleId;
            await WebService({
                endPoint: `CommonUtility/vehiclecomplianceandcertifications`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    VehicleComplianceAndCertificationId = result;
                    WSSuccessAlert("Success", "Vehicle certification detail successfully saved.");
                }
            })
        }
        if(VehicleComplianceAndCertificationId > 0 && selectedDocumentFiles.length > 0){
            selectedDocumentFiles?.map(async (file) => {
                const documentFileName = await WebService({
                    endPoint: "upload/File?destination=VehicleCertificationDocuments",
                    dispatch,
                    body: file,
                    isFile: true,
                }).then((res) => res.filename);


                await WebService({
                    endPoint: "CommonUtility/vehiclecertificationfiles",
                    body: {
                            VehicleComplianceAndCertificationId : VehicleComplianceAndCertificationId,
                            FilePath : documentFileName
                        },
                    dispatch,
                });
            });
        }

        callBackEvent();
    };

    const handleDocumentFileUpload = (files) => {
        const mergedFiles = selectedDocumentFiles.concat(Array.from(files));
        setSelectedDocumentFiles(mergedFiles);
        document.getElementById("InputDocumentFile").value = '';
    };

    const handleRemoveDocument = (indexToRemove) => {
        const updatedFiles = selectedDocumentFiles.filter((file, index) => index !== indexToRemove);
        setSelectedDocumentFiles(updatedFiles);
    };

    const handleDBRemoveDocument = async (VehicleCertificationFileId, indexToRemove) => {
        await WebService({
            endPoint: `CommonUtility/vehiclecertificationfiles?VehicleCertificationFileId=${VehicleCertificationFileId}`,
            method: "DELETE",
            // dispatch,
        }).then((res) => {
            const updatedData = dbDocumentsFiles.filter((file, index) => index !== indexToRemove);
            setDbDocumentsFiles(updatedData);
        }).catch((err) => {
            console.error(err);
            WSErrorAlert('Error', 'Please try to delete after some time later.');
        });
    };
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(documentDetailData).length > 0) ? "Edit Vehicle Certification" : "Add Vehicle Certification"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={documentDetailData}
                        onSubmit={onSubmitVehicleVehicleCertification}
                    >
                        <div className="row">
                            
                            <div className="col-md-12 pb-2">
                                <FormInputDropdown
                                    ddOpt={vehicleCertificationDropdown}
                                    label="Vehicle Certificate Type"
                                    name="VehicleCertificateTypeId"
                                    type="text"
                                    // isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Description"
                                    name="Description"
                                    as="textarea"
                                    rows="2"
                                    className="form-control"
                                />
                            </div>

                            <div className="col-md-12">
                                <FormInputFile
                                    label="Upload Documents"
                                    name="Document"
                                    setValue={(files) => { handleDocumentFileUpload(files) }}
                                    selectMultipleFiles={true}
                                    id="InputDocumentFile"
                                />
                                <div className="row">
                                    {selectedDocumentFiles.map((file, index) => (
                                        <div class = "col-sm-6 col-md-3" key={index}>
                                            <a target='_blank' href={URL.createObjectURL(file)} className = "thumbnail">
                                                {(file.type === "application/pdf") ? (
                                                    <img src={`${StandardConst.ProjectImagesInPublicAssets}/pdf_icon.png`} alt={`file-${index}`}/>
                                                ) : (
                                                    <img src={URL.createObjectURL(file)} alt={`file-${index}`}/>
                                                )}
                                            </a>
                                            <div className="text-dark bg-opacity-25 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => handleRemoveDocument(index)}>
                                                <ActionButton
                                                    IconName="Delete"
                                                    id={`Btn_Delete_Documents_Tumbnail_${index}`}
                                                />
                                            </div>

                                        </div>
                                    ))}

                                    {dbDocumentsFiles.map((fileData, i) => (
                                        <div class = "col-sm-6 col-md-3" key={fileData.VehicleCertificationFileId}>
                                            <a target='_blank' href={`${StandardConst.VehicleCertificationDocuments}${fileData.FilePath}`} className = "thumbnail">
                                                {(fileData.FilePath.split('.').pop() === "pdf") ? (
                                                    <img src={`${StandardConst.ProjectImagesInPublicAssets}/pdf_icon.png`} alt={`file-${fileData.VehicleCertificationFileId}`}/>
                                                ) : (
                                                    <img src={`${StandardConst.VehicleCertificationDocuments}${fileData.FilePath}`} alt={`file-${fileData.VehicleCertificationFileId}`}/>
                                                )}
                                            </a>
                                            <div className="text-dark bg-opacity-25 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => handleDBRemoveDocument(fileData.VehicleCertificationFileId, i)}>
                                                <ActionButton
                                                    IconName="Delete"
                                                    id={`Btn_Delete_Documents_Tumbnail_${fileData.VehicleCertificationFileId}`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                                    
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(documentDetailData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

export default VehicleRegistration;
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormCheckRadioInput, FormInputDropdown, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import HtmlFileReader from "../../Services/HtmlFileReader";
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { map } from "underscore";
import SettingsIcon from '@mui/icons-material/Settings';

const apiUrl = "CommonUtility/static_visitor_type";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditVisitorType = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ TypeName: {} });
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [paramData, setParamData] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [badgeType, setBadgeType] = useState(StandardConst.BadgeType[0].text);

  const Roles = useSelector((s) => s.auth.AssignRole ?? {});
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      fetchDummyData();
      if(id){
        setShow(false);
        // const data = {
        //   TypeName: await WebService({
        //     endPoint: `${apiUrl}?where=VisitorTypeId eq ${id || 0}`,
        //     dispatch,
        //   }).then((c) => (c.length > 0 ? c[0] : {})),
        // };
        const data = {
          TypeName: await WebService({
            endPoint: `CommonUtility/Edit/static_visitor_type?VisitorTypeId=${id || 0}`,
            dispatch,
          }).then((c) => (c.length > 0 ? c[0] : {}))
        }
        const badgeId = await WebService({
          endPoint: `CommonUtility/Edit/VisitorTypeBadge?VisitorTypeId=${id || 0}`,
          dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}))

        if(Object.keys(badgeId).length > 0){
          const templatesName = await WebService({
            endPoint: `CommonUtility/Edit/badge_templates?badge_templatesId=${badgeId.BadgeTemplatesId || 0}`,
            dispatch,
          }).then((c) => (c.length > 0 ? c[0] : {}))
          setTemplateData([{text: templatesName.badge_template_name, value: templatesName.badge_templatesId}])
        }

        if(data.TypeName.IsEventVisitorType){
          setBadgeType(StandardConst.BadgeType[2].text);
        }
        setData(data);
        setShow(true);
      }
      else {
        data.TypeName = {
          WillProvidePhoneNo: true,
          WillProvideEmail: true,
      }
        setData(data);
      }
    },
  }));

  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  
  const fetchDummyData = () => {

    const paramData = {
      Name: "VisitorName",
      Photo: `null`,
      VisitorType: "Visitor TypeName",
      Company: "VisitorCompany",
      Email: "Email",
      Phone: "ContactNo",
      VisitorId: "VisitorId",
      CheckInTime:"CheckIn",
      TemplateName: "/sample.html",
      QRData: {
        Name: "VisitorName",
        Company: "VisitorCompany",
        Email: "Email",
        Phone: "ContactNo",
      },
      Logo: CompanyInfo.Logo,
      VisitorGuid: "Guid"
    };
    setParamData(paramData)
  }
  const handleClose = () => {
    setShow(false);
    setData({ TypeName: {} });
    setTemplateData([]);
    setIsCollapsed(true);
    setBadgeType(StandardConst.BadgeType[0].text);
  }
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      TypeName: yup.string().trim().required(requiredMessage),
    })
    .required();

    const fetchBadgeId = async() => {

      const badgeTemplates = await WebService({
        dispatch, 
        endPoint: `CommonUtility/fetchAll/badge_templates?CompanyId=${StandardConst.YesNo.Yes}&isReserved=${StandardConst.YesNo.Yes}`,
      });

      const badgeId = badgeTemplates.find((template) => {
        return template.badge_templatesId === Number(selectedFile);
      });

      return badgeId.badge_templatesId;
    }

  const onSubmit = async (data) => {

    data.IsEventVisitorType = (data.IsEventVisitorType !== null && ((data.IsEventVisitorType ? 1 : 0) || 0) === 1) ? 1 : 0;
    data.NotVisibleInSelfService = (data.NotVisibleInSelfService !== null && ((data.NotVisibleInSelfService ? 1 : 0)) === 1) ? 1 : 0;
    
    var visitorTypeId = data.VisitorTypeId;

    if (visitorTypeId === undefined) {
      // jiski object properties ki value false hogi usko null kr diya hai yha pr 
      for (let key in data) {
        if (data.hasOwnProperty(key) && data[key] === false) {
          data[key] = null;
        }
      }
      if(Roles[0] === StandardConst.SystemRole.SuperAdmin){
        if(Number(data.CompanyId) === 0){
          data.CompanyId = null;
          data.IsReserved = StandardConst.YesNo.Yes;
        }
        else {
          data.CompanyId = Number(data.CompanyId);
        }
      }

      visitorTypeId = await WebService({
        endPoint: "CommonUtility/SaveVisitorType/static_visitor_type",
        body: data,
        dispatch,
      });

    } else {
      await WebService({
        endPoint: `${apiUrl}?VisitorTypeId=${data.VisitorTypeId}&SettingNull=true`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }

    if(visitorTypeId && selectedFile !== undefined) {
      const badgeId = (selectedFile !== "") ? await fetchBadgeId() : null;
      // this is old BadgeTemplateId for search existing data
      var searchBadgeTemplateId = templateData[0] ? templateData[0].value : null;
      if(searchBadgeTemplateId === undefined){
        searchBadgeTemplateId = null;
      }
      const visitorBadgeData = {
        VisitorTypeId: visitorTypeId,
        BadgeTemplatesId : badgeId,
      }

      await WebService({
        endPoint: `Visitor/AddOrUpdateVisitorBadgeTemplate/${visitorTypeId}/${searchBadgeTemplateId}`,
        method:"POST",
        body: visitorBadgeData,
        dispatch,
      })
    }
    handleClose();
    prop.callBackEvent();
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleSelectFile = (Filename) => {
    setSelectedFile(Filename);
  }

  const labelFontSize = {
    fontSize: "14px",
  }

  const dynamicStyles = {
    IsReserved: {
      position: "relative",
      top: "11px",
    },
    // Add more styles as needed
  };

  const [companyList, setCompanyList] = useState([]);

  const getAllCompaniesList = async () => {
    await WebService({
      endPoint: `CommonUtility/fetchAll/companyinformation`,
      dispatch,
    }).then((companyList) => {

      const companyListArray = map(companyList, (oneCompany) => ({
        value: oneCompany.CompanyId || "" || null || undefined,
        text: oneCompany.CompanyName,
      }));

      companyListArray.unshift({ value: 0, text: "All Companies" });
      setCompanyList(companyListArray);
    });
  };

  useEffect(() => {
    if(Roles[0] === StandardConst.SystemRole.SuperAdmin){
      getAllCompaniesList();
    }
  }, [Roles]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.TypeName.VisitorTypeId || 0) === 0
            ? "Add Visitor Type"
            : "Edit Visitor Type"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.TypeName}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Visitor Type"
                name="TypeName"
                type="text"
                isRequired="true"
              />
            </div>

            <div className="col-md-12">
              <FormCheckRadioInput
                label="Visitor type for event"
                name="IsEventVisitorType"
                isRadio={false}
                setValue={(val) => (val) ? setBadgeType(StandardConst.BadgeType[2].text) : setBadgeType(StandardConst.BadgeType[0].text) }
              />
            </div>

            <div className="col-md-12 pb-1">
              <FormCheckRadioInput
                label="Hide in self service"
                name="NotVisibleInSelfService"
                isRadio={false}
              />
            </div>
            
            <div className="col-md-12">
              {Roles[0] === StandardConst.SystemRole.SuperAdmin && (
                <FormInputDropdown
                  name="CompanyId"
                  ddOpt={companyList}
                  label="Access"
                >
                </FormInputDropdown>
              )}
            </div>
            
            <div className="col-md-12" style={Roles[0] === StandardConst.SystemRole.SuperAdmin ? dynamicStyles.IsReserved : {} }>
                <span style={{color: "#5a5a5a", position: "relative", top: "6px", fontSize: "14px"}}>
                            Visitor Badge Type{" "}
                </span>
                {(badgeType === StandardConst.BadgeType[0].text) && (
                  <HtmlFileReader 
                    jsonData={paramData} 
                    // defaultTemplate={"QR.html"} 
                    ShowDropdown={StandardConst.YesNo.Yes} 
                    selectPosition={true}
                    selectFile={handleSelectFile}
                    SelectedTemplateId={templateData}
                    showDropDownList={badgeType}
                  />
                )}

                {(badgeType === StandardConst.BadgeType[2].text) && (
                  <HtmlFileReader 
                    jsonData={paramData} 
                    // defaultTemplate={"QR.html"} 
                    ShowDropdown={StandardConst.YesNo.Yes} 
                    selectPosition={true}
                    selectFile={handleSelectFile}
                    SelectedTemplateId={templateData}
                    showDropDownList={badgeType}
                  />
                )}
            </div>
          </div>
          <div className="row">
            <h5 onClick={toggleCollapse}>
                    <SettingsIcon fontSize="small" />
                    Setting
                    {isCollapsed ? (
                      <ExpandMore fontSize="small" />
                    ) : (
                      <ExpandLess fontSize="small" />
                    )}
            </h5>
            <hr></hr>
            <div className={`col-md-6 ${isCollapsed ? 'd-none' : ''}`}>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="VisitorBadgeNeeded"
                    isRadio={false}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="This visitor needs a visitor badge" style={labelFontSize}>
                    Visitor badge
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="WillWaitAtReception"
                    isRadio={false}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="Will not enter campus, will wait at reception only" style={labelFontSize}>
                    Will wait at reception only
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="PrePrintVisitorBadges"
                    isRadio={false}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="Can pre-print the visitor badges before the visit" style={labelFontSize}>
                    Pre-print badge
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="CanBringOtherGuests"
                    isRadio={false}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="Can pre-print the visitor badges before the visit" style={labelFontSize}>
                    Can bring other guests
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="GuestWillEnterCampus"
                    isRadio={false}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="Guests with the visitor will also enter the campus" style={labelFontSize}>
                    Guest will enter campus
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="GuestPassAlsoNeeded"
                    isRadio={false}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="Guests needs seperate passes?" style={labelFontSize}>
                    Guests needs seperate passes?
                  </label>
                </div>
              </div>
            </div>
            <div className={`col-md-6 ${isCollapsed ? 'd-none' : ''}`} >
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="CaptureNoOfGuests"
                    isRadio={false}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="No of guests coming with the visitor needs to be captured?" style={labelFontSize}>
                    Capture Number Of Guests
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput name="IsVIPVisitor" isRadio={false} />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="Is VIP Visitor - Visitor pass will be sent along with the invite" style={labelFontSize}>
                    Is VIP Visitor
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="WillProvidePhoneNo"
                    isRadio={false}
                    setValue={(e) => {
                      setData({ ...data, WillProvidePhoneNo: e });
                    }}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label title="Will provide phone number?" style={labelFontSize}>
                    Will provide phone number?
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="WillProvideEmail"
                    isRadio={false}
                    setValue={(e) => {
                      setData({ ...data, WillProvideEmail: e });
                    }}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label 
                  title="Will provide email?" style={labelFontSize}>
                    Will provide email?
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="float-start col-md-2 text-wrap">
                  <FormCheckRadioInput
                    name="IsServiceProvider"
                    isRadio={false}
                  />
                </div>
                <div className="float-start col-md-10 text-wrap">
                  <label
                    title="Is a service provider? No Host needed."
                    style={labelFontSize}
                  >
                    Is a service provider? No Host needed.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnVisitorTypeSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data.TypeName.VisitorTypeId || 0) === 0
              ? "Submit"
              : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditVisitorType);

import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputDropdown, FormInputFile, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { map, omit } from "underscore";
import Cookies from "universal-cookie";
import { Tooltip } from "@mui/material";
import { WSSuccessAlert } from "../../Services/WSAlert";

const requiredMessage = StandardConst.requiredMessage;
const numberError = StandardConst.numberError;
const mobileMessage = StandardConst.mobileMessage;
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditCompany = forwardRef((prop, ref) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ CompanyProfile: {} });
  // const [file, setFile] = useState();
  // const [Currency, SetCurrency] = useState();
  // const [Country, SetCountry] = useState();
  // const [fileName, setFileName] = useState("");

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      const data = {};
      const p3 = WebService({
        endPoint: `CompanyProfile/Fetch/${id || 0}`,
        dispatch,
      }).then((r) => {
        data.CompanyProfile = r.CompanyProfile ?? {};
      });
      const p1 = WebService({
        endPoint: `Currency/FetchCurrency`,
        dispatch,
      }).then((r) => {
        data.CurrencyData = map(r, (v) => ({
          value: v.CurrencyId || "" || null || undefined,
          text: `${v.Symbol} - ${v.Description}`,
        }));
      });
      const p2 = WebService({
        endPoint: `CompanyProfile/FetchCountry`,
        dispatch,
      }).then((r) => {
        data.CountryData = map(r, (v) => ({
          value: v.CountryId || "" || null || undefined,
          text: `${v.CountryCode} - ${v.Country}`,
        }));
      });
      await Promise.all([p1, p2, p3]);
      // data.CompanyProfile.DomainName ??= window.location.href
      //   .toString()
      //   .toLowerCase()
      //   .replaceAll("https://", "")
      //   .replaceAll("http://", "")
      //   .split("/#/")[0];
      setData(data);
      setShow(true);
    },
  }));

  const handleClose = () => setShow(false);
  const schema = yup
    .object()
    .shape({
      CompanyName: yup.string().trim().required(requiredMessage),
      Type: yup.string().trim().required(requiredMessage),
      Phone: yup.string()
        .required(StandardConst.requiredMessage)
        .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid),

      // PanNo: yup.string().trim().required(requiredMessage),
      Email: yup.string().email().required(requiredMessage),
      State: yup.string().trim().required(requiredMessage),
      city: yup.string().trim().required(requiredMessage),
      pincode: yup.string().trim().required(requiredMessage),
      Address1: yup.string().trim().required(requiredMessage),
      CurrencyId: yup.string().trim().required(requiredMessage),
      CountryId: yup.string().trim().required(requiredMessage),
      Fax: yup
        .string()
        .trim()
        .notRequired()
        .label("Fax")
        .matches(/^\+?[0-9]{6,12}$/, "Is not in correct format"),
    })
    .required();

  const onSubmit = async (data) => {
    if ((data.File?.size ?? 0) > 0)
      await WebService({
        endPoint: "upload/File",
        dispatch,
        body: data.File,
        isFile: true,
      }).then((res) => {
        data.Logo = res.filename;
      });
    data = omit(data, "File");

    if ((data.DomainName ?? "") !== "") {
      data.DomainName = data.DomainName.toString()
        .toLowerCase()
        .replaceAll("https://", "")
        .replaceAll("http://", "")
        .split("/#/")[0];
    }
    await WebService({
      endPoint: "CompanyProfile/Submit",
      body: data,
      dispatch,
    });
    await WebService({
      dispatch,
      endPoint: `CommonUtility/companyinformation?where=CompanyId eq ${data.CompanyId}`,
    }).then((c) => {
      if (c.length > 0)
        cookies.set("company", JSON.stringify(c[0]), { path: "/" });
    });
    WSSuccessAlert("Success", "Company information updated successfully.");
    dispatch(ConfigurationflowStatusUpdate());
    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.CompanyProfile.CompanyId || 0) === 0
            ? "Add Company Profile"
            : "Update Company Profile"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.CompanyProfile}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body>
          <div className="card">
            <div className="alert alert-primary m-0" role="alert">
              <div className="row d-flex justify-content-around align-items-center">
                <div className="col-3 ">
                  <img
                    src={
                      data.CompanyProfile.Logo != ""
                        ? `${StandardConst.apiBaseUrl}/uploads/${
                            data.CompanyProfile.Logo ?? "user_placeholder.jpg"
                          }`
                        : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                    }
                    className="rounded img-thumbnail border-2 border-primary border-opacity-50 shadow-2 object-fit-cover"
                    alt="..."
                  />
                </div>
                <div className="col-9">
                  <h4 className="alert-heading m-0 p-0">Company Logo</h4>
                  <span>
                    This logo will be used throughout the portal and will be
                    also included on the pay slips{" "}
                  </span>
                  <hr />
                  <FormInputFile name="File" type="file" />
                </div>
              </div>
            </div>
          </div>
          <div className="card bg-light">
            <div className="alert alert-primary" role="alert">
              Company Basic Details
            </div>
            <div className="row px-2">
              <div className="col">
                <FormInputText
                  label="Company Name"
                  name="CompanyName"
                  type="text"
                  isRequired="true"
                />{" "}
              </div>
            </div>
            <div className="row px-2">
              <div className="col">
                <FormInputDropdown
                  name="Type"
                  label="Type"
                  ddOpt={StandardConst.companyTypes}
                  isRequired="true"
                />
              </div>
              <div className="col">
                <FormInputText
                  name="Phone"
                  label="Phone"
                  type="text"
                  isRequired="true"
                />
              </div>
            </div>
            <div className="row px-2">
              <div className="col">
                <FormInputText
                  name="Email"
                  label="Email"
                  isRequired="true"
                  type="text"
                />
              </div>
              <div className="col">
                <FormInputText label="Fax" name="Fax" type="text" />
              </div>
            </div>
            <div className="row px-2 ">
              <div className="col">
                <FormInputText name="TIN" label="TIN" type="text" />
              </div>
              <div className="col">
                <FormInputText name="PanNo" label="PAN Number" type="text" />
              </div>
            </div>
          </div>
          <div className="card bg-light pt-2">
            <div className="alert alert-primary" role="alert">
              Company Address Details
            </div>
            <div className="row px-2">
              <div className="col">
                <FormInputText
                  label="Address Line 1"
                  name="Address1"
                  type="text"
                  isRequired="true"
                />
              </div>
            </div>
            <div className="row px-2 pb-1">
              <div className="col">
                <FormInputText
                  label="Address Line 2"
                  name="Address2"
                  type="text"
                />
              </div>
            </div>
            <div className="row px-2 pb-1">
              <div className="col">
                <FormInputText
                  label="Address Line 3"
                  name="Address3"
                  type="text"
                />
              </div>
            </div>

            <div className="row px-2">
              <div className="col">
                {" "}
                <FormInputText
                  label="City"
                  name="city"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col">
                <FormInputText
                  label="State"
                  name="State"
                  type="text"
                  isRequired="true"
                />
              </div>
            </div>
            <div className="row px-2">
              <div className="col">
                <FormInputText
                  label="Pincode"
                  name="pincode"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col d-none">
                {" "}
                <FormInputText label="Remarks" name="Remarks" type="text" />
              </div>

              <div className="col">
                <FormInputDropdown
                  label="Country"
                  name="CountryId"
                  ddOpt={data.CountryData}
                  isRequired="true"
                ></FormInputDropdown>
              </div>
            </div>
          </div>
          <div className="card bg-light">
            <div className="alert alert-primary" role="alert">
              Company Social Media Link
            </div>
            <div className="row px-2">
              <div className="col">
                <FormInputText
                  label="Facebook Url"
                  name="facebookUrl"
                  type="text"
                />
              </div>
            </div>
            <div className="row px-2 pt-1 pb-1">
              <div className="col">
                <FormInputText
                  label="LinkdIn Url"
                  name="linkedInUrl"
                  type="text"
                />
              </div>
            </div>
            <div className="row px-2">
              <div className="col">
                <FormInputText
                  label="Twitter Url"
                  name="twitterUrl"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="card bg-light">
            <div className="alert alert-primary" role="alert">
              Company Settings
            </div>
            <div className="row px-2">
              <div className="col">
                <FormInputDropdown
                  label="Currency for Payroll"
                  name="CurrencyId"
                  ddOpt={data.CurrencyData}
                  isRequired="true"
                ></FormInputDropdown>
              </div>
            </div>
          </div>
          <div className="card bg-light">
            <div className="alert alert-primary" role="alert">
              Email Setting
            </div>
            <div className="row px-2 pb-1">
              <div className="col">
                <FormInputText
                  name="MailServer"
                  label="Company Outgoing EMail Server"
                  type="text"
                />
              </div>
            </div>

            <div className="row px-2 pb-1">
              <div className="col">
                <Tooltip title="Select Email Protocols">
                  <FormInputDropdown
                    name="MailProtocols"
                    label={` Mail Protocols`}
                    ddOpt={StandardConst.EmailProtocols}
                  />
                </Tooltip>
              </div>

              <div className="col">
                <FormInputText name="MailPort" label="Mail Port" type="text" />
              </div>
            </div>

            <div className="row px-2 pb-1 pt-1">
              <div className="col-12 col-md-6">
                <Tooltip title="To send mails from system">
                  <FormInputText
                    name="MailUserName"
                    type="text"
                    label=" Email Account Username"
                  />
                </Tooltip>
              </div>
              <div className="col-12 col-md-6">
                <Tooltip title="For Authentication">
                  <FormInputText
                    name="MailPassword"
                    label="Password "
                    type="password"
                  />
                </Tooltip>
              </div>
              <div className="col-12 col-md-6">
                <Tooltip title="Domain Name">
                  <FormInputText
                    name="DomainName"
                    type="text"
                    label="Domain Name"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnErrorlogSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data.CompanyProfile.ErrorId || 0) === 0
              ? "Submit"
              : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

export default AddEditCompany;

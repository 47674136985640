import React from "react";
import Container from "react-bootstrap/Container";
import TableComponent from "./TableComponent";
import { Box, Button, Tooltip } from "@mui/material";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import { useNavigate } from "react-router-dom";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const StaticListComponent = ({
  bData,
  appData,
  columns,
  records,
  MasterPageName,
  isSearchRequired,
  AddButtonText,
  BackBtnReturnPath, // give sorce page path in this parameter when you use this component the back button will back you to the source page
  ShowListGridButtons,
  ...rest
}) => {
  AddButtonText ??= MasterPageName;
  BackBtnReturnPath ??= "";
  ShowListGridButtons ??= false;
  const navigate = useNavigate();

  return (
    <>
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <h5 className="ms-4 mt-2">{MasterPageName}</h5>
          <div className="ms-4">
            <div className="row">
              <div className="col-8 col-md-10">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
              {(ShowListGridButtons) && (
                <div className="col-4 col-md-2 p-0 m-0 d-flex justify-content-center text-align-center">
                  <Tooltip title="List View" enterDelay={500} leaveDelay={200}>
                    <Button onClick={() => navigate("/ManageBadgeTemplates")}><FormatListBulletedIcon /></Button>
                  </Tooltip>
                  <Tooltip title="Card View" enterDelay={500} leaveDelay={200}>
                    <Button onClick={() => navigate("/BadgeTemplatesCardDisplay")}><CalendarViewMonthIcon /></Button>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </Box>
        <TableComponent
          columns={columns}
          data={records}
          title={AddButtonText}
          isSearchRequired={isSearchRequired}
          BackBtnReturnPath={BackBtnReturnPath}
          {...rest}
        />
      </Container>
    </>
  );
};

export default StaticListComponent;

import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent'
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { WebService } from '../../Services/WebService';
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import Button from "react-bootstrap/esm/Button";
import AddEditAccessLabel from './AddEditAccessLabel';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { PageInfo } from '../PageInfo';

const ManageAccessLabel = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Access Level" });
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    useEffect(() => {
        SetPermission({
            ManageAdd: true, // ActionPermission("Visitor Type - Add"),
            ManageEdit: true, // ActionPermission("Visitor Type - Edit"),
            ManageDelete: true, // ActionPermission("Visitor Type - Delete"),
        });
    }, [permissionList]);
    
    const [accesslevelTypeList, setAccesslevelTypeList] = useState([]);

    const fetchAllAccesslevelTypeDetails = async (AccessLevelTypeId) => {
        if(AccessLevelTypeId !== null){
            const data = await WebService({
                endPoint: `CommonUtility/Edit/accessleveltype`,
                dispatch,
            });
            setAccesslevelTypeList(data);
        }
    }

    const fetchAccessLabels = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accesslevel?CompanyId=${CompanyInfo.CompanyId ?? 0}`,
            dispatch,
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchAllAccesslevelTypeDetails();
        fetchAccessLabels();
    }, []);


    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Access Level";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "Access Level";
    const dispatch = useDispatch();
    const onDelete = async (AccessLevelId) => {
        await WebService({
            endPoint: `CommonUtility/accesslevel?AccessLevelId=${AccessLevelId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAccessLabels();
    };

    const [bData,] = React.useState([
        {
            title: "Access Management",
            hrefLink: "#",
        },
        {
            title: "Access Level",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Access Level Name",
            Value: "AccessLevelName"
        },
        {
            Text: "Description",
            Value: "Description"
        },
        {
            Text: "Code",
            Value: "Code"
        },
        {
            Text: "Access Level Type",
            render: (dr) => {
                return <span>{accesslevelTypeList?.find((item) => item.AccessLevelTypeId === dr.AccessLevelTypeId)?.TypeName}</span>;
            }
        },
        {
            Text: "Status",
            Value: "Status",
            render: (dr) => (
                <>
                    {dr.Status === StandardConst.StatusDropdown[0].value ? StandardConst.StatusDropdown[0].text : StandardConst.StatusDropdown[1].text}
                </>
            )
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.AccessLevelId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Access Level"}
                        id={`btnAccessLevelEdit_${dr.AccessLevelId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr?.AccessLevelName, // Message of Alert
                                dr.AccessLevelId  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Access Level"}
                        id={`btnAccessLevelDelete_${dr.AccessLevelId}`}
                    />
                </>
            ),
        },
    ];

    // if (Roles[0] === StandardConst.SystemRole.SuperAdmin) {
    //     columns.splice(1, 0, {
    //         Text: "Access",
    //         Value: "CompanyName"
    //     });
    // }

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);


    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.AccessLevelheaderValueNoResults}
                        subHeaderValue={StandardConst.AccessLevelSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button id="NoRecordFoundAddAccessLevel" variant="outline-primary" onClick={() => fnEdit(0)}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditAccessLabel
                callBackEvent={async () => await fetchAccessLabels()}
                ref={addEditModalRef}
            ></AddEditAccessLabel>
        </>
    )
}

export default ManageAccessLabel
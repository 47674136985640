import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { WebService } from "../../Services/WebService";
import { format } from "date-fns";
import "../Static/Static.css";
import AddEditOfficelocation from "./AddEditOfficelocation";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { chain } from "underscore";
import Modal from "react-bootstrap/Modal";
import QRCodeGeneratorDownloadComponent from "../../Services/QRCodeGeneratorDownloadComponent";
import { Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";


const AllErrorList = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Manage Office Locations" });
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Office Location - Add"),
      ManageEdit: ActionPermission("Office Location - Edit"),
      ManageDelete: ActionPermission("Office Location - Delete"),
    });
  }, [permissionList]);

  const fetchOfficelocationList = async () =>
    Promise.all([
      WebService({
        endPoint: "Officelocation/Fetch",
        dispatch,
      })
        .then((r) =>
          chain(r ?? [])
            .map((m) => ({
              ...m,
              VisitorUrl: `${
                window.location.origin
              }/#/Visitor/Self/CheckIn?company=${m.CompanyCode}&l=${window.btoa(
                m.OfficeLocationId
              )}`,
            }))
            .value()
        )
        .then(setRecords),
      dispatch(ConfigurationflowStatusUpdate()),
    ]);
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  const GenerateQRRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchOfficelocationList();
    }
    renderAfterCalled.current = true;
  }, []);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  const onDelete = async (OfficeLocationId) => {
    await WebService({
      endPoint: `CommonUtility/officelocation?OfficeLocationId=${OfficeLocationId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchOfficelocationList();
  };

  const OpenManageLocationContacts = (OfficeLocationId) => {
    navigate(`/ManageContactsForOfficeLocation`, { state: { OfficeLocationId } });
  };

  const columns = [
    {
      Text: "Company Name",
      Value: "CompanyName",
    },
    // {
    //   Text: "VisitorUrl",
    //   render: (dr) => (
    //     <a href={dr.VisitorUrl} target="_blank">
    //       VisitorLink
    //     </a>
    //   ),
    // },
    {
      Text: "Location",
      Value: "Location",
    },
    {
      Text: "Address",
      Value: "Address",
    },
    {
      Text: "Action",
      key: "OfficeLocationId",
      cssClass: "text-center td-width-100",
      isVisiable: permission.ManageEdit,
      render: (dr) => (
        <>
          <div className="d-flex">
            <ActionButton
              onClick={() =>
                OpenManageLocationContacts(dr.OfficeLocationId)
              }
              IconName="Contact"
              id="ManageOfficeLocationContacts"
            />
            <ActionButton
              onClick={async () =>
                await GenerateQRRef.current.openModal(dr.VisitorUrl, dr.Address)
              }
              IconName="QR"
              id="btnOfficelocationDelete"
            />
            <ActionButton
              onClick={(e) =>
                fnEdit(dr.OfficeLocationId)
              }
              disabled={!permission.ManageEdit}
              IconName="Edit"
              id="btnOfficelocationEdit"
            />
            <ActionButton
              onClick={(e) =>
                ref.current.confirmAlert(
                  "Delete", //Confirm button text
                  "Are You Sure", // Text if Alert
                  "Do you want to delete " + MasterPageName, // Message of Alert
                  dr.OfficeLocationId // Endpoint to hit for delete
                )
              }
              disabled={!permission.ManageDelete}
              IconName="Delete"
              id="btnOfficelocationDelete"
            />
          </div>
        </>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Manage Office Locations",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Manage Office Locations";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Office Location";
  return (
    <>
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""} // give sorce page path in this parameter when you use this component the back button will back you to the source page
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.ManageOfficeLocationheaderValueNoResults}
            subHeaderValue={StandardConst.ManageOfficeLocationubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${AddButtonText}`}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditOfficelocation
        callBackEvent={() => fetchOfficelocationList()}
        ref={addEditModalRef}
      ></AddEditOfficelocation>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <GenerateQR ref={GenerateQRRef} />
    </>
  );
};

const GenerateQR = forwardRef(({}, ref) => {
  const [show, setShow] = useState(false);
  const [qrData, setQrData] = useState(false);
  const [officeLocationAddress, setOfficeLocationAddress] = useState("");

  const handleClose = () => setShow(false);
  useImperativeHandle(ref, () => ({
    openModal: async (QrData, officeLocationAddress) => {
      setOfficeLocationAddress(officeLocationAddress);
      setQrData(QrData);
      setShow(true);
    },
  }));

  return (
    <Modal
      size="xs"
      centered
      show={show}
      onHide={handleClose}
      className="container-fluid"
    >
      <Modal.Header closeButton>
        <Modal.Title>QR Code for office location</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
        <Alert severity="success">
          Download and paste this QR Code at the entrace of your office. Visitor
          can scan this QR Code and to self check in
        </Alert>
        <div className="p-3 m-0">
          <QRCodeGeneratorDownloadComponent data={qrData}  showWodLogo={true} showLogo={true} Text={officeLocationAddress}/>
        </div>
      </Modal.Body>
    </Modal>
  );
});
export default AllErrorList;

import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Form, FormCheckRadioInput, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Box, Chip } from '@mui/material';
import { WebService } from '../../Services/WebService';
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import AppbarComponent from '../../Services/AppbarComponent';
import { StandardConst } from '../../Services/StandardConst';
import SnackbarComponent from '../../Services/SnackbarComponent';
import { contentSearch } from '../../Services/UtilityService';
import { extend, filter, map, mapObject } from 'underscore';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { PageInfo } from '../PageInfo';


const AssignPermissions = () => {

    const { roleId } = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState({ ManagePermissions: {} });
    const [records, setRecords] = useState([]);
    const [dataSet, setDataSet] = useState([]);
    PageInfo({ pageTitle: "Manage Permissions" });
    const [RolesList, setRolesList] = useState([]);
    const [searchContent, setSearchContent] = useState("");
    const refSnackbar = useRef();
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const [hideCompleteFormWhenNoData, setHideCompleteFormWhenNoData] = useState(false);
    const getPermissions = async () => {
        let endPoint = "";
        if (Roles[0] === StandardConst.SystemRole.SuperAdmin) {
            endPoint = `CommonUtility/fetchAll/staticpermissions`;
        } else {
            endPoint = `Permission/notReservedPermissions?IsReserved=${0}`
        }
        const data = await WebService({
            endPoint: endPoint,
            dispatch,
        });
        setRecords(data);
        setDataSet(data);
    }

    useEffect(() => {
        getPermissions();
        getRoles();
    }, [Roles])


    const columns = [
        {
            Text: "Permission",
            Value: "Permission"
        }
    ]
    const searchPermissios = () => {

        // this is very simple code for searching permissions 
        // if(searchContent){
        //     const ds = records.filter(obj => {
        //         const result = contentSearch(obj.Permission, searchContent);
        //         return result;
        //     });
        //     setDataSet(ds);
        // }else {
        //     getPermissions();
        // }

        var ds = map(records ?? [], (m1, index) => {
            return mapObject(extend(m1, { vartualId: index + 1 }), (val, key) => {
                return val;
            });
        });

        if (searchContent) {
            var searchableColumns = filter(columns ?? [], (f) => (f?.Searchable ?? true) == true) ?? [];
            searchableColumns = map(searchableColumns, (obj) => obj.Value ?? obj.Text) ?? [];
            ds = filter(ds ?? [], (obj) => {
                var a1 = filter(
                    searchableColumns,
                    (col) => contentSearch(obj[col], searchContent)
                );
                return a1.length > 0;
            });
            if (ds.length > 0) {
                setDataSet(ds);
                setHideCompleteFormWhenNoData(false);
            } else {
                setHideCompleteFormWhenNoData(true);
            }
        } else {
            getPermissions();
            setHideCompleteFormWhenNoData(false);
        }
    };

    useEffect(() => {
        searchPermissios();
    }, [searchContent]);

    const singleRoleDetails = async () => {
        const v = await WebService({
            endPoint: `CommonUtility/Edit/staticroles?RoleId=${roleId || 0}`,
            dispatch,
            requiredLoader: false,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        const RoleId = [{
            value: v.RoleId || "" || null || undefined,
            text: v.RoleName,
        }]
        setData({ ManagePermissions : { RoleId : RoleId[0].value}});
    };

    useEffect(() => {
        singleRoleDetails();
    }, []);

    useEffect(() => {
        getEditPermissionValues();
    }, []);

    const getRoles = async () => {
        const data = await WebService({
            endPoint: `RolePermission/getRolesAndPermissionsMasterList`,
            dispatch,
        });

        // developer ko saare role dikhane hai
        let filterRoles;
        await Roles?.map((element) => {
            if (element === StandardConst.SystemRole.SuperAdmin) {
                filterRoles = data.roles;
            } else {
                filterRoles = data.roles.filter((item) => item.IsReserved === 0);
            }
            return null;
        });

        data.roles = await filterRoles.map((v) => {
            return {
                value: v.RoleId || "" || null || undefined,
                text: v.RoleName,
            };
        });

        setRolesList(data.roles);
    }

    const [currentRoleId, setCurrentRoleId ] = useState(null);
    const [checkPermissionArr, setCheckPermissionArr] = useState([]);

    const getEditPermissionValues = async (currentRoleId) => {
        currentRoleId = currentRoleId ? currentRoleId : roleId
        setCurrentRoleId(currentRoleId)
        const data = await WebService({
            endPoint: `Permission/FetchAssignedPermissionsByRole/rolepermissions?RoleId=${currentRoleId}`,
            dispatch,
        });
        const rolePermissions = data.map((obj) => { return obj.PermissionId });
        setCheckPermissionArr(rolePermissions);
    }

    const requiredMessage = "This is a required field";
    const schema = yup
        .object()
        .shape({
            ManagePermissions: yup.string().trim().required(requiredMessage),
        })
        .required();

    const handleCurrentRoleValue = async (currentRoleValue) => {
        await getEditPermissionValues(currentRoleValue);
    }

    const [bData, setBData] = React.useState([
        {
            title: "Security Management",
            hrefLink: "#",
        },
        {
            title: "Manage Permissions",
            hrefLink: "#",
        },
    ]);
    const MasterPageName = "Manage Permissions";
    const [confirmMessage, setConfirmMessage] = useState("");

    const handleAssignPermissionThroughCheckbox = async(currentValue, PermissionId) => {
        if(currentValue){
            const data = {
                RoleId: currentRoleId,
                PermissionId: PermissionId,
            }
            await WebService({
                endPoint: "CommonUtility/rolepermissions",
                body: data,
                dispatch,
            });
            setConfirmMessage("Permission successfully assigned to the role.");
            refSnackbar.current.setOpenSnackBar();
        }else {
            await WebService({
                endPoint: `CommonUtility/rolepermissions?PermissionId=${PermissionId}&RoleId=${currentRoleId}`,
                method: "DELETE",
                dispatch,
            });
            setConfirmMessage("Permission successfully removed to the role.");
            refSnackbar.current.setOpenSnackBar();
        }
    }
    
    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <h5 className="ms-4 mt-2">{MasterPageName}</h5>
                    <div className="ms-4">
                        <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                    </div>
                </Box>
                <AppbarComponent
                    title={MasterPageName}
                    isSearchRequired={true}
                    isAddButtonRequired={true}
                    Exception={true}
                    setSearchContent={(v) => setSearchContent(v ?? "")}
                    searchContent={searchContent}
                />
                {
                    hideCompleteFormWhenNoData
                        ?
                        <NoRecordTemplate
                            headerValue={StandardConst.headerValueNoResults}
                            subHeaderValue={StandardConst.subHeaderValueStandard}
                            imageUrl={StandardConst.imageNoRecordsFound}
                        />
                        :
                        Object.keys(data.ManagePermissions) && (
                        <Form
                            defaultValues={data.ManagePermissions}
                        >
                            <div className="row">
                                <div className="col-md-3 col-sm-12" style={{marginLeft: "23px"}}>
                                <FormInputDropdown
                                    name="RoleId"
                                    ddOpt={RolesList}
                                    isRequired="true"
                                    label="Role"
                                    value={data.ManagePermissions.RoleId}
                                    setValue={(currentValue) => { handleCurrentRoleValue(Number(currentValue))}}
                                ></FormInputDropdown>
                                </div>
                            </div>
                            <div className="table-responsive p-0" style={{ overflow: "none" }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{ paddingLeft: "10px" }}
                                                style={{ width: "5px" }}
                                            >Select</TableCell>
                                            <TableCell>Permissions</TableCell>
                                            <TableCell>Permission Descriptions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataSet.map((singlePermission, i) => (
                                            <React.Fragment key={i}>
                                                <TableRow>
                                                    <TableCell
                                                    sx={{lineHeight: 0,  paddingBottom: 0}}
                                                    >
                                                        <div style={{ margin: '5px' }}>
                                                            <FormCheckRadioInput
                                                                name={String(singlePermission.PermissionId)}
                                                                isRadio={false}
                                                                value={checkPermissionArr.find((permissonId) => permissonId === singlePermission.PermissionId ? true : false)}
                                                                setValue={ (currentValue) => { 
                                                                    handleAssignPermissionThroughCheckbox(Number(currentValue), singlePermission.PermissionId)}}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell 
                                                        sx={{
                                                            // lineHeight: 0,  paddingBottom: 0,
                                                             paddingTop: 0}}
                                                    >
                                                        {singlePermission.Permission}  &nbsp;&nbsp;
                                                        {(singlePermission.IsReserved === StandardConst.YesNo.Yes && Roles[0] === StandardConst.SystemRole.SuperAdmin) && (
                                                            <Chip 
                                                                label="Reserved"
                                                                sx={{ color: "white", padding: "0px"}}
                                                                color= "primary"
                                                                size="small"
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell 
                                                        // sx={{lineHeight: 0,  paddingBottom: 0}}
                                                    >
                                                        {singlePermission.PermissionDescription}
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Form>
                        )
                }
            </Container>
        </>
    )
}

export default AssignPermissions
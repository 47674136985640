import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";
import { ActionPermission, PageInfo } from '../../PageInfo';
import StaticListComponent from '../../../Services/StaticListComponent';
import { StandardConst } from '../../../Services/StandardConst';
import { WebService } from '../../../Services/WebService';
import ActionButton from '../../../Services/ActionButton';
import { NoRecordTemplate } from '../../../Services/TableComponent';
import AddEditUserType from './AddEditUserType';
import SnackbarComponent from '../../../Services/SnackbarComponent';
import DeleteConfirmAlert from '../../../Services/AlertComponent';

const ManageUserType = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "User Type" });
    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("User Type - Add"),
            ManageEdit: ActionPermission("User Type - Edit"),
            ManageDelete: ActionPermission("User Type - Delete"),
        });
    }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "User Type";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "User Type";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);

    const fetchUserType = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/usertype`,
            dispatch,
        });
        setRecords(data);
    }

    useEffect(() => {
        fetchUserType();
    }, []);

    const [bData,] = React.useState([
        {
            title: "Master",
            hrefLink: "#",
        },
        {
            title: "User Type",
            hrefLink: "#",
        },
    ]);

    const onDelete = async (Details) => {
        const data = Details.split(',');
        const UserTypeId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/usertype?UserTypeId=${UserTypeId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchUserType();
    };

    const columns = [
        {
            Text: "Name",
            Value: "Name"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.UserTypeId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit User Type"}
                        id={`btnUserTypeEdit_${dr.UserTypeId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.Name, // Message of Alert
                                `${dr.UserTypeId},${dr.Name}`  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete User Type"}
                        id={`btnUserTypeDelete_${dr.UserTypeId}`}
                    />
                </>
            ),
        },
    ];

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`User ${(deletedName !== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.UserTypeheaderValueNoResults}
                        subHeaderValue={StandardConst.UserTypeSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditUserType
                callBackEvent={async () => await fetchUserType()}
                ref={addEditModalRef}
            ></AddEditUserType>
        </>
    )
}

export default ManageUserType
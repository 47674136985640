import React from "react";
import Cookies from "universal-cookie";
import { NumericFormat } from "react-number-format";

const DisplayNumberFormatComponent = ({ Number, Currency, ...rest }) => {
  Number ??= 0;
  const cookies = new Cookies();
  Currency ??= (cookies.get("company") ?? {}).Symbol;
  const IntNumber = parseFloat(Number);
  return (
    <>
      <NumericFormat
        displayType="text"
        value={IntNumber}
        decimalScale={2}
        fixedDecimalScale
        prefix={Currency}
        thousandsGroupStyle="lakh"
        thousandSeparator=","
      />
    </>
  );
};

export const DisNumFormatWoCurrency = ({ Number }) => {
  Number ??= 0;
  const cookies = new Cookies();
  const Currency = (cookies.get("company") ?? {}).Symbol;
  return (
    <>
      <NumericFormat
        displayType="text"
        value={Number}
        decimalScale={2}
        fixedDecimalScale
      />
    </>
  );
};

export const DisNumFormatWoCurrencyDecimal = ({ Number }) => {
  Number ??= 0;
  const cookies = new Cookies();
  const Currency = (cookies.get("company") ?? {}).Symbol;
  return (
    <>
      <NumericFormat
        displayType="text"
        value={Number}
        decimalScale={0}
        fixedDecimalScale
        thousandsGroupStyle="lakh"
        thousandSeparator=","
      />
    </>
  );
};

export default DisplayNumberFormatComponent;

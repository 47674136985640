import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import { WSSuccessAlert } from '../../Services/WSAlert';


const AddEditAccessLevelType = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AccessLevelType: {} });
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                const data = {
                    AccessLevelType: {},
                };
                setData(data);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AccessLevelType: await WebService({
                endPoint: `CommonUtility/Edit/accessleveltype?AccessLevelTypeId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const handleClose = () => {
        setShow(false)
        setData({AccessLevelType : {}});
    };

    const requiredMessage = "This is a required field";
    const schema = yup
        .object()
        .shape({
            TypeName: yup.string().trim().required(requiredMessage),
        })
        .required();

    const onSubmit = async (data) => {
        if (data.AccessLevelTypeId === undefined) {
            await WebService({
                endPoint: "CommonUtility/accessleveltype",
                body: data,
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", `Access level type ${data.TypeName} added successfully`);
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/accessleveltype?AccessLevelTypeId=${data.AccessLevelTypeId}`,
                method: "PUT",
                body: data,
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", `Access level type ${data.TypeName} updated successfully`);
            });
        }
        handleClose();
        props.callBackEvent();
    }

    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.AccessLevelType && data.AccessLevelType.AccessLevelTypeId || 0) === 0
                        ? "Add Access Level"
                        : "Edit Access Level"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.AccessLevelType}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Type Name"
                                name="TypeName"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText 
                                label="Icon" 
                                name="Icon" 
                                type="text" 
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="Status"
                                ddOpt={StandardConst.StatusDropdown}
                                label="Status"
                                isRequired="true"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.AccessLevelType && data.AccessLevelType.AccessLevelTypeId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditAccessLevelType);
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { Form, FormAutoCompleteDropdownCard, InputDatePicker, InputDropdown } from "../../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { WebService } from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import _, { chain, extend, map, omit, sortBy } from "underscore";
import TableComponent from "../../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../../PageInfo";
import TopbarComponent from "../../../Services/TopbarComponent";
import { NoRecordTemplate } from "../../../Services/TableComponent";
import { StandardConst } from "../../../Services/StandardConst";
import WSCircularProgress from "../../../Services/WSCircularProgress";
import SquareIcon from "@mui/icons-material/Square";
import { Autocomplete, ButtonGroup, Chip, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WSLinearProgressColor from "../../../Services/WSLinearProgressColor";
import DisplayNumberFormatComponent from "../../../Services/DisplayNumberFormatComponent";
import ActionButton from "../../../Services/ActionButton";
import { WSErrorAlert, WSSuccessAlert } from "../../../Services/WSAlert";
import Button from "react-bootstrap/esm/Button";

const AutomaticPayslipGeneration = () => {
    PageInfo({ pageTitle: "Automatic Payslip Generation " });

    const [bData, setBData] = React.useState([
        {
          title: "Leave Management",
          hrefLink: "#",
        },
        {
          title: "Automatic Payslip Generation",
          hrefLink: "#",
        },
      ]);
    const MasterPageName = "Automatic Payslip Generation ";

    
    // const [permission, SetPermission] = useState({
    //     AllUser: ActionPermission("Leave - Search All User"),
    // });

    // const permissionList = useSelector((s) => s.auth.PermissionList??[]);
    // useEffect(() => {
    //     SetPermission({
    //     AllUser: ActionPermission("Leave - Search All User"),
    //     });
    //     fetchEmployee();
    // }, [permissionList]);

    useEffect(() => {
        fetchEmployee();
    }, []);

    const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
    const childUser = useSelector((s) => s.auth.ChildUsers ?? []);
  
    var dispatch = useDispatch();
    const roundOf = (num, position) => {
        return (
        Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
        Math.pow(10, 2)
        );
    };
    
    const [filter, setFilter] = useState({
        FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
        ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
        WeeklyAndMonthlyPayslipType: StandardConst.WeeklyAndMonthlyPayslipDropdown[0].value,
        employee: []
    });
    const [employees, setEmployees] = useState([]);
    // const [basicDataIsLoaded, setBasicDataIsLoaded] = useState(false);
  
    const fetchEmployee = async (SalaryPaymentFrequency) => {
        SalaryPaymentFrequency ??= filter.WeeklyAndMonthlyPayslipType;
        if(SalaryPaymentFrequency === StandardConst.timeFrame.Week){
            SalaryPaymentFrequency = 'Weekly';
        }else{
            SalaryPaymentFrequency = 'Monthly';
        }
        
        // endPoint: `Visitor/fetchAllEmployees`
        let employeesList = await WebService({
            dispatch,
            endPoint: `Payslip/fetchEmployeesListAccordingPayable?SalaryPaymentFrequency=${SalaryPaymentFrequency}`
        });

        if (employeesList.length > 0) {
            setEmployees(
                [{ EmployeeId: 0, heading: "All Employees" }].concat(
                    map(sortBy(employeesList, "FullName"), (m) => ({
                    EmployeeId: m.EmployeeId,
                    heading: m.FullName, 
                    description: m.Designation, 
                    avatar: `${StandardConst.apiBaseUrl}/uploads/${m.ProfileImage}`
                }))
                )
            );
        }else{
            setEmployees([]);
        }
    };

//   const fetchReport = async (search = filter) => {
//     const leaveType =
//       search.leaveType != 0
//         ? `'${search.leaveType}'`
//         : leaveTypes
//             .filter((f) => f.value != 0)
//             .reduce((m, v) => `${m},${v.value}`, "")
//             .substring(1);
    
//     const employee = search.employee.length > 0
//       ? search.employee[0].EmployeeId
//       : employees[0].EmployeeId;

//     if (leaveTypes.length > 0 && employees.length>0) {
//       const endPoint = `Report/Leave/Summary?Types=${leaveType}&Employees=${employee}&FromDate=${search.FromDate}&ToDate=${search.ToDate}`;
//       let results = await WebService({ dispatch, endPoint });
      
//       results = map(results, (m1) =>
//         extend(omit(m1,"Leave"), {
//           Leave: m1.Leave.filter(f=>(f.Consumed===0 && f.Allocated===0)===false).map(l=>{
//             l.Consumed=parseFloat(l.Consumed);
//             l.Allocated=parseFloat(l.Allocated);
//             if(l.Consumed>l.Allocated){
//               l.Allocated=l.Consumed;
//             }
//             return l;
//           }),
//         })
//       ).filter((f) => f.Leave.length > 0);
//       setReports(results);
//       CalculateLeaveBalance(results);
//     }

//     setBasicDataIsLoaded(true);
//   };
//   const CalculateLeaveBalance = (data) => {
//         const s1 = data.map((m) => m.Leave).reduce((m, r) => m.concat(r), []);
//         setLeaveTotalBalance({
//       Consumed: s1.reduce((m, r) => m + r.Consumed, 0),
//       Allocated: s1.reduce((m, r) => m + r.Allocated, 0),
//     });
//       };
//   const [LeaveType, setLeaveType] = useState([
//     { LeaveType: "Casual" },
//     { LeaveType: "Unpaid" },
//     { LeaveType: "Sick" },
//     { LeaveType: "Leave x" },
//     { LeaveType: "Leave Y" },
//   ]);
//   const [BarColors, setBarColors] = useState([
//     {
//       Item: 0,
//       PrimaryColor: "#0AA2C0",
//       SecondaryColor: "#9EEAF9",
//     },
//     {
//       Item: 1,
//       PrimaryColor: "#D63384",
//       SecondaryColor: "#EFADCE",
//     },
//     {
//       Item: 2,
//       PrimaryColor: "#3D8BFD",
//       SecondaryColor: "#9EC5FE",
//     },
//     {
//       Item: 3,
//       PrimaryColor: "#FD7E14",
//       SecondaryColor: "#FECBA1",
//     },
//     {
//       Item: 4,
//       PrimaryColor: "#4DD4AC",
//       SecondaryColor: "#A6E9D5",
//     },
//   ]);
  useEffect(() => {
    // if (
    //   (filter.FromDate ?? "").toString().length > 0 &&
    //   (filter.ToDate ?? "").toString().length > 0 &&
    //   leaveTypes.length > 0 &&
    //   employees.length > 0
    // ) {
    //   fetchReport();
    // }
  }, [filter]);

  //#region Time Frame
    const [timeFrame, setTimeFrame] = useState(StandardConst.timeFrame.Week);
    const setDateByTimeFrame = (fromDate) => {
        switch (timeFrame) {
            //dropdown option for week semimonth and costum are removed because consume and allocated leave calculation is deficult for a day week or semimonth -- (2023-09-29)
            //#region Week
            case StandardConst.timeFrame.Week: {
                setFilter((filter) => ({
                ...filter,
                FromDate: fromDate.toSQLDate(),
                ToDate: fromDate.plus({ day: 6 }).toSQLDate(),
                }));
                break;
            }
            //#endregion
            //#region Month
            case StandardConst.timeFrame.Month: {
                setFilter((filter) => ({
                ...filter,
                FromDate: fromDate.toSQLDate(),
                ToDate: fromDate.endOf("month").toSQLDate(),
                }));
                break;
            }
            //#endregion
        }
    };
    useEffect(() => {
        switch (timeFrame) {
            //dropdown option for week semimonth and costum are removed because consume and allocated leave calculation is deficult for a day week or semimonth -- (2023-09-29)
            //#region Week
            case StandardConst.timeFrame.Week: {
                const fromDate = DateTime.now().minus({
                day: DateTime.now().weekday - 1,
                });
                setDateByTimeFrame(fromDate);
                break;
            }
            //#region Month
            case StandardConst.timeFrame.Month: {
                setDateByTimeFrame(DateTime.now().startOf("month"));
                break;
            }
        }
    }, [timeFrame]);

    const fnPrevious = () => {
        switch (timeFrame) {
            //dropdown option for week semimonth and costum are removed because consume and allocated leave calculation is deficult for a day week or semimonth -- (2023-09-29)
            //#region Week
            case StandardConst.timeFrame.Week: {
            const fromDate = DateTime.fromSQL(filter.FromDate).minus({ day: 7 });
            setDateByTimeFrame(fromDate);
            break;
            }
            //#region Month
            case StandardConst.timeFrame.Month: {
                setDateByTimeFrame(
                DateTime.fromSQL(filter.FromDate).minus({ month: 1 })
                );
                break;
            }
            //#endregion
        }
    };
    const fnNext = () => {
        switch (timeFrame) {
            //dropdown option for week semimonth and costum are removed because consume and allocated leave calculation is deficult for a day week or semimonth -- (2023-09-29)
            //#region Week
            case StandardConst.timeFrame.Week: {
            const fromDate = DateTime.fromSQL(filter.FromDate).plus({ day: 7 });
            setDateByTimeFrame(fromDate);
            break;
            }
            //#endregion
            //#region Month
            case StandardConst.timeFrame.Month: {
                setDateByTimeFrame(
                DateTime.fromSQL(filter.FromDate).plus({ month: 1 })
                );
                break;
            }
            //#endregion
        }
    };
    //#endregion

    const [autoErrorShow, setAutoErrorShow] = useState(false);
    const handleChangeemployee = (event, selectedOptions) => {
        (selectedOptions.length === 0) ? setAutoErrorShow(true) : setAutoErrorShow(false);
        setFilter((prev) => ({...prev, employee : selectedOptions}));
    };


    const [payslipData, setPayslipData] = useState([]);

    const CalculateSalary = async (employeeData) => {
      const returnobject = {GrossSalary: 0, TotalDeduction: 0, NetSalary : 0};
      for (let j = 0; j < employeeData.employeepayslipcomponents.length; j++) {
        const element = employeeData.employeepayslipcomponents[j];
        if(element.EarningOrDeductionType === "Earning"){
          returnobject.GrossSalary += (parseFloat(element.Amount)/employeeData.TotalWorkingDays)*employeeData.TotalWorkedDays
        }else{
          returnobject.TotalDeduction += (parseFloat(element.Amount)/employeeData.TotalWorkingDays)*employeeData.TotalWorkedDays
        }
      }

      returnobject.NetSalary = returnobject.GrossSalary - returnobject.TotalDeduction;
      return returnobject;
    }

    const getEmployeePaySlipData = async () => {
        // filter.employee = filter.employee.map(employee => employee.EmployeeId);

        const data = await WebService({
          endPoint : `Payslip/FetchAllSelectedEmployeePaySlipData`,
          method : 'POST',
          body : filter,
          dispatch
        })
        for (let i = 0; i < data.length; i++) {
          const claculatedData = await CalculateSalary(data[i]);
          data[i].GrossSalary = claculatedData.GrossSalary;
          data[i].TotalDeduction = claculatedData.TotalDeduction;
          data[i].NetSalary = claculatedData.NetSalary;
        }

        setPayslipData(data);
    }

    const generatePayslipForEmployee = async (payslipDataRow) => {

      // Create body for create api
      const body = extend({}, {
        EmployeeId : payslipDataRow.EmployeeId,
        GrossSalary: payslipDataRow.GrossSalary,
        TotalDeductions: payslipDataRow.TotalDeduction,
        NetSalary: payslipDataRow.NetSalary,
        CurrencyId : payslipDataRow.CurrencyId,
        TotalWorkingDays: payslipDataRow?.TotalWorkingDays,
        UnpaidAbsenceDays: payslipDataRow?.TotalNoOfLeaves,
        FromDate : filter.FromDate,
        ToDate : filter.ToDate,
        employeepayslipcomponents: chain(payslipDataRow?.employeepayslipcomponents ?? [])
          .map((m) => ({
            SalaryComponentId: m.SalaryComponentId,
            CalculatedAmount: m.Amount,
          }))
          .value(),
      });

      // Create Api
      await WebService({
        endPoint: "Payslip", 
        body ,
        dispatch
      }).then((rec) => {
        WSSuccessAlert("Success", "Payslip Successfully Generated");
      });

    };

    const GenerateAllPayslip = async () => {
      const AllPayslipData = payslipData.filter(employee => employee.PayslipAlreadyGenerated === false);
      if(AllPayslipData.length > 0) {
        for (let i = 0; i < AllPayslipData.length; i++) {
          await generatePayslipForEmployee(AllPayslipData[i]);
        }
      }else{
        WSErrorAlert("All payslip already generated");
      }


    }

    const skipPayslipGeneration = (payslipDataRow) => {
      setPayslipData(payslipData.filter(employee => employee.EmployeeId !== payslipDataRow.EmployeeId));
    }

    const columns = [
        { Text: "EncodedId", Value: "EncodedId", isVisiable: false },
        {
          Text: "Name",
          Value: "EmployeeName",
          IsSearch: true,
          cssClass: "text-center",
        },
        // {
        //   Text: "Month",
        //   Value:  "FromDate",
        //   DateFormat: "MMM-yy"
        // },
        {
          Text: "Working Days",
          Value: "TotalWorkingDays",
          IsSearch: true,
          cssClass: "text-center",
          //Searchable:false
        },
        {
          Text: "Total No. Of Leaves",
          Value: "TotalNoOfLeaves",
          IsSearch: true,
          cssClass: "text-center",
          //Searchable:false
        },
        {
          Text: "Total Worked Days",
          Value: "TotalWorkedDays",
          IsSearch: true,
          cssClass: "text-center",
          //Searchable:false
        },
        {
          Text: "Gross Salary",
          Value: "GrossSalary",
          IsSearch: true,
          style: {textAlign: "right"},
          render: (dr) => {
            return (
              <>
              <div style={{textAlign: "right"}}>
                <DisplayNumberFormatComponent Number={dr.GrossSalary} Currency={dr.Symbol}/>
              </div>
              </>
            )
          }
        },
        {
          Text: "Deduction",
          Value: "TotalDeduction",
          IsSearch: true,
          style: {textAlign: "right"},
          render: (dr) => {
            return (
              <>
                <div style={{ textAlign: "right" }}>
                  <DisplayNumberFormatComponent Number={dr.TotalDeduction} Currency={dr.Symbol} />
                </div>
              </>
            )
          }
        },
        {
          Text: "Net Salary",
          Value: "NetSalary",
          IsSearch: true,
          style: {textAlign: "right"},
          render: (dr) => {
            return (
              <>
                <div style={{ textAlign: "right" }}>
                  <DisplayNumberFormatComponent Number={dr.NetSalary} Currency={dr.Symbol} />
                </div>
              </>
            )
          }
        },
        {
          Text: "Action",
          cssClass: "text-center td-width-100",
          render: (dr) => (
            <>
              {(dr.PayslipAlreadyGenerated) 
                ? 
                <Chip 
                    label="Generated"
                    sx={{ color: "white", padding: "0px"}}
                    color= "primary"
                    size="small"
                /> 
                :
                <div className="d-flex" >
                    <ActionButton
                      onClick={() => generatePayslipForEmployee(dr)}
                      // disabled={!permission.ManageEdit}
                      IconName="Generate"
                      IconTooltip={"Generate Payslip"}
                      id={`btnGenerate_${dr.EmployeeId}`}
                    />
                    <ActionButton
                      onClick={() => skipPayslipGeneration(dr)}
                      // disabled={!permission.ManageEdit}
                      IconName="Skip"
                      IconTooltip={"Skip"}
                      id={`btnSkip_${dr.EmployeeId}`}
                    />
                  {/* <Button onClick={() => generatePayslipForEmployee(dr)}>Generate Payslip</Button>
                  <Button onClick={() => skipPayslipGeneration(dr)}>Skip</Button> */}
                </div>
              }
            </>
          ),
        },
    ];

    var filterableViewComponent = (
        <>
        <Row className="mx-2">
            <Col className="d-flex flex-row align-items-center p-0 text-white" md={5}>
                {/* <ButtonGroup
                    color="inherit"
                    size="small"
                    variant="outlined"
                    aria-label="small button group"
                > */}
                    {/* Back Button */}
                    <Button onClick={fnPrevious} color="inherit" size="small" variant="outlined">
                        <ArrowBackIcon />
                    </Button>

                    
                {/* </ButtonGroup> */}
                <span className="mx-2 h5 text-white">
                    <strong>
                    This&nbsp;
                    {`${timeFrame}: `}
                    </strong>
                    {`${DateTime.fromSQL(filter.FromDate).toFormat(
                    "dd MMM"
                    )} - ${DateTime.fromSQL(filter.ToDate).toFormat("dd MMM yyyy")}`}
                </span>
                {/* Forward Button */}
                <Button onClick={fnNext} color="inherit" size="small" variant="outlined">
                        <ArrowForwardIcon />
                    </Button>
            </Col>
            <Col md={2} className="d-flex flex-row align-items-center p-0">
                <InputDropdown
                    setValue={(val) => {setTimeFrame(val); fetchEmployee(val)}}
                    labelCss="text-center"
                    className="form-control"
                    ddOpt={StandardConst.WeeklyAndMonthlyPayslipDropdown}
                    value={filter.WeeklyAndMonthlyPayslipType}
                    // setValue={(v) => setFilter({ ...filter, WeeklyAndMonthlyPayslipType: v })}
                />
            </Col>
            <Col md={3} className="ps-1">

                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={employees}
                    getOptionLabel={(option) => option.heading}
                    // defaultValue={selectedVisitorTypes}
                    onChange={handleChangeemployee}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="employees"
                            error={autoErrorShow} // Set error state of TextField
                            helperText={(autoErrorShow) && "Atleast one employee you want to select."} // Display error message if error is true
                        />
                    )}
                />
                {/* <Form>
                    <FormAutoCompleteDropdownCard
                        data={employees}
                        name="EmployeeId"
                        optionText="heading"
                        setValue={(v) =>
                            setFilter({ ...filter, employee: v })
                        } 
                        value={filter.employee.length > 0 ? filter.employee[0] : employees[0]}
                        disableClearable={true}
                    />
                </Form> */}
            </Col>
            <Col md={2} className="d-flex flex-row align-items-center p-0 text-white">
                <Button color="inherit" onClick={() => getEmployeePaySlipData()}>Generate Payslip</Button>
            </Col>
        </Row>
        </>
    );

    // if(!basicDataIsLoaded){
    //     return <></>;
    // }

    return (
        <>
        <Container fluid className="base-container">
            <TopbarComponent bData={bData} HeadingText={MasterPageName} />
            <div elevation={0} className="primary-bg-color">{filterableViewComponent}</div>


            {(payslipData.length > 0) && (
                <>
                  <TableComponent
                      columns={columns}
                      //  initialSearchContent={searchParams.get("search") ?? ""}
                      data={chain(payslipData)
                        .sortBy((s) => s.PayslipId)
                        .reverse()
                        .value()}
                      //  bData={bData}
                      //  MasterPageName={MasterPageName}
                      //  noRecordCss="p-0"
                      //  noRecordFound={
                      //    <NoRecordTemplate
                      //      headerValue={StandardConst.EmployeePayslipsheaderValueNoResults}
                      //      subHeaderValue={StandardConst.EmployeePayslipsSubHeaderRole}
                      //      imageUrl={StandardConst.imageNoRecordsFound}
                      //      actionButton={
                      //        <>
                      //          {permission.ManageAdd && (
                      //            <Button id="NoRecordFoundAddEmployeePaySlip" variant="outline-primary" onClick={() => fnEdit(0)}>
                      //              Add {MasterPageName}
                      //            </Button>
                      //          )}
                      //        </>
                      //      }
                      //    />
                      //  }
                      //  onAddEvent={() => fnEdit()}
                      isSearchRequired={true}
                      allowSerialNo={true}
                      IsAddButtonVisible={false}
                  />
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    <Button variant="outline-success" onClick={() => GenerateAllPayslip()} >Generate all</Button>
                  </div>
                </>
            )}

            {/* {reportSummaryComponent} */}
            {/* <hr className="mx-2 p-0 m-1"></hr> */}
            {/* <div className="mx-2">{reportInGridComponent}</div> */}
        </Container>
        </>
    );
};

export default AutomaticPayslipGeneration;

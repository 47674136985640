import DeleteConfirmAlert from "../../Services/AlertComponent";
import React, { useEffect, memo, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditPerformanceComponent from "./AddEditPerformanceComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import "../Static/Static.css";
import _, { chain, map } from "underscore";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/Button";
import { useSearchParams } from "react-router-dom";

const PerformanceComponent = () => {
  const [searchParams] = useSearchParams();
  const deleteConfirmAlertRef = useRef();
  const refSnackbar = useRef();
  const dispatch = useDispatch();
  PageInfo({ pageTitle: "Performance Assessment" });


  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Performance - Add"),
      ManageEdit: ActionPermission("Performance - Edit"),
      ManageDelete: ActionPermission("Performance - Delete"),
      ManageAllUser: ActionPermission("Performance - All Employees"),
      ManageMyTeam: ActionPermission("Performance - My Team"),
    });
    fetchPerformanceList();
  }, [permissionList]);

  const [records, setRecords] = useState([]);
  const fetchPerformanceList = async () => {
    var endPoint = "Performance/Fetch";
    const manageAllUser = ActionPermission("Performance - All Employees");
    if (manageAllUser) endPoint += "/All";
    else endPoint += "/Own";
    const data = await WebService({ endPoint, dispatch }).then((r) =>
      map(r, (m) => ({ ...m, EncodedId: window.btoa(m.PerformanceId) }))
    );
    setRecords(data);
  };

  const onDelete = async (PerformanceId) => {
    await WebService({
      endPoint: `CommonUtility/employeeperformance?PerformanceId=${PerformanceId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchPerformanceList();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  // useEffect(() => {
  //   if (!renderAfterCalled.current) {
  //     fetchPerformanceList();
  //   }
  //   renderAfterCalled.current = true;
  // }, []);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  const columns = [
    { Text: "EncodedId", Value: "EncodedId", isVisiable: false },
    {
      Text: "EMP. NAME",
      Value: "FullName",
    },
    {
      Text: "FROM DATE",
      DateFormat: "yyyy-MM-dd",
      Value: "FromDate",
    },
    {
      Text: "TO DATE",
      DateFormat: "yyyy-MM-dd",
      Value: "ToDate",
    },
    {
      Text: "EMP. RATING",
      Value: "EmpSelfScore",
    },
    {
      Text: "MANAGER RATING",
      Value: "ManagerScore",
    },
    {
      Text: "FINAL RATING",
      Value: "FinalAgreedScore",
    },
    {
      Text: "REVIEW COMMENTS",
      render: (dr) => dr.FinalReviewComments ? (dr.FinalReviewComments.length <= 100 ? dr.FinalReviewComments : dr.FinalReviewComments.slice(0, 100) + '...') : null
    },
    {
      Text: "HR COMMENTS",
      render: (dr) => dr.HRComments ? (dr.HRComments.length <= 100 ? dr.HRComments : dr.HRComments.slice(0, 100) + '...') : null
    },
    {
      Text: "Action",
      key: "PerformanceId",
      cssClass: "text-center td-width-100",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      NotUseInExport: true,
      render: (dr) => (
        <div className="d-flex">
          {permission.ManageEdit && (
            <ActionButton
              onClick={() => fnEdit(dr.PerformanceId)}
              IconName="Edit"
              id="btnPerformanceEdit"
            />
          )}

          {permission.ManageDelete && (
            <ActionButton
              onClick={(e) =>
                deleteConfirmAlertRef.current.confirmAlert(
                  "Delete", //Confirm button text
                  "Are You Sure", // Text if Alert
                  "Do you want to delete " + MasterPageName, // Message of Alert
                  dr.PerformanceId // Endpoint to hit for delete
                )
              }
              IconName="Delete"
              id="btnerformanceEditDelete"
            />
          )}
        </div>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Performance Management",
      hrefLink: "#",
    },
    {
      title: "Performance Assessment",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Performance Assessment";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Performance";

  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <StaticListComponent
        initialSearchContent={searchParams.get("search") ?? ""}
        columns={columns}
        records={chain(records)
          .sortBy((s) => s.PerformanceId)
          .reverse()
          .value()}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={
              StandardConst.PerformanceAssessmentheaderValueNoResults
            }
            subHeaderValue={StandardConst.PerformanceAssessmentSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditPerformanceComponent
        callBackEvent={() => fetchPerformanceList()}
        ref={addEditModalRef}
      />
      <DeleteConfirmAlert
        ref={deleteConfirmAlertRef}
        confirmEvent={(v) => onDelete(v)}
      />
    </>
  );
};

export default memo(PerformanceComponent);

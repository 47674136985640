import React from 'react';

export const CurrencyFormat = (props) => {
    // You can receive the currency value as a prop
    const { value, LocaleCode, CurrencyCode } = props;
    // Format the currency using Intl.NumberFormat
    const formattedCurrency = new Intl.NumberFormat(LocaleCode, {
      style: 'currency',
      currency: CurrencyCode // Change the currency code as needed
    }).format(value);
    return formattedCurrency;
};
import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";

const ManageAttach = () => {
    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Manage Attach" });
    useEffect(() => {
        SetPermission({
            ManageDelete: true //ActionPermission("Asset Type - Delete"),
        });
    }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Manage Attach";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "Manage Attach";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);

    const onDelete = async (Details) => {
        const data = Details.split(',');
        const TicketAttachId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/ticketattach?TicketAttachId=${TicketAttachId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAllAttach();
    };

    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? 0)
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? 0);
    
    const fetchAllAttach = async() => {
        let endPoint = ``;
        if(EmployeeId){
            endPoint =  `tickets/ticketAttach?EmployeeId=${EmployeeId}`
        }
        else if(UserId){
            endPoint = `tickets/ticketAttach?UserId=${UserId}`
        }
        const data = await WebService({
            endPoint: endPoint,
            dispatch,
        });

        const modifiedData = data.map((res) => {
            if(res.EmployeeId){
                res.FullName = res.EmpName
            }
            if(res.UserId){
                res.FullName = res.UserName
            }
            return res;
        }); 
        setRecords(modifiedData);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchAllAttach();
    }, []);

    const [bData,] = React.useState([
        {
            title: "Helpdesk",
            hrefLink: "#",
        },
        {
            title: "Manage Attach",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Person",
            Value: "FullName"
        },
        {
            Text: "Ticket",
            Value: "Title"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.FullName + " from " + dr.Title, // Message of Alert
                                `${dr.TicketAttachId},${dr.FullName}`  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Attach"}
                        id={`btnAssetTypeDelete_${dr.TicketAttachId}`}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Attach ${(deletedName!== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.ManageAttachheaderValueNoResults}
                        subHeaderValue={StandardConst.ManageAttachSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                    />
                }
                onAddEvent={() => {}}
                IsAddButtonVisible={false}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
        </>
    )
}

export default ManageAttach
import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditMenuRole from "./AddEditMenuRole";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/Button";
import { Chip } from "@mui/material";

const MenuRole = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Assign Menu to Role" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});

  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Menu Role - Add"),
      ManageDelete: ActionPermission("Menu Role - Delete"),
    });
  }, [permissionList]);

  const fetchMenuRole = async () => {
    const data = await WebService({
      endPoint: "MenuRole/Fetch",
      dispatch,
    });

    setRecords(data);
  };

  const onDelete = async (MenuRoleId) => {
    await WebService({
      endPoint: `MenuRole/Remove/${MenuRoleId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchMenuRole();
  };

  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchMenuRole();
    }
    renderAfterCalled.current = true;
  }, []);

  const columns = [
    {
      Text: "Role Name",
      render: (dr) => {
        return <><span>{dr.RoleName}</span> 
        {(Roles[0] === StandardConst.SystemRole.SuperAdmin && dr.IsReservedMenu === StandardConst.YesNo.Yes && dr.IsReservedRole === StandardConst.YesNo.Yes) && (
          <>
            &nbsp;&nbsp;
            <Chip 
                label="Reserved"
                sx={{ color: "white", padding: "0px"}}
                color= "primary"
                size="small"
            />
          </>
        )}</>
      }
    },
    {
      Text: "Menu Text",
      Value: "MenuText",
    },
    {
      Text: "Action",
      key: "MenuRoleId",
      cssClass: "text-center td-width-100",
      isVisiable: permission.ManageDelete,
      Template: (
        <>
          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                e.currentTarget.parentElement.parentElement.getAttribute("data-key") // Endpoint to hit for delete
              )
            }
            disabled={!permission.ManageDelete}
            IconName="Delete"
            id="btnMenuRoleDelete"
          />
        </>
      ),
    }
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Security Management",
      hrefLink: "#",
    },
    {
      title: "Assign Menu to Role",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Assign Menu to Role";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Menu Role";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.AssignMenuRoleheaderValueNoResults}
            subHeaderValue={StandardConst.AssignMenuRoleSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button id="NoRecordFoundAddMenuRole" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditMenuRole
        callBackEvent={() => fetchMenuRole()}
        ref={addEditModalRef}
      ></AddEditMenuRole>
    </>
  );
};
export default MenuRole;

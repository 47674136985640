import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import * as yup from "yup";
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { useDispatch, useSelector } from "react-redux"
import { map } from 'underscore';


const AddEditInterviewType = (props, ref) => {

    const [show, setShow] = useState(false);
    const [interviewTypeData, setInterviewTypeData] = useState({});
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo);
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const isSuperAdmin = Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin);
    const dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id > 0) {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        await WebService({
            endPoint: `CommonUtility/Edit/staticinterviewtypes?StaticInterviewTypeId=${id || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? setInterviewTypeData(c[0]) : {}));
    }

    const handleClose = () => {
        setShow(false)
        setInterviewTypeData({});
    };

    const schema = yup
        .object()
        .shape({
            InterviewType: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            InterviewTypeCode: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            // Status: yup.string().trim().required(requiredMessage),
        })
        .required();

    const onSubmit = async (data) => {
        if (data.StaticInterviewTypeId === undefined) {
            await WebService({
                endPoint: "CommonUtility/staticinterviewtypes",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/staticinterviewtypes?StaticInterviewTypeId=${data.StaticInterviewTypeId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(interviewTypeData.StaticInterviewTypeId || 0) === 0
                        ? "Add Interview Type"
                        : "Edit Interview Type"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={interviewTypeData}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Interview Type Name"
                                name="InterviewType"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Interview Type Code"
                                name="InterviewTypeCode"
                                type="text"
                                isRequired="true"
                                maxlength="2"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(interviewTypeData && interviewTypeData.StaticInterviewTypeId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditInterviewType);
import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AssEditEmployeeJobFunction from "./AddEditEmployeeJobFunction";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { sortBy } from "underscore";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";

const EmployeeJobFunction = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  const [appData, setAppData] = React.useState({
    searchPlaceHolder: "Search Job Function",
    addMenuTitle: "Add Job Function",
  });
  PageInfo({ pageTitle: "Employee Job Function" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Roles - Add"),
    ManageEdit: ActionPermission("Roles - Edit"),
    ManageDelete: ActionPermission("Roles - Delete"),
    });
  }, [permissionList]);

  const fetchEmployeeJobFunction = async () => {
    const data = await WebService({
      endPoint: "JobFunction/FetchEmployeeJobFunction",
      dispatch,
    });
    setRecords(sortBy(data, (s) => s.FullName));
    dispatch(ConfigurationflowStatusUpdate());
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchEmployeeJobFunction();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Employee Name",
      Value: "FullName",
    },
    {
      Text: "Employee Code",
      Value: "EmployeeCode",
    },

    {
      Text: "Job Function",
      Value: "JobFunction",
    },
    {
      Text: "Action",
      cssClass: "text-end td-width-100",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          <ActionButton
            onClick={() => fnEdit(dr.EmployeeId)}
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnManageJobFunctionEdit"
          />
        </>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Employee",
      hrefLink: "#",
    },
    {
      title: "Employee Job Function",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Employee Job Function";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Job Function";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={false}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AssEditEmployeeJobFunction
        callBackEvent={() => fetchEmployeeJobFunction()}
        ref={addEditModalRef}
      ></AssEditEmployeeJobFunction>
    </>
  );
};
export default EmployeeJobFunction;

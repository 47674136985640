//#region Import
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WebService } from "../../Services/WebService";
import { format } from "date-fns";
import "./Dashboard.css";
import AddEditLeave from "../Leave/AddEditleave";
import { toInteger } from "lodash";
import StarRatings from "react-star-ratings";
import { Link, useNavigate } from "react-router-dom";
import _, { extend, map, reduce, where } from "underscore";
import { DateTime } from "luxon";
import { ActionPermission, PageInfo } from "../PageInfo";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { StandardConst } from "../../Services/StandardConst";
import "../../asset/ReactStyle.scss";
import "../../asset/BootstrapStyle.scss";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { green } from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { WSSuccessAlert } from "../../Services/WSAlert";

import {
  AlertTitle,
  Avatar,
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/material";
import Swal from "sweetalert2";
import { InputText } from "../Form";
import FlowDashboardButton from "../ConfigurationFlow/FlowComponent/FlowDashboardButton";
import AddEditRoles from "../Static/AddEditRoles";
import InviteUser from "../ConfigurationFlow/FlowComponent/InviteUser";
import Button from "react-bootstrap/esm/Button";
import DoneIcon from "@mui/icons-material/Done";
//#endregion
//#region DashboardComponent
const DashboardComponent = () => {
  const permission = {
    ApplyLeave: ActionPermission("Leave - Add"),
  };
  PageInfo({ pageTitle: "Dashboard" });
  var ActiveClass = null;
  const [Info, setInfo] = useState([]);
  const [Performance, setPerformance] = useState([]);
  const [leaveStatus, setleaveStatus] = useState([]);
  const [Employeeleave, setEmployeeleave] = useState([]);
  const [EmployeeProject, setEmployeeProject] = useState([]);
  const [ProjectManager, setProjectManager] = useState([]);
  const [Notification, setNotification] = useState([]);
  const [timeSheet, setTimeSheet] = useState({});
  var dispatch = useDispatch();

  var fetchData = async () => {
    var res = await WebService({ endPoint: "Dashboard/Fetch" /* dispatch*/ });
    //setDashboard(res.users);
    if (res.rating[0] == null) {
      setPerformance(0);
    } else {
      setPerformance(res.rating[0]);
    }
    // if ((res.EmployeeAssignProject[0]?.CreatedDate || "") !== "")
    //     res.EmployeeAssignProject[0].CreatedDate = format(
    //       new Date(res.EmployeeAssignProject[0].CreatedDate),
    //       "yyyy-MM-dd"
    //     );

    setleaveStatus(res.lastleaveStatus[0]?.Status);
    setEmployeeleave(res.Employeeleave);
    setEmployeeProject(res.EmployeeAssignProject);
    setProjectManager(res.ProjectManager);
    let pendingFor = 0;
    if (
      res.timeSheet.lastDay.length > 0 &&
      DateTime.fromISO(res.timeSheet.lastDay[0].LastDate).diffNow("day").values
        .days < -1
    ) {
      pendingFor =
        parseInt(
          DateTime.fromISO(res.timeSheet.lastDay[0].LastDate).diffNow("day")
            .values.days
        ) * -1;
    }
    let lastEntryTime = 0;
    let lastEntryProject = "N/A";
    if (res.timeSheet.lastEntry.length > 0) {
      const lastEntry = res.timeSheet.lastEntry[0];
      lastEntryProject = lastEntry.ProjectName;
      lastEntryTime = parseInt(
        DateTime.fromISO(lastEntry.UpdatedTime).diffNow("day").values.days * -1
      );
    }
    setTimeSheet({
      pendingFor: `${pendingFor} Days`,
      lastEntryTime: `${lastEntryTime} Days`,
      lastEntryProject,
    });
  };
  const fetchNotification = async () => {
    var res = await WebService({ endPoint: "Notification/Fetch" /*dispatch*/ });
    setNotification(res);
  };

  const fetchCheckIn = async () => {
    var info = await WebService({
      endPoint: "Dashboard/fetchCheckIn",
      method: "GET",
      //dispatch,
    });
    setInfo(info?.slice(-1) ?? []);
  };
  const addEditModalRef = useRef();
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  const renderAfterCalled = useRef(false);
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();
      fetchCheckIn();
      fetchNotification();
    }
    renderAfterCalled.current = true;
  }, []);
  const pStatuscolor = leaveStatus == "Pending";
  const aStatuscolor = leaveStatus == "Approve";
  const rStatuscolor = leaveStatus == "Reject";
  const score = toInteger(
    (Performance.ManagerScore +
      Performance.EmpSelfScore +
      Performance.FinalAgreedScore) /
      3
  );

  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);

    return color;
  }

  const checkIn = async () => {
    const res = await WebService({
      endPoint: "Dashboard/EmployeeCheckInCheckOut",
      method: "POST",
      dispatch,
    });
    if (res.Message == "CheckInSuccess") {
      WSSuccessAlert("CheckIn Success", "Attendance Successfully logged");
    }

    if (res.Message == "CheckOutSuccess") {
      WSSuccessAlert("CheckOut Success", "Attendance Successfully logged");
    }
    fetchCheckIn();
  };

  var Checked =
    Info?.length == 0 ? (
      <Button variant="outline-success" onClick={checkIn} className="mx-1">
        <span className=" mx-1">
          <i className="fa fa-clock-o"></i>
        </span>
        Check In
      </Button>
    ) : Info[0]?.CheckOutTime == null && Info?.length > 0 ? (
      <Button variant="outline-success" className="mx-1" onClick={checkIn}>
        <span className=" mx-1">
          <i className="fa fa-clock-o"></i>
        </span>
        Check Out
      </Button>
    ) : (
      <Button variant="outline-primary" className="mx-1" onClick={checkIn}>
        <span className=" mx-1">
          <i className="fa fa-clock-o"></i>
        </span>
        Check In
      </Button>
    );
  var nevigate = useNavigate();
  var projectDetilView = (data) => (
    <>
      <div className="d-flex justify-content-between">
        <List
          sx={{
            width: "100%",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                style={{
                  backgroundColor: randomColor(),
                }}
                alt={`${data.ProjectName}`}
                src="/static/images/avatar/1.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${data.ProjectName} `}
              secondary={
                <React.Fragment>
                  {(data.ManagerRole ?? "") !== "" && (
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {data.ManagerRole}:&nbsp;
                      </Typography>
                      <span>
                        {reduce(
                          data.ManagerName,
                          (m, v) => `${m} | ${v ?? ""}`,
                          ""
                        ).substring(3)}
                      </span>
                    </>
                  )}

                  <span className="d-flex float-end">
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Assigned On: &nbsp;
                    </Typography>

                    {format(new Date(data.CreatedDate), "dd-MMM-yy")}
                  </span>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
      </div>
    </>
  );
  return (
    <div className="container p-0">
      <header className="bg-surface-primary border-bottom pt-6 p-1">
        <div className="container-fluid p-0 pb-2 pt-2">
          <div className="mb-npx">
            <div className="row align-items-center p-0">
              <div className="col-sm-5 col-12 mb-sm-0">
                <h3 className="">Dashboard</h3>
              </div>
              <div className="col-sm-7 col-12 text-sm-end">
                <div className="mx-n1">
                  {Checked}
                  <Button
                    variant="outline-primary"
                    className="mx-1"
                    onClick={() => nevigate("/Common/Profile")}
                  >
                    <i className="fa fa-pencil mx-1"></i> Profile
                  </Button>
                  {permission.ApplyLeave && (
                    <Button
                      variant="outline-success"
                      className="mx-1"
                      onClick={() => fnEdit()}
                      disabled={!permission.ApplyLeave}
                    >
                      <i className="fa fa-plus mx-1"></i>
                      Leave
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ConfigurationManager />
      <ConfigurationActions />

      <TeamDashboard />
      {/* <TopPerformers /> */}
      <div className="base-margin-x">
        <Row>
          <Col className="col-md-6 col-12">
            {" "}
            <Paper>
              <Alert severity="info" icon={<AccountTreeIcon />}>
                <strong> Project Details</strong>
              </Alert>
              {EmployeeProject.length == 0
                ? "No Project Assigned yet"
                : EmployeeProject.map(projectDetilView)}
            </Paper>
          </Col>
          <Col className="col-md-6 col-12">
            {" "}
            <Paper>
              <Alert severity="success" icon={<NotificationsActiveIcon />}>
                <strong> Notification</strong>
              </Alert>
              {Notification.length == 0
                ? "No Notification"
                : Notification.map((note) => {
                    return (
                      <div className="d-flex justify-content-between">
                        <List
                          sx={{
                            width: "100%",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                        >
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar
                                alt={`${note.Title}`}
                                src="/static/images/avatar/1.jpg"
                              />
                            </ListItemAvatar>
                            <Link to={`/${note.Route}`}>
                              <ListItemText
                                primary={`${note.Title} `}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    ></Typography>
                                    {Notification.length == 0 ? (
                                      "No Notification"
                                    ) : (
                                      <span> {note.Subject} </span>
                                    )}
                                  </React.Fragment>
                                }
                              />
                            </Link>
                          </ListItem>
                          <Divider variant="inset" component="li" />
                        </List>
                      </div>
                    );
                  })}
            </Paper>
          </Col>
        </Row>
      </div>
      <div>
        <div className="base-margin-x base-margin-2y">
          {permission.ApplyLeave && (
            <div className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-blue btn-h-outline-blue btn-a-outline-blue w-100 base-margin-2y py-3 shadow-sm">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <h4 className="text-600 text-primary letter-spacing align-items-center">
                    Leave Details
                  </h4>

                  <div className="text-secondary-d1 text-120 d-none">
                    <span className="ml-n15 align-text-bottom"></span>
                    {pStatuscolor ? (
                      <span className="badge bg-warning">
                        Status: {leaveStatus}
                      </span>
                    ) : aStatuscolor ? (
                      <span className="badge bg-success">
                        Status: {leaveStatus}
                      </span>
                    ) : rStatuscolor ? (
                      <span className="badge bg-danger">
                        Status: {leaveStatus}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <ul className="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left  my-md-0">
                  {Employeeleave.length == 0 ? (
                    <span className="text-danger">
                      You Can apply Unpaid Leave Only
                    </span>
                  ) : (
                    Employeeleave.map((data) => {
                      return (
                        <li>
                          <i className="fa fa-check text-success text-110 mr-2 mt-1"></i>
                          <span className="text-110">
                            &nbsp;{data.LeaveName} :{data.Usedleave}/
                            {data.Balance}
                          </span>
                        </li>
                      );
                    })
                  )}
                </ul>
                <div className="col-12 col-md-4 text-center">
                  <Button
                    variant="outline-primary"
                    className="mx-1 w-40"
                    onClick={() => fnEdit()}
                    disabled={!permission.ApplyLeave}
                  >
                    <i className="fa fa-plus mx-1"></i>
                    Apply Leave
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div className="d-style bgc-white btn btn-brc-tp btn-outline-green btn-h-outline-green btn-a-outline-green w-100 base-margin-2y py-3 shadow-sm border-2">
            <div className="row align-items-center">
              <div className="col-12 col-md-4">
                <h4 className="text-600 text-success letter-spacing align-items-center">
                  Time Sheet
                </h4>

                <div className="text-secondary-d2 text-120 d-none">
                  <span className="badge rounded-pill bg-danger">Pending</span>
                </div>
              </div>

              <ul className="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-md-0">
                <li>
                  <i className="fa fa-check text-danger text-110 mr-2 mt-1"></i>
                  <span className="text-110">
                    &nbsp;Pending for : {timeSheet.pendingFor}
                  </span>
                </li>
                <li>
                  <i className="fa fa-check text-success text-110 mr-2 mt-1"></i>
                  <span className="text-110">
                    &nbsp;Last Entry : {timeSheet.lastEntryTime} ago
                  </span>
                </li>
                <li>
                  <i className="fa fa-check text-success text-110 mr-2 mt-1"></i>
                  <span className="text-110">
                    &nbsp;Last Project : {timeSheet.lastEntryProject}
                  </span>
                </li>
              </ul>

              <div className="col-12 col-md-4 text-center">
                <Button
                  variant="outline-success"
                  className="mx-1 w-40"
                  onClick={() => nevigate("/TimeSheet/Entry")}
                >
                  <i className="fa fa-plus mx-1"></i>
                  Timesheet
                </Button>
              </div>
            </div>
          </div>

          <div className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-purple btn-h-outline-purple btn-a-outline-purple w-100 base-margin-2y py-3 shadow-sm">
            {" "}
            <div className="row align-items-center">
              <div className="col-12 col-md-4">
                <h4 className="text-600 text-info letter-spacing">
                  Self Performance
                </h4>
                <div className="d-flex justify-content-center d-none">
                  <div className="ratings">
                    <StarRatings
                      rating={score}
                      starDimension="25px"
                      starSpacing="5px"
                      starRatedColor="#ffc107"
                    />
                  </div>
                </div>
              </div>

              <ul className="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-md-0">
                {" "}
                <li
                  {...(ActiveClass = [
                    Performance.ManagerScore <= "3"
                      ? "fa fa-check text-danger text-110 mr-2 mt-1"
                      : Performance.ManagerScore == 4
                      ? "fa fa-check text-info text-110 mr-2 mt-1"
                      : "fa fa-check text-success text-110 mr-2 mt-1",
                  ].join(""))}
                >
                  <i className={ActiveClass}></i>
                  <span>
                    <span className="text-110">
                      {" "}
                      Manager Score :{" "}
                      {Performance.ManagerScore > 0
                        ? Performance.ManagerScore
                        : "No Rating"}
                    </span>
                  </span>
                </li>
                <li
                  {...(ActiveClass = [
                    Performance.EmpSelfScore == "3"
                      ? "fa fa-check text-danger text-110 mr-2 mt-1"
                      : Performance.EmpSelfScore == "4"
                      ? "fa fa-check text-info text-110 mr-2 mt-1"
                      : "fa fa-check text-success text-110 mr-2 mt-1",
                  ])}
                >
                  <i className={ActiveClass}></i>
                  <span>
                    <span className="text-110">
                      {" "}
                      Self Score :{" "}
                      {Performance.EmpSelfScore > 0
                        ? Performance.EmpSelfScore
                        : "No Rating"}
                    </span>
                  </span>
                </li>
                <li
                  {...(ActiveClass = [
                    Performance.FinalAgreedScore == "3"
                      ? "fa fa-check text-danger text-110 mr-2 mt-1"
                      : Performance.FinalAgreedScore == "4"
                      ? "fa fa-check text-info text-110 mr-2 mt-1"
                      : "fa fa-check text-success text-110 mr-2 mt-1",
                  ])}
                >
                  <i className={ActiveClass}></i>
                  <span>
                    <span className="text-110">
                      {" "}
                      Final Score :{" "}
                      {Performance.FinalAgreedScore > 0
                        ? Performance.FinalAgreedScore
                        : "No Rating"}
                    </span>
                  </span>
                </li>
              </ul>

              <div className="col-12 col-md-4 text-center">
                <Button
                  variant="outline-info"
                  className="mx-1 w-40"
                  onClick={() => nevigate("/Performance")}
                >
                  <i className="fa fa-plus mx-1"></i>
                  View Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddEditLeave
        callBackEvent={() => fetchData()}
        ref={addEditModalRef}
      ></AddEditLeave>
    </div>
  );
};
//#endregion
//#region TeamDashboard
const TeamDashboard = () => {
  var ActiveClass = null;
  const [Dashboard, setDashboard] = useState([]);
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(3);
  const afterRender = React.useRef(false);
  const dispatch = useDispatch();
  const fetchData = async (searchText) => {
    searchText ??= "";
    const v1 = await WebService({
      // dispatch,
      endPoint: `Project/Team/Member?search=${searchText}`,
    });
    setDashboard(v1);
  };
  function handleChangePage(event, newpage) {
    setpg(newpage);
  }
  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  React.useEffect(() => {
    if (!afterRender.current) fetchData();
    afterRender.current = true;
  }, []);
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  // const InputText = styled(InputBase)(({ theme }) => ({
  //   color: "inherit",
  //   "& .MuiInputBase-input": {
  //     padding: theme.spacing(1, 1, 1, 0),
  //     // vertical padding + font size from searchIcon
  //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     transition: theme.transitions.create("width"),
  //     width: "100%",
  //     [theme.breakpoints.up("md")]: {
  //       width: "20ch",
  //     },
  //   },
  // }));
  return (
    <>
      <div className="base-margin-x mt-2 base-margin-2y mb-2">
        <Paper>
          <Alert
            severity="info"
            icon={<Diversity3Icon />}
            action={
              <div className="d-flex flex-row align-self-center">
                <InputText
                  setValue={async (v) => await fetchData(v ?? "")}
                  placeholder="Search Team"
                />
                {/* <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <InputText
                    value={searchText}
                    setValue={(v) => setSearchText(v ?? "")}
                    placeholder="Search Team"
                  />
                </Search> */}
              </div>
            }
          >
            <AlertTitle>My Team</AlertTitle>
          </Alert>

          <div className="d-flex flex-row mx-2 bg-light">
            {Dashboard.slice(pg * rpg, pg * rpg + rpg).map((item) => (
              <div className="col-md-4 col-sm-4 col-12 p-1 d-flex align-items-stretch bg-light">
                <div className="d-none">
                  {
                    (ActiveClass = [
                      item.Status == "Approved" ? "success" : "warning",
                    ].join(" "))
                  }
                </div>

                <div className="card card-team border border-1 border-dark border-opacity-25 rounded shadow-2">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-row align-items-center">
                        <div className="icon-image-up">
                          <img
                            className="rounded-circle"
                            src={
                              item.ProfileImage != ""
                                ? `${StandardConst.apiBaseUrl}/uploads/${
                                    item.ProfileImage ?? "user_placeholder.jpg"
                                  }`
                                : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                            }
                          />
                        </div>
                        <div className="ms-2 c-details">
                          <h6 className="mb-0">{item.FullName}</h6>{" "}
                          <span className="small">{item.Designation}</span>
                        </div>
                      </div>
                      <div>
                        <span
                          className={`bordered accent badge badge-pill bg-soft-${ActiveClass} text-${ActiveClass} me-2`}
                        >
                          {item.Status}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="small">
                          {(item.Roles ?? []).length > 0 && (
                            <p className="ws-font-10 mb-0">
                              {" "}
                              {reduce(
                                item.Roles,
                                (m, v) =>
                                  `${m} | ${v.ProjectName} (${v.DisplayDescription})`,
                                ""
                              ).substring(2)}
                            </p>
                          )}
                          {(item.Roles ?? []).length < 1 && (
                            <p className="text-muted mb-0">
                              {" "}
                              {item.Designation}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-muted d-flex justify-content-around">
                    <button className="btn">
                      <a href={`mailto:${item.Email}`}>
                        <i className="fa fa-envelope"></i>
                      </a>
                    </button>
                    <button className="btn">
                      <a href={`tel:${item.Phone}`}>
                        <i className="fa fa-phone"></i>
                      </a>
                    </button>
                    <button className="btn">
                      <i className="fa fa-comments"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <TablePagination
            rowsPerPageOptions={[4]}
            component="div"
            count={Dashboard.length}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
};
//#endregion
//#region Top Performers
const TopPerformers = () => {
  const afterRender = React.useRef(false);
  const dispatch = useDispatch();
  const [topPerformers, setTopPerformers] = React.useState({});
  React.useEffect(() => {
    if (!afterRender.current) fetchTopPerformers();
    afterRender.current = true;
  }, []);
  const fetchTopPerformers = async () => {
    var res = await WebService({
      endPoint: "Dashboard/fetchTopPerformers",
      // dispatch,
    });
    setTopPerformers(res);
  };
  return (
    <>
      <div className="base-margin-x mt-2 base-margin-2y mb-2">
        <Paper>
          <Alert severity="success" icon={<NotificationsActiveIcon />}>
            <strong> Top Performers</strong>
          </Alert>
          <div className="d-flex flex-wrap justify-content-center">
            {topPerformers.map((p) => {
              return (
                <div className="card text-center card-performers mt-3 m-2">
                  <div className="circle-image">
                    <img src="https://i.imgur.com/hczKIze.jpg" width="50" />
                  </div>
                  {/* <div>
                    <Avatar
                      className="performance-image"
                      alt={`${p.argument}`}
                      src="/static/images/avatar/1.jpg"
                    />
                  </div> */}
                  <div className="six-pointed-star"></div>
                  <span className="name mb-1 fw-500">{p.argument}</span>
                  <small className="text-black-50">{p.Designation}</small>
                  <small className="text-black-50 font-weight-bold">
                    {p.EmployeeCode}
                  </small>
                  <div className="rate bg-success text-white mt-1">
                    <div className="rating">
                      <input type="radio" name="rating" value="5" id="5" />
                      <label for="5">★</label>
                      <input type="radio" name="rating" value="5" id="4" />
                      <label for="4">★</label>
                      <input type="radio" name="rating" value="5" id="3" />
                      <label for="3">★</label>
                      <input type="radio" name="rating" value="5" id="2" />
                      <label for="2">★</label>
                      <input type="radio" name="rating" value="5" id="1" />
                      <label for="1">★</label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Paper>
      </div>
    </>
  );
};
//#endregion
//#region Configuration Manager

const ConfigurationManager = () => {
  const afterRender = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [configurationVisibility, setConfigurationVisibility] = useState([]);
  useEffect(() => {
    if (!afterRender.current) {
      WebService({ /*dispatch,*/ endPoint: "ConfigurationFlow" }).then((c) => {
        setConfigurationVisibility(
          map(c.flow, (m) =>
            extend(m, {
              component: where(c.component, {
                ConfigurationId: m.ConfigurationId,
              }),
            })
          )
        );
      });
    }
    afterRender.current = true;
  }, []);
  const [progress, setProgress] = React.useState(50);
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#50FBA2" : "#3C65C4",
    },
  }));
  const checkMark = <CheckCircleOutlineIcon sx={{ fontSize: 30 }} />;
  const incompleteCheckMark = (
    <Avatar sx={{ width: 30, height: 30 }}>{checkMark}</Avatar>
  );
  const completeCheckMark = (
    <Avatar sx={{ bgcolor: green[500], width: 30, height: 30 }}>
      {checkMark}
    </Avatar>
  );
  const completeNowButton = (component) => (
    <>
      <button
        onClick={() => {
          navigate(component.Nevigaion);
        }}
        type="button"
        className="btn btn-outline-primary btn-sm"
      >
        Complete Now
      </button>
    </>
  );
  const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: 5,
    padding: theme.spacing(2, 0.8, 2, 0.8),
    "& .MuiChip-root": {},
  }));

  const completedButton = (component) => (
    <>
      <StyledChip
        icon={<DoneIcon />}
        label="Completed"
        color="success"
        size="small"
      />
    </>
  );
  const flow = (m) => (
    <div className="container bootstrap snippets bootdey">
      <div className="widget-head-color-box lazur-bg p-lg">
        <div className="row">
          <div className="col d-flex justify-content-start flex-column">
            <div className="font-bold no-margins">{m.ConfigurationName}</div>
            <div> {m.Details}</div>
          </div>
          <div className="col d-flex align-items-end justify-content-center flex-column me-3">
            <Box sx={{ width: "50%" }}>
              <div>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    (where(m.component, {
                      StatusId: StandardConst.Status.Completed,
                    }).length /
                      m.component.length) *
                    100
                  }
                />
                <div className="d-flex justify-content-end">
                  {
                    where(m.component, {
                      StatusId: StandardConst.Status.Completed,
                    }).length
                  }
                  /{m.component.length} Completed
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <div className="widget-text-box mt-n4">
        {map(m.component, (component, componentIndex) => (
          <div className="row mb-4" key={componentIndex}>
            <div className="col flex-row d-flex justify-content-start">
              {(component.StatusId ?? StandardConst.Status.Pending) ===
              StandardConst.Status.Pending
                ? incompleteCheckMark
                : completeCheckMark}
              <span className="mx-2">
                {componentIndex + 1}. {component.ComponentName}
              </span>
              <Chip
                label={
                  (component.IsRequired ?? StandardConst.YesNo.No) ===
                  StandardConst.YesNo.Yes
                    ? "Required"
                    : "Optional"
                }
                variant="outlined"
                size="small"
                color={
                  (component.IsRequired ?? StandardConst.YesNo.No) ===
                  StandardConst.YesNo.Yes
                    ? "error"
                    : "info"
                }
              />
            </div>
            <div className="col d-flex justify-content-end">
              {(component.StatusId ?? StandardConst.Status.Pending) ===
              StandardConst.Status.Pending
                ? completeNowButton(component)
                : completedButton()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <>
      {map(configurationVisibility, (m) => (
        <>
          {m.component.length !==
            where(m.component, { StatusId: StandardConst.Status.Completed })
              .length && flow(m)}
        </>
      ))}
    </>
  );
};
//#endregion

export const ConfigurationActions = memo(() => {
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const downloadTxtFile = () => {
    const DomainURL = `${CompanyInfo.DomainName}/#/Login?company=${CompanyInfo.Code}`;
    const element = document.createElement("a");
    const file = new Blob(
      [
        `Details for the Organization : ${CompanyInfo.CompanyName}\nLogin ID: ${CompanyInfo.Email} \nLoginURL: ${DomainURL}
        \nTo setup WiseOfficeDay as your private Company HRMS system, please follow the instructions below:\n
        1. Open company profile on WiseOfficeDay using the Company Profile menu item and add your website domain.\n
        2. Now, go your company website hosting provide. Login into your account. Please create a subdomain in your website with following format\n
               employee.[Yourwebsite].com
          3. Please add a A record in the DNS configuration for your newly created sub-domain employee.[Yourwebsite].com\n
           DNS Setting\n
           A Record\n
           Host name:  employee.[Yourdomain].com\n
           IP Address: 69.49.234.185\n
        Wait for 1-2 hours. You and your employees will be able to login using employee.[Yourwebsite].com`,
      ],
      {
        type: "text/plain;charset=utf-8",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = "OrganizationDetails.txt";
    document.body.appendChild(element);
    element.click();
  };
  const permission = {
    AdminAction: ActionPermission("Config Action - Admin"),
    CompanyAction: ActionPermission("Config Action - Company"),
    HRAction: ActionPermission("Config Action - HR"),
    ManagerAction: ActionPermission("Config Action - Manager"),
    EmployeeAction: ActionPermission("Config Action - Employee"),
  };

  const [systemRolesData, setSystemRolesData] = useState([]);
  const getSystemRolesfunction = () => {
    WebService({ 
      endPoint: "Roles/getSystemRoles" 
    }).then((systemRoles) => {
      const filteredRoles = systemRoles.filter(role => role.RoleId !== StandardConst.SystemRole.Manager);
      setSystemRolesData(filteredRoles);
    });
  }

  useEffect(() => {
    getSystemRolesfunction();
  },[]);
  var nevigate = useNavigate();
  const addEditModalRef = useRef();
  const inviteUserModalRef = useRef();

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  const fnInviteUser = async (id) =>
    await inviteUserModalRef.current.openModal(id || 0);

  return (
    <Row className="p-0">
      <AddEditRoles ref={addEditModalRef}></AddEditRoles>
      <InviteUser ref={inviteUserModalRef} />
      {(permission.AdminAction || permission.CompanyAction) && (
        systemRolesData?.map((systemRoles, index) => (
            <FlowDashboardButton
              headerValue={`Add ${systemRoles.RoleName}`}
              headerIcon="plus-circle me-1"
              headerColor={index % 2 === 0 ? "primary" : "success"}
              subHeaderValue={`${systemRoles.RoleName} for your organization`}
              actionButton={
                <>
                  <Button
                    size="sm"
                    variant={index % 2 === 0 ? "outline-primary" : "outline-success"}

                    onClick={async () =>
                      await inviteUserModalRef.current.openModal(
                        1,
                        systemRoles.RoleId,
                        'Invite ' + systemRoles.RoleName
                      )
                    }
                    id={`invite_${systemRoles.RoleName}`}
                  >
                    Invite {systemRoles.RoleName}
                  </Button>
                </>
              }
            />
        ))
      )}

      {/* Domain Configuration card */}
      {permission.AdminAction && (
        <FlowDashboardButton
          headerIcon="cog me-1"
          headerValue="Domain Configuration"
          subHeaderValue="Setup Employee Login domain"
          actionButton={
            <>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => fnInviteUser(3)}
              >
                Email Config
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => downloadTxtFile()}
              >
                Download Domain
              </Button>
            </>
          }
        />
      )}

      {/* Employee Management card */}
      {permission.HRAction && (
        <FlowDashboardButton
          headerColor="success"
          headerIcon="users me-1"
          headerValue="Employee Management"
          subHeaderValue="Approve Employee & Set Salary"
          actionButton={
            <>
              <Button
                size="sm"
                variant="outline-success"
                onClick={() => nevigate("/RegistrationApproval")}
              >
                All Employee
              </Button>
              <Button
                size="sm"
                variant="outline-success"
                onClick={() => nevigate("/SalaryPackage")}
              >
                Create Package
              </Button>
            </>
          }
        />
      )}
      
      {/* Project management card */}
      {permission.ManagerAction && (
        <FlowDashboardButton
          headerIcon="code me-1"
          headerValue="Project Management"
          subHeaderValue="Manage your projects & team"
          actionButton={
            <>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => nevigate("/Project")}
              >
                Manage Projects
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => nevigate("/AssignTeam")}
              >
                Assign Team
              </Button>
            </>
          }
        />
      )}

      {/* Leave Management card */}
      {permission.HRAction && (
        <FlowDashboardButton
          headerColor="success"
          headerIcon="bell me-1"
          headerValue="Leave Management"
          subHeaderValue="Manage your team leaves"
          actionButton={
            <>
              <Button
                size="sm"
                variant="outline-success"
                onClick={() => nevigate("/LeaveApproval")}
              >
                Approve Leave
              </Button>
              <Button
                size="sm"
                variant="outline-success"
                onClick={() => nevigate("/leaveBalance")}
              >
                Assign Leave
              </Button>
            </>
          }
        />
      )}

      {/* Timesheet Entry & Reports card */}
      {permission.EmployeeAction && (
        <FlowDashboardButton
          headerColor="primary"
          headerIcon="calendar me-1"
          headerValue="Timesheet"
          subHeaderValue="Timesheet Entry & Reports"
          actionButton={
            <>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => nevigate("/TimeSheet/Entry")}
              >
                TimeSheet Entry
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => nevigate("/TimeSheet/Report")}
              >
                Report
              </Button>
            </>
          }
        />
      )}

      {/* Mark your Employee Appraisal card */}
      {permission.ManagerAction && (
        <FlowDashboardButton
          headerColor="success"
          headerIcon="check-circle me-1"
          headerValue="Appraisal"
          subHeaderValue="Mark your Employee Appraisal" 
          actionButton={
            <>
              <Button
                size="sm"
                variant="outline-success"
                onClick={() => nevigate("/Performance")}
              >
                Pending Appraisal
              </Button>
              <Button
                size="sm"
                variant="outline-success"
                onClick={() => nevigate("/Performance")}
              >
                Report
              </Button>
            </>
          }
        />
      )}
      {permission.HRAction && (
        <FlowDashboardButton
          headerIcon="check-circle me-1"
          headerValue="Payslip"
          subHeaderValue="Generate & Download Payslip"
          actionButton={
            <>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => nevigate("/Template")}
              >
                Template
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => nevigate("/Payslip")}
              >
                Generate Payslip
              </Button>
            </>
          }
        />
      )}
    </Row>
  );
});
export default memo(DashboardComponent);

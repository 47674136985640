import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Form, FormInputText } from '../Form';
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { WebService } from '../../Services/WebService';


const AddEditEventAttributes = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ EventAttributes: {} });
    const dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
        setShow(true);
        if(id === 0){
            const data = {EventAttributes: {}};
            setData(data);
        }else {
            setShow(false);
            await fetchEditValue(id);
            setShow(true);
        }
    },
}));

    const fetchEditValue = async(id) => {
        const data = {
            EventAttributes: await WebService({
                endPoint: `CommonUtility/Edit/eventattributes?EventAttributeId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
            setData(data);
    }

    const handleClose = () => {
        setShow(false);
    }

    const requiredMessage = "This is a required field";
    const schema = yup
        .object()
        .shape({
            Attribute: yup.string().trim().required(requiredMessage),
            Description: yup.string().trim().required(requiredMessage),
        })
        .required();

    const onSubmit = async(data) => {
        data.EventId = props.EventId;
        if (data.EventAttributeId === undefined) {
            await WebService({
                endPoint: "CommonUtility/eventattributes",
                body: data,
                dispatch,
            });
        }else {
            await WebService({
                endPoint: `CommonUtility/eventattributes?EventAttributeId=${data.EventAttributeId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }  
        handleClose();
        props.callBackEvent();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                            {(data && data.EventAttributes && data.EventAttributes.EventAttributeId || 0) === 0
            ? "Add Event Attributes"
            : "Edit Event Attributes"
            }
                    </Modal.Title>
                </Modal.Header>
                <Form
                    defaultValues={data.EventAttributes}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                >
                    <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Attribute Name"
                                name="Attribute"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Description"
                                name="Description"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="btnEventSubmit" variant="outline-primary" type="submit">
                        {(data && data.EventAttributes && data.EventAttributes.EventAttributeId || 0) === 0
            ? "Submit"
            : "Save Changes"
            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default forwardRef(AddEditEventAttributes);
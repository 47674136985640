import React, { memo, useEffect, useRef, useState } from "react";
import { WebService } from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import TableComponent, {
  NoRecordTemplate,
} from "../../../Services/TableComponent";
import { map } from "underscore";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../../Services/ActionButton";
import Cookies from "universal-cookie";
import { Avatar, Box, Container, Chip } from "@mui/material";

import StaticListComponent from "../../../Services/StaticListComponent";
import { StandardConst } from "../../../Services/StandardConst";
import ReactTimeAgo from "react-time-ago";
import { InputDropdown } from "../../Form";
import { DateTime } from "luxon";
import InvoiceSettingsComponent from "../InvoiceSettingsComponent";
import { PageInfo } from "../../PageInfo";

const cookies = new Cookies();
const InvoiceSettingsListComponent = () => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editInvoiceRef = useRef();
  PageInfo({ pageTitle: "Invoice Settings" });
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});
  const [companyList, setCompanyList] = useState({});
  
  const getAllCompany = async () => {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/fetchAll/companyinformation`,
      method: "GET",
    }).then((res) => {
      setCompanyList(res.reduce((acc, company) => {
        acc[company.CompanyId] = company.CompanyName;
        return acc;
      }, {}));
    });
  };

  useEffect(() => {
    getAllCompany();
  }, [Roles?.some((element) => element === StandardConst.SystemRole.SuperAdmin)]);

  const fetchAllInvoice = async () => {
    const endPoint = (Roles[0] === StandardConst.SystemRole.SuperAdmin) ? `CommonUtility/fetchAll/invoice_settings` : `CommonUtility/fetchRecordsByStatus/invoice_settings/'${StandardConst.Status.Active}'?IsReserved=${StandardConst.YesNo.Yes}&CompanyId=${StandardConst.YesNo.Yes}`;

    await WebService({
      dispatch,
      endPoint: endPoint,
    }).then( async (InvoiceSettingList) => {
      setState(InvoiceSettingList);
    });
  };

  useEffect(() => {
    if (Roles.length > 0) {
      fetchAllInvoice();
    }
  }, [Roles]);

  const downloadFile = async (filePath) => {
    try {
      const response = await fetch(`${StandardConst.apiBaseUrl}/${filePath}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies.get("token") ?? ""}`, // Add your authorization header if needed
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        let filename = "downloaded-file"; // Default filename

        const contentDisposition = response.headers.get("content-disposition");
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch) {
            filename = filenameMatch[1];
          }
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(`Failed to download file. Status: ${response.status}`);
      }
    } catch (error) {
      console.error("File download failed:", error);
    }
  };
  const fnDownloadInvoicePdf = async (InvoiceId) => {
    await WebService({
      dispatch,
      endPoint: `Invoice/GenerateInvoice/${InvoiceId}`,
    }).then((r) => {
      const invoiceName = `FileService/EmailAttachment/${r}.pdf`;
      downloadFile(invoiceName);
    });

    // handleDownloadClick();
  };
  const MasterPageName = "Invoice Settings";
  const [bData, setBData] = React.useState([
    {
      title: "Invoice Management",
      hrefLink: "#",
    },
    {
      title: "Invoice Setting",
      hrefLink: "#",
    },
  ]);

  const invoiceSettingDelete = async (InvoiceSettingId) => {
    await WebService({
      dispatch,
      endPoint: `Invoice/DeleteInvoiceSetting/${InvoiceSettingId}`,
      method: "DELETE",
    }).then((res) => {
      if (res != "") {
        fetchAllInvoice();
      }
    });
  };

  const fnAddEdit = async (InvoiceSettingId, clickMode) => {
    navigate(`/AddInvoiceSettings`, { state: { InvoiceSettingId, clickMode } });
  };

  const Columns = [
    {
      Text: "Setting",
      Value: "SettingName",
      render: (dr) => (
        <>
          <span>
            {dr.SettingName}{" "}
            <i
              onClick={() => fnDownloadInvoicePdf(dr.InvoiceSettingId)}
            />
            {(dr.IsReserved === StandardConst.YesNo.Yes) && (
              <Chip 
                  label="Reserved"
                  sx={{ color: "white", padding: "0px"}}
                  color= "primary"
                  size="small"
              />
            )}
          </span>
        </>
      ),
    },
    {
      Text: "Invoice Number",
      render: (dr) => (
        <>
          {dr.InvoicePrefix}{dr.InvoiceNumberFormat}
        </>
      ),
    },
    {
      Text: "Discount",
      Value: "DiscountType",
    },
    {
      Text: "Currency",
      render: (dr) => <span>{StandardConst.Currency.find(currency => currency.value === dr.CurrencyId)?.text || ""}</span>
    }
  ];

  if(Roles[0] === StandardConst.SystemRole.SuperAdmin){
    Columns.push(
    {
      Text: "Available For",
      render: (dr) => {
        if(dr.IsReserved > 0){
          return "All Companies";
        }
        else{
          return companyList[dr.CompanyId];
        }
      },
    },
    {
      Text: "Status",
      render: (dr) => {
        const data =  Object.keys(StandardConst.Status).find(key => StandardConst.Status[key] === dr.StatusId) || "";
        return (
          <>
            <Chip label={data === "Delete" ? "Deleted" : data }
            sx={{ backgroundColor: data === "Delete" ? "#808080" : "green" }}
            />
          </>
        )
      },
    });
  }

  Columns.push({
    Text: "Action",
    cssClass: "text-end",
    render: (dr, key) => (
      <div key={key} className="d-flex">
        <ActionButton
          onClick={() => fnAddEdit(dr.InvoiceSettingId, StandardConst.FormMode.View)}
          IconName="View"
          sx={{ color: "#9A9A9A" }}
          id="btnIdCardView"
        />
        {(dr.IsReserved === 0 || (dr.IsReserved === 1 && Roles[0] === StandardConst.SystemRole.SuperAdmin)) && (
          <>
            <ActionButton
              onClick={() => fnAddEdit(dr.InvoiceSettingId, StandardConst.FormMode.Edit)}
              IconName="Edit"
              id="btnIdCardView"
            />
            <ActionButton
              onClick={() => invoiceSettingDelete(dr.InvoiceSettingId)}
              IconName="Delete"
              id="btnIdCardView"
            />
          </>
        )}
      </div>

    ),
  });

  return (
    <>
      <StaticListComponent
        records={state}
        columns={Columns}
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderValueStandard}
            imageUrl={StandardConst.imageNoRecordsFound}
          />
        }
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        isSearchRequired={true}
        onAddEvent={() => fnAddEdit(0, StandardConst.FormMode.Add)}
        IsAddButtonVisible={true}
        allowSerialNo={true}
      ></StaticListComponent>
    </>
  );
};

export default memo(InvoiceSettingsListComponent);

import Modal from "react-bootstrap/Modal";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import TableComponent from "../../Services/TableComponent";
import { findIndex, map, without } from "underscore";
import { CheckRadioInput } from "../Form";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import { WebService } from "../../Services/WebService";

const AssignProjectToUserComponent = ({}, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [userId, setUserId] = useState(0);
  const [assigned, setAssigned] = useState([]);
  const fetchProjects = async (CustomerId) =>
    await WebService({
      dispatch,
      endPoint: `CommonUtility/projectmaster?${generateQueryStringByObject({
        where: `CustomerId eq ${CustomerId}`,
        select: "ProjectId, ProjectName"
      })}`,
    }).then(setProjectList);
  const fetchAssignedProject = async (UserId) =>
    await WebService({
      dispatch,
      endPoint: `CommonUtility/user_project?${generateQueryStringByObject({
        where: `UserId eq ${UserId}`,
        select: "ProjectId",
      })}`,
    }).then((r) => setAssigned(map(r, (m) => m.ProjectId)));
  useImperativeHandle(ref, () => ({
    openModal: async ({CustomerId, UserId}) => {
      setUserId(UserId);
      setShow(true);
      await Promise.all([fetchProjects(CustomerId), fetchAssignedProject(UserId)]);
    },
  }));
  const handleClose = () => setShow(false);
  const fnAssign = async (dr, isSelected) => {
    // var index = findIndex(projectList, { ProjectId: dr.ProjectId });
    // projectList[index].Selected = isSelected ? 1 : 0;
    var Opt = {
      endPoint: `CommonUtility/user_project`,
      dispatch,
      requiredLoader: false
    };
    if (isSelected) {
      Opt.body = {
        ProjectId: dr.ProjectId,
        UserId: userId,
      };
    } else {
      Opt.method = "DELETE";
      Opt.endPoint += `?${generateQueryStringByObject({
        ProjectId: dr.ProjectId,
        UserId: userId
      })}`;
    }
    var p1 = WebService(Opt)
      .then(() => {
        if(isSelected) setAssigned(d=>([...d,dr.ProjectId]));
        else setAssigned(d=>without(d,dr.ProjectId));
      });
    Promise.all([p1]);
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Project</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <TableComponent
            isSearchRequired={true}
            columns={[
              { Text: "Project", Value: "ProjectName" },
              {
                Text: "Assigned",
                render: (dr) => (
                  <>
                    <CheckRadioInput
                      isRadio={false}
                      value={assigned.filter(f=>f==dr.ProjectId).length>0}
                      setValue={(v) => fnAssign(dr, v)}
                    />
                  </>
                ),
              },
            ]}
            data={projectList}
            allowSerialNo={true}
          ></TableComponent>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default forwardRef(AssignProjectToUserComponent);

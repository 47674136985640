import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Form, FormInputText } from "../../Form";
import { StandardConst } from "../../../Services/StandardConst";
import { WebService } from "../../../Services/WebService";

const { forwardRef, useState, useImperativeHandle } = React;

const AddEditUserType = (prop, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ UserType: {} });

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                setData({ UserType: {} })
            } else {
                setShow(false);
                const data = {
                    UserType: await WebService({
                        endPoint: `CommonUtility/Edit/usertype?UserTypeId=${id || 0}`,
                        dispatch,
                    }).then((c) => (c.length > 0 ? c[0] : {}))
                }
                setData(data);
                setShow(true);
            }
        },
    }));
    const handleClose = () => setShow(false);
    const schema = yup
        .object()
        .shape({
            Name: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        if (data.UserTypeId === undefined) {
            await WebService({
                endPoint: 'CommonUtility/usertype',
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/usertype?UserTypeId=${data.UserTypeId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        prop.callBackEvent();
    };
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data?.UserType?.UserTypeId || 0) === 0
                        ? "Add User Type"
                        : "Edit User Type"}
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.UserType}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Name"
                                name="Name"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id="btnSubmit"
                        variant="outline-primary"
                        type="submit"
                    >
                        {" "}
                        {(data?.UserType?.UserTypeId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default forwardRef(AddEditUserType);

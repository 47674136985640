import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import _ from "underscore";
import { PageInfo } from "../PageInfo";
import { StandardConst } from "../../Services/StandardConst";
const requiredMessage = StandardConst.requiredMessage;
const numberError = StandardConst.numberError;
const mobileMessage = StandardConst.mobileMessage;
const PaymentConfirmation = () => {
  PageInfo({ pageTitle: "Register" });
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  return (
    <>
      <div
        className="container-fluid p-md-1 justify-content-center"
        style={{
          maxWidth: "1000px",
          "padding-bottom": "5em",
          marginTop: "20px",
        }}
      >
        <div class="ibox-content m-b-sm border-bottom">
          <div class="row my-3">
            <div class="col-md-4 text-center">
              <img
                src={`${StandardConst.apiBaseUrl}/uploads/${
                  (CompanyInfo ?? {}).Logo ?? ""
                }`}
                alt="logo"
                className="img-size rounded mx-auto d-block "
              />
              <span className="h6">{CompanyInfo.CompanyName}</span>
            </div>
            <div class="col-md-8 d-flex justify-content-center align-items-start flex-column">
              <h3 class="">Thank you for your subscriptions</h3>
              <div class=" forum-sub-title">
                Welcome to wise office day. We have received your payment.
                Details will be emailed to your registered email id.
              </div>
            </div>
          </div>
        </div>
        <div className="card card0 border-0" style={{ paddingBottom: "50px" }}>
          <div className="d-flex justify-content-center align-items-start flex-column p-4">
            <div class="h4 mb-0 text-primary mt-2">Payment Summary</div>
            <div class="mb-0">
              <span class="fw-bold">Product:</span>
              <span class="c-green">: Name of product</span>
            </div>
            <div class="mb-0">
              <span class="fw-bold">Price:</span>
              <span class="c-green"> $452.90</span>
            </div>
            <div class="mb-0">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Atque
              nihil neque quisquam aut repellendus, dicta vero? Animi dicta
              cupiditate, facilis provident quibusdam ab quis, iste harum ipsum
              hic, nemo qui!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentConfirmation;

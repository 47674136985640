import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { WebService } from "../../Services/WebService";
import * as yup from "yup";
import { Form, FormInputText } from "../Form";
import {
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/esm/Button";
import { PageInfo } from "../PageInfo";
import { WSSuccessAlert } from "../../Services/WSAlert";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, Box, AppBar, Alert, Tooltip, Avatar } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArticleIcon from '@mui/icons-material/Article';
import { AssetsComponent } from '../ProfileComponent/EditProfileComponent';

const CustomerProfile = () => {

  PageInfo({ pageTitle: "User Profile" });

  const CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);


  //Tabs start

  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0, mt: 0 }}>
            <span>{children}</span>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  //Tab End

  return (
    <>
      <Container
        fluid
        className="base-container"
      >
        <Box>
          <AppBar
            position="static"
            color="info"
            elevation={0}
            className="Maintab_Custome mb-2"
            style={{ maxHeight: "40px", height: "40px" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              style={{
                maxHeight: "40px",
                height: "40px",
              }}
              id="tabs"
            >
              <Tab
                icon={<AccountBoxIcon />}
                sx={{ "min-width": "40px !important" }}
                aria-label="General"
                label={window.innerWidth <= 600 ? '' : "Profile"}
                iconPosition="start"
                className="tab_Custome pt-0"
                id="General"
              />
              {(CompanyId > 0) && (
                <Tab
                  icon={<ArticleIcon />}
                  sx={{ "min-width": "40px !important" }}
                  aria-label="Purchase Details"
                  label={window.innerWidth <= 600 ? '' : "Assets"}
                  iconPosition="start"
                  className="tab_Custome pt-0"
                  id="PurchaseDetails"
                />
              )}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          > 
            <TabPanel value={value} index={0} dir={theme.direction}>
              <BasicProfileInformation
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AssetsComponent
              />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Container>
    </>
  );
};


const BasicProfileInformation = () => {

  const [data, setData] = useState({ User: {} });
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCustomer();
  }, []);

  const fetchCustomer = async () => {
    const data = await WebService({
      endPoint: `Customer/FetchUserDetailsOfCustomer`,
      dispatch,
    });
    if (data.length > 0) {
      const usersData = {
        User: data[0]
      }
      if (usersData.User.Phone) {
        usersData.User.Mobile = usersData.User.Phone;
      }
      setData(usersData);
    }
  };


  const schema = yup
    .object()
    .shape({
      FullName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
      Email: yup
        .string()
        .email()
        .required(StandardConst.ValidationMessages.RequiredFieldMsg)
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, StandardConst.ValidationMessages.InvalidEmailMsg),
      Address: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    })
    .required();

  const onSubmit = async (data) => {
    if (data.CustomerId === null) {
      data.Phone = data.Mobile;
      await WebService({
        endPoint: `CommonUtility/users?UserId=${data.UserId}`,
        method: "PUT",
        body: data,
        dispatch
      });
    } else {
      await WebService({
        endPoint: `CommonUtility/customerdetails?CustomerId=${data.CustomerId}`,
        method: "PUT",
        body: data,
        dispatch
      });
    }
    WSSuccessAlert("Success", "Successfully Saved");
  }

  return (
    <>
      <div>
        <Container className="base-container" fluid style={{ width: "98%" }}>
          <div className="row p-0 mb-2 d-flex flex-row">
            <div className="col-md-8">
              {
                Object.keys(data.User).length > 0 && (
                  <Form
                    defaultValues={data.User}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                  >
                    <div className="card shadow-none border-0  border-start footer-widget">
                      <Alert severity="info" sx={{ width: "95%" }}>Basic Information</Alert>
                      <div className="card-body">
                        <div className="row mt-1">
                          <div className="row">
                            {(data?.User?.CustomerId !== null) && (
                              <div className="col-md-12">
                                <FormInputText
                                  name="Name"
                                  label="Customer Name"
                                  type="text"
                                  placeholder="Customer Name"
                                  disabled
                                />
                              </div>
                            )}
                            <div className="col-md-12">
                              <FormInputText
                                name="FullName"
                                label="Full Name"
                                type="text"
                                placeholder="Full Name"
                                isRequired="true"
                              />
                            </div>
                            <div className="col-md-12">
                              <FormInputText
                                name="Email"
                                label="Email"
                                placeholder="Email"
                                type="text"
                                isRequired="true"
                              />
                            </div>
                            <div className="col-md-12">
                              <FormInputText
                                name="Mobile"
                                label="Phone"
                                placeholder="Phone"
                                type="text"
                              />
                            </div>
                            <div className="col-md-12">
                              <FormInputText
                                name="Address"
                                label="Address"
                                placeholder="Address"
                                isRequired="true"
                              />
                            </div>
                            <div className="col-12 text-center mt-4">
                              <Button
                                id="buttonSubmit"
                                type="submit"
                                variant="outline-primary"
                                className="px-4 float-end mt-4 mb-1"
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
export default CustomerProfile;

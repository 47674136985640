import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./HolidayList.css";
import * as yup from "yup";
import axios from "axios";
import FooterComponent from "../Layout/FooterComponent";
import validator from "validator";
import { WebService } from "../../Services/WebService";
import { format } from "date-fns";
import { StandardConst } from "../../Services/StandardConst";
import { PageInfo } from "../PageInfo";
import { Box } from "@mui/material";
import { Form, FormInputText } from "../Form";

const HolidayList = () => {
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  PageInfo({ pageTitle: "Holiday List" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [selectedHolidayYear, setSelectedHolidayYear] = useState(new Date().getFullYear());
  const fetchStatus = async () => {
    const data = await WebService({
      endPoint: `Holiday/FetchUA?CompanyId=${(CompanyInfo ?? {}).CompanyId}&Year=${selectedHolidayYear}`,
      dispatch,
    });
    setRecords(data);
  };
  const renderAfterCalled = useRef(false);
  useEffect(() => {
    if (((CompanyInfo ?? {}).CompanyId ?? 0) != 0) fetchStatus();
  }, [CompanyInfo, selectedHolidayYear]);

  return (
    <>
      <div class="container pb-5">
        <div class="row">
          <div class="col-lg-12">
            <div class="wrapper wrapper-content animated fadeInRight">
              <div class="ibox-content m-b-sm py-3 row">
                <div class="col-md-3 col-12">
                  <div class="">
                    <img
                      src={`${StandardConst.apiBaseUrl}/uploads/${
                        (CompanyInfo ?? {}).Logo ?? ""
                      }`}
                      alt="logo"
                      className="img-size"
                    />
                  </div>
                </div>
                <div class="col-md-9 col-12">
                  <div className="row">
                    <div className="col-6 col-md-3">
                      <h2>Holiday List - </h2>
                    </div>
                    <div className="col-6 col-md-2">
                      <Form>
                        <FormInputText
                            name="Year"
                            type="dateYear"
                            portalId="root"
                            isRequired="true"
                            value={`${selectedHolidayYear}-01-01`}
                            setValue={(val) => setSelectedHolidayYear(new Date(val).getFullYear())}
                        />
                      </Form>
                    </div>
                  </div>
                  <div class="forum-sub-title">
                    List of period of time set aside for festivals or recreation
                  </div><br />
                </div>
              </div>

              <div class="ibox-content forum-container pb-5 row">
                <div class="forum-title">
                  <div class="pull-right forum-desc">
                    <samll>Total Holiday: {records.length}</samll>
                  </div>
                  <h3>Calendar of {selectedHolidayYear}</h3>
                </div>
                {records.length === 0 && (
                  <>
                    <Box
                      className="p-md-5"
                      sx={{
                        backgroundColor: "#F8F9FA",
                      }}
                    >
                      <div class="error-template">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <div>
                            <img
                              src={`${StandardConst.apiBaseUrl}/${
                                StandardConst.imageNoRecordsFound ?? ""
                              }`}
                              class="norecord-image"
                              alt="logo"
                            />
                          </div>
                          <div>
                            <h3>No Holiday Details Found</h3>
                            <div class="error-details">
                              No holiday details found
                            </div>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </>
                )}
                {records.map((data) => (
                  <div class="forum-item active">
                    <div class="row">
                      <div class="col-md-1">
                        <div class="widget-49-title-wrapper">
                          <div class={`widget-49-date-${data.HolidayWeekDay}`}>
                            <span class="widget-49-date-day">
                              {" "}
                              {format(new Date(data.HolidayDate), "dd")}{" "}
                            </span>
                            <span class="widget-49-date-month">
                              {format(new Date(data.HolidayDate), "MMM")}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="forum-item-title">{data.HolidayName}</div>
                        <div class="forum-sub-title">
                          {data.HolidayComments}
                        </div>
                      </div>

                      <div class="d-flex align-items-center justify-content-start col-md-1 forum-info">
                        <span class="forum-day">{data.HolidayWeekDay}</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-end col-md-2">
                        <span class=""> {data.HolidaySaka}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom">
        <FooterComponent />
      </div>
    </>
  );
};

export default HolidayList;

import React, { memo, useEffect, useRef } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { WebService } from "./Services/WebService";
import { setCompanyInfo } from "./Services/authSlice";
import Cookies from "universal-cookie";

const Auth = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const fetchAndSetCompanyInfo = () => {
    const domain=window.location.href.toString().toLowerCase().replaceAll("https://","").replaceAll("http://","").replaceAll("index.html","").split("/#/")[0];
    let companyCode=searchParams.get("company")??"";
    if(companyCode==="undefined") companyCode="";

    const endPoint = `CompanyProfile?select=companyinformation.*,staticcurrency.Symbol,staticcurrency.CurrencyCode
    &expand=staticcurrency&where=${
      companyCode != ""
        ? `Code eq '${companyCode}'`
        : `LOWER(ifnull(DomainName,'')) eq '${domain}'`
    }`;
    WebService({
      //dispatch,
      endPoint,
    }).then((c) => {
      if (c.length > 0) {
        dispatch(setCompanyInfo(c[0]));
        cookies.set("company", JSON.stringify(c[0]), { path: "/" });
      } else {
        navigate("/");
      }
    });
  };
  useEffect(() => {
    fetchAndSetCompanyInfo();
  }, [searchParams]);
  // const navigate = useNavigate();
  // const loged=useSelector((state) => state.auth.loged);
  // useEffect(()=>{
  //   alert(loged);
  // },loged);
  return (
    <>
      <Outlet />
    </>
  );
};
export default memo(Auth);

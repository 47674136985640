import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditRolePermission from "./AddEditRolePermission";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/Button";

const RolePermission = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Manage Permissions for Role" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Role Permission - Add"),
      ManageDelete: ActionPermission("Role Permission - Delete"),
    });
  }, [permissionList]);
  
  const fetchRolePermission = async () => {
    const data = await WebService({
      endPoint: "RolePermission/Fetch",
      dispatch,
    });

    setRecords(data);
  };

  const onDelete = async (dr) => {
    await WebService({
      endPoint: `RolePermission/${dr.RolePermissionId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchRolePermission();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchRolePermission();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Role Name",
      Value: "RoleName",
    },
    {
      Text: "Permission",
      Value: "Permission",
    },
    {
      Text: "Permission Description",
      Value: "PermissionDescription",
    },
    // {
    //   Text: "Action",
    //   cssClass: "text-center td-width-100",
    //   isVisiable: permission.ManageDelete,
      // render: (dr) => (
      //   <>
      //     <ActionButton
      //       onClick={(e) =>
      //         ref.current.confirmAlert(
      //           "Delete", //Confirm button text
      //           "Are You Sure", // Text if Alert
      //           "Do you want to delete " + MasterPageName, // Message of Alert
      //           dr // Endpoint to hit for delete
      //         )
      //       }
      //       disabled={!permission.ManageDelete}
      //       IconName="Delete"
      //       id={`btnRolePermissionDelete_${dr.RolePermissionId}`}
      //     />
      //   </>
      // ),
    // },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Security Management",
      hrefLink: "#",
    },
    {
      title: "Manage Permissions for Role ",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Manage Permissions for Role";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Role Permissions";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.ManagePermissionsheaderValueNoResults}
            subHeaderValue={StandardConst.ManagePermissionsSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            // actionButton={
            //   <>
            //     {permission.ManageAdd && (
            //       <Button variant="outline-primary" onClick={() => fnEdit(0)}>
            //         Add {AddButtonText}
            //       </Button>
            //     )}
            //   </>
            // }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={false}
        // IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditRolePermission
        callBackEvent={() => fetchRolePermission()}
        ref={addEditModalRef}
      ></AddEditRolePermission>
    </>
  );
};
export default RolePermission;

import { DateTime } from "luxon";
import React, {
  forwardRef,
  isValidElement,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useDispatch, useSelector } from "react-redux";
import _, {
  extend,
  filter,
  find,
  map,
  mapObject,
  omit,
} from "underscore";
import { InputDropdown } from "../Component/Form";

import {
  TablePagination,
  Box,
  Grid,
  Chip,
} from "@mui/material";
import AppbarComponent from "./AppbarComponent";
import { contentSearch, dataClone } from "./UtilityService";
import { StandardConst } from "./StandardConst";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
// import IconButton from '@mui/material/IconButton';
import HtmlFileReader from "./HtmlFileReader";
import { format } from "date-fns/esm";
import Frame from 'react-frame-component';

const pageOptions = [20, 40, 60, 80];
const TableComponent = forwardRef(
  (
    {
      data,
      columns,
      excelExportFileName,
      allowSerialNo,
      noRecordCss,
      onAddEvent,
      IsAddButtonVisible,
      isSearchRequired,
      title,
      toExpend,
      children,
      noRecordFound,
      pageSize,
      isExpandButtonVisiable,
      headerCss,
      tableCss,
      initialSearchContent,
      BackBtnReturnPath,
      isExcelExportButtonVisible,
      hideDefaultAddLabel
    },
    ref
  ) => {
    hideDefaultAddLabel ??= false;
    isExcelExportButtonVisible ??= true;
    initialSearchContent ??= "";
    headerCss ??= "";
    tableCss ??= "";
    BackBtnReturnPath ??= "";
    isExpandButtonVisiable ??= true;
    pageSize ??= pageOptions[0];
    noRecordFound ??= "Record not found";
    const roundOf = (num, position) => {
      return (
        Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
        Math.pow(10, 2)
      );
    };
    const navigate = useNavigate();
    toExpend ??= (_) => {};
    const isExpendable = isValidElement(children);
    allowSerialNo ??= false;
    noRecordCss ??= "";
    IsAddButtonVisible ??= (onAddEvent || null) !== null;
    title ??= "";
    const groupByInitOpt = { value: "", text: "None" };
    const dispatch = useDispatch();
    const [dataSet, setDataSet] = useState([]);
    const [expeandableItem, setExpeandableItem] = useState({});
    const [groupByOpt, setGroupByOpt] = useState([groupByInitOpt]);
    const [searchContent, setSearchContent] = useState(initialSearchContent);
    const [currentGroupByValue, setCurrentGroupByValue] =
      useState(groupByInitOpt);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);
    const renderAfterCalled = useRef(false);
    useEffect(() => {
      if (!renderAfterCalled.current) {
        GroupbyOptionBinding();
      }
      renderAfterCalled.current = true;
    }, []);
    useEffect(() => {
      setPage(1);
    }, [searchContent]);
    useEffect(() => {
      InitDataBinding();
    }, [data, searchContent, page]);
    useEffect(() => {
      setExpeandableItem({});
    }, [data]);
    useEffect(() => {
      if (page === 1) InitDataBinding();
    }, [rowsPerPage]);
    const GroupbyOptionBinding = () => {
      var x1 = _.filter(
        columns,
        (f) =>
          (f.IsGroupByFeature ?? false) === true &&
          (f.isVisiable ?? true) === true
      );
      x1 = _.map(x1, (m) => {
        return {
          value: m.Value ?? m.Text,
          text: m.Text,
        };
      });
      setGroupByOpt(groupByOpt.concat(x1));
    };
    const [exportData, setExportData] = useState([]);
    const InitDataBinding = () => {
      var ds = map(data ?? [], (m1, index) => {
        return mapObject(extend(m1, { vartualId: index + 1 }), (val, key) => {
          var result = find(
            columns,
            (f) => (f.Value ?? f.Text) === key && (f.DateFormat ?? "") !== ""
          );
          if (result && val != null)
            val = DateTime.fromISO(val).toFormat(result.DateFormat);
          return val;
        });
      });

      if ((searchContent ?? "") != "") {
        var searchableColumns =
          filter(columns ?? [], (f) => (f?.Searchable ?? true) == true) ?? [];
        searchableColumns =
          map(searchableColumns, (obj) => obj.Value ?? obj.Text) ?? [];
        ds = filter(ds ?? [], (obj) => {
          var a1 = filter(
            searchableColumns,
            (col) => contentSearch(obj[col], searchContent)
          );
          return a1.length > 0;
        });
      }
      setExportData(ds);
      setCountOfTotalRecords(ds.length);
      if (rowsPerPage > 0 && page > 0) {
        var skipValue = rowsPerPage * (page - 1);
        var takeValue = rowsPerPage * page;
        ds = filter(
          map(ds, (r, i) => extend(r, { index: i })),
          (f) => f.index > skipValue - 1 && f.index < takeValue
        );
      }
      setDataSet(ds);
    };
    const GroupBySumation = (ds, col) => {
      switch (col.GroupByResult) {
        case "Summation": {
          return (
            <>
              {_.reduce(
                ds,
                (memo, cur) => roundOf(memo + cur[col.Value ?? col.Text], 2),
                0
              )}
            </>
          );
        }
        default:
          return <></>;
      }
    };
    const [countOfTotalRecords, setCountOfTotalRecords] = useState(0);
    const itemExpendedStatus = (dr) =>
      expeandableItem?.vartualId == dr?.vartualId;
    const [pageSizeOptions, setPageSizeOptions] = useState([]);
    useEffect(() => {
      var options = dataClone(pageOptions);
      if (countOfTotalRecords > 0) {
        options = filter(options, (f) => f <= countOfTotalRecords);
      }
      setPageSizeOptions(options);
    }, [countOfTotalRecords]);
    //#region Expand Event
    const expandClickEvent = (dr) => {
      if (!itemExpendedStatus(dr)) {
        setExpeandableItem(dr);
        toExpend(omit(dr, "vartualId"));
      } else setExpeandableItem({});
    };
    useImperativeHandle(ref, () => ({
      expandClickEvent,
    }));
    //#endregion
    //#region Last page last data delete then page move to previous page
    useEffect(() => {
      setTimeout(() => {
        if (
          (countOfTotalRecords ?? 0) > 0 &&
          (page ?? 1) > 1 &&
          (rowsPerPage ?? 0) > 0
        ) {
          const s1 = countOfTotalRecords % rowsPerPage;
          let s2 = (countOfTotalRecords - s1) / rowsPerPage;
          if (s1 > 0) s2 += 1;
          if (page > s2) setPage(s2);
        }
      });
    }, [countOfTotalRecords, page, rowsPerPage]);
    //#endregion
    const pagination = (
      <TablePagination
        component="div"
        count={countOfTotalRecords}
        page={page - 1}
        onPageChange={(event, newPage) => setPage(newPage + 1)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={pageSizeOptions}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(1);
        }}
      />
    );

    const getBadgeTypeText = (badgeTypeValue) => {
        const match = (StandardConst.BadgeType).find((type) => type.value === badgeTypeValue);
        return match ? match.text : " ";
    };

    const getBadgeTemplateOrientationText = (value) => {
        const match = (StandardConst.OrientationVisitorBadge).find((TemplateOrientation) => TemplateOrientation.value === value);
        return match ? match.text : " ";
    };

    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const VisitorAndEventDummyData = {
        Name: "Luna Rosewood",
        Photo: `${StandardConst.ProjectImagesInPublicAssets}/SampleVisitorBadge-BadgeTemplateCardDisplay.jpg`,
        VisitorType: "Business Owner",
        Company: "Enchanted Gardens Co.",
        Email: "luna.rosewood@example.com",
        Phone: "+1 (555) 123-4567",
        Logo: CompanyInfo.Logo,
        Address: "123 Enchanted Lane, Fairyland, Wonderland",
        Designation: "Botanical Consultant",
        VisitorId: 1,
        CheckInTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        VisitorGuid: "123"
    }

    const EmployeeIdCardDummyData = {
        CompanyName: CompanyInfo?.CompanyName,
        Logo: CompanyInfo?.Logo,
        ProfileImage: `${StandardConst.ProjectImagesInPublicAssets}/SampleBadgeEmployeeIdCard-ManageBadgeTemplates.jpg`,
        FullName: "Amelia Brooks",
        EmployeeCode: "EMP2023-456",
        PermanentAddress: "123 Main Street, New York, USA",
        Email: "amelia.brooks@example.com",
        Designation: "Marketing Manager",
        Phone: "+1 (555) 123-4567",
        Address: "456 Elm Avenue, New York, USA",
        CommunicationAddress: "Address: Same as Permanent Address",
        CompanyPhone: "+1 (555) 987-6543",
        EmployeeGuid: "123456",
    }

    return (
      <>

        {/* ye condition is liye kr rhe hai ki jisme add button ayega usme blue bar me search or add button dikhega or Excel ka button second row me ayega or agar jisme add button nahi hoga usme search or excel button dono sath me hoga */}
        {(IsAddButtonVisible === true || (onAddEvent || null) !== null) && (
          <>
            <AppbarComponent
              title={title}
              hideDefaultAddLabel={hideDefaultAddLabel}
              isSearchRequired={isSearchRequired}
              isAddButtonRequired={IsAddButtonVisible}
              setSearchContent={(v) => setSearchContent(v ?? "")}
              searchContent={searchContent}
              onAddEvent={onAddEvent}
              exportData={exportData}
              columns={columns}
              excelExportFileName={excelExportFileName}
              isExcelExportButtonVisible={isExcelExportButtonVisible}
            />
          </>
        )}

        {(IsAddButtonVisible === false && (onAddEvent || null) === null) && (
          <Row className="p-1 m-0">
              <Col className="col-12 p-0">
                  <AppbarComponent
                    title={title}
                    hideDefaultAddLabel={hideDefaultAddLabel}
                    isSearchRequired={isSearchRequired}
                    isAddButtonRequired={IsAddButtonVisible}
                    setSearchContent={(v) => setSearchContent(v ?? "")}
                    searchContent={searchContent}
                    onAddEvent={onAddEvent}
                    exportData={exportData}
                    columns={columns}
                    excelExportFileName={excelExportFileName}
                    isExcelExportButtonVisible={isExcelExportButtonVisible}
                  />
              </Col>
          </Row>
        )}

        <Row
          className={`d-flex bg-light mx-1 ${
            (dataSet ?? []).length < 1 ? "d-none" : ""
          }`}
        >
          {_.filter(
            columns,
            (f) =>
              (f.IsGroupByFeature ?? false) === true &&
              (f.isVisiable ?? true) === true
          ).length > 0 && (
            <Col className="col-md-4 my-3 float-start">
              <div className="d-flex align-items-start">
                <InputDropdown
                  ddOpt={groupByOpt}
                  value={currentGroupByValue.value}
                  setValue={(v) =>
                    setCurrentGroupByValue(
                      _.findWhere(groupByOpt, { value: v })
                    )
                  }
                  label="Group By"
                  name="GroupBy"
                />
              </div>
            </Col>
          )}
        </Row>

        <div className="table-responsive p-2" style={{ overflow: "none" }}>
            <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {dataSet.map((item, index) => (
                    <Grid item xs={12} sm={4} md={4} key={index}>
                        <Card sx={{ maxWidth: 345 }}>
                            <div className="d-flex justify-content-center align-items-center">
                              <Frame style={{ width: `${item.BadgeTemplateWidth+2}mm`, height: `${item.BadgeTemplateHeight+8}mm`}}>
                                <HtmlFileReader 
                                    key={item.badge_templatesId}
                                    jsonData={(item.badge_type === StandardConst.BadgeType[1].value) ? EmployeeIdCardDummyData : VisitorAndEventDummyData} 
                                    defaultTemplate={{
                                        text: item.badge_template_name, 
                                        value: item.badge_templatesId,
                                        file: item.html_file_path
                                    }} 
                                    ShowDropdown={0} 
                                    ShowPrintBadgeBtn={0} 
                                    ShowDownloadBadgeBtn = {0}
                                    SelectedTemplateId={{
                                        text: item.badge_template_name, 
                                        value: item.badge_templatesId,
                                        file: item.html_file_path
                                    }}
                                    selfVisitorBadge={StandardConst.ShowOrHide.Hide}
                                    showDropDownList={StandardConst.BadgeType[0].text}
                                    fullImagePathGiven={true}
                                />
                              </Frame>
                            </div>
                            <CardContent>
                                <span>{item.badge_template_name}</span>  &nbsp;&nbsp; {(item.isReserved === StandardConst.YesNo.Yes) && (
                                <Chip 
                                    label="Reserved"
                                    sx={{ color: "white", padding: "0px"}}
                                    color= "primary"
                                    size="small"
                                />
                                )}

                                <p>{getBadgeTypeText(item.badge_type)}</p>
                                <p>{item.BadgeTemplateHeight} X {item.BadgeTemplateWidth} mm</p>
                                <Chip
                                    label={getBadgeTemplateOrientationText(item.BadgeTemplateOrientation)}
                                    color={item.BadgeTemplateOrientation === StandardConst.OrientationVisitorBadge[0].value ? 'primary' : "warning"}
                                    variant='filled'
                                    sx={{borderRadius: "3px" }}
                                />
                            </CardContent>
                            <CardActions>
                                {(columns.find(column => column.Text === "Action")?.render !== undefined) ? columns.find(column => column.Text === "Action")?.render(item) : ""}
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
        {rowsPerPage > 0 &&
          countOfTotalRecords > 0 &&
          countOfTotalRecords > rowsPerPage &&
          pagination}

        {(BackBtnReturnPath !== "") && (
          <div className="row d-flex justify-content-center">
            <div className="col-12 text-center p-2">
              <Button variant="outline-primary" onClick={() => {navigate(BackBtnReturnPath)}}>
                <ArrowBackIcon /> {(BackBtnReturnPath === StandardConst.ReturnPagePaths.ActionCenter) ? "Back to Action Center" : "Back"}
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
);

export const NoRecordTemplate = memo(
  ({ headerValue, subHeaderValue, imageUrl, actionButton, cssClass}) => {
    headerValue ??= "";
    subHeaderValue ??= "";
    imageUrl ??= "";
    actionButton ??= null;
    cssClass ??= "p-md-5";
    return (
      <>
        <Box
          className={cssClass}
          sx={{
            backgroundColor: "#F8F9FA",
          }}
        >
          <div className="error-template">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div>
                {imageUrl !== "" && (
                  <img
                    src={`${StandardConst.apiBaseUrl}/${imageUrl}`}
                    className="norecord-image"
                    alt="logo"
                  />
                )}
              </div>
              <div>
                <h3>{headerValue}</h3>
                <div className="error-details">
                  {subHeaderValue !== "" && <span>{subHeaderValue}</span>}
                </div>
                <div className="error-actions">
                  {isValidElement(actionButton) && actionButton}
                </div>
              </div>
            </div>
          </div>
        </Box>

        {/* <Container sx={{ height: "200px" }}>
        <h1>{headerValue}</h1>
        {subHeaderValue !== "" && <h2>{subHeaderValue}</h2>}
        {imageUrl !== "" && (
          <img
            src={`${StandardConst.apiBaseUrl}/${imageUrl}`}
            className="logo"
            alt="logo"
          />
        )}
        {isValidElement(actionButton) && actionButton}
      </Container> */}
      </>
    );
  }
);

export default memo(TableComponent);

import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditEmployeeKRA from "./AddEditEmployeeKRA";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import _, { map, sortBy } from "underscore";
import TableComponent, {
  NoRecordTemplate,
} from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { Form, FormInputDropdown, InputDropdown } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Box, Container } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
const EmployeeKRA = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  const [appData, setAppData] = React.useState({
    searchPlaceHolder: "Search Job Function",
    addMenuTitle: "Add Job Function",
  });
  PageInfo({ pageTitle: "Employee KRA" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: true,
      ManageEdit: true,
      ManageDelete: true,
      ManageSearchAllUser: ActionPermission("Employee KRA - Search All User"),
    });
  }, [permissionList]);

  const fetchEmployeeJobFunction = async () => {
    const data = await WebService({
      endPoint: `JobFunction/FetchEmployeeKRA/${selectedEmployee}`,
      dispatch,
    });
    setRecords(sortBy(data, (s) => s.FullName));
    dispatch(ConfigurationflowStatusUpdate());
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  const MasterPageName = "Employee KRA";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Employee KRA";

  const childUser = useSelector((s) => s.auth.ChildUsers ?? []);
  const [persons, setPersons] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(0);

  const onDelete = async (EmpSpecificKRAId) => {
    await WebService({
      endPoint: `CommonUtility/otheremployeesspecifickra?EmpSpecificKRAId=${EmpSpecificKRAId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    fetchEmployeeJobFunction();
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchPersonList();
    }
    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (selectedEmployee !== 0) {
      fetchEmployeeJobFunction();
    }
  }, [selectedEmployee]);
  const columns = [
    {
      Text: "KRA",
      Value: "KRAShortDescription",
    },
    {
      Text: "KRA Description",
      Value: "KRADescription",
      cssClass: "w-75",
    },
    {
      Text: "Action",
      cssClass: "text-end td-width-100",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <div className="d-flex">
          <ActionButton
            onClick={() => fnEdit(dr.EmpSpecificKRAId, selectedEmployee)}
            IconName="Edit"
            disabled={dr.EmpSpecificKRAId == null}
            id="btnManageJobFunctionEdit"
          />

          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                dr.EmpSpecificKRAId // Endpoint to hit for delete
              )
            }
            disabled={dr.EmpSpecificKRAId == null}
            IconName="Delete"
            id="btnRoleDelete"
          />
        </div>
      ),
    },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Employee",
      hrefLink: "#",
    },
    {
      title: "Employee KRA",
      hrefLink: "#",
    },
  ]);

  const fetchPersonList = async () => {
    const childUsers = await WebService({ dispatch, endPoint: "User/LoggedUser" }).then((r) => {
      let resp;
      if((r.EmployeeId??0)!==0){
        resp = WebService({ dispatch, endPoint: `User/ChildUsers/${r.EmployeeId}` }).then((cu) => {
          return cu;
        });
      }
      return resp;
    });

    var opt = {
      endPoint: `CommonUtility/employees?where=StatusId eq ${StandardConst.Status.Approved}&select=EmployeeId, FullName`,
      dispatch,
    };
    let res = await WebService(opt)
      .then((r) => sortBy(r, "FullName"))
      .then((r) =>
        map(r, (m) => ({
          value: m.EmployeeId,
          text: m.FullName,
        }))
      );
    if(!permission.ManageSearchAllUser){
      res = res.filter((f) => childUsers.filter((u) => u === f.value).length > 0);
    }
    setPersons(res);
    setSelectedEmployee(res[0].value);
  };

  const fnEdit = async (id, selectedEmployee) =>
    await addEditModalRef.current.openModal(id || 0, selectedEmployee);
  return (
    <>
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <h3 className="ms-4 mt-2">{MasterPageName}</h3>
          <div className="ms-4 mb-2">
            <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
          </div>
        </Box>

        <Box
          sx={{
            width: 1,
          }}
        >
          <div
            className="pt-2 pb-2 px-4"
            style={{ backgroundColor: "#eee" }}
          >
            <Row className=" d-flex justify-content-end">
            <Col className="col-md-2 col-12">
            Select Team Member
              </Col>
              <Col className="col-md-2 col-12">
                <InputDropdown
                  name="EmployeeId"
                  ddOpt={persons ?? []}
                 
                  labelCss="mt-2"
                  value={selectedEmployee}
                  setValue={(v) => setSelectedEmployee(parseInt(v))}
                />
              </Col>
            </Row>
          </div>
        </Box>

        <TableComponent
          title={AddButtonText}
          columns={columns}
          data={records}
          MasterPageName={MasterPageName}
          noRecordCss="p-0"
          noRecordFound={
            <NoRecordTemplate
              headerValue={StandardConst.headerValueNoResults}
              subHeaderValue={StandardConst.subHeaderRole}
              imageUrl={StandardConst.imageNoRecordsFound}
            />
          }
          onAddEvent={selectedEmployee ? () => fnEdit(0, selectedEmployee) : null}
          IsAddButtonVisible={selectedEmployee ? true : false}
          isSearchRequired={true}
          allowSerialNo={true}
        />
      </Container>

      <AddEditEmployeeKRA
        callBackEvent={() => fetchEmployeeJobFunction()}
        ref={addEditModalRef}
      ></AddEditEmployeeKRA>
    </>
  );
};
export default EmployeeKRA;

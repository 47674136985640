import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { WebService } from '../../Services/WebService';
import { StandardConst } from '../../Services/StandardConst';
import Swal from "sweetalert2";
import { generateRandomPassword } from '../../utils/CommonUtils';

const EventCoordinator = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ EventCoordinator: {} });
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [coordinatorType, setCoordinatorType] = useState(StandardConst.CoordinatorType[0].value);
    const dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                const data = { EventCoordinator: {} };
                setData(data);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            EventCoordinator: await WebService({
                endPoint: `EventCoordinators/SingleEventCoordinator?EventCoordinatorId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const handleClose = () => {
        setShow(false);
        setFullNameDetail("");
        setPhoneDetail(null);
        setAlreadyHadEmployeeId(null);
        setAlreadyHadUserId(null);
    }

    const ShowAlreadyCoordinatorAlert = async(employeeId, userId, EventId) => {

        let endPoint = "";

        if(employeeId){
            endPoint = `EventCoordinators/checkIfCoordinatorAlreadyExist?EmployeeId=${employeeId}&EventId=${EventId}`;
        }else if(userId){
            endPoint = `EventCoordinators/checkIfCoordinatorAlreadyExist?UserId=${userId}&EventId=${EventId}`;
        }else {
            return [];
        }

        const res = await WebService({
            endPoint: endPoint,
            dispatch,
            requiredLoader: false,
        }).then((c) => {
            return (c.data.length > 0 ? c.data : [])
        });

        if(res.length > 0){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "mx-1 w-100 btn btn-outline-success",
                    cancelButton: "mx-1 btn btn-outline-danger",
                },
                buttonsStyling: false,
            });
    
            swalWithBootstrapButtons.fire({
                title: "Oops!",
                text: "You are already event Coordinator",
                icon: "warning",
                reverseButtons: true,
                customClass: {
                    confirmButton: "mx-1 btn btn-outline-success",
                    cancelButton: "mx-1 btn btn-outline-danger",
                },
                buttonsStyling: false,
            });
            return res;
        }else if(res.length === 0){
            return [];
        }
    }

    const [fullNameDetail, setFullNameDetail] = useState("");
    const [phoneDetail, setPhoneDetail] = useState(null);
    const [alreadyHadEmployeeId, setAlreadyHadEmployeeId] = useState(null);
    const [alreadyHadUserId, setAlreadyHadUserId] = useState(null);
    const onSubmit = async (data) => {
        data.EventId = props.EventId;
        data.CompanyId = companyInfo.CompanyId;
        if (data.FullName === null) data.FullName = fullNameDetail;
        if (data.Phone === null) data.Phone = phoneDetail;
        const randomPassword = generateRandomPassword(8);
        data.Password = randomPassword;
        const notFoundCoordinator = await ShowAlreadyCoordinatorAlert(alreadyHadEmployeeId, alreadyHadUserId, props.EventId);
        if(notFoundCoordinator.length === 0 ){
            if (Number(data.CoordinatorType) === StandardConst.CoordinatorType[0].value) {
                if (data.EventCoordinatorId === undefined) {
                    if (!alreadyHadEmployeeId) {
                        await WebService({
                            endPoint: `Subscription/getActiveCompanySubscription`,
                            dispatch
                        });
                        const empId = await WebService({
                            endPoint: `CommonUtility/employees?register=success`,
                            body: data,
                            dispatch,
                        });
                        data.EmployeeId = empId;
                    } else {
                        data.EmployeeId = alreadyHadEmployeeId;
                    }
                    if(data.EmployeeId){
                        // employee ke case me isko employee or Event coordinator dono hi banana hai
                        data.RoleId = StandardConst.SystemRole.Employee;
                        await WebService({
                            endPoint: `CommonUtility/userroles`,
                            body: data,
                            dispatch,
                        });

                        data.RoleId = StandardConst.SystemRole.EventCoordinator;
                        await WebService({
                            endPoint: `CommonUtility/userroles`,
                            body: data,
                            dispatch,
                        });
                    }
                    await WebService({
                        endPoint: `CommonUtility/eventcoordinators`,
                        body: data,
                        dispatch,
                    });

                    await WebService({
                        endPoint: `EventCoordinators/SendMailForCoordinator?SendMailForEmployeeCoordinator=true`,
                        body: data,
                        dispatch,
                    });

                } else { }
            } else {
                if (data.EventCoordinatorId === undefined) {
                    if (!alreadyHadUserId) {
                        const res = await WebService({
                            endPoint: `CommonUtility/users?register=success`,
                            body: data,
                            dispatch,
                        });
                        data.UserId = res;
                    } else {
                        data.UserId = alreadyHadUserId;
                    }

                    if(data.UserId){
                        data.RoleId = StandardConst.SystemRole.EventCoordinator;
                        await WebService({
                            endPoint: `CommonUtility/userroles`,
                            body: data,
                            dispatch,
                        });
                    }

                    await WebService({
                        endPoint: `CommonUtility/eventcoordinators`,
                        body: data,
                        dispatch,
                    });

                    await WebService({
                        endPoint: `EventCoordinators/SendMailForCoordinator?SendMailForUserCoordinator=true`,
                        body: data,
                        dispatch,
                    });

                } else {
                    const result = await WebService({
                        endPoint: `EventCoordinators/SingleEventCoordinator?EventCoordinatorId=${data.EventCoordinatorId}`,
                        dispatch,
                    }).then((c) => (c.length > 0 ? c[0] : {}));
                    await WebService({
                        endPoint: `CommonUtility/users?UserId=${result.UserId}`,
                        method: "PUT",
                        body: data,
                        dispatch,
                    });
                }
            }
        handleClose();
        props.callBackEvent();
        }
    }

    const requiredMessage = "This is a required field";
    const schema = yup
        .object()
        .shape({
            // FullName: yup.string().trim().required(requiredMessage),
            FullName: data.EventCoordinator.FullName ? null : yup.string().trim().required(requiredMessage),
            Email: yup
                .string()
                .email()
                .required(requiredMessage)
                .matches(
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    "Please enter a valid email"
                ),
            // Phone: yup
            Phone: data.EventCoordinator.Phone ? null : yup
                .number()
                .typeError(requiredMessage)
                .required(requiredMessage)
                .min(1000000000, requiredMessage),
        })
        .required();



    const handleEmailChangeInput = async (emailValue) => {
        if(parseInt(coordinatorType) === StandardConst.CoordinatorType[0].value){
            const employeesRes = await WebService({
                endPoint: `EventCoordinators/checkIfEmployeesAlreadyExist?Email=${emailValue}&CompanyId=${companyInfo.CompanyId}`,
                dispatch,
                requiredLoader: false,
            }).then((c) => (c.data.length > 0 ? c.data : []));

            if (employeesRes.length > 0) {
                setAlreadyHadEmployeeId(employeesRes[0].EmployeeId);
                setAlreadyHadUserId(null);
                setData({ EventCoordinator: { Phone: employeesRes[0].Phone, FullName: employeesRes[0].FullName } })
                setFullNameDetail(employeesRes[0].FullName);
                setPhoneDetail(employeesRes[0].Phone);
            }else {
                setData({ EventCoordinator: {} });
                setFullNameDetail("");
                setPhoneDetail(null);
                setAlreadyHadEmployeeId(null);
            }
        }

        if(parseInt(coordinatorType) === StandardConst.CoordinatorType[1].value){
            
            const res = await WebService({
                endPoint: `EventCoordinators/checkIfUserAlreadyExist?Email=${emailValue}`,
                dispatch,
                requiredLoader: false,
            }).then((c) => (c.data.length > 0 ? c.data : []));

            if (res.length > 0) {
                setAlreadyHadUserId(res[0].UserId);
                setAlreadyHadEmployeeId(null);
                setData({ EventCoordinator: { Phone: res[0].Phone, FullName: res[0].FullName } })
                setFullNameDetail(res[0].FullName);
                setPhoneDetail(res[0].Phone);
            }else {
                setData({ EventCoordinator: {} });
                setFullNameDetail("");
                setPhoneDetail(null);
                setAlreadyHadEmployeeId(null);
            }
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {(data?.EventCoordinator?.EventCoordinatorId || 0) === 0
                            ? "Add Event Coordinator"
                            : "Edit Event Coordinator"}
                    </Modal.Title>
                </Modal.Header>
                <Form
                    defaultValues={data.EventCoordinator}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                >
                    <Modal.Body className="p-4">
                        <div className="row">
                            <div className="col-md-12">
                                <FormInputDropdown
                                    name="CoordinatorType"
                                    ddOpt={StandardConst.CoordinatorType}
                                    label="Coordinator Type"
                                    isRequired="true"
                                    value={data?.EventCoordinator?.EventCoordinatorId ? StandardConst.CoordinatorType[1].value : ""}
                                    setValue={(val) => setCoordinatorType(val)}
                                ></FormInputDropdown>
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Email"
                                    name="Email"
                                    type="text"
                                    isRequired="true"
                                    setValue={(enteredEmail) => { handleEmailChangeInput(enteredEmail) }}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Name"
                                    name="FullName"
                                    type="text"
                                    isRequired="true"
                                    value={data.EventCoordinator.FullName}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Phone"
                                    name="Phone"
                                    type="text"
                                    minLength={10}
                                    maxLength={10}
                                    isRequired="true"
                                    value={data.EventCoordinator.Phone}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id="btnEventSubmit" variant="outline-primary" type="submit">
                            {(data?.EventCoordinator?.EventCoordinatorId || 0) === 0
                                ? "Submit"
                                : "Save Changes"}{" "}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default forwardRef(EventCoordinator);
import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import AddEditAssetCustomField from "./AddEditAssetCustomField";
import { Box, Container, Typography, Stack } from "@mui/material";
import { Form, FormInputDropdown } from '../Form'
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";


const AssetCustomFields = () => {

    const MasterPageName = "Asset Custom Fields";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "Asset Custom Field";
    const ref = useRef();
    const refSnackbar = useRef();
    PageInfo({ pageTitle: "Asset Custom Fields" });
    const dispatch = useDispatch();
    const [records, setRecords] = useState([]);
    const [permission, SetPermission] = useState({});
    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    // const [currentAssetId, setCurrentAssetId] = useState(0);
    const addEditModalRef = useRef();

    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Asset Custom Field - Add"),
            ManageEdit: ActionPermission("Asset Custom Field - Edit"),
            ManageDelete: ActionPermission("Asset Custom Field - Delete"),
        });
    }, [permissionList]);

    const [bData] = React.useState([
        {
            title: "Asset Management",
            hrefLink: "#",
        },
        {
            title: "Asset Custom Fields",
            hrefLink: "#",
        },
    ]);

    const fetchAssetCustomFields = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/assetcustomfields?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
        });
        setRecords(data.sort((a, b) => a.DisplayOrder - b.DisplayOrder));
        dispatch(ConfigurationflowStatusUpdate());
    };

    const [deletedName, setDeletedName] = useState(null);
    const onDelete = async (Details) => {
        const data = Details.split(',');
        const CustomFieldId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/assetcustomfields?CustomFieldId=${CustomFieldId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAssetCustomFields();
    };

    useEffect(() => {
        fetchAssetCustomFields();
    }, []);

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    const handleFieldsOrdring = async (clickedObj, clickedArrow) => {

        const maxOrderNumber = records[records.length - 1].DisplayOrder;

        const minOrderNumber = records[0].DisplayOrder;

        const checkOrder = clickedArrow == StandardConst.ArrowClicked.Up ? clickedObj.DisplayOrder - 1 : clickedObj.DisplayOrder + 1 ;

        if (checkOrder >= minOrderNumber && checkOrder <= maxOrderNumber) {
            const clickedRowIndex = records.findIndex(item =>
                item.CustomFieldId === clickedObj.CustomFieldId);

            const nextUpdateIndex = clickedArrow == StandardConst.ArrowClicked.Up ? clickedRowIndex - 1 : clickedRowIndex + 1;
            const nextUpdatVehicleCustomFieldId = records[nextUpdateIndex].CustomFieldId;
            const nextUpdatedOrderNumber = records[nextUpdateIndex].DisplayOrder;
            
            
            await WebService({
                dispatch,
                endPoint: `AssetAssignment/CustomFieldsDisplayOrder`,
                method: "PUT",
                body: {
                    ClickedUpdatedVehicleCustomFieldId: clickedObj.CustomFieldId,
                    ClickedUpdatedOrderNumber: clickedObj.DisplayOrder,
                    NextUpdatedVehicleCustomFieldId: nextUpdatVehicleCustomFieldId,
                    NextUpdatedOrderNumber: nextUpdatedOrderNumber,
                }
            }).then((response) => {
                if (response > 0) {
                    fetchAssetCustomFields();
                }
            });
        }
    }

    const columns = [
        {
            Text: "Custom Field Label",
            Value: "CustomFieldLabel",
        },
        {
            Text: "Custom Field Name",
            Value: "CustomFieldName",
        },
        {
            Text: "Custom Field Type",
            Value: "CustomFieldType"
        },
        {
            Text: "Custom Field Required",
            Value: "CustomFieldIsRequired",
            render: (dr) => {

                const foundStatus = StandardConst.YesOrNo?.find(element => element?.value === dr.CustomFieldIsRequired);
                return (
                    <>
                        {foundStatus.text}
                    </>
                )
            }
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.CustomFieldId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Custom Field"}
                        id={`btnCustomFieldEdit_${dr.CustomFieldId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.CustomFieldLabel, // Message of Alert
                                `${dr.CustomFieldId},${dr.CustomFieldLabel}`  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Custom Field"}
                        id={`btnCustomFieldDelete_${dr.CustomFieldId}`}
                    />
                    <ActionButton
                        onClick={() => handleFieldsOrdring(dr, StandardConst.ArrowClicked.Up)}
                        IconName="UpArrow"
                        IconTooltip={"Up Arrow"}
                        id={`UpArrow${dr.CustomFieldId}`}
                    />

                    <ActionButton
                        onClick={() => handleFieldsOrdring(dr, StandardConst.ArrowClicked.Down)}
                        IconName="DownArrow"
                        IconTooltip={"Down Arrow"}
                        id={`DownArrow${dr.CustomFieldId}`}
                    />
                </>
            ),
        },
    ]

    return (
        <>
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
                            <div className="ms-4">
                                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                            </div>
                        </div>
                    </div>
                </Box>
                <SnackbarComponent ref={refSnackbar} confirmMessage={`Custom Field ${(deletedName!== null) ? deletedName : ""} Deleted successfully`} />
                <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
                <TableComponent
                    columns={columns}
                    noRecordCss="p-0"
                    noRecordFound={
                        <NoRecordTemplate
                            headerValue={StandardConst.AssetCustomFieldheaderValueNoResults}
                            subHeaderValue={StandardConst.AssetCustomFieldSubHeader}
                            imageUrl={StandardConst.imageNoRecordsFound}
                            actionButton={
                                <>
                                    {permission.ManageAdd && (
                                        <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${AddButtonText}`}>
                                            Add {AddButtonText}
                                        </Button>
                                    )}
                                </>
                            }
                        />
                    }
                    data={records}
                    isSearchRequired={true}
                    title={AddButtonText}
                    onAddEvent={() => fnEdit()}
                    IsAddButtonVisible={permission?.ManageAdd}
                    allowSerialNo={true}
                />
                <AddEditAssetCustomField
                    highestNumberOfOrderDisplay={records[records.length - 1]?.DisplayOrder}
                    callBackEvent={async () => await fetchAssetCustomFields()}
                    ref={addEditModalRef}
                ></AddEditAssetCustomField>
            </Container>
        </>
    );
}

export default AssetCustomFields;
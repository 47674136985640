import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditUserRole from "./AddEditUserRole";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import { confirm } from "react-confirm-box";
import "./Static.css";
import TableComponent from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import StaticListComponent from "../../Services/StaticListComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import SnackbarComponent from "../../Services/SnackbarComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/Button";

const apiUrl = "CommonUtility/userroles";
const UserRole = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Assign Role to Employee" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("User Role - Add"),
    ManageDelete: ActionPermission("User Role - Delete"),
    });
  }, [permissionList]);

  const fetchUserRole = async () => {
    const data = await WebService({
      endPoint: "UserRole",
      dispatch,
    });
    setRecords(data);
  };
  const onDelete = async (UserRoleId) => {
    await WebService({
      endPoint: `${apiUrl}?UserRoleId=${UserRoleId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchUserRole();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchUserRole();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Role Name",
      Value: "RoleName",
    },
    {
      Text: "Full Name",
      Value: "FullName",
    },
    {
      Text: "Action",
      //key: "UserRoleId",
      style: { width: "60px", textAlign: "center", padding: "0px" },
      isVisiable: permission.ManageDelete,
      render: (dr) => (
        <>
          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                dr.UserRoleId // Endpoint to hit for delete
              )
            }
            disabled={
              !permission.ManageDelete ||
              dr.RoleId === StandardConst.SystemRole.Company
            }
            IconName="Delete"
            id="btnUserRoleDelete"
          />
        </>
      ),
    },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Employee",
      hrefLink: "#",
    },
    {
      title: "Assign  Role to Employee",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Assign Role to Employee";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = " Role";

  //const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

  const fnEdit = async (id = 0) =>
    await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.AssignRoleheaderValueNoResults}
            subHeaderValue={StandardConst.AssignRoleSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button id="NoRecordFoundAddRole" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditUserRole
        callBackEvent={() => fetchUserRole()}
        ref={addEditModalRef}
      ></AddEditUserRole>
    </>
  );
};
export default UserRole;

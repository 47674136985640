import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from "../../Services/ActionButton";
import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { Box, Container } from "@mui/material";
import { Form, FormInputDropdown, FormInputText, InputDropdown } from '../Form'
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import { WSSuccessAlert } from "../../Services/WSAlert";
const _ = require("underscore");

const BankDetailsCustomFields = () => {

    const MasterPageName = "Manage Bank Detail Custom Fields";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "New Field";
    const ref = useRef();
    const refSnackbar = useRef();
    PageInfo({ pageTitle: "Manage Bank Detail Custom Fields" });
    const dispatch = useDispatch();
    const [customFieldsData, setCustomFieldsData] = useState([]);
    const [countriesDropdown, setCountriesDropdown] = useState([{ value: 0, text: "All Countries" }]);
    const [companiesDropdown, setCompaniesDropdown] = useState([]);

    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [selectedCountryId, setSelectedCountryId] = useState(null);

    

    const [permission, SetPermission] = useState({});
    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const addEditModalRef = useRef();

    const assignedRole = useSelector((s) => s.auth.AssignRole ?? []);

    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Bank Detail Custom Field - Add"),
            ManageEdit: ActionPermission("Bank Detail Custom Field - Edit"),
            ManageDelete: ActionPermission("Bank Detail Custom Field - Delete"),
        });
    }, [permissionList]);

    const [bData] = React.useState([
        {
            title: "Bank Detail Management",
            hrefLink: "#",
        },
        {
            title: "Manage Bank Detail Custom Fields",
            hrefLink: "#",
        },
    ]);

    const fetchBankDetailCustomFields = async () => {
        const data = await WebService({
            endPoint: `FinanceTemplate/fetchBankDetailByCompanyAndCountry?CompanyId=${(selectedCompanyId === null || selectedCompanyId === StandardConst.YesNo.No) ? 'IS NULL' : selectedCompanyId}&CountryId=${(selectedCountryId === null || selectedCountryId === StandardConst.YesNo.No) ? 'IS NULL' : selectedCountryId}`,
            dispatch,
        });
        setCustomFieldsData(data.sort((a, b) => a.DisplayOrder - b.DisplayOrder));
        dispatch(ConfigurationflowStatusUpdate());
    };

    const [deletedBankDetailCustomFieldLabel, setDeletedBankDetailCustomFieldLabel] = useState(null);
    const onDelete = async (BankDetailCustomFieldDetail) => {
        const BankDetailCustomFieldData = BankDetailCustomFieldDetail.split(',');
        const BankDetailCustomFieldId = BankDetailCustomFieldData[0];
        const BankDetailCustomFieldLabel = BankDetailCustomFieldData[1];
        setDeletedBankDetailCustomFieldLabel(BankDetailCustomFieldLabel);

        await WebService({
            endPoint: `CommonUtility/bankdetailcustomfields?BankDetailCustomFieldId=${BankDetailCustomFieldId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchBankDetailCustomFields();
    };

    const fetchAllCountries = async () => {
        await WebService({
            endPoint: `CommonUtility/fetchAll/countries`,
            dispatch,
        }).then((res) => {
            if(res.length > 0){
                setCountriesDropdown([{ value: 0, text: "All Countries" }, ...res.map(item => ({value: item.CountryId, text: item.Country}))]);
            }
        })
    }

    const fetchAllCompanies = async () => {
        await WebService({
            endPoint: `CommonUtility/fetchAll/companyinformation?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
        }).then((res) => {
            if(res.length > 0){
                var CompaniesList = res.map(item => ({value: item.CompanyId, text: item.CompanyName}));
                
                const supperAdminRole = assignedRole.some(item => item === StandardConst.SystemRole.SuperAdmin);
                if(supperAdminRole){
                    CompaniesList = [{ value: 0, text: "All Companies" }, ...CompaniesList];
                }

                setCompaniesDropdown(CompaniesList);
            }
        })
    }

    useEffect(() => {
        Promise.all([
            fetchAllCountries(),
            fetchAllCompanies()
        ])
    }, [assignedRole]);

    useEffect(() => {
        fetchBankDetailCustomFields();
    }, [selectedCompanyId, selectedCountryId]);

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);


    const handleFieldsOrdring = async (clickedObj, clickedArrow) => {

        // const customFieldsData = filter(invoiceSettingState?.Details, {
        //   Section: clickedObj.Section,
        // })?.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    
        const maxOrderNumber = customFieldsData[customFieldsData.length - 1].DisplayOrder;
    
        const minOrderNumber = customFieldsData[0].DisplayOrder;
    
        const checkOrder = clickedArrow == StandardConst.ArrowClicked.Up ? clickedObj.DisplayOrder - 1 : clickedObj.DisplayOrder + 1 ;

        if(checkOrder >= minOrderNumber && checkOrder <= maxOrderNumber){
          const clickedRowIndex = customFieldsData.findIndex(item => 
            item.BankDetailCustomFieldId === clickedObj.BankDetailCustomFieldId);
    
          const nextUpdateIndex = clickedArrow == StandardConst.ArrowClicked.Up ? clickedRowIndex - 1 : clickedRowIndex + 1;
          const nextUpdateBankDetailCustomFieldId = customFieldsData[nextUpdateIndex].BankDetailCustomFieldId;
          const nextUpdatedOrderNumber = customFieldsData[nextUpdateIndex].DisplayOrder;
    
          await WebService({
            dispatch,
            endPoint: `FinanceTemplate/UpdateOrdringInBankDetailColumnFields`,
            method: "PUT",
            body: {
              ClickedUpdatedBankDetailCustomFieldId: clickedObj.BankDetailCustomFieldId, 
              ClickedUpdatedOrderNumber: clickedObj.DisplayOrder, 
              NextUpdatedBankDetailCustomFieldId: nextUpdateBankDetailCustomFieldId, 
              NextUpdatedOrderNumber: nextUpdatedOrderNumber, 
            }
            // body: clickedObj
          }).then((response) => {
            if(response > 0){
                fetchBankDetailCustomFields();
            }
          });
        }
    };

    const columns = [
        {
            Text: "Field Label",
            Value: "FieldLabel",
        },
        {
            Text: "Field Name",
            Value: "FieldName",
        },
        {
            Text: "Field Type",
            Value: "FieldType"
        },
        {
            Text: "Field Required",
            render: (dr) => <span>{StandardConst.YesOrNo?.find(element => element?.value === dr.FieldIsRequired).text}</span>
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.BankDetailCustomFieldId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Custom Field"}
                        id={`btnCustomFieldEdit_${dr.BankDetailCustomFieldId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.FieldLabel, // Message of Alert
                                `${dr.BankDetailCustomFieldId},${dr.FieldLabel}` // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete custom fields"}
                        id={`btnCustomFieldDelete_${dr.BankDetailCustomFieldId}`}
                    />

                    <ActionButton
                        onClick={() => handleFieldsOrdring(dr, StandardConst.ArrowClicked.Up)}
                        // disabled={!permission.ManageEdit}
                        IconName="UpArrow"
                        IconTooltip={"Up Arrow"}
                        id={`UpArrow${dr.BankDetailCustomFieldId}`}
                    />

                    <ActionButton
                        onClick={() => handleFieldsOrdring(dr, StandardConst.ArrowClicked.Down)}
                        // disabled={!permission.ManageEdit}
                        IconName="DownArrow"
                        IconTooltip={"Down Arrow"}
                        id={`DownArrow${dr.BankDetailCustomFieldId}`}
                    />
                </>
            ),
        },
    ]

    return (
        <>
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
                            <div className="ms-4">
                                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                            </div>
                        </div>
                    </div>
                </Box>
                <SnackbarComponent ref={refSnackbar} confirmMessage={`Custom Field ${(deletedBankDetailCustomFieldLabel!== null) ? deletedBankDetailCustomFieldLabel : ""} Deleted successfully`} />
                <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
                <div className="row">
                    <div className="col-md-3 col-sm-12" style={{marginLeft: "23px"}}>
                        <InputDropdown
                            name="CompanyId"
                            ddOpt={companiesDropdown}
                            isRequired="true"
                            label="Companies"
                            setValue={(currentValue) => setSelectedCompanyId(parseInt(currentValue))}
                        ></InputDropdown>
                    </div>
                    <div className="col-md-3 col-sm-12" style={{marginLeft: "23px"}}>
                        <InputDropdown
                            name="CountryId"
                            ddOpt={countriesDropdown}
                            isRequired="true"
                            label="Countries"
                            setValue={(currentValue) => setSelectedCountryId(parseInt(currentValue))}
                        ></InputDropdown>
                    </div>
                </div>
                <TableComponent
                    columns={columns}
                    noRecordCss="p-0"
                    noRecordFound={
                        <NoRecordTemplate
                            headerValue={StandardConst.BankDetailCustomFieldheaderValueNoResults}
                            subHeaderValue={StandardConst.BankDetailCustomFieldSubHeader}
                            imageUrl={StandardConst.imageNoRecordsFound}
                            actionButton={
                                <>
                                    {permission.ManageAdd && (
                                        <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${AddButtonText}`}>
                                            Add {AddButtonText}
                                        </Button>
                                    )}
                                </>
                            }
                        />
                    }
                    data={customFieldsData}
                    isSearchRequired={true}
                    title={AddButtonText}
                    onAddEvent={() => fnEdit()}
                    IsAddButtonVisible={permission?.ManageAdd}
                    allowSerialNo={true}
                />
                <AddEditBankDetailCustomField
                    callBackEvent={async () => await fetchBankDetailCustomFields()}
                    ref={addEditModalRef}
                    countriesDropdown = {countriesDropdown}
                    companiesDropdown = {companiesDropdown}
                ></AddEditBankDetailCustomField>
            </Container>
        </>
    );
}

const AddEditBankDetailCustomField = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});

    const [countriesDropdown, setCountriesDropdown] = useState(props.countriesDropdown);
    const [companiesDropdown, setCompaniesDropdown] = useState(props.companiesDropdown);

    useEffect(() => {
        setCountriesDropdown(props.countriesDropdown);
        setCompaniesDropdown(props.companiesDropdown);
    }, [props]);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            if (id > 0) {
                await fetchEditValue(id);
            }
            setShow(true);
        },
    }));

    const fetchEditValue = async (id) => {
        const data = await WebService({
                endPoint: `CommonUtility/Edit/bankdetailcustomfields?BankDetailCustomFieldId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {}));

        setData(data);
    }

    const handleClose = () => {
        setShow(false);
        setData({});
    }
    const schema = yup
        .object()
        .shape({
            FieldName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            FieldLabel: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            FieldType: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        handleClose();
        if(parseInt(data.CompanyId) === StandardConst.YesNo.No){
            data.CompanyId = null;
        }
        if(parseInt(data.CountryId) === StandardConst.YesNo.No){
            data.CountryId = null;
        }
        if (data.BankDetailCustomFieldId === undefined) {
            const existingData = await WebService({
                endPoint: `FinanceTemplate/fetchBankDetailByCompanyAndCountry?CompanyId=${(data.CompanyId === null || data.CompanyId === StandardConst.YesNo.No) ? 'IS NULL' : data.CompanyId}&CountryId=${(data.CountryId === null || data.CountryId === StandardConst.YesNo.No) ? 'IS NULL' : data.CountryId}`,
                dispatch,
            });
            let maxDisplayOrder = 0;
            if(existingData.length > 0){
                maxDisplayOrder = _.max(existingData, item => item.DisplayOrder).DisplayOrder;
            }
            data.DisplayOrder = (parseInt(maxDisplayOrder) + 1);

            await WebService({
                endPoint: "CommonUtility/Insert/bankdetailcustomfields",
                body: data,
                method: "POST",
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", `${data.FieldName} added successfully`)
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/bankdetailcustomfields?BankDetailCustomFieldId=${data.BankDetailCustomFieldId}`,
                method: "PUT",
                body: data,
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", `${data.FieldName} updated successfully`)
            });
        }
        props.callBackEvent();
    };


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data?.BankDetailCustomFieldId || 0) === 0
                        ? "Add Bank Detail Custom Field"
                        : "Edit Bank Detail Custom Field"}
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12 pb-1">
                            <FormInputDropdown
                                label="Companies"
                                name="CompanyId"
                                ddOpt={companiesDropdown}
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12 pb-1">
                            <FormInputDropdown
                                label="Contries"
                                name="CountryId"
                                ddOpt={countriesDropdown}
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Field Label"
                                name="FieldLabel"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Field Name"
                                name="FieldName"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12 pb-1">
                            <FormInputDropdown
                                label="Field Type"
                                name="FieldType"
                                ddOpt={StandardConst.invoiceFieldType}
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12 pb-2">
                            <FormInputDropdown
                                name="FieldIsRequired"
                                ddOpt={StandardConst.YesOrNo}
                                label="Field Is Required"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id="btnBankDetailCustomFieldSubmit"
                        variant="outline-primary"
                        type="submit"
                    >
                        {" "}
                        {(data?.BankDetailCustomFieldId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export default BankDetailsCustomFields;
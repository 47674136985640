import React, { useContext, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import * as Form from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import {
  each,
  extend,
  extendOwn,
  findWhere,
  map,
  pick,
  reduce,
} from "underscore";
import { DateTime } from "luxon";
import { StandardConst } from "../../Services/StandardConst";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditProject = (prop, ref) => {
  const formRef = useRef();
  const cloneData = (ds) => JSON.parse(JSON.stringify(ds));
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [taskType, setTaskType] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [budgetList, setBudgetList] = useState([]);
  const [ProjectStartDate, setProjectStartDate] = useState(
    DateTime.now().toJSDate()
  );
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
        setShow(true);
        fetchCurrencyListAndCustomerList();
      if(id === 0){
        setData({
          budget: map(cloneData(taskType), (m) =>
          extend(m, {
            Hours: findWhere(cloneData(budgetList), { TaskTypeId: "" })
              ?.Hours,
          })
        ),
        })
      }else {
        fetchProjectEditValue(id);
      }

    },
  }));

  const fetchProjectEditValue = async(id) => {
    setShow(false);
    const projectRequest = WebService({
      dispatch,
      endPoint: `CommonUtility/Edit/projectmaster?ProjectId=${id || 0}`,
    }).then((ds) => (ds.length > 0 ? ds[0] : {}));

    const budgetRequest = WebService({
      dispatch,
      endPoint: `CommonUtility/Edit/project_budget?ProjectId=${id || 0}`,
    });

    Promise.all([projectRequest, budgetRequest]).then(([projectData, budgetData]) => {
      const data = {
        ...projectData,
        budget: taskType.map((m) => ({
          ...m,
          Hours: budgetData.find((item) => item.TaskTypeId === m.TaskTypeId)?.Hours,
        })),
      };

      setBudgetList(budgetData);
      setData(data);
      setShow(true);
    });
  }

  const fetchCurrencyListAndCustomerList = async() => {
    await Promise.all([
      WebService({ dispatch, 
        endPoint: "CommonUtility/fetchAll/staticcurrency",
      })
      .then((res)=> setCurrencyList(res)),
      WebService({dispatch, 
        endPoint: `CommonUtility/fetchAll/customerdetails?CompanyId=${StandardConst.YesNo.Yes}`,
      })
      .then((res)=> setCustomerList(res))
    ])
  }

  const handleClose = () => setShow(false);
  const requiredMessage = "This field is required";
  const schema = yup.object({
    ProjectName: yup.string().trim().required(requiredMessage),
    CurrencyId: yup.string().trim().required(requiredMessage),
    StartDate: yup.string().trim().required(requiredMessage),
    // EndDate: yup.string().trim().required(requiredMessage),
    // BudgetTotalHour: yup
    //   .number()
    //   .typeError(requiredMessage)
    //   .label("Hour")
    //   .required(requiredMessage)
    //   .min(0),
    budget: yup.array().of(
      yup.object().shape({
        Hours: yup
          .number()
          .typeError(requiredMessage)
          .required(requiredMessage)
          .min(0),
      })
    ),
  });

  const onSubmit = async (data) => {
    data.BudgetTotalHour = reduce(data.budget, (m, r) => m + r.Hours, 0);
    var opt = { endPoint: "CommonUtility/projectmaster", dispatch, body: data };
    if ((data?.ProjectId ?? 0) === 0) {
      extendOwn(data, { ProjectId: await WebService(opt) });
    } else {
      opt.method = "PUT";
      opt.endPoint += `?ProjectId=${data.ProjectId}`;
      await WebService(opt);
    }
    await WebService({
      dispatch,
      endPoint: `CommonUtility/project_budget?ProjectId=${data.ProjectId}`,
      method: "DELETE",
    });
    each(data.budget, async (m) => {
      m.ProjectId = data.ProjectId;
      await WebService({
        dispatch,
        endPoint: "CommonUtility/project_budget",
        body: pick(m, ["ProjectId", "TaskTypeId", "Hours"]),
      });
    });
    handleClose();
    prop.callBackEvent();
  };
  const init = async () => {
    await WebService({
      dispatch,
      endPoint: "CommonUtility/tasktype?select=TaskTypeId, DisplayDescription",
      requiredLoader: false,
    }).then((ds) => setTaskType(ds));
  };
  useEffect(() => {
      Promise.all([init()]);
  }, []);
  var formContent = (
    <Form.Form
      defaultValues={data}
      onSubmit={onSubmit}
      validationSchema={schema}
      id="frm_project"
      ref={formRef}
    >
      <Row>
        <Col md={12}>
          <Form.FormInputText
            label="Project Name"
            name="ProjectName"
            type="text"
            className="form-control"
            isRequired="true"
          />
        </Col>
        <Col md={12}>
          <Form.FormInputDropdown
            label="Currency Symbol"
            ddOpt={map(currencyList, (m) => ({
              value: m.CurrencyId,
              text: `${m.Symbol} - ${m.Description}`,
            }))}
            name="CurrencyId"
            className="form-control"
            isRequired="true"
          />
        </Col>
        <Col md={12} className="fm_m_b_07">
          <Form.FormInputDropdown
            label="Customer"
            ddOpt={[{}].concat(
              map(customerList, (m) => ({
                value: m.CustomerId,
                text: `${m.Name}`,
              }))
            )}
            name="CustomerId"
            className="form-control"
          />
        </Col>
        <Col md={12}>
          <Form.FormInputText
            label="Start Date"
            name="StartDate"
            type="date"
            className="form-control"
            isRequired="true"
            // onChange={(v) =>
            //   setProjectStartDate(DateTime.fromJSDate(new Date(v)))
            // }
            // value={ProjectStartDate}
          />
        </Col>
        <Col md={12}>
          <Form.FormInputText
            label="End Date"
            name="EndDate"
            type="date"
            className="form-control"
          />
        </Col>
        <Col md={12}>
          <Form.FormInputDropdown
            name="Status"
            ddOpt={StandardConst.ProjectStatus}
            label="Status"
            isRequired="true"
          />
        </Col>
        {/* <Col md={12}>
          <Form.FormInputText
            label="Budget Total Hour"
            name="BudgetTotalHour"
            type="number"
            className="form-control"
            isRequired="true"
            min={0}
          />
        </Col> */}
      </Row>
      <Form.FormFields name="budget">
        <Row>
          <Col md={12}>
            <Form.FormInputText
              dynamicLabel="DisplayDescription"
              name="Hours"
              type="number"
              className="form-control"
              isRequired="true"
              min={0}
              placeholder="Hrs"
            />
          </Col>
        </Row>
      </Form.FormFields>
    </Form.Form>
  );
  var footerComponent = (
    <>
      <Modal.Footer>
        <Button
          id="btnProjectSubmit"
          variant="outline-primary"
          type="submit"
          form="frm_project"
        >
          {" "}
          {(data?.ProjectId ?? 0) === 0 ? "Submit" : "Save Changes"}
        </Button>
      </Modal.Footer>
    </>
  );
  return (
      <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data?.ProjectId ?? 0) === 0 ? "Add Project" : "Edit Project"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">{formContent}</Modal.Body>

      {footerComponent}
    </Modal>
  );
};

export default forwardRef(AddEditProject);

import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextBox = ({ 
    value,
    setValue
}) => {

    const handleChange = (content) => {
        // Check if content is the default empty content
        if (content === '<p><br></p>') {
          setValue(null);
        } else {
          setValue(content);
        }
    };

    return (
        <div className="add-note-rich-text-box">
            <ReactQuill
                theme="snow"
                value={value}
                onChange={(v) => handleChange(v)}
            />
        </div>
    )
}

export default RichTextBox;
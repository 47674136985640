import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputDropdown } from "../Form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ActionPermission } from "../PageInfo";
import { useNavigate } from "react-router-dom";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditMenuRole = (prop, ref) => {

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Roles - Add"),
    });
  }, [permissionList]);

  var nevigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ MenuRole: {} });
  useImperativeHandle(ref, () => ({
    openModal: async () => {
      setShow(true);
      const data = await WebService({
        endPoint: `MenuRole/Fetch1`,
        dispatch,
      });
      data.menu = data.menu.map((v) => {
        return {
          value: v.MenuId || "" || null || undefined,
          text: v.MenuText,
        };
      });
      data.roles = data.roles.map((v) => {
        return {
          value: v.RoleId || "" || null || undefined,
          text: v.RoleName,
        };
      });
      setData(data);
    },
  }));
  const handleClose = () => setShow(false);
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      MenuId: yup.string().trim().required(requiredMessage),
      RoleId: yup.string().trim().required(requiredMessage),
    })
    .required();
  const onSubmit = async (data) => {
    await WebService({
      endPoint: "MenuRole/Create",
      body: data,
      dispatch,
    });

    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Menu-Role</Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.MenuRole}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row d-flex justify-content-center">
            <div className="col-md-3">
              <label style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>Select Role</label>
            </div>
            <div className="col-md-6">
              <FormInputDropdown
                name="RoleId"
                ddOpt={data.roles}
                isRequired="true"
              ></FormInputDropdown>
            </div>
            {permission.ManageAdd && (
              <div className="col-md-3 text-end">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => nevigate("/Roles")}
                >
                  Add Role
                </Button>
              </div>
            )}
          </div>
          <div className="row pt-2 d-flex justify-content-center">
            <div className="col-md-3">
              <label style={{ color: '#5a5a5a',fontFamily: 'sans-serif',fontSize: '14px'}}>Select Menu</label>
            </div>
            <div className="col-md-9">
              <FormInputDropdown
                name="MenuId"
                ddOpt={data.menu}
                isRequired="true"
              ></FormInputDropdown>
            </div>

            {permission.ManageAdd && <div className="col-md-3"></div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnMenuRoleSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditMenuRole);

import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent'
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import Container from "react-bootstrap/Container";
import { AppBar, Box, Stack, Tab, Tabs, useTheme } from "@mui/material";
import { StandardConst } from '../../Services/StandardConst';
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import { CheckRadioInput, InputDropdown } from '../Form';
import { useLocation } from "react-router-dom";
import { PageInfo } from '../PageInfo';
import { WebService } from '../../Services/WebService';
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views-react-18-fix";

const AssignAccessLabels = () => {

    const refSnackbar = useRef();
    const [records, setRecords] = useState([]);
    const [confirmMessage, setConfirMessage] = useState("");
    const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const { state } = useLocation();
    const [stateAccessGroupId, setStateAccessGroupId] = useState(state?.AccessGroupId);
    const [AccessGroupList, setAccessGroupList] = useState([]);
    const [AccessGroupListData, setAccessGroupListData] = useState([]);
    const [checkedAccessLevelList, setCheckedAccessLevelList] = useState([]);
    const [checkedAccessGroupList, setCheckedAccessGroupList] = useState([]);

    const dispatch = useDispatch();
    const MasterPageName = "Assign Access For Access Group";
    PageInfo({ pageTitle: MasterPageName });

    const [bData, setBData] = React.useState([
        {
            title: "Access Management",
            hrefLink: "#",
        },
        {
            title: "Assign Access Levels",
            hrefLink: "#",
        },
    ]);

    const fetchAccessGroupsList = async () => {
        const accessGroupData = await WebService({
            endPoint: `CommonUtility/fetchAll/accessgroup?CompanyId=${StandardConst.YesNo.Yes}`,
            dispatch,
        });

        const filteredData = accessGroupData.filter((r) => r.Status === StandardConst.StatusDropdown[0].value && r.AccessGroupId !== stateAccessGroupId);

        setAccessGroupListData(filteredData);

        const res = await accessGroupData.map((v) => {
            return {
                value: v.AccessGroupId || "" || null || undefined,
                text: v.AccessGroupName,
            };
        });
        setAccessGroupList(res);
    }

    const fetchAccessLabelsList = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accesslevel?CompanyId=${CompanyInfo.CompanyId}`,
            dispatch,
        });
        const filteredData = data.filter((r) => r.Status === StandardConst.StatusDropdown[0].value);
        setRecords(filteredData);
    }

    const handleAssignAccessLevels = async (currentValue,AccessLevelId) => {
        if(currentValue){
            const data = {
                AccessGroupId: stateAccessGroupId,
                AccessLevelId: AccessLevelId,
            }
            await WebService({
                endPoint: "CommonUtility/accesslevelsinaccessgroups",
                body: data,
                dispatch,
            });
            setConfirMessage("Access Level successfully assigned to the Access Group.");
            refSnackbar.current.setOpenSnackBar();
        }else {
            await WebService({
                endPoint: `CommonUtility/accesslevelsinaccessgroups?AccessLevelId=${AccessLevelId}`,
                method: "DELETE",
                dispatch,
            });
            setConfirMessage("Access Level successfully removed from the Access Group.");
            refSnackbar.current.setOpenSnackBar();
        }
    }


    const checkedAssignedAccessLevels = async (Id) => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accesslevelsinaccessgroups?AccessGroupId=${Id ? Id : stateAccessGroupId}`,
            dispatch,
        });
        const checkedAccessLevelIds = data.map((obj) => { return obj.AccessLevelId });
        setCheckedAccessLevelList(checkedAccessLevelIds);
    }

    const checkedAssignedAccessGroup = async (Id) => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/accesslevelsinaccessgroups?AccessGroupId=${Id ? Id : stateAccessGroupId}`,
            dispatch,
        });
        const checkedAccessGroupIds = data.map((obj) => obj.AssignedAccessGroupId);
        setCheckedAccessGroupList(checkedAccessGroupIds);
    }

    const handleAssignAccessGroup = async (currentValue, AccessGroupId) => {
        if (currentValue) {
            const data = {
                AccessGroupId: stateAccessGroupId,
                AssignedAccessGroupId: AccessGroupId,
            }
            await WebService({
                endPoint: "CommonUtility/accesslevelsinaccessgroups",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/accesslevelsinaccessgroups?AssignedAccessGroupId=${AccessGroupId}`,
                method: "DELETE",
                dispatch,
            });
        }
    }

    const AccessLevelColumns = [
        {
            Text: "Select",
            style: {width: "5px"},
            render: (dr) => (
                <>
                <Stack>
                    <CheckRadioInput
                        name={String(dr.AccessLevelId)+"ALID"}
                        isRadio={false}
                        value={checkedAccessLevelList?.find((AccessLevelId) => AccessLevelId === dr.AccessLevelId ? true : false)}
                        setValue={(currentValue) => { handleAssignAccessLevels(Number(currentValue), dr.AccessLevelId)}}
                    />
                </Stack>
                </>
            )
        },
        {
            Text: "Access Level Name",
            Value: "AccessLevelName",
        },{
            Text: "Status",
            Value: "Status",
            render: (dr) => (
                <>
                    <span>
                    { dr.Status === StandardConst.StatusDropdown[0].value ? StandardConst.StatusDropdown[0].text : StandardConst.StatusDropdown[1].text}
                    </span>
                </>
            )
        },
    ]

    const AccessGroupcolumns = [
        {
            Text: "Select",
            style: {width: "5px"},
            render: (dr) => (
                <>
                <Stack>
                    <CheckRadioInput
                        name={String(dr.AccessGroupId)+"AGID"}
                        isRadio={false}
                        value={checkedAccessGroupList?.find((AccessGroupId) => AccessGroupId === dr.AccessGroupId ? true : false)}
                        setValue={(currentValue) => { handleAssignAccessGroup(Number(currentValue), dr.AccessGroupId)}}
                    />
                </Stack>
                </>
            )
        },
        {
            Text: "Access Group Name",
            Value: "AccessGroupName",
        },{
            Text: "Status",
            Value: "Status",
            render: (dr) => (
                <>
                    <span>
                    { dr.Status === StandardConst.StatusDropdown[0].value ? StandardConst.StatusDropdown[0].text : StandardConst.StatusDropdown[1].text}
                    </span>
                </>
            )
        },
    ]

    useEffect(() => {
        setAccessGroupList([]);
        setAccessGroupListData([]);
        setRecords([]);

        checkedAssignedAccessLevels(stateAccessGroupId);
        checkedAssignedAccessGroup(stateAccessGroupId);
        fetchAccessGroupsList();
        fetchAccessLabelsList();
    }, [stateAccessGroupId]);

    const [value, setValue] = useState(0);
    const theme = useTheme();
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
      };
    
  

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <Container fluid className="base-container">
                
                    <Box
                        sx={{
                            width: 1,
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                                <h3 className="ms-4 mt-2">{MasterPageName}</h3>
                                <div className="ms-4">
                                    <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                                </div>
                            </div>
                        </div>
                    </Box>

                    <div className="row primary-bg-color p-0 m-0" style={{height: "53px"}}>
                        <div className="col-md-4 col-sm-12 d-flex justify-content-start align-items-start" style={{ margin: "0.5rem 0 0.5rem 1.5rem" }}>
                            <InputDropdown
                                labelCss="text-white"
                                name="AccessGroupId"
                                ddOpt={AccessGroupList}
                                isRequired="true"
                                label="Access Group Name"
                                value={stateAccessGroupId}
                                setValue={(currentValue) => { setStateAccessGroupId(Number(currentValue)) }}
                            ></InputDropdown>
                        </div>
                    </div>

                    <Box className="mt-1">
                        <AppBar
                            position="static"
                            color="info"
                            elevation={0}
                            className="Maintab_Custome"
                            style={{ maxHeight: "40px", height: "40px" }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                // indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                                style={{
                                maxHeight: "40px",
                                height: "40px",
                                }}
                            >
                                <Tab
                                    title="Access Groups"
                                    value={0}
                                    sx={{ "min-width" : "40px !important"}}
                                    // icon={<AccountBoxIcon />}
                                    aria-label="Access Groups"
                                    label={window.innerWidth <= 600 ? '' : 'Access Groups'}
                                    iconPosition="start"
                                    className="tab_Custome pt-0"
                                />
                                
                                <Tab
                                    title='Access Levels'
                                    value={1}
                                    sx={{ "min-width" : "40px !important"}}
                                    // icon={<ArticleIcon />}
                                    aria-label="Access Levels"
                                    label={window.innerWidth <= 600 ? '' : 'Access Levels'}
                                    iconPosition="start"
                                    className="tab_Custome pt-0"
                                />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <div id={0} className='mt-2'>
                                <TableComponent
                                    columns={AccessGroupcolumns}
                                    noRecordCss="p-0"
                                    noRecordFound={
                                        <NoRecordTemplate
                                            headerValue={StandardConst.headerValueNoResults}
                                            subHeaderValue={StandardConst.subHeaderValueStandard}
                                            imageUrl={StandardConst.imageNoRecordsFound}
                                        />
                                    }
                                    data={AccessGroupListData}
                                    isSearchRequired={true}
                                    IsAddButtonVisible={false}
                                />
                            </div>
                            <div id={1} className='mt-2'>
                                <TableComponent
                                    columns={AccessLevelColumns}
                                    noRecordCss="p-0"
                                    noRecordFound={
                                        <NoRecordTemplate
                                            headerValue={StandardConst.headerValueNoResults}
                                            subHeaderValue={StandardConst.subHeaderValueStandard}
                                            imageUrl={StandardConst.imageNoRecordsFound}
                                        />
                                    }
                                    data={records}
                                    isSearchRequired={true}
                                />
                            </div>
                        </SwipeableViews>
                    </Box>
            </Container>
        </>
    )
}

export default AssignAccessLabels
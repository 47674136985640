import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
//import counterReducer from '../redux/counter';
import loaderReducer from "./Loader";
import configurationFlowReducer from "./ConfigurationFlow";

import authReducer from '../features/auth/authSlice'
import facenetReducer from '../features/auth/facenetSlice'
import userReducer from '../features/dashboard/userSlice'
import matchReducer from '../features/dashboard/matchSlice'
import detectReducer from '../features/dashboard/detectSlice'
import similarityReducer from '../features/dashboard/similaritySlice'

export const store = configureStore({
  reducer: {
    loader: loaderReducer,
    auth: authSlice,
    configurationFlow: configurationFlowReducer,
    authR: authReducer,
    facenet: facenetReducer,
    user: userReducer,
    match: matchReducer,
    detect: detectReducer,
    similarity: similarityReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
//export const RootState = ReturnType<typeof store.getState>()
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
//export const AppDispatch = typeof store.dispatch;

import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { 
  Form,
  FormInputText,
  FormAutoCompleteDropdown,
  FormInputDropdown,
  FormCheckRadioInput,
  FormAutoCompleteDropdownCard
} from "../Form";
import { chain } from "underscore";
import { useSearchParams } from "react-router-dom";
import { ExpandMore, ExpandLess, Diversity1 } from '@mui/icons-material';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { UploadOrClickProfilePicture } from "../../Services/ImageUploader";
import { WSSuccessAlert } from "../../Services/WSAlert";
import { formatCurrentDate } from "../../utils/CommonUtils";
import { DateTime } from "luxon";


const { forwardRef, useState, useImperativeHandle } = React;
const EditVisitorDetails = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ TypeName: {} });
  const [state, setState] = useState({});
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [searchParams] = useSearchParams();
  const [visitorDp, setVisitorDp] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleClose = () => {
    setShow(false);
    setEventId(null);
    setIsCollapsed(true);
    setData({ TypeName : {}});
  }
  const requiredMessage = "This is a required field";
  const [uploadedFile, setuploadedFile] = useState();

  var CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);

  // This function fetch all Visitor Type list and this list used in "Visitor Type" field
  const FetchVisitorTypeList = async (EventId) => {
    var EndPoint = `Visitor/Type?CompanyId=${CompanyId}`;
    if(EventId){
      EndPoint = `Event/getEventVisitorDataForDropdown?EventId=${EventId}`;
    }
    await WebService({
      dispatch,
      endPoint: EndPoint,
    }).then((visitorTypes) => setState((s) => ({ ...s, visitorTypes })));
  };

  const [EmployeesList, setEmployeesList] = useState([]);

  // This function fetch all employee list and this list used in "Whom to meet" field
  const FetchEmployeeList = async () => {
    const data = await WebService({
      dispatch,
      endPoint: `Visitor/fetchAllEmployees`
    });
    const list = data?.filter((r) => r.FullName !== null)?.map((res) => {
      return {
          uniqueId: res.EmployeeId,
          heading: res.FullName, 
          description: res.Designation, 
          avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
        }
    });
    setEmployeesList(list);
  };

  const [checkInAction, SetCheckInAction] = useState(false);
  const [EventId, setEventId] = useState(null);
  const [eventAttributeList, setEventAttributeList] = useState([]);
  const [checkedArrAttributeList, setCheckedArrAttributeList] = useState([]);
  const [isEventPage, setIsEventPage] = useState(false);

  useEffect(() => {
    FetchEmployeeList();
  },[]);
  
  useImperativeHandle(ref, () => ({
    openModal: async (id, CheckInAction, visitorTypeId, eventId) => {
      Promise.all([FetchVisitorTypeList(eventId)]);

      handleSelectedValue(visitorTypeId);
      getsetlocation();
        if(CheckInAction === "CheckInAction"){
          SetCheckInAction(true);
        }else {
          SetCheckInAction(false);
        }
        if(eventId){
          setEventId(eventId);
          fetchEventAttributesList(eventId);
          const data = await WebService({
              endPoint: `CommonUtility/Edit/visitorcheckinattributes?EventId=${eventId}&VisitorId=${id}`,
              dispatch
            })
          const checkedAttributeList = data.map((obj) => obj.EventAttributeId);
          setCheckedArrAttributeList(checkedAttributeList);
        }
        const data = {
          TypeName: await WebService({
          endPoint: `Visitor/GetVisitorDetails/${CompanyId}/${id}`,
            dispatch,
          }).then((c) => (c.length > 0 ? c[0] : {})),
        };

        if(Object.keys(data.TypeName).length > 0 && data.TypeName?.ContactPerson) {
          data.TypeName.ContactPerson = [EmployeesList.find(item => item.uniqueId === data.TypeName.ContactPerson)];
        }
        
        setVisitorDp(data.TypeName.ProfilePicture);
        setData(data);
        setShow(true);
    },
  }));

  const checkIsEventPage = () => {
    if (prop?.state?.EventId) {
      const stateArray = Object?.keys(prop?.state);
      const isEventPage = stateArray?.some((res) => res === "EventId");
      setIsEventPage(isEventPage);
    }
  }

  useEffect(() => {
    checkIsEventPage();
  }, [isEventPage]);

  const fetchEventAttributesList = async(eventId) => {
    const data = await WebService({
        endPoint: `CommonUtility/fetchAll/eventattributes?EventId=${eventId}`,
        dispatch,
      });
      setEventAttributeList(data);

}

  const CheckInAction = (dr) =>{
    const formattedDate = formatCurrentDate();
    WebService({
      dispatch,
      endPoint: `Visitor/CheckIn`,
      body: { VisitorId: dr.VisitorId, CheckInDate: formattedDate },
      method: "PATCH",
    }).then((_) => {
      prop.callBackEvent();
      WSSuccessAlert("Success", `${dr.VisitorName} Successfully Checked In.`);
    });
}


  const [settings, setSettings] = useState({phone: false, email: false, whomTOMeet: false});

    const handleSelectedValue = async(visitorTypeId) => {

      const visitorTypeData = await WebService({
        dispatch, 
        endPoint: `CommonUtility/fetchAll/static_visitor_type?VisitorTypeId=${visitorTypeId}`,
      }).then((c) => (c.length > 0 ? c[0] : {}));
      
      setSettings((prevSettings) => ({
        ...prevSettings,
        phone: prevSettings.phone || visitorTypeData.WillProvidePhoneNo ? visitorTypeData.WillProvidePhoneNo : false,
        email: prevSettings.email || visitorTypeData.WillProvideEmail ? visitorTypeData.WillProvideEmail : false,
        whomTOMeet: prevSettings.whomTOMeet || visitorTypeData.IsServiceProvider ? visitorTypeData.IsServiceProvider : false,
      }));
    }

  const schema = yup
    .object()
    .shape({
      VisitorName: yup
        .string()
        .trim()
        .required(StandardConst.requiredMessage)
        .max(100),

      ContactNo:  settings.phone ? 
      yup
      .number()
      .label("Contact No")
      .typeError(StandardConst.requiredMessage)
      .required(StandardConst.requiredMessage)
      .positive()
      .integer()
      .min(1000000000, StandardConst.mobileMessage)
      .max(9999999999, StandardConst.mobileMessage)
      :
      null,

      Purpose: yup.string().trim().required(requiredMessage),
      ContactPerson: !EventId && !settings.whomTOMeet ? 
      yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string(),
          label: yup.string(),
        })
      )
      .typeError(StandardConst.requiredMessage)
      .min(1, StandardConst.requiredMessage)
      :null,

      VisitorTypeId: yup
        .string()
        .trim()
        .required(StandardConst.requiredMessage),
        
      OfficeLocationId: !isEventPage ? yup.string().trim().required(StandardConst.requiredMessage) : null,
      ScheduleCheckInTime: data.TypeName.InvitedBy !== null ? yup
        .date()
        .typeError(StandardConst.requiredMessage)
        .required(StandardConst.requiredMessage) : null,
      VisiteTime: data.TypeName.InvitedBy !== null ? yup
                  .mixed()
                  .test('is-time-valid', 'Time is not valid', (value) => {
                    if (!value) return false; // don't empty values
                    return true;
                  })
                  .typeError(StandardConst.requiredMessage)
                  .required(StandardConst.requiredMessage) : null,
    })
    .required();

  const [imageName, setImageName] = useState("");
  const LoggedEmployeeUser = useSelector((s) => s?.auth?.LoggedUser);
  const LoggedUserUser = useSelector((s) => s?.auth?.LoggedCustomer);

  const onSubmit = async (data) => {

    let EventAttributeIds = [...checkedArrAttributeList];
    eventAttributeList.map((element) => {
      if (data.hasOwnProperty(String(element.EventAttributeId))) {
        if (data[element.EventAttributeId] === true && !EventAttributeIds.includes(element.EventAttributeId)) {
          EventAttributeIds.push(element.EventAttributeId);
        }else if(data[element.EventAttributeId] === false){
          const onlyCheckedArray = EventAttributeIds.filter(id => id !== element.EventAttributeId);
          EventAttributeIds = [...onlyCheckedArray];
        }
        delete data[element.EventAttributeId];
      }
      return null;
    });

    if(EventAttributeIds.length === 0){
      await WebService({
        endPoint: `CommonUtility/visitorcheckinattributes?VisitorId=${data.VisitorId}`,
        method: "DELETE",
        dispatch,
    });
    }
    data.ContactPerson = data.ContactPerson && data.ContactPerson[0].uniqueId;
    data.ProfilePicture = imageName || visitorDp;
    await WebService({
      endPoint: `Visitor/Update/${data.VisitorId}`,
      method: "PUT",
      body: data,
      dispatch,
    });
    if (checkInAction) {
      CheckInAction(data);
    }

    // for Attribute create api call
    let loggedUser = null;
    let userType = "";
    if (LoggedEmployeeUser) {
      loggedUser = LoggedEmployeeUser
      userType = StandardConst.UserType.Employee;
    } else if (LoggedUserUser) {
      loggedUser = LoggedUserUser
      userType = StandardConst.UserType.User;
    } else {
      loggedUser = null;
      userType = null;
    }
    EventAttributeIds?.map(async(EventAttributeId) => {
      const customData = {
        ...data,
        EventId: EventId,
        UpdatedByUserType: userType,
        UpdateById: loggedUser,
        EventAttributeId: EventAttributeId
      }
      if(checkedArrAttributeList.length === 0){
        await WebService({
          endPoint: "CommonUtility/visitorcheckinattributes",
          body: customData,
          dispatch,
        });
      }else {
        await WebService({
          endPoint: `CommonUtility/visitorcheckinattributes?VisitorId=${data.VisitorId}`,
          method: "DELETE",
          dispatch,
      });
      await WebService({
        endPoint: "CommonUtility/visitorcheckinattributes",
        body: customData,
        dispatch,
      });
      }
      return null;
    });

    handleClose();
    prop.callBackEvent();
  };


  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  }

  const labelFontSize = {
    fontSize: "14px",
  }

  const [locationData, setlocationData] = useState([]);
  const getsetlocation = () => {
    WebService({
      endPoint: `UserProfile/Fetchlocation`,
      dispatch,
    }).then((data) => {
      setlocationData(
        data.data.map((v) => {
          const words = v.Address.split(' ');
          const truncatedWords = words.slice(0, 3);
          const truncatedAddress = truncatedWords.join(' ');
          const finalAddress = truncatedAddress + ', ' + v.Location;
          return {
            value: v.OfficeLocationId,
            text: finalAddress,
          };
        })
      );
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {
            checkInAction ? "CheckIn Details" : "Edit Visitor Details"
          }
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.TypeName}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-4 m-auto mb-4">
            <UploadOrClickProfilePicture
              UploadedFileName={setImageName}
              ShowProfilePicture={visitorDp}
            >
            </UploadOrClickProfilePicture>

            </div>
            <div className="col-md-12">
              <FormInputDropdown
                label="Visitor Type"
                name="VisitorTypeId"
                ddOpt={[{}].concat(
                  chain(state.visitorTypes ?? [])
                    .map((m) => ({
                      value: m.VisitorTypeId,
                      text: m.TypeName,
                    }))
                    .value()
                )}
                isRequired="true"
                setValue={(currentValue) => { handleSelectedValue(Number(currentValue)) }}
              />
            </div>
            <div className="col-md-12">
              <FormInputText
                label="Visitor Name"
                name="VisitorName"
                isRequired="true"
              />
            </div>

            <div className="col-md-12">
              <FormInputText
                label="Visitor Company"
                name="VisitorCompany"
              />
            </div>

            <div className="col-md-12">
              {
                settings.email ? 
                  <FormInputText
                    label="Visitor Email"
                    name="Email"
                  />
                  : null
              }
            </div>

            <div className="col-md-12">
              {
                settings.phone ?
                  <FormInputText
                    label="Visitor Contact"
                    name="ContactNo"
                    isRequired="true"
                  />
                : null
              }
            </div>
            <div className="col-md-12">
              <FormInputText
                label="Designation"
                name="Designation"
              />
            </div>

            <div className="col-md-12">
              <FormInputText
                label="Visitor Address"
                name="Address"
                as="textarea"
              />
            </div>

            <div className="col-md-12">
              <FormInputText
                label="Purpose of Visit"
                name="Purpose"
                as="textarea"
                isRequired="true"
              />
            </div>
            {
              !isEventPage && (
                <>
                  <div className="col-md-12">
                    <FormInputDropdown
                      label="Office Location"
                      name="OfficeLocationId"
                      ddOpt={locationData}
                      isRequired="true"
                    />
                  </div>
                </>
              )
            }
            <div className="col-md-12">
              {
                !EventId && 
                !settings.whomTOMeet ? 
                  <FormAutoCompleteDropdownCard
                    name="ContactPerson"
                    data={chain(EmployeesList ?? [])}
                    label="Whom To Meet"
                    optionText="heading"
                    value={data.TypeName?.ContactPerson !== undefined ? data.TypeName?.ContactPerson[0] : ''}
                    isRequired="true"
                    placeholder="Choose a Person"
                  />
                : null
              }
            </div>
            {
              !isEventPage ? 
              data.TypeName.InvitedBy === null ?
                <>
                  <div className="col-md-12">
                    <FormInputDropdown
                      label="Access"
                      name="Access"
                      ddOpt={StandardConst.VisitorRegistration}
                    />
                  </div>
                </>
                :
                <>
                  <div className="col-md-12">
                    <FormInputText
                      label="Check In Date"
                      name="ScheduleCheckInTime"
                      type="date"
                      min={DateTime.now().toJSDate()}
                      isRequired="true"
                    />
                  </div>
                  <div className="col-md-12">
                    <FormInputText
                      label="Check In Time"
                      name="VisiteTime"
                      type="time"
                      isRequired="true"
                    />
                  </div>
                </>
                : 
                null
            }
            {
              (EventId && eventAttributeList.length > 0) && (
                <>
                  <div className="row">
                    <h5 onClick={toggleCollapse} style={{ marginTop: "15px" }}>
                      Event Properties
                      {
                        isCollapsed
                          ?
                          (<ExpandMore fontSize="small" />)
                          :
                          (<ExpandLess fontSize="small" />)
                      }
                    </h5>
                    <hr></hr>
                    {
                      eventAttributeList?.map((res) => (
                        <>
                          <div className={`col-md-6 ${isCollapsed ? 'd-none' : ''}`}>
                            <div className="row" style={{ height: "38px" }}>
                              <div className="float-start col-md-2 text-wrap">
                                <FormCheckRadioInput
                                  name={String(res.EventAttributeId)}
                                  isRadio={false}
                                  value={
                                    checkedArrAttributeList.find(
                                      (EventAttributeId) => EventAttributeId === res.EventAttributeId ? true : false)
                                  }
                                />
                              </div>
                              <div className="float-start col-md-10 text-wrap">
                                <label title="This visitor needs a visitor badge" style={labelFontSize}>
                                  {res.Attribute}
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    }
                  </div>
                </>
              )
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            checkInAction ? (
              <Button
              id="btnVisitorTypeCheckIn"
              variant="outline-primary"
              type="submit"
              >
              CheckIn
              </Button>
            )
            : 
            <Button
            id="btnVisitorTypeSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data.TypeName.VisitorTypeId || 0) === 0
              ? "Submit"
              : "Save Changes"}
          </Button>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(EditVisitorDetails);

import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
// import AddEditRoles from "./AddEditRoles";
import { useDispatch, useSelector } from "react-redux";
import "./Subscription.css";
import { PageInfo } from "../PageInfo";
import { StandardConst } from "../../Services/StandardConst";
import { Avatar, Box, Button, ButtonGroup } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { WSSuccessAlert } from "../../Services/WSAlert";
import { InputDropdown } from "../Form";

const Subscription = () => {
  const nevigate = useNavigate();
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  PageInfo({ pageTitle: "Subscription" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  // const [companyCurrentPlan, setCompanyCurrentPlan] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState("");

  const fetchPlans = async () => {
    const data = await WebService({
      endPoint: `Subscription/Plans?date=${DateTime.now().toSQLDate()}`,
      dispatch,
    });
    setRecords(data);
  };

  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchPlans();
    }
    renderAfterCalled.current = true;
  }, []);

  const [bData, setBData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Subscription",
      hrefLink: "#",
    },
  ]);

  const SubscriptionHeaderColor = [
    "#08b97b",
    "#ff4136",
    "#2196f3",
    "#39FF14",
    "#808080",
    "#f4c414",

  ];
  const SubscriptionTextColor = [
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
  ];
  const MasterPageName = "Subscription";

  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);

    return color;
  }
  //Subscription Model

  const [planDetails, setPlanDetails] = useState({});
  const [subscriptionBracketDroptdown, setSubscriptionBracketDroptdown ] = useState([]);
  const onSubscribeEvent = async (dataRow) =>{
    await WebService({
      dispatch,
      endPoint: `Subscription/Plan/${dataRow.SubscriptionId}`,
    }).then((r) => {
      getSubscriptionBracket(r.Details, r.Details[0].Duration, 0);
      setPlanDetails(r);
    })
  };

  const getSubscriptionBracket = async (detailsData, duration, index) => {
    setActiveSubscription(duration);
    // here we need to set index in plandetail state for calculation
    setPlanDetails((dt) => ({ ...dt, DetailIndex: index, }));

    const subscriptionBracketList = detailsData.filter(details => details.Duration === duration).map(item => ({text: `${item.UserLimitFrom} - ${item.UserLimitTo}`, value : item.SubscriptionDetailId}));
    setSubscriptionBracketDroptdown(subscriptionBracketList);
  }

  const updateSubscriptionBracket = (SubscriptionDetailId) => {
    if(planDetails?.Details){
      const selectedIndex = planDetails.Details.findIndex(item => item.SubscriptionDetailId === SubscriptionDetailId);
      // here we neet to set index in plandetail state for calculation
      setPlanDetails((dt) => ({ ...dt, DetailIndex: selectedIndex, }));
    }
  }

  const payableAmt = () => {
    return (
      planDetails.Details[planDetails?.DetailIndex ?? 0].FinalPrice *
      (StandardConst.subscriptionPeriod[
        planDetails.Details[planDetails?.DetailIndex ?? 0]?.Duration ?? ""
      ]?.Month ?? 0) *
      planDetails.Details[planDetails?.DetailIndex ?? 0].UserLimitTo
    );
  };

  const payableAmtIncludingTax = () => {
    return payableAmt() + (payableAmt() * 18)/100;
  }

  const PaySubscription = async (PaymentDetails) => {
    planDetails ??= {};
    var subcriptionDetail =
      (planDetails.Details ?? []).concat([{}])[planDetails.DetailIndex ?? 0] ??
      {};
    var data = {
      CompanyId: null,
      SubscriptionId: planDetails.SubscriptionId,
      SubscriptionName: planDetails.Name,
      SubscriptionDuration: subcriptionDetail.Duration,
      SubscriptionTypeId: planDetails.SubscriptionTypeId,
      SubscriptionDetailId: subcriptionDetail.SubscriptionDetailId,
      // FromDate: DateTime.now().toSQLDate(),
      // ToDate: DateTime.now().plus({month:(StandardConst.subscriptionPeriod[subcriptionDetail.Duration ?? ""]?.Month ?? 0)}).minus({day:1}).toSQLDate(),
      UserLimitFrom: subcriptionDetail.UserLimitFrom,
      UserLimitTo: subcriptionDetail.UserLimitTo,
      ModuleId: null,
      IsActive: StandardConst.YesNo.Yes,
      CreatedBy: null,
      UpdatedBy: null,
      TimeZone: DateTime.now().zone.name,
      Month:
        StandardConst.subscriptionPeriod[subcriptionDetail.Duration ?? ""]
          ?.Month ?? 0,
    };
    const insertedCompanySubscriptionId = await WebService({
       dispatch,
       endPoint: "Subscription",
       body: data 
      });
    if(insertedCompanySubscriptionId){
      const inserted = await WebService({
        dispatch,
        endPoint: "CommonUtility/companysubscriptionpayments",
        body: {
          CompanySubscriptionId: insertedCompanySubscriptionId,
          RazorpayPaymentId: PaymentDetails.razorpay_payment_id,
          RazorpayOrderId: PaymentDetails.razorpay_order_id,
          RazorpaySignature: PaymentDetails.razorpay_signature
        },
        method: "POST",
      });
      WSSuccessAlert("Success", "Plan subscribed successfully");
      nevigate("/Dashboard");
    }
  };
  let uniqueDurations = [];
  const view_SelectedPlanDetails = (
    <>
      {(planDetails.SubscriptionId ?? 0) !== 0 && (
        <div className="row justify-content-md-center">
          <div className="col-sm-6 col-md-6  mb-3">
            <div
              className="card border-start-5"
              style={{ borderColor: SubscriptionHeaderColor[0] }}
            >
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center position-relative pb-3">
                    <div className="flex-shrink-0">
                      <Avatar
                        variant="rounded"
                        sx={{ width: 60, height: 60 }}
                        style={{
                          backgroundColor: randomColor(),
                        }}
                        alt={planDetails.Name}
                        src="/static/images/avatar/1.jpg"
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <span className="display-6">
                        <strong>{planDetails.Name}</strong>
                      </span>
                      <p className="fs-6 m-0">
                        {planDetails.Symbol}{" "}
                        <del>
                          {
                            planDetails.Details[planDetails?.DetailIndex ?? 0]
                              .PricePerUser
                          }{" "}
                          / User / {"Month "}
                        </del>
                      </p>
                      <span className="fs-5 m-0">
                        <strong>
                          {planDetails.Symbol}{" "}
                          {
                            planDetails.Details[planDetails?.DetailIndex ?? 0]
                              .FinalPrice
                          }{" "}
                          / User / {"Month "}
                        </strong>
                      </span>
                    </div>
                  </div>
                  <div lassName="d-flex flex-column">
                    <ButtonGroup
                      className="mt-4"
                      size="small"
                      variant="outlined"
                      disableElevation
                      aria-label="Disabled elevation buttons"
                    >
                      {planDetails.Details.map((f, i) => {
                        if (!uniqueDurations.includes(f.Duration)) {
                            uniqueDurations.push(f.Duration);
                          return <React.Fragment key={i}>
                            <Button className={activeSubscription === f.Duration ? "active-subscription" : ""} onClick={() => getSubscriptionBracket(planDetails.Details, f.Duration, i)}
                            >
                              {StandardConst.subscriptionPeriod[f?.Duration ?? ""]
                                ?.Name ?? ""}
                            </Button>
                            <br></br>
                          </React.Fragment>
                        }
                        return null;
                      })}
                    </ButtonGroup>
                    <div className="flex-grow-1 ms-3">
                      <span className="fs-5"><br />
                        <InputDropdown
                          name="SubscriptionBracket"
                          ddOpt={subscriptionBracketDroptdown}
                          label="No. of user"
                          setValue={(currentValue) => updateSubscriptionBracket(parseInt(currentValue))}
                          value={subscriptionBracketDroptdown[0]}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-3 pt-2 text-center border-top d-flex flex-column">
                  <div className="mx-2">
                    <div className="d-flex float-start">
                      <strong>Total User:</strong>
                    </div>
                    <div className="d-flex float-end">
                      <strong>
                        {
                          planDetails.Details[planDetails?.DetailIndex ?? 0]
                            .UserLimitTo
                        }
                      </strong>
                    </div>
                  </div>
                  <div className="mx-2">
                    <div className="d-flex float-start">
                      <strong>Subscription Amount:</strong>
                    </div>
                    <div className="d-flex float-end">
                      <strong>
                        {planDetails.Symbol}{" "}
                        {
                          planDetails.Details[planDetails?.DetailIndex ?? 0]
                            .PricePerUser
                        }{" "}
                        X{" "}
                        {
                          planDetails.Details[planDetails?.DetailIndex ?? 0]
                            .UserLimitTo
                        }{" "}
                        X{" "}
                        {StandardConst.subscriptionPeriod[
                          planDetails.Details[planDetails?.DetailIndex ?? 0]
                            ?.Duration ?? ""
                        ]?.Month ?? 0}{" "}
                        =&nbsp;
                        {planDetails.Symbol}
                        {planDetails.Details[planDetails?.DetailIndex ?? 0]
                          .PricePerUser *
                          (StandardConst.subscriptionPeriod[
                            planDetails.Details[planDetails?.DetailIndex ?? 0]
                              ?.Duration ?? ""
                          ]?.Month ?? 0) *
                          planDetails.Details[planDetails?.DetailIndex ?? 0]
                            .UserLimitTo}
                      </strong>
                    </div>
                  </div>
                  <div className="mx-2 text-danger">
                    <small>
                      <div className="d-flex float-start">
                        <strong>Discount:</strong>
                      </div>
                      <div className="d-flex float-end">
                        <strong>
                          (-) &nbsp;
                          {planDetails.Symbol}{" "}
                          {
                            planDetails.Details[planDetails?.DetailIndex ?? 0]
                              .Discount
                          }{" "}
                          X{" "}
                          {
                            planDetails.Details[planDetails?.DetailIndex ?? 0]
                              .UserLimitTo
                          }{" "}
                          X{" "}
                          {StandardConst.subscriptionPeriod[
                            planDetails.Details[planDetails?.DetailIndex ?? 0]
                              ?.Duration ?? ""
                          ]?.Month ?? 0}{" "}
                          =&nbsp; {planDetails.Symbol}
                          {planDetails.Details[planDetails?.DetailIndex ?? 0]
                            .Discount *
                            (StandardConst.subscriptionPeriod[
                              planDetails.Details[planDetails?.DetailIndex ?? 0]
                                ?.Duration ?? ""
                            ]?.Month ?? 0) *
                            planDetails.Details[planDetails?.DetailIndex ?? 0]
                              .UserLimitTo}
                        </strong>
                      </div>
                    </small>
                  </div>
                  <div className="mx-2 mt-2">
                    <div className="d-flex float-start">
                      <strong>Amount:</strong>
                    </div>
                    <div className="d-flex float-end border-top border-1 border-primary">
                      <strong>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {planDetails.Symbol}
                        {payableAmt()}
                      </strong>
                    </div>
                  </div>
                  <div className="mx-2 mt-2">
                    <div className="d-flex float-start">
                      <strong>GST 18%:</strong>
                    </div>
                    <div className="d-flex float-end">
                      <strong>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        + {planDetails.Symbol}
                        {(payableAmt() * 18)/100}
                      </strong>
                    </div>
                  </div>
                  <div className="mx-2 mt-2">
                    <div className="d-flex float-start">
                      <strong>Payable Amount:</strong>
                    </div>
                    <div className="d-flex float-end border-top border-1 border-primary">
                      <strong>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {planDetails.Symbol}
                        {/* {payableAmt() + (payableAmt() * 18)/100} */}
                        {payableAmtIncludingTax()}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="mt-3 pt-2 text-center border-top">
                  <div className="d-flex flex-column">

                    <div className="mx-2 mt-2">
                      <div className="d-flex float-start">
                        <strong>Account Name:</strong>
                      </div>
                      <div className="d-flex float-end">
                        <strong>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          Wise Software Solutions Pvt Ltd
                        </strong>
                      </div>
                    </div>

                    <div className="mx-2 mt-2">
                      <div className="d-flex float-start">
                        <strong>Account Number:</strong>
                      </div>
                      <div className="d-flex float-end">
                        <strong style={{letterSpacing: "3px"}}>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          251111155555
                        </strong>
                      </div>
                    </div>
                    <div className="mx-2 mt-2">
                      <div className="d-flex float-start">
                        <strong>IFSC Code:</strong>
                      </div>
                      <div className="d-flex float-end">
                        <strong style={{letterSpacing: "3px"}}>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          INDB0001029
                        </strong>
                      </div>
                    </div>
                  </div>


                  <div className="d-flex justify-content-center gap-3">
                    <button
                      type="button"
                      onClick={() => setPlanDetails({})}
                      style={{
                        backgroundColor: SubscriptionHeaderColor[1],
                        color: SubscriptionTextColor[0],
                      }}
                      className="mx-1 w-100 mt-2 ws-button"
                    >
                      Change Plan
                    </button>
                    <button
                      style={{
                        backgroundColor: SubscriptionHeaderColor[0],
                        color: SubscriptionTextColor[0],
                      }}
                      className="mx-1 w-100 mt-2 ws-button"
                      onClick={() => getRazorpayOrderAndPay(payableAmtIncludingTax(), planDetails.Name)}
                    >
                      Pay
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
  
  const view_SubscriptionPackages = (
    <>
      {(planDetails.SubscriptionId ?? 0) === 0 && (
        <div className="row justify-content-md-start">
          {records.map((m, i) => (
            <React.Fragment key={i}>
              <div className="col-md-6 col-lg-4">
                <div className="card" style={{border: m.IsSubscribed && m.currentPlanDetails.length > 0 ? "2px solid green" : "none"}}>
                  <div className="card-body p-0">
                    <h5 className="card-title">
                      <div
                        className="heading d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor:
                            SubscriptionHeaderColor[
                            SubscriptionHeaderColor.length - 1 < i
                              ? SubscriptionHeaderColor.length - 1
                              : i
                            ],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="heading-text">{m.Name}</div>
                          <span className="fs-6">
                            {m.UserLimitFrom} - {m.UserLimitTo} User
                          </span>
                        </div>
                      </div>
                    </h5>
                    <p className="card-text" style={{ fontFamily: "Montserrat, sans-serif" }}>
                      <div className="item">
                        <div className="features">
                          {m.features.map((f, key) => (
                            <div className="d-flex justify-content-start align-items-center my-2" key={key}>
                              <CheckCircleOutlineIcon
                                sx={{ fontSize: 20 }}
                                color="success"
                              />
                              <span className="feature mx-2">{f.Feature}</span>
                            </div>
                          ))}
                        </div>
                        <div className="price" style={{ fontFamily: "none" }}>
                          <h4
                            style={{
                              color:
                                SubscriptionHeaderColor[
                                SubscriptionHeaderColor.length - 1 < i
                                  ? SubscriptionHeaderColor.length - 1
                                  : i
                                ],
                            }}
                          >
                            {`${m.Symbol}${parseInt(m.Price)}`}
                            <span className="span-paisa">
                              .{`${m.Price}`.split(".")[1]}
                            </span>
                          </h4>
                          <div className="pricing-plan-period">/User/Month</div>
                        </div>
                        <div className="buttons">
                          <button
                            type="button"
                            onClick={async () => await onSubscribeEvent(m)}
                            disabled={m.IsSubscribed}
                            style={{
                              backgroundColor:
                                SubscriptionHeaderColor[
                                SubscriptionHeaderColor.length - 1 < i
                                  ? SubscriptionHeaderColor.length - 1
                                  : i
                                ],
                              color:
                                SubscriptionTextColor[
                                SubscriptionTextColor.length - 1 < i
                                  ? SubscriptionTextColor.length - 1
                                  : i
                                ],
                            }}
                            className="btn mx-1 w-100 mt-2 "
                          >
                            {m.IsSubscribed && m.currentPlanDetails.length > 0 ? 'Subscribed' : 'Subscribe'}
                          </button>
                          {
                            m.IsSubscribed && m.currentPlanDetails.length > 0 && (
                              <button
                                type="button"
                                onClick={async () => await onSubscribeEvent(m)}
                                // disabled={m.IsSubscribed}
                                style={{
                                  backgroundColor:
                                    SubscriptionHeaderColor[
                                    SubscriptionHeaderColor.length - 1 < i
                                      ? SubscriptionHeaderColor.length - 1
                                      : i
                                    ],
                                  color:
                                    SubscriptionTextColor[
                                    SubscriptionTextColor.length - 1 < i
                                      ? SubscriptionTextColor.length - 1
                                      : i
                                    ],
                                }}
                                className="btn mx-1 w-100 mt-2 "
                              >
                                Change User Limits
                              </button>
                            )
                          }
                        </div>
                      </div>
                    </p>
                    {
                      m.IsSubscribed && m.currentPlanDetails.length > 0 && (
                        <>
                          <h5 className="card-title mb-0" style={{ opacity: "0.7" }}>
                            <div
                              className="heading d-flex align-items-center justify-content-center"
                              style={{
                                backgroundColor:
                                  SubscriptionHeaderColor[
                                  SubscriptionHeaderColor.length - 1 < i
                                    ? SubscriptionHeaderColor.length - 1
                                    : i
                                  ],
                                borderTopLeftRadius: "0px",
                                borderTopRightRadius: "0px",
                                borderBottomLeftRadius: "10px",
                                borderBottomRightRadius: "10px",
                              }}
                            >
                              <div className="d-flex flex-column">
                                <div className="heading-text">{m?.currentPlanDetails[0]?.IsActive === 1 && "Current Plan"}</div>
                                <span className="fs-6">
                                   {m?.currentPlanDetails[0]?.FromDate && DateTime?.fromISO(m?.currentPlanDetails[0]?.FromDate)?.toFormat("dd-MMM-yyyy")} - {m?.currentPlanDetails[0]?.ToDate && DateTime?.fromISO(m?.currentPlanDetails[0]?.ToDate)?.toFormat("dd-MMM-yyyy")} <br />
                                  <span className="fs-6">
                                    {m?.currentPlanDetails[0]?.UserLimitFrom} - {m?.currentPlanDetails[0]?.UserLimitTo} User
                                  </span>
                                  <div>
                                  {`${m.Symbol}${parseInt(m?.currentPlanPrice[0]?.FinalPrice)}`}
                                  <span className="">
                                    .{`${m?.currentPlanPrice[0]?.FinalPrice}`.split(".")[1]}/User/{m?.currentPlanDetails[0]?.SubscriptionDuration}
                                  </span>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </h5>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );

  const getRazorpayOrderAndPay = async (amount, subscriptionName) => {
    const data = {
      currency : CompanyInfo?.CurrencyCode,
      amount : amount
    }
    const RazorPayOrder = await WebService({
      endPoint: `Razorpay/FetchRazorPayOrderNumber`,
      body: data,
      method: 'POST',
      dispatch
    })
    if(RazorPayOrder){
      const options = {
        key: StandardConst.RazorpayKeys.KeyId,
        currency: RazorPayOrder.currency,
        amount: RazorPayOrder.amount,
        name: subscriptionName,
        description: "",
        image: `${StandardConst.apiBaseUrl}/uploads/${CompanyInfo?.Logo}`,
        order_id: RazorPayOrder.id,
        handler: function (response) {
          if(response?.razorpay_payment_id && response?.razorpay_order_id && response?.razorpay_signature){
            PaySubscription(response);
          }
        },
        prefill: {
          name: CompanyInfo?.CompanyName,
          email: CompanyInfo?.Email,
          contact: CompanyInfo?.Phone,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    const scriptloaddnn = loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  return (
    <>
      <div className="base-container container-fluid bgcolor-subscription-page">
        <Box
          sx={{
            width: 1,
            height: 80,
          }}
        >
          <h3 className="ms-4 mt-2">{MasterPageName}</h3>
          <div className="ms-4">
            <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
          </div>
        </Box>
        <div
          style={{ backgroundColor: StandardConst.Colors.primary }}
          className="p-3"
        ></div>

        <div>
          <div className="pricing-table">
            <div className="container pb-5">
              <div className="block-heading">
                <h2>Subscription Plan</h2>
                <p>
                Our subscription plans are suited for all company sizes. Whether you are a big corporate or a startup, we have a plan to suit your needs.                </p>
              </div>
              {view_SubscriptionPackages}
              {view_SelectedPlanDetails}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Subscription;

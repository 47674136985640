    import React, {
        useEffect,
        useState,
        useRef,
    } from "react";
    import { WebService } from "../../Services/WebService";
    import "../Static/Static.css";
    import { ActionPermission, PageInfo } from "../PageInfo";
    import SnackbarComponent from "../../Services/SnackbarComponent";
    import "../Static/Static.css";
    import ActionButton from "../../Services/ActionButton";
    import StaticListComponent from "../../Services/StaticListComponent";
    import { useDispatch, useSelector } from "react-redux";
    import DeleteConfirmAlert from "../../Services/AlertComponent";
    import { NoRecordTemplate } from "../../Services/TableComponent";
    import Button from "react-bootstrap/esm/Button";
    import { StandardConst } from "../../Services/StandardConst";
    
    import AddEditBadgeTemplates from "./AddEditBadgeTemplates";
    import { Avatar, Card, CardActions, CardContent, CardHeader, Chip, IconButton } from "@mui/material";
import HtmlFileReader from "../../Services/HtmlFileReader";
import GridCardComponent from "../../Services/GridCardComponent";
    
    const BadgeTemplatesCardDisplay = () => {

        const ref = useRef();
        const refSnackbar = useRef();
        const addEditModalRef = useRef();
        PageInfo({ pageTitle: "Manage Badge Templates" });
        const [records, setRecords] = useState([]);
        const dispatch = useDispatch();

        const [permission, SetPermission] = useState({});
        const permissionList = useSelector((s) => s.auth.PermissionList??[]);
        const Roles = useSelector((s) => s.auth.AssignRole ?? {});
        const [companyList, setCompanyList] = useState({});

        const getAllCompany = async () => {
            await WebService({
              dispatch,
              endPoint: `CommonUtility/fetchAll/companyinformation`,
              method: "GET",
            }).then((res) => {
              setCompanyList(res.reduce((acc, company) => {
                acc[company.CompanyId] = company.CompanyName;
                return acc;
              }, {}));
            });
        };

        useEffect(() => {
                getAllCompany();
        }, [Roles?.some((element) => element === StandardConst.SystemRole.SuperAdmin)]);

        useEffect(() => {
            SetPermission({
                ManageAdd: ActionPermission("Badge Template - Add"),
                ManageEdit: ActionPermission("Badge Template - Edit"),
                ManageDelete: ActionPermission("Badge Template - Delete"),
                ManageDownload: ActionPermission("Badge Template - Download"),
            });
        }, [permissionList]);

        const fetchBadgeTemplatesList = async() => {
            const data = await WebService({
                endPoint: `CommonUtility/fetchAll/badge_templates?CompanyId=${StandardConst.YesNo.Yes}&isReserved=${StandardConst.YesNo.Yes}`,
                // ?CompanyId=${StandardConst.YesNo.Yes}
                dispatch,
                });
                setRecords(data);
        }

        const getBadgeTypeText = (badgeTypeValue) => {
            const match = (StandardConst.BadgeType).find((type) => type.value === badgeTypeValue);
            return match ? match.text : " ";
        };

        const getBadgeTemplateOrientationText = (value) => {
            const match = (StandardConst.OrientationVisitorBadge).find((TemplateOrientation) => TemplateOrientation.value === value);
            return match ? match.text : " ";
        };

        const renderAfterCalled = useRef(false);

        useEffect(() => {
        if (!renderAfterCalled.current) {
            fetchBadgeTemplatesList();
        }
        renderAfterCalled.current = true;
        }, []);

        const downloadHTMLFile = (file) => {
            const url = `${StandardConst.apiBaseUrl}/Visitor/getBadgeTemplateHTMLContents/${file}`;
            fetch(url)
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then(content => {
                const blob = new Blob([content], { type: "text/plain" });
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = blobUrl;
                a.download = file; 
                a.click();
                URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }

        const onDelete = async (badge_templatesId) => {
        await WebService({
            endPoint: `CommonUtility/badge_templates?badge_templatesId=${badge_templatesId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchBadgeTemplatesList();
        };
    
        const columns = [
            {
                Text: "Badge display",
                render: (dr) => (
                <Card sx={{ maxWidth: 345 }}>
                    <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                        R
                        </Avatar>
                    }
                    //   action={
                    //     <IconButton aria-label="settings">
                    //       <MoreVertIcon />
                    //     </IconButton>
                    //   }
                    title={dr.badge_template_name}
                    subheader="September 14, 2016"
                    />

                    <HtmlFileReader 
                        jsonData={{
                            Name: "Shubham",
                            // Photo: d[0].ProfilePicture,
                            VisitorType: "Business",
                            Company: "Wise",
                            Email: "ws@example.com",
                            Phone: "1234567891",
                            // Logo: CompanyInfo.Logo,
                            Address: "New Add",
                            Designation: "software development",
                            VisitorId: 1,
                            CheckInTime: "Not CheckedIn",
                            // QRData:
                            //   "Name: " + d[0]?.VisitorName +
                            //   "Company: " + d[0]?.VisitorCompany +
                            //   "Email: " + d[0]?.Email +
                            //   "Phone: " + d[0]?.ContactNo
                            // ,
                            VisitorGuid: "123"
                        }} 
                        defaultTemplate={{
                            text: dr.badge_template_name, 
                            value: dr.badge_templatesId,
                            file: dr.html_file_path
                        }} 
                        ShowDropdown={0} 
                        ShowPrintBadgeBtn={0} 
                        ShowDownloadBadgeBtn = {0}
                        SelectedTemplateId={{
                            text: dr.badge_template_name, 
                            value: dr.badge_templatesId,
                            file: dr.html_file_path
                        }}
                        selfVisitorBadge={StandardConst.ShowOrHide.Hide}
                        showDropDownList={StandardConst.BadgeType[0].text}
                    />
                    {/* <CardMedia
                    component="img"
                    height="194"
                    image="/static/images/cards/paella.jpg"
                    alt="Paella dish"
                    /> */}
                    <CardContent>
                        {/* <span>{dr.badge_template_name}</span> */}
                        <span>{dr.badge_template_name}</span>  &nbsp;&nbsp; {(dr.isReserved === StandardConst.YesNo.Yes) && (
                        <Chip 
                            label="Reserved"
                            sx={{ color: "white", padding: "0px"}}
                            color= "primary"
                            size="small"
                        />
                        )}

                        <p>{getBadgeTypeText(dr.badge_type)}</p>
                        <p>{dr.BadgeTemplateHeight} X {dr.BadgeTemplateWidth} mm</p>
                        <Chip
                            label={getBadgeTemplateOrientationText(dr.BadgeTemplateOrientation)}
                            color={dr.BadgeTemplateOrientation === StandardConst.OrientationVisitorBadge[0].value ? 'primary' : "warning"}
                            variant='filled'
                            sx={{borderRadius: "3px" }}
                        />
                    </CardContent>
                    <CardActions>
                        {/* <div className="d-flex"> */}
                            <ActionButton
                                onClick={ () => { downloadHTMLFile(dr.html_file_path);} }
                                IconName="Download"
                                id="btnBadgeTemplatesDownload"
                                disabled={!permission.ManageDownload}
                            />
                            {(dr.isReserved === StandardConst.YesNo.No || (Roles[0] === StandardConst.SystemRole.SuperAdmin && dr.isReserved === StandardConst.YesNo.Yes)) && (
                                <>
                                    <ActionButton
                                        onClick={(e) =>
                                            fnEdit(dr.badge_templatesId)
                                        }
                                        disabled={!permission.ManageEdit}
                                        IconName="Edit"
                                        id="btnBadgeTemplatesEdit"
                                    />
                                    <ActionButton
                                        onClick={(e) =>
                                            ref.current.confirmAlert(
                                            "Delete", //Confirm button text
                                            "Are You Sure", // Text if Alert
                                            "Do you want to delete " + MasterPageName, // Message of Alert
                                            dr.badge_templatesId // Endpoint to hit for delete
                                            )}
                                        disabled={!permission.ManageDelete}
                                        IconName="Delete"
                                        id="btnBadgeTemplatesDelete"
                                    />
                                </>
                            )}
                        {/* </div> */}
                    </CardActions>
                </Card>
                )
            },
        ];
        // if(Roles[0] === StandardConst.SystemRole.SuperAdmin){
        //     columns.push(
        //     {
        //         Text: "HTML FIle Path",
        //         Value: "html_file_path",
        //     },
        //     {
        //       Text: "Available For",
        //       render: (dr) => {
        //         if(dr.isReserved > 0){
        //           return "All Companies";
        //         }
        //         else{
        //           return companyList[dr.CompanyId];
        //         }
        //       },
        //     })
        // }

        columns.push(
        {
            Text: "Action",
            key: "badge_templatesId",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
            <>
                {/* <div className="d-flex"> */}
                    <ActionButton
                        onClick={ () => { downloadHTMLFile(dr.html_file_path);} }
                        IconName="Download"
                        id="btnBadgeTemplatesDownload"
                        disabled={!permission.ManageDownload}
                    />
                    {(dr.isReserved === StandardConst.YesNo.No || (Roles[0] === StandardConst.SystemRole.SuperAdmin && dr.isReserved === StandardConst.YesNo.Yes)) && (
                        <>
                            <ActionButton
                                onClick={(e) =>
                                    fnEdit(dr.badge_templatesId)
                                }
                                disabled={!permission.ManageEdit}
                                IconName="Edit"
                                id="btnBadgeTemplatesEdit"
                            />
                            <ActionButton
                                onClick={(e) =>
                                    ref.current.confirmAlert(
                                    "Delete", //Confirm button text
                                    "Are You Sure", // Text if Alert
                                    "Do you want to delete " + MasterPageName, // Message of Alert
                                    dr.badge_templatesId // Endpoint to hit for delete
                                    )}
                                disabled={!permission.ManageDelete}
                                IconName="Delete"
                                id="btnBadgeTemplatesDelete"
                            />
                        </>
                    )}
                {/* </div> */}
            </>
            ),
        });
    
        const [bData, setBData] = React.useState([
        {
            title: "Visitor Management",
            hrefLink: "#",
        },
        {
            title: "Manage Badge Templates",
            hrefLink: "#",
        },
        ]);
        const MasterPageName = "Manage Badge Templates";
        const confirmMessage = MasterPageName + " Deleted successfully";
        const AddButtonText = "Badge Templates";
        const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
        return (
        <>
            <GridCardComponent
            tableCss={"table-row-card-layout"}
            columns={columns}
            records={records}
            bData={bData}
            MasterPageName={MasterPageName}
            AddButtonText={AddButtonText}
            noRecordCss="p-0"
            noRecordFound={
                <NoRecordTemplate
                headerValue={StandardConst.ManageBadgeTemplateheaderValueNoResults}
                subHeaderValue={StandardConst.ManageBadgeTemplateHeaderRole}
                imageUrl={StandardConst.imageNoRecordsFound}
                actionButton={
                    <>
                    {permission.ManageAdd && (
                        <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                        Add {AddButtonText}
                        </Button>
                    )}
                    </>
                }
                />
            }
            onAddEvent={() => fnEdit()}
            // IsAddButtonVisible={permission?.ManageAdd}
            isSearchRequired={true}
            allowSerialNo={true}
            ShowListGridButtons={true}
            ></GridCardComponent>
            <AddEditBadgeTemplates
            callBackEvent={() => fetchBadgeTemplatesList()}
            ref={addEditModalRef}
            >
            </AddEditBadgeTemplates>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
        </>
        );
    };

    export default BadgeTemplatesCardDisplay;
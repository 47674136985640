/* #region Import */
import React, { forwardRef, useState, useImperativeHandle, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, InputText, FormAutoCompleteDropdownCard } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../Static/Static.css";
import { ActionPermission } from "../PageInfo";
import _ from "underscore";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import { StandardConst } from "../../Services/StandardConst";
/* #endregion */

const AddEditPerformanceComponent = (prop, ref) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ performance: {}, IsOwner: true });
  const [records, setRecords] = useState([]);
  const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
  const childUser = useSelector((s) => s.auth.ChildUsers ?? []);
  const dispatch = useDispatch();
  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);

  const [permission, setPermission] = useState({});
  
  useEffect(() => {
    setPermission({
      ManageUser: ActionPermission("Performance - All Employees"),
      ManageMyTeam: ActionPermission("Performance - My Team"),
      ManageDate: ActionPermission("Performance - Modify Date"),
      ManageEmpRating: ActionPermission("Performance - Employee Rating"),
      ManageMangerRating: ActionPermission("Performance - Manager Rating"),
      ManageFinalRating: ActionPermission("Performance - Final Rating"),
      ManageFinalReviewComment: ActionPermission(
        "Performance - Final Review Comment"
      ),
      ManageHRComment: ActionPermission("Performance - HR Comment")
    });

    
  }, [permissionList]);

  useEffect(() => {
    if(loggedUser > 0){
      fetchAllUser();
    }
  }, [permission]);

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id > 0){
        setShow(false);
        await fetchData(id);
        setShow(true);
      }
    },
  }));

  const [userList, setUserList] = useState([]);
  const fetchAllUser = async () => {
    const usersData = await WebService({
      endPoint: `Visitor/fetchAllEmployees`,
      dispatch,
      requiredLoader: false
    })
    .then((res) =>
      _.map(res, (m) => {
        return {
          EmployeeId: m.EmployeeId,
          heading: m.FullName,
          description: m.Designation,
          avatar: `${StandardConst.apiBaseUrl}/uploads/${m.ProfileImage}`
        };
      })
    )
    .then((r) => {
      if (!permission.ManageUser) {
        let listOfUser = [loggedUser];
        if (permission.ManageMyTeam) {
          listOfUser = listOfUser.concat(childUser);
        }
        return r.filter(
          (f) => listOfUser.filter((u) => u === f.value).length > 0
        );
      }
      return r;
    });

    setUserList(usersData);
  };

  const fetchData = async (id) => {
    const data = await WebService({
      dispatch,
      // endPoint: `Performance/FetchById/${id || 0}`,
      endPoint: `CommonUtility/Edit/employeeperformance?PerformanceId=${id}`,
    }).then(res => ({performance : res.length ? res[0] : {}}));
    
    data.performance ??= {};

    data.performance.EmployeeId = [userList.find(user => user.EmployeeId === data.performance.EmployeeId)];
    // data.IsOwner =
    //   (id ?? 0) === 0 || data.performance.EmployeeId === loggedUser;
    setData(data);
  }

  useEffect(() => {
    if (
      (data.performance?.EmployeeId ?? 0) !== 0 &&
      !isNaN(data.performance.EmployeeId)
    ) {
      const p1 = fetchDetails();
      Promise.all([p1]);
    } else {
      setRecords([]);
    }
  }, [data.performance?.PerformanceId, data.performance?.EmployeeId]);
  const fetchDetails = async (employeeId = null, performanceId = null) => {
    performanceId ??= data.performance?.PerformanceId ?? 0;
    employeeId ??= data.performance?.EmployeeId ?? loggedUser;

    const endPoint = `Performance/Details?${generateQueryStringByObject({
      employeeId: isNaN(data.performance?.EmployeeId)
        ? loggedUser
        : data.performance?.EmployeeId ?? loggedUser,
      performanceId: data.performance?.PerformanceId ?? 0,
    })}`;
    setRecords([]);
    var result = await WebService({ dispatch, endPoint });
    setRecords(result);
  };
  const handleClose = () => {
    setShow(false); 
    setData({ performance: {}, IsOwner: true });
  }
  const requiredMessage = "This is a required field";
  const ratingRangeErrorMessage = "Range between 1 to 5";
  const schema = yup
    .object()
    .shape({
      // EmployeeId: yup
      //   .number()
      //   .typeError(requiredMessage)
      //   .required(requiredMessage),
      FromDate: yup.date().typeError(requiredMessage).required(requiredMessage),
      ToDate: yup
        .date()
        .typeError(requiredMessage)
        .required(requiredMessage)
        .when("FromDate", (fromDate, passSchema) =>
          fromDate.toLocaleString() === "Invalid Date"
            ? passSchema
            : passSchema.min(fromDate)
        ),
      EmpSelfScore: yup
        .number()
        .typeError(requiredMessage)
        .min(1, ratingRangeErrorMessage)
        .max(5, ratingRangeErrorMessage)
        .when([], (_, passSchema) =>
          permission.ManageEmpRating
            ? passSchema.required(requiredMessage)
            : passSchema.notRequired()
        ),
      FinalAgreedScore: yup
        .number()
        .typeError(requiredMessage)
        .min(1, ratingRangeErrorMessage)
        .max(5, ratingRangeErrorMessage)
        .when([], (_, passSchema) =>
          permission.ManageFinalRating //&& !data.IsOwner
            ? passSchema.required(requiredMessage)
            : passSchema.notRequired()
        ),
      ManagerScore: yup
        .number()
        .typeError(requiredMessage)
        .min(1, ratingRangeErrorMessage)
        .max(5, ratingRangeErrorMessage)
        .when([], (_, passSchema) =>
          permission.ManageMangerRating //&& !(data?.IsOwner ?? true)
            ? passSchema.required(requiredMessage)
            : passSchema.notRequired()
        ),
    })
    .required();
  const onSubmit = async (data) => {
    data.details = records;

    if (!permission.ManageUser) {
      data.EmployeeId ??= loggedUser;
    }else if(data.EmployeeId !== undefined) {
      data.EmployeeId = data.EmployeeId[0].EmployeeId;
    }
    await WebService({ dispatch, endPoint: "Performance/Submit", body: data });

    handleClose();
    prop.callBackEvent();
  };
  const formRef = useRef();
  const commonStyles = {
    border: 1,
    borderBottom: 1,
    borderColor: "grey",
    p: 2,
  };

  const boxCommonStyles = {
    "& > :not(style)": {
      m: 1,
    },
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcente"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {(data.performance.PerformanceId || 0) === 0
              ? "Add Performance"
              : "Edit performance"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            defaultValues={data.performance}
            onSubmit={onSubmit}
            validationSchema={schema}
            ref={formRef}
            id="hook-form"
          >
            <Row className="d-flex">
              {" "}
              {(permission.ManageUser || permission.ManageMyTeam) && (
                <>
                  <Col className="col-12 col-md-2 custom_form_label_modal d-flex align-items-center">
                    Select User
                  </Col>
                  <Col className={`col-12 col-md-10 ${window.innerWidth <= 600 ? '' : "custom_padding_left"}`}>
                    {" "}
                    <FormAutoCompleteDropdownCard
                      name="EmployeeId"
                      data={[{}].concat(userList)}
                      optionText="heading"
                      // placeholder="Choose a Employee"
                      className="form-control"
                      isRequired="true"
                      value={data?.performance?.EmployeeId !== undefined ? data?.performance?.EmployeeId[0] : ""}
                      setValue={(v) =>
                        setData((r) => ({
                          ...r,
                          performance: {
                            ...r.performance,
                            EmployeeId: parseInt(v[0].EmployeeId),
                          },
                        }))
                      }
                  />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col className="col-md-6 pt-2">
                <FormInputText
                  type="date"
                  label="From Date"
                  name="FromDate"
                  className="form-control"
                  disabled={!permission.ManageDate}
                  isRequired="true"
                  setValue={(v) =>
                    setData({
                      ...data,
                      performance: { ...data.performance, FromDate: v },
                    })
                  }
                  max={data?.performance?.ToDate}
                />
              </Col>
              <Col className="col-md-6 pt-2">
                {" "}
                <FormInputText
                  label="To Date"
                  name="ToDate"
                  type="date"
                  className="form-control"
                  disabled={!permission.ManageDate}
                  isRequired="true"
                  setValue={(v) =>
                    setData({
                      ...data,
                      performance: { ...data.performance, ToDate: v },
                    })
                  }
                  min={data?.performance?.FromDate}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-md-6">
                <FormInputText
                  label="Manager Rating"
                  name="ManagerScore"
                  type="number"
                  className="form-control"
                  disabled={
                    !permission.ManageMangerRating
                    // !(permission.ManageMangerRating && !data.IsOwner)
                  }
                  isRequired="true"
                />
              </Col>
              <Col className="col-md-6">
                {" "}
                <FormInputText
                  label="Employee Rating"
                  name="EmpSelfScore"
                  type="number"
                  className="form-control"
                  disabled={!permission.ManageEmpRating}
                  isRequired="true"
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-md-6">
                <FormInputText
                  label="Final Rating"
                  name="FinalAgreedScore"
                  type="number"
                  className="form-control"
                  disabled={
                    !permission.ManageFinalRating
                    // !(permission.ManageFinalRating && !data.IsOwner)
                  }
                  isRequired="true"
                />
              </Col>{" "}
            </Row>
            <Row>
              <Col className="col-12 col-md-2 custom_form_label_modal">
                HR Comments
              </Col>
              <Col className={`col-12 col-md-10 ${window.innerWidth <= 600 ? '' : "custom_padding_left"}`}>
                <FormInputText
                  name="HRComments"
                  as="textarea"
                  rows={6}
                  className="form-control"
                  disabled={!permission.ManageHRComment}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-md-2 custom_form_label_modal">
                Final Review Comment
              </Col>
              <Col className={`col-12 col-md-10 ${window.innerWidth <= 600 ? '' : "custom_padding_left"}`}>
                <FormInputText
                  name="FinalReviewComments"
                  as="textarea"
                  rows={6}
                  className="form-control"
                  disabled={
                    !permission.ManageFinalReviewComment
                    // !(permission.ManageFinalReviewComment && !data.IsOwner)
                  }
                />
              </Col>
            </Row>

            <div class="p-2 mb-2 bg-light text-dark">
              {" "}
              <h5 className="text-center ">KRA Details</h5>
            </div>

            <Row>
              <Col className="table-responsive p-0">
                <Table table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Short Desc</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Description</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Self Assessment</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Manager Assessment</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <tbody>
                    {records.map((detail, index) => (
                      <KraDetails
                        value={detail}
                        key={index}
                        IsOwner={data.IsOwner}
                        setValue={(val) => {
                          let newArr = [...records];
                          newArr[index] = val;
                          setRecords(newArr);
                        }}
                      />
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            id="btnPerformanceSubmit"
            type="submit"
            form="hook-form"
          >
            {(data.performance.PerformanceId || 0) === 0
              ? "Submit"
              : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const KraDetails = ({ value, setValue, IsOwner }) => {
  const [data, setData] = useState(value);
  const permission = {
    ManageEmployeeSelfAssessment: ActionPermission(
      "Performance - Employee Self Assessment"
    ),
    ManageManagerAssessment: ActionPermission(
      "Performance - Manager Assessment"
    ),
    // !IsOwner && ActionPermission("Performance - Manager Assessment"),
  };
  useEffect(() => {
    setValue(data);
  }, [data]);

  return (
    <TableRow className="col-md-12">
      <TableCell className="table-td-center">
        {data.KRAShortDescription}
      </TableCell>
      <TableCell className="table-td-left">{data.KRADescription}</TableCell>
      <TableCell className="table-td-center">
        <InputText
          name="EmplyeeSelfAssessment"
          as="textarea"
          value={data.EmployeeSelfAssessment}
          setValue={(val) => setData({ ...data, EmployeeSelfAssessment: val })}
          disabled={!permission.ManageEmployeeSelfAssessment}
        />
      </TableCell>
      <TableCell className="table-td-center">
        <InputText
          name="ManagerAssessment"
          as="textarea"
          value={data.ManagerAssessment}
          setValue={(val) => setData({ ...data, ManagerAssessment: val })}
          disabled={!permission.ManageManagerAssessment}
        />
      </TableCell>
    </TableRow>
  );
};

export default forwardRef(AddEditPerformanceComponent);

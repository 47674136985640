import React from "react";
import { Link } from "react-router-dom";
import "../Login.css";
import { useSelector } from "react-redux";

function FooterComponent() {
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

  return (
    <div className="bg-blue py-2">
      <div className="row d-flex justify-content-between px-3">
        <div className="col-md-5 col-12 social-contact ml-4 ml-sm-auto">
          <Link
            className="link-route"
            to={`/Register?company=${(CompanyInfo ?? {}).Code}`}
            id="btnFooterRegister"
          >
            Register
          </Link>
          <span className="vertical-line mx-2"></span>
          <Link
            className="link-route"
            to={`/HolidayList?company=${(CompanyInfo ?? {}).Code}`}
            id="btnFooterHoliday"
          >
            Holiday
          </Link>
          <span className="vertical-line mx-2"></span>
          <Link
            className="link-route"
            to={`/Login?company=${(CompanyInfo ?? {}).Code}`}
            id="btnFooterlogin"
          >
            Login
          </Link>
        </div>
        <small className="col-md-5 col-12 ml-4 ml-sm-5 mb-2 py-0">
          Copyright &copy; {new Date().getFullYear()}. All rights reserved.
        </small>
        <div className="col-md-2 justify-content-end col-12 social-contact ml-4 ml-sm-auto">
          <a href={(CompanyInfo ?? {}).facebookUrl}>
            <span className="fa fa-facebook me-4 text-sm"></span>
          </a>
          <a href={(CompanyInfo ?? {}).twitterUrl}>
            <span className="fa fa-twitter me-4 mr-sm-5 text-sm"></span>
          </a>
          <a href={(CompanyInfo ?? {}).linkedInUrl}>
            <span className="fa fa-linkedin me-4 text-sm"></span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;

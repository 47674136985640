import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import QrReader from 'react-qr-scanner';
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { WebService } from "../../Services/WebService";
import {
  Alert,
  Card, Container, IconButton,
} from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import { WSErrorAlert, WSInfoAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { PageInfo } from "../PageInfo";
import { DateTime } from "luxon";
import Modal from "react-bootstrap/Modal";
import HtmlFileReader from "../../Services/HtmlFileReader";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CloseIcon from '@mui/icons-material/Close';
import TableComponent, { NoRecordTemplate } from "../../Services/TableComponent";
import ActionButton from "../../Services/ActionButton";
import { Form, FormAutoCompleteDropdown, FormInputDropdown, FormInputText, InputDropdown } from "../Form";
import { chain } from "underscore";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import { format } from "date-fns";
import BootstrapModal from "react-bootstrap/Modal";


const VehicleInVehicleOut = () => {
  const dispatch = useDispatch();
  const QRScanRef = useRef();
  const pageName = "Vehicle In-Out";
  PageInfo({ pageTitle: pageName });
  // "environment" dene per by default back camera open hota hai or front camera open krne ke liye "user" dena hota hai
  const [openFrontOrBackCamera , setOpenFrontOrBackCamera] = useState(StandardConst.Camera.BackCamera);
  const [tripStartTripEndFormData, setTripStartTripEndFormData] = useState({});
  const [selectedOfficeLocationId, setSelectedOfficeLocationId] = useState(null);
  
  const MasterPageName = pageName;
  const [bData, setBData] = React.useState([
    {
      title: "Vehicle Management",
      hrefLink: "#",
    },
    {
      title: pageName,
      hrefLink: "#",
    },
  ]);

  

  const [isDisableReportingInTime, setIsDisableReportingInTime] = useState(false);
  const [isDisableCheckInTime, setIsDisableCheckInTime] = useState(false);
  const [selectedVehicleData, setSelectedVehicleData] = useState([]);
  const [officeLocationInfo, setOfficeLocationInfo] = useState([]);
  const [companyVehicleDropdown, setCompanyVehicleDropdown] = useState([]);
  const [companyDriverDropdown, setCompanyDriverDropdown] = useState([]);
  const [companyDriversData, setCompanyDriversData] = useState([]);

  const fetchCompanyVehicleDropdownData = async () => {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/vehicleregistration`,
    }).then((res) => setCompanyVehicleDropdown(res.map(item => ({value: item.VehicleId, text: item.RegistrationNumber}))));
  };

  const fetchScannerEmployeeOrUserOfficeLocation = async () => {
    fetchCompanyDriverDropdownData();

    await WebService({
      dispatch,
      endPoint: `Vehicle/getOfficeLocationForScannerEmployeeOrUser`,
    }).then((res) => {
      if(res.length > 0){
        setOfficeLocationInfo(res);
        fetchCompanyDriverDropdownData(res[0].OfficeLocationId);
      }
    });
  };
  
  const fetchCompanyDriverDropdownData = async (OfficeLocationId) => {
    OfficeLocationId ??= null;

    await WebService({
      dispatch,
      endPoint: `Vehicle/getOfficeLocationListInVehicleInOut?OfficeLocationId=${OfficeLocationId}`,
      // endPoint: `CommonUtility/companydriversinformation`,
    }).then((res) => setCompanyDriverDropdown(res.map(item => ({value: item.DriverId, text: item.DriverName}))));
  };

  const fetchCompanyDriversData = async () => {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/companydriversinformation`,
    }).then((res) => setCompanyDriversData(res));
  };

  useEffect(() => {
    fetchScannerEmployeeOrUserOfficeLocation();
    fetchCompanyVehicleDropdownData();
    fetchCompanyDriversData();
  },[]);


  // This code is qr scanner
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);
  // const [key, setKey] = useState(0);

  // const handleScan = (data) => {
  //   if (data) {
  //     setKey((prevKey) => prevKey + 1);
  //     FetchVehicleDetails(data.text);
  //   }
  // }

  // const handleError = (err) => {
  //   console.log(err);
  // }

  const Schema = yup.object().shape({
    VehicleId: yup.array().of(
                    yup.object().shape({
                      value: yup.string(),
                      text: yup.string(),
                    })
                  )
                  .test({
                    test: function (value) {
                      if(typeof value[0] === "string" ) {
                        return false;
                      }
                      return true;
                    },
                    message: 'Please select an option',
                  })
                  .typeError(StandardConst.requiredMessage)
                  .min(1, StandardConst.requiredMessage),

    OdometerStartReading: yup.number().transform(value => (isNaN(value) || value === undefined ? undefined : value)).nullable().min(0).optional(),
    OdometerEndReading: yup.number().transform(value => (isNaN(value) || value === undefined ? undefined : value)).nullable().min(0).optional().moreThan(yup.ref('OdometerStartReading'), "Trip end meter reading should be more than trip start meter reading")
  });

  // scannedQRData me qr code me data ayega - agar Vehicle hai to "V,{Guid}" or agar employee hai to "E,{Guid}"
  const FetchVehicleDetails = async (scannedQRData) => {
    setTripStartTripEndFormData({});
    let showTheErrorMsg = true;
    const arrQRCodeData = scannedQRData.split(',');

    if(scannedQRData !== "" && arrQRCodeData.length > 0) {
      const qrTextVehicle = arrQRCodeData[0];
      const Guid = arrQRCodeData[1];

      // this is for Vehicles badges -- Guid ki length 36 hoti hai to humne check kr liye ki agar 32 se jada hai to iska mtlab hai ki hamara hi qr code hai
      if(qrTextVehicle === "Vehicle" && Guid.length > 32) { 
        const vehicleData = await WebService({
          dispatch,
          endPoint: `CommonUtility/Edit/vehicleregistration?Guid='${Guid}'`,
        });
        if(vehicleData.length > 0){
          showTheErrorMsg = false;
          if(vehicleData[0].CompanyId === CompanyId){
            setTripStartTripEndFormData({VehicleId: [{"value": vehicleData[0].VehicleId,"text": vehicleData[0].RegistrationNumber}]});
            fetchSelectedVehicleData(vehicleData[0].VehicleId)
          }else{
            WSErrorAlert("Error", "This vehicle is not recognize please register.");
          }
        }
      }
    }

    if(showTheErrorMsg){
      WSErrorAlert("Invalid QR", "This Vehicle is not a valid.");
    }

  };

  // const toggleCamera = () => {
  //   if(openFrontOrBackCamera === StandardConst.Camera.BackCamera){
  //     setOpenFrontOrBackCamera(StandardConst.Camera.FrontCamera);
  //   }else{
  //     setOpenFrontOrBackCamera(StandardConst.Camera.BackCamera);
  //   }
  // }

  const [buttonClicked, setButtonClicked] = useState(null);
  const onSubmit = async (data) => {
    data.VehicleId = data.VehicleId[0].value;
    data.Action = buttonClicked;
    const returnMsg = await WebService({
      dispatch,
      endPoint: `Vehicle/vehicleInVehicleOutByForm`,
      body: data,
      method: 'POST',
    });

    if(returnMsg){
      fetchSelectedVehicleData(data.VehicleId);

      switch (returnMsg) {
        case StandardConst.TripStartTripEndErrMsgConstants.OfficeLocationNotFound :
          WSErrorAlert("Error", "Your office location is not available please contact your administrator.");
          break;

        case StandardConst.TripStartTripEndErrMsgConstants.GuidNotFound :
          WSErrorAlert("Error", "This vehicle does not exist");
          break;

        case StandardConst.TripStartTripEndErrMsgConstants.TripStart :
          WSSuccessAlert("Success", "Vehicle successfully CHECKED IN for this office location.");
          break;

        case StandardConst.TripStartTripEndErrMsgConstants.TripEnd :
          WSSuccessAlert("Success", "Vehicle successfully CHECKED OUT for this office location.");
          break;

        case StandardConst.TripStartTripEndErrMsgConstants.ReportingInTime :
          WSSuccessAlert("Success", "Vehicle Reporting successfully done this office location.");
          break;

        case StandardConst.TripStartTripEndErrMsgConstants.ReportingOutTime :
          WSSuccessAlert("Success", "Reporting out.");
          break;

        case StandardConst.TripStartTripEndErrMsgConstants.ReportingNotFound :
          WSErrorAlert("Error", "Please Report first this vehicle.");
          break;
      }
    }
  };

  const fetchSelectedVehicleData = async (VehicleId) => {
    await WebService({
      dispatch,
      endPoint: `CommonUtility/Edit/vehicleinvehicleout?VehicleId=${VehicleId}`
    }).then((res) => {
      // This code for ORDER BY VehicleInVehicleOutId DESC
      const reverseData = res.sort((a, b) => b.VehicleInVehicleOutId - a.VehicleInVehicleOutId);
      setSelectedVehicleData(reverseData);
      setIsDisableReportingInTime(res.some(item => item.ReportingInTime !== null && item.ReportingOutTime === null));
      setIsDisableCheckInTime(res.some(item => item.TripStartDate !== null && item.TripEndDate === null));
    })
  };

  const VehicleLogColumns = [
    {
        Text: "Vehicle",
        Value: "VehicleId",
        render: (dr) => companyVehicleDropdown?.find(item => item.value === dr.VehicleId)?.text
    },
    {
        Text: "Driver",
        render: (dr) => companyDriversData?.find(item => item.DriverId === dr.DriverId)?.DriverName
    },

    {
      Text: "OfficeLocationId",
      render: (dr) => officeLocationInfo?.find(item => item.OfficeLocationId === dr.OfficeLocationId)?.Location

    },
    {
        Text: "Trip Time",
        render: (dr) => {
          let returnString = "";
          
          if(dr.TripStartDate !== null){
            const tripStartDate = new Date(dr.TripStartDate);
            returnString = format(tripStartDate, 'dd-MMM-yyyy hh:mm a');
          }
          if(dr.TripEndDate !== null){
            const tripEndDate = new Date(dr.TripEndDate);
            returnString += " - " + format(tripEndDate, 'dd-MMM-yyyy hh:mm a');
          }
          return returnString;
        }
    },
    {
      Text: "Reporting Time",
      DateFormat: "dd-MMM-yyyy hh:mm a",
      render: (dr) => {
        let returnString = "";
        
        if(dr.ReportingInTime !== null){
          const ReportingInTime = new Date(dr.ReportingInTime);
          returnString = format(ReportingInTime, 'dd-MMM-yyyy hh:mm a');
        }
        if(dr.ReportingOutTime !== null){
          const ReportingOutTime = new Date(dr.ReportingOutTime);
          returnString += " - " + format(ReportingOutTime, 'dd-MMM-yyyy hh:mm a');
        }
        return returnString;
      }
    },
    {
      Text: "Meter Reading",
      render: (dr) => (dr.OdometerStartReading !== null && dr.OdometerEndReading !== null) ?
          dr.OdometerStartReading + " - " + dr.OdometerEndReading + " (" + (dr.OdometerEndReading-dr.OdometerStartReading) + " km)" : "",
    },
];

const htmlForm = <>
                  <Form 
                      defaultValues={tripStartTripEndFormData}
                      onSubmit={onSubmit}
                      validationSchema={Schema}
                  >
                      <div className="card shadow-none footer-widget">
                          <Alert severity="info" sx={{ width: "95%" }}>Vehicle Information</Alert>
                          <div className="card-body">
                              <div className="row mt-1">
                                  <div className="row">
                                      {(officeLocationInfo.length > 0) && (
                                        <>
                                          <div className="col-md-12">
                                            <FormInputDropdown
                                                label="Office Location"
                                                name="OfficeLocationId"
                                                ddOpt = {
                                                  chain(officeLocationInfo ?? [])
                                                    .map((m) => ({
                                                      value: m.OfficeLocationId,
                                                      text: m.Location,
                                                    }))
                                                    .value()
                                                }
                                                setValue = {(val) => fetchCompanyDriverDropdownData(val)}
                                            />
                                          </div>
                                        </>
                                      )}


                                      <div className="col-md-12">
                                          <FormAutoCompleteDropdown
                                            name="VehicleId"
                                            data={companyVehicleDropdown}
                                            label="Vehicles"
                                            optionText="text"
                                            // freeText="true"
                                            isRequired="true"
                                            setValue={(val) => {
                                              if(val[0].value !== undefined) {
                                                fetchSelectedVehicleData(val[0].value)
                                              }
                                            }}
                                          />
                                      </div>

                                      <div className="col-md-12 pb-1">
                                          <FormInputDropdown
                                              label="Drivers"
                                              name="DriverId"
                                              ddOpt={companyDriverDropdown}
                                          />
                                      </div>

                                      <div className="col-md-12">
                                          <FormInputText
                                              label="Odometer Start Reading"
                                              name="OdometerStartReading"
                                              type="number"
                                              min={0}
                                              onInput={(event) => {
                                                event.target.value = Math.abs(event.target.value);
                                              }}
                                          />
                                      </div>

                                      <div className="col-md-12">
                                          <FormInputText
                                              label="Odometer End Reading"
                                              name="OdometerEndReading"
                                              type="number"
                                              min={0}
                                              onInput={(event) => {
                                                event.target.value = Math.abs(event.target.value);
                                              }}
                                          />
                                      </div>

                                  </div>
                              </div>
                              <div>
                                  <Button
                                      variant="outline-primary"
                                      type="submit"
                                      id="btnSubmit"
                                      className="px-4 float-end mt-4 mb-1"
                                      style={{ marginRight: "27px" }}
                                      onClick={() => setButtonClicked(StandardConst.TripStartTripEndErrMsgConstants.TripEnd)}
                                      // disabled={isDisableCheckOutTime}
                                      disabled={!isDisableCheckInTime}
                                  >
                                      Trip End
                                  </Button>
                                  <Button
                                      variant="outline-primary"
                                      type="submit"
                                      id="btnSubmit"
                                      className="px-4 float-end mt-4 mb-1"
                                      style={{ marginRight: "27px" }}
                                      onClick={() => setButtonClicked(StandardConst.TripStartTripEndErrMsgConstants.TripStart)}
                                      disabled={isDisableCheckInTime}
                                  >
                                      Trip Start
                                  </Button>

                                  <Button
                                      variant="outline-primary"
                                      type="submit"
                                      id="btnSubmit"
                                      className="px-4 float-end mt-4 mb-1"
                                      style={{ marginRight: "27px" }}
                                      onClick={() => setButtonClicked(StandardConst.TripStartTripEndErrMsgConstants.ReportingOutTime)}
                                      disabled={!isDisableReportingInTime}
                                  >
                                      Reporting Out
                                  </Button>
                                 
                                  <Button
                                      variant="outline-primary"
                                      type="submit"
                                      id="btnSubmit"
                                      className="px-4 float-end mt-4 mb-1"
                                      style={{ marginRight: "27px" }}
                                      onClick={() => setButtonClicked(StandardConst.TripStartTripEndErrMsgConstants.ReportingInTime)}
                                      disabled={isDisableReportingInTime}
                                  >
                                      Reporting In
                                  </Button>
                              </div>
                          </div>
                      </div>
                  </Form>
                </>

  return (
    <div className="base-container container-fluid ">
      <Box
        sx={{
          width: 1,
          height: 65,
        }}
      >
        <h5 className="ms-4 mt-2">{MasterPageName}</h5>
        <div className="ms-4">
          <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
        </div>
      </Box>
      <div className="primary-bg-color p-1">
        <Button className="float-end" onClick={ async () => await QRScanRef.current.openModal()}>Open Scanner</Button>
      </div>
      <Container className='mt-2'>
          <div className="row">
              <div className="col-12 col-md-8">
                {/* this condition for intial time render form */}
                {(Object.keys(tripStartTripEndFormData).length === 0) && ( htmlForm )}

                {/* this condition for rerendring data in scan */}
                {(Object.keys(tripStartTripEndFormData).length > 0) && ( htmlForm )}
              </div>
              {/* <div className="col-12 col-md-4">
                  <div id="Vehicle-QRCode">
                      <div className="Heading mb-4">
                          <Alert severity="info">Scan your QR-Code</Alert>
                      </div>
                      <Button 
                        onClick={ async () => await QRScanRef.current.openModal()}
                      >Open Scanner</Button>
                      <div className="row mb-4 justify-content-center mt-2">
                        <Card sx={{ maxWidth: 600, backgroundColor: "#fbfbfb" }}>
                          <div className="text-center">
                              <QrReader
                                key={key}
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                constraints={{
                                  video: { facingMode: openFrontOrBackCamera }
                                }}
                                style={{ width: '100%' }}
                              />
                              <span>
                                Scan the QR CODE on the vehicle  
                                <ActionButton
                                  onClick={() => toggleCamera()}
                                  IconName="SwitchCameraLarge"
                                  IconTooltip="Switch Camera"
                                  id="SwitchCamera"
                                />
                                <ActionButton
                                  IconName="FullScreenLarge"
                                  IconTooltip="Full Screen"
                                  id="FullScreen"
                                />
                                <ActionButton
                                  IconName="FullScreenExitLarge"
                                  IconTooltip="Exit Full Screen"
                                  id="FullScreenExit"
                                />
                              </span>
                          </div>
                        </Card>
                      </div>
                  </div>
              </div> */}
          </div>

          {(selectedVehicleData.length > 0) && (
            <div className="row">
               <TableComponent
                  columns={VehicleLogColumns}
                  data={selectedVehicleData}
                  noRecordCss="p-0"
                  noRecordFound={
                  <NoRecordTemplate
                      headerValue="record not found."
                      // subHeaderValue="Please fill out the insurance details"
                      imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                  />
                  }
                  // onAddEvent={() => openInsuranceDetailsModal(0)}
                  IsAddButtonVisible={false}
                  isSearchRequired={false}
                  // initialSearchContent={searchParams.get("search") ?? ""}
                  allowSerialNo={true}
                  isExcelExportButtonVisible={false}
                />
            </div>
          )}
      </Container>
      <ModalQRScanner ref={QRScanRef} callBackEvent={async (text) => await FetchVehicleDetails(text)}/>
    </div>
  );
};

const ModalQRScanner = forwardRef(({ callBackEvent }, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [openFrontOrBackCamera , setOpenFrontOrBackCamera] = useState(StandardConst.Camera.BackCamera);
  


  useImperativeHandle(ref, () => ({
      openModal: async () => {
          setShow(true);
      },
  }));

  const handleClose = () => {
      setShow(false);
  };

  const onQrScan = async (data) => {
    if(data?.text){
      handleClose();
      callBackEvent(data.text);
    }
  };

  const handleError = (err) => {
    console.log(err);
  }

  const toggleCamera = () => {
    if(openFrontOrBackCamera === StandardConst.Camera.BackCamera){
      setOpenFrontOrBackCamera(StandardConst.Camera.FrontCamera);
    }else{
      setOpenFrontOrBackCamera(StandardConst.Camera.BackCamera);
    }
  }
  
  return (
      <>
          <BootstrapModal show={show} onHide={handleClose}>
              <BootstrapModal.Header closeButton>
              <BootstrapModal.Title>Scan your Qr here</BootstrapModal.Title>
              </BootstrapModal.Header>
              <BootstrapModal.Body>
                <div className="row mb-4 justify-content-center mt-2">
                  <Card sx={{ maxWidth: 600, backgroundColor: "#fbfbfb" }}>
                    <div className="text-center">
                        {(openFrontOrBackCamera === StandardConst.Camera.BackCamera) && (
                          <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={onQrScan}
                            constraints={{
                              video: { facingMode: openFrontOrBackCamera }
                            }}
                            style={{ width: '100%' }}
                          />
                        )}

                        {(openFrontOrBackCamera === StandardConst.Camera.FrontCamera) && (
                          <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={onQrScan}
                            constraints={{
                              video: { facingMode: openFrontOrBackCamera }
                            }}
                            style={{ width: '100%' }}
                          />
                        )}
                        <span>
                          Scan the QR CODE on the vehicle  
                          <ActionButton
                            onClick={() => toggleCamera()}
                            IconName="SwitchCameraLarge"
                            IconTooltip="Switch Camera"
                            id="SwitchCamera"
                          />
                          <ActionButton
                            IconName="FullScreenLarge"
                            IconTooltip="Full Screen"
                            id="FullScreen"
                          />
                          <ActionButton
                            IconName="FullScreenExitLarge"
                            IconTooltip="Exit Full Screen"
                            id="FullScreenExit"
                          />
                        </span>
                    </div>
                  </Card>
                </div>
              </BootstrapModal.Body>
          </BootstrapModal>
      </>
  );
});

export default VehicleInVehicleOut;

import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { PageInfo } from '../PageInfo';
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import { WSSuccessAlert } from '../../Services/WSAlert';
import BootstrapModal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import CurrencyInput from 'react-currency-input-field';
import * as yup from "yup";
import { format } from 'date-fns';

const VehicleMaintenanceAndRepairs = () => {
    // const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    // const [permission, SetPermission] = useState({});
    const [vehicleMaintenanceAndRepairData, setVehicleMaintenanceAndRepairData] = useState([]);
    PageInfo({ pageTitle: "Maintenance And Repairs" });
    // useEffect(() => {
    //     SetPermission({
    //         ManageAdd: ActionPermission("Vehicle - Add"),
    //         ManageEdit: ActionPermission("Vehicle - Edit"),
    //         ManageDelete: ActionPermission("Vehicle - Delete"),
    //         ManageQRCode: ActionPermission("Vehicle - QR Code"),
    //     });
    // }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Maintenance And Repairs";
    const confirmMessage = MasterPageName + " record Deleted successfully";
    const MaintenanceAndRepairFormRef = useRef();
    const navigate = useNavigate();
    const AddButtonText = "Maintenance And Repair";
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    const [vehicleData, setVehicleData] = useState([]);
    const [companyCurrencyData, setCompanyCurrencyData] = useState({});


    const onDelete = async (MaintenanceAndRepairId) => {
        await WebService({
            endPoint: `CommonUtility/vehiclemaintenanceandrepairs?MaintenanceAndRepairId=${MaintenanceAndRepairId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchVehicleMaintenanceAndRepairList();
    };

    const fetchVehicleMaintenanceAndRepairList = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/vehiclemaintenanceandrepairs`,
            dispatch,
        });
        setVehicleMaintenanceAndRepairData(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    const fetchVehicleData = async () => {
        await WebService({
            endPoint: `CommonUtility/vehicleregistration`,
            dispatch,
        }).then((res) => {
            if(res.length > 0){
                setVehicleData(res);
            }
        })
    };

    const fetchCurrencyData = async () => {
        const companyInformation = await WebService({
            endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${companyInfo.CompanyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        const currencyInformation = await WebService({
            endPoint: `CommonUtility/Edit/staticcurrency?CurrencyId=${companyInformation.CurrencyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        const currencyLocaleCode = await WebService({
            endPoint: `CommonUtility/Edit/countries?CountryId=${currencyInformation.CountryId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        currencyInformation.LocaleCode = currencyLocaleCode.LocaleCode;
        setCompanyCurrencyData(currencyInformation);
    };


    useEffect(() => {
        if(companyInfo?.CurrencyId){
            fetchCurrencyData();
        }
    }, [companyInfo]);

    useEffect(() => {
        fetchVehicleData();
        fetchVehicleMaintenanceAndRepairList();
    }, []);

    const [bData ] = React.useState([
        {
            title: "Vehicle Management",
            hrefLink: "#",
        },
        {
            title: "Maintenance And Repairs",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Maintenance Date",
            DateFormat: "dd-MMM-yyyy",
            Value: "MaintenanceDate",
        },
        {
            Text: "Next Scheduled Maintenance Date",
            DateFormat: "dd-MMM-yyyy",
            Value: "NextScheduledMaintenanceDate",
        },
        {
            Text: "Vehicle Model",
            render: (dr) => {
                return vehicleData?.find(item => item.VehicleId === dr.VehicleId)?.Model;
            }
        },
        {
            Text: "Remarks",
            Value: "Remarks"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            // isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => openAddEditModal(dr.MaintenanceAndRepairId)}
                        // disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Maintenance And Repair"}
                        id={`btnEdit_${dr.MaintenanceAndRepairId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete this Maintenance And Repairs record.", // Message of Alert
                                dr.MaintenanceAndRepairId  // Endpoint to hit for delete
                            )}
                        // disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Maintenance and Repair"}
                        id={`btnDelete_${dr.VehicleId}`}
                    />
                </>
            ),
        },
    ];

    const openAddEditModal = async (id) => await MaintenanceAndRepairFormRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <ModalAddMaintenanceAndRepair ref={MaintenanceAndRepairFormRef} CompanyCurrencyData={companyCurrencyData} callBackEvent={async () => await fetchVehicleMaintenanceAndRepairList()}/>
            <StaticListComponent
                columns={columns}
                records={vehicleMaintenanceAndRepairData}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.VehicleMaintenanceheaderValueNoResults}
                        subHeaderValue={StandardConst.VehicleMaintenanceSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {/* {permission.ManageAdd && ( */}
                                    <Button variant="outline-primary" onClick={() => openAddEditModal(null)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                {/* )} */}
                            </>
                        }
                    />
                }
                onAddEvent={() => openAddEditModal(null)}
                // IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
        </>
    )
}

const ModalAddMaintenanceAndRepair = forwardRef(({ CompanyCurrencyData,callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.companyInfo ?? {});

    const [show, setShow] = useState(false);
    const [maintenanceAndRepairData, setMaintenanceAndRepairData] = useState({});
    const [vehicleDropdownList, setVehicleDropdownList] = useState([]);
    const [vehicleMaintenanceTypes, setVehicleMaintenanceTypes] = useState([]);

    useImperativeHandle(ref, () => ({
        openModal: async (MaintenanceAndRepairId) => {
            await WebService({
                endPoint: `CommonUtility/staticvehiclemaintenancetypes`,
                dispatch,
            }).then((res) => {
                if(res.length > 0){
                    const vehicleMaintenanceTypeData = res.map(item => ({text : item.MaintenanceType, value : item.VehicleMaintenanceTypeId}))
                    setVehicleMaintenanceTypes(vehicleMaintenanceTypeData);
                }
            })

            await WebService({
                endPoint: `CommonUtility/vehicleregistration`,
                dispatch,
            }).then((res) => {
                if(res.length > 0){
                    setVehicleDropdownList(res.map(item => ({value: item.VehicleId, text: item.Model})));
                }
            })

            if(MaintenanceAndRepairId > 0){
                await WebService({
                    endPoint: `CommonUtility/Edit/vehiclemaintenanceandrepairs?MaintenanceAndRepairId=${MaintenanceAndRepairId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setMaintenanceAndRepairData(res[0]);
                    }
                })
            }

            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setMaintenanceAndRepairData({});
        setVehicleDropdownList([]);
    };

    const onSubmitVehicleMaintenanceAndRepair = async (data) => {
        handleClose();
        
        data.MaintenanceDate = format(new Date(data.MaintenanceDate), "yyyy-MM-dd");

        if(data.MaintenanceAndRepairId !== undefined){
            await WebService({
                endPoint: `CommonUtility/vehiclemaintenanceandrepairs?MaintenanceAndRepairId=${data.MaintenanceAndRepairId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Vehicle maintenance and repair successfully updated.");
            })
        }else{
            // data.VehicleId = VehicleId;
            data.CompanyId = companyInfo.CompanyId;
            await WebService({
                endPoint: `CommonUtility/vehiclemaintenanceandrepairs`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    WSSuccessAlert("Success", "Vehicle maintenance and repair successfully saved.");
                }
            })
        }

        callBackEvent();
    };
    const maintenanceSchema = yup.object().shape({
        MaintenanceDate: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(maintenanceAndRepairData).length > 0) ? "Edit Maintenance And Repair" : "Add Maintenance And Repair"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={maintenanceAndRepairData}
                        onSubmit={onSubmitVehicleMaintenanceAndRepair}
                        validationSchema={maintenanceSchema}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <FormInputText
                                    label="Date"
                                    name="MaintenanceDate"
                                    type="date"
                                    isRequired="true"
                                />
                            </div>

                            <div className="col-md-12 pb-1">
                                <FormInputDropdown
                                    label="Choose Vehicle"
                                    name="VehicleId"
                                    ddOpt={vehicleDropdownList}
                                    isRequired="true"
                                />
                            </div>

                            <div className="col-md-12 pb-1">
                                <FormInputDropdown
                                    label="Maintenance / Repair"
                                    name="VehicleMaintenanceTypeId"
                                    ddOpt={vehicleMaintenanceTypes}
                                    isRequired="true"
                                />
                            </div>

                            <div className="col-md-12">
                                <FormInputText
                                    label="Cost"
                                    name="Cost"
                                    type="currency"
                                    locale={CompanyCurrencyData?.LocaleCode}
                                    currencyCode={CompanyCurrencyData?.CurrencyCode}
                                />
                            </div>
                            
                            <div className="col-md-12">
                                <FormInputText
                                    label="Next Scheduled Date"
                                    name="NextScheduledMaintenanceDate"
                                    type="date"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Remarks"
                                    name="Remarks"
                                    as="textarea"
                                    rows="2"
                                    className="form-control"
                                />
                            </div>
                                    
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(maintenanceAndRepairData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

export default VehicleMaintenanceAndRepairs
import AddEditTask from "./AddEditTaskCategory";
import GroupsIcon from "@mui/icons-material/Groups";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Modal from "react-bootstrap/Modal";
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { WebService } from "../../Services/WebService";
import AddEditProject from "./AddEditProject";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import TableComponent from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import {
  extend,
  extendOwn,
  filter,
  findWhere,
  map,
} from "underscore";
import { InputDropdown } from "../Form";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/Button";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Project = () => {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  var nevigate = useNavigate();
  const editTaskRef = useRef();
  const cloneData = (ds) => JSON.parse(JSON.stringify(ds));
  const ref = useRef();
  const refAssignTeamToProject = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Manage Projects" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [budgetSubCategory, setBudgetSubCategory] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Project - Add"),
      ManageEdit: ActionPermission("Project - Edit"),
      ManageDelete: ActionPermission("Project - Delete"),
      ManageAssignTeam: ActionPermission("Project - AssignTeam"),
      ManageTask : ActionPermission("Project - ManageTask"),
    });
  }, [permissionList]);


  const fetchProject = async () => {
    // var dr = {};
    await WebService({
      dispatch,
      endPoint: `Project/fetchAllProjectDetails`,
      requiredLoader:false
    }).then(res => {
      if(res.length > 0){
        setRecords(res);
      }
    });

    // var data = map(dr.projects, (m) =>
    //   extend(m, {
    //     customerName:
    //       findWhere(cloneData(dr.customers), {
    //         CustomerId: m.CustomerId,
    //       })?.Name ?? null,
    //     Category: map(cloneData(budgetSubCategory), (m1) =>
    //       extend(m1, {
    //         budget:
    //           findWhere(cloneData(dr.project_budget), {
    //             ProjectId: m.ProjectId,
    //             TaskTypeId: m1.TaskTypeId,
    //           })?.Hours ?? 0,
    //         actual:
    //           findWhere(cloneData(dr.projectActualCost), {
    //             ProjectId: m.ProjectId,
    //             TaskTypeId: m1.TaskTypeId,
    //           })?.TimeInHour ?? 0,
    //       })
    //     ),
    //   })
    // );
    
    dispatch(ConfigurationflowStatusUpdate());
  };
  
  const onDelete = async (ProjectId) => {
      await WebService({
        endPoint: `Project/Remove/${ProjectId}`,
        method: "DELETE",
        dispatch,
      });
      refSnackbar.current.setOpenSnackBar();
      await fetchProject();
  };
  const addEditModalRef = useRef();
  useEffect(() => {
    if (budgetSubCategory.length > 0) {
      fetchProject();
    }
  }, [budgetSubCategory]);
  
  const [bData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Manage Projects",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Manage Projects";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Project";

  const [additionalColumnsData, setAdditionalColumnsData ] = useState();
  const getAdditionalColumnsData = async() => {
    await WebService({ dispatch, endPoint: "CommonUtility/Edit/tasktype" })
    .then((ds) => {
        setBudgetSubCategory(ds);
        setAdditionalColumnsData(ds);
      });
  }

  useEffect(() => {
    getAdditionalColumnsData();
  }, []);

// Check if response is available and has the expected properties
  let additionalColumns = [];

  if (additionalColumnsData && additionalColumnsData.length > 0 && additionalColumnsData[0].DisplayDescription) {
      additionalColumns = additionalColumnsData.map((e) => ({
          Text: e.DisplayDescription,
          render: (dr) => <BudgetViewComponent ProjectId={dr.ProjectId} taskType={e} />,
      }));
  }

  const columns = [
    {
      Text: "Project Name",
      Value: "ProjectName",
    },
    { 
      Text: "Customer", 
      Value: "CustomerName" 
    },
    { 
      Text: "Currency", 
      Value: "Symbol" 
    },
    {
      Text: "Total Hour",
      Value: "BudgetTotalHour",
    },
    ...additionalColumns,
    {
      Text: "Action",
      key: "ProjectId",
      style: { width: "191px" },
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <div className="d-flex">
          <ActionButton
            onClick={() => fnEdit(dr.ProjectId)}
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnProjectEditModel"
          />

          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                dr.ProjectId // Endpoint to hit for delete
              )
            }
            disabled={!permission.ManageDelete}
            IconName="Delete"
            id="btnProjectDelete"
          />
          <Tooltip title="Manage Task">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={() => {
                editTaskRef.current.openModal(0, dr.ProjectId);
              }}
              disabled={!permission.ManageTask}
            >
              <AddTaskIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Manage Team">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={async () => {
                nevigate(`/AssignTeam?search=${dr.ProjectName}`);
              }}
              disabled={!permission.ManageAssignTeam}
            >
              <GroupsIcon />
            </IconButton>
          </Tooltip>
          {/* <MoreAction
          MenuItems={[
            {
              name: "Assign team",
              action: async () =>
                await refAssignTeamToProject.current.fetchTeam(
                  dr.ProjectId
                ),
              isVisiable: true,
            },
          ]}
        /> */}
        </div>
      ),
    },
  ];

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""}
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.ManageProjectheaderValueNoResults}
            subHeaderValue={StandardConst.ManageProjectHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${AddButtonText}`}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
        initialSearchContent={searchParams.get("search") ?? ""}
      ></StaticListComponent>
      <AddEditProject
        callBackEvent={() => fetchProject()}
        ref={addEditModalRef}
      ></AddEditProject>
      <AddEditTask ref={editTaskRef} />
      <AssignTeamToProject ref={refAssignTeamToProject} />
    </>
  );
};

const AssignTeamToProject = forwardRef(({}, ref) => {
  const renderAfterCalled = useRef(false);
  const [dataSet, setDataSet] = useState([]);
  const [roles, setRoles] = useState([]);
  const permission = {
    ManageAssignTeam: ActionPermission("Project - AssignTeam"),
  };
  const dispatch = useDispatch();
  const fetchTeam = async (projectId = 0) => {
    const assigned = await WebService({
      dispatch,
      endPoint: `CommonUtility/project_team?where=ProjectId eq ${projectId}&select=EmployeeId,ProjectRoleId`,
    });
    await WebService({
      dispatch,
      endPoint:
        "CommonUtility/employees?where=StatusId=3&select=EmployeeId, FullName, Designation, Department",
    })
      .then((drc) =>
        map(drc, (dr) =>
          extend(dr, {
            ProjectRoleId: findWhere(assigned, { EmployeeId: dr.EmployeeId })
              ?.ProjectRoleId,
            ProjectId: projectId,
          })
        )
      )
      .then((res) => {
        setDataSet(res);
        setShow(true);
      });
  };
  useImperativeHandle(ref, () => ({
    fetchTeam,
  }));
  const fetchProjectRoles = () =>
    WebService({
      dispatch,
      requiredLoader: false,
      endPoint:
        "CommonUtility/Edit/static_project_roles",
    })
      .then((res) =>
        map(res, (m) => ({
          value: m.ProjectRoleId,
          text: m.DisplayDescription,
        }))
      )
      .then((res) => setRoles(res));
  useEffect(() => {
    if(roles && roles.length > 0) {
      Promise.all([fetchProjectRoles()]);
    }
  }, []);
  const onAssign = async (obj, ProjectRoleId) => {
    if (ProjectRoleId == "") ProjectRoleId = null;
    setDataSet((dataSet) =>
      map(dataSet, (m) => {
        if (m.ProjectId == obj.ProjectId && m.EmployeeId == obj.EmployeeId)
          m.ProjectRoleId = ProjectRoleId;
        return m;
      })
    );
    var opt = {
      endPoint: "Project/Team",
      dispatch,
      body: {
        ProjectId: obj.ProjectId,
        EmployeeId: obj.EmployeeId,
        ProjectRoleId,
      },
    };
    await WebService(opt);
  };
  const columns = [
    {
      Text: "Member Name",
      Value: "FullName",
    },
    {
      Text: "Role",
      style: { width: "250px" },
      render: (dr) => (
        <>
          <InputDropdown
            ddOpt={[{}].concat(roles)}
            value={dr.ProjectRoleId}
            setValue={async (v) => {
              if ((dr?.ProjectRoleId ?? "") != (v ?? "")) await onAssign(dr, v);
            }}
            disabled={!permission.ManageAssignTeam}
          />
        </>
      ),
    },
  ];
  const BodyContaint = (
    <TableComponent
      data={dataSet}
      columns={columns}
      IsAddButtonVisible={false}
      isSearchRequired={false}
      pageSize={0}
    ></TableComponent>
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Team Assign</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">{BodyContaint}</Modal.Body>
      </Modal>
    </>
  );
});

const MoreAction = ({ MenuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {filter(MenuItems, (f) => f.isVisiable ?? true).length > 0 && (
        <>
          <IconButton
            id="basic-button"
            size="small"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {map(
              filter(MenuItems, (f) => f.isVisiable ?? true),
              (m) => (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    m.action();
                  }}
                >
                  {m.name}
                </MenuItem>
              )
            )}
          </Menu>
        </>
      )}
    </>
  );
};


const BudgetViewComponent = ({ProjectId, taskType}) => {

  const [record, setRecord] = useState({});

  const fetchData = async () => {
    const data = await WebService({
      endPoint: `Project/fetchProjectBudgetedAndActualHours?ProjectId=${ProjectId}`,
      method: "GET",
    });
  
    if(data.length > 0) {
      var s1 = findWhere(data ?? [], { TaskTypeId: taskType.TaskTypeId });
      var budget = s1?.budgetHours ?? 0;
      var actual = s1?.actualHours ?? 0;

      setRecord({ actual, budget });
    }
    // taskType ??= {
    //     "TaskTypeId": 1,
    //     "CoreCode": "Billable",
    //     "DisplayDescription": "Billable"
    // };

  };

  useEffect(() => {
    fetchData();
  })
  
  
  return (
    <span>
      {record.actual}/{record.budget}
    </span>
  );
};

export default Project;

import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { StandardConst } from "./StandardConst";
export default function WSLinearProgressColor({
  Billable,
  PrimaryColor,
  SecondaryColor,
}) {
  Billable ??= 0;
  PrimaryColor ??= StandardConst.wsBillableColor;
  SecondaryColor ??= StandardConst.wsNonBillableColor;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 0,

    [`&.${linearProgressClasses.colorPrimary}`]: {
      // Total Color
      backgroundColor: SecondaryColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: PrimaryColor,
    },
  }));

  return (
    <>
      <div className="my-2">
        <BorderLinearProgress variant="determinate" value={Billable} pro />
      </div>
    </>
  );
}

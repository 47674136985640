import { map } from "underscore";

export const dataClone = (data) => JSON.parse(JSON.stringify(data));
export const contentSearch = (data, search) =>
  (data ?? "")
    .toString()
    .toLowerCase()
    .indexOf((search ?? "").toString().toLowerCase()) > -1;
export const generateQueryStringByObject = (object) =>
  map(object, (v, k) => `${k}=${v}`)
    .reduce((m, v) => `${m}&${v}`, "")
    .substring(1);

export const roundOff = (num, position) => {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
    Math.pow(10, position)
  );
};

import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
const { forwardRef, useState, useImperativeHandle } = React;


const AddEditAssetCustomField = (props, ref) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AssetCustomField: {} });
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                setData({ AssetCustomField: {} })
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AssetCustomField: await WebService({
                endPoint: `CommonUtility/Edit/assetcustomfields?CustomFieldId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const handleClose = () => {
        setShow(false);
        setData({ AssetCustomField : {}});
    }
    const schema = yup
        .object()
        .shape({
            CustomFieldName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            CustomFieldLabel: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            CustomFieldType: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;
        if (data.CustomFieldId === undefined) {
            data.DisplayOrder = (parseInt(props.highestNumberOfOrderDisplay) + 1);
            await WebService({
                endPoint: "CommonUtility/assetcustomfields",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/assetcustomfields?CustomFieldId=${data.CustomFieldId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    };


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data?.AssetCustomField?.CustomFieldId || 0) === 0
                        ? "Add Asset Custom Field"
                        : "Edit Asset Custom Field"}
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.AssetCustomField}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Custom Field Label"
                                name="CustomFieldLabel"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Custom Field Name"
                                name="CustomFieldName"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12 pb-1">
                            <FormInputDropdown
                                name="CustomFieldType"
                                ddOpt={StandardConst.invoiceFieldType}
                                label="Custom Field Type"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12 pb-2">
                            <FormInputDropdown
                                name="CustomFieldIsRequired"
                                ddOpt={StandardConst.YesOrNo}
                                label="Custom Field Is Required"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id="btnDepartmentSubmit"
                        variant="outline-primary"
                        type="submit"
                    >
                        {" "}
                        {(data?.AssetCustomField?.CustomFieldId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default forwardRef(AddEditAssetCustomField);
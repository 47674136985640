import React, { useEffect, useState, useRef, memo } from "react";
import { WebService } from "../../Services/WebService";
import { format } from "date-fns";
import "../Company/Company.css";
import AddEditError from "./AddEditCompany";
import { ActionPermission, PageInfo, PagePermission } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Company/Company.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { useDispatch, useSelector } from "react-redux";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from 'react-router-dom';

const CompanyList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuList = useSelector((state) => state.auth.MenuList);

  // This code for check this page have permission to see this login person or not
  const currentURL = location.pathname.substring(1); // or location.href if you need the full URL
  if(menuList.length > 1) {
    if(!menuList.some(menuList => menuList.Route === currentURL)){
      navigate("/Dashboard");
    }
  }

  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Company List" });
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();

  const fetchcompanyList = async () => {
    const data = await WebService({
      endPoint: "CompanyProfileList/fetch",
      dispatch,
      requiredLoader: false,
    });
    setRecords(data);
  };
  const [permission, SetPermission] = useState({});
  useEffect(() => {
    SetPermission({
    ManageAdd: ActionPermission("Company Info - Add"),
    // ManageDelete: ActionPermission("Roles - Delete"),
    });
  }, []);
  // const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  // useEffect(() => {
  //   if ((CompanyInfo?.CompanyId ?? 0) !== 0) {
  //     Promise.all([fnEdit(CompanyInfo.CompanyId)]);
  //   }
  // }, [CompanyInfo?.CompanyId]);
  useEffect(() => {
    // if (!renderAfterCalled.current) {
    fetchcompanyList();
    // }
    // renderAfterCalled.current = true;
  }, []);

  const columns = [
    {
      Text: "Company Name",
      Value: "CompanyName",
    },
    {
      Text: "Company Type",
      Value: "Type",
    },

    {
      Text: "Email",
      Value: "Email",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },

    {
      Text: "Date",
      DateFormat: "dd-MM-yyyy",
      Value: "CreatedTime",
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Company ",
      hrefLink: "#",
    },
    {
      title: "Company List",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = " Company List";
  const confirmMessage = MasterPageName + " Deleted successfully";

  return (
    <>
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderValueStandard}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {MasterPageName}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditError
        callBackEvent={() => fetchcompanyList()}
        ref={addEditModalRef}
      ></AddEditError>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
    </>
  );
};

export default memo(CompanyList);

import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditConfigurationComponent from "./AddEditConfigurationComponent";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { sortBy } from "underscore";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { useLocation, useNavigate } from "react-router-dom";

const ConfigurationComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuList = useSelector((state) => state.auth.MenuList);

  // This code for check this page have permission to see this login person or not
  const currentURL = location.pathname.substring(1); // or location.href if you need the full URL
  if(menuList.length > 1) {
    if(!menuList.some(menuList => menuList.Route === currentURL)){
      navigate("/Dashboard");
    }
  }

  const ref = useRef();
  const refSnackbar = useRef();
  const [appData, setAppData] = React.useState({
    searchPlaceHolder: "Search Configuration Component",
    addMenuTitle: "Add Configuration Component",
  });
  PageInfo({ pageTitle: "Configuration Component" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [permission] = useState({
    ManageAdd: true,
    ManageEdit: true,
    ManageDelete: true,
  });
  const fetchConfigurationMaster = async () => {
    const data = await WebService({
      endPoint:
        "CommonUtility/configuration_component?expand=configuration_master&select=ConfigurationComponentId,ComponentName,configuration_master.Details,Nevigaion,configuration_master.IsRequired,RelatedTable,configuration_master.ConfigurationName",
      dispatch,
    });
    setRecords(sortBy(data, (s) => s.ConfigurationComponentId));
    dispatch(ConfigurationflowStatusUpdate());
  };
  const onDelete = async (ConfigurationComponentId) => {
    await WebService({
      endPoint: `CommonUtility/configuration_component?ConfigurationComponentId=${ConfigurationComponentId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchConfigurationMaster();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchConfigurationMaster();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Configuration Name",
      Value: "ConfigurationName",
    },
    {
      Text: "Component Name",
      Value: "ComponentName",
    },
    {
      Text: "Component Details",
      Value: "Details",
    },

    {
      Text: "Nevigaion",
      Value: "Nevigaion",
    },

    {
      Text: "Related Table",
      Value: "RelatedTable",
    },

    {
      Text: "Action",
      cssClass: "text-center td-width-100",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          {dr.IsReserved != 1 && (
            <>
              <ActionButton
                onClick={() => fnEdit(dr.ConfigurationComponentId)}
                // disabled={!permission.ManageEdit}
                IconName="Edit"
                id="btnEdit"
              />

              <ActionButton
                onClick={(e) =>
                  ref.current.confirmAlert(
                    "Delete", //Confirm button text
                    "Are You Sure", // Text if Alert
                    "Do you want to delete " + MasterPageName, // Message of Alert
                    dr.ConfigurationComponentId // Endpoint to hit for delete
                  )
                }
                disabled={!permission.ManageDelete}
                IconName="Delete"
                id="btnDelete"
              />
            </>
          )}
        </>
      ),
    },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Master",
      hrefLink: "#",
    },
    {
      title: "Configuration Component",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Configuration Component";
  const confirmMessage = MasterPageName + " Deleted successfully";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {MasterPageName}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditConfigurationComponent
        callBackEvent={() => fetchConfigurationMaster()}
        ref={addEditModalRef}
      ></AddEditConfigurationComponent>
    </>
  );
};
export default ConfigurationComponent;

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { StandardConst } from "../../Services/StandardConst";
import EmployeeDashboard from "./Employee/EmployeeDashboard";
import CompanyDashboard from "./Company/CompanyDashboard";
import HumanResourceDashboard from "./HumanResource/HumanResourceDashboard";
import { ActionPermission } from "../PageInfo";
import { WebService } from "../../Services/WebService";
import { map } from "underscore";
import { useNavigate } from "react-router-dom";
import JobApplicantDashboard from "./JobApplicant/JobApplicantDashboard";

const DashboardCommonComponent = () => {

  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  const [permission, setPermission]= useState({});
  useEffect(()=> {
    setPermission({
        CompanyDashboard: ActionPermission("Company Dashboard"),
        HRDashboard: ActionPermission("HR Dashboard"),
        EmployeeDashboard: ActionPermission("Employee Dashboard"),
        JobApplicantDashboard: ActionPermission("Job Applicant Dashboard"),
    });
  }, [permissionList]);

  const viewDashboard = () => {
    if (permission.CompanyDashboard) return <CompanyDashboard />;
    else if (permission.HRDashboard) return <HumanResourceDashboard />;
    else if (permission.EmployeeDashboard) return <EmployeeDashboard />;
    else if (permission.JobApplicantDashboard) return <JobApplicantDashboard />;
    else return <></>;
  };
  return (
    <>
      {viewDashboard()}
      {/* <Notification /> */}
    </>
  );
};

const Notification = () => {
  const [state, setState] = useState([]);
  const dispatch = useDispatch();
  const fetchNotReadedNotification = () => {
    WebService({ dispatch, endPoint: `Notification?Skip=0&Take=10` }).then(
      (r) => {
        setState(
          map(r, (m) => {
            if ((m.Route ?? "") !== "") {
              m.Route = `/${m.Route}${m.Route.indexOf("?")>=0?"&":"?"}search=${window.btoa(m.UniqueRefNo)}`;
            }
            return m;
          })
        );
      }
    );
  };
  useEffect(() => {
    Promise.all([fetchNotReadedNotification()]);
  }, []);
  const navigate = useNavigate();
  const fnRead = async (dr) => {
    const process = [];
    process.push(
      WebService({
        dispatch,
        endPoint: `Notification/MarkRead/${dr.NotificationId}`,
        method: "PUT",
        requiredLoader: false,
      })
    );
    if ((dr.Route ?? "") !== "") {
      process.push(navigate(dr.Route));
    }
    await Promise.all(process);
  };

  return (
    <>
      <div className="container">
        {map(state, (m) => (
          <div className="row" onClick={() => fnRead(m)}>
            {!m.IsReaded && <strong>{m.Message}</strong>}
            {m.IsReaded && <>{m.Message}</>}
          </div>
        ))}
        <button onClick={() => navigate(`/Common/Notification`)}>More</button>
      </div>
    </>
  );
};

export default memo(DashboardCommonComponent);

import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import SweetAlert from "sweetalert2";
import * as yup from "yup";
import { Form, FormInputText } from "../Form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { logout } from "../../Services/authSlice";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/esm/Button";
import { WSInfoAlert, WSSuccessAlert } from "../../Services/WSAlert";
import { ActionPermission, PageInfo } from "../PageInfo";


const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  PageInfo({ pageTitle: "Change Password" });
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();
  const fetchMail = async () => {
    const data = await WebService({ dispatch, endPoint: "LogIn/Fetch1" });
    setRecords(data);
  };
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });
  const [newPasswordValues, setNewPasswordValues] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmpasswordValues, setConfirmPasswordValues] = useState({
    password: "",
    showPassword: false,
  });

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleClickNewPassword = () => {
    setNewPasswordValues({
      ...newPasswordValues,
      showPassword: !newPasswordValues.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handlePasswordChange = (prop) => (event) => {
  //   setPasswordValues({ ...passwordValues, [prop]: event.target.value });
  // };

  const handleClickConfirmPassword = () => {
    setConfirmPasswordValues({
      ...confirmpasswordValues,
      showPassword: !confirmpasswordValues.showPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleConfirmPasswordChange = (prop) => (event) => {
    setConfirmPasswordValues({
      ...confirmpasswordValues,
      [prop]: event.target.value,
    });
  };

  const formSchema = yup.object().shape({
    OldPassword: yup.string().required("This is a required field"),
    NewPassword: yup
      .string()
      .required("This is a required field")
      .min(8, "Password must be at 8 char long"),
    ConfirmPassword: yup
      .string()
      .required("This is a required field")
      .oneOf([yup.ref("NewPassword")], "Password does not match"),
  });
  const onSubmit = async (data) => {
    console.log("changepwd", data);
    WebService({
      dispatch,
      endPoint: "LogIn/Update",
      body: data,
    }).then((res) => {
      if (res == "Password Updated Successfully") {
        WSSuccessAlert("Success", res);
        dispatch(logout());        
      } else {
        WSInfoAlert("Info", res);
      }
    });
  };
  const MasterPageName = "Change Password";
  const [bData, setBData] = React.useState([
    {
      title: "Home",
      hrefLink: "/",
      visible: false,
    },
    {
      title: "Change Password",
      hrefLink: "#",
    },
  ]);
  return (
    <div className="base-container container-fluid ">
      <Box
        sx={{
          width: 1,
          height: 80,
        }}
      >
        <h3 className="ms-4 mt-2">{MasterPageName}</h3>
        <div className="ms-4">
          <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
        </div>
      </Box>
      <div className="p-3 primary-bg-color"></div>

      <div class="base-margin-x base-margin-2y mb-2 ">
        <div className="row mb-4 justify-content-center mt-2">
          <Card sx={{ maxWidth: 600, backgroundColor: "#fbfbfb" }}>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: StandardConst.Colors.primary }}
                  aria-label="recipe"
                >
                  CP
                </Avatar>
              }
              action={<IconButton aria-label="settings"></IconButton>}
              title="Change Password"
              subheader="Use this screen to change your password "
            />
            <hr></hr>
            {/* <CardMedia
          component="img"
          height="194"
          image="/static/images/cards/paella.jpg"
          alt="Paella dish"
        /> */}
            <CardContent>
              <Form onSubmit={onSubmit} validationSchema={formSchema}>
                <div className="row m-4 mb-3 mt-4">
                  <div className="col-md-10">
                    <FormInputText
                      label="Old Password"
                      type={passwordValues.showPassword ? "text" : "password"}
                      placeholder="Old-Password"
                      name="OldPassword"
                      isRequired="true"
                    />
                  </div>
                  <div className="col-md-2">
                    <IconButton
                      size="small"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="row m-4 mb-3 mt-4">
                  <div className="col-md-10">
                    <FormInputText
                      label="New Password"
                      type={
                        newPasswordValues.showPassword ? "text" : "password"
                      }
                      placeholder="New-Password"
                      name="NewPassword"
                      isRequired="true"
                    />
                  </div>
                  <div className="col-md-2">
                    <IconButton
                      size="small"
                      onClick={handleClickNewPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {newPasswordValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="row m-4 mb-3 mt-4">
                  <div className="col-md-10">
                    <FormInputText
                      label="Confirm Password"
                      type={
                        confirmpasswordValues.showPassword ? "text" : "password"
                      }
                      placeholder="Confirm-Password"
                      name="ConfirmPassword"
                      isRequired="true"
                    />
                  </div>
                  <div className="col-md-2">
                    <IconButton
                      size="small"
                      onClick={handleClickConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {confirmpasswordValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="col-12 text-center mt-4">
                  <Button
                    id="btnUpdatePassword"
                    type="submit"
                    variant="outline-primary"
                  >
                    Update Password
                  </Button>
                </div>
              </Form>
            </CardContent>
            <CardActions disableSpacing></CardActions>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;

import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { WebService } from "./WebService";

export const configurationFlowSlice = createSlice({
  name: "configurationFlow",
  initialState: {
    count: 0,
  },
  reducers: {
    statusUpdate: (state) => {
      state.count += 1;
    },
    statusUpdated: (state) => {
      if (state.count > 0) state.count -= 1;
    },
  },
});
export const { statusUpdate, statusUpdated } = configurationFlowSlice.actions;
export default configurationFlowSlice.reducer;

export const ConfigurationFlow = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const count = useSelector((state) => state.configurationFlow.count ?? 0);
  useEffect(() => {
    if (count === 1) {
      WebService({dispatch, endPoint:`ConfigurationFlow`, body:{
        routePath: location.pathname,
      }, requiredLoader:false})
      dispatch(statusUpdated());
    }
  }, [count]);
  return <></>;
};

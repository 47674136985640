import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useDispatch, useSelector } from "react-redux";
import TableComponent, {
  NoRecordTemplate,
} from "../../../Services/TableComponent";
import { WebService } from "../../../Services/WebService";
import "../../Static/Static.css";
import AddEditComponent from "./AddEditComponentTemplate";
import { ActionPermission } from "../../PageInfo";
import ActionButton from "../../../Services/ActionButton";
import SnackbarComponent from "../../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../../Services/AlertComponent";
import { StandardConst } from "../../../Services/StandardConst";
import { findWhere, where } from "underscore";
import DisplayNumberFormatComponent from "../../../Services/DisplayNumberFormatComponent";
import { Button, Divider, Grid, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
const SalaryTemplateComponent = forwardRef(({}, ref) => {
  const deleteConfirmAlertRef = useRef();
  const refSnackbar = useRef();
  var dispatch = useDispatch();
  var [data, setData] = useState([]);
  var afterRender = useRef(false);
  const fetchComponentList = async () => {
    var res = await WebService({
      dispatch,
      endPoint: "SalaryTemplateComp/Fetch",
    });
    setData(res);
  };
  //#region Parent Data Id store
  const [templateId, setTemplateId] = useState(null);
  useImperativeHandle(ref, () => ({
    fetchByParent: setTemplateId,
  }));
  //#endregion
  const [permission] = useState({
    ManageAdd: ActionPermission("Salary - Component Add"),
    ManageEdit: ActionPermission("Salary - Component Edit"),
    // ManageDelete: ActionPermission("Roles - Delete"),
  });

  const onDelete = async (TemplateComponentId) => {
    await WebService({
      endPoint: `SalaryTemplateComp/Delete/${TemplateComponentId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchComponentList();
  };

  const addEditModalRef = useRef();
  useEffect(() => {
    if (afterRender.current === false) {
      fetchComponentList();
    }
    afterRender.current = true;
  }, []);
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [companyCurrentSymbol, setCompanyCurrencySymbol] = useState("");

  const fetchCurrentSymbolOfCompany = async () => {
    const companyInformation = await WebService({
      endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${companyInfo.CompanyId || 0}`,
      dispatch,
    }).then((c) => (c.length > 0 ? c[0] : {}));
    const currencyInformation = await WebService({
      endPoint: `CommonUtility/Edit/staticcurrency?CurrencyId=${companyInformation.CurrencyId || 0}`,
      dispatch,
    }).then((c) => (c.length > 0 ? c[0] : {}));
    setCompanyCurrencySymbol(currencyInformation.Symbol);
  };

  useEffect(() => {
    fetchCurrentSymbolOfCompany();
  }, [companyInfo.CompanyId, companyCurrentSymbol])
  const fnEdit = async (id) =>
    await addEditModalRef.current.openModal(id || 0, templateId);
  var columns = [
    {
      Text: "Template Name",
      Value: "TemplateName",
      isVisiable: templateId === null,
    },
    {
      Text: "Salary Head",
      render: (dr) => (
        <>
          <span>{`${dr.EarningOrDeductionName} (`}</span>
          {dr.CalculationMethodId === 1 ? (
            dr.IsDependentOnCTC === 1 ? (
              <span>{`${dr.NumberOrAmount ?? 0}% of CTC`}</span>
            ) : (
              <span>
                {`${dr.NumberOrAmount ?? 0}% of ${
                  findWhere(data, {
                    TemplateComponentId: dr.DependentOnComponentId,
                  })?.EarningOrDeductionName
                }`}
              </span>
            )
          ) : (
            <>
              <DisplayNumberFormatComponent Number={dr.NumberOrAmount} Currency={companyCurrentSymbol}/>
            </>
          )}
          <span>{")"}</span>
        </>
      ),
    },
    {
      Text: "Action",
      key: "TemplateComponentId",
      cssClass: "text-center",
      style: { width: "75px", padding: "0px" },
      isVisiable: permission.ManageEdit,
      Template: (
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(e.currentTarget.parentElement.parentElement.getAttribute("data-key"))
            }
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnSalaryTemplateCompEdit"
          />

          <ActionButton
            onClick={(e) =>
              deleteConfirmAlertRef.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                e.currentTarget.parentElement.parentElement.getAttribute("data-key") // Endpoint to hit for delete
              )
            }
            IconName="Delete"
            id="btnSalaryTemplateCompDelete"
          />
        </>
      ),
    },
  ];

  const MasterPageName = "Salary Template Component";
  const confirmMessage = MasterPageName + " Deleted successfully";
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert
        ref={deleteConfirmAlertRef}
        confirmEvent={(v) => onDelete(v)}
      />
      <Container
        style={{
          backgroundColor: "#FFF",
          padding: "0px",
        }}
      >
        {templateId === null && (
          <Row className="mt-2">
            <Col
              xs={12}
              md={12}
              className="d-flex justify-content-center align-items-center"
            >
              <h4>Template Component</h4>
            </Col>
          </Row>
        )}
        <Row
          className="d-flex align-items-center"
          style={{
            backgroundColor: StandardConst.wsSecondaryColor,
            padding: "10px",
          }}
        >
          <Col md={6} className="col-5 text-center square border-end mb-1">
            <strong> Earnings</strong>
          </Col>
          <Col md={5} className="col-5 text-center bg-color:red">
            <strong>Deduction</strong>
          </Col>
          <Col md={1} className="col-2 text-center bg-color:red">
            {permission.ManageAdd && (
              <IconButton size="small" color="primary" onClick={() => fnEdit()}>
                <AddCircleIcon />
              </IconButton>
            )}
          </Col>
        </Row>
        <Grid container spacing={0.5} sx={{ pl: 0 }}>
          <Grid item className="col-6">
            <TableComponent
              columns={columns}
              noRecordCss="p-0"
              isSearchRequired={false}
              data={where(
                templateId === null
                  ? data
                  : where(data, { TemplateId: templateId }),
                { IsEarning: 1 }
              )}
              pageSize={0}
              IsAddButtonVisible={false}
              onAddEvent={() => fnEdit()}
            />
          </Grid>
          <Grid item className="col-6">
            <TableComponent
              columns={columns}
              noRecordCss="p-0"
              data={where(
                templateId === null
                  ? data
                  : where(data, { TemplateId: templateId }),
                { IsEarning: 0 }
              )}
              pageSize={0}
              // IsAddButtonVisible={permission?.ManageAdd}
              IsAddButtonVisible={false}
              isSearchRequired={false}
              onAddEvent={() => fnEdit()}
            />
          </Grid>
        </Grid>
      </Container>
      <AddEditComponent
        callBackEvent={() => fetchComponentList()}
        ref={addEditModalRef}
      ></AddEditComponent>
    </>
  );
});

export default SalaryTemplateComponent;

import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown, FormInputFile } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { omit } from "underscore";
import { Alert, IconButton, Tooltip } from "@mui/material";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditBadgeTemplates = (prop, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ BadgeTemplates: {} });
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const [showFileInput, setShowFileInput] = useState(false);
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                setData({BadgeTemplates: {} });
                setSelectedTemplate("");
                setShowFileInput(true);
            }
            else {
                setShow(false);
                const fetchCompany = await fetchValues(id);
                setData({ BadgeTemplates: fetchCompany[0] });
                setSelectedTemplate(fetchCompany[0].html_file_path);
                setShowFileInput(false);
                setShow(true);
            }
        },
    }));

    const fetchValues = async (id) => {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/badge_templates?badge_templatesId=${id || 0}`,
            dispatch,
        });
        return data;
    }

    const handleClose = () => {
        setShow(false)
        setData({BadgeTemplates: {}});
        setSelectedTemplate("");
    };

    const requiredMessage = "This is a required field";
    const isValidFileType = (file) => {
        return (file && file.type === 'text/html');
    };

    const schema = yup
        .object()
        .shape({
            badge_template_name: yup.string().trim().required(requiredMessage),
            badge_type: yup.string().trim().required(requiredMessage),
            File: data.BadgeTemplates.html_file_path ? 
            null
            :
            yup
            .mixed()
            .required(requiredMessage)
            .test("is-valid-type", "Please upload HTML File", (value) =>
            isValidFileType(value)
            ),
            BadgeTemplateHeight : yup.number().typeError(requiredMessage).required(requiredMessage),
            BadgeTemplateWidth : yup.number().typeError(requiredMessage).required(requiredMessage)
        })
        .required();

    const CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);

    const onSubmit = async (data) => {

        data.badge_type = Number(data.badge_type);
        data.isReserved = Number(data.isReserved);
        data.BadgeTemplateHeight = Number(data.BadgeTemplateHeight);
        data.BadgeTemplateWidth = Number(data.BadgeTemplateWidth);
        if(Roles[0] !== StandardConst.SystemRole.SuperAdmin){
            data.isReserved = StandardConst.YesNo.No;
        }
        data.CompanyId = CompanyId;
        if ((data.File?.size ?? 0) > 0){
            await WebService({
            endPoint: "upload/File?destination=badge_templates",
            dispatch,
            body: data.File,
            isFile: true,
            }).then((res) => {
            data.html_file_path = res.filename;
            });
            data = omit(data, "File");
        }
        if (data.badge_templatesId === undefined) {
            await WebService({
            endPoint: `CommonUtility/badge_templates`,
            method: "POST",
            body: data,
            dispatch,
            });
        } else {
            await WebService({
            endPoint: `CommonUtility/badge_templates?badge_templatesId=${data.badge_templatesId}`,
            method: "PUT",
            body: data,
            dispatch,
            });
        }
        handleClose();
        prop.callBackEvent();
    };

    const showUploadFileInput = () => {
        setShowFileInput(!showFileInput);
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data?.BadgeTemplates?.badge_templatesId || 0) === 0
                        ? "Add Badge Template"
                        : "Edit Badge Template"}
                </Modal.Title>
            </Modal.Header>
            <Alert 
                severity="warning"
            >
                Please ensure that there are no SVG tags in the html file only use IMG tag for images. SVG are not supported.
            </Alert>
            <Form
                defaultValues={data.BadgeTemplates}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row mt-2">
                        <FormInputText
                            name="badge_template_name"
                            label="Badge Template Name"
                            type="text"
                            isRequired="true"
                        />
                    </div>
                    <div className="row" style={{position: "relative", top: "-22px", right: "-185px", width: "289px" }}>
                        <span style={{ position: "relative", left: "-186px", top: "29px", color: "#5a5a5a", fontSize: "14px", fontFamily: "sans-serif"}}>
                            Badge HTML File{" "}
                        </span>

                        {selectedTemplate ? 
                        <span>
                            {selectedTemplate}
                            <Tooltip title="Upload File">
                                <IconButton>
                                    <i className="fa fa-file fa-2x" style={{marginLeft: "10px", fontSize: "1em"}}
                                    onClick={showUploadFileInput}
                                    ></i>
                                </IconButton>
                            </Tooltip>
                        </span>
                        : null
                        }
                        {
                            showFileInput  && (
                                <FormInputFile
                                    name="File"
                                    type="file"
                                    label={"HTML File Path"}
                                    dynamicLabel={"Badge HTML File"}
                                />
                                )}
                    </div>
                    <div className="row" style={{ position: "relative", top: "-23px"}}>
                        <FormInputDropdown
                            name="badge_type"
                            ddOpt={StandardConst.BadgeType}
                            label="Badge Type"
                        ></FormInputDropdown>
                    </div>
                    <div className="row" style={{ position: "relative", top: "-20px"}}>
                        {Roles[0] === StandardConst.SystemRole.SuperAdmin && (
                            <FormInputDropdown
                            name="isReserved"
                            ddOpt={StandardConst.YesOrNoReserved}
                            label="Reserved"
                        >
                        </FormInputDropdown>
                        )}
                    </div>

                    <div className="row" style={{ position: "relative", top: "-20px"}}>
                        <FormInputDropdown
                            name="BadgeTemplateOrientation"
                            ddOpt={StandardConst.OrientationVisitorBadge}
                            label="Badge Template Orientation"
                            setValue={(val) => {
                                if(val === StandardConst.OrientationVisitorBadge[0].value){
                                    document.getElementById("BadgeTemplateHeight").placeholder = "Height - 54";
                                    document.getElementById("BadgeTemplateWidth").placeholder = "Width - 84";
                                }else{
                                    document.getElementById("BadgeTemplateHeight").placeholder = "Height - 82";
                                    document.getElementById("BadgeTemplateWidth").placeholder = "Width - 54";
                                }
                            }}
                        >
                        </FormInputDropdown>
                    </div>

                    <div className="row mt-1" style={{ position: "relative", top: "-20px"}}>
                        <div className="col-md-5">
                            <label style={{color: "#5a5a5a", fontSize: "14px", fontFamily: "sans-serif"}}>Badge (height - width (mm))<span className="text-danger">＊</span></label>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6 p-0">
                                    <FormInputText
                                        id="BadgeTemplateHeight"
                                        name="BadgeTemplateHeight"
                                        type="text"
                                        isRequired="true"
                                        placeholder={(data.BadgeTemplates.BadgeTemplateOrientation === StandardConst.OrientationVisitorBadge[0].value) ? "Height - 54" : "Height - 82"}
                                    />
                                </div>
                                <div className="col-md-6 ">
                                    <FormInputText
                                        id="BadgeTemplateWidth"
                                        name="BadgeTemplateWidth"
                                        type="text"
                                        isRequired="true"
                                        placeholder={(data.BadgeTemplates.BadgeTemplateOrientation === StandardConst.OrientationVisitorBadge[0].value) ? "Width - 84" : "Width - 54"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id="btnBadgeTemplateSubmit"
                        variant="outline-primary"
                        type="submit"
                    >
                        {" "}
                        {(data?.BadgeTemplates?.badge_templatesId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}{" "}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default forwardRef(AddEditBadgeTemplates);

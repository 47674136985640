import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../../Services/TableComponent";
import { WebService } from "../../../Services/WebService";
import "../../Static/Static.css";
import { confirm } from "react-confirm-box";
import AddEditSalaryComponent from "./AddEditSalaryComponent";
import Button from "react-bootstrap/esm/Button";
import { ActionPermission, PageInfo } from "../../PageInfo";
import ActionButton from "../../../Services/ActionButton";
import SnackbarComponent from "../../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../../Services/AlertComponent";
import CompanyInfoWide from "../../../Services/CompanyInfoWide";
import { NoRecordTemplate } from "../../../Services/TableComponent";
import { StandardConst } from "../../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../../Services/ConfigurationFlow";

const Salary = () => {
  const MasterPageName = "Salary Component";
  const confirmMessage = MasterPageName + " Deleted successfully";

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Salary - Component Add"),
      ManageEdit: ActionPermission("Salary - Component Edit"),
      ManageDelete: ActionPermission("Salary - Component Delete"),
    });
  }, [permissionList]);

  const ref = useRef();
  const refSnackbar = useRef();
  var dispatch = useDispatch();
  PageInfo({ pageTitle: "Salary Structure Heads" });
  var [data, setData] = useState([]);
  var [deductionData, setDeductionData] = useState([]);
  var afterRender = useRef(false);
  const fetchList = async () => {
    WebService({
      dispatch,
      endPoint: "SalaryComponent/Earning",
    }).then((r) => setData(r));
    WebService({
      dispatch,
      endPoint: "SalaryComponent/Deduction",
    }).then((r) => setDeductionData(r));
    dispatch(ConfigurationflowStatusUpdate());
  };

  const onDelete = async (SalaryComponentsId) => {
    await WebService({
      endPoint: `SalaryComponent/${SalaryComponentsId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    fetchList();
  };

  const addEditModalRef = useRef();
  useEffect(() => {
    if (afterRender.current === false) {
      fetchList();
    }
    afterRender.current = true;
  }, []);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  var columns = [
    {
      Text: "Component Name",
      Value: "EarningOrDeductionName",
    },

    {
      Text: "Action",
      cssClass: "text-end",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          {permission.ManageEdit && (
            <ActionButton
              onClick={(e) => fnEdit(dr.SalaryComponentsId)}
              IconName="Edit"
              id="btnSalaryEdit"
            />
          )}
          {permission.ManageDelete && (
            <ActionButton
              onClick={(e) =>
                ref.current.confirmAlert(
                  "Delete", //Confirm button text
                  "Are You Sure", // Text if Alert
                  "Do you want to delete " + MasterPageName, // Message of Alert
                  dr.SalaryComponentsId // Endpoint to hit for delete
                )
              }
              IconName="Delete"
              id={`btnSalaryDelete_${dr.SalaryComponentsId}`}
            />
          )}
        </>
      ),
    },
  ];

  var columnsDeduction = [
    {
      Text: "Component Name",
      Value: "EarningOrDeductionName",
    },
    {
      Text: "Tax",
      Value: "PreTaxORPostTax",
    },
    {
      Text: "Action",
      cssClass: "text-end",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          {permission.ManageEdit && (
            <ActionButton
              onClick={(e) => fnEdit(dr.SalaryComponentsId)}
              IconName="Edit"
              id="btnSalaryEdit"
            />
          )}
          {permission.ManageDelete && (
            <ActionButton
              onClick={(e) =>
                ref.current.confirmAlert(
                  "Delete", //Confirm button text
                  "Are You Sure", // Text if Alert
                  "Do you want to delete " + MasterPageName, // Message of Alert
                  dr.SalaryComponentsId // Endpoint to hit for delete
                )
              }
              IconName="Delete"
              id={`btnSalaryDelete_${dr.SalaryComponentsId}`}
            />
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <Container>
        <Row className="mt-2">
          <Col xs={8} md={10}>
            <CompanyInfoWide />
          </Col>

          <Col
            xs={4}
            md={2}
            className="d-flex justify-content-center align-items-end flex-column"
          >
            <Button
              variant="mx-1 btn btn-outline-primary"
              id="btnSalaryComponentAdd"
              IsAddButtonVisible={permission?.ManageAdd}
              onClick={() => fnEdit()}
            >
              Add +
            </Button>
          </Col>
          <hr></hr>
        </Row>
        <Row>
          <Col md={6} className="text-center square border-end mb-1">
            <strong> Earnings</strong>
          </Col>
          <Col md={6} className="text-center bg-color:red">
            <strong>Deduction</strong>
          </Col>
        </Row>

        <Row>
          <Col className="square border-end">
            <TableComponent
              columns={columns}
              noRecordCss="p-0"
              noRecordFound={
                <NoRecordTemplate
                  headerValue={StandardConst.EarningheaderValueNoResults}
                  subHeaderValue={StandardConst.EarningSubHeaderRole}
                  imageUrl={StandardConst.imageNoRecordsFound}
                  actionButton={
                    <>
                      <Button
                        variant="outline-primary"
                        onClick={() => fnEdit(0)}
                      >
                        Add Earning
                      </Button>
                    </>
                  }
                />
              }
              data={data}
              allowSerialNo={false}
              IsAddButtonVisible={false}
              isSearchRequired={false}
            />
          </Col>

          <Col>
            <TableComponent
              columns={columnsDeduction}
              noRecordCss="p-0"
              noRecordFound={
                <NoRecordTemplate
                  headerValue={StandardConst.DeductionheaderValueNoResults}
                  subHeaderValue={StandardConst.DeductionSubHeaderRole}
                  imageUrl={StandardConst.imageNoRecordsFound}
                  actionButton={
                    <>
                      <Button
                        variant="outline-primary"
                        onClick={() => fnEdit(0)}
                      >
                        Add Deduction
                      </Button>
                    </>
                  }
                />
              }
              data={deductionData}
              allowSerialNo={false}
              IsAddButtonVisible={false}
              isSearchRequired={false}
            />
          </Col>
        </Row>
      </Container>
      <AddEditSalaryComponent
        callBackEvent={() => fetchList()}
        ref={addEditModalRef}
      ></AddEditSalaryComponent>
    </>
  );
};

export default Salary;

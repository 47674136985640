import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";
import AddEditSubscriptionType from './AddEditSubscriptionType';

const SubscriptionType = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Subscription Type" });
    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Subscription Type - Add"),
            ManageEdit: ActionPermission("Subscription Type - Edit"),
            ManageDelete: ActionPermission("Subscription Type - Delete"),
        });
    }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Subscription Type";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const addEditModalRef = useRef();

    const AddButtonText = "Subscription Type";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);

    const fetchSubscriptionType = async() => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/subscription_type`,
            dispatch,
        });
        setRecords(data);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchSubscriptionType();
    }, []);

    const [bData,] = React.useState([
        {
            title: "Master",
            hrefLink: "#",
        },
        {
            title: "Subscription Type",
            hrefLink: "#",
        },
    ]);

    const onDelete = async (Details) => {
        const data = Details.split(',');
        const SubscriptionTypeId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/subscription_type?SubscriptionTypeId=${SubscriptionTypeId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchSubscriptionType();
    };

    const columns = [
        {
            Text: "Name",
            Value: "Name"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.SubscriptionTypeId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Subcription Type"}
                        id={`btnSubcriptionTypeEdit_${dr.SubscriptionTypeId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.Name, // Message of Alert
                                `${dr.SubscriptionTypeId},${dr.Name}`  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Subcription Type"}
                        id={`btnSubcriptionTypeDelete_${dr.SubscriptionTypeId}`}
                    />
                </>
            ),
        },
    ];

    const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Subscription ${(deletedName !== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.SubscriptionTypeheaderValueNoResults}
                        subHeaderValue={StandardConst.SubcriptionTypeSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
            <AddEditSubscriptionType
                callBackEvent={async () => await fetchSubscriptionType()}
                ref={addEditModalRef}
            ></AddEditSubscriptionType>
        </>
    )
}

export default SubscriptionType
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { PageInfo } from '../PageInfo';
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/esm/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { WSErrorAlert, WSSuccessAlert } from '../../Services/WSAlert';
import BootstrapModal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import { DateTime } from 'luxon';
import * as yup from "yup";

const VehicleDriverAssignment = () => {
    // const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    // const [permission, SetPermission] = useState({});
    const [vehicleDriverAssignmentData, setVehicleDriverAssignmentData] = useState([]);
    PageInfo({ pageTitle: "Manage Driver" });
    // useEffect(() => {
    //     SetPermission({
    //         ManageAdd: ActionPermission("Vehicle - Add"),
    //         ManageEdit: ActionPermission("Vehicle - Edit"),
    //         ManageDelete: ActionPermission("Vehicle - Delete"),
    //         ManageQRCode: ActionPermission("Vehicle - QR Code"),
    //     });
    // }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Manage Driver";
    const VehicleDriverAssignmentFormRef = useRef();
    const { state } = useLocation();
    const navigate = useNavigate();
    const AddButtonText = "Driver";
    const dispatch = useDispatch();
    const [deletedDriverName, setDeletedDriverName] = useState(null);

    // const [vehicleData, setVehicleData] = useState([]);

    const onDelete = async (DriverDetail) => {
        const DriverData = DriverDetail.split(',');
        const DriverId = DriverData[0];
        const DriverName = DriverData[1];
        setDeletedDriverName(DriverName);
        await WebService({
            endPoint: `CommonUtility/companydriversinformation?DriverId=${DriverId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchVehicleDriverAssignmentList();
    };

    const fetchVehicleDriverAssignmentList = async () => {
        const data = await WebService({
            // endPoint: `Vehicle/getCompanyDriverAndVehicleAssignmentDetail?CompanyId=${StandardConst.YesNo.Yes}`,
            endPoint: `CommonUtility/companydriversinformation`,
            dispatch,
        });
        setVehicleDriverAssignmentData(data);
        // dispatch(ConfigurationflowStatusUpdate());
    }

    // const fetchVehicleData = async () => {
    //     await WebService({
    //         endPoint: `CommonUtility/vehicleregistration`,
    //         dispatch,
    //     }).then((res) => {
    //         if(res.length > 0){
    //             setVehicleData(res);
    //         }
    //     })
    // };

    useEffect(() => {
        // fetchVehicleData();
        if(state?.OpenAddModal){
            openAddEditModal(0);
        }
        fetchVehicleDriverAssignmentList();
    }, []);

    const [bData ] = React.useState([
        {
            title: "Vehicle Management",
            hrefLink: "#",
        },
        {
            title: "Manage Driver",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Driver Name",
            Value: "DriverName"
        },
        {
            Text: "DOB",
            DateFormat: "dd-MMM-yyyy",
            Value: "DOB",
        },
        {
            Text: "Driver Contact",
            Value: "DriverContact"
        },
        // {
        //     Text: "Assigned Date",
        //     DateFormat: "dd-MMM-yyyy hh:mm a",
        //     Value: "AssignedDate",
        // },
        // {
        //     Text: "Assigned Vehicle Model",
        //     render: (dr) => {
        //         return vehicleData?.find(item => item.VehicleId === dr.VehicleId)?.Model;
        //     }
        // },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            // isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => openAddEditModal(dr.DriverId)}
                        // disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Vehicle Driver"}
                        id={`btnEdit_${dr.DriverId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                `Do you want to delete ${dr.DriverName} record.`, // Message of Alert
                                `${dr.DriverId},${dr.DriverName}`  // Endpoint to hit for delete
                            )}
                        // disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Vehicle Driver"}
                        id={`btnDelete_${dr.DriverId}`}
                    />
                </>
            ),
        },
    ];

    const openAddEditModal = async (id) => await VehicleDriverAssignmentFormRef.current.openModal(id || 0);

    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Driver ${(deletedDriverName!== null) ? deletedDriverName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <ModalVehicleDriverAssignment ref={VehicleDriverAssignmentFormRef} callBackEvent={async () => await fetchVehicleDriverAssignmentList()}/>
            <StaticListComponent
                columns={columns}
                records={vehicleDriverAssignmentData}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.VehicleDriverAssignmentheaderValueNoResults}
                        subHeaderValue={StandardConst.VehicleDriverAssignmentSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {/* {permission.ManageAdd && ( */}
                                    <Button variant="outline-primary" onClick={() => openAddEditModal(null)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                {/* )} */}
                            </>
                        }
                    />
                }
                onAddEvent={() => openAddEditModal(null)}
                // IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
        </>
    )
}

const ModalVehicleDriverAssignment = forwardRef(({ callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.companyInfo ?? {});

    const [show, setShow] = useState(false);
    const [vehicleDriverAssignmentData, setVehicleDriverAssignmentData] = useState({});
    const [officeLocationList, setOfficeLocationList] = useState([{ value: 0, text: "All Office Location" }]);


    useImperativeHandle(ref, () => ({
        openModal: async (DriverId) => {
            await WebService({
                endPoint: `CommonUtility/officelocation`,
                dispatch,
            }).then((res) => {
                if(res.length > 0){
                    setOfficeLocationList([{ value: 0, text: "All Office Location" }, ...res.map(item => ({value: item.OfficeLocationId, text: item.Location}))]);
                }
            })

            if(DriverId > 0){
                await WebService({
                    // endPoint: `Vehicle/getCompanyDriverAndVehicleAssignmentDetail?DriverId=${DriverId}`,
                    endPoint: `CommonUtility/Edit/companydriversinformation?DriverId=${DriverId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setVehicleDriverAssignmentData(res[0]);
                    }
                })
            }

            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setVehicleDriverAssignmentData({});
    };

    const onSubmitVehicleDriverAssignment = async (data) => {
        handleClose();
        
        if(parseInt(data.OfficeLocationId) === StandardConst.YesNo.No){
            data.OfficeLocationId = null;
        }
        if(data.DriverId !== undefined){
            await WebService({
                endPoint: `CommonUtility/companydriversinformation?DriverId=${data.DriverId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", `${data.DriverName} updated successfully`)
            });
        }else{
            data.CompanyId = companyInfo.CompanyId;
            await WebService({
                endPoint: `CommonUtility/companydriversinformation`,
                body: data,
                method: 'POST',
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", `${data.DriverName} added successfully`)
            });
        }

        callBackEvent();
    };
    
    const driverSchema = yup.object().shape({
        DriverName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        DOB: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
        DriverContact: yup
        .number()
        .label("Contact No")
        .required(StandardConst.requiredMessage)
        .positive()
        .integer()
        .typeError(StandardConst.requiredMessage)
        .min(1000000000, StandardConst.mobileMessage)
        .max(9999999999, StandardConst.mobileMessage),
        DriverLicenseNumber: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });

    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(vehicleDriverAssignmentData).length > 0) ? "Edit Driver" : "Add Driver"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={vehicleDriverAssignmentData}
                        onSubmit={onSubmitVehicleDriverAssignment}
                        validationSchema={driverSchema}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <FormInputText
                                    label="Driver Name"
                                    name="DriverName"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="DOB"
                                    name="DOB"
                                    type="date"
                                    max={DateTime.now().minus({ year: 18 }).toSQLDate()}
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Driver Contact"
                                    name="DriverContact"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>

                            <div className="col-md-12">
                                <FormInputText
                                    label="Driver License Number"
                                    name="DriverLicenseNumber"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>

                            <div className="col-md-12">
                                <FormInputDropdown
                                    label="Office Location"
                                    name="OfficeLocationId"
                                    ddOpt={officeLocationList}
                                    // isRequired="true"
                                />
                            </div>
                                    
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(vehicleDriverAssignmentData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

export default VehicleDriverAssignment
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { map, pick } from "underscore";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
const requiredMessage = StandardConst.requiredMessage;
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditEmployeeJobFunction = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ StaticJobFunctions: {}, Employee: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      const data = {
        StaticJobFunctions: await WebService({
          endPoint: `CommonUtility/staticjobfunctions`,
          dispatch,
        }).then((c) =>
          [{}].concat(
            map(c, (m) => ({ value: m.JobFunctionId, text: m.JobFunction }))
          )
        ),
        Employee: await WebService({
          dispatch,
          endPoint: `CommonUtility/employees?where=EmployeeId eq ${
            id || 0
          }&select=FullName,EmployeeId,JobFunctionId,EmployeeCode`,
        }),
      };
      setData(data);
      setShow(true);
    },
  }));
  const handleClose = () => setShow(false);
  const schema = yup
    .object()
    .shape({
      EmployeeId: yup.string().trim().required(requiredMessage),
      JobFunctionId: yup.string().trim().required(requiredMessage),
    })
    .required();

  const onSubmit = async (data) => {
    await WebService({
      endPoint: `CommonUtility/employees?EmployeeId=${data.EmployeeId}`,
      method: "PUT",
      body: pick(data, ["JobFunctionId"]),
      dispatch,
    });

    handleClose();
    prop.callBackEvent();
  };

  const formBody = (
    <>
      <div className="row ws-font-14 mb-1">
        <div className="col-md-5">Name:</div>
        <div className="col-md-7">{data?.Employee[0]?.FullName}</div>
      </div>
      <div className="row ws-font-14 mb-1">
        <div className="col-md-5">Code:</div>
        <div className="col-md-7">{data?.Employee[0]?.EmployeeCode}</div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormInputDropdown
            name="JobFunctionId"
            ddOpt={data.StaticJobFunctions}
            label="Job Function"
          />
        </div>
      </div>

      <></>
    </>
  );
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="px-2">
          {(data.Employee[0]?.EmployeeId || 0) === 0
            ? "Add Job Function"
            : "Edit Job Function"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.Employee[0]}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">{formBody}</Modal.Body>
        <Modal.Footer>
          <Button
            id="btnJobFunctionSubmit"
            variant="outline-primary"
            type="submit"
          >
            Assign Job Function
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditEmployeeJobFunction);

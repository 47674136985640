import Cookies from "universal-cookie";
import { StandardConst } from "./StandardConst";
import { logout } from "./authSlice";
import { extend, mapObject, reduce } from "underscore";
import Swal from "sweetalert2";
import { start, stop } from "./Loader";
import { DateTime } from "luxon";

const cookies = new Cookies();
export const WebService = async (props) => {
  let { requiredLoader } = props;
  requiredLoader ??= true;
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "mx-1 w-100 btn btn-outline-success",
      cancelButton: "mx-1 btn btn-outline-danger",
    },
    buttonsStyling: false,
  });

  const getResponse = new Promise((resolve, reject) => {
    if (props.dispatch !== undefined && requiredLoader) props.dispatch(start());
    props.isFile ??= false;
    let opt = {
      method:
        props?.method ?? ((props?.body || null) === null ? "GET" : "POST"),
      credentials: "include",
    };
    if (!props.isFile) {
      opt.headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${cookies.get("token") ?? ""}`,
      };
    }
    if (props.body !== undefined) {
      if (props.isFile) {
        const formData = new FormData();
        formData.append("", props.body);
        opt.body = formData;
      } else {
        if (typeof props.body === typeof {}) {
          props.body = mapObject(props.body, (m) => {
            if (typeof m === "object" && DateTime.fromJSDate(m).isValid) {
              m = DateTime.fromJSDate(m)
                .setZone("utc")
                .toSQL()
                .substring(0, 23);
            }
            return m;
          });
        }
        opt.body = JSON.stringify(props.body);
      }
    }

    fetch(`${StandardConst.apiBaseUrl}/${props.endPoint}`, opt)
      .then((res) => {
        try {
          if (props.dispatch !== undefined && requiredLoader) props.dispatch(stop());
            
          if (res.status >= 200 && res.status < 300)
            return res
              .json()
              .then((c) => extend({ IsSuccess: true }, { data: c }));
          else {
            if (res.status === 401) {
              if (props.dispatch !== undefined) props.dispatch(logout());
            } else {
              return res.json().then((c) =>
                extend({
                  IsSuccess: false,
                  statusText: res.statusText,
                  data: c,
                })
              );
            }
          }
        } catch (err) {
          throw err;
        }
      })
      .then((result) => {
        if (result.IsSuccess) resolve(result.data);
        else {
          result.data ??= [{ Error: result.statusText }];
          if (typeof result.data == typeof {}) {
            result.data = [result.data];
          }
          const errorMessage = reduce(
            result.data,
            (m, obj) => `${m}, ${obj.Error ?? obj.error}`,
            ""
          ).substring(2);
          if (props.dispatch !== undefined)
            swalWithBootstrapButtons.fire({
              title: "Oops!",
              text: errorMessage,
              icon: "warning",
              reverseButtons: true,
              customClass: {
                confirmButton: "mx-1 btn btn-outline-success",
                cancelButton: "mx-1 btn btn-outline-danger",
              },
              buttonsStyling: false,
            });
          reject(errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        reject();
      });
  });
  return getResponse;
};

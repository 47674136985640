import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import { Alert, Chip } from '@mui/material';
import { CalculatePostedDate } from './JobListing';
import { CurrencyFormat } from '../../Services/DisplayCurrencyFormat';


const JobViewModal = (props, ref) => {

    const [show, setShow] = useState(false);
    const [jobData, setJobData] = useState({});
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const isSuperAdmin = Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin);
    const isJobApplicant = Roles?.some(element => element === StandardConst.SystemRole.JobApplicant);
    const [isApplied, setIsApplied] = useState(false);
    useImperativeHandle(ref, () => ({
        openModal: async (JobId, isApplied) => {
            setShow(true);
            setIsApplied(isApplied);
            if (JobId > 0) {
                await WebService({
                    endPoint: `Recruitment/fetchJobDetailsByJobId?JobId=${JobId}`,
                    method: 'GET',
                    dispatch
                }).then(res => res.length > 0 ? setJobData(res[0]) : null);
            }
        },
    }));

    const applyThisJob = async (JobId) => {
        await handleClose();
        props.ApplyJob(JobId);
    }

    const handleClose = async () => {
        setShow(false)
        setJobData({});
    };

    const renderSalary = (data) => {
        switch (data.SalaryRange) {
            case "Exact Salary":
                return <CurrencyFormat value={data.ExactSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} /> ;
            
            case "Starting Salary":
                return <CurrencyFormat value={data.MinimumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />;

            case "Maximum Salary":
                return <CurrencyFormat value={data.MaximumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />;

            case "Range":
                return (
                    <span>
                      <CurrencyFormat value={data.MinimumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} /> 
                      {' to '} 
                      <CurrencyFormat value={data.MaximumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />
                    </span>
                  );

        }
      };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>View Job Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className="container">
                    {(Object.keys(jobData).length > 0) && (
                        <div>
                            <p className='h1'>{jobData?.Title}</p>
                            <p>
                                {jobData?.Location} &nbsp;
                                {(jobData?.OperatingArea !== null) && (
                                    <Chip 
                                        label={jobData?.OperatingArea}
                                        sx={{ color: "grey", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)"}}
                                        color= "secondary"
                                        size="small"
                                    />
                                )}

                            </p>
                            {(jobData?.JobLocationType) && (
                                <>
                                    <Chip 
                                    label={jobData?.JobLocationType}
                                    sx={{ color: "grey", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                    color= "secondary"
                                    size="small"
                                    />&nbsp;
                                </>
                            )}
                            
                            {jobData?.JobTypes?.split(',').map((jobType) => (
                                <>
                                    <Chip 
                                        label={jobType}
                                        sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                        size="small"
                                    />
                                    &nbsp;
                                </>
                            ))}
                            {jobData?.JobSchedules?.split(',').map((JobSchedule) => (
                                <>
                                    <Chip 
                                        label={JobSchedule}
                                        sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                        size="small"
                                    />
                                    &nbsp;
                                </>
                            ))}
                            
                            {(jobData?.SupplementalPays || jobData?.Benifits || jobData?.MinimumSalary || jobData?.MaximumSalary || jobData?.ExactSalary) && (
                                <>
                                    <Alert severity="info" className='mb-3 w-100'>Pay and Benefits</Alert>
                                    <p className='mb-1'>{renderSalary(jobData)} {jobData?.Rate} &nbsp;
                                        {jobData?.SupplementalPays?.split(',').map((SupplementalPay) => (
                                            <>
                                                <Chip 
                                                    label={SupplementalPay}
                                                    sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)"}}
                                                    size="small"
                                                />
                                                &nbsp;
                                            </>
                                        ))}
                                        {jobData?.Benifits?.split(',').map((Benifit) => (
                                        <>
                                            <Chip 
                                                label={Benifit}
                                                sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)"}}
                                                size="small"
                                            />
                                            &nbsp;
                                        </>
                                    ))}
                                    </p>
                                </>
                            )}

                            {(jobData?.Skills) && (
                                <>
                                    <Alert severity="info" className='my-3 w-100'>Skills</Alert>
                                    
                                    {jobData?.Skills?.split(',').map((skill) => (
                                        <>
                                            <Chip 
                                                label={skill}
                                                sx={{ color: "green", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                                size="small"
                                            />
                                            &nbsp;
                                        </>
                                    ))}
                                </>

                            )}

                            {(jobData?.Description) && (
                                <>
                                    <Alert severity="info" className='my-3 w-100'>Job Description</Alert>
                                    <div dangerouslySetInnerHTML={{ __html:  jobData?.Description}} />
                                </>
                            )}

                            {(jobData?.MinimumExperiance) && (
                                <>
                                    <Alert severity="info" className='my-3 w-100'>Experiance</Alert>
                                    <p>
                                        {
                                            (parseInt(jobData?.MinimumExperiance) === parseInt(jobData?.MaximumExperiance)) && (
                                                jobData?.MinimumExperiance !== null ? jobData?.MinimumExperiance : jobData?.MaximumExperiance
                                            )
                                        }
                                        {
                                            (parseInt(jobData?.MinimumExperiance) !== parseInt(jobData?.MaximumExperiance)) && (
                                                `${jobData?.MinimumExperiance} To ${jobData?.MaximumExperiance}`
                                            )
                                        }&nbsp;
                                        Years
                                    </p>
                                </>
                            )}

                            {(jobData?.NumberOfPeopleToHire) && (
                                <>
                                    <Alert severity="info" className='my-3 w-100'>No. of vacancies</Alert>

                                    <Chip 
                                        label={`${jobData?.NumberOfPeopleToHire} Position`}
                                        sx={{ color: "grey", padding: "0px", borderRadius: "5px", fontWeight: "bold", backgroundColor: "rgba(0,125,0,0.1)", marginBottom: "8px"}}
                                        color= "success"
                                        size="small"
                                    />&nbsp;
                                </>
                            )}
                            <br />
                            <br />
                            <div className="d-flex text-secondary">
                                 Posted : &nbsp;<CalculatePostedDate dateTime={jobData?.PostedDate} />
                            </div> 
                            
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className="p-4">
                {(!isApplied && isJobApplicant || Roles.length === 0) && (
                    <Button id="btnApply" type="submit" onClick={() => applyThisJob(jobData?.JobId)}>Apply</Button>
                )}
                {(isApplied) && (
                    <Chip 
                        label="Applied"
                        sx={{ color: "white", padding: "0px"}}
                        color= "success"
                        size="small"
                    />
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default forwardRef(JobViewModal);
import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useLocation } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import { PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, Box, AppBar, Alert, Tooltip, Avatar } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { StandardConst } from '../../Services/StandardConst';
import { Form, FormAutoCompleteDropdown, FormAutoCompleteDropdownCard, FormCheckRadioInput, FormInputDropdown, FormInputFile, FormInputText } from '../Form';
import Button from "react-bootstrap/Button";
import { ReactangleShapeOfUploadPicture } from '../../Services/ImageUploader';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from "react-bootstrap/Modal";
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import SnackbarComponent from '../../Services/SnackbarComponent';
import ActionButton from '../../Services/ActionButton';
import Swal from "sweetalert2";
import EditAssetAssignment from './EditAssetAssignment';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { chain, chunk, isEmpty, map, range } from "underscore";
import { AssignAssetComponentModal } from '../ProfileComponent/EditProfileComponent';
import QRCodeGeneratorDownloadComponent from '../../Services/QRCodeGeneratorDownloadComponent';
import { format } from "date-fns";
import Carousel from "react-bootstrap/Carousel";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import BootstrapForm from "react-bootstrap/Form";
import { WSWarningAlert } from '../../Services/WSAlert';
import { ModalShowImagesInCarousel } from '../VehicleManagement/ModalShowImagesInCarousel';
import { DateTime } from 'luxon';


const AddAssetInformation = () => {

    const { state } = useLocation();
    const [AssetIdAfterSave, setAssetIdAfterSave] = useState(null);

    useState(() => {
        if (state && state.AssetId) {
            setAssetIdAfterSave(state.AssetId);
        }
    }, [state]);

    let PageTitle = "";
    if(state && state.AssetId){
        PageTitle = "Edit Asset";
    }else {
        PageTitle = "Add Asset";
    }
    PageInfo({ pageTitle: PageTitle });

    //Tabs start

    const [bData] = React.useState([
        {
            title: "Assets Management",
            hrefLink: "#",
        },
        {
            title: "Manage Assets",
            hrefLink: "#",
        },
    ]);
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const handleChange = (event, newValue) => {
        if(AssetIdAfterSave){
            setValue(newValue);
        }else {
            WSWarningAlert("Warning", "Please Fill Asset Information page first.");
            setValue(0);
        }
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0, mt: 0 }}>
                        <span>{children}</span>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    //Tab End

    const handleSaveAssetIdInAdd = (id) => {
        setAssetIdAfterSave(id);
    }

    return (
        <>
            <Container
                fluid
                style={{
                    padding: "0px",
                    borderRadius: "5px ",
                    margin: "10px",
                    backgroundColor: "#fff",
                    width: "90%"
                }}
            >
                <Topbar bData={bData} HeadingText={state?.AssetId === undefined || state?.AssetId === null || state.AssetId === 0 ? "Add Asset Information" : "Edit Asset Information"}></Topbar>
                <Box>
                    <AppBar
                        position="static"
                        color="info"
                        elevation={0}
                        className="Maintab_Custome mb-2"
                        style={{ maxHeight: "40px", height: "40px" }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            style={{
                                maxHeight: "40px",
                                height: "40px",
                            }}
                            id="tabs"
                        >
                            <Tab
                                icon={<WebAssetIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="General"
                                label={window.innerWidth <= 600 ? '' : "General"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="General"
                            />
                            <Tab
                                icon={<ShoppingCartIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Purchase Details"
                                label={window.innerWidth <= 600 ? '' : "Purchase Details"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="PurchaseDetails"
                            />
                            <Tab
                                icon={<RemoveShoppingCartIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Depreciation"
                                label={window.innerWidth <= 600 ? '' : "Depreciation"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="Depreciation"
                            />
                            <Tab
                                icon={<DescriptionIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="Documents"
                                label={window.innerWidth <= 600 ? '' : "Documents"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="Documents"
                            />
                            <Tab
                                icon={<AssignmentIndIcon />}
                                sx={{ "min-width": "40px !important" }}
                                aria-label="AssetAssignment"
                                label={window.innerWidth <= 600 ? '' : "Asset Assignment"}
                                iconPosition="start"
                                className="tab_Custome pt-0"
                                id="AssetAssignment"
                            />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <GeneralComponent
                                state={state}
                                GetAssetIdAfterSave={(AssetId) => handleSaveAssetIdInAdd(AssetId)}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <PurchaseDetailsComponent
                                state={state}
                                AssetIdAfterSave={AssetIdAfterSave}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            Depreciation
                        </TabPanel>
                        <TabPanel value={value} index={3} dir={theme.direction}>
                            <DocumentsComponent
                                state={state}
                                AssetIdAfterSave={AssetIdAfterSave}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={4} dir={theme.direction}>
                            <AssetAssignment
                                state={state}
                                AssetIdAfterSave={AssetIdAfterSave}
                            />
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            </Container>
        </>
    );
}

const GeneralComponent = (props) => {

    const [AssestTypeName, setAssestTypeName] = useState([]);
    const dispatch = useDispatch();
    const [data, setData] = useState({ Assets: {} });
    const [customFields, setCustomFields] = useState([]);
    const [customFieldInput, setCustomFieldInput] = useState([]);
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [imageName, setImageName] = useState([]);
    const [statusArray, setStatusArray] = useState([]);
    const [onChangeSelectAssetTypeName, setOnChangeSelectAssetTypeName] = useState({});
    
    const ExcludeReturnStatus = () => {
        const modifiedStatus = StandardConst.AssetStatusDropdown?.filter(item => item?.value !== StandardConst.AssetStatusDropdown[4].value);
        setStatusArray(modifiedStatus);
    }

    useEffect(() => {
        const fetchEditValue = async (id) => {
            const data = {
                General: await WebService({
                    endPoint: `CommonUtility/Edit/assets?AssetId=${id || 0}`,
                    dispatch,
                }).then((c) => (c.length > 0 ? c[0] : {})),
                CustomFieldValues: await WebService({
                    endPoint: `CommonUtility/fetchAll/assetcustomfieldvalues?AssetId=${id || 0}`,
                    dispatch,
                }).then((c) => (c.length > 0 ? c : [])),
            };
            // fix validation from name through CustomFieldId using name without value
            const customFieldValue = await Promise.all(data.CustomFieldValues.map(async(item) => {
                const data = await WebService({
                    endPoint: `CommonUtility/fetchAll/assetcustomfields?CustomFieldId=${item.CustomFieldId}`,
                    dispatch,
                }).then(c => c.length > 0 ? c[0] : {});
                return {[String(data.CustomFieldName)] : (item.CustomFieldValue)}
            }));
            const mergeCustom = Object.assign({}, ...customFieldValue);
            const DepartmentAndOfficeLocationData = await WebService({
                endPoint: `AssetAssignment/fetchDepartmentAndOfficeLocationByAssetId?AssetId=${data.General.AssetId || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {}));
            const combinedDetails = { ...data.General, ...mergeCustom, ...DepartmentAndOfficeLocationData};
            const mergeObject = {
                Assets: combinedDetails
            }
            handleChangeStatus(mergeObject.Assets.Status)
            setData(mergeObject);
            const AssetImages = {
                images: await WebService({
                    endPoint: `CommonUtility/Edit/assetimages?AssetId=${id || 0}`,
                    dispatch,
                }).then((c) => (c.length > 0 ? c : [])),
            };
            let storedImageNames = AssetImages.images.map(obj => (obj.AssetImageName));
            setImageNames(storedImageNames);
        };

        const fetchCustomFields = async() => {
            const data = await WebService({
                endPoint: `CommonUtility/fetchAll/assetcustomfields?CompanyId=${companyInfo.CompanyId}`,
                dispatch,
            });

            const modifiedArray = data?.map((item) => {
                if(item.CustomFieldType === StandardConst.dataTypes.String){
                    item.CustomFieldType = "text";
                }else if(item.CustomFieldType === StandardConst.dataTypes.Number){
                    item.CustomFieldType = "number";
                }else if(item.CustomFieldType === StandardConst.dataTypes.Date){
                    item.CustomFieldType = "date"
                }else if(item.CustomFieldType === StandardConst.dataTypes.Currency){
                    item.CustomFieldType = "currency";
                }
                return item;
            });
            setCustomFields(modifiedArray);
        }

        const fetchData = async () => {
            if (props.state && props.state.AssetId) {
                await fetchEditValue(props.state.AssetId);
            } else if (props.state && props.state.AssetId === undefined) {
                const data = {
                    Assets: {},
                };
                setData(data);
            }
        };

        fetchData();
        ExcludeReturnStatus();
        fetchOfficeLocation();
        fetchDepartments();
        fetchAssetTypes();
        fetchCustomFields();


    }, [props.state]);

    const fetchAssetTypes = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/assettype?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
        });

        const Reserved = await WebService({
            endPoint: `CommonUtility/Edit/assettype?isReserved=1`,
            dispatch,
        });
        const mergeArray = [...data, ...Reserved];
        const modifiedList = mergeArray?.filter((l) => l.Status !== StandardConst.StatusDropdown[1].value);
        const list = modifiedList?.map((r) => {
            return { text: r.AssetTypeName, value: r.AssetTypeId, icon: r.Icon }
        });
        setAssestTypeName(list);
    }

    const [imageNames, setImageNames] = useState([]);

    useEffect(() => {
        setImageNames([...imageNames, ...imageName]);
    }, [imageName]);

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;
        data.AssetTypeId = onChangeSelectAssetTypeName.value;
        if (props.state.AssetId === undefined) {
            const AssetId = await WebService({
                endPoint: "CommonUtility/assets",
                body: data,
                dispatch,
            });
            Swal.fire({
                text: "Saved",
                icon: "success"
            });
            props.GetAssetIdAfterSave(AssetId);
            data.AssetId = AssetId;
        } else {
            await WebService({
                endPoint: `CommonUtility/assets?AssetId=${props.state.AssetId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
            await WebService({
                endPoint: `CommonUtility/assetlocation?AssetId=${props.state.AssetId}`,
                method: "PUT",
                body: {ToDate: DateTime.local().toFormat('yyyy-MM-dd hh:mm a')},
                dispatch,
            });
            Swal.fire({
                text: "Save Changes",
                icon: "success"
            });
        }
        delete data.ToDate;
        data.FromDate = DateTime.local().toFormat('yyyy-MM-dd hh:mm a');
        await WebService({
            endPoint: "CommonUtility/assetlocation",
            body: data,
            dispatch,
        });

        customFieldInput.map(async (item) => {
            const collectData = item;
            collectData.AssetId = data.AssetId;
            const ifCustomFieldAlreadyExists = await WebService({
                endPoint: `CommonUtility/Edit/assetcustomfieldvalues?CustomFieldId=${item.CustomFieldId || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c : {}));

            if(ifCustomFieldAlreadyExists[0].AssetId === item.AssetId){
                await WebService({
                    endPoint: `CommonUtility/assetcustomfieldvalues?CustomFieldValueId=${ifCustomFieldAlreadyExists[0].CustomFieldValueId}`,
                    method: "PUT",
                    body: collectData,
                    dispatch,
                });
            }else {
                await WebService({
                    endPoint: "CommonUtility/assetcustomfieldvalues",
                    body: collectData,
                    dispatch,
                });
            }
        });

        imageNames.map(async (img) => {
            const ifImageIsAlreadyNotExists = await WebService({
                endPoint: `CommonUtility/Edit/assetimages?AssetImageName='${img}'`,
                dispatch,
            }).then((c) => (c.length > 0 ? c : []));
            if (ifImageIsAlreadyNotExists.length === 0) {
                if (img) data.AssetImageName = img;
                if (data.AssetImageName) {
                    await WebService({
                        endPoint: "CommonUtility/assetimages",
                        body: data,
                        dispatch,
                    });
                }
            }
        });
    }

    const onDeleteImage = async (imgName, DeleteIndex) => {
        await WebService({
            endPoint: `CommonUtility/assetimages?AssetImageName='${imgName}'`,
            method: "DELETE",
            dispatch,
            requiredLoader: false,
        });
        const updatedImageNames = imageNames.filter((_, index) => index !== DeleteIndex);
        setImageNames(updatedImageNames);
    }

    const ViewFullImageModelRef = useRef();

    const ViewFullImage = async (imgName, index) => {
        await ViewFullImageModelRef.current.openModal(imgName || "");
    }

    const [officeLocationIds, setOfficeLocationIds] = useState([]);
    const fetchOfficeLocation = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/officelocation?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
        });
        let list = data.map((r) => {
            const words = r.Address.split(' ');
            const truncatedWords = words.slice(0, 3);
            const truncatedAddress = truncatedWords.join(' ');
            const finalAddress = truncatedAddress + ', ' + r.Location;
            return { text: finalAddress, value: r.OfficeLocationId }
        });
        if (list.length === 0) {
            list = [{ text: "No Office Location" }]
        }
        setOfficeLocationIds(list);
    }

    const [departmentList, setDepartmentList] = useState([]);
    const fetchDepartments = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/static_department?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
        });
        let list = data.map((r) => {
            return { text: r.DepartmentName, value: r.DepartmentId }
        });
        if (list.length === 0) {
            list = [{ text: "No Department" }]
        }
        setDepartmentList(list);
    }

    const [showRemarksInput, setShowRemarksInput] = useState(false);

    const handleChangeStatus = (value) => {
        if (Number(value) === StandardConst.AssetStatusDropdown[6].value) {
            setShowRemarksInput(true);
        } else {
            setShowRemarksInput(false);
        }
    }

    const handleCurrentValue = (currentValue, CustomFieldId) => {
        const existingIndex = customFieldInput.findIndex(item => item.CustomFieldId === CustomFieldId);
        if (existingIndex !== -1) {
            const updatedCustomFieldInput = [...customFieldInput];
            updatedCustomFieldInput[existingIndex] = { CustomFieldId: CustomFieldId, CustomFieldValue: currentValue };
            setCustomFieldInput(updatedCustomFieldInput);
        } else {
            setCustomFieldInput([...customFieldInput, { CustomFieldId: CustomFieldId, CustomFieldValue: currentValue }]);
        }
    }

    // Function to generate validation schema dynamically based on custom fields
    const generateValidationSchema = (customFields) => {
        let schema = {};
        customFields.forEach(field => {
            let fieldSchema;
            if(field.CustomFieldType === "text" && field.CustomFieldIsRequired === StandardConst.YesNo.Yes){
                fieldSchema = yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg)
            }
            if(field.CustomFieldType === "number" && field.CustomFieldIsRequired === StandardConst.YesNo.Yes){
                fieldSchema = yup.number().required(StandardConst.ValidationMessages.RequiredFieldMsg)
            }
            if(field.CustomFieldType === "date" && field.CustomFieldIsRequired === StandardConst.YesNo.Yes){
                fieldSchema = yup.date().required(StandardConst.ValidationMessages.RequiredFieldMsg)
            }
            if(field.CustomFieldType === "currency" && field.CustomFieldIsRequired === StandardConst.YesNo.Yes){
                fieldSchema = yup.number().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg)
            }
            if (field.CustomFieldIsRequired === StandardConst.YesNo.Yes) {
                fieldSchema = fieldSchema;
            }
            schema[field.CustomFieldName] = fieldSchema;
        });
        return yup.object().shape(schema);
    };

    // Combined validation schema
    const combinedValidationSchema = (customFields) => {
        const dynamicSchema = generateValidationSchema(customFields);
        const staticSchema = yup.object().shape({
            AssetName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Status: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        });
        return yup.object().shape({
            ...dynamicSchema.fields,
            ...staticSchema.fields,
        });
    };
    const validationSchema = combinedValidationSchema(customFields);

    const [companyCurrencyData, setCompanyCurrencyData] = useState({});
    const fetchCurrencyData = async () => {
        const companyInformation = await WebService({
            endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${companyInfo.CompanyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        const currencyInformation = await WebService({
            endPoint: `CommonUtility/Edit/staticcurrency?CurrencyId=${companyInformation.CurrencyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        const currencyLocaleCode = await WebService({
            endPoint: `CommonUtility/Edit/countries?CountryId=${currencyInformation.CountryId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        currencyInformation.LocaleCode = currencyLocaleCode.LocaleCode;

        setCompanyCurrencyData(currencyInformation);
    };

    useEffect(() => {
        if(companyInfo?.CurrencyId){
            fetchCurrencyData();
        }
    }, [companyInfo]);

    let classNameMdNone = "";
    let isRequiredClass = "";
    const isRequired = "false";
    const label = "label";
    if (isRequired === "true") {
        isRequiredClass = "text-danger";
    } else {
        isRequiredClass = "d-none";
    }
    let classNameMd = "";
    if ((label || "") == "") {
        classNameMdNone = "col-25 float-start col-md-5 text-wrap d-none";
        classNameMd = "float-end col-md-12 col-75 ";
    } else {
        classNameMdNone = "col-75 float-start col-md-5 text-wrap";
        classNameMd = "col-75 float-end col-md-7";
    }


    const handleChangeAssetTypeName = (e, value) => {
        setOnChangeSelectAssetTypeName(value);
    }

    const [OfficeLocationRecords, setOfficeLocationRecords] = useState([]);

    const fetchAllAssetOfficeLocationData = async() => {
        const data = await WebService({
            endPoint: `AssetAssignment/fetchOfficeLocationByAsset?AssetId=${props.state.AssetId}`,
            dispatch
        });

        const modifiedArray = data.map((item) => {

            if (item.Address || item.Location) {
                const words = item.Address && item.Address?.split(' ');
                const truncatedWords = words?.slice(0, 3);
                const truncatedAddress = truncatedWords?.join(' ');
                const finalAddress = truncatedAddress + ', ' + item?.Location;
                item.finalAddress = finalAddress;
            }

            if(item.FromDate) item.FromDate = DateTime.fromISO(item.FromDate, { zone: 'UTC' }).toFormat('dd-MM-yyyy hh:mm a');

            if(item.ToDate) item.ToDate = DateTime.fromISO(item.ToDate, { zone: 'UTC' }).toFormat('dd-MM-yyyy hh:mm a');

            return item;
        });
        setOfficeLocationRecords(modifiedArray);
    }

    useEffect(() => {
        if(props.state.AssetId) fetchAllAssetOfficeLocationData();
    }, []);

    const OfficeLocationColumns = [
        {
            Text: "Office Location", 
            Value: "finalAddress"
        },
        {
            Text: "From Date",
            Value: "FromDate",
        },
        {
            Text: "ToDate",
            Value: "ToDate",
        }
    ];

    return (
        <>
            <div>
                <Container className="base-container" fluid style={{ width: "98%" }}>
                    <div className="row p-0 mb-2 d-flex flex-row">
                        <div className="col-md-8">
                            {Object.keys(data?.Assets).length > 0 || props?.state?.AssetId === undefined || props?.state?.AssetId === null || props.state.AssetId === 0 ? (
                                <div>
                                    <Form
                                        defaultValues={data.Assets}
                                        onSubmit={onSubmit}
                                        validationSchema={validationSchema}
                                    >
                                        <div className="card shadow-none border-0  border-start footer-widget">
                                            <Alert severity="info" sx={{ width: "95%" }}>Asset Information</Alert>
                                            <div className="card-body">
                                                <div className="row mt-1">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <FormInputText
                                                                label="Asset Name"
                                                                name="AssetName"
                                                                type="text"
                                                                isRequired="true"
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <FormInputText
                                                                name="Description"
                                                                label="Description"
                                                                as="textarea"
                                                                rows="2"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <FormInputText
                                                                label="Asset Number"
                                                                name="AssetNumber"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <FormInputText
                                                                label="Asset Tag Id"
                                                                name="AssetTagId"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="col-md-12 pt-2 pb-2">
                                                            <div className={`${classNameMdNone}`}>
                                                                <BootstrapForm.Label>
                                                                    <div className={`d-flex justify-content-start`}>
                                                                        <div style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
                                                                            Asset Type Name
                                                                        </div>
                                                                        <div className={isRequiredClass}>＊</div>
                                                                    </div>
                                                                </BootstrapForm.Label>{" "}
                                                            </div>
                                                            <div className={classNameMd}>
                                                                <Autocomplete
                                                                    id="asset-type-select"
                                                                    options={AssestTypeName}
                                                                    autoHighlight
                                                                    onChange={(e, val) => handleChangeAssetTypeName(e, val)}
                                                                    defaultValue={AssestTypeName.find(item => item.value === data.Assets.AssetTypeId)}
                                                                    getOptionLabel={(option) => option.text}
                                                                    renderOption={(props, option) => (
                                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                            <i className={option.icon}></i>
                                                                            <span style={{ marginLeft: "5px" }}>{option.text}</span>
                                                                        </Box>
                                                                    )}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Choose a Asset type name"
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pb-2">
                                                            <FormInputDropdown
                                                                name="Status"
                                                                ddOpt={statusArray}
                                                                label="Status"
                                                                setValue={(value) => handleChangeStatus(value)}
                                                            />
                                                        </div>
                                                        {
                                                            showRemarksInput && (
                                                                <>
                                                                    <div className="col-md-12">
                                                                        <FormInputText
                                                                            name="Remarks"
                                                                            label="Remarks"
                                                                            as="textarea"
                                                                            rows="2"
                                                                            className="form-control"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        {/* custom fields */}
                                                        {
                                                            customFields.sort((a, b) => a.DisplayOrder - b.DisplayOrder)?.map((field, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div className="col-md-12">
                                                                        <FormInputText
                                                                            label={field.CustomFieldLabel}
                                                                            name={field.CustomFieldName}
                                                                            type={field.CustomFieldType}
                                                                            isRequired={field.CustomFieldIsRequired === StandardConst.YesNo.Yes ? "true" : "false"}
                                                                            portalId="root"
                                                                            setValue={(val) => handleCurrentValue(val, field.CustomFieldId)}
                                                                            locale={companyCurrencyData?.LocaleCode}
                                                                            currencyCode={companyCurrencyData?.CurrencyCode}
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="mx-n4 my-2">
                                                        <Alert severity="info" sx={{ width: "99%" }}>Asset Location</Alert>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 pb-1">
                                                            <FormInputDropdown
                                                                name="OfficeLocationId"
                                                                ddOpt={officeLocationIds}
                                                                label="Office Location"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <FormInputDropdown
                                                                name="DepartmentId"
                                                                ddOpt={departmentList}
                                                                label="Department"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="outline-primary"
                                                        type="submit"
                                                        id="btnSubmit"
                                                        className="px-4 float-end mt-4 mb-1"
                                                        style={{ marginRight: "27px" }}
                                                    >
                                                        Save Changes
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                    <div className="office-location-movement-table">
                                        <TableComponent
                                            columns={OfficeLocationColumns}
                                            data={OfficeLocationRecords}
                                            noRecordCss="p-0"
                                            noRecordFound={
                                                <NoRecordTemplate
                                                    headerValue={StandardConst.OfficeLocationMovementValueNoResults}
                                                    subHeaderValue={StandardConst.OfficeLocationMovementSubHeaderRole}
                                                    imageUrl={StandardConst.imageNoRecordsFound}
                                                />
                                            }
                                            isSearchRequired={false}
                                            allowSerialNo={true}
                                            isExcelExportButtonVisible={false}
                                        />
                                    </div>
                                </div>
                            )
                                :
                                ""}
                        </div>
                        <div className="col-md-4">
                            <div id="asset-qrcode">
                                <div className="Heading mb-4">
                                    <Alert severity="info">Asset QR Code</Alert>
                                </div>
                                {
                                    data.Assets.AssetId && (
                                        <>
                                            <div className='qr-code'>
                                                <QRCodeGeneratorDownloadComponent data={`AssetId:${data.Assets.AssetId}, AssetName:${data.Assets.AssetName}`} showWodLogo={false} showLogo={false} EventLogo={null} Text="Powered by wiseofficeday.com" />
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div id="asset-images" className='mt-3'>
                                <div className="Heading mb-4">
                                    <Alert severity="info">Asset Images</Alert>
                                </div>
                                <div id="photo-upload" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ReactangleShapeOfUploadPicture
                                        UploadedFileName={setImageName}
                                        endPointUrl={"upload/File?destination=assetImages"}
                                        multipleSelect={true}
                                    >
                                    </ReactangleShapeOfUploadPicture>
                                </div>

                                <div style={{ display: "flex", width: "100%" }}>
                                    <div className="uploaded-images" style={{ flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                        {
                                            imageNames.map((imgName, index) => (
                                                <Card sx={{ maxWidth: "200px", maxHeight: "150px", margin: "8px" }}
                                                    key={index}
                                                >
                                                    {imgName && index % 2 !== 0 ? (
                                                        <>
                                                            <div className="pic-holder" style={{ border: "none", borderRadius: 0 }}>
                                                                <CardMedia
                                                                    component="img"
                                                                    height="100px"
                                                                    style={{ width: "70%" }}
                                                                    image={`${StandardConst.apiBaseUrl}/uploads/assetImages/${imgName}`}
                                                                />
                                                                <label
                                                                    htmlFor="newVisitorPhoto"
                                                                    className="upload-file-block"
                                                                >
                                                                    <Tooltip title="Delete Image">
                                                                        <IconButton onClick={async () => await onDeleteImage(imgName, index)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="View Image">
                                                                        <IconButton onClick={() => ViewFullImage(imgName, index)}>
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </label>
                                                            </div>
                                                        </>
                                                    )
                                                        :
                                                        ""}
                                                </Card>
                                            ))
                                        }
                                    </div>
                                    <div className="uploaded-images" style={{ flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                        {
                                            imageNames.map((imgName, index) => (
                                                <Card sx={{ maxWidth: "200px", maxHeight: "150px", margin: "8px" }}
                                                    key={index}
                                                >
                                                    {imgName && index % 2 === 0 ? (
                                                        <>
                                                            <div className="pic-holder" style={{ border: "none", borderRadius: 0 }}>
                                                                <CardMedia
                                                                    component="img"
                                                                    height="100px"
                                                                    style={{ width: "70%" }}
                                                                    image={`${StandardConst.apiBaseUrl}/uploads/assetImages/${imgName}`}
                                                                />
                                                                <label
                                                                    htmlFor="newVisitorPhoto"
                                                                    className="upload-file-block"
                                                                >
                                                                    <Tooltip title="Delete Image">
                                                                        <IconButton onClick={async () => await onDeleteImage(imgName, index)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="View Image">
                                                                        <IconButton onClick={() => ViewFullImage(imgName, index)}>
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </label>
                                                            </div>
                                                        </>
                                                    )
                                                        :
                                                        ""}
                                                </Card>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='viewModel'>
                                <ViewFullImageModel
                                    ref={ViewFullImageModelRef}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

const ViewFullImageModel = forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const [imgName, setImgName] = useState("");
    useImperativeHandle(ref, () => ({
        openModal: async (imgName) => {
            setShow(true);
            setImgName(imgName);
        },
    }));

    const handleClose = () => {
        setShow(false)
        setImgName("");
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        View Asset Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <img src={`${StandardConst.apiBaseUrl}/uploads/assetImages/${imgName}`} alt="" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnClose" variant="outline-primary" type="submit" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});


const PurchaseDetailsComponent = (props) => {

    const [data, setData] = useState({ PurchaseDetails: {} })
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchEditValue = async (id) => {
            const data = {
                PurchaseDetails: await WebService({
                    endPoint: `CommonUtility/Edit/assetpurchasedetail?AssetId=${id || 0}`,
                    dispatch,
                    requiredLoader: false,
                }).then((c) => (c.length > 0 ? c[0] : {})),
            };
            if ((data?.PurchaseDetails?.Year || "") !== "") { 
                data.PurchaseDetails.Year = format(new Date(data.PurchaseDetails.Year), "yyyy");
            }
            if(isEmpty(data.PurchaseDetails)){
                data.PurchaseDetails.Manufacturer = null; // if form is empty form should be open
            }
            setData(data);
        };

        const fetchData = async () => {
            if (props.state && props.state.AssetId) {
                await fetchEditValue(props.state.AssetId);
            } else if (props.state && props.state.AssetId === undefined) {
                const data = {
                    PurchaseDetails: {},
                };
                setData(data);
            }
        };

        fetchData();

    }, [props.state]);

    const SubmitPurchaseDetails = async (data) => {
        data.AssetId = props.state.AssetId || props.AssetIdAfterSave;
        data.Year = format(new Date(data.Year), "yyyy-MM-dd");
        if (data.AssetId === undefined || data.AssetId === null) {
            Swal.fire({
                text: "You need to before save data in General Details.",
                icon: "warning"
            });
        } else {
            if (data.AssetPurchaseDetailId) {
                await WebService({
                    endPoint: `CommonUtility/assetpurchasedetail?AssetPurchaseDetailId=${data.AssetPurchaseDetailId}`,
                    method: "PUT",
                    body: data,
                    dispatch,
                });
            }else {
                await WebService({
                    endPoint: "CommonUtility/assetpurchasedetail",
                    body: data,
                    dispatch,
                });
            }
            Swal.fire({
                text: "Save Changes",
                icon: "success"
            });
        }
    }

    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [companyCurrencyData, setCompanyCurrencyData] = useState({});
    const fetchCurrencyData = async () => {
        const companyInformation = await WebService({
            endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${companyInfo.CompanyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        const currencyInformation = await WebService({
            endPoint: `CommonUtility/Edit/staticcurrency?CurrencyId=${companyInformation.CurrencyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        const currencyLocaleCode = await WebService({
            endPoint: `CommonUtility/Edit/countries?CountryId=${currencyInformation.CountryId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        currencyInformation.LocaleCode = currencyLocaleCode.LocaleCode;
        setCompanyCurrencyData(currencyInformation);
    };

    useEffect(() => {
        if(companyInfo?.CurrencyId){
            fetchCurrencyData();
        }
    }, [companyInfo]);

    return (
        <>
            <Container className="base-container" fluid style={{ width: "98%" }}>
                <div className="row p-0 mb-2 d-flex flex-row">
                    <div className="col-md-12">
                        {data?.PurchaseDetails && Object?.keys(data.PurchaseDetails)?.length > 0 || props.state.AssetId === undefined || props.AssetIdAfterSave === null ? (
                            <Form
                                defaultValues={data?.PurchaseDetails}
                                onSubmit={SubmitPurchaseDetails}
                            >
                                <div className="card shadow-none border-0  border-start footer-widget">
                                    <Alert severity="info">Purchase Details</Alert>
                                    <div className="card-body">
                                        <div className="row mt-1">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Manufacturer"
                                                        name="Manufacturer"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Year"
                                                        name="Year"
                                                        type="dateYear"
                                                        portalId="root"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Model"
                                                        name="Model"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Serial No"
                                                        name="SerialNo"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Barcode No"
                                                        name="BarcodeNo"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Purchase Order No"
                                                        name="PurchaseOrderNo"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Vendor Name And Address"
                                                        name="VendorNameAndAddress"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Order Date"
                                                        name="OrderDate"
                                                        type="date"
                                                        portalId="root"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Order No"
                                                        name="OrderNo"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Invoice No"
                                                        name="InvoiceNo"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="col-md-12" style={{ color: '#5a5a5a' }}>
                                                    <FormInputText
                                                        label="Cost"
                                                        name="Cost"
                                                        type="currency"
                                                        locale={companyCurrencyData?.LocaleCode}
                                                        currencyCode={companyCurrencyData?.CurrencyCode}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        name="WarrantyInformation"
                                                        label="Warranty Information"
                                                        as="textarea"
                                                        rows="2"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Warranty Expiry Date"
                                                        name="WarrantyExpiryDate"
                                                        type="date"
                                                        portalId="root"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        name="LicensingInformation"
                                                        label="Licensing Information"
                                                        as="textarea"
                                                        rows="2"
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputDropdown
                                                        name="AssetCondition"
                                                        ddOpt={StandardConst.AssetCondition}
                                                        label="Asset Condition"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <Button
                                        variant="outline-primary"
                                        type="submit"
                                        id="btnSubmit"
                                        className="px-4 float-end"
                                        style={{ marginRight: "40px" }}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </Form>
                        ) : ""}
                    </div>
                </div>
            </Container>
        </>
    )
}

const DocumentsComponent = (props) => {

    const [data, setData] = useState({ Documents: {} })
    const dispatch = useDispatch();
    const ref = useRef();
    const refSnackbar = useRef();
    const documentFormRef = useRef();
    const ViewAttachmentsModelRef = useRef();
    const confirmMessage = "Uploaded File Deleted successfully";
    const [FileDetails, setFileDetails] = useState([]);
    const [FileNames, setFileNames] = useState([]);
    const [FilesForTable, setFilesForTable] = useState([]);

    const SubmitDocumentDetails = async (data) => {
        data.AssetId = props.state.AssetId || props.AssetIdAfterSave;
        if (FileNames.length === 0) {
            Swal.fire({
                text: "You Should upload atleast one Attachment",
                icon: "warning"
            });
        } else {

            if (data.AssetId === undefined || data.AssetId === null) {
                Swal.fire({
                    text: "You need to before save data in General Details.",
                    icon: "warning"
                });
            }else {
            const AssetDocumentId = await WebService({
                endPoint: "CommonUtility/assetdocuments",
                body: data,
                dispatch,
            });
            data.AssetDocumentId = AssetDocumentId;
            FileNames?.map(async (file) => {
                data.FileName = await WebService({
                    endPoint: "upload/File",
                    dispatch,
                    body: file,
                    isFile: true,
                }).then((res) => res.filename);
                if (props.state.AssetId === undefined) {
                    await WebService({
                        endPoint: "CommonUtility/assetdocumentattchments",
                        body: data,
                        dispatch,
                    });
                } else {
                    await WebService({
                        endPoint: "CommonUtility/assetdocumentattchments",
                        body: data,
                        dispatch,
                    });
                }
            });
            Swal.fire({
                text: "Added Document",
                icon: "success"
            });
        }
            fetchDocumentsTable();
            documentFormRef.current.fnReset();
            setFilesForTable([]);
            setFileNames([]);
        }
    }

    function generateUniqueId() {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 10000);
        return `${timestamp}${random}`;
    }

    useEffect(() => {
        if (FileDetails.length > 0) {
            const uniqueId = generateUniqueId();
            FileDetails.map((file) => {
                file.id = Number(uniqueId);
                setFileNames(prevFileNames => [...prevFileNames, file]);
                setFilesForTable(prevFileNames => [...prevFileNames, { fileName: file?.name, AssetDocumentId: Number(uniqueId) }]);
            });
        }
    }, [FileDetails]);

    const schema = yup
        .object()
        .shape({
            DocumentName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onDeleteFile = async (AssetDocumentId) => {
        await WebService({
            endPoint: `CommonUtility/assetdocuments?AssetDocumentId=${AssetDocumentId}`,
            method: "DELETE",
            dispatch,
            requiredLoader: false,
        });
        const updatedArray = FileNames.filter((file) => file.AssetDocumentId !== AssetDocumentId);
        setFileNames(updatedArray);
        const updatedFileNames = FilesForTable.filter((file) => file.AssetDocumentId !== AssetDocumentId);
        setFilesForTable(updatedFileNames);
        refSnackbar.current.setOpenSnackBar();
    };

    const [DocumentsTable, setDocumentTable] = useState([]);

    const fetchAllDocuments = async (id) => {
        const data = {
            AllDocuments: await WebService({
                endPoint: `CommonUtility/fetchAll/assetdocuments?AssetId=${id || 0}`,
                dispatch,
            })
        };
        setDocumentTable(data.AllDocuments);
    };

    const fetchDocumentsTable = async () => {
        props.state.AssetId = props.AssetIdAfterSave;
        if (props.state && props.state.AssetId) {
            await fetchAllDocuments(props.state.AssetId);
        } else if (props.state && props.state.AssetId === undefined) {
            const data = {
                Documents: {},
            };
            setData(data);
        }
    };

    useEffect(() => {
        fetchDocumentsTable();
    }, [props.state]);

    const ViewAllAttachments = async (dbInfoObj, AssetDocumentId) => {
        await ViewAttachmentsModelRef.current.openModal(dbInfoObj, AssetDocumentId);
    }

    const DocumentColumns = [
        {
            Text: "Document Name",
            Value: "DocumentName",
        },
        {
            Text: "Document No",
            Value: "DocumentNo",
        },
        {
            Text: "Date",
            Value: "Date",
            DateFormat: "dd-MM-yyyy",

        },
        {
            Text: "Document Description",
            Value: "DocumentDescription",
        },
        {
            Text: "Attachments",
            cssClass: "text-center td-width-100",
            Value: "Attachments",
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => ViewAllAttachments({tableName: 'assetdocumentattchments', fileFildName: 'FileName', compareFildName: 'AssetDocumentId', folderPath: `${StandardConst.apiBaseUrl}/uploads/` }, dr.AssetDocumentId)}
                        IconName="View"
                        id={`btnDocumentFileEdit_${dr.AssetDocumentId}`}
                    />
                </>
            )
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: true,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.DocumentName, // Message of Alert
                                dr.AssetDocumentId // Endpoint to hit for delete
                            )
                        }
                        IconName="Delete"
                        id={`btnDocumentFileDelete${dr.AssetDocumentId}`}
                    />
                </>
            ),
        },
    ]

    const onDeleteDocument = async (AssetDocumentId) => {
        await WebService({
            endPoint: `CommonUtility/assetdocuments?AssetDocumentId=${AssetDocumentId}`,
            method: "DELETE",
            dispatch,
            requiredLoader: false,
        });
        refSnackbar.current.setOpenSnackBar();
        fetchDocumentsTable();
    };

    const handleSelectDocumentFile = (files) => {
        const Files = Array.from(files);
        setFileDetails([...Files]);
    }

    return (
        <>
            <Container className="base-container" fluid style={{ width: "98%" }}>
                <div className="row p-0 mb-2 d-flex flex-row">
                    <div className="col-md-12">
                        <Form
                            defaultValues={data?.Documents}
                            onSubmit={SubmitDocumentDetails}
                            validationSchema={schema}
                            ref={documentFormRef}
                        >
                            <div className="card shadow-none border-0  border-start footer-widget">
                                <Alert severity="info">Asset Documents</Alert>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormInputText
                                                label="Document Name"
                                                name="DocumentName"
                                                type="text"
                                                isRequired="true"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <FormInputText
                                                label="Document No"
                                                name="DocumentNo"
                                                type="text"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <FormInputText
                                                label="Date"
                                                name="Date"
                                                type="date"
                                                max="2999-12-31"
                                                portalId="root"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <FormInputText
                                                name="DocumentDescription"
                                                label="Document Description"
                                                as="textarea"
                                                rows="2"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className='col-md-12' style={{ position: "relative" }}>
                                            <div className='position-upload'>
                                                <label htmlFor="upload-document" style={{ color: "#5a5a5a" }}>
                                                    Attachments
                                                    <span className="text-danger">＊</span>
                                                </label>
                                                <div style={{ display: "inline-block", position: "absolute", left: "500px", width: "222px" }}>
                                                    <FormInputFile
                                                        lable="Attachments"
                                                        name="Files"
                                                        type="file"
                                                        setValue={(files) => { handleSelectDocumentFile(files) }}
                                                        selectMultipleFiles={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-5"></div>
                                            <div className="col-md-7">
                                                <div className="uploaded-attachments">
                                                    {
                                                        FilesForTable.map((file, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className="d-inline-block">
                                                                    <AttachmentPreview
                                                                        fileName={file.fileName}
                                                                        onDeleteFile={() => onDeleteFile(file.AssetDocumentId)}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-end">
                                            <div className="mt-4 d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    type="submit"
                                                    id="btnSubmit"
                                                    className="px-4 float-end"
                                                >
                                                    Add Document
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <ModalShowImagesInCarousel 
                            ref={ViewAttachmentsModelRef} 
                        />
                        <div className="document-table">
                            <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
                            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDeleteDocument(v)} />
                            <TableComponent
                                columns={DocumentColumns}
                                data={DocumentsTable}
                                noRecordCss="p-0"
                                noRecordFound={
                                    <NoRecordTemplate
                                        headerValue={StandardConst.DocumentsUploadheaderValueNoResults}
                                        subHeaderValue={StandardConst.DocumentsUploadSubHeaderRole}
                                        imageUrl={StandardConst.imageNoRecordsFound}
                                    />
                                }
                                isSearchRequired={false}
                                allowSerialNo={true}
                                isExcelExportButtonVisible={false}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

const AttachmentPreview = ({ fileName, onDeleteFile }) => {

    const renderPreview = () => {

        function insertNewlines(str) {
            var result = '';
            for (var i = 0; i < str.length; i++) {
                result += str[i];
                if ((i + 1) % 7 === 0) {
                    result += '\n';
                }
            }
            return result;
        }
        
        var inputString = fileName;
        var stringWithNewlines = insertNewlines(inputString);
        fileName = stringWithNewlines;

        return (
            <>
                <div className="pic-holder" style={{ border: "none", borderRadius: 0, margin: 0, height: "100px", width: "100px" }}>
                    <img src={`${StandardConst.apiBaseUrl}/uploads/3720094.png`} style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden' }} />
                    <label
                        htmlFor="newVisitorPhoto"
                        className="upload-file-block"
                    >
                        <div style={{ fontSize: "10px", marginLeft: "17px" }}>
                            {fileName}
                        </div>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => onDeleteFile()}>
                                <DeleteIcon sx={{color: "red"}}/>
                            </IconButton>
                        </Tooltip>
                    </label>
                </div>
            </>
        );
    };

    return (
        <div className="file-preview mt-2">
            {renderPreview()}
        </div>
    );
};

const AssetAssignment = (props) => {

    const [data, setData] = useState({ AssetAssign: {} });
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const dispatch = useDispatch();
    const ref = useRef();
    const [AssetsList, setAssetsList] = useState([]);
    const [WhomToAssignList, setWhomToAssignList] = useState([]);
    const [AssetAssignedRecords, setAssetAssignedRecords] = useState([]);
    const AssignAssetModelRef = useRef();

    const fetchAllEssentialInformation = async () => {
        const data = {
            Assets: await WebService({
                endPoint: `CommonUtility/fetchAll/assets?AssetId=${props.state.AssetId || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c : {})),
            Employees: await WebService({
                endPoint: `Visitor/fetchAllEmployees`,
                dispatch,
            }),
            Users: await WebService({
                endPoint: `CommonUtility/fetchAll/users?CompanyId=${companyInfo.CompanyId || 0}`,
                dispatch,
            }),
        }

        const Assets = (data?.Assets)?.map((r) => {
            return { text: r.AssetName, value: r.AssetId }
        })
        setAssetsList(Assets);
        const emplist = data.Employees?.filter((r) => r.FullName !== null)?.map((res) => {
            return {
                EmployeeId: res.EmployeeId,
                heading: res.FullName,
                description: res.Designation,
                avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        const userslist = data.Users?.filter((r) => r.FullName !== null)?.map((res) => {
            return {
                UserId: res.UserId,
                heading: res.FullName,
                description: res.Designation,
                avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        const whomToAssignArray = [...emplist, ...userslist];
        setWhomToAssignList(whomToAssignArray);
    }


    useEffect(() => {
        fetchAllEssentialInformation();
    }, [props.state]);

    const SubmitAssetAssignDetails = async (data) => {
        data.AssetId = props.state.AssetId || props.AssetIdAfterSave;
        const whomToAssignKeys = Object.keys(data.WhomToAssign[0]);
        const isEmployeeId = whomToAssignKeys.some((key) => key === "EmployeeId");

        let UserIdOrEmployeeId = 0;
        let UserKeyOrEmployeeKey = "";
        if (isEmployeeId) {
            data.EmployeeId = data.WhomToAssign[0].EmployeeId;
            UserIdOrEmployeeId = data.EmployeeId;
            UserKeyOrEmployeeKey = "EmployeeId";
        } else {
            data.UserId = data.WhomToAssign[0].UserId;
            UserIdOrEmployeeId = data.UserId;
            UserKeyOrEmployeeKey = "UserId";
        }
        delete data.WhomToAssign;
        const AssetStatus = await WebService({
            endPoint: `CommonUtility/Edit/assets?AssetId=${data.AssetId}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        if (AssetStatus.Status === StandardConst.AssetStatusDropdown[1].value) {
            Swal.fire({
                text: `This asset is already assigned.`,
                icon: "warning"
            });
        }else if(AssetStatus.Status === StandardConst.AssetStatusDropdown[2].value){
            Swal.fire({
                text: `This asset is ${StandardConst.AssetStatusDropdown[2].text.toLowerCase()}`,
                icon: "warning"
            });
        } else if(AssetStatus.Status === StandardConst.AssetStatusDropdown[3].value){
            Swal.fire({
                text: `This asset is ${StandardConst.AssetStatusDropdown[3].text.toLowerCase()}`,
                icon: "warning"
            });
        }else if(AssetStatus.Status === StandardConst.AssetStatusDropdown[5].value){
            Swal.fire({
                text: `This asset is ${StandardConst.AssetStatusDropdown[5].text.toLowerCase()}`,
                icon: "warning"
            });
        } else if(AssetStatus.Status === StandardConst.AssetStatusDropdown[6].value){
            Swal.fire({
                text: `This asset is ${StandardConst.AssetStatusDropdown[6].text.toLowerCase()}`,
                icon: "warning"
            });
        }
        else if (AssetStatus.Status === StandardConst.AssetStatusDropdown[0].value) {
            await WebService({
                endPoint: `CommonUtility/assets?AssetId=${data.AssetId}`,
                method: "PUT",
                body: data,
                dispatch,
            });

            if (data.AssetId === undefined || data.AssetId === null ) {
                await WebService({
                    endPoint: "CommonUtility/assetassignment",
                    body: data,
                    dispatch,
                });
                Swal.fire({
                    text: "You need to before save data in General Details.",
                    icon: "warning"
                });
            } else {
                if (data.AssetAssignmentId === undefined) {
                    await WebService({
                        endPoint: "CommonUtility/assetassignment",
                        body: data,
                        dispatch,
                    });
                    Swal.fire({
                        text: "Saved",
                        icon: "success"
                    });
                }
            }
        }
        fetchAllAssignedAssets();
    }

    const fetchAllAssignedAssets = async () => {
        if (props.state && props.state.AssetId) {
            const data = await WebService({
                endPoint: `AssetAssignment/fetchAssignedAssets?AssetId=${props.state.AssetId}`,
                dispatch,
            })
            setAssetAssignedRecords(data);
        }
    }

    useEffect(() => {
        fetchAllAssignedAssets();
        ExcludeReturnStatus();
    }, [props.state]);

    const EditAssetAssignmentModalRef = useRef();
    const fnEdit = async (id) => await EditAssetAssignmentModalRef.current.openModal(id || 0);

    const AssignedColumns = [
        {
            Text: "Person",
            render: (dr) => (
                <>
                    <div className="d-flex flex-row align-items-center">
                        <div>
                            <Avatar
                                variant="circle"
                                alt={dr.EmployeeName ? dr.EmployeeName : dr.UserName}
                                src={
                                    dr?.ProfileImage
                                        ? `${StandardConst.apiBaseUrl}/uploads/${dr?.ProfileImage}`
                                        : `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                                }
                                sx={{ width: 40, height: 40 }}
                            />
                        </div>
                        <div className="d-flex flex-column ms-2">
                            <span>
                                <strong>{dr.EmployeeName ? dr.EmployeeName : dr.UserName ? dr.UserName : ""}</strong>
                            </span>
                        </div>
                    </div>
                </>
            ),
        },
        {
            Text: "Asset Name",
            Value: "AssetName",
        },
        {
            Text: "Issue Date",
            Value: "IssueDate",
            DateFormat: "dd-MM-yyyy",
        },
        {
            Text: "Return Date",
            Value: "ReturnDate",
            DateFormat: "dd-MM-yyyy",
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            // isVisiable: permission.ManageEdit || permission.ManageDelete,
            isVisiable: true,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.AssetAssignmentId)}
                        // disabled={!permission.ManageEdit}
                        IconName="Edit"
                        id={`btnAssignmentEdit_${dr.AssetAssignmentId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.AssetName, // Message of Alert
                                dr.AssetAssignmentId                                // Endpoint to hit for delete
                            )
                        }
                        disabled={dr.ReturnDate === null}
                        IconName="Delete"
                        id={`btnDocumentFileDelete${dr.AssetAssignmentId}`}
                    />
                </>
            ),
        },
    ]

    const onDeleteAssetAssigned = async(AssetAssignmentId) => {
        await WebService({
            endPoint: `CommonUtility/assetassignment?AssetAssignmentId=${AssetAssignmentId}`,
            method: "DELETE",
            dispatch,
            requiredLoader: false,
        });
        fetchAllAssignedAssets();
    }

    const [statusArray, setStatusArray] = useState([]);

    const ExcludeReturnStatus = () => {
        const modifiedStatus = StandardConst.AssetAssign?.filter(item => item?.value !== StandardConst.AssetAssign[3].value && ![StandardConst.AssetAssign[1].value, StandardConst.AssetAssign[2].value].includes(item?.value));
        setStatusArray(modifiedStatus);
    }
    let QRData = {};

    const handleSelectedPerson = (value) => {
        if (value[0].CompanyId) {
            const whomToAssignKeys = Object.keys(value[0]);
            const isEmployeeId = whomToAssignKeys.some((key) => key === "EmployeeId");
            if (isEmployeeId) {
                QRData.EmployeeId = value[0].EmployeeId;
                QRData.UserId = 0;
            } else {
                QRData.UserId = value[0].UserId;
                QRData.EmployeeId = 0;
            }
        }
    }

    return (
        <>
            <Container className="base-container" fluid style={{ width: "98%" }}>
                <div className="row p-0 mb-2 d-flex flex-row">
                    <div className="col-md-12">
                        <Form
                            defaultValues={data?.AssetAssign}
                            onSubmit={SubmitAssetAssignDetails}
                        >
                            <div className="card shadow-none border-0  border-start footer-widget">
                                <Alert severity="info">Asset Assign</Alert>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormInputDropdown
                                                name="AssetId"
                                                ddOpt={AssetsList}
                                                label="Asset Name"
                                                disabled="true"
                                            />
                                        </div>
                                        <div className="col-md-12 mt-2 mb-2">
                                            <FormAutoCompleteDropdownCard
                                                name="WhomToAssign"
                                                data={chain(WhomToAssignList ?? [])}
                                                label="Person"
                                                optionText="heading"
                                                placeholder="Choose a Person"
                                                setValue={(val) => { handleSelectedPerson(val) }}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <FormInputText
                                                name="IssueDate"
                                                label="Issue Date"
                                                type="date"
                                                portalId="root"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <FormInputDropdown
                                                name="Status"
                                                ddOpt={statusArray}
                                                label="Status"
                                            />
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-end">
                                            <div className="mt-4 d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    type="submit"
                                                    id="btnSubmit"
                                                    className="px-4 float-end"
                                                >
                                                    Asign Asset
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    id="btnQRCode"
                                                    className="px-4 float-end"
                                                    style={{ marginRight: "10px" }}
                                                    onClick={async () => await AssignAssetModelRef.current.openModal(QRData.EmployeeId, QRData.UserId)}
                                                >
                                                    Via QR Code
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <div className="asset-assign-table">
                                <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDeleteAssetAssigned(v)} />
                                <TableComponent
                                    columns={AssignedColumns}
                                    data={AssetAssignedRecords}
                                    noRecordCss="p-0"
                                    noRecordFound={
                                        <NoRecordTemplate
                                            headerValue={StandardConst.AssetAssignheaderValueNoResults}
                                            subHeaderValue={StandardConst.AssetAssignSubHeaderRole}
                                            imageUrl={StandardConst.imageNoRecordsFound}
                                        />
                                    }
                                    isSearchRequired={false}
                                    allowSerialNo={true}
                                    isExcelExportButtonVisible={false}
                                />
                            </div>
                    </div>
                </div>
                <AssignAssetComponentModal 
                    ref={AssignAssetModelRef} 
                    callBackEvent={async () => await fetchAllAssignedAssets()} 
                />
                <EditAssetAssignment
                    callBackEvent={async () => await fetchAllAssignedAssets()}
                    ref={EditAssetAssignmentModalRef}
                >
                </EditAssetAssignment>
            </Container>
        </>
    )
}

export default AddAssetInformation;
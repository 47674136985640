import React, { useEffect, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditLeave from "./AddEditleave";
import ApproveRejectLeave from "./ApproveDecline";
import Container from "react-bootstrap/Container";
import "../Static/Static.css";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import { Box } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { chain, extend, map } from "underscore";
import { useSearchParams } from "react-router-dom";
import { generateQueryStringByObject } from "../../Services/UtilityService";

const LeaveApproval = () => {
  const [searchParams] = useSearchParams();
  const ref = useRef();
  const refSnackbar = useRef();
  const [records, setRecords] = useState([]);
  const [ApprovalStatus, setleaveApprovalStatus] = useState([]);
  const [Employeeleave, setEmployeeleave] = useState([]);
  const dispatch = useDispatch();
  PageInfo({ pageTitle: "Approve Leaves" });
  const childUser = useSelector((s) => s.auth.ChildUsers ?? []);
  // const [permission] = useState({
  //   ManageAdd: false,// ActionPermission("Leave - Add"),
  //   ManageEdit: false,// ActionPermission("Leave - Edit"),
  //   ManageAllUser: ActionPermission("Leave - Search All User"),
  //   ManageAprroveReject: ActionPermission("Leave - Approve Reject"),
  // });

  

  const [permission, SetPermission] = useState({
      // ManageAdd: false,// ActionPermission("Leave - Add"),
      // ManageEdit: false,// ActionPermission("Leave - Edit"),
      // ManageAllUser: ActionPermission("Leave - Search All User"),
      // ManageAprroveReject: ActionPermission("Leave - Approve Reject"),
  });
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: false,// ActionPermission("Leave - Add"),
      ManageEdit: false,// ActionPermission("Leave - Edit"),
      ManageAllUser: ActionPermission("Leave - Search All User"),
      ManageAprroveReject: ActionPermission("Leave - Approve Reject"),
    });
  }, [permission]);

  const fetchLeaveList = async () => {

    const childUsers = await WebService({ dispatch, endPoint: "User/LoggedUser" }).then((r) => {
      let resp;
      if((r.EmployeeId??0)!==0){
        resp = WebService({ dispatch, endPoint: `User/ChildUsers/${r.EmployeeId}` }).then((cu) => {
          return cu;
        });
      }
      return resp;
    });

    const manageAllUser = ActionPermission("Leave - Search All User")

    const endPoint = `ApplyLeave/Fetch/AllLeave?${generateQueryStringByObject({
      orderBy: "LeaveApplyDate desc"
    })}`;
    const data = await WebService({ endPoint, dispatch })
      .then((r) =>
        map(r, (m) => {
          m.EncodedId = window.btoa(m.LeavesId);
          return m;
        })
      )
      .then((r) => {
        // if (!permission.ManageAllUser) {
        if (!manageAllUser) {
          return r.filter(
              (f) => childUsers.filter((u) => u === f.EmployeeId).length > 0
            );
        } else {
          return r;
        }
      })
      .then((r) =>
        map(r, (m) => {
          if (m.approvalStatus === "Applied") m.approvalStatus = "Pending";
          return m;
        })
      );
      // console.log("data", data);
      // console.log("permission", permission.ManageAllUser);
    setRecords(data);
    // setEmployeeleave(data.Employeeleave);
    // setleaveApprovalStatus(data?.AllLeave?.ApprovalStatusId);
  };
  const onDelete = async () => {
    await fetchLeaveList();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  const approveRejectModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchLeaveList();
    }
    renderAfterCalled.current = true;
  }, []);
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);

  const fnLeaveApproveReject = async (id) =>
    await approveRejectModalRef.current.openModal(id || 0);

  const columns = [
    { Text: "EncodedId", Value: "EncodedId", isVisiable: false },
    {
      Text: "Name",
      Value: "FullName",
    },
    // {
    //   Text: "Apply Date",
    //   DateFormat: "yyyy-MM-dd",
    //   Value: "LeaveApplyDate",
    // },
    {
      Text: "Leave Type",
      Value: "LeaveName",
    },
    {
      Text: "From Date",
      DateFormat: "yyyy-MM-dd",
      Value: "LeaveFromDate",
    },
    {
      Text: "To Date",
      DateFormat: "yyyy-MM-dd",
      Value: "LeaveToDate",
    },
    { Text: "No. of Leave", Value: "NoOfLeaves" },
    { Text: "Approval Note", Value: "ApprovalNote" },
    {
      Text: "Status",
      Value: "approvalStatus",
    },
    // {
    //   Text: "Status",
    //   Value: "Status",
    // },
    {
      Text: "Action",
      isVisiable: permission.ManageAprroveReject,
      cssClass: "text-center td-width-100",
      render: (dr) => (
        <>
          {dr.StatusId === StandardConst.Status.Applied && (
            <ActionButton
              onClick={() => fnLeaveApproveReject(dr.LeavesId)}
              disabled={!permission.ManageAprroveReject}
              IconName="Edit"
              id="btnleaveApplyApproveReject"
            />
          )}
        </>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Leave Management",
      hrefLink: "#",
    },
    {
      title: " Approve Leaves",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Approve Leaves";
  const confirmMessage = MasterPageName + " Deleted successfully";

  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />

      <Container fluid className="base-container">
        <Box
          sx={{
            width: 1,
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h3 className="ms-4 mt-2">{MasterPageName}</h3>
              <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
              </div>
            </div>
          </div>
        </Box>
        <TableComponent
          columns={columns}
          data={chain(records)
            .sortBy((s) => s.LeavesId)
            .reverse()
            .value()}
          title={MasterPageName}
          isSearchRequired={true}
          noRecordCss="p-0"
          noRecordFound={
            <NoRecordTemplate
              headerValue={StandardConst.ApproveLeavesheaderValueNoResults}
              subHeaderValue={StandardConst.ApproveLeavesSubHeaderRole}
              imageUrl={StandardConst.imageNoRecordsFound}
            />
          }
          onAddEvent={() => fnEdit()}
          IsAddButtonVisible={false}
          allowSerialNo={true}
          initialSearchContent={searchParams.get("search") ?? ""}
        />

        <AddEditLeave
          callBackEvent={() => fetchLeaveList()}
          ref={addEditModalRef}
        ></AddEditLeave>

        <ApproveRejectLeave
          callBackEvent={() => fetchLeaveList()}
          ref={approveRejectModalRef}
        ></ApproveRejectLeave>
      </Container>
    </>
  );
};

export default LeaveApproval;

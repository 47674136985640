import React, { useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import { StandardConst } from "../../Services/StandardConst";
import { WSSuccessAlert } from "../../Services/WSAlert";
import "./Leave.css";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditleave = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
  const [permission, SetPermission] = useState({});

  useImperativeHandle(ref, () => ({
    openModal: async (id, IsReadOnly) => {
      IsReadOnly??=false;
      const data = await WebService({
        endPoint: `Applyleave/Fetchleave/${id || 0}`,
        dispatch,
      })
        .then((c) => (c.length > 0 ? c[0] : {}))
        .then((r) => ({ ...r, Comment: r.ApprovalNote }));
      setData(data);
      setReadOnly(IsReadOnly);
      setShow(true);
    },
  }));
  PageInfo({ pageTitle: "Leave" });
  
  useEffect(() => {
    SetPermission({
        ManageCancel: ActionPermission("Leave - Cancel"),
        ManageAprroveReject: ActionPermission("Leave - Approve Reject"),
    });
}, [permissionList]);

  const handleClose = () => setShow(false);
  const schema = yup
    .object()
    .shape({
      // leavestatus: yup.string().trim().required(requiredMessage),
      //Comment: yup.string().trim().required(requiredMessage),
    })
    .required();

  const onSubmit = async (data) => {
    data = {
      LeavesId: data.LeavesId,
      StatusId: data.StatusId,
      ApprovalNote: data.Comment,
    };
    const responsedata = await WebService({
      endPoint: "Applyleave/ChangeLeaveStatus",
      body: data,
      dispatch,
    });
    WSSuccessAlert("Success", responsedata);

    // setShow(true);
    handleClose();
    prop.callBackEvent();
  };

  const formRef = useRef();
  const styleObj = {
    fontSize: 14,
    "font-family": "sans-serif",
    color: "rgb(108 106 106)",
    "padding-top": "6px",
  };
  const inputadding = {
    paddingLeft: "66px",
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={handleClose}
      centered
      className="container-fluid"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {readOnly? "View Leave Details" : "Approve Leave"}
        </Modal.Title>
      </Modal.Header>

      <Form
        ref={formRef}
        defaultValues={data}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row mt-1">
            <FormInputText
              label="Employee Name"
              name="FullName"
              disabled={true}
            />
          </div>

          <div className="row mt-1">
            <FormInputText
              label="Leave Type"
              name="LeaveName"
              disabled={true}
            />
          </div>

          <div class="row mt-1">
            <div class="col-md-4">
              <label style={styleObj}>Leave Date</label>
            </div>
            <div class="col-md-4 paddinginputleft_0 inputadding">
              <FormInputText type="date" name="LeaveFromDate" disabled={true} />
            </div>

            <div class="col-md-4">
              <FormInputText type="date" name="LeaveToDate" disabled={true} />
            </div>
          </div>

          {(data.LeaveFromTime && data.LeaveToTime) && (
            <>
              <div class="row mt-1">
                <div class="col-md-4">
                  <label>Leave Time</label>
                </div>
                <div class="col-md-4 paddinginputleft_0 inputadding">
                  <FormInputText type="time" name="LeaveFromTime" disabled={true} />
                </div>

                <div class="col-md-4">
                  <FormInputText type="time" name="LeaveToTime" disabled={true} />
                </div>
              </div>
            </>
          )}

          <div className="row mt-1">
            <FormInputText
              label="No. of Leave"
              name="NoOfLeaves"
              disabled={true}
            />
          </div>
          <div className="row mt-1">
            <FormInputText
              name="Remarks"
              label="Reason for Leave"
              as="textarea"
              disabled={true}
            />
          </div>
          <div className="row mt-1">
            <FormInputText
              label="Emergency Phone Number"
              name="EmergencyPhone"
              type="text"
              disabled={true}
            />
          </div>
          <div className="row mt-1">
            <FormInputText
              name="Comment"
              label="Remark/Reason for Approve/Reject"
              as="textarea"
              disabled={readOnly}
            />
          </div>
          
        </Modal.Body>
        {!readOnly && (<>
            <Modal.Footer>
              {permission.ManageAprroveReject && (
                <>
                  <Button
                    variant="outline-primary"
                    id="btnleaveApprovaleSubmit"
                    type="submit"
                    onClick={() => {
                      formRef.current.fnReset((formValues) => ({
                        ...formValues,
                        StatusId: StandardConst.Status.Approved,
                      }));
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="outline-primary"
                    id="btnleaveApprovaleSubmit"
                    type="submit"
                    onClick={() => {
                      formRef.current.fnReset((formValues) => ({
                        ...formValues,
                        StatusId: StandardConst.Status.Reject,
                      }));
                    }}
                  >
                    Reject
                  </Button>
                </>
              )}
            </Modal.Footer>
        </>)}
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditleave);

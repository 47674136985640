import { StandardConst } from "./StandardConst";

export const dateFormatFunction = (dateString) => {

    const dateObject = new Date(dateString);
    
    // Extract date components
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const day = dateObject.getDate();
    
    // Format the date as you need
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
}

export const inputValidations = (fieldType, fieldValue) => {
    switch (fieldType) {
        case StandardConst.invoiceFieldType.find(item => item.text === "String").value:
          return fieldValue.replace(/[^A-Za-z0-9. '-]/g, '');
      
        case StandardConst.invoiceFieldType.find(item => item.text === "Number").value:
          return fieldValue.replace(/[^0-9.]/g, '');
      
        case StandardConst.invoiceFieldType.find(item => item.text === "Currency").value:
          return fieldValue.replace(/[^0-9.]/g, '');

        default:
          return fieldValue;
      }
};

export const formatCurrency = (currencyCode, localeCode, value) => {
  return new Intl.NumberFormat(localeCode, {
         style: 'currency',
         currency: currencyCode,
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
       }).format(Number(value));
};
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0D6EFD",
      darker: "#053e85",
    },
    success: {
      main: "#08b97b",
      darker: "#053e85",
    },
    warning: {
      main: "#f4c414",
      darker: "#053e85",
    },
    danger: {
      main: "#ff4136",
      contrastText: "#fff",
    },
    publish: {
      main: "#F75800",
      darker: "#053e85",
    },
    view: {
      main: "#01A047",
      darker: "#053e85",
    },
    info: {
      main: "#2196f3",
      darker: "#053e85",
    },
    grey: {
      main: "#CFCFCF",
      contrastText: "#fff",
    },
    resendEmail: {
      main: "#00C49F",
      contrastText: "#fff",
    },
    white: {
      main: "#fff",
      contrastText: "#0D6EFD",
    },
  },
});

export default theme;

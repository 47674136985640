import React, { memo, useEffect, useRef, useState } from "react";
import "./taxInvoiceStyles.css";
import { WebService } from "../../../Services/WebService";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../../Services/BreadcrumbsComponent";
import { StandardConst } from "../../../Services/StandardConst";
import {
  Form,
  FormAutoCompleteDropdown,
  FormInputDropdown,
  FormInputText,
} from "../../Form";
import { Box, Container, Tooltip, styled } from "@mui/material";
import * as yup from "yup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { chain, filter, map, omit, sortBy, where } from "underscore";

import ActionButton from "../../../Services/ActionButton";
import Button from "react-bootstrap/esm/Button";
import { DynamicCompanySection, DynamicCustomerSection, DynamicInvoiceDetailSection } from "./InvoiceSection";
import { DateTime } from "luxon";
import { PageInfo } from "../../PageInfo";
const InvoiceComponent = () => {
  const [invoiceDetailsState, setState] = useState({
    Customer: [],
  });

  const { state } = useLocation();
  const [template, setTemplate] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [currencyDropdown, setCurrencyDropdown] = useState([]);
  const [allTax, setAllTax] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addEditModalRef = useRef();
  const [invoiceSettings, setinvoiceSettings] = useState([]);
  const [cutomerList, setCutomerList] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [invoiceCompanyDetails, setInvoiceCompanyDetails] = useState([]);
  const [invoiceCustomerDetails, setInvoiceCustomerDetails] = useState([]);
  const [invoiceBottomAndDetailSection, setInvoiceBottomAndDetailSection] = useState([]);
  const [detailsInitialJsonData, setDetailsInitialJsonData] = useState([]);
  PageInfo({ pageTitle: "Edit Invoice" });
  
  const childRef = useRef(null);

  const fetchAllInvoiceSettings = async (settingsId) => {
    await WebService({
      dispatch,
      endPoint: `Invoice/Setting/Header/FetchById/${settingsId}`,
    }).then((r) => {
      setState(r);
    });
  };
  const fetchInvoice = async () => {
    setInvoiceDetails();
    await WebService({
      dispatch,
      endPoint: `Invoice/Fetch/${state?.InvoiceHeaderId ?? 0}`,
    }).then((r) => {

      setInvoiceCompanyDetails(filter(r?.Details, { Section: StandardConst.InvoiceSectionNames.Company })?.sort((a, b) => a.OrderNo - b.OrderNo));
      setInvoiceCustomerDetails(filter(r?.Details, { Section: StandardConst.InvoiceSectionNames.Customer })?.sort((a, b) => a.OrderNo - b.OrderNo));

      setDetailsInitialJsonData(filter(r?.Details, { Section: StandardConst.InvoiceSectionNames.Details })
      ?.sort((a, b) => a.OrderNo - b.OrderNo)
      ?.map((row) => row.Details.reduce((acc, detailsData) => {
        acc[detailsData.FieldName] = detailsData.Value;
        return acc;
      }, {InvoiceDetailId: row.InvoiceDetailId})) || [])

      GetAllConditionAndFormulaData(r.InvoiceSettingId);

      setInvoiceDetails(r);
    });
  };
  const [bottomData, setBottomData] = useState({});

  const updateBottomSectionData = (newBottomSectionJsonData) => {
    setBottomData(newBottomSectionJsonData);
  };

  const [emptyFields, setEmptyFields] = useState([]);

  const updateInvoice = async () => {
    const modifiedInvoiceCustomerDetails = invoiceCustomerDetails.map(({ Details, InvoiceDetailId, InvoiceHeaderId, ...rest }) => rest);
    const modifiedInvoiceCompanyDetails = invoiceCompanyDetails.map(({ Details, InvoiceDetailId, InvoiceHeaderId, ...rest }) => rest);

    var FormSubmitOrNot = true;
    var EmptyFieldsArr = [{DetailSectonValidation : []}];

    modifiedInvoiceCompanyDetails?.map((item) => {
      if(item.Value === "" && item.FieldIsRequired === 1){
        EmptyFieldsArr.push(item.FieldName);
        FormSubmitOrNot = false;
      }
    });

    modifiedInvoiceCustomerDetails?.map((item) => {
      if(item.Value === "" && item.FieldIsRequired === 1){
        EmptyFieldsArr.push(item.FieldName);
        FormSubmitOrNot = false;
      }
    });
    
    bottomData?.map((item) => {
      if(item.Value === "" && item.FieldIsRequired === 1){
        EmptyFieldsArr.push(item.FieldName);
        FormSubmitOrNot = false;
      }
    });

    invoiceBottomAndDetailSection?.Details?.map((item, index) => {
      const details = [];
      item.Details?.map((ObjDetails) => {
        if(ObjDetails.Value === "" && ObjDetails.FieldIsRequired === 1){
          details.push(ObjDetails.FieldName);
          FormSubmitOrNot = false;
        }
      });
      EmptyFieldsArr[0].DetailSectonValidation[index] = details;
    });

    setEmptyFields(EmptyFieldsArr);


    const mergedJsonData = { 
      Details: modifiedInvoiceCustomerDetails.concat(modifiedInvoiceCompanyDetails).concat(invoiceBottomAndDetailSection.Details).concat(bottomData),
    }
    const updatedJsonData = omit(invoiceDetails, "Details");
    updatedJsonData.Details = mergedJsonData.Details;
    updatedJsonData.InvoiceStatus = StandardConst.Status.InvoiceUnpaid;
    updatedJsonData.TotalAmount = parseInt(bottomData.find(item => item.FieldName === StandardConst.BottomSectionFixedFieldName.TotalAmount)?.Value)?.toFixed(2);
    updatedJsonData.TaxAmount = parseInt(bottomData.find(item => item.FieldName === StandardConst.BottomSectionFixedFieldName.TaxAmount)?.Value)?.toFixed(2);
    updatedJsonData.NetAmount = parseInt(bottomData.find(item => item.FieldName === StandardConst.BottomSectionFixedFieldName.NetAmount)?.Value)?.toFixed(2);

    if(FormSubmitOrNot === true && invoiceDetails.InvoiceNo !== ""){
      await WebService({
        dispatch,
        method: "POST",
        endPoint: `Invoice/UpdateInvoice/${updatedJsonData.InvoiceHeaderId}`,
        body: updatedJsonData,
      }).then((InvoiceHeaderId) => {
        navigate(`/CustomerInvoice`);
      });
    }

  };

  const handleUpdateDetailsData = (updatedData) => {
    setInvoiceBottomAndDetailSection(updatedData);
  };

  const handleUpdateCompanyData = (updatedData) => {
    setInvoiceCompanyDetails(updatedData);
  };

  const handleUpdateCustomerData = (updatedData) => {
    setInvoiceCustomerDetails(updatedData);
  };

  const [conditionDataState, setConditionDataState] = useState([]);
  const [formulaDataState, setFormulaDataState] = useState([]);

  const GetAllConditionAndFormulaData = async (InvoiceSettingId) => {
    const formulaPreConditionData = await WebService({
            dispatch,
            method: "GET",
            endPoint: `Invoice/GetFormulaPreConditions/${InvoiceSettingId}`,
    });
    setConditionDataState(formulaPreConditionData);

    const formulaData = await WebService({
          dispatch,
          method: "GET",
          endPoint: `Invoice/GetFormulaData/${InvoiceSettingId}`,
    });
    setFormulaDataState(formulaData);
  }

  const fetchCustomer = async () => {
    await WebService({
      endPoint: `Customer/Fetch`,
      dispatch,
    }).then((r) => {
      setCutomerList(
        map(r, (v) => ({
          CustomerId: v.CustomerId || "" || null || undefined,
          Name: v.PersonName,
        }))
      );
    });
  };
  const fetchAllTemplate = async () => {
    await WebService({ dispatch, endPoint: `Invoice/Template/FetchAll` }).then(
      (r) => {
        setTemplate(
          map(r, (v) => ({
            value: v.TemplateId || "" || null || undefined,
            text: v.TemplateName,
          }))
        );
      }
    );
  };
  const fetchAllCurrency = async () => {
    await WebService({
      endPoint: `Currency/FetchCurrency`,
      dispatch,
    }).then((currencyList) => {
      setCurrencyData(currencyList);

      setCurrencyDropdown(map(currencyList, (v) => ({
        value: v.CurrencyId || "" || null || undefined,
        text: `${v.Symbol} - ${v.Description}`,
        description: `${v.Description}`,
        symbol: `${v.Symbol} `,
      })))

    });
  };

  const fetchAllTax = async () => {
    await WebService({
      endPoint: `Invoice/TaxMaster/FetchAll`,
      dispatch,
    }).then((r) => {
      setAllTax(
        map(r, (v) => ({
          value: v.TaxId || "" || null || undefined,
          text: `${v.TaxName} - ${v.TaxPercentage}`,
          taxName: `${v.TaxName}`,
          taxPercentage: v.TaxPercentage,
        }))
      );
    });
  };
  const onSubmit = async (data) => {
    await WebService({
      dispatch,
      endPoint: "#",
      body: {},
    });
  };
  const schema = yup.object().shape({
    Message: yup
      .string()
      .trim()
      .label("Message")
      .required(StandardConst.requiredMessage),
    Expire: yup
      .date()
      .label("Expire")
      .typeError(StandardConst.requiredMessage)
      .required(StandardConst.requiredMessage),

    Customer: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string(),
          label: yup.string(),
        })
      )
      .min(1, "Customer Required"),
  });
  var Today =
    new Date().getDate() +
    "/" +
    new Date().getMonth() +
    "/" +
    new Date().getFullYear();
  useEffect(() => {
    Promise.all([
      // fetchAllInvoiceSettings(),
      fetchCustomer(),
      fetchAllTemplate(),
      fetchAllCurrency(),
      fetchAllTax(),
    ]).then(() => fetchInvoice());
  }, []);

  const MasterPageName = "Edit Invoice";
  const [bData, setBData] = React.useState([
    {
      title: "Invoice Management",
      hrefLink: "#",
    },
    {
      title: "Customer Invoice",
      hrefLink: "#",
    },
  ]);

  //Creating dynamic inputs
  const [inputFields, setInputFields] = useState([{ value: "" }]);

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));
  const fetchInvoiceSection = () => {};

  return (
    <Container fluid className="base-container">
      <Box
        sx={{
          width: 1,
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
            <div className="ms-4">
              <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
            </div>
          </div>
        </div>
      </Box>
      <div className="p-3 primary-bg-color"></div>
      <div className="mx-4 mt-2">
        <Form
          defaultValues={invoiceDetails}
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {/* <div className="row">
            <div className="col-4">
              <FormAutoCompleteDropdown
                name="Customer"
                data={cutomerList}
                label="Select Customer"
                optionText="Name"
              />
            </div>
            <div className="col-4">
              <FormInputText
                name="SettingName"
                isRequired="true"
                label="Invoice Name"
                type="text"
              />
            </div>
            <div className="col-4">
              <FormInputDropdown
                name="TemplateId"
                ddOpt={template ?? []}
                label="Select Template"
                isRequired="true"
                setValue={(v) => {
                  fetchAllInvoiceSettings(v);
                }}
              ></FormInputDropdown>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <FormInputText
                name="InvoicePrefix"
                isRequired="true"
                label="Prefix"
                type="text"
              />
            </div>
            <div className="col-4">
              <FormInputDropdown
                name="InvoiceNumberFormat"
                ddOpt={StandardConst.InvoiceNumberFormat}
                label="Number Format"
                isRequired="true"
              ></FormInputDropdown>
            </div>
            <div className="col-4">
              <FormInputText
                name="DueDays"
                isRequired="true"
                label="due (days)"
                type="text"
              />
            </div>
          </div> */}
          {/* <div className="row mt-1" is="abc">
            <div className="col-4">
              <FormInputDropdown
                name="IsShowPayment"
                ddOpt={StandardConst.YesOrNo}
                label="Show Payments"
                isRequired="true"
              ></FormInputDropdown>
            </div>
            <div className="col-4">
              <FormInputDropdown
                name="CurrencyId"
                ddOpt={currencyData ?? []}
                label="Default Currency"
                isRequired="true"
              ></FormInputDropdown>
            </div>
            <div className="col-4">
              <FormInputDropdown
                name="DiscountType"
                ddOpt={StandardConst.DiscountType}
                label="Discount Type"
                isRequired="true"
              ></FormInputDropdown>
            </div>
            <div className="col-4">
              <FormInputDropdown
                name="TaxId"
                ddOpt={allTax}
                label="Default Tax"
                isRequired="true"
              ></FormInputDropdown>
            </div>
          </div> */}

          {/* <div className="my-4">
            <Root>
              <Divider>
                <span>&nbsp;&nbsp;Invoice &nbsp;&nbsp;</span>
              </Divider>
            </Root>
          </div> */}

          <div className="tax-invoice">

            <div className="row">
                <div className="col-4 position-relative">
                  <FormInputText
                    label="Invoice Number"
                    name="InvoiceNo"
                    type="text"
                    isRequired="true"
                    setValue={(val) => setInvoiceDetails((previousData) => {
                      return { 
                        ...previousData, InvoiceNo: val
                      }
                    })}
                    value={invoiceDetails?.InvoiceNo}
                  />

                  {/* Here we show Validation msg which is form of tooltip */}
                  <div className={`position-absolute top-50 end-0 translate-middle ${ (invoiceDetails?.InvoiceNo === "") ? "" : "d-none"}`}>
                      <Tooltip
                        arrow
                        disableFocusListener
                        title={StandardConst.ValidationMessages.RequiredFieldMsg}
                      >
                        <i className="fa fa-exclamation-circle me-2" style={{color: "red"}} ></i>
                      </Tooltip>
                  </div>
                </div>
            </div>
            <div className="row">
              <div className="col-4">
                <FormInputText
                  label="Invoice Date"
                  name="InvoiceDate"
                  type="date"
                  min={DateTime.now().toJSDate()}
                  isRequired="true"
                  setValue={(val) => setInvoiceDetails((previousData) => {
                    return { 
                      ...previousData, InvoiceDate: val
                    }
                  })}
                  value={invoiceDetails?.InvoiceDate}
                />
              </div>
              <div className="col-4">
                <FormInputText
                  label="Due Date"
                  name="InvoiceDueDate"
                  type="date"
                  min={DateTime.now().toJSDate()}
                  isRequired="true"
                  setValue={(val) => setInvoiceDetails((previousData) => {
                    return { 
                      ...previousData, InvoiceDueDate: val
                    }
                  })}
                  value={invoiceDetails?.InvoiceDueDate}
                />
              </div>
              <div className="col-4">
                <FormInputDropdown
                  name="CurrencyId"
                  ddOpt={currencyDropdown ?? []}
                  label="Currency"
                  isRequired="true"
                  setValue={(val) => setInvoiceDetails((previousData) => {
                    return { 
                      ...previousData, CurrencyId: parseInt(val) 
                    }
                  })}
                  value={invoiceDetails?.CurrencyId}
                ></FormInputDropdown>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <FormInputDropdown
                  name="DiscountType"
                  ddOpt={StandardConst.DiscountType}
                  label="Discount Type"
                  isRequired="true"
                  setValue={(val) => setInvoiceDetails((previousData) => {
                    return { 
                      ...previousData, DiscountType: parseInt(val)
                    }
                  })}
                  value={invoiceDetails?.DiscountType}
                ></FormInputDropdown>
              </div>
              <div className="col-4">
               
                <FormInputDropdown
                  name="IsShowPayment"
                  ddOpt={StandardConst.YesOrNo}
                  label="Show Payment"
                  setValue={(val) => setInvoiceDetails((previousData) => {
                    return { 
                      ...previousData, IsShowPayment: parseInt(val) 
                    }
                  })}
                  value={invoiceDetails?.IsShowPayment}
                >
                </FormInputDropdown>
                {/* :&nbsp; {invoiceDetails?.IsShowPayment === StandardConst.YesNo.Yes ? "Yes" : "No"} */}
              </div>
              <div className="col-4 d-none">
                <FormInputDropdown
                  name="TaxId"
                  ddOpt={allTax}
                  label="Tax"
                  isRequired="true"
                  setValue={(val) => setInvoiceDetails((previousData) => {
                    return { 
                      ...previousData, TaxId: parseInt(val)
                    }
                  })}
                  value={invoiceDetails?.TaxId}
                ></FormInputDropdown>
              </div>
            </div>

            <div className="row">
              <div className="col my-4">
                <span className="h5 mb-4"> Company Details</span>
                <div className="mt-4">
                  <DynamicCompanySection
                    data={invoiceCompanyDetails}
                    companyDetails={invoiceCompanyDetails}
                    onUpdateData={handleUpdateCompanyData}
                    currencyData={currencyData.find(
                      (item) => item.CurrencyId === invoiceDetailsState?.CurrencyId
                    )}
                    calledInEdit={StandardConst.YesNo.Yes}
                    emptyFields={emptyFields}
                  />
                </div>
              </div>

              {/* Start Customer here */}
              <div className="col my-4">
                <span className="h5 mb-4"> Customer Details</span>
                <div className="mt-4">
                  <DynamicCustomerSection
                    data={invoiceCustomerDetails}
                    customerDetails={invoiceCustomerDetails}
                    onUpdateData={handleUpdateCustomerData}
                    currencyData={currencyData.find(
                      (item) => item.CurrencyId === invoiceDetailsState?.CurrencyId
                    )}
                    calledInEdit={StandardConst.YesNo.Yes}
                    emptyFields={emptyFields}
                  />
                </div>
              </div>
            </div>

            <div className="row">

              {invoiceDetails ? (
                <>
                <DynamicInvoiceDetailSection

                  data={(filter(invoiceDetails?.Details, { Section: StandardConst.InvoiceSectionNames.Details })?.[0]?.Details || [])?.sort((a, b) => a.OrderNo - b.OrderNo).map(column => ({ FieldName: column.FieldName, FieldLabel: column.Name, FieldType: column.FieldType, FieldIsRequired: column.FieldIsRequired}))}
                  
                  updateJsonData={handleUpdateDetailsData}
                  currencyData={currencyData.find(
                    (item) => item.CurrencyId === invoiceDetails?.CurrencyId
                  )}
                  initialJsonData={detailsInitialJsonData}
                  
                  conditionData={conditionDataState}
                  formulaData={formulaDataState}
                  DataBottomSection={filter(invoiceDetails?.Details, {
                      Section: StandardConst.InvoiceSectionNames.Bottom,
                    })?.sort((a, b) => a.OrderNo - b.OrderNo)}
                  DetailsSectionRowInEdit={StandardConst.YesNo.Yes}
                  updateBottomSectionData={updateBottomSectionData}
                  emptyFields={emptyFields}
                  invoiceSettingId = {invoiceDetails.InvoiceSettingId}
                />
                </> 
              ) : (
                "loading"
              )}
            </div>
          </div>
        </Form>
      </div>
      <div className="row mb-4">
          <div className="col-10 d-flex float-end"></div>
          <div className="col-2 d-flex float-end">
            <Button
              variant="outline-primary"
              // type="submit"
              className="d-flex float-end"
              onClick={() => updateInvoice()}
            >
              <i className="fa fa-plus-circle me-2 mt-1"></i> Save Invoice
            </Button>
          </div>
        </div>
    </Container>
  );
};

export default memo(InvoiceComponent);

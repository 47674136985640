import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { StandardConst } from "./StandardConst";

export default function WSLinearProgressWithCap({
  Billable,
  NonBillable,
  Capacity,
}) {
  Billable ??= 0;
  NonBillable ??= 0;
  Capacity ??= false;
  const MIN = 0;
  const MAX = 20;
  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);
  const BorderLinearProgressNonBillable = styled(LinearProgress)(
    ({ theme }) => ({
      height: 21,
      borderRadius: 0,

      [`& .${linearProgressClasses.bar1Buffer}`]: {
        borderRadius: 0,
        backgroundColor:
          Capacity === true
            ? StandardConst.wsNonBillableAlertColor
            : StandardConst.wsNonBillableColor,
      },
    })
  );

  const BorderLinearProgressBillable = styled(LinearProgress)(({ theme }) => ({
    height: 21,
    borderRadius: 0,

    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor:
        Capacity == true
          ? StandardConst.wsBillableAlertColor
          : StandardConst.wsBillableColor,
    },
  }));

  const BorderLinearProgressAlert = styled(LinearProgress)(({ theme }) => ({
    height: 21,
    borderRadius: 0,

    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: StandardConst.wsGreyColor,
    },
  }));

  return (
    <>
      <BorderLinearProgressAlert
        className="my-1 "
        variant="determinate"
        value="100"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "rgba(0, 0, 0, 0.0)",
            borderWidth: "1px",
            borderColor: "#CFCFCF",
          },
        }}
      />
      <BorderLinearProgressNonBillable
        className="my-1 overlap"
        variant="determinate"
        value={Billable + NonBillable}
        sx={{
          // top: "24px",
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          "& .MuiLinearProgress-bar": {
            backgroundColor:
              Capacity === true
                ? StandardConst.wsNonBillableAlertColor
                : StandardConst.wsNonBillableColor,
          },
          position: "relative",
          top: "-25px"
        }}
      />

      <BorderLinearProgressBillable
        className="my-1 overlap-next"
        variant="determinate"
        value={Billable}
        // value={normalise(Billable)}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          "& .MuiLinearProgress-bar": {
            backgroundColor:
              Capacity == true
                ? StandardConst.wsBillableAlertColor
                : StandardConst.wsBillableColor,
          },
          position: "relative",
          top: "-50px"
        }}
      />
    </>
  );
}

import React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PublishIcon from "@mui/icons-material/Publish";
import { ThemeProvider } from "@mui/material/styles";
import wsTheme from "./WsMuiTheme";
import EmailResendIcon from "@mui/icons-material/ForwardToInbox";
import AssignIcon from "@mui/icons-material/Addchart";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import QrCodeIcon from "@mui/icons-material/QrCode";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import SaveIcon from "@mui/icons-material/Save";
import BadgeIcon from '@mui/icons-material/Badge';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import InfoIcon from '@mui/icons-material/Info';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import AddCardIcon from '@mui/icons-material/AddCard';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const ActionButton = ({ IconName, IconTooltip, IconColor, iconSize, ...rest }) => {
  IconName ??= "Edit";
  IconTooltip ??= "";
  IconColor ??= "";
  iconSize = iconSize !== "" ? iconSize : "small"
  const iconComponent = (IconName) => {
    switch (IconName) {
      case "Save":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <SaveIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Add":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <AddIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );

      case "Remove":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <RemoveIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Close":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <CloseIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Update":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <FileDownloadDoneIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Print":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <LocalPrintshopIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "QR":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <QrCodeIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
        case "Coordinator":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <AccessibilityIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
        case "Attribute":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <AppRegistrationIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
        case "EventVisitors":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <PeopleIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "CheckOut":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "info"}>
                <LogoutIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "CheckIn":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "info"}>
                <LoginIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Download":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <DownloadIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Publish":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "publish"}>
                <PublishIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "View":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "view"}>
                <VisibilityIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Edit":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <EditIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Delete":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "danger"}>
                <DeleteIcon fontSize="10" />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "EmailResend":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "resendEmail"}>
                <EmailResendIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Assign":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <AssignIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
      case "Badge":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <BadgeIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );

        case "Permission":
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                <AdminPanelSettingsIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
        case "Contact":
          return(
            <>
              <Tooltip title={IconTooltip}>
                <span>
                <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                  <ContactPhoneIcon fontSize={iconSize} />
                </IconButton>
                </span>
              </Tooltip>
            </>
          );

        case "SwitchCamera":
          return(
            <>
              <Tooltip title={IconTooltip}>
                <span>
                <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                  <CameraswitchIcon fontSize={iconSize} />
                </IconButton>
                </span>
              </Tooltip>
            </>
          );

        case "SwitchCameraLarge":
          return(
            <>
              <Tooltip title={IconTooltip}>
                <span>
                <IconButton size="large" {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                  <CameraswitchIcon fontSize="large" />
                </IconButton>
                </span>
              </Tooltip>
            </>
          );

          case "FullScreenLarge":
          return(
            <>
              <Tooltip title={IconTooltip}>
                <span>
                <IconButton size="large" {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                  <FullscreenIcon fontSize="large" />
                </IconButton>
                </span>
              </Tooltip>
            </>
          );

          case "FullScreenExitLarge":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size="large" {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <FullscreenExitIcon fontSize="large" />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "Information":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <InfoIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "EventRegister":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <HowToRegIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "UpArrow":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <ArrowUpwardIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );
          
          case "DownArrow":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <ArrowDownwardIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "FixInterview":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <InterpreterModeIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "Mail":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <MailIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "Phone":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <PhoneIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "Feedback":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <ThumbUpAltIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "Generate":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <AddCardIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "Skip":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <PlaylistRemoveIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

          case "Search":
            return(
              <>
                <Tooltip title={IconTooltip}>
                  <span>
                  <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "primary"}>
                    <SearchIcon fontSize={iconSize} />
                  </IconButton>
                  </span>
                </Tooltip>
              </>
            );

      default:
        return (
          <>
            <Tooltip title={IconTooltip}>
              <span>
              <IconButton size={iconSize} {...rest} color={IconColor !== "" ? IconColor : "danger"}>
                <EditIcon fontSize={iconSize} />
              </IconButton>
              </span>
            </Tooltip>
          </>
        );
    }
  };
  return (
    <>
      <ThemeProvider theme={wsTheme}>{iconComponent(IconName)} </ThemeProvider>
    </>
  );
};

export default ActionButton;

import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { StandardConst } from "./StandardConst";
export default function WSLinearProgress({ Billable, Capacity }) {
  Billable ??= 0;
  Capacity ??= false;
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 0,

    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        Capacity === true
          ? StandardConst.wsNonBillableAlertColor
          : StandardConst.wsNonBillableColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor:
        Capacity == true
          ? StandardConst.wsBillableAlertColor
          : StandardConst.wsBillableColor,
    },
  }));

  return (
    <>
      <div>
        <BorderLinearProgress variant="determinate" value={Billable} pro />
      </div>
    </>
  );
}

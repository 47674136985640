import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { StandardConst } from "./StandardConst";
import { Tooltip } from "@mui/material";

export default function WSLinearProgressTriColor({
  FirstValue,
  SecondValue,
  Capacity,
  FirstValueColor,
  SecondValueColor,
}) {
  FirstValue ??= 0;
  SecondValue ??= 0;
  Capacity ??= false;
  FirstValueColor ??= StandardConst.Colors.primary;
  SecondValueColor ??= StandardConst.Colors.info;

  const BorderLinearProgressNonBillable = styled(LinearProgress)(
    ({ theme }) => ({
      height: 21,
      borderRadius: 0,

      [`& .${linearProgressClasses.bar1Buffer}`]: {
        borderRadius: 0,
        backgroundColor:
          Capacity === true
            ? StandardConst.wsNonBillableAlertColor
            : StandardConst.wsNonBillableColor,
      },
    })
  );

  const BorderLinearProgressBillable = styled(LinearProgress)(({ theme }) => ({
    height: 21,
    borderRadius: 0,

    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor:
        Capacity == true
          ? StandardConst.wsBillableAlertColor
          : StandardConst.wsBillableColor,
    },
  }));

  const BorderLinearProgressAlert = styled(LinearProgress)(({ theme }) => ({
    height: 21,
    borderRadius: 0,

    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: StandardConst.wsGreyColor,
    },
  }));

  return (
    <>
      <BorderLinearProgressAlert
        className="my-1 "
        variant="determinate"
        value="100"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          "& .MuiLinearProgress-bar": {
            backgroundColor: StandardConst.Colors.grey,
            borderWidth: "1px",
            borderColor: "#CFCFCF",
          },
        }}
      />
      <BorderLinearProgressNonBillable
        className="my-1 overlap"
        variant="determinate"
        value={FirstValue + SecondValue}
        sx={{
          // top: "24px",
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          "& .MuiLinearProgress-bar": {
            backgroundColor:
              Capacity === true
                ? StandardConst.wsNonBillableAlertColor
                : SecondValueColor,

            borderRight: 3,
            borderColor: "#FFFFFF",
          },
        }}
      />

      <BorderLinearProgressBillable
        className="my-1 overlap-next"
        variant="determinate"
        value={FirstValue}
        // value={normalise(FirstValue)}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          "& .MuiLinearProgress-bar": {
            backgroundColor:
              Capacity == true
                ? StandardConst.wsBillableAlertColor
                : FirstValueColor,
            borderRight: 3,
            borderColor: "#FFFFFF",
          },
        }}
      />
    </>
  );
}

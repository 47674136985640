import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { StandardConst } from "../../Services/StandardConst";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditMenuMaster = (prop, ref) => {

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ MenuMaster: {} });

  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      // this is old method this is not fast method
      // const data = await WebService({
      //   endPoint: `MenuMaster/Fetch/${id || 0}`,
      //   dispatch,
      // });
      // data.parent = [{ value: null, text: "" }].concat(
      //   _.map(data.parent, (m) => {
      //     return {
      //       value: m.MenuId,
      //       text: m.MenuText,
      //     };
      //   })
      // );
      // setData(data);
      // setShow(true);

      // this is the fast loading method and standard methods of all pages
      setShow(true);
      fetchAllParentMenus(id);
      if (id === 0) {
        const data = {
          MenuMaster: {},
        };
        setData(data);
      } else {
        setShow(false);
        await fetchEditValues(id);
        setShow(true);
      }
    },
  }));

  const fetchEditValues = async (id) => {
    const data = {
      MenuMaster: await WebService({
        endPoint: `CommonUtility/Edit/menus?MenuId=${id}`,
        dispatch,
      }).then(r => r.length > 0 ? r[0] : {})
    }
    setData(data);
  }

  const [ParentMenuList, setParentMenuList] = useState([]);

  const fetchAllParentMenus = async (id) => {
    const data = await WebService({
      endPoint: `CommonUtility/Edit/menus`,
      dispatch,
    });

    const modifiedData = data?.filter((item) => item.ParentId === null);
    let list = [{ text: "", value: null }].concat(modifiedData?.map((res) => {
      return { text: res.MenuText, value: res?.MenuId }
    }));

    if (id) {
      list = list.filter((obj) => obj.value !== id);
    }
    setParentMenuList(list);
  }

  const handleClose = () => {
    setShow(false);
    setData({ MenuMaster : {} });
    setParentMenuList([]);
  }

  const schema = yup
    .object()
    .shape({
      MenuText: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    })
    .required();

  const onSubmit = async (data) => {
    if (data.MenuId === undefined) {
      await WebService({
        endPoint: "MenuMaster/Create",
        body: data,
        dispatch,
      });
    } else {
      await WebService({
        endPoint: `MenuMaster/Update/${data.MenuId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }
    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data?.MenuMaster?.MenuId || 0) === 0 ? "Add Menu" : "Edit Menu"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data?.MenuMaster}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Menu Text"
                name="MenuText"
                type="text"
                isRequired="true"
              />
            </div>
            <div className="col-md-12">
              <FormInputText label="Route" name="Route" type="text" />
            </div>
            <div className="col-md-12">
              <FormInputText label="Icon" name="Icon" type="text" />
            </div>
            <div className="col-md-12">
              <FormInputDropdown
                name="ParentId"
                ddOpt={ParentMenuList}
                label="Parent"
              ></FormInputDropdown>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>          
          <Button id="btnMenuMasterSubmit" variant="outline-primary" type="submit"> {(data?.MenuMaster?.MenuId || 0) === 0 ? "Submit" : "Save Changes"}</Button>
        </Modal.Footer>        
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditMenuMaster);

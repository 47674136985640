import '../css/App.css'
import '../css/Auth.css'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab, setCameraStatus, setScreenshot } from '../features/auth/authSlice'
import { Alert } from './Alert'
import { Camera } from './Camera'
import { FormTabs } from './FormTabs'
import { Loader } from './Loader'
import { Login } from './Login'
import { useEffect, useState } from 'react'
import { getFacenetError, getFaces, setFacenetMessage, setFacesToBlank, setOutline } from '../features/auth/facenetSlice'
import { WebService } from '../Services/WebService'
import { WSErrorAlert, WSSuccessAlert } from '../Services/WSAlert'
import { StandardConst } from '../Services/StandardConst'
import * as faceapi from 'face-api.js'
import ActionButton from '../Services/ActionButton'
import { NoRecordTemplate } from '../Services/TableComponent'
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/Container";

export const EmployeeAttendanceWithFaceScanning = () => {
    const dispatch = useDispatch()
    const faces = useSelector(getFaces);
    const errorFacenet = useSelector(getFacenetError);

    const [closeCamera, setCloseCamera] = useState(false);
    
    
    const handleCameraOpening = () => {
        dispatch(setCameraStatus('opened'))
        dispatch(setScreenshot(null))
        dispatch(setOutline('#ddd'))
        dispatch(setFacenetMessage('Place the face in the oval.'))
    }

    const loadLibraries = async () => {
        await Promise.all([
            faceapi.nets.ssdMobilenetv1.loadFromUri('facenet/models/ssd_mobilenetv1'),
            faceapi.nets.tinyFaceDetector.loadFromUri('facenet/models/tiny_face_detector'),
            faceapi.nets.faceLandmark68Net.loadFromUri('facenet/models/face_landmark_68'),
            faceapi.nets.faceRecognitionNet.loadFromUri('facenet/models/face_recognition')
        ])
    }

    useEffect(() => {
        loadLibraries();
        dispatch(setFacesToBlank([]));
        handleCameraOpening();
    }, []);

    useEffect(() => {
        if(faces.length !== 0){
            const user = {descriptor: Object.values(faces[0].descriptor)}

            WebService({ 
                endPoint: `Biometrics/EmployeeCheckInCheckOutWithFaceScanning/${StandardConst.UserType.Employee}`,
                method: "POST",
                body: user,
                dispatch
            }).then((result) => {
                // if(Object.keys(result).length > 0){
                if(result === "CheckInSuccess"){
                    WSSuccessAlert("CheckIn Success", "Attendance Successfully logged");
                }

                if(result === "CheckOutSuccess"){
                    WSSuccessAlert("CheckOut Success", "Attendance Successfully logged");

                }
                if(result === "FaceNotMatched"){
                    WSErrorAlert("We can't Recognize you !", "Attendance Not logged. Please contact admin");
                }
                dispatch(setFacesToBlank([]));
                handleCameraOpening();
            });
        }else{
            if(errorFacenet !== null){
                WSErrorAlert("Error", errorFacenet);
                handleCameraOpening();
            }

        }
    }, [faces]);


    return (
        <>
            {!closeCamera ? (
                <>
                    <Loader />
                    <div className="container FaceContainer wrap-login100">
                        <div className="row FaceRecognitionBackgroundColor">
                            <div className="col-md-12 l-side position-relative" style={window.innerWidth <= 600 ? {height : '500px'} : {}}>
                                <div style={{position: "absolute", zIndex: "1111", right: "0px"}}>
                                    <ActionButton
                                        onClick={() => setCloseCamera(true)}
                                        IconColor="danger"
                                        IconName="Close"
                                        IconTooltip={"Close the camera"}
                                    />
                                </div>
                                <Camera FindTheUser={true} />                        
                            </div>
                        </div>
                    </div>
                </>
            )
            :
            (
                <Container fluid className="base-container">
                    <NoRecordTemplate
                        headerValue="Face base attendance system"
                        subHeaderValue="Click open camera to start taking attendance by face."
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <Button variant="outline-primary" onClick={() => setCloseCamera(false)}>Open Camera</Button>
                        }
                    />
                </Container>
            )}
        </>
    )
}
import React, { useEffect, memo, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import TableComponent from "../../Services/TableComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckBox from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import Pending from "@mui/icons-material/Pending";
import { Tab, Tabs, Box, AppBar } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { map } from "underscore";
import ActionButton from "../../Services/ActionButton";
import { StandardConst } from "../../Services/StandardConst";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";


const RegistrationApproval = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  PageInfo({ pageTitle: "Approve Employee" });
  const [records, setRecords] = useState([]);
  const [approvedRecords, setApprovedRecords] = useState([]);
  const [rejectedRecords, setRejectedRecords] = useState([]);

  const refSnackbar = useRef();
  const deleteRef = useRef();

  const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
  const [permission, SetPermission] = useState({});

  useEffect(() => {
    SetPermission({
        NonApprovedEmployeeDelete: ActionPermission("Non-Approved Employees - Delete")
    });
}, [permissionList]);

  const fetchRegistrationApprovalList = async () => {
    const details = await WebService({
      endPoint:
        "CommonUtility/employees?where=StatusId eq 5 and Step eq 1&select=FullName,FatherName,Email,Phone,EmployeeId,StatusId",
      dispatch,
    }).then((r) =>
      map(r, (m) => ({ ...m, EncodedId: window.btoa(m.EmployeeId) }))
    );

    setRecords(details);
  };
  const FetchByStatus = async (id) => {
    const details = await WebService({
      endPoint: `CommonUtility/employees?where=StatusId eq ${id}&select=FullName,FatherName,Email,Phone,EmployeeId,StatusId`,
      dispatch,
    });
    if (id == 3) {
      setApprovedRecords(details);
    } else if (id == 4) {
      setRejectedRecords(details);
    }
  };
  //Tabs start

  const [bData, setBData] = React.useState([
    {
      title: "Employee",
      hrefLink: "#",
    },
    {
      title: "Approve Employee",
      hrefLink: "#",
    },
  ]);
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0, mt: 0 }}>
            <span>{children}</span>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  //Tab End

  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchRegistrationApprovalList();
      FetchByStatus(3);
      FetchByStatus(4);
    }
    renderAfterCalled.current = true;
  }, []);

  const columns = [
    { Text: "EncodedId", Value: "EncodedId", isVisiable: false },
    {
      Text: "Full Name",
      Value: "FullName",
    },
    {
      Text: "Father Name",
      Value: "FatherName",
    },
    {
      Text: "Email",
      Value: "Email",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },
    {
      Text: "Action",
      //isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <div className="d-flex">
          <button
            className="btn btn-default text-primary"
            id="btnRegistrationApprovalView"
            onClick={() => fnEdit(dr.EmployeeId)}
            //disabled={!permission.ManageEdit}
          >
            <i className="fa fa-eye"></i>
          </button>
          {(dr.StatusId !== StandardConst.Status.Approved) && (
            <ActionButton
                onClick={(e) =>
                    deleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        "Do you want to delete this employee " + dr.FullName, // Message of Alert
                        dr.EmployeeId  // Endpoint to hit for delete
                    )}
                disabled={!permission.NonApprovedEmployeeDelete}
                IconName="Delete"
                IconTooltip={"Delete Employee"}
            />
          )}
        </div>
      ),
    },
  ];

  const onDelete = async (EmployeeId) => {
    await WebService({
        endPoint: `CommonUtility/employees?EmployeeId=${EmployeeId}`,
        method: "DELETE",
        dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchRegistrationApprovalList();
    await FetchByStatus(StandardConst.Status.Reject);
  };

  const fnEdit = async (EmpId) => {
    navigate("/Common/Profile", { state: { EmpId } });
  };
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={StandardConst.EmployeeDeleteConfirmationMsg} />
      <DeleteConfirmAlert ref={deleteRef} confirmEvent={(v) => onDelete(v)} />
      <Container
        fluid
        style={{
          padding: "0px",
          // "background-color": "#FFF",
          borderRadius: "5px ",
          margin: "10px",
          backgroundColor: "#fff",
        }}
      >
        <Topbar bData={bData} HeadingText="Employee"></Topbar>
        <Box>
          <AppBar
            position="static"
            color="info"
            elevation={0}
            className="Maintab_Custome mb-2"
            style={{ maxHeight: "40px", height: "40px" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              style={{
                maxHeight: "40px",
                height: "40px",
              }}
              id="tabs"
            >
              <Tab
                icon={<Pending />}
                aria-label="person"
                label="Pending"
                iconPosition="start"
                className="tab_Custome pt-0"
                id="pending"
              />
              <Tab
                icon={<CheckBox />}
                aria-label="phone"
                label="Approved"
                iconPosition="start"
                className="tab_Custome pt-0"
                id="approved"
              />
              <Tab
                icon={<Cancel />}
                aria-label="favorite"
                label="Rejected"
                iconPosition="start"
                className="tab_Custome pt-0"
                id="rejected"
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {" "}
              <TableComponent
                columns={columns}
                noRecordCss="p-0"
                noRecordFound={
                  <NoRecordTemplate
                    headerValue="No employee registrations are PENDING approvals."
                    subHeaderValue="Looks like you don't have any pending results !!!"
                    imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                  />
                }
                data={records}
                IsAddButtonVisible={false}
                isSearchRequired={true}
                initialSearchContent={searchParams.get("search") ?? ""}
                allowSerialNo={true}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              {" "}
              <TableComponent
                columns={columns}
                noRecordCss="p-0"
                noRecordFound={
                  <NoRecordTemplate
                    headerValue="We did not find any employee registrations which are Approved"
                    subHeaderValue="Looks like you don't have any approved results !!!"
                    imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                  />
                }
                data={approvedRecords}
                IsAddButtonVisible={false}
                isSearchRequired={true}
                allowSerialNo={true}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <TableComponent
                columns={columns}
                noRecordCss="p-0"
                noRecordFound={
                  <NoRecordTemplate
                    headerValue="We did not find any employee registrations which are REJECTED"
                    subHeaderValue="Looks like you don't have any rejected results !!!"
                    imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                  />
                }
                data={rejectedRecords}
                IsAddButtonVisible={false}
                isSearchRequired={true}
                allowSerialNo={true}
              />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Container>
    </>
  );
};

export default memo(RegistrationApproval);

import DeleteIcon from "@mui/icons-material/Delete";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Form, FormAutoCompleteDropdownCard, FormInputDropdown, FormInputText, InputCurrency } from "../../Form";
import * as yup from "yup";
import { WebService } from "../../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import _, { chain, each, extend, findWhere, omit, sortBy } from "underscore";
import { DateTime } from "luxon";
import { useAsyncDebounce } from "react-table";
import Button from "react-bootstrap/Button";
import { useImperativeHandle } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IconButton } from "@mui/material";
import { StandardConst } from "../../../Services/StandardConst";
import CompanyInfoWide from "../../../Services/CompanyInfoWide";
import DisplayNumberFormatComponent from "../../../Services/DisplayNumberFormatComponent";
import { OtherComponentAdd } from "../AddEditPayslip";
import { generateQueryStringByObject } from "../../../Services/UtilityService";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TableComponent from "../../../Services/TableComponent";
import { WSErrorAlert, WSSuccessAlert } from "../../../Services/WSAlert";
import { format } from "date-fns";
import Topbar from "../../../Services/TopbarComponent";
import AppbarComponent from "../../../Services/AppbarComponent";
import { useLocation, useNavigate } from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddEditEmpSalaryPackage = forwardRef(({}, ref) => {

  const otherComponentAddRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const MasterPageName = (state) ? "Edit Employee Salary Package " : "Add Employee Salary Package";
  const initialDataValue = {
    FromDate: DateTime.local().toSQLDate(),
    ToDate: null,
    TotalEarning: 0,
    TotalDeduction: 0,
    CTC: null,
    SalaryTemplateId: null,
  };
  const [alertState, setAlertState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = alertState;
  const [data, setData] = useState(initialDataValue);
  const [empList, setElpList] = useState([]);
  const [salaryTemplateList, setSalaryTemplateList] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const formRef = useRef();
  const afterRender = useRef(false);
  const requiredMessage = "This is a required field";

  const [employeePackages, setEmployeePackages] = useState([]);
  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [currencyData, setCurrencyData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const [bData, setBData] = React.useState([
    {
      title: "Payroll Management",
      hrefLink: "#",
    },
    {
      title: "Feed Employee Salary",
      hrefLink: "#",
    },
  ]);

  const schema = yup
  .object()
  .shape({
    EmployeeId: yup
      .array()
      .of(
        yup.object().shape({
          heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
      )
      .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
      .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
    FromDate: yup.date().typeError(requiredMessage).required(requiredMessage),
    ToDate: yup.date().typeError(requiredMessage).notRequired(),
    SalaryTemplateId: yup.string().required(requiredMessage),
    SalaryPaymentFrequency: yup.string().required(requiredMessage),
    CTC: yup.number().typeError(requiredMessage).required(requiredMessage),
  })
  .required();

  const changeCurrency = async (CurrencyId) => {
    if(CurrencyId !== undefined) {
      await WebService({
        endPoint: `Currency/FetchCurrency?CurrencyId=${CurrencyId}`,
        method: 'GET',
      }).then(res => res.length > 0 ? setCurrencyData(res[0]) : null)
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ ...alertState, open: false });
  };


  const findOverlappingPackage = (submissionData, startDate, endDate) => {

    let updatedEmployeePackages = employeePackages;
    if(submissionData?.EmployeePackageId !== undefined && submissionData?.EmployeePackageId > 0){
      updatedEmployeePackages = employeePackages.filter(employee => employee.EmployeePackageId !== submissionData?.EmployeePackageId);
    }

    const newStartDate = new Date(startDate);
    const newEndDate = endDate ? new Date(endDate) : null;

    for (const pkg of updatedEmployeePackages) {
      const pkgStartDate = new Date(pkg.FromDate);
      const pkgEndDate = pkg.ToDate ? new Date(pkg.ToDate) : null;

      // Check for overlap conditions
      if (newEndDate === null && pkgEndDate === null) {
        // Both are open-ended
        return pkg;
      } else if (newEndDate === null && pkgEndDate !== null) {
        // New package is open-ended, existing package has end_date
        if (newStartDate < pkgEndDate) {
          return pkg;
        }
      } else if (newEndDate !== null && pkgEndDate === null) {
        // Existing package is open-ended, new package has end_date

        if (pkgStartDate < newEndDate) {
          return pkg;
        }
      } else {
        // Both have end_dates

        if (!(newEndDate <= pkgStartDate || newStartDate >= pkgEndDate)) {
          return pkg;
        }
      }
    }
    return null; // No overlap found
  };

  const onSubmit = async (dr) => {
    const overlappingPackage = findOverlappingPackage(dr, dr.FromDate, dr.ToDate);
    if (overlappingPackage) {
      WSErrorAlert('Error', `Package overlaps with existing package from ${format(new Date(overlappingPackage.FromDate), 'yyyy-MM-dd')} to ${overlappingPackage.ToDate ? format(new Date(overlappingPackage.ToDate), 'yyyy-MM-dd') : `` || 'ongoing'}.`);
      return;
    }
    dr.TotalEarning = data.TotalEarning;
    dr.TotalDeduction = data.TotalDeduction;
    dr.EmployeeId = dr.EmployeeId[0].EmployeeId;
    dr.Details = _.map(componentList, (m) =>
      _.pick(
        m,
        "EmployeePackageDetailId",
        "EmployeePackageId",
        "SalaryComponentId",
        "Amount"
      )
    );
    var opt = {
      endPoint: "EmployeePackage",
      dispatch,
      body: dr,
    };
    if ((dr?.EmployeePackageId ?? 0) !== 0) {
      opt.endPoint += `/${dr.EmployeePackageId}`;
      opt.method = "PUT";
    }
    await WebService(opt);
    await fnReset();
    // setAlertState({ ...alertState, open: true });
    WSSuccessAlert('Success', 'Successfully feed salary');
    navigate('/SalaryPackage');
  };

  const fnReset = async (obj, onEditDataLoaded) => {
    obj ??= initialDataValue;
    onEditDataLoaded ??= false;
    if ((obj?.FromDate ?? null) != null) {
      obj.FromDate = DateTime.fromISO(obj.FromDate).toFormat("yyyy-MM-dd");
    }
    if ((obj?.ToDate ?? null) != null) {
      obj.ToDate = DateTime.fromISO(obj.ToDate).toFormat("yyyy-MM-dd");
    }
    obj.SalaryTemplateId ??=
      salaryTemplateList.length > 0 ? salaryTemplateList[0].value : 0;
    setData(obj);
    if(onEditDataLoaded){
      setDataLoaded(onEditDataLoaded);
    }
    await formRef.current.fnReset(omit(obj, "Details"));
    await fetchTemplateComponent(obj.SalaryTemplateId, obj?.Details ?? []);
  };

  const fetchSalaryTemplateList = () => {
    WebService({ endPoint: "SalaryTemplate/Fetch", dispatch }).then(
      (result) => {
        const ddOpt = _.map(result, (m) => {
          return {
            value: m.TemplateId,
            text: m.TemplateName,
          };
        });
        setSalaryTemplateList(ddOpt);
      }
    );
  };

  const fetchEmployeeList = () => {
    WebService({
      endPoint: `Visitor/fetchAllEmployees`,
      dispatch
    })
      .then((result) => {
        const empDDOpt = _.map(result, (m) => {
          return {
            EmployeeId: m.EmployeeId,
            heading: m.FullName,
            description: m.Designation,
            avatar: `${StandardConst.apiBaseUrl}/uploads/${m.ProfileImage}`
          };
        });
        setElpList(empDDOpt);
    });
  };

  const fetchTemplateComponent = async (templateId, details) => {
    details ??= data?.Details ?? [];
    var endPoint = `SalaryTemplateComp/ByTemplate/${templateId}`;
    if (details.length > 0) {
      endPoint = `CommonUtility/staticsalarycomponents?${generateQueryStringByObject(
        {
          where: `SalaryComponentsId in (${chain(details ?? [])
            .reduce((m, v) => `${m},${v.SalaryComponentId}`, 0)
            .value()})`,
        }
      )}`;
    }
    let result = await WebService({ endPoint });
    if (details.length > 0) {
      result = chain(result ?? [])
        .map((m) => ({
          TemplateComponentId: null,
          TemplateId: null,
          SalaryComponentId: m.SalaryComponentsId,
          DependentOnComponentId: null,
          IsDependentOnCTC: 0,
          CalculationMethodId: 2,
          NumberOrAmount: "0",
          Code: "Fixed",
          CalculationMethod: "Fixed",
          EarningOrDeductionName: m.EarningOrDeductionName,
          EarningOrDeductionType: m.EarningOrDeductionType,
          PreTaxORPostTax: null,
        }))
        .value();
    }

    if (result.length > 0 && (details ?? []).length > 0)
      each(result, (m) => {
        m.ManualAmount = findWhere(details ?? [], {
          SalaryComponentId: m.SalaryComponentId,
        })?.Amount;
      });
    setTimeout(() => setComponentList(result));
  };


  const init = () => {
    Promise.all([fetchEmployeeList(), fetchSalaryTemplateList()])
  };

  const roundOf = (num, position) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
      Math.pow(10, 2)
    );
  };

  const claculateValue = (dependValue, e) => {
    dependValue ??= 0;
    e.NumberOrAmount ??= 0;
    if ((e?.Code ?? "") === "Percentage") {
      return roundOf((dependValue * e.NumberOrAmount) / 100, 2);
    } else {
      return roundOf(parseFloat(e.NumberOrAmount), 2);
    }
  };

  const onComponentValueCalculation = () => {
    let comList = JSON.parse(JSON.stringify(componentList));
    _.each(comList, (e) => {
      if ((e.ManualAmount ?? "") === "") {
        if (e.IsDependentOnCTC) {
          e.Amount = claculateValue(data?.CTC, e);
        } else if (e.DependentOnComponentId !== null) {
          var dependAmt =
            _.findWhere(comList, {
              TemplateComponentId: e.DependentOnComponentId,
            })?.Amount ?? 0;
          e.Amount = claculateValue(dependAmt, e);
        } else {
          e.Amount = claculateValue(0, e);
        }
      } else {
        e.Amount = parseFloat(e.ManualAmount);
      }
    });

    if (JSON.stringify(comList) !== JSON.stringify(componentList)) {
      setComponentList(comList);
    }
  };

  const updateManualAmt = useAsyncDebounce((m, v) => {
    const newState = componentList.map((obj) => {
      if (obj.TemplateComponentId === m.TemplateComponentId) {
        return { ...obj, ManualAmount: v };
      }
      return obj;
    });
    setComponentList(newState);
  }, 100);

  const editPackage = async () => {
    if (state?.EmployeePackageId != null) {
      var result = await WebService({
        dispatch,
        endPoint: `EmployeePackage/${state?.EmployeePackageId}`,
      });
      if((result?.CurrencyId ?? null) !== null) {
        changeCurrency(result?.CurrencyId);
      }
      await fnReset(result ?? initialDataValue, true);
    } else {
      await fnReset(initialDataValue);
    }
  };

  const newPackageWithSelectedEmployee = async (EmployeeId) => {
    await fnReset(extend(initialDataValue, { EmployeeId }));
  };

  const getEmployeePackages = async (EmployeeId) => {
    await WebService({
      endPoint: `EmployeePackage?EmployeeId=${EmployeeId}`,
      method: 'GET',
    }).then(res => res.length > 0 ? setEmployeePackages(sortBy(sortBy(res || [], "EmpName"), "FromDate").reverse()) : null)
  };

  var employeePackageColumns = [
    {
      Text: "Package Start Date",
      DateFormat: "yyyy-MM-dd",
      Value: "FromDate",
    },
    {
      Text: "Package End Date",
      DateFormat: "yyyy-MM-dd",
      Value: "ToDate",
    },
    {
      Text: "Salary Structure",
      Value: "TemplateName",
    },
    {
      Text: "Payment Frequency",
      Value: "SalaryPaymentFrequency",
    },
    {
      Text: "CTC",
      Value: "CTC",
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <DisplayNumberFormatComponent Number={dr.CTC} Currency={currencyData?.Symbol} />
            </div>
          </>
        )
      }
    },
    {
      Text: "Total Earning / Month",
      Value: "TotalEarning",
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <DisplayNumberFormatComponent Number={dr.TotalEarning} Currency={currencyData?.Symbol} />
            </div>
          </>
        )
      }
    },
    {
      Text: "Total Deduction / Month",
      Value: "TotalDeduction",
      style: {textAlign: "right"},
      render: (dr) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>
              <DisplayNumberFormatComponent Number={dr.TotalDeduction} Currency={currencyData?.Symbol} />
            </div>
          </>
        )
      }
    },
    // {
    //   Text: "Action",
    //   render: (obj) => (
    //     <>
    //       {DateTime.fromISO(obj.FromDate) <= DateTime.now() && (
    //         <>
    //           <div className="d-flex">
    //             <IconButton
    //               size="small"
    //               color="primary"
    //               onClick={() => editItem(obj.EmployeePackageId)}
    //             >
    //               <Edit fontSize="small" />
    //             </IconButton>
    //             <IconButton
    //               onClick={() =>
    //                 refDelete.current.confirmAlert(
    //                   "Delete", //Confirm button text
    //                   "Are You Sure", // Text if Alert
    //                   "Do you want to delete ", // Message of Alert
    //                   obj.EmployeePackageId
    //                 )
    //               }
    //               size="small"
    //               sx={{ color: pink[500] }}
    //               // onClick={() => fnDelete(obj.EmployeePackageId)}
    //             >
    //               <Delete fontSize="small" />
    //             </IconButton>
    //           </div>
    //         </>
    //       )}
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    if (!afterRender.current) {
      init();
    }
    afterRender.current = true;
  }, []);

  useEffect(() => {
    var totalEarning = chain(componentList ?? [])
      .where({ EarningOrDeductionType: "Earning" })
      .reduce((m, v) => m + (v?.Amount ?? 0), 0)
      .value();
    var totalDeduction = chain(componentList ?? [])
      .where({ EarningOrDeductionType: "Deduction" })
      .reduce((m, v) => m + (v?.Amount ?? 0), 0)
      .value();
    setData((data) => ({
      ...data,
      TotalEarning: roundOf(totalEarning ?? 0, 2),
      TotalDeduction: roundOf(totalDeduction ?? 0, 2)
    }));
  }, [componentList]);

  useEffect(() => {
    if (state?.EmployeePackageId){
      editPackage();
    }else{
      setData((data) => ({
        ...data,
        CurrencyId: companyInfo.CurrencyId
      }));
      changeCurrency(companyInfo.CurrencyId);
      setDataLoaded(true);
    }
  }, [state]);

  useEffect(() => {
    if (componentList.length > 0 && (data?.CTC ?? 0) > 0) {
      onComponentValueCalculation();
    }
  }, [componentList, data?.CTC]);

  useEffect(() => {
    if (data?.EmployeeId?.length > 0) {
      getEmployeePackages(data?.EmployeeId[0].EmployeeId);
    }
  }, [data?.EmployeeId]);

  useEffect(() => {
    if ((data?.SalaryTemplateId ?? "") !== "") {
      fetchTemplateComponent(data?.SalaryTemplateId);
    }
  }, [data?.SalaryTemplateId]);

  useImperativeHandle(ref, () => ({
    // editPackage,
    newPackageWithSelectedEmployee,
  }));

  //New Code for Style
  const commonStyles = {
    border: 1,
    borderBottom: 1,
    borderColor: "primary.main",
    p: 2,
  };

  const boxCommonStyles = {
    "& > :not(style)": {
      m: 1,
    },
  };
  //New Code for Style

  if(!dataLoaded) {
    return <></>;
  }

  return (
    <>
      <Container className="base-container" fluid>
        <Topbar bData={bData} HeadingText={MasterPageName}></Topbar>
        <AppbarComponent
            title={MasterPageName}
            isSearchRequired={false}
            isAddButtonRequired={true}
            Exception={true}
        />
        <Form
          defaultValues={data}
          onSubmit={onSubmit}
          validationSchema={schema}
          ref={formRef}
          id="hook-form"
        >
          <div className="card card-cd p-2">
            {" "}
            <CompanyInfoWide />
         
            <Row>
              <Col className="col-md-6 col-12 mb-1">
                <FormAutoCompleteDropdownCard
                  name="EmployeeId"
                  data={empList}
                  label="Select Employee"
                  optionText="heading"
                  isRequired="true"
                  className="form-control"
                  value={data?.EmployeeId ? data?.EmployeeId[0] : ''}
                  setValue={(v) => setData({ ...data, EmployeeId: v })}
                />
              </Col>
              <Col className="col-md-6 col-12">
                <FormInputDropdown
                  name="SalaryTemplateId"
                  ddOpt={salaryTemplateList}
                  label="Select Salary Structure"
                  className="form-control"
                  isRequired="true"
                  setValue={(v) => setData({ ...data, SalaryTemplateId: v })}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-md-6 col-12">
                <FormInputText
                  label="Package Start Date"
                  name="FromDate"
                  type="date"
                  className="form-control"
                  setValue={(dt) => setData({ ...data, FromDate: dt })}
                  isRequired="true"
                  min={DateTime.local().toSQLDate()}
                  portalId="root"
                />
              </Col>
              <Col className="col-md-6 col-12">
                <FormInputText
                  label="Package End Date"
                  name="ToDate"
                  type="date"
                  min={data.FromDate || DateTime.local().toSQLDate()}
                  portalId="root"
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-md-6 col-12">
                <FormInputDropdown
                  label="Currency"
                  name="CurrencyId"
                  ddOpt={StandardConst.Currency}
                  value={data.CurrencyId}
                  className="form-control"
                  isRequired="true"
                  setValue={(val) => {
                    setData({ ...data, CurrencyId: val });
                    changeCurrency(val);
                  }}
                />
              </Col>
              <Col className="col-md-6 col-12">
              <FormInputText
                  locale={currencyData?.LocaleCode}
                  currencyCode={currencyData?.CurrencyCode}
                  label="CTC"
                  name="CTC"
                  type="currency"
                  className="form-control"
                  isRequired="true"
                  setValue={(v) => setData({ ...data, CTC: v })}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-md-6 col-12">
                <FormInputDropdown
                  name="SalaryPaymentFrequency"
                  ddOpt={[
                    { value: "Monthly", text: "Monthly" },
                    { value: "Weekly", text: "Weekly" },
                  ]}
                  label="Payment Frequency"
                  className="form-control"
                  isRequired="true"
                  setValue={(v) =>
                    setData({ ...data, SalaryPaymentFrequency: v })
                  }
                />
              </Col>
            </Row>
          </div>
        </Form>

        <div className="card card-cd container">
          <Row className="bg-info border-bottom rounded-top">
            <Col className="col-6 text-center border-end pt-1">Earnings</Col>
            <Col className="col-4 text-center bg-color:red pt-1">
              Deductions
            </Col>
            <Col className="col-2 text-end">
              <IconButton
                size="small"
                color="light"
                onClick={async () =>
                  await otherComponentAddRef.current.openModal(
                    chain(componentList ?? [])
                      .map((m) => m.SalaryComponentId)
                      .value()
                  )
                }
              >
                <AddCircleIcon />
              </IconButton>
            </Col>
          </Row>

          <Row className="bg-body pt-2">
            <Col>
              {_.map(
                _.where(componentList, { EarningOrDeductionType: "Earning" }),
                (m) => (
                  <>
                    <Row className="square border-end">
                      <Col className="col-md-6 col-12">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={async () => {
                            await setComponentList((s) =>
                              chain(s ?? [])
                                .filter(
                                  (f) =>
                                    f.SalaryComponentId !== m.SalaryComponentId
                                )
                                .value()
                            );
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <span>{m.EarningOrDeductionName} </span>
                      </Col>
                      <Col className="col-md-6 col-12">
                        <InputCurrency
                          locale={currencyData?.LocaleCode}
                          currencyCode={currencyData?.CurrencyCode}
                          className="form-control text-end"
                          value={m.Amount}
                          predefaultValue={true}
                          setValue={(v) => updateManualAmt(m, v)}
                          id={`txt_earning_from_${m.EarningOrDeductionName.replace(
                            " ",
                            "_"
                          )}`}
                        />
                      </Col>
                    </Row>
                  </>
                )
              )}
            </Col>
            <Col>
              {_.map(
                _.where(componentList, {
                  EarningOrDeductionType: "Deduction",
                }),
                (m) => (
                  <>
                    <Row>
                      <Col className="col-md-6 col-12">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={async () => {
                            await setComponentList((s) =>
                              chain(s ?? [])
                                .filter(
                                  (f) =>
                                    f.SalaryComponentId !== m.SalaryComponentId
                                )
                                .value()
                            );
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <span>{m.EarningOrDeductionName} </span>
                      </Col>
                      <Col className="col-md-6 col-12">

                      <InputCurrency
                          locale={currencyData?.LocaleCode}
                          currencyCode={currencyData?.CurrencyCode}
                          className="form-control text-end"
                          value={m.Amount}
                          predefaultValue={true}
                          setValue={(v) => updateManualAmt(m, v)}
                          id={`txt_deduction_by_${m.EarningOrDeductionName.replace(
                            " ",
                            "_"
                          )}`}
                        />
                      </Col>
                    </Row>
                  </>
                )
              )}
            </Col>
          </Row>

          <Row className="bg-light rounded-bottom p-1">
            <Col className="square border-end">
              <Row>
                <Col className="col-md-6 col-12">
                  <strong>Total Earnings :</strong>
                </Col>
                <Col className="text-end">
                  {" "}
                  <strong>
                    <DisplayNumberFormatComponent
                      Number={data.TotalEarning ?? 0}
                      Currency={currencyData?.Symbol}
                    />
                  </strong>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="col-md-6 col-12">
                  <strong>Total Deductions :</strong>
                </Col>
                <Col className="text-end">
                  <strong>
                    <DisplayNumberFormatComponent
                      Number={data.TotalDeduction ?? 0}
                      Currency={currencyData?.Symbol}
                    />
                  </strong>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="bg-light p-1">
            <Col className="col-md-6 col-12">
              <span>
                <strong>
                  Net pay for the Month :
                  <DisplayNumberFormatComponent
                    Number={roundOf(data.TotalEarning - data.TotalDeduction, 2)}
                    Currency={currencyData?.Symbol}
                  />
                </strong>
              </span>
            </Col>
          </Row>
        </div>

        <Row className="p-2">
          <div className="text-end">
            <Button
              variant="outline-danger"
              type="button"
              onClick={() => fnReset()}
              id="resetButton"
            >
              Reset
            </Button>
            <span> </span>
            <Button variant="outline-primary" type="submit" form="hook-form" id="submitButton">
              {(state) ? "Changes Submit" : "Submit"}
            </Button>
          </div>
        </Row>
        
        {/* Selected Employee Packages List */}
        {(employeePackages.length > 0) && (
          <TableComponent
            columns={employeePackageColumns}
            data={employeePackages}
            IsAddButtonVisible={false}
            isSearchRequired={false}
            allowSerialNo={true}
            isExcelExportButtonVisible={false}
          />
        )}
      </Container>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Submitted successfully
        </Alert>
      </Snackbar>
      <OtherComponentAdd
        ref={otherComponentAddRef}
        callBackEvent={async (dt) => {
          var dr = {
            TemplateComponentId: null,
            TemplateId: null,
            SalaryComponentId: dt.SalaryComponentId,
            DependentOnComponentId: null,
            IsDependentOnCTC: 0,
            CalculationMethodId: 2,
            NumberOrAmount: dt.Amt,
            Code: "Fixed",
            CalculationMethod: "Fixed",
            EarningOrDeductionName: dt.EarningOrDeductionName,
            EarningOrDeductionType: dt.EarningOrDeductionType,
            PreTaxORPostTax: dt.PreTaxORPostTax,
            Amount: 0,
          };
          await setComponentList((s) => [...s, dr]);
        }}
      />
    </>
  );
});

export default AddEditEmpSalaryPackage;

import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
const requiredMessage = StandardConst.requiredMessage;
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditInvoiceSection = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [sectionLabel, setSectionLabel] = useState();
  const [dropdownListValuesOfTableFieldMapping, setDropdownListValuesOfTableFieldMapping] = useState([]);

  const [data, setData] = useState({});

  const handleClose = () => {
    setData({});
    setDropdownListValuesOfTableFieldMapping([]);
    setSectionLabel('');
    setShow(false);
  };

  useImperativeHandle(ref, () => ({
    openModal: async (invoiceCustomFieldId, section, maxOrderNumber, invoiceSettingId) => {

      fetchTableFieldMappingColumns(section);
      setShow(true);

      if(invoiceCustomFieldId > 0){
        setShow(false);
        await WebService({
          endPoint: `Invoice/Setting/Detail/FetchById/ ${invoiceCustomFieldId || 0}`,
          dispatch,
        }).then((r) => {
          setData(r);
          setShow(true);
        });
      }else{
        setData({Section: section, SectionWiseDisplayOrder: maxOrderNumber + 1, InvoiceSettingId: invoiceSettingId})
      }
      setSectionLabel(section);
    },
  }));

  const fetchTableFieldMappingColumns = async (mappingSection) => {
    await WebService({
      endPoint: `CommonUtility/fetchAll/invoice_table_filed_mapping?Section='${mappingSection}'`,
      dispatch,
    }).then((invoiceTableFieldMappingData) => {
      
        let listOfDropdown = [
          ...StandardConst.DataEntryType,
          ...invoiceTableFieldMappingData.map((r) => {
          return { value: r.InvoiceTableFiledMappingId, text: r.DisplayLabel };
        })];
        setDropdownListValuesOfTableFieldMapping(listOfDropdown);
    });
  }

  const schema = yup
    .object()
    .shape({
      FieldLabel: yup.string().trim().required(requiredMessage),
      FieldType: yup.string().trim().required(requiredMessage),
    })
    .required();

  const onSubmit = async (data) => {
    data.InvoiceTableFiledMappingId = (data.InvoiceTableFiledMappingId == 0) ? null : data.InvoiceTableFiledMappingId;
    data.FieldName = data.FieldLabel.replace(/ /g, "");
    if (data.InvoiceCustomFieldId === undefined) {
      await WebService({
        dispatch,
        endPoint: "Invoice/Setting/Detail/Insert",
        body: data,
      });
    } else {
      await WebService({
        endPoint: `Invoice/Setting/Detail/UpdateById/${data.InvoiceCustomFieldId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }

    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="px-2">
          {(data?.InvoiceCustomFieldId || 0) === 0
            ? `Add Invoice Fields`
            : `Edit Invoice Fields`}
        </Modal.Title>
      </Modal.Header>
      <Form defaultValues={data} onSubmit={onSubmit} validationSchema={schema}>
        <Modal.Body className="p-4">
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Text On Invoice"
                  name="FieldLabel"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputDropdown
                  name="FieldType"
                  ddOpt={StandardConst.invoiceFieldType}
                  label="Display"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputDropdown
                  name="InvoiceTableFiledMappingId"
                  ddOpt={dropdownListValuesOfTableFieldMapping}
                  label="Value will come from"
                />
              </div>
              <div className="col-md-12">
                <FormInputDropdown
                  name="FieldIsRequired"
                  ddOpt={StandardConst.YesOrNo}
                  label="Is Required Field"
                />
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button id="btnRoleSubmit" variant="outline-primary" type="submit">
            {" "}
            {(data?.InvoiceCustomFieldId || 0) === 0
              ? "Submit"
              : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditInvoiceSection);

import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { InputDatePicker, InputDropdown } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { WebService } from "../../Services/WebService";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import _, { extend, findWhere, groupBy, map, omit, reduce } from "underscore";
import TableComponent from "../../Services/TableComponent";
import { PageInfo } from "../PageInfo";
import TopbarComponent from "../../Services/TopbarComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import DisplayNumberFormatComponent, {
  DisNumFormatWoCurrency,
} from "../../Services/DisplayNumberFormatComponent";
import WSCircularProgress from "../../Services/WSCircularProgress";
import SquareIcon from "@mui/icons-material/Square";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WSLinearProgressWithCap from "../../Services/WSLinearProgressWithCap";
const TeamComponent = () => {
  PageInfo({ pageTitle: "Team Time Sheet" });
  var dispatch = useDispatch();
  const roundOf = (num, position) => {
    return (
      Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
      Math.pow(10, 2)
    );
  };
  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
    team: 0,
  });
  const [teams, setTeams] = useState([]);
  const [projects, setProjects] = useState([]);
  const [reports, setReports] = useState([]);
  const afterRender = useRef(false);
  useEffect(() => {
    if (!afterRender.current) {
      Promise.all([fetchDesignation(), fetchProject()]);
    }
    afterRender.current = true;
  }, []);
  const fetchDesignation = async () => {
    const v1 = await WebService({
      dispatch,
      endPoint:
        "CommonUtility/static_project_roles?orderBy=DisplayDescription&select=DisplayDescription,ProjectRoleId",
    });
    if (v1.length > 0)
      setTeams(
        [{ value: 0, text: "Everyone" }].concat(
          map(v1, (m) => ({
            value: m.ProjectRoleId,
            text: m.DisplayDescription,
          }))
        )
      );
  };
  const fetchProject = async () => {
    const v1 = await WebService({
      dispatch,
      endPoint:
        "CommonUtility/projectmaster?orderBy=ProjectName&select=ProjectName,ProjectId,Symbol&expand=staticcurrency",
    });
    if (v1.length > 0) {
      setProjects(
        map(v1, (m) => ({
          value: m.ProjectId,
          text: m.ProjectName,
          Symbol: m.Symbol,
        }))
      );
      setFilter((filter) => ({ ...filter, project: v1[0].ProjectId }));
    }
  };
  const fetchReport = async (search = filter) => {
    const team =
      search.team != 0
        ? `'${search.team}'`
        : teams
            .filter((f) => f.value != 0)
            .reduce((m, v) => `${m},'${v.value}'`, "")
            .substring(1);
    if (teams.length > 0) {

      const endPoint = `Report/Timesheet/Team?startDate=${search.FromDate}&endDate=${filter.ToDate}&projectId=${filter.project}&roles=(${team})`;

      var results = await WebService({ dispatch, endPoint });

      const modifiedArray = await Promise.all(results?.map(async(item) => {
        let totalReduceData = {};
        if(item.EmployeeId && item.ProjectId){
          const res = await WebService({ 
            dispatch, 
            endPoint: `Report/Timesheet/Member?startDate=${search.FromDate}&endDate=${search.ToDate}&projectId=${item.ProjectId}&memberId=${item.EmployeeId}` 
          });
    
          const uniqueMonths = new Set();
          const filteredResult = res.map((obj) => {
            const formattedDate = DateTime.fromISO(obj.Date).toFormat("yyyy-MM-dd");
            const month = formattedDate.substring(5, 7);
            if (!uniqueMonths.has(month)) {
              uniqueMonths.add(month);
              return obj;
            }
            if (obj.BillingMode === StandardConst.BilingMode.FixedPerMonth.replace(/\s/g, "")) {
              obj.Amount = 0; // Return 0 for duplicate months
            }
            return obj;
          }).filter(obj => obj !== null);

          const reducedData = filteredResult.reduce((acc, cur) => {
            return {
              Amount: acc.Amount + Math.round(Number(cur.Amount)),
              TimeInHour: acc.TimeInHour + cur.TimeInHour
            };
          }, { Amount: 0, TimeInHour: 0 }); // <-- Provide initial value for accumulator
          
          const { Amount, TimeInHour } = reducedData;          

          totalReduceData.Amount = Amount;
          totalReduceData.TimeInHour = TimeInHour;
        }
        item.Amount = totalReduceData.Amount;
        item.TimeInHour = totalReduceData.TimeInHour;
        return item;
    }));
      setReports(modifiedArray);
    }
  };
  useEffect(() => {
    if (
      (filter.FromDate ?? "").toString().length > 0 &&
      (filter.ToDate ?? "").toString().length > 0 &&
      teams.length > 0 &&
      (filter.project ?? 0) != 0
    ) {
      fetchReport();
    }
  }, [filter, teams]);
  const [budgetVisiable, setBudgetVisiable] = useState(false);
  useEffect(() => {
    if ((filter?.project ?? 0) !== 0) {
      var p1 = WebService({
        dispatch,
        endPoint: `Report/Timesheet/Team/BudgetVisiable?ProjectId=${filter.project}`,
      }).then((r) => {
        setBudgetVisiable(r);
      });
      Promise.all([p1]);
    }
  }, [filter.project]);
  //#region Time Frame
  const [timeFrame, setTimeFrame] = useState(StandardConst.timeFrame.Week);
  const setDateByTimeFrame = (fromDate) => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.plus({ day: 6 }).toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: (fromDate.day > 15
            ? fromDate.endOf("month")
            : fromDate.plus({ day: 14 })
          ).toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("month").toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("quarter").toSQLDate(),
        }));
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setFilter((filter) => ({
          ...filter,
          FromDate: fromDate.toSQLDate(),
          ToDate: fromDate.endOf("year").toSQLDate(),
        }));
        break;
      }
      //#endregion
    }
  };
  useEffect(() => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.now().minus({
          day: DateTime.now().weekday - 1,
        });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        const currentDay = DateTime.now().day;
        let fromDate = DateTime.now().startOf("month");
        if (currentDay > 15) fromDate = fromDate.plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(DateTime.now().startOf("month"));
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(DateTime.local().startOf("quarter"));
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(DateTime.now().startOf("year"));
        break;
      }
      //#endregion
    }
  }, [timeFrame]);
  const fnPrevious = () => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.fromSQL(filter.FromDate).minus({ day: 7 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        let fromDate = DateTime.fromSQL(filter.FromDate);
        fromDate =
          fromDate.day > 15
            ? fromDate.startOf("month")
            : fromDate.minus({ month: 1 }).plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ month: 1 })
        );
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ month: 3 })
        );
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).minus({ year: 1 })
        );
        break;
      }
      //#endregion
    }
  };
  const fnNext = () => {
    switch (timeFrame) {
      //#region Week
      case StandardConst.timeFrame.Week: {
        const fromDate = DateTime.fromSQL(filter.FromDate).plus({ day: 7 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Semimonth
      case StandardConst.timeFrame.Semimonth: {
        let fromDate = DateTime.fromSQL(filter.FromDate);
        fromDate =
          fromDate.day > 15
            ? fromDate.plus({ month: 1 }).startOf("month")
            : fromDate.plus({ day: 15 });
        setDateByTimeFrame(fromDate);
        break;
      }
      //#endregion
      //#region Month
      case StandardConst.timeFrame.Month: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).plus({ month: 1 })
        );
        break;
      }
      //#endregion
      //#region Quarter
      case StandardConst.timeFrame.Quarter: {
        setDateByTimeFrame(
          DateTime.fromSQL(filter.FromDate).plus({ month: 3 })
        );
        break;
      }
      //#endregion
      //#region Year
      case StandardConst.timeFrame.Year: {
        setDateByTimeFrame(DateTime.fromSQL(filter.FromDate).plus({ year: 1 }));
        break;
      }
      //#endregion
    }
  };
  //#endregion
  var filterableViewComponent = (
    <>
      <Row className="mt-3 mx-2">
        <Col className="d-flex flex-row align-items-center p-0" md={9}>
          <ButtonGroup
            size="small"
            variant="outlined"
            aria-label="small button group"
          >
            <Button
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "hide" : ""
              }
              onClick={fnPrevious}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "hide" : ""
              }
              onClick={fnNext}
            >
              <ArrowForwardIcon />
            </Button>
          </ButtonGroup>
          <span className="mx-2 h4">
            <strong>
              This&nbsp;
              {`${timeFrame}: `}
            </strong>
            {`${DateTime.fromSQL(filter.FromDate).toFormat(
              "dd MMM"
            )} - ${DateTime.fromSQL(filter.ToDate).toFormat("dd MMM yyyy")}`}
          </span>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between mx-1 pt-2">
        <Col md={8}>
          <Row>
            <Col md={3} className="pt-1">
              <InputDropdown
                setValue={setTimeFrame}
                ddOpt={map(StandardConst.timeFrame, (text, _) => ({
                  value: text,
                  text,
                }))}
              />
            </Col>
            <Col
              md={3}
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "p-0 " : "d-none"
              }
            >
              <InputDatePicker
                labelCss="text-center"
                value={filter.FromDate}
                setValue={(v) => setFilter({ ...filter, FromDate: v })}
                max={(filter.ToDate ?? "") == "" ? undefined : filter.ToDate}
              />
            </Col>
            <Col
              md={3}
              className={
                timeFrame === StandardConst.timeFrame.Custom ? "" : "d-none"
              }
            >
              <InputDatePicker
                labelCss="text-center"
                value={filter.ToDate}
                setValue={(v) => setFilter({ ...filter, ToDate: v })}
                min={
                  (filter.FromDate ?? "") == "" ? undefined : filter.FromDate
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={4} className="p-0">
          <Row>
            <Col
              md={6}
              className={timeFrame === StandardConst.timeFrame.Custom}
            >
              <InputDropdown
                labelCss="text-center"
                className="form-control"
                ddOpt={teams || []}
                value={filter.team}
                setValue={(v) => setFilter({ ...filter, team: v })}
              />
            </Col>
            <Col md={6}>
              <InputDropdown
                labelCss="text-center"
                className="form-control"
                ddOpt={projects || []}
                value={filter.project}
                setValue={(v) => setFilter({ ...filter, project: parseInt(v) })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
  var reportSummaryComponent = (
    <>
      <hr className="mx-2"></hr>
      <Row className="px-4 p-3">
        <Col className="d-flex flex-row  justify-content-between" md={5}>
          <div className="d-flex flex-column text-left">
            <div className="small">Total Hours</div>
            <div className="h3">
              <strong>
                <DisNumFormatWoCurrency
                  Number={roundOf(
                    reduce(reports, (m, v) => m + v.TimeInHour, 0),
                    2
                  )}
                />
              </strong>
            </div>{" "}
          </div>
          <div className="d-flex flex-column text-left">
            <div className="small">Billable Hours</div>
            <div className="h3">
              <strong>
                <DisNumFormatWoCurrency
                  Number={roundOf(
                    reduce(reports, (m, v) => m + v.Billable, 0),
                    2
                  )}
                />
              </strong>
            </div>{" "}
          </div>

          <div className="d-flex flex-column text-left">
            <div className="small">Non-Billable Hours</div>
            <div className="h3">
              <strong>
                {" "}
                <DisNumFormatWoCurrency
                  Number={roundOf(
                    reduce(reports, (m, v) => m + v.NonBillable, 0),
                    2
                  )}
                />
              </strong>
            </div>{" "}
          </div>
        </Col>
        <Col md={1}></Col>
        <Col className="d-flex flex-row  justify-content-between" md={6}>
          <div className="d-flex flex-row text-left mt-2 m">
            <div>
              <WSCircularProgress
                Billable={
                  (roundOf(
                    reduce(reports, (m, v) => m + v.Billable, 0),
                    2
                  ) /
                    roundOf(
                      reduce(reports, (m, v) => m + v.TimeInHour, 0),
                      2
                    )) *
                  100
                }
              />
            </div>
            <div className="ms-2 small">
              <SquareIcon
                sx={{ color: `${StandardConst.wsBillableColor}`, fontSize: 20 }}
              />
              <span>Billable</span>
              <br></br>
              <SquareIcon
                sx={{
                  color: `${StandardConst.wsNonBillableColor}`,
                  fontSize: 20,
                }}
              />
              <span>Non-Billable</span>
            </div>{" "}
          </div>
          {budgetVisiable && (
            <>
              <div className="d-flex flex-column text-left mx-3 text-nowrap">
                <div className="small">Billable Mode</div>
                <div className="medium">
                  <strong>
                    {
                      reports.reduce((m, v) => {
                        const billingMode = v.BillingMode.replace(/\s/g, "");
                        return StandardConst.BilingMode[billingMode] || "NA";
                      }, "NA")
                    }
                  </strong>
                </div>{" "}
              </div>
              <div className="d-flex flex-column text-left">
                <div className="small">Billable Invoice</div>
                <div className="h3">
                  <strong>
                    {" "}
                    <DisplayNumberFormatComponent
                      Currency={
                        findWhere(projects ?? [], {
                          value: filter.project ?? 0,
                        })?.Symbol ?? ""
                      }
                      Number={roundOf(
                        reduce(reports, (m, v) => m + v.Amount, 0),
                        2
                      )}
                    />
                  </strong>
                </div>{" "}
              </div>
              <div className="d-flex flex-column text-left">
                <div className="small">Billable Amount</div>
                <div className="h3">
                  <strong>
                    {" "}
                    <DisplayNumberFormatComponent
                      Currency={
                        findWhere(projects ?? [], {
                          value: filter.project ?? 0,
                        })?.Symbol ?? ""
                      }
                      Number={roundOf(
                        reduce(reports, (m, v) => m + v.Amount, 0),
                        2
                      )}
                    />
                  </strong>
                </div>{" "}
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
  var reportInGridComponent = (
    <>
      <Row>
        <Col>
          <TableComponent
            data={reports}
            columns={[
              {
                Text: "Member",
                render: (dr) => <span>{dr.FullName}</span>,
                cssClass: "w-25",
              },
              {
                Text: "Hours",
                render: (dr) => (
                  <DisNumFormatWoCurrency Number={dr.TimeInHour} />
                ),
                cssClass: "td-width-1 text-center",
              },
              {
                Text: "",
                render: (dr) => (
                  <Tooltip
                    placement="top"
                    title={`Billable: ${dr.Billable} | Non-Billable: ${dr.NonBillable}`}
                  >
                    <span style={{ height: "26px", display: "block" }}>
                      <WSLinearProgressWithCap
                        Billable={
                          dr.TimeInHour > dr.Capacity
                            ? (dr.Billable / dr.TimeInHour) * 100
                            : (dr.Billable / dr.Capacity) * 100
                        }
                        NonBillable={
                          dr.TimeInHour > dr.Capacity
                            ? (dr.NonBillable / dr.TimeInHour) * 100
                            : (dr.NonBillable / dr.Capacity) * 100
                        }
                        Capacity={dr.TimeInHour > dr.Capacity ? true : false}
                      />
                    </span>
                  </Tooltip>
                ),
              },
              {
                Text: "Capacity",
                render: (dr) => <DisNumFormatWoCurrency Number={dr.Capacity} />,
                cssClass: "td-width-1 text-center",
              },
              {
                Text: "Billable(H)",
                render: (dr) => <DisNumFormatWoCurrency Number={dr.Billable} />,
                cssClass: "td-width-1 text-center",
              },
              {
                Text: "Non-Billable (H)",
                render: (dr) => <DisNumFormatWoCurrency Number={dr.NonBillable} />,
                cssClass: "td-width-2 text-center",
              },
            ]}
            noRecordCss="p-0"
            headerCss="table-header-grey"
            noRecordFound={
              <NoRecordTemplate
                headerValue="No timesheets found for the team."
                subHeaderValue="You will be able to see the summary of the timesheets entered by your team here after the team members start filling their timesheet. Please check the date range to look for the correct data."
                imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
              />
            }
            IsAddButtonVisible={false}
          ></TableComponent>
        </Col>
      </Row>
    </>
  );
  const [bData, setBData] = React.useState([
    {
      title: "Time Sheet",
      hrefLink: "#",
    },
    {
      title: "Team Time Sheet",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Team Time Sheet";
  return (
    <>
      <Container fluid className="base-container">
        <TopbarComponent bData={bData} HeadingText={MasterPageName} />
        <div elevation={0} className="p-3 primary-bg-color"></div>
        <div>{filterableViewComponent}</div>

        {reportSummaryComponent}
        <hr className="mx-2 p-0 m-1"></hr>
        <div className="mx-2">{reportInGridComponent}</div>
      </Container>
    </>
  );
};

export default TeamComponent;

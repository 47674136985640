import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormAutoCompleteDropdown, FormAutoCompleteDropdownCard, FormInputDropdown, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';
import { chain } from 'underscore';
import { generateRandomPassword } from '../../utils/CommonUtils';
import { WSSuccessAlert } from '../../Services/WSAlert';


const AddEditAgent = (props, ref) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ Agents: {} });
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const [UserOrEmployeeList, setUserOrEmployeeList] = useState([]);
    const [showUserTypeInput, setShowUserTypeInput] = useState(false);
    const  [preFilldedData, setPreFilldedData] = useState( { Agents: {}});
    const [showEmployeeInput, setShowEmployeeInput] = useState(false);
    
    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            // fetchAllEssentialInformation();
            if (id === 0) {
                setShowEmployeeInput(true);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    useEffect(() => {
        Promise.all([
            fetchAllEssentialInformation()
        ])
    }, [])

    const fetchEditValue = async (id) => {
        const data = {
            Agents: await WebService({
                endPoint: `CommonUtility/Edit/agents?AgentId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        if(data.Agents.EmployeeId){
            setShowEmployeeInput(true);
        }else if( data.Agents.UserId){
            setShowUserTypeInput(true);
        }
        if(data.Agents.UserId === null && data.Agents.UserType === null){
            data.Agents.PersonType = StandardConst.AgentType[1].value;
        }

        if(data.Agents.EmployeeId){
            data.Agents.Person = [UserOrEmployeeList.find(item => item.EmployeeId === data.Agents.EmployeeId)];
        }else if(data.Agents.UserId){
            data.Agents.Person = [UserOrEmployeeList.find(item => item.UserId === data.Agents.UserId)];
        }

        setPreFilldedData(data);
        setData(data);
    }

    const handleClose = () => {
        setShow(false);
        setShowUserTypeInput(false);
        setData({ Agents: {} });
        setShowEmployeeInput(false);
        setPreFilldedData({ Agents: {} });
        setIsEmployeeOrUser(false);
        setEmpIdAlreadyExists(null);
    };

    const [isEmployeeOrUser, setIsEmployeeOrUser] = useState(false);
    
    const schema = yup
        .object()
        .shape({
            FullName: preFilldedData.Agents.FullName ? null : yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Email:  preFilldedData.Agents.Email ? null : yup
                .string()
                .email()
                .required(StandardConst.ValidationMessages.RequiredFieldMsg)
                .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, StandardConst.ValidationMessages.InvalidEmailMsg),

            PersonType: yup.number().required(),
            Person: isEmployeeOrUser === true ? yup
                    .array()
                    .of(
                        yup.object().shape({
                            heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
                        })
                    )
                    .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
                    .min(1, StandardConst.ValidationMessages.RequiredFieldMsg) : null,
            
            ContactNumber: yup.string()
            .nullable()
            .test(
                'is-valid-phone',
                StandardConst.ValidationMessages.PhoneNoIsNotValid,
                value => !value || StandardConst.PhoneNumberValidateRegex.test(value)
            )
        })
        .required();
    
    const [isEmpIdAlreadyExists, setEmpIdAlreadyExists] = useState(null);

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;
        if (data.Person?.length > 0) {
            const PersonKeys = Object.keys(data?.Person[0]);
            const isEmployeeId = PersonKeys?.some((key) => key === "EmployeeId");
            if (isEmployeeId) {
                data.EmployeeId = data.Person[0]?.EmployeeId;
            } else {
                data.UserId = data.Person[0]?.UserId;
            }
            data.FullName = preFilldedData.Agents.FullName;
            data.Email = preFilldedData.Agents.Email;
            data.ContactNumber = preFilldedData.Agents.ContactNumber;
        }

        if (data.AgentId === undefined) {
            if(Number(data?.PersonType) === StandardConst.AgentType[1].value){
                const randomPassword = generateRandomPassword(8);
                data.Password = randomPassword;
                if (!data.UserId) {
                    const UserId = await WebService({
                        endPoint: `CommonUtility/users?register=success`,
                        body: data,
                        dispatch,
                    });
                    data.UserId = UserId;
                }
            }
            data.RoleId = StandardConst.SystemRole.Agent;
            await WebService({
                endPoint: `CommonUtility/userroles`,
                body: data,
                dispatch,
            });
            await WebService({
                endPoint: "CommonUtility/agents",
                body: data,
                dispatch,
            });
            // send mail for the agents
            let endPoint = ``;
            if(data.EmployeeId){
                delete data.Password;
                endPoint = `tickets/SendMailForAgent?SendMailForEmployeeAgent=true`;
            }
            if(data.UserId){
                endPoint = `tickets/SendMailForAgent?SendMailForUserAgent=true`;
            }
            await WebService({
                endPoint: endPoint,
                body: data,
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", "Agent Added Successfully");
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/agents?AgentId=${data.AgentId}`,
                method: "PUT",
                body: data,
                dispatch,
            }).then(res => {
                WSSuccessAlert("Success", "Agent Updated Successfully");
            });
        }
        handleClose();
        props.callBackEvent();
    }

    const fetchAllEssentialInformation = async () => {
        const data = {
            Employees: await WebService({
                endPoint: `Visitor/fetchAllEmployees`,
                dispatch,
            }),
            Users: await WebService({
                endPoint: `CommonUtility/fetchAll/users?CompanyId=${companyInfo.CompanyId || 0}`,
                dispatch,
            }),
        }
        const emplist = data.Employees?.filter((r) => r.FullName !== null)?.map((res) => {
            return {
                ...res,
                EmployeeId: res.EmployeeId,
                heading: res.FullName,
                description: res.Designation,
                avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        const userslist = data.Users?.filter((r) => r.FullName !== null)?.map((res) => {
            return {
                ...res,
                UserId: res.UserId,
                heading: res.FullName,
                description: res.Designation,
                avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        const combinedDetails = [...emplist, ...userslist];
        setUserOrEmployeeList(combinedDetails);
    }

    const handleSelectedPerson = (value) => {
        if(value[0]?.CompanyId){
            const PersonKeys = Object.keys(value[0]);
            const isEmployeeId = PersonKeys?.some((key) => key === "EmployeeId");
            if (isEmployeeId) {
                setShowUserTypeInput(false);
                setShowEmployeeInput(true);
                setEmpIdAlreadyExists(value[0].EmployeeId);
            } else {
                setShowUserTypeInput(true);
                setShowEmployeeInput(false);
            }
            setPreFilldedData({ Agents : { FullName : value[0].FullName, Email: value[0].Email, ContactNumber: value[0].Phone}});
        }else {
            setShowUserTypeInput(false);
            setPreFilldedData( { Agents: {}});
            setShowEmployeeInput(false);
        }
    }

    const handlePersonType = (value) => {
        if(value === StandardConst.AgentType[0].value){
            setIsEmployeeOrUser(true);
        }else {
            setIsEmployeeOrUser(false);
            setEmpIdAlreadyExists(null);
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.Agents && data.Agents.AgentId || 0) === 0
                        ? "Add Agent"
                        : "Edit Agent"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.Agents}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12 pb-2">
                            <FormInputDropdown
                                label="Person Type"
                                name="PersonType"
                                ddOpt={StandardConst.AgentType}
                                setValue={(value) => handlePersonType(Number(value))}
                            />
                        </div>
                        {
                            isEmployeeOrUser && (
                                <>
                                    <div className="col-md-12 pb-2">
                                        <FormAutoCompleteDropdownCard
                                                name="Person"
                                                data={chain(UserOrEmployeeList ?? [])}
                                                label="Person"
                                                optionText="heading"
                                                isRequired="true"
                                                setValue={(val) => { handleSelectedPerson(val) }}
                                                value={data.Agents.EmployeeId && data.Agents.Person[0] || data.Agents.UserId && data.Agents.Person[0]}

                                            />
                                    </div>
                                </>
                            )
                        }
                        <div className="col-md-12">
                            <FormInputText
                                label="Full Name"
                                name="FullName"
                                type="text"
                                isRequired="true"
                                value={preFilldedData.Agents.FullName}
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Email"
                                name="Email"
                                type="text"
                                isRequired="true"
                                value={preFilldedData.Agents.Email}
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                label="Contact Number"
                                name="ContactNumber"
                                type="text"
                                value={preFilldedData.Agents.ContactNumber}
                            />
                        </div>
                        {
                            showUserTypeInput && (
                                <div className="col-md-12 pb-2">
                                    <FormInputDropdown
                                        label="User Type"
                                        name="UserType"
                                        ddOpt={StandardConst.UserTypeAgent}
                                    />
                                </div>
                            )
                        }
                        <div className="col-md-12">
                            <FormInputText
                                label="Position Or Role"
                                name="PositionOrRole"
                                type="text"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.Agents && data.Agents.AgentId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditAgent); 
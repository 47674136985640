import '../css/App.css'
import '../css/Auth.css'
import { useDispatch } from 'react-redux'
import { setActiveTab } from '../features/auth/authSlice'
import { Alert } from './Alert'
import { Camera } from './Camera'
import { FormTabs } from './FormTabs'
import { Loader } from './Loader'
import { Login } from './Login'
import { useEffect } from 'react'

export const FaceDetector = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setActiveTab('login'));
    }, []);

    return (
        <>
            <Loader />
            <div className="container FaceRecognitionContainer wrap-login100">
                <div className="row FaceRecognitionBackgroundColor">
                    <div className="col-lg-6 col-md-12 l-side position-relative">
                        <Camera />                        
                    </div>
                    <div className="col-lg-6 col-md-12 r-side">
                        <ul className="nav nav-tabs">
                            <li className={'nav-item active'}>Login</li>
                        </ul>
                        <div className='form-container'>
                            <Alert />
                            <Login />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
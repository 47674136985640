import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { WebService } from "../../Services/WebService";
import { map } from "underscore";
import {
  Cell,
  Tooltip,
  PieChart,
  Pie,
  ResponsiveContainer,
} from "recharts";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { DateRange } from "../Dashboard/Company/CompanyDashboard";

// How to call this component ex:- <CustomPieChart endPoint="Vehicle/getFuelConsumptionAnalysis" mappingFields={{name: MileageRange, value: NumberOfVehicles}} graphHeading="Fuel Consumption Analysis Graph" graphRadius={80} />
export const CustomPieChart = ({endPoint, mappingFields, graphHeading, graphRadius, showFilter}) => {
  showFilter ??= false;
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const dispatch = useDispatch();
    const [data, setData] = useState([{name: StandardConst.NoDataForGraphs, value: 1}]);
  
    const fetchData = async (selectedMonthYear) => {
      selectedMonthYear ??= null;
      let updatedEndPoint = endPoint;
      if(showFilter){
        updatedEndPoint += `?YearMonthFilter=${selectedMonthYear !== null ? selectedMonthYear : format(new Date(), 'yyyy-MM')}`;
      }

      await WebService({
        dispatch,
        endPoint: updatedEndPoint,
      }).then((r) => {
        if(r.length > 0){
            setData(
                map(r, (m) => ({
                    name: m[mappingFields.name],
                    value: m[mappingFields.value],
                }))
            );
        }else{
          setData([{name: StandardConst.NoDataForGraphs, value: 1}]);
        }
      });
    };
  
    useEffect(() => {
      if ((companyInfo.CompanyId ?? 0) !== 0) Promise.all([fetchData()]);
    }, [companyInfo]);

    const handleChangeFilters = async (selectedMonthYear) => {
      await fetchData(selectedMonthYear);
    };
  
    const COLORS = [
      StandardConst.GraphColors.blue,
      StandardConst.GraphColors.green,
      StandardConst.GraphColors.yellow,
      StandardConst.GraphColors.orange,
      StandardConst.GraphColors.red,
      StandardConst.GraphColors.lightGrey,
    ];
  
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
      return (
        <text
          className="font-size-13"
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };
  
    const NoDataRrenderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
      return <></>;
    };
    return (
      <>
        <div className="card card-cd m-0 p-3">
          <div className="d-flex flex-row">
            <div className="col-7 d-flex justify-content-start align-items-center">
              <h5 className="card-title">{graphHeading}</h5>
            </div>
            <div className="col-5 d-flex justify-content-start align-items-center">
                {(showFilter) && (
                  <DateRange 
                    ButtonColor="inherit"
                    setDateRange={({ StartDate, EndDate }) => {
                      if(StartDate){
                        handleChangeFilters(format(new Date(StartDate), "yyyy-MM"))
                      }
                    }}
                  />
                )}
            </div>
          </div>
  
          <div className="card-body card-body-cd p-0">
            <div className="row d-flex flex-row">
              <div className="col-12 col-md-7 d-flex flex-row justify-content-center align-items-center">
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart width={300} height={150}>
                    <Pie
                      data={data}
                      labelLine={false}
                      label={(data[0].name === StandardConst.NoDataForGraphs) ? NoDataRrenderCustomizedLabel : renderCustomizedLabel}
                      outerRadius={graphRadius}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => {
                      if(entry.name === StandardConst.NoDataForGraphs){
                          return <Cell
                            key={`cell-${index}`}
                            fill={StandardConst.GraphColors.lightGrey}
                          />
                        }
                        else{
                          return <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        }
                      })}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="col-12 col-md-5">
                {data?.map((m, index) => (
                  <div className="d-flex flex-row align-items-center font-size-13" key={index}>
                    <div>
                      <Box
                        sx={{
                          width: 5,
                          height: 20,
                          backgroundColor: (m.name === StandardConst.NoDataForGraphs) ? StandardConst.GraphColors.lightGrey : COLORS[index % COLORS.length],
                        }}
                      />
                    </div>
  
                    <div className="d-flex flex-column justify-content-start align-items-start p-1">
                      <span>
                        {m.name} - <strong> {(m.name === StandardConst.NoDataForGraphs) ? 0 : m.value}</strong>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
const requiredMessage = StandardConst.requiredMessage;
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditEmployeeKRA = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ Employee: {} });
  const [setEmployee, setEmployeeData] = useState();
  useImperativeHandle(ref, () => ({
    openModal: async (id, EmployeeId) => {
      setShow(true);
      setEmployeeData(EmployeeId);
      if(id){
        setShow(false);
        const data = {
          Employee: await WebService({
            endPoint: `CommonUtility/Edit/otheremployeesspecifickra?EmpSpecificKRAId=${id || 0}`,
            dispatch,
        })}
        setData(data);
        setShow(true);
      }
    },
  }));
  const handleClose = () => {
    setShow(false);
    setData({ Employee: {} });
  }
  const schema = yup
    .object()
    .shape({
      KRA: yup.string().trim().required(requiredMessage),
      KRADescription: yup.string().trim().required(requiredMessage),
    })
    .required();

  const onSubmit = async (AddEditData) => {
    AddEditData.EmployeeId = setEmployee;
    if (AddEditData.EmpSpecificKRAId === undefined) {
      await WebService({
        dispatch,
        endPoint: "CommonUtility/otheremployeesspecifickra",
        body: AddEditData,
      });
    } else {
      await WebService({
        endPoint: `CommonUtility/otheremployeesspecifickra?EmpSpecificKRAId=${
          data.Employee[0].EmpSpecificKRAId ?? 0
        }`,
        method: "PUT",
        body: AddEditData,
        dispatch,
      });
    }

    handleClose();
    prop.callBackEvent();
  };

  const formBody = (
    <>
      <div className="row">
        <div className="col-md-12">
          <FormInputText label="KRA" name="KRA" type="text" isRequired="true" />
          <FormInputText
            label="KRA Description"
            name="KRADescription"
            type="text"
            isRequired="true"
          />
        </div>
      </div>

      <></>
    </>
  );
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="px-2">
          {(data.Employee[0] || 0) === 0
            ? "Add Employee KRA"
            : "Edit Employee KRA"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.Employee[0]}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">{formBody}</Modal.Body>
        <Modal.Footer>
          <Button
            id="btnJobFunctionSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data.Employee[0] || 0) === 0 ? "Assign Employee KRA" : "Save Changes"}{" "}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditEmployeeKRA);

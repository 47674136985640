import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form, FormInputDropdown, FormInputText } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { StandardConst } from "../../Services/StandardConst";
import * as yup from "yup";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import TableComponent from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import format from "date-fns/format";
import "./dateCss.css";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Modal from "react-bootstrap/Modal";
import DeleteConfirmAlert from "../../Services/AlertComponent";

import {
  Alert,
  Chip,
  Paper,
} from "@mui/material";
import SnackbarComponent from "../../Services/SnackbarComponent";
import ActionButton from "../../Services/ActionButton";
import ViewIDCard from "./ViewIDCard";
import HtmlFileReader from "../../Services/HtmlFileReader";
import StaticListComponent from "../../Services/StaticListComponent";
import AddEmployeeIdCard from "./AddEmployeeIdCard";
import { WSSuccessAlert } from "../../Services/WSAlert";

const EmployeeIdCardReport = () => {
  PageInfo({ pageTitle: "Generate Employee ID Card" });

  const refDeleteEmployeeIdCard = useRef();

  const [filter, setFilter] = useState({
    FromDate: DateTime.local().toFormat("yyyy-MM-dd"),
    ToDate: DateTime.local().toFormat("yyyy-MM-dd"),
  });
  const [data, setData] = useState(null);
  const [expireDate, setExpireDate] = useState();
  const [formdate, setFromdate] = useState(null);
  const [todate, setTodate] = useState(null);
  const [persons, setPersons] = useState([]);
  const renderAfterCalled = useRef(false);
  const viewIDCardRef = useRef();
  const [dp, setDp] = useState([]);
  const dispatch = useDispatch();
  const [IdCardinfo, setIdCardInfo] = useState([]);

  const [confirmMessage, setconfirmMessage] = useState([]);
  const refSnackbar = useRef();
  const onSubmit = async (rec) => {
    if ((rec?.ExpireDate || "") !== "")
      rec.ExpireDate = format(new Date(rec.ExpireDate), "yyyy-MM-dd");

    //setExpireDate(rec.ExpireDate);
    const data = await WebService({
      endPoint: "EmployeeIdCard/FetchCard",
      body: rec,
      dispatch,
    });

    if (data == "Employee Id Card Already Created..!") {
      setconfirmMessage(data);
      refSnackbar.current.setOpenSnackBar();
    } else if (data == "Office location Data is Missing..!") {
      setconfirmMessage(data);
      refSnackbar.current.setOpenSnackBar();
    } else {
      await fetchIdCardList();
      // if ((data?.DOB || "") !== "")
      //   data.DOB = format(new Date(data.DOB), "yyyy-MM-dd");
      // setDp(data?.ProfileImage ?? null);
      // setData(data);
    }
  };


  const fetchIdCardList = async () => {
    const data = await WebService({
      endPoint: `EmployeeIdCard/FetchActiveIdCard`,
      dispatch,
    });
    if ((data?.DOB || "") !== "")
      data.DOB = format(new Date(data.DOB), "yyyy-MM-dd");
    setIdCardInfo(data.IdCardDetails);
  };

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);

  useEffect(() => {
    SetPermission({
      ManageEmployeeIdCardEdit: ActionPermission("Employee Id Card - Edit"),
      ManageEmployeeIdCardDelete: ActionPermission("Employee Id Card - Delete"),
    });
  }, [permissionList]);

  const fetchPersonList = async () => {
    var opt = { endPoint: "User/ActiveUserList", dispatch };
    // if (permission.ManageSearchAllUsers) opt.endPoint += "?user=all";
    // else if (permission.ManageReportingUser) opt.endPoint += "?user=manage";
    // else opt.endPoint += "?user=own";

    const res = await WebService(opt);
    setPersons(
      [...persons].concat(
        (res ?? []).map((m) => {
          return {
            value: m.EmployeeId,
            text: m.FullName,
          };
        })
      )
    );
  };
  const init = () => {
    // fetchProjectList();
    fetchIdCardList();
    // fetchPersonList();
  };
  const updateExpiredEmployeeIdInactive = async () => {
    await WebService({
      endPoint: 'EmployeeIdCard/UpdateExpiredEmployeeIdCardToInactive',
      method: 'PUT'
    })
  };
  useEffect(() => {
    if (!renderAfterCalled.current) {
      init();
    }
    renderAfterCalled.current = true;
    updateExpiredEmployeeIdInactive();
  }, []);

  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      ExpireDate: yup
        .date()
        .label("Expire Date")
        .typeError(requiredMessage)
        .required(),
    })
    .required();
  const filterChange = () => {
    setData(null);
  };
  useEffect(() => {
    setFromdate(filter.FromDate);
  }, [filter.FromDate]);
  useEffect(() => {
    setTodate(filter.ToDate);
  }, [filter.ToDate]);
  // const fnEdit = () => {};

  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const [selectedIdCard, setSelectedIdCard] = useState([]);
  const fetchEmployeeDetails = async(employeeId) => {
    const data = await WebService({
      endPoint: `EmployeeIdCard/FetchActiveIdCard/${employeeId}`,
      dispatch,
    });

    const Details = data.IdCardDetails[0];
    setSelectedIdCard([{text: Details.badge_template_name, value: Details.badge_templatesId}]);
    const companyString = Details.CompanyName?.split(' ');
    const companyFormattedText = companyString?.length >= 5 ? companyString?.slice(0, 5).join(' ') : null;
    const nameString = Details?.FullName?.toUpperCase().split(' ');
    const nameFormattedText =nameString?.length >= 3 ? nameString?.slice(0, 3).join(' ') : null;

    const permanentAddressString = Details?.PermanentAddress?.toUpperCase()?.split(' ');
    const permanentAddressFormattedText =permanentAddressString?.length >= 3 ? permanentAddressString?.slice(0, 3).join(' ') : null;

    const communicationAddressString = Details?.Address?.toUpperCase()?.split(' ');
    const communicationAddressFormattedText =communicationAddressString?.length >= 6 ? communicationAddressString?.slice(0, 6).join(' ') : null;

    const paramData = {
      CompanyName: companyFormattedText || Details.CompanyName,
      Logo: CompanyInfo.Logo,
      ProfileImage: Details?.ProfileImage,
      FullName: nameFormattedText || Details.FullName.toUpperCase(),
      EmployeeCode: Details?.EmployeeCode?.toUpperCase(),
      PermanentAddress: permanentAddressFormattedText || Details.PermanentAddress,
      Email: Details.EmployeeEmail,
      Designation: Details.Designation,
      Phone: Details.Phone,
      Address: Details.Address,
      CommunicationAddress: communicationAddressFormattedText || Details.Address,
      CompanyPhone: Details.CompanyPhone,
      EmployeeGuid: Details.Guid,
      QRData: 
          "Name: " + Details.FullName + 
          "Company: " + Details.CompanyName + 
          "Email: " + Details.Address + 
          "Phone: " + Details.Phone + 
          "Code: " + Details.EmployeeCode
    }

    await GenerateBadgeRef.current.openModal(paramData);
  }

  const IdCarddetails = [
    {
      Text: "Name",
      Value: "FullName",
    },
    {
      Text: "Employee Code",
      Value: "EmployeeCode",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },
    {
      Text: "Location",
      Value: "Location",
    },
    {
      Text: "ID Card Expire Date",
      Value: "ExpireDate",
      DateFormat: "yyyy-MM-dd",
    },
    {
      Text: "Action",
      key: "ARTypeId",
      cssClass: "text-center td-width-100",
      // isVisiable: permission.ManageEdit,
      render: (dr) => (
        <>
          <ActionButton
            onClick={async () => await viewIDCardRef.current.openModal(dr.EmployeeId)}
            IconName="View"
            id="btnIdCardView"
          />
          <ActionButton
            IconName="Print"
            id="btnIdCardPrintn"
            IconTooltip={"Print Employee Id Card"}
            onClick={async () =>await fetchEmployeeDetails(dr.EmployeeId)}
          />
        </>
      ),
    },
  ];

  const filterComponent = (
    <>
      {data === null && (
        <div>
          <SnackbarComponent
            ref={refSnackbar}
            confirmMessage={confirmMessage}
          />
          <div className="primary-bg-color p-1 px-4">
            <Form
              defaultValues={filter}
              onSubmit={onSubmit}
              validationSchema={schema}
            >
              <Row>
                <Col md={4}>
                  <FormInputDropdown
                    name="EmployeeId"
                    ddOpt={persons ?? []}
                    label="Employee"
                    labelCss="text-light"
                  />
                </Col>
                <Col md={4}>
                  <FormInputText
                    name="ExpireDate"
                    type="date"
                    label="Expire Date"
                    labelCss="text-light"
                    min={new Date().toISOString().split("T")[0]}
                    max="2999-12-31"
                  />
                </Col>
                <Col md={4}>
                  <Button
                  color="inherit"
                    id="btnCreateIDCard"
                    variant="outline-primary"
                    type="submit"
                    className="float-end css-1y942vo-MuiButtonBase-root-MuiButton-root text-light"
                  >
                    Create ID Card
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
      {IdCardinfo !== null && (
        <div>
          <Paper elevation={2} className="mb-2">
            <Alert>
              <strong>Active ID Card</strong>
            </Alert>

            <TableComponent
              columns={IdCarddetails}
              noRecordCss="p-0"
              noRecordFound={
                <NoRecordTemplate
                  headerValue={StandardConst.GeneratedEmployeeIDheaderValueNoResults}
                  subHeaderValue={StandardConst.subHeaderValueStandard}
                  imageUrl={StandardConst.imageNoRecordsFound}
                />
              }

              data={IdCardinfo}
              // onAddEvent={() => fnEdit()}
              isSearchRequired={true}
              IsAddButtonVisible={false}
              allowSerialNo={true}
            />
          </Paper>
        </div>
      )}
    </>
  );

  const GenerateBadgeRef = useRef();

  const ActiveIdCardComponent = (
    <>
      {IdCardinfo !== null && (
        <div>
          <Paper elevation={2} className="mb-2">
            <Alert>
              <strong>Active ID Card</strong>
            </Alert>

            <TableComponent
              columns={IdCarddetails}
              data={IdCardinfo}
              // onAddEvent={() => fnEdit()}
              isSearchRequired={false}
              IsAddButtonVisible={false}
            />
          </Paper>
        </div>
      )}
    </>
  );
  const MasterPageName = "Generate Employee ID Card";
  const [bData, setBData] = React.useState([
    {
      title: "Employee",
      hrefLink: "#",
    },
    {
      title: "Generate Employee ID Card",
      hrefLink: "#",
    },
  ]);

  const addModalEmployeeRef = useRef();

  const onDelete = async (EmployeeCardBadgeId) => {
    await WebService({
      endPoint: `CommonUtility/employeecardbadges/?EmployeeCardBadgeId=${EmployeeCardBadgeId}`,
      method: "DELETE",
      dispatch,
    });
    // refSnackbar.current.setOpenSnackBar();
    WSSuccessAlert("Sucess", "EmployeeId Card Successfully Deleted.");
    await fetchIdCardList();
  };

  const columns = [
    {
      Text: "Name",
      Value: "FullName",
    },
    {
      Text: "Employee Code",
      Value: "EmployeeCode",
    },
    {
      Text: "Phone",
      Value: "Phone",
    },
    {
      Text: "Location",
      Value: "Location",
    },
    {
      Text: "ID Card Issued Date",
      Value: "IssuedDate",
      DateFormat: "yyyy-MM-dd",
    },
    {
      Text: "ID Card Expire Date",
      Value: "ExpiryDate",
      DateFormat: "yyyy-MM-dd",
    },
    {
      Text: "Status",
      render: (dr) => (
        <span>
            {dr.StatusId === StandardConst.StatusDropdown[0].value ? StandardConst.StatusDropdown[0].text : StandardConst.StatusDropdown[1].text}
        </span>
      )
      // render: (dr) => <Chip 
      //       label={dr.StatusId === StandardConst.EmployeeIdCardStatus.Active ? "Active" : "Inactive"}
      //       sx={{ color: dr.StatusId === StandardConst.EmployeeIdCardStatus.Active ? "white" : "black", padding: "0px"}}
      //       color={dr.StatusId === StandardConst.EmployeeIdCardStatus.Active ? "success" : "default"}
      //       size="small"
      //   />
        
    },
    {
      Text: "Action",
      key: "ARTypeId",
      cssClass: "text-center td-width-100",
      // isVisiable: permission.ManageEdit,
      render: (dr) => (
        <>
          {/* <ActionButton
            onClick={async () => await viewIDCardRef.current.openModal(dr.EmployeeId)}
            IconName="View"
            id="btnIdCardView"
          /> */}
          <div className="d-flex">
            <ActionButton
              onClick={() => fnEdit(dr.EmployeeCardBadgeId)}
              disabled={!permission.ManageEmployeeIdCardEdit}
              IconName="Edit"
              id="btnVisitorTypeEdit"
            />

            <ActionButton
              onClick={(e) =>
                refDeleteEmployeeIdCard.current.confirmAlert(
                  "Delete", //Confirm button text
                  "Are You Sure", // Text if Alert
                  "Do you want to delete " + MasterPageName, // Message of Alert
                  dr.EmployeeCardBadgeId // Endpoint to hit for delete
                )
              }
              disabled={!permission.ManageEmployeeIdCardDelete}
              IconName="Delete"
              id="btnVisitorTypeDelete"
            />

            <ActionButton
              IconName="Print"
              id="btnIdCardPrintn"
              IconTooltip={"Print Employee Id Card"}
              onClick={async () =>await fetchEmployeeDetails(dr.EmployeeId)}
            />
          </div>
        </>
      ),
    },
  ];

  const fnEdit = async (id) => await addModalEmployeeRef.current.openModal(id || 0);
  const AddButtonText = "Create ID Card";
  return (
    <>
      <DeleteConfirmAlert ref={refDeleteEmployeeIdCard} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
      columns={columns}
      records={IdCardinfo}
      bData={bData}
      MasterPageName={MasterPageName}
      AddButtonText={AddButtonText}
      noRecordCss="p-0"
      noRecordFound={
        <NoRecordTemplate
          headerValue={StandardConst.GeneratedEmployeeIDheaderValueNoResults}
          subHeaderValue={StandardConst.subHeaderValueStandard}
          imageUrl={StandardConst.imageNoRecordsFound}
          actionButton={
            <>
              {/* {permission.ManageAdd && ( */}
                <Button id="NoRecordFoundCreateIdCard" variant="outline-primary" onClick={() => fnEdit(0)}>
                  {AddButtonText}
                </Button>
              {/* )} */}
            </>
          }
        />
      }
      onAddEvent={() => fnEdit()}
      // IsAddButtonVisible={permission?.ManageAdd}
      IsAddButtonVisible={true}
      isSearchRequired={true}
      allowSerialNo={true}
      >
      </StaticListComponent>
      <ViewIDCard ref={viewIDCardRef} />
      <GenerateBadge ref={GenerateBadgeRef} SelectedTemplate={selectedIdCard} />
      <AddEmployeeIdCard
      callBackEvent={() => fetchIdCardList()}
      ref={addModalEmployeeRef}>
      </AddEmployeeIdCard>
    </>
  );
};

const GenerateBadge = forwardRef(({SelectedTemplate}, ref) => {
  const [show, setShow] = useState(false);
  const [paramData, setParamData] = useState(false);
  const handleClose = () => setShow(false);
  useImperativeHandle(ref, () => ({
    openModal: async (paramData) => {
      setParamData(paramData);
      setShow(true);
    },
  }));

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="container-fluid"
    >
      <Modal.Header closeButton>
        <Modal.Title>Employee Id Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HtmlFileReader
        jsonData={paramData}
        defaultTemplate={SelectedTemplate[0]} 
        ShowDropdown={0} 
        ShowPrintBadgeBtn={1} 
        ShowDownloadBadgeBtn = {1}
        SelectedTemplateId={SelectedTemplate}
        showDropDownList={StandardConst.BadgeType[1].text}
        />
      </Modal.Body>
    </Modal>
  );
});


export default EmployeeIdCardReport;

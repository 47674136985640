import React, { useEffect, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import "../Static/Static.css";
import AddEditleaveBalance from "./AddEditleaveBalance";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import Button from "react-bootstrap/Button";
import { chain } from "underscore";

const AllLeaveBalanceList = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Allocate Leaves" });
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();
  const [confirmMessage, setconfirmMessage] = useState([]);

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Leave - Allocation"),
      ManageEdit: ActionPermission("Permission - Edit"),
      ManageDelete: ActionPermission("Permission - Delete"),
    });
  }, [permissionList]);

  const fetchLeaveBalanceList = async () => await WebService({ endPoint: "leaveBalance/Fetch", dispatch }).then(setRecords);
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  //#region InIt action
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchLeaveBalanceList();
    }
    renderAfterCalled.current = true;
  }, []);
  //#endregion
  //#region Delete Action
  const onDelete = async (leaveTransactionsId) => {
    const result = await WebService({
      endPoint: `leaveBalance/DeleteAllocatedLeave/${leaveTransactionsId}`,
      dispatch,
      method: "DELETE",
    });
    setconfirmMessage(result.leaveBalance);
    refSnackbar.current.setOpenSnackBar();
    await fetchLeaveBalanceList();
  };
  //#endregion
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  //#region Table Columns
  const columns = [
    { Text: "Employee Name", Value: "employeeName" },
    { Text: "Leave Month", Value: "ValidFormDate", DateFormat: "MMM-yy" },
    { Text: "Leave Type", Value: "LeaveName" },
    { Text: "No. of Leaves", Value: "NoOfLeaveDebited" },
    { Text: "Expiry Date", Value: "ValidToDate", DateFormat: "dd-MMM-yy" },
    {
      Text: "Action",
      key: "leaveTransactionsId",
      cssClass: "text-center td-width-100",
      render: (dr)=>(
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(dr.leaveTransactionsId)
            }
            IconName="Edit"
            id="btnleaveBalanceModelEdit"
          />
          
          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete",
                "Are You Sure",
                "Do you want to delete " + MasterPageName,
                dr.leaveTransactionsId
              )
            }
            IconName="Delete"
            disabled={dr.Consumed===1}
            id="btnleaveBalanceModelDelete"
          />
        </>
      ),
    },
  ];
  //#endregion
  const [bData, setBData] = React.useState([
    {
      title: "Leave Management",
      hrefLink: "#",
    },
    {
      title: "Allocate Leaves",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Allocate Leaves";
  const AddButtonText = "Leaves";

  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={chain(records)
          .sortBy((s) => s.leaveTransactionsId)
          .reverse()
          .value()}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.AllocateLeavesheaderValueNoResults}
            subHeaderValue={StandardConst.AllocateLeavesSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                <Button id="NoRecordFoundAddLeaves" variant="outline-primary" onClick={() => fnEdit(0)}>
                  Add {AddButtonText}
                </Button>
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={true}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditleaveBalance
        callBackEvent={() => fetchLeaveBalanceList()}
        ref={addEditModalRef}
      ></AddEditleaveBalance>
    </>
  );
};

export default AllLeaveBalanceList;

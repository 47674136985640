/* #region Import */
import React, { memo, useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebService } from "../../Services/WebService";
// import "./LogIn.css";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FooterComponent from "../Layout/FooterComponent";
import * as yup from "yup";
import { StandardConst } from "../../Services/StandardConst";
import {
  Form,
  FormAutoCompleteDropdown,
  FormInputDropdown,
  FormInputText,
  InputText,
} from "../Form";
import Button from "react-bootstrap/esm/Button";
import { PageInfo } from "../PageInfo";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { chain, extendOwn } from "underscore";
import { DateTime } from "luxon";
import { Alert, AlertTitle } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CancelIcon from '@mui/icons-material/Cancel';

import { UploadOrClickProfilePicture } from "../../Services/ImageUploader";
import HtmlFileReader from "../../Services/HtmlFileReader";
import { formatCurrentDate } from "../../utils/CommonUtils";
import { addDays } from "date-fns";
/* #endregion */
const VisitorSelfCheckInComponent = () => {
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  PageInfo({ pageTitle: "Self CheckIn - Visitor" });
  const [locationData, setlocationData] = useState();
  const [searchParams] = useSearchParams();
  var dispatch = useDispatch();
  var [state, setState] = useState({});
  var CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);
  const [settings, setSettings] = useState({phone: false, email: false, whomTOMeet: false});
  const [EventsId, setEventsId] = useState(null);
  const [officeLocationId, setOfficeLocationId] = useState(null);
  const FetchVisitorTypeList = async () => {

    const eventQueryParams = searchParams.get("e");
    let EventId = null;
    if(eventQueryParams){
      EventId = window.atob(eventQueryParams);
      setEventsId(EventId);
    }
    var EndPoint = `Visitor/Type?CompanyId=${CompanyId}&OnlySelfServiceVisitorList=${StandardConst.YesNo.Yes}`;
    if(EventId !== null){
      EndPoint = `Event/getEventVisitorDataForDropdown?EventId=${EventId}&OnlySelfServiceVisitorList=${StandardConst.YesNo.Yes}`;
    }

    await WebService({
      dispatch,
      endPoint: EndPoint,
    }).then((visitorTypes) => {
      setState((s) => ({ ...s, visitorTypes }))
    });
  };
  const FetchEmployeeList = async () => {
    let apiUrl = `CompanyProfile/Employees/${(CompanyInfo ?? {}).Code}`
    const officeQueryParams = searchParams.get("l");
      let officeLocationId = null;
      if(officeQueryParams){
        officeLocationId = window.atob(officeQueryParams);
        setOfficeLocationId(officeLocationId);
      }
    const eventQueryParams = searchParams.get("e");
      let EventId = null;
      if(eventQueryParams){
        EventId = window.atob(eventQueryParams);
        setEventsId(EventId);
      }
    if(officeLocationId){
      apiUrl += `?where=OfficeLocationId eq ${officeLocationId}`;
    }
    // to be removed after testing
    // else if(EventId) {
    //   apiUrl += `?where=EventId eq ${EventId}`
    // }
    
    await WebService({
      dispatch,
      endPoint: apiUrl
    }).then((employees) => setState((s) => ({ ...s, employees })));
  };
  useEffect(() => {
    setlocationData(searchParams.get("l") ?? "");
    Promise.all([, FetchVisitorTypeList(), FetchEmployeeList()]);
  }, [CompanyId, searchParams]);

  const [officeLocationData, setOfficeLocationData] = useState([]);

  const fetchOfficeLocation = async() => {
    const fetchOfficeLocationdata = await WebService({
      endPoint: `OfficeLocation/getWithoutLoginOfficeLocationData?OfficeLocationId=${officeLocationId}`,
      dispatch,
    });
    setOfficeLocationData(fetchOfficeLocationdata);
  }

  useEffect(() => {
    fetchOfficeLocation();
  }, [officeLocationId]);

  const [expireThisEvent, setExpireThisEvent] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const fetchEventDetails = async() => {

    if(EventsId !== null) {
      const data = await WebService({
        endPoint: `Event/FetchEventDetail/${EventsId}`,
        dispatch,
      });

      const CurrentDateFormattedDate = new Date().toISOString().split('T')[0];
      var eventToDate = "";

      if(data.length > 0) {
        eventToDate = new Date(data[0]?.EventToDate).toISOString().split('T')[0];
      }

      if(data.length === 0 || eventToDate < CurrentDateFormattedDate){
        setExpireThisEvent(true);
      }
      setEventDetails(data);
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, [EventsId]);

  const [imageName, setImageName] = useState("");
  const [visitorList, setVisitorList] = useState([]);
  const [isAllVisitorOrGuestSave, setIsAllVisitorOrGuestSave] = useState(false);
  const [guestLink, setGuestLink] = useState({});
  const [selectedGuest, setSelectedGuest] = useState({});
  const [defaultFormData, setDefaultFormData] = useState({});
  const [countFilledGuest, setCountFilledGuest] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const resetFunction = () => {
    setGuestLink({});
    setSelectedGuest({});
    setDefaultFormData({});
  };

  useEffect(() => {
    if(Object.keys(selectedGuest).length > 0) {
      setDefaultFormData({VisitorTypeId : selectedGuest.VisitorTypeId, ContactPerson : selectedGuest.ContactPerson, Purpose: selectedGuest.Purpose});
      setIsDataLoaded(true);
    }else{
      setIsDataLoaded(true);
    }
  }, [selectedGuest]);

  useEffect(() => {
    if(countFilledGuest === guestLink?.NumberOfGuest?.length ?? 0) {
      resetFunction();
      setCountFilledGuest(0);
      // this is called here to show success message
      setIsAllVisitorOrGuestSave(true);
    }
  }, [countFilledGuest]);

  const onSubmit = async (dt) => {
    extendOwn(dt, {
      CompanyId,
      ContactPersonForGuest : dt.ContactPerson,
      ContactPerson:
        chain(dt.ContactPerson ?? [])
          .first()
          .value()?.EmployeeId ?? null,
      OfficeLocationId: window.atob(locationData ?? null),
    });
    // dt.ProfilePicture = uploadedFile;
    dt.ProfilePicture = imageName;
    if(EventsId){
      dt.EventId = Number(EventsId);
    }
    const VisitorId = await WebService({
      dispatch,
      endPoint: "Visitor/Create",
      body: dt,
    });
    if(VisitorId > 0 && dt.ContactPerson !== null){
      // await WebService({
      //   dispatch,
      //   endPoint: "Notification/Create",
      //   body: {
      //     Message: `${dt.VisitorName} (Visitor) has Checked In `,
      //     SendTo: [dt.ContactPerson],
      //     Expire: addDays(new Date(), 1),
      //   },
      // });

      await WebService({
        endPoint: `Visitor/WhomToMeetPersonSendEmail?EmployeeId=${dt.ContactPerson}&OfficeLocationId=${dt.OfficeLocationId}`,
        body: {
          VisitorName : dt.VisitorName,
        },
        method: 'POST',
      });
    }

    setVisitorList((prevData) => [...prevData, VisitorId]);

    if(settings.guest && settings.GuestNeedSeparatePass && dt.NumberOfGuest > 0 && Object.keys(guestLink).length === 0) {
      setGuestLink({NumberOfGuest: Array.from({ length: dt.NumberOfGuest }, () => ({ GuestName: "" })), ParentVisitorId: VisitorId, ParentVisitorName : dt.VisitorName, VisitorTypeId : dt.VisitorTypeId, ContactPerson : dt.ContactPersonForGuest, Purpose: dt.Purpose});
    }
    
    if(settings.guest && settings.GuestNeedSeparatePass && dt.NumberOfGuest > 0){

    }else{
      if(Object.keys(selectedGuest).length === 0) {
        // this is called here to show success message
        setIsAllVisitorOrGuestSave(true);
      }
    }

    if(Object.keys(selectedGuest).length > 0) {
      setCountFilledGuest(prev => prev+1);
      if(selectedGuest?.index !== undefined) {
        guestLink.NumberOfGuest[selectedGuest.index].GuestName = dt.VisitorName;
      }
      setSelectedGuest({});
    }
    frmRef.current.fnReset();
    setDefaultFormData({});
  };
  // end of submit function
  
  const validationSchema = yup
    .object()
    .shape({
      VisitorName: yup
        .string()
        .trim()
        .label("Name")
        .required(StandardConst.requiredMessage)
        .max(100),
      VisitorCompany: yup.string().trim().label("Company").max(100),
      Address: yup.string().trim().label("Address").max(300),
      Email: settings.email ? yup.string().trim().label("Email").max(200).email() : null,
      Purpose: yup.string().required(StandardConst.requiredMessage),
      ContactPerson: EventsId ? null : !settings.whomTOMeet ? 
                    yup
                    .array()
                    .of(
                      yup.object().shape({
                        value: yup.string(),
                        label: yup.string(),
                      })
                    )
                    .typeError(StandardConst.requiredMessage)
                    .min(1, StandardConst.requiredMessage)
                    :null,
      VisitorTypeId: yup.string().required(StandardConst.requiredMessage),

      ContactNo: settings.phone ? 
                yup
                .number()
                .label("Contact No")
                .required(StandardConst.requiredMessage)
                .positive()
                .integer()
                .min(1000000000, StandardConst.mobileMessage)
                .max(9999999999, StandardConst.mobileMessage)
                .typeError(StandardConst.requiredMessage)
                :null,
    })
    .required();
  var frmRef = useRef();

  const [uploadedFile, setuploadedFile] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const handleChangeVisitorType = async(visitorTypeId) => {
    const visitorTypeData = await WebService({
      dispatch, 
      // endPoint: `CommonUtility/fetchAll/static_visitor_type?VisitorTypeId=${visitorTypeId}`,
      endPoint: `Visitor/FetchAllVisitorType?visitorTypeId=${visitorTypeId}&EventId=${EventsId}`,
    }).then((c) => (c.length > 0 ? c[0] : {}));

    setSelectedTemplate({ text: visitorTypeData.badge_template_name, value: visitorTypeData.badge_templatesId})
    
    setSettings((prevSettings) => ({
      ...prevSettings,
      phone: prevSettings.phone || visitorTypeData.WillProvidePhoneNo ? visitorTypeData.WillProvidePhoneNo : false,
      email: prevSettings.email || visitorTypeData.WillProvideEmail ? visitorTypeData.WillProvideEmail : false,
      whomTOMeet: prevSettings.whomTOMeet || visitorTypeData.IsServiceProvider ? visitorTypeData.IsServiceProvider : false,
      guest: prevSettings.NumberOfGuest || visitorTypeData.CanBringOtherGuests ? visitorTypeData.CanBringOtherGuests : false,
      GuestNeedSeparatePass: visitorTypeData.GuestPassAlsoNeeded ? visitorTypeData.GuestPassAlsoNeeded : false,
    }));
  }

  if (!isDataLoaded) {
    return <></>;
  }

  return (
    <>
      <section
        className="loginMobile_view p-2 p-md-5"
        style={{ backgroundColor: "#eee" }}
      >
        <div className="row d-flex justify-content-center align-items-center h-100 ">
          <div className="login_card_padding card text-black">
            <div className="container-fluid px-md-5 px-lg-1 px-xl-5">
              <div className="border-0  no-gutter">
                <div className="row mt-md-4">
                  <div className="col-md-2">

                    {((EventsId && eventDetails[0]?.EventLogo) || (CompanyInfo?.Logo )) && (
                      <img
                        src={`${StandardConst.apiBaseUrl}/uploads/${EventsId && eventDetails[0]?.EventLogo ? eventDetails[0]?.EventLogo : (CompanyInfo ?? {})?.Logo ?? ""
                          }`}
                        className="img-responsive img-size rounded mx-auto d-block"
                        alt="logo"
                        placeholder="<i class='icon-search'></i>"
                      />
                    )}
                  </div>
                  <div className="col-md-10">
                    <div className="float-right" style={{ fontSize: "23.64px" }}>
                      <div className="text-center text-md-end fw-bold">Self checkin - Visitor <br /></div>
                      {(eventDetails.length > 0) && (
                        <div className="text-center text-md-end fw-normal">{eventDetails[0].EventName}</div>
                      )}
                      {(officeLocationData.length > 0) && (
                        <>
                          <div className="text-center text-md-end fw-normal">{officeLocationData[0].Location}</div>
                          <div className="row p-0">
                            <div className="col-md-6 p-0"></div>
                            <div className="col-12 col-md-6 p-2 text-center text-md-end fw-normal text-break">
                              {officeLocationData[0].Address}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <hr className="mt-3" />
                <div className="row d-flex">
                  <div className="col-lg-6 d-none d-lg-block">
                    <div className="card1">
                      <div className="row px-3 justify-content-center  mt-5 mb-5 border-line">
                        <img
                          src={`${StandardConst.ProjectImagesInPublicAssets}/vistorSelfCheckin.png`}
                          className="bg-image "
                          alt="loading"
                        />
                      </div>
                    </div>
                  </div>
                  {!isAllVisitorOrGuestSave && (
                    <div className="col-lg-6 px-1 mt-md-4 p-md-2">
                      <div className="mt-xs-2">
                        {(expireThisEvent) && (
                          <Alert
                            severity="error"
                            icon={<CancelIcon fontSize="inherit" />}
                          >
                            <AlertTitle>Event Expired</AlertTitle>
                            {" "}
                            This event has finished{" "}
                            <strong> — Check In is not allowed</strong>
                          </Alert>
                        )}

                        {(!expireThisEvent) && (
                          <Alert
                            severity="info"
                            icon={<AccountCircleIcon fontSize="inherit" />}
                          >
                            <AlertTitle>Visitor CheckIn</AlertTitle>
                            {uploadedFile == null && (
                              <>
                                {" "}
                                Upload Profile Picture{" "}
                                <strong> — Click on upload</strong>
                              </>
                            )}
                            {uploadedFile != null && (
                              <>
                                {" "}
                                Fillup all details —{" "}
                                <strong>Click on Submit</strong>
                              </>
                            )}
                          </Alert>
                        )}
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <Form
                          defaultValues={defaultFormData}
                          onSubmit={onSubmit}
                          ref={frmRef}
                          validationSchema={validationSchema}
                        >
                          <Row className="my-2">
                            <Col className="d-flex justify-content-center">
                              {/* <ProfileImageUpload
                                UploadText={"Profile Image"}
                                UploadedFileName={(fileName) =>
                                  setuploadedFile(fileName)
                                }
                              /> */}
                              <UploadOrClickProfilePicture
                              UploadedFileName={setImageName}
                              >
                              </UploadOrClickProfilePicture>
                            </Col>
                          </Row>

                          <>
                            <Row>

                              {(Object.keys(selectedGuest).length > 0) && (
                                <Col md={12} sm={12}>
                                  {/* <label htmlFor="">Guest of : {selectedGuest?.ParentVisitorName}</label> */}
                                  <InputText
                                    label={"Guest of"}
                                    value={selectedGuest?.ParentVisitorName}
                                    disabled={true}
                                  />
                                </Col>
                              )}

                              <Col md={12} sm={12}>
                                <FormInputDropdown
                                  label="Who are you?"
                                  name="VisitorTypeId"
                                  ddOpt={[{}].concat(
                                    chain(state.visitorTypes ?? [])
                                      .map((m) => ({
                                        value: m.VisitorTypeId,
                                        text: m.TypeName,
                                      }))
                                      .value()
                                  )}
                                  isRequired="true"
                                  setValue={(currentValue) => handleChangeVisitorType(Number(currentValue))}
                                  value={defaultFormData?.VisitorTypeId ?? ""}
                                  disabled={Object.keys(selectedGuest).length > 0}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12}>
                                <FormInputText
                                  label="Your Name"
                                  name="VisitorName"
                                  isRequired="true"
                                />
                              </Col>
                              <Col md={12} sm={12}>
                                <FormInputText
                                  label="Your Company"
                                  name="VisitorCompany"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12}>
                                {" "}
                                {settings.email ? 
                                <FormInputText
                                  label="Your Email"
                                  name="Email"
                                />
                                :
                                null}
                              </Col>
                              <Col>
                                {" "}
                                {settings.phone ?
                                <FormInputText
                                  label="Contact Number"
                                  name="ContactNo"
                                  isRequired="true"
                                />
                                :
                                null}
                              </Col>
                              <Col md={12} sm={12}>
                                <FormInputText
                                  label="Designation"
                                  name="Designation"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12}>
                                <FormInputText
                                  label="Address"
                                  name="Address"
                                  as="textarea"
                                />
                              </Col>
                              <Col md={12} sm={12}>
                                <FormInputText
                                  label="Purpose of Visit"
                                  name="Purpose"
                                  as="textarea"
                                  isRequired="true"
                                  value={defaultFormData?.Purpose ?? ""}
                                  disabled={Object.keys(selectedGuest).length > 0}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12} sm={12}>
                                {
                                  EventsId ? null :
                                  (!settings.whomTOMeet ? 
                                    <FormAutoCompleteDropdown
                                      name="ContactPerson"
                                      data={chain(state?.employees ?? [])
                                        .sortBy((s) => s.FullName)
                                        .value()}
                                      label="Whom To Meet"
                                      optionText="FullName"
                                      isRequired="true"
                                      value={defaultFormData?.ContactPerson !== undefined ? defaultFormData?.ContactPerson ?? "" : ""}
                                      disabled={Object.keys(selectedGuest).length > 0}
                                    />
                                    :
                                    null)
                                }
                              </Col>

                                {(settings.guest && Object.keys(selectedGuest).length === 0) ? 
                                  <Col md={12} sm={12}>
                                      <FormInputText label="Number of guests" name="NumberOfGuest" />
                                  </Col>
                                :
                                null
                                }
                            </Row>

                            <Row>
                              {Object.keys(guestLink).length > 0 && 
                                <div className="col-12 mt-2">
                                  {guestLink.NumberOfGuest.map((guest, index) => {
                                    return <><Button onClick={() => setSelectedGuest({...guestLink, index: Number(index)})}>{guest?.GuestName !== '' ? guest?.GuestName : `Add Your Guest ${index + 1}`}</Button>&nbsp;&nbsp;</>
                                  })}
                                </div>
                              }
                            </Row>

                            <Row className="mt-4 pb-4">
                              <Col className="d-flex justify-content-end">
                                {(!expireThisEvent) && (
                                  <>
                                    <Button
                                      className="mx-2"
                                      type="reset"
                                      onClick={() => frmRef.current.fnReset()}
                                      variant="outline-danger"
                                    >
                                      Reset
                                    </Button>
                                    <Button type="Submit" variant="outline-primary">
                                      Submit
                                    </Button>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </>
                        </Form>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6 px-1 mt-4 p-2">
                    {isAllVisitorOrGuestSave && (
                      visitorList.map((VisitorId) => 
                          <GetVisitorDetails VisitorId={VisitorId} eventId={EventsId} selectedTemplate={selectedTemplate} eventDetailsData={eventDetails} />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </section>
      <br></br>
      <div className="fixed-bottom">
        <FooterComponent />
      </div>
    </>
  );
};

const GetVisitorDetails = ({ VisitorId, selectedTemplate, eventId, eventDetailsData }) => {
  const [visitorDetails, setVisitorDetails] = useState();
  const [ImgUrl, setImgUrl] = useState();
  const CompanyId = useSelector((s) => s.auth.CompanyInfo?.CompanyId ?? 0);
  const dispatch = useDispatch();
  const FetchVisitorDetails = async () => {
    await WebService({
      dispatch,
      endPoint: `Visitor/GetVisitorDetails/${CompanyId}/${VisitorId}`,
    }).then((d) => setVisitorDetails(d[0]));
  };
  useEffect(() => {
    Promise.all([CheckInAction(), FetchVisitorDetails()]);
  }, [VisitorId]);

  const CheckInAction = () => {

    const formattedDate = formatCurrentDate();
    // yaha ye check kr rhe hai ki ye event ka visitor hai ya normal visitor hai agar event ki details ati hai to ye event ka visitor hai
    // const lengthOfEventDetailsData = eventDetailsData.length;
    var CurrentDateFormattedDate = "";
    var EventFromDateOnly = "";
    var EventToDateOnly = "";

    if(eventId !== null) {
      CurrentDateFormattedDate = new Date().toISOString().split('T')[0];
      EventFromDateOnly = new Date(eventDetailsData[0].EventFromDate).toISOString().split('T')[0];
      EventToDateOnly = new Date(eventDetailsData[0].EventToDate).toISOString().split('T')[0];
    }

    // OR oprater ke bad ho condition lagi hai bo jab normal visitor register hoga tab sabhi normal visitor ko 
    if((EventFromDateOnly <= CurrentDateFormattedDate && EventToDateOnly >= CurrentDateFormattedDate) || (eventId === null)){
      WebService({
        dispatch,
        endPoint: `Visitor/CheckIn`,
        body: { VisitorId: VisitorId, CheckInDate: formattedDate },
        method: "PATCH",
      }).then((_) => FetchVisitorDetails());
    }
  }

  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

  const QrData = {
    Name: visitorDetails?.VisitorName,
    Company: visitorDetails?.VisitorCompany,
    Email: visitorDetails?.Email,
    Phone: visitorDetails?.ContactNo,
    // QrValue: "visitorDetails",
    QRData:
      "Visitor Name : " +
      visitorDetails?.VisitorName +
      ", Visitor Type : " +
      visitorDetails?.TypeName +
      ", Meet To : " +
      visitorDetails?.ContactPersonName,
    CheckInDate: DateTime.fromISO(visitorDetails?.CreatedOn).toFormat("dd-MMM-yyyy"),
    CheckInTime: DateTime.fromISO(visitorDetails?.CreatedOn).toFormat(
      "dd-MM-yyyy hh:mm a"
    ),
    VisitorId: visitorDetails?.VisitorId,
    OfficeLocation: visitorDetails?.Address,
    Photo: visitorDetails?.ProfilePicture,
    BadgeColor: StandardConst.Colors.warning,
    VisitorType: visitorDetails?.TypeName,
    CheckIn: visitorDetails?.CheckIn
      ? DateTime.fromISO(visitorDetails?.CheckIn, { zone: 'utc' }).toFormat('dd-MMM-yyyy hh:mm a')
      : "Not CheckedIn",
    Logo: CompanyInfo.Logo,
    VisitorGuid: visitorDetails?.Guid
  };
  return (
    <>
      <div className="d-flex flex-column justify-content-center mt-2">
        <Row className="mt-2 mb-5">
          <Col className="d-flex justify-content-center">
            {/* <QRCodeGeneratorComponent data={QrData} /> */}
            <HtmlFileReader
              jsonData={QrData}
              defaultTemplate={selectedTemplate}
              Message={`${QrData.Name} Checked In @ ${QrData.CheckIn} - Download your badge`}
              MessageAlertTitle={
                eventId ? "Your details are submitted successfully" : "Visitor details submitted successfully"
              }
              ShowDownloadBadgeBtn={1}
              ShowPrintBadgeBtn={1}
              selfVisitorBadge={StandardConst.ShowOrHide.Show}
              companyId={CompanyInfo.CompanyId}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default memo(VisitorSelfCheckInComponent);

import React, { useEffect, useState } from "react";
import "./taxInvoiceStyles.css";
import { StandardConst } from "../../../Services/StandardConst";
import { find } from "underscore";
import { Tooltip } from "@mui/material";
import ActionButton from "../../../Services/ActionButton";
import DatePicker from "react-datepicker"; // Import the date picker component
import "react-datepicker/dist/react-datepicker.css"; // Import the date picker CSS
import { WebService } from "../../../Services/WebService";
import { useDispatch } from "react-redux";
import { inputValidations } from "../../../Services/StandardFunctions";
import CurrencyInput from 'react-currency-input-field';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const DynamicCustomerSection = ({ data, customerDetails, onUpdateData, currencyData, calledInEdit, emptyFields}) => {
    calledInEdit ??= StandardConst.YesNo.No;
    const [selectedDates, setSelectedDates] = useState({});
    const [initialCustomerData, setInitialCustomerData] = useState([{}]);
  
    useEffect(() => {
      if (customerDetails) {
        if(calledInEdit == StandardConst.YesNo.Yes) {
          setInitialCustomerData(customerDetails);
          onUpdateData(customerDetails);
        }else{
          const updatedCustomerDetails = customerDetails.map((item) => ({
            ...item,
            FieldLabel: data.find(dataItem => dataItem.FieldName === item.FieldName).FieldLabel,
            FieldType: data.find(dataItem => dataItem.FieldName === item.FieldName).FieldType,
            OrderNo: data.find(dataItem => dataItem.FieldName === item.FieldName).SectionWiseDisplayOrder,
            FieldIsRequired: data.find(dataItem => dataItem.FieldName === item.FieldName).FieldIsRequired
          }));

          data.forEach((dataObject) => {
            const existsInUpdatedCustomerDetailsArray = updatedCustomerDetails.some((updatedCustomerDetailsObject) => 
            dataObject.FieldName === updatedCustomerDetailsObject.FieldName && dataObject.Section === updatedCustomerDetailsObject.Section
            );

            if(!existsInUpdatedCustomerDetailsArray){
              updatedCustomerDetails.push({
                FieldName : dataObject.FieldName,
                Section : dataObject.Section,
                Value : "",
                FieldLabel : dataObject.FieldLabel,
                FieldType : dataObject.FieldType,
                FieldIsRequired: dataObject.FieldIsRequired,
                OrderNo: dataObject.SectionWiseDisplayOrder
              });
            }
          });

          setInitialCustomerData(updatedCustomerDetails);
          onUpdateData(updatedCustomerDetails);
        }
      }else{
        setInitialCustomerData([{}]);
      }
    }, [customerDetails]);
  
    const handleDateChange = (date, fieldIndex) => {
      const updatedDates = { ...selectedDates };
      updatedDates[fieldIndex] = date;
      setSelectedDates(updatedDates);
  
      const updatedData = [...data];
      updatedData[fieldIndex].FieldValue = date ? date.toISOString() : null;
      onUpdateData(updatedData);
    };
  
    // Placeholder function for handling input changes for non-date fields
    // const handleInputChange = (event, fieldIndex) => {
    //   const updatedData = [...data];
    //   updatedData[fieldIndex].FieldValue = event.target.value;
    //   onUpdateData(updatedData ?? []);
    // };

    const [showWarningMsg, setShowWarningMsg] = useState([]);

    useEffect(() => {
      emptyFields?.map((fieldname) => {
        if(fieldname){
          setShowWarningMsg((prevShowWarningMsg) => ({
            ...prevShowWarningMsg,
            [fieldname]: true,
          }));
        }
      });
    }, [emptyFields]);
  
    const handleInputChange = (fieldValue, fieldName, required) => {
      const updatedData = [...initialCustomerData];
      const index = updatedData.findIndex((item) => item.FieldName === fieldName);

      if(fieldValue === "" || fieldValue === undefined){
        setShowWarningMsg((prevShowWarningMsg) => ({
          ...prevShowWarningMsg,
          [fieldName]: true,
        }));
      }else {
        setShowWarningMsg((prevShowWarningMsg) => ({
          ...prevShowWarningMsg,
          [fieldName]: false,
        }));
      }

      if (index !== -1) {
        updatedData[index].Value = fieldValue;
        updatedData[index].FieldIsRequired = required;
      } else{
        updatedData.push({
          FieldName: fieldName,
          Section: "Customer",
          Value: fieldValue,
          FieldIsRequired: required,
        });
      }
      onUpdateData(updatedData);
    };
  
    return (
      <div className="dynamic-textboxes">
        {data.map((field, index) => {
          const label = field.FieldLabel == null ? field.FieldName : field.FieldLabel;
          const name = field.FieldName;
          const type = field.FieldType == null ? "text" : field.FieldType;
          const section = field.Section;
          const fieldRequired = field.FieldIsRequired;
          if (type === "date") {
            return (
              <div
                key={field.InvoiceCustomFieldId}
                className={`textbox ${
                  section === "Company" ? "right" : "left"
                } date-picker-container`}
              >
                <label htmlFor={name}>{label}</label>
                {field.FieldIsRequired === 1 && <span className="text-danger">＊</span>}
                <DatePicker
                  selected={selectedDates[index]}
                  onChange={(date) => handleDateChange(date, index)}
                  dateFormat="yyyy-MM-dd"
                  autoComplete="off"
                />
              </div>
            );
          } else {
            return (
              <div
                key={field.InvoiceCustomFieldId}
                className={`textbox ${section === "Company" ? "right" : "left"}`}
              >
                <label htmlFor={name}>{label}</label>
                {field.FieldIsRequired === 1 && <span className="text-danger">＊</span>}

                <div className="position-relative">
                  {(type === StandardConst.dataTypes.Currency) && (
                    <CurrencyInput
                      className="text-end"
                      value={
                        find(
                          initialCustomerData,
                          (item) => item.FieldName === field.FieldName
                        )?.Value ?? ""
                      }
                      onValueChange={(value) => handleInputChange(value, name, fieldRequired)}
                      intlConfig={{locale: currencyData?.LocaleCode, currency: currencyData?.CurrencyCode}}
                      allowNegativeValue={false} // Remove if negative values are allowed
                    />
                  )}

                  {(type !== StandardConst.dataTypes.Currency) && (
                    <input
                      type={type === "Number" ? "number" : type}
                      name={name}
                      id={name}
                      required={field.FieldIsRequired === 1}
                      defaultValue={
                        find(
                          initialCustomerData,
                          (item) => item.FieldName === field.FieldName
                        )?.Value ?? ""
                      }
                      onBlur={(event) => handleInputChange(event.target.value, name, fieldRequired)} // Use the placeholder function for non-date fields
                    />
                  )}

                  {/* Here we show Validation msg which is form of tooltip */}
                  <div className={`position-absolute top-50 end-0 translate-middle ${field.FieldIsRequired === 1 && (showWarningMsg[name]) ? "" : "d-none"}`}>
                        <Tooltip
                          arrow
                          disableFocusListener
                          title={StandardConst.ValidationMessages.RequiredFieldMsg}
                        >
                          <i className="fa fa-exclamation-circle me-2" style={{color: "red"}} ></i>
                        </Tooltip>
                  </div>

                </div>
              </div>
            );
          }
        })}
      </div>
    );
};
  
export const DynamicCompanySection = ({ data, companyDetails, onUpdateData, currencyData, calledInEdit, emptyFields}) => {
    calledInEdit ??= StandardConst.YesNo.No;
    const [selectedDates, setSelectedDates] = useState({});
    const [initialCompanyData, setInitialCompanyData] = useState([{}]);
    useEffect(() => {
      if (companyDetails) {
        if(calledInEdit == StandardConst.YesNo.Yes) {
          setInitialCompanyData(companyDetails);
          onUpdateData(companyDetails);
        }else{
          const updatedCompanyDetails = companyDetails.map((item) => ({
            ...item,
            FieldLabel: data.find(dataItem => dataItem.FieldName === item.FieldName).FieldLabel,
            FieldType: data.find(dataItem => dataItem.FieldName === item.FieldName).FieldType,
            OrderNo: data.find(dataItem => dataItem.FieldName === item.FieldName).SectionWiseDisplayOrder,
            FieldIsRequired: data.find(dataItem => dataItem.FieldName === item.FieldName).FieldIsRequired
          }));

          data.forEach((dataObject) => {
            const existsInUpdatedCompanyDetailsArray = updatedCompanyDetails.some((updatedCompanyDetailsObject) => 
            dataObject.FieldName === updatedCompanyDetailsObject.FieldName && dataObject.Section === updatedCompanyDetailsObject.Section
            );

            if(!existsInUpdatedCompanyDetailsArray){
              updatedCompanyDetails.push({
                FieldName : dataObject.FieldName,
                Section : dataObject.Section,
                Value : "",
                FieldLabel : dataObject.FieldLabel,
                FieldType : dataObject.FieldType,
                FieldIsRequired: dataObject.FieldIsRequired,
                OrderNo: dataObject.SectionWiseDisplayOrder
              });
            }
          });

          setInitialCompanyData(updatedCompanyDetails);
          onUpdateData(updatedCompanyDetails);
        }
      }else{
        setInitialCompanyData([{}]);
      }
    }, [companyDetails]);
  
    const handleDateChange = (date, fieldIndex) => {
      const updatedDates = { ...selectedDates };
      updatedDates[fieldIndex] = date;
      setSelectedDates(updatedDates);
  
      const updatedData = [...data];
      updatedData[fieldIndex].FieldValue = date ? date.toISOString() : null;
      onUpdateData(updatedData);
    };

    const [showWarningMsg, setShowWarningMsg] = useState([]);


    useEffect(() => {
      emptyFields?.map((fieldname) => {
        if(fieldname){
          setShowWarningMsg((prevShowWarningMsg) => ({
            ...prevShowWarningMsg,
            [fieldname]: true,
          }));
        }
      });
    }, [emptyFields]);
    
  
  // Placeholder function for handling input changes for non-date fields
  const handleInputChange = (fieldValue, fieldName, required) => {
    const updatedData = [...initialCompanyData];
      const index = updatedData.findIndex((item) => item.FieldName === fieldName);

      if(fieldValue === "" || fieldValue === undefined){
        setShowWarningMsg((prevShowWarningMsg) => ({
          ...prevShowWarningMsg,
          [fieldName]: true,
        }));
      }else {
        setShowWarningMsg((prevShowWarningMsg) => ({
          ...prevShowWarningMsg,
          [fieldName]: false,
        }));
      }

      if (index !== -1) {
        updatedData[index].Value = fieldValue;
        updatedData[index].FieldIsRequired = required;
      } else{
        updatedData.push({
          FieldName: fieldName,
          Section: StandardConst.InvoiceSectionNames.Company,
          Value: fieldValue,
          FieldIsRequired: required,
        });
      }

    onUpdateData(updatedData);
  };
    // const handleInputChange = (event, fieldIndex) => {
    //       const updatedData = [...data];
    //       updatedData[fieldIndex].FieldValue = event.target.value;
    //       onUpdateData(updatedData);
    // };
  
    return (
      <div className="dynamic-textboxes">
        {data.map((field, index) => {
          const fieldLabel = field.FieldLabel == null ? field.FieldName : field.FieldLabel;
          const fieldName = field.FieldName;
          const fieldType = field.FieldType == null ? "text" : field.FieldType;
          const fieldSection = field.Section;
          const fieldRequired = field.FieldIsRequired;
  
          if (fieldType === "date") {
            return (
              <div
                key={field.InvoiceCustomFieldId}
                className={`textbox ${
                  fieldSection === "Company" ? "right" : "left"
                } date-picker-container`}
              >
                <label>{fieldLabel}</label>
                {fieldRequired === 1 && <span className="text-danger">＊</span>}
                <DatePicker
                  selected={selectedDates[index]}
                  onChange={(date) => handleDateChange(date, index)}
                  dateFormat="yyyy-MM-dd"
                  autoComplete="off"
                />
              </div>
            );
          } else {
            return (
              <div
                key={field.InvoiceCustomFieldId}
                className={`textbox ${fieldSection === "Company" ? "right" : "left"}`}
              >
                <label htmlFor={fieldName}>{fieldLabel}</label>
                {fieldRequired === 1 && <span className="text-danger">＊</span>}
                <div className="position-relative">
                  {(fieldType === StandardConst.dataTypes.Currency) && (
                    <CurrencyInput
                      className="text-end"
                      value={
                        find(
                          initialCompanyData,
                          (item) => item.FieldName === field.FieldName
                        )?.Value ?? ""
                      }
                      onValueChange={(value) => handleInputChange(value, fieldName, fieldRequired)}
                      intlConfig={{locale: currencyData?.LocaleCode, currency: currencyData?.CurrencyCode}}
                      allowNegativeValue={false} // Remove if negative values are allowed
                    />
                  )}

                  {(fieldType !== StandardConst.dataTypes.Currency) && (
                    <input
                      type={fieldType === "Number" ? "number" : fieldType}
                      name={fieldName}
                      id={fieldName}
                      required={field.FieldIsRequired === 1}
                      defaultValue={
                        find(
                          initialCompanyData,
                          (item) => item.FieldName === field.FieldName
                        )?.Value ?? ""
                      }
                      onBlur={(event) => handleInputChange(event.target.value, fieldName, fieldRequired)} // Use the placeholder function for non-date fields
                    />
                  )}
                  
                  {/* Here we show Validation msg which is form of tooltip */}
                  <div className={`position-absolute top-50 end-0 translate-middle ${field.FieldIsRequired === 1 && (showWarningMsg[fieldName]) ? "" : "d-none"}`}>
                      <Tooltip
                        arrow
                        disableFocusListener
                        title={StandardConst.ValidationMessages.RequiredFieldMsg}
                      >
                        <i className="fa fa-exclamation-circle me-2" style={{color: "red"}} ></i>
                      </Tooltip>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
};
  
export const DynamicInvoiceDetailSection = ({
    data, // this data is only for details section
    updateJsonData,
    currencyData,
    initialJsonData,
    conditionData,
    formulaData,
    DataBottomSection, // this data is only for Bottom section
    DetailsSectionRowInEdit, // this is called in InvoiceComponent.jsx page 
    updateBottomSectionData, // this update bottom section data to save in database
    emptyFields,
    invoiceSettingId  // this is call for check which setting data we want to use
  }) => {
    const dispatch = useDispatch();
    DetailsSectionRowInEdit??=StandardConst.YesNo.No;

    const [detailsSectionData, setDetailsSectionData] = useState([{}]);
    const [bottomSectionData, setBottomSectionData] = useState([]);
    const [showWarningMsg, setShowWarningMsg] = useState([]);
    const [showWarningMsgInDetail, setShowWarningMsgInDetail] = useState([]);
    const [invoiceSettingIdState, setInvoiceSettingIdState] = useState("");

    useEffect(() => {
        if(invoiceSettingIdState === "" || invoiceSettingIdState !== invoiceSettingId){
          const newBottomData = DataBottomSection.map((DataBottomSectionRow, index) => {
            return {
              Section: DataBottomSectionRow.Section,
              FieldName: DataBottomSectionRow.FieldName,
              FieldLabel: DataBottomSectionRow.FieldLabel,
              FieldType: DataBottomSectionRow.FieldType,
              Value: Number(DataBottomSectionRow.Value ?? 0) == 0 ? "" : Number(DataBottomSectionRow.Value ?? 0),
              OrderNo: (DetailsSectionRowInEdit === StandardConst.YesNo.Yes) ? DataBottomSectionRow.OrderNo : DataBottomSectionRow.SectionWiseDisplayOrder,
              FieldIsRequired: DataBottomSectionRow.FieldIsRequired
            };
          });
          setBottomSectionData(newBottomData);
          setInvoiceSettingIdState(invoiceSettingId);
        }
    }, [invoiceSettingId]);
  
    // This useEffect set detailsSectionData state in initialy 
    useEffect(() => {
      if (initialJsonData) {
        setDetailsSectionData(initialJsonData);
      }else{
        setDetailsSectionData([{}]);
      }
    }, [initialJsonData]);
  
    // if detailsSectionData state change so this use effect set details and bottom data for save these data in database
    useEffect(() => {
      functionForCalculatingFormula();
      handleGetAllRowsData();
    }, [detailsSectionData, formulaData]);

    // if bottomSectionData state change so this use effect set bottom data for save these data in database
    // useEffect(() => {
    //   updateBottomSectionData(bottomSectionData);
    // }, [bottomSectionData]);

    const [arrBottomFieldDisableOrNot, setArrBottomFieldDisableOrNot] = useState({});

    const addDisableTrueOrFalse = (key, value) => {
      if (!arrBottomFieldDisableOrNot.hasOwnProperty(key)) {
        // Key doesn't exist, add the new key-value pair
        setArrBottomFieldDisableOrNot(prevState => ({
          ...prevState,
          [key]: value,
        }));
      }
    };
  
    const GetFieldValue = (formulaDataRow, detailsSectionDataRow, arrCalculatedFields, CalledIn) => {

      var FormulaFieldType = formulaDataRow.LeftSideFieldType;
      var FormulaFieldName = formulaDataRow.EquationLeftSideField;
      if(CalledIn == "RightField") {
        FormulaFieldType = formulaDataRow.RightSideFieldType;
        FormulaFieldName = formulaDataRow.EquationRightSideField;
      }
      var returnValue;
  
      if(FormulaFieldType == StandardConst.FormulaBoxFilledFieldType.CalculatedField){
        returnValue = FormulaFieldName
      }else{
        if(FormulaFieldName in detailsSectionDataRow){
          returnValue = detailsSectionDataRow[FormulaFieldName] ?? 0;
        }else{
          returnValue = (arrCalculatedFields?.find(item => item.FieldName === FormulaFieldName)?.Value) ?? 0;
        }
      }

      return Number(returnValue ?? 0);
    }
  
    const functionForCalculatingFormula = (CalledInBottomInput) => {

      try{
        CalledInBottomInput ??=false;
        let arrCalculatedFields = [...bottomSectionData];
        let AmountSum = 0;

        if(formulaData.length > 0){
          for (const [tableIndex, detailsSectionDataRow] of Object.entries(detailsSectionData)) {
              for(const formulaDataRow of formulaData) {
                addDisableTrueOrFalse(formulaDataRow.DestinationField, true);
                const BottomSectionLabel = DataBottomSection.find(item => item.FieldName === formulaDataRow.DestinationField)?.FieldLabel;
                const BottomSectionType = DataBottomSection.find(item => item.FieldName === formulaDataRow.DestinationField)?.FieldType;
                if(DetailsSectionRowInEdit == StandardConst.YesNo.Yes){
                  var BottomSectionOrderNo = DataBottomSection.find(item => item.FieldName === formulaDataRow.DestinationField)?.OrderNo;
                }else{
                  var BottomSectionOrderNo = DataBottomSection.find(item => item.FieldName === formulaDataRow.DestinationField)?.SectionWiseDisplayOrder;
                }
                const FormulaGeneratorId = formulaDataRow.FormulaGeneratorId;
                var newCondition = '';
                let conditionResult = true;
                // check pre condition
                if(conditionData.length > 0) {
                  const conditionsForGeneratorId = conditionData.filter(item => item.FormulaGeneratorId === FormulaGeneratorId);
                  const conditionLength = conditionsForGeneratorId.length;
                  conditionsForGeneratorId.forEach((conditionData, conditionIndex) => {
                    var conditionRightSideFieldValue;
                    if(conditionData.LeftSideField in detailsSectionDataRow){
                      conditionRightSideFieldValue = (detailsSectionDataRow[conditionData.RightSideField] === undefined) ? conditionData.RightSideField : detailsSectionDataRow[conditionData.RightSideField];

                      if(!isNaN(parseFloat(detailsSectionDataRow[conditionData.LeftSideField])) && !isNaN(parseFloat(conditionRightSideFieldValue))){
                        newCondition += `${detailsSectionDataRow[conditionData.LeftSideField]} ${conditionData.Operator} ${conditionRightSideFieldValue}`;
                      }else if(isNaN(parseFloat(detailsSectionDataRow[conditionData.LeftSideField])) && !isNaN(parseFloat(conditionRightSideFieldValue))){
                        newCondition += `'${detailsSectionDataRow[conditionData.LeftSideField]}' ${conditionData.Operator} ${conditionRightSideFieldValue}`;
                      }else if(!isNaN(parseFloat(detailsSectionDataRow[conditionData.LeftSideField])) && isNaN(parseFloat(conditionRightSideFieldValue))){
                        newCondition += `${detailsSectionDataRow[conditionData.LeftSideField]} ${conditionData.Operator} '${conditionRightSideFieldValue}'`;
                      }else{
                        newCondition += `'${detailsSectionDataRow[conditionData.LeftSideField]}' ${conditionData.Operator} '${conditionRightSideFieldValue}'`;
                      }
                    }else{
                      // Condition ke right side me user koi Value de skta hai jese "Fixed" ya Field ka name bhi de skta hai to ye pata krne ke liye ki ye value hai or isko aise ke aise use krna hai ya ye DB field hai or uske value ko lakr use krna hai isliye pehle hum apne State me field name find kr lete hai agar mil jata hai to uski value nikal lete hai or nahi mila to matlab find ne undified retrun kiya to usko hi value man lete hai
                      conditionRightSideFieldValue = arrCalculatedFields?.find(item => item.FieldName === conditionData.RightSideField)?.Value;
                      if(conditionRightSideFieldValue === undefined){
                        conditionRightSideFieldValue = conditionData.RightSideField;
                      }
                      const bottomObj = arrCalculatedFields?.find(item => item.FieldName === conditionData.LeftSideField) ?? "";
                      if(bottomObj !== ""){
                        if(!isNaN(parseFloat(bottomObj.Value)) && !isNaN(parseFloat(conditionRightSideFieldValue))){
                          newCondition += `${bottomObj.Value} ${conditionData.Operator} ${conditionRightSideFieldValue}`;
                        }else if(isNaN(parseFloat(bottomObj.Value)) && !isNaN(parseFloat(conditionRightSideFieldValue))){
                          newCondition += `'${bottomObj.Value}' ${conditionData.Operator} ${conditionRightSideFieldValue}`;
                        }else if(!isNaN(parseFloat(bottomObj.Value)) && isNaN(parseFloat(conditionRightSideFieldValue))){
                          newCondition += `${bottomObj.Value} ${conditionData.Operator} '${conditionRightSideFieldValue}'`;
                        }else{
                          newCondition += `'${bottomObj.Value}' ${conditionData.Operator} '${conditionRightSideFieldValue}'`;
                        }
                      }
                    }
                
                    if (conditionIndex !== (conditionLength - 1) && newCondition !== '') {
                      if(conditionData.JoinOperator === 'AND') {
                        newCondition += ` && `;
                      }else if(conditionData.JoinOperator === 'OR') {
                        newCondition += ` || `;
                      }
                    }
                  });
                }

                if (newCondition != '') {
                  conditionResult = eval(newCondition);
                }
                if(conditionResult){
                  const EquationLeftSideFieldValue = GetFieldValue(formulaDataRow, detailsSectionDataRow, arrCalculatedFields, "LeftField");
                  const EquationRightSideFieldValue = GetFieldValue(formulaDataRow, detailsSectionDataRow, arrCalculatedFields, "RightField");
                  switch(formulaDataRow.FormulaType){
                    case StandardConst.FormulasType.AssignmentFunction:
                      if(formulaDataRow.Section == StandardConst.InvoiceSectionNames.Details){
                        detailsSectionDataRow[formulaDataRow.DestinationField] = EquationLeftSideFieldValue;
                      }else if(formulaDataRow.Section == StandardConst.InvoiceSectionNames.Bottom && tableIndex == detailsSectionData.length-1){
                        arrCalculatedFields.find(item => item.FieldName === formulaDataRow.DestinationField).Value = EquationLeftSideFieldValue
                        // arrCalculatedFields.push({Section: StandardConst.InvoiceSectionNames.Bottom, FieldName: formulaDataRow.DestinationField, FieldLabel: BottomSectionLabel, FieldType: BottomSectionType, Value : EquationLeftSideFieldValue, OrderNo: BottomSectionOrderNo});
                        // , HtmlTag : formulaDataRow.DestinationFieldHtmlTag, DataType : formulaDataRow.DataType (this is used in arrCalculatedFields array objects but be dont needed this right now)
                      }
                      break;
      
                    case StandardConst.FormulasType.EquationFormula:
                      const equationFormulaValue = eval(`${EquationLeftSideFieldValue} ${formulaDataRow.OperatorOrFunction} ${EquationRightSideFieldValue}`);
                      if(formulaDataRow.Section == StandardConst.InvoiceSectionNames.Details){
                        detailsSectionDataRow[formulaDataRow.DestinationField] = equationFormulaValue;
                      }else if(formulaDataRow.Section == StandardConst.InvoiceSectionNames.Bottom && tableIndex == detailsSectionData.length-1){
                        arrCalculatedFields.find(item => item.FieldName === formulaDataRow.DestinationField).Value = equationFormulaValue
                        // arrCalculatedFields.push({Section: StandardConst.InvoiceSectionNames.Bottom, FieldName: formulaDataRow.DestinationField, FieldLabel: BottomSectionLabel, FieldType: BottomSectionType, Value : equationFormulaValue, OrderNo: BottomSectionOrderNo});
                        // , HtmlTag : formulaDataRow.DestinationFieldHtmlTag, DataType : formulaDataRow.DataType (this is used in arrCalculatedFields array objects but be dont needed this right now)
                      }
                      break;
      
                    case StandardConst.FormulasType.AggregateFunction:
                        if(formulaDataRow.OperatorOrFunction == "SUM") {
                          AmountSum += parseFloat(EquationLeftSideFieldValue);
                          if(formulaDataRow.Section == StandardConst.InvoiceSectionNames.Bottom && tableIndex == detailsSectionData.length-1){
                            arrCalculatedFields.find(item => item.FieldName === formulaDataRow.DestinationField).Value = AmountSum
                            
                            // arrCalculatedFields.push({Section: StandardConst.InvoiceSectionNames.Bottom, FieldName: formulaDataRow.DestinationField, FieldLabel: BottomSectionLabel, FieldType: BottomSectionType, Value : AmountSum, OrderNo: BottomSectionOrderNo});
                              // , HtmlTag : formulaDataRow.DestinationFieldHtmlTag, DataType : formulaDataRow.DataType (this is used in arrCalculatedFields array objects but be dont needed this right now)
                          }
                        }
                      break;
                  }
                }
              }
          }
          
          setBottomSectionData(arrCalculatedFields);
        }
        // updateBottomSectionData function bottom section ke input se call nahi krana hai baki sabhi jgah se call krna hai jaha bhi ye function call hai
        if(!CalledInBottomInput){
          updateBottomSectionData(bottomSectionData);
        }
      }catch (error) {
        console.log(error);
      }
    };

  
    const handleAddRow = () => {
      setDetailsSectionData([...detailsSectionData, {}]);
      setShowWarningMsgInDetail([...showWarningMsgInDetail, {}]);
    };
  
    const handleDeleteRow = async (index, InvoiceDetailId) => {
      var removeFromUI = true;
      if(DetailsSectionRowInEdit == StandardConst.YesNo.Yes && InvoiceDetailId != undefined) {
        await WebService({
          dispatch,
          method: "DELETE",
          endPoint: `Invoice/DeleteInvoiceRecord/${InvoiceDetailId}`,
        }).then((res) => {
            console.log("Invoice deleted successfully ", res);
        }).catch(err => {
          removeFromUI = false;
        });
      }
      if(removeFromUI) {
        const updatedData = [...detailsSectionData];
        updatedData.splice(index, 1);
        setDetailsSectionData(updatedData);

        // this code for show required msg field show or not so here we delete that object 
        const updateShowWarningMsgInDetail = [...showWarningMsgInDetail];
        updateShowWarningMsgInDetail.splice(index, 1);
        setShowWarningMsgInDetail(updateShowWarningMsgInDetail);
      }
    };

    const handleGetAllRowsData = () => {
      const arrShowWarningMsgInDetail  = [...showWarningMsgInDetail];
      const diff = Number(detailsSectionData.length) - Number(arrShowWarningMsgInDetail.length);
      if(diff > 0){
        for (let i = 0; i < diff; i++) {
          arrShowWarningMsgInDetail.push({});
        }
        setShowWarningMsgInDetail(arrShowWarningMsgInDetail);
      }

      const allRowsData = detailsSectionData.map((rowData, index) => {
        const rowDetails = data.map((column, index) => ({
          FieldName: column.FieldName,
          Name: column.FieldLabel,
          Value: rowData[column.FieldName] || "",
          FieldType: column.FieldType,
          OrderNo: index + 1,
          FieldIsRequired: column.FieldIsRequired
        }));
  
        return {
          Section: StandardConst.InvoiceSectionNames.Details,
          FieldName: "DetailsRow-"+(index + 1),
          Value: (index + 1).toString(),
          OrderNo: index + 1,
          Details: rowDetails
        };
      });
      const jsonData = {
        Details: allRowsData,
      };
      updateJsonData(jsonData);
    };

    useEffect(() => {
      if (emptyFields !== undefined) {
        const emptyFieldsForDetails = emptyFields[0]?.DetailSectonValidation.map(item => {
            // Using reduce to convert array of strings to an object
            return item.reduce((acc, key) => {
                acc[key] = true;
                return acc;
            }, {});
        });

        if(emptyFieldsForDetails !== undefined && emptyFieldsForDetails.length > 0){
          setShowWarningMsgInDetail(emptyFieldsForDetails);
        }
        emptyFields?.map((fieldname) => {
          if(fieldname){
            setShowWarningMsg((prevShowWarningMsg) => ({
              ...prevShowWarningMsg,
              [fieldname]: true,
            }));
          }
        });
      }
    }, [emptyFields]);

    const handleBottomInputChange = (FieldValue, fieldName, fieldType, required) => {
        const updatedNewBottomData = [...bottomSectionData];
        const updatedDetailsSectionData = [...detailsSectionData];
        Object.keys(arrBottomFieldDisableOrNot)?.forEach(key => {
          if(updatedNewBottomData.find(item => item.FieldName === key) !== undefined){
            updatedNewBottomData.find(item => item.FieldName === key).Value = "";
          }
          if(updatedDetailsSectionData.find(item => item.FieldName === key) !== undefined){
            detailsSectionData.find(item => item.FieldName === key).Value = "";
            setDetailsSectionData(detailsSectionData);
          }
        });

        const index = updatedNewBottomData.findIndex((item) => item.FieldName === fieldName);
        
        if(FieldValue === ""){
          setShowWarningMsg((prevShowWarningMsg) => ({
            ...prevShowWarningMsg,
            [fieldName]: true,
          }));
        }else {
          setShowWarningMsg((prevShowWarningMsg) => ({
            ...prevShowWarningMsg,
            [fieldName]: false,
          }));
        }

        FieldValue = FieldValue !== undefined ? FieldValue : "";
        FieldValue = inputValidations(fieldType, FieldValue);

        if (index !== -1) {
          updatedNewBottomData[index].Value = FieldValue !== "" ? Number(FieldValue) : "";
          updatedNewBottomData[index].FieldIsRequired = required;
        } else{
          updatedNewBottomData.push({
            Section: StandardConst.InvoiceSectionNames.Bottom,
            FieldName: fieldName,
            FieldLabel: DataBottomSection.find(item => item.FieldName === fieldName)?.FieldLabel,
            FieldType: DataBottomSection.find(item => item.FieldName === fieldName)?.FieldType,
            OrderNo: DataBottomSection.find(item => item.FieldName === fieldName)?.SectionWiseDisplayOrder,
            Value: FieldValue !== "" ? Number(FieldValue) : "",
            FieldIsRequired: required,
          });
        }
       
        setBottomSectionData(updatedNewBottomData);
        functionForCalculatingFormula(true);
        updateBottomSectionData(bottomSectionData);
    };
  
    return (
      <div>
        <table className="mt-4">
          <thead>
            <tr>
              {data.map((column) => (
                <th key={column.InvoiceCustomFieldId}>
                  {column.FieldName}{column.FieldIsRequired === 1 && <span className="text-danger">＊</span>}
                </th>
              ))}
              <th>
                {" "}
                <ActionButton
                  onClick={handleAddRow}
                  IconName="Add"
                  id="btnCalculationComponentAdd"
                  IconTooltip={"Add New Item"}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {detailsSectionData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {data.map((column) => (
                  <td key={column.InvoiceCustomFieldId} className="position-relative">
                    {(column.FieldType === StandardConst.dataTypes.Currency) && (
                      <CurrencyInput
                        className="text-end"
                        value={
                          rowData[column.FieldName] ||
                          (find(rowData?.Details, { Name: column?.FieldName })?.Value ?? '')
                        }
                        onValueChange={(value) => {
                          const updatedRowData = [...detailsSectionData];
                          const updatedNewBottomData = [...bottomSectionData];
                          Object.keys(arrBottomFieldDisableOrNot)?.forEach(key => {
                            if(updatedNewBottomData.find(item => item.FieldName === key) !== undefined){
                              updatedNewBottomData.find(item => item.FieldName === key).Value = "";
                              setBottomSectionData(updatedNewBottomData);
                            }
                            if(updatedRowData.find(item => item.FieldName === key) !== undefined){
                              updatedRowData.find(item => item.FieldName === key).Value = "";
                            }
                          });

                          updatedRowData[rowIndex] = {
                            ...rowData,
                            [column.FieldName]: value,
                          };
                          setDetailsSectionData(updatedRowData);

                          if(showWarningMsgInDetail !== undefined && showWarningMsgInDetail.length > 0) {
                            showWarningMsgInDetail[rowIndex][column.FieldName] = (value === undefined || value === '') ? true : false;
                            setShowWarningMsgInDetail(showWarningMsgInDetail);
                          }
                        }}
                        intlConfig={{locale: currencyData?.LocaleCode, currency: currencyData?.CurrencyCode}}
                        // disabled={arrBottomFieldDisableOrNot[column?.FieldName] ?? false}
                        allowNegativeValue={false} // Remove if negative values are allowed
                        decimalScale={2}
                      />
                    )}

                    {/* Here we show Validation msg which is form of tooltip */}
                    <div className={`position-absolute top-50 end-0 translate-middle ${column?.FieldIsRequired === 1 && (showWarningMsgInDetail !== undefined) && (showWarningMsgInDetail[rowIndex] !== undefined) && (showWarningMsgInDetail[rowIndex].hasOwnProperty(column.FieldName)) && (showWarningMsgInDetail[rowIndex][column.FieldName]) ? "" : "d-none"}`}>
                          <Tooltip
                            arrow
                            disableFocusListener
                            title={StandardConst.ValidationMessages.RequiredFieldMsg}
                          >
                            <i className="fa fa-exclamation-circle me-2" style={{color: "red"}} ></i>
                          </Tooltip>
                    </div>

                    {(column.FieldType !== StandardConst.dataTypes.Currency) && (
                      <input
                        type="text"
                        name={column?.FieldName}
                        id={column?.FieldName}
                        value={
                          rowData[column.FieldName] ||
                          (find(rowData?.Details, { Name: column?.FieldName })
                            ?.Value ??
                            "")
                        }
                        onChange={(e) => {
                          const updatedRowData = [...detailsSectionData];
                          const updatedNewBottomData = [...bottomSectionData];
                          Object.keys(arrBottomFieldDisableOrNot)?.forEach(key => {
                            if(updatedNewBottomData.find(item => item.FieldName === key) !== undefined){
                              updatedNewBottomData.find(item => item.FieldName === key).Value = "";
                              setBottomSectionData(updatedNewBottomData);
                            }
                            if(updatedRowData.find(item => item.FieldName === key) !== undefined){
                              updatedRowData.find(item => item.FieldName === key).Value = "";
                            }
                          });

                          updatedRowData[rowIndex] = {
                            ...rowData,
                            [column.FieldName]: inputValidations(column?.FieldType, e.target.value),
                          };
                          setDetailsSectionData(updatedRowData);

                          if(showWarningMsgInDetail !== undefined && showWarningMsgInDetail.length > 0) {
                            showWarningMsgInDetail[rowIndex][column.FieldName] = (e.target.value === undefined || e.target.value === '') ? true : false;
                            setShowWarningMsgInDetail(showWarningMsgInDetail);
                          }
                        }}
                      />
                    )}
                  </td>
                ))}
                <td>
                  <ActionButton
                    onClick={() => handleDeleteRow(rowIndex, rowData.InvoiceDetailId)}
                    IconName="Delete"
                    disabled={detailsSectionData.length > 1 ? false : true}
                    id="btnCalculationComponentDelete"
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            {DataBottomSection.map((column) => (
              <tr>
                <th colSpan={data.length - 2}></th>
                <th>{column.FieldLabel} {column.FieldIsRequired === 1 && <span className="text-danger">＊</span>}
                </th>
                <td className="position-relative">
                    {(column.FieldType === StandardConst.dataTypes.Currency) && (
                      <CurrencyInput
                        className="text-end"
                        value={
                          bottomSectionData?.find(item => item.FieldName === column?.FieldName)?.Value
                        }
                        onValueChange={(value) => handleBottomInputChange(value, column?.FieldName, column?.FieldType, column?.FieldIsRequired)
                        }
                        intlConfig={{locale: currencyData?.LocaleCode, currency: currencyData?.CurrencyCode}}
                        allowNegativeValue={false} // Remove if negative values are allowed
                        disabled={arrBottomFieldDisableOrNot[column?.FieldName] ?? false}
                        decimalScale={2}
                      />
                    )}

                    {(column.FieldType !== StandardConst.dataTypes.Currency) && (
                      <input 
                        type="text"
                        name={column?.FieldName}
                        id={column?.FieldName}
                        value={
                          bottomSectionData.find(item => item.FieldName === column?.FieldName)?.Value
                        }
                        onChange={(event) => handleBottomInputChange(event.target.value, column?.FieldName, column?.FieldType, column?.FieldIsRequired)}
                      />
                    )}

                    {/* Here we show Validation msg which is form of tooltip */}
                    <div className={`position-absolute top-50 end-0 translate-middle ${column?.FieldIsRequired === 1 && (showWarningMsg[column.FieldName]) ? "" : "d-none"}`}>
                      <Tooltip
                        arrow
                        disableFocusListener
                        title={StandardConst.ValidationMessages.RequiredFieldMsg}
                      >
                        <i className="fa fa-exclamation-circle me-2" style={{color: "red"}} ></i>
                      </Tooltip>
                    </div>
                </td>
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    );
};
import React from "react";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form } from "../Form";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { NoRecordTemplate } from "../../Services/TableComponent";

const { forwardRef, useState, useImperativeHandle } = React;
const ModalChildGroupAndLevel = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [calledAccessGroupName, setCalledAccessGroupName] = useState('');
  const [groupData, setGroupData] = useState([]);

  useImperativeHandle(ref, () => ({
    openModal: async (AccessGroupId, AccessGroupName) => {
      setCalledAccessGroupName(AccessGroupName);
      const groupData = await WebService({
        endPoint: `AccessGroup/getAccessGroupChild?AccessGroupId=${AccessGroupId}`,
        dispatch,
      });
      setGroupData(groupData);
      setShow(true);
    },
  }));
  const handleClose = () => {
    setCalledAccessGroupName('');
    setGroupData([]);
    setShow(false)
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcente"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{calledAccessGroupName} Access</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(groupData[1]?.returnData.length > 0) ? (
          <div dangerouslySetInnerHTML={{ __html: groupData[0]?.htmlElement }} />
        ) : (
          <NoRecordTemplate
            headerValue={"No access assigned"}
            subHeaderValue={"There are no access lavels or access groups assigned in this group."}
            imageUrl={StandardConst.imageNoRecordsFound}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default forwardRef(ModalChildGroupAndLevel);

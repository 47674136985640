import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../Form";
import * as yup from "yup";
import { FormInputText } from "../Form";
import Swal from "sweetalert2";

function MydModalWithGrid(props) {
  const dispatch = useDispatch();
  const CompanyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
  const requiredMessage = "This is required field";
  const schema = yup
    .object()
    .shape({
      Email: yup
        .string()
        .trim()
        .required(requiredMessage)
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Please enter a valid email"
        ),
    })
    .required();
  const successAlert = (res) => {
    Swal.fire({
      position: "top-center",
      icon: res.success == "true" ? "success" : "error",
      text: res.message,
      showConfirmButton: true,
    });
  };
  const submit = async (data) => {
    data.CompanyId = (CompanyInfo ?? {}).CompanyId;
    await WebService({
      endPoint: "LogIn/ForgetPassword",
      body: data,
      dispatch,
    }).then((res) => {
      successAlert(res);
      props.onHide();
    });
  };
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Forgot Password
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={submit} validationSchema={schema}>
        <Modal.Body className="show-grid">
          <div className="col-md-12">
            <FormInputText
              label="Enter Registered Email"
              name="Email"
              type="text"
              isRequired="true"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnMailCancel"
            onClick={props.onHide}
            variant="outline-danger"
          >
            Cancel
          </Button>
          <Button id="btnMailSubmit" type="submit" variant="outline-primary">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default MydModalWithGrid;

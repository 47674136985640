import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditHolidayMaster from "./AddEditHolidayMaster";
import { useDispatch, useSelector } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { useLocation } from "react-router-dom";

const apiUrl = "CommonUtility/holidaymaster";
const HolidayMaster = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Holiday List" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const { state } = useLocation();

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
        ManageAdd: ActionPermission("Holiday - Add"),
        ManageEdit: ActionPermission("Holiday - Edit"),
        ManageDelete: ActionPermission("Holiday - Delete"),
    });
  }, [permissionList]);
  const fetchHolidayMaster = async () => {
    const data = await WebService({
      // endPoint: apiUrl,
      endPoint: `CommonUtility/fetchAll/holidaymaster?CompanyId=${StandardConst.YesNo.Yes}`,
      dispatch,
    });
    setRecords(data);
    dispatch(ConfigurationflowStatusUpdate());
  };

  const onDelete = async (HolidayId) => {
      await WebService({
        endPoint: `${apiUrl}?HolidayId=${HolidayId}`,
        method: "DELETE",
        dispatch,
      });
      refSnackbar.current.setOpenSnackBar();
      await fetchHolidayMaster();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchHolidayMaster();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Date",
      DateFormat: "dd-MM-yyyy",
      Value: "HolidayDate",
    },
    {
      Text: "Day",
      Value: "HolidayWeekDay",
    },
    {
      Text: "Name",
      Value: "HolidayName",
    },
    {
      Text: "Description",
      Value: "HolidaySaka",
    },
    {
      Text: "Comments",
      Value: "HolidayComments",
    },
    {
      Text: "Action",
      cssClass: "text-center td-width-100",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
        <div className="d-flex">
        <ActionButton
            onClick={() => fnEdit(dr.HolidayId)}
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id={`btnHolidayEdit_${dr.HolidayId}`}
          />
          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                dr.HolidayId // Endpoint to hit for delete
              )
            }
            disabled={!permission.ManageDelete}
            IconName="Delete"
            id={`btnHolidayDelete_${dr.HolidayId}`}
          />
        </div>
        </>
      ),
    },
  ];
  const [bData, setBData] = React.useState([
    {
      title: "Company",
      hrefLink: "#",
    },
    {
      title: "Holiday List",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Holiday List";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Holiday";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""} // give sorce page path in this parameter when you use this component the back button will back you to the source page
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.HolidayheaderValueNoResults}
            subHeaderValue={StandardConst.HolidayubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_add_${AddButtonText}`}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditHolidayMaster
        callBackEvent={async () => await fetchHolidayMaster()}
        ref={addEditModalRef}
      ></AddEditHolidayMaster>
    </>
  );
};
export default HolidayMaster;

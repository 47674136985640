import React, { memo, useEffect, useState, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch } from "react-redux";
import TableComponent, {
  NoRecordTemplate,
} from "../../Services/TableComponent";
import { map } from "underscore";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../Services/ActionButton";
import { Avatar, Box, Container } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import StaticListComponent from "../../Services/StaticListComponent";
import { StandardConst } from "../../Services/StandardConst";
import ReactTimeAgo from "react-time-ago";
import { PageInfo } from "../PageInfo";
import DeleteConfirmAlert from "../../Services/AlertComponent";
const CommonNotificationComponent = () => {
  const [state, setState] = useState({});
  const ref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchNotification = async () => {
    await WebService({ dispatch, endPoint: `Notification/ByUser/All` })
      .then((r) =>
        map(r, (m) => {
          if ((m.Route ?? "") !== "") {
            m.Route = `/${m.Route}?search=${window.btoa(m.UniqueRefNo)}`;
          }
          return m;
        })
      )
      .then((dt) => setState((d) => ({ ...d, dt })));
  };
  useEffect(() => {
    Promise.all([fetchNotification()]);
  }, []);
  const read = async (dr) => {
    const process = [];
    if (dr.IsReaded === 0)
      process.push(
        WebService({
          dispatch,
          endPoint: `Notification/MarkRead/${dr.NotificationId}`,
          method: "PUT",
          requiredLoader: false,
        })
      );
    if ((dr.Route ?? "") !== "") {
      process.push(navigate(dr.Route));
    }
    await Promise.all(process);
  };
  const remove = async (dr) => {
    await WebService({
      dispatch,
      endPoint: `Notification/ByUser/${dr.NotificationId}`,
      method: "DELETE",
    }).then((_) => fetchNotification());
  };
  PageInfo({ pageTitle: "Manage Notifications" });
  const MasterPageName = "Manage Notifications";
  const [bData, setBData] = React.useState([
    {
      title: "Notifications",
      hrefLink: "#",
    },
    {
      title: "Manage Notifications",
      hrefLink: "#",
    },
  ]);
  const fnEdit = () => {};
  return (
    <>
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => remove(v)} />
      <StaticListComponent
        records={state.dt}
        allowSerialNo={false}
        ExcluedFieldFromColumns={["From", "Message"]}
        ExcelExportFieldNames={[
          {ExcelColumnHeading: "From", DataColumnName: "CreatedBy"},
          {ExcelColumnHeading: "Message", DataColumnName: "Message"},
          {ExcelColumnHeading: "Notification Creation Date", DataColumnName: "CreatedOn", DataType: StandardConst.dataTypes.DateTime},
        ]}
        columns={[
          {
            Text: "From",
            cssClass: "text-start ps-4",
            render: (dr) => (
              <>
                <div className="row d-flex flex-row ">
                  <div className="col-2">
                    <Avatar
                      alt={dr.CreatedBy}
                      src={`${StandardConst.uploadImages}${
                        dr?.ProfileImage ?? ""
                      }`}
                      sx={{
                        width: 45,
                        height: 45,
                      }}
                    />
                  </div>
                  <div className="col-10">
                    <div className="d-flex flex-column">
                      <span className="ms-2">{dr.CreatedBy}</span>
                      <ReactTimeAgo
                        date={new Date(dr.CreatedOn)}
                        locale="en-US"
                        className="ms-2 font-size-13 text-muted"
                      />
                    </div>
                  </div>
                </div>
              </>
            ),
          },

          {
            Text: "Message",
            render: (dr) => (
              <>
                <div className="d-flex flex-column">
                  <span>{dr.Message}</span>
                  <span className="font-size-13 text-muted">{dr.TypeName}</span>
                </div>
              </>
            ),
          },

          {
            Text: "Action",
            cssClass: "text-end",
            render: (dr) => (
              <>
                <ActionButton
                  onClick={async () => await read(dr)}
                  IconName="View"
                  sx={{ color: "#9A9A9A" }}
                  id="btnView"
                />
                <ActionButton
                  onClick={(e) =>
                    ref.current.confirmAlert(
                      "Delete", //Confirm button text
                      "Are You Sure", // Text if Alert
                      "Do you want to delete Notification", // Message of Alert
                      dr // Endpoint to hit for delete
                    )
                  }
                  IconName="Delete"
                  id="btnDelete"
                />
              </>
            ),
          },
        ]}
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.headerValueNoResults}
            subHeaderValue={StandardConst.subHeaderValueStandard}
            imageUrl={StandardConst.imageNoRecordsFound}
          />
        }
        bData={bData}
        MasterPageName={MasterPageName}
        noRecordCss="p-0"
        isSearchRequired={true}
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={false}
      ></StaticListComponent>
    </>
  );
};

export default memo(CommonNotificationComponent);

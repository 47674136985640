import { DateTime } from "luxon";
import React, {
  forwardRef,
  isValidElement,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useDispatch } from "react-redux";
import _, {
  extend,
  filter,
  find,
  map,
  mapObject,
  omit,
  where,
} from "underscore";
import { InputDropdown } from "../Component/Form";

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  IconButton,
  Container,
  TablePagination,
  Box,
} from "@mui/material";
import AppbarComponent from "./AppbarComponent";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { contentSearch, dataClone } from "./UtilityService";
import { StandardConst } from "./StandardConst";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const pageOptions = [20, 40, 60, 80];
const TableComponent = forwardRef(
  (
    {
      data,
      columns,
      excelExportFileName,
      allowSerialNo,
      noRecordCss,
      onAddEvent,
      IsAddButtonVisible,
      isSearchRequired,
      title,
      toExpend,
      children,
      noRecordFound,
      pageSize,
      isExpandButtonVisiable,
      headerCss,
      tableCss,
      initialSearchContent,
      BackBtnReturnPath,
      isExcelExportButtonVisible,
      hideDefaultAddLabel,
      filterData,
      ExcluedFieldFromColumns,
      ExcelExportFieldNames,
      callBackEvent
    },
    ref
  ) => {
    callBackEvent ??= false;
    ExcluedFieldFromColumns ??= [];
    ExcelExportFieldNames ??= [];
    filterData ??= [];
    hideDefaultAddLabel ??= false;
    isExcelExportButtonVisible ??= true;
    initialSearchContent ??= "";
    headerCss ??= "";
    tableCss ??= "";
    BackBtnReturnPath ??= "";
    isExpandButtonVisiable ??= true;
    pageSize ??= pageOptions[0];
    noRecordFound ??= "Record not found";
    const roundOf = (num, position) => {
      return (
        Math.round((num + Number.EPSILON) * Math.pow(10, position)) /
        Math.pow(10, 2)
      );
    };
    const navigate = useNavigate();
    toExpend ??= (_) => {};
    const isExpendable = isValidElement(children);
    allowSerialNo ??= false;
    noRecordCss ??= "";
    IsAddButtonVisible ??= (onAddEvent || null) !== null;
    title ??= "";
    const groupByInitOpt = { value: "", text: "None" };
    const dispatch = useDispatch();
    const [dataSet, setDataSet] = useState([]);
    const [expeandableItem, setExpeandableItem] = useState({});
    const [groupByOpt, setGroupByOpt] = useState([groupByInitOpt]);
    const [searchContent, setSearchContent] = useState(initialSearchContent);
    const [currentGroupByValue, setCurrentGroupByValue] =
      useState(groupByInitOpt);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);
    const [filterValues, setFilterValues] = useState({});

    const renderAfterCalled = useRef(false);
    useEffect(() => {
      if (!renderAfterCalled.current) {
        GroupbyOptionBinding();
      }
      renderAfterCalled.current = true;
    }, []);
    useEffect(() => {
      setPage(1);
    }, [searchContent, filterValues]);
    useEffect(() => {
      InitDataBinding();
    }, [data, searchContent, page, filterValues]);
    useEffect(() => {
      setExpeandableItem({});
    }, [data]);
    useEffect(() => {
      if (page === 1) InitDataBinding();
    }, [rowsPerPage]);
    const GroupbyOptionBinding = () => {
      var x1 = _.filter(
        columns,
        (f) =>
          (f.IsGroupByFeature ?? false) === true &&
          (f.isVisiable ?? true) === true
      );
      x1 = _.map(x1, (m) => {
        return {
          value: m.Value ?? m.Text,
          text: m.Text,
        };
      });
      setGroupByOpt(groupByOpt.concat(x1));
    };
    const [exportData, setExportData] = useState([]);
    const InitDataBinding = () => {
      var ds = map(data ?? [], (m1, index) => {
        return mapObject(extend(m1, { vartualId: index + 1 }), (val, key) => {
          var result = find(
            columns,
            (f) => (f.Value ?? f.Text) === key && (f.DateFormat ?? "") !== ""
          );
          if (result && val != null)
            val = DateTime.fromISO(val).toFormat(result.DateFormat);
          return val;
        });
      });

      if ((searchContent ?? "") != "") {
        var searchableColumns =
          filter(columns ?? [], (f) => (f?.Searchable ?? true) == true) ?? [];
        searchableColumns =
          map(searchableColumns, (obj) => obj.Value ?? obj.Text) ?? [];
        ds = filter(ds ?? [], (obj) => {
          var a1 = filter(
            searchableColumns,
            (col) => contentSearch(obj[col], searchContent)
            // (obj[col] ?? "")
            //   .toString()
            //   .toLowerCase()
            //   .indexOf((searchContent ?? "").toLowerCase()) > -1
          );
          return a1.length > 0;
        });
      }

      if(Object.keys(filterValues).length > 0) {
        ds = ds.filter(item =>
          Object.keys(filterValues).every(key => {
            // Agar filter value empty hai, to usko ignore karein
            if (filterValues[key] === '') return true;
            // Otherwise, value ko match karein
            return item[key] === filterValues[key];
          })
        );
      }
      setExportData(ds);
      setCountOfTotalRecords(ds.length);
      if (rowsPerPage > 0 && page > 0) {
        var skipValue = rowsPerPage * (page - 1);
        var takeValue = rowsPerPage * page;
        ds = filter(
          map(ds, (r, i) => extend(r, { index: i })),
          (f) => f.index > skipValue - 1 && f.index < takeValue
        );
      }
      setDataSet(ds);
    };
    const GroupBySumation = (ds, col) => {
      switch (col.GroupByResult) {
        case "Summation": {
          return (
            <>
              {_.reduce(
                ds,
                (memo, cur) => roundOf(memo + cur[col.Value ?? col.Text], 2),
                0
              )}
            </>
          );
        }
        default:
          return <></>;
      }
    };
    const [countOfTotalRecords, setCountOfTotalRecords] = useState(0);
    const itemExpendedStatus = (dr) =>
      expeandableItem?.vartualId == dr?.vartualId;
    const [pageSizeOptions, setPageSizeOptions] = useState([]);
    useEffect(() => {
      var options = dataClone(pageOptions);
      if (countOfTotalRecords > 0) {
        options = filter(options, (f) => f <= countOfTotalRecords);
      }
      setPageSizeOptions(options);
    }, [countOfTotalRecords]);
    //#region Expand Event
    const expandClickEvent = (dr) => {
      if (!itemExpendedStatus(dr)) {
        setExpeandableItem(dr);
        toExpend(omit(dr, "vartualId"));
      } else setExpeandableItem({});
    };
    useImperativeHandle(ref, () => ({
      expandClickEvent,
    }));
    //#endregion
    //#region Last page last data delete then page move to previous page
    useEffect(() => {
      setTimeout(() => {
        if (
          (countOfTotalRecords ?? 0) > 0 &&
          (page ?? 1) > 1 &&
          (rowsPerPage ?? 0) > 0
        ) {
          const s1 = countOfTotalRecords % rowsPerPage;
          let s2 = (countOfTotalRecords - s1) / rowsPerPage;
          if (s1 > 0) s2 += 1;
          if (page > s2) setPage(s2);
        }
      });
    }, [countOfTotalRecords, page, rowsPerPage]);
    //#endregion
    const pagination = (
      <TablePagination
        component="div"
        count={countOfTotalRecords}
        page={page - 1}
        onPageChange={(event, newPage) => setPage(newPage + 1)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={pageSizeOptions}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(1);
        }}
      />
    );

    const [orderByUsingArrow, setOrderByUsingArrow] = useState(StandardConst.OrderBy.DESC);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const handleMouseEnterOnTableHead = (e, key) => {
      setHoveredRowIndex(key);
    }

    const handleMouseLeaveOnTableHead = () => {
      setHoveredRowIndex(null);
    }

    const handleUpArrow = (value) => {
      setOrderByUsingArrow(StandardConst.OrderBy.ASC);
      data.sort(function (a, b) {
        const aKeys = Object.keys(a);
        const aValues = Object.values(a);
        let AFieldName = "";
        aKeys.map((akey, index) => {
          if (akey === value) {
            AFieldName = aValues[index];
          }
        });
        const bKeys = Object.keys(b);
        const bValues = Object.values(b);
        let BFieldName = "";
        bKeys.map((bkey, index) => {
          if (bkey === value) {
            BFieldName = bValues[index];
          }
        });
        if (typeof (AFieldName) === "string" && typeof (BFieldName) === "string") {
          var textA = AFieldName?.toUpperCase();
          var textB = BFieldName?.toUpperCase();
          if (textA < textB) {
            return 1;
          }
          if (textA > textB) {
            return -1;
          }
          return 0;
        }
        if (typeof(AFieldName) === "number" && typeof(BFieldName) === "number") {
          return BFieldName - AFieldName;
        }
      });
      InitDataBinding();
    }

    const handleDownArrow = (value) => {
      setOrderByUsingArrow(StandardConst.OrderBy.DESC);
      data.sort(function (a, b) {
        const aKeys = Object.keys(a);
        const aValues = Object.values(a);
        let AFieldName = "";
        aKeys.map((akey, index) => {
          if (akey === value) {
            AFieldName = aValues[index];
          }
        });
        const bKeys = Object.keys(b);
        const bValues = Object.values(b);
        let BFieldName = "";
        bKeys.map((bkey, index) => {
          if (bkey === value) {
            BFieldName = bValues[index];
          }
        });
        if (typeof (AFieldName) === "string") {
          var textA = AFieldName?.toUpperCase();
          var textB = BFieldName?.toUpperCase();
          if (textA < textB) {
            return -1;
          }
          if (textA > textB) {
            return 1;
          }
          return 0;
        }
        if (typeof(AFieldName) === "number"){
          return AFieldName - BFieldName;
        }
      });
      InitDataBinding();
    }
    return (
      <>

        {/* ye condition is liye kr rhe hai ki jisme add button ayega usme blue bar me search or add button dikhega or Excel ka button second row me ayega or agar jisme add button nahi hoga usme search or excel button dono sath me hoga */}
        {(IsAddButtonVisible === true || (onAddEvent || null) !== null) && (
          <>
            <AppbarComponent
              title={title}
              hideDefaultAddLabel={hideDefaultAddLabel}
              isSearchRequired={isSearchRequired}
              isAddButtonRequired={IsAddButtonVisible}
              setSearchContent={(v) => setSearchContent(v ?? "")}
              searchContent={searchContent}
              onAddEvent={onAddEvent}
              exportData={exportData}
              columns={columns}
              excelExportFileName={excelExportFileName}
              isExcelExportButtonVisible={isExcelExportButtonVisible}
              filterData={filterData}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              ExcluedFieldFromColumns={ExcluedFieldFromColumns}
              ExcelExportFieldNames={ExcelExportFieldNames}
              callBackEvent={callBackEvent}
            />
          </>
        )}

        {(IsAddButtonVisible === false && (onAddEvent || null) === null) && (
          <Row className="p-1 m-0">
              <Col className="col-12 p-0">
                  <AppbarComponent
                    title={title}
                    hideDefaultAddLabel={hideDefaultAddLabel}
                    isSearchRequired={isSearchRequired}
                    isAddButtonRequired={IsAddButtonVisible}
                    setSearchContent={(v) => setSearchContent(v ?? "")}
                    searchContent={searchContent}
                    onAddEvent={onAddEvent}
                    exportData={exportData}
                    columns={columns}
                    excelExportFileName={excelExportFileName}
                    isExcelExportButtonVisible={isExcelExportButtonVisible}
                    filterData={filterData}
                    setFilterValues={setFilterValues}
                    filterValues={filterValues}
                    ExcluedFieldFromColumns={ExcluedFieldFromColumns}
                    ExcelExportFieldNames={ExcelExportFieldNames}
                    callBackEvent={callBackEvent}
                  />
              </Col>
          </Row>
        )}

        <Row
          className={`d-flex bg-light mx-1 ${
            (dataSet ?? []).length < 1 ? "d-none" : ""
          }`}
        >
          {_.filter(
            columns,
            (f) =>
              (f.IsGroupByFeature ?? false) === true &&
              (f.isVisiable ?? true) === true
          ).length > 0 && (
            <Col className="col-md-4 my-3 float-start">
              <div className="d-flex align-items-start">
                <InputDropdown
                  ddOpt={groupByOpt}
                  value={currentGroupByValue.value}
                  setValue={(v) =>
                    setCurrentGroupByValue(
                      _.findWhere(groupByOpt, { value: v })
                    )
                  }
                  label="Group By"
                  name="GroupBy"
                />
              </div>
            </Col>
          )}
        </Row>

        <div className="table-responsive p-0" style={{ overflow: "none" }}>
          <Table size="small" className={tableCss}>
            <TableHead
              className={`${headerCss}    ${
                (dataSet ?? []).length < 1 ? "d-none" : ""
              }`}
            >
              <TableRow>
                {isExpendable && isExpandButtonVisiable && (
                  <TableCell></TableCell>
                )}
                {allowSerialNo && (
                  <TableCell
                    sx={{
                      width: "50px",
                      paddingLeft: "25px",
                    }}
                  >
                    #
                  </TableCell>
                )}
                {_.map(
                  _.filter(
                    columns,
                    (f) =>
                      f.Text !== currentGroupByValue.text &&
                      (f.isVisiable ?? true)
                  ),
                  (m, key) => (
                    <React.Fragment key={key}>
                      <TableCell
                        sx={m?.style ?? {}}
                        className={m?.cssClass ?? ""}
                        onMouseEnter={(e) => handleMouseEnterOnTableHead(e, key)}
                        onMouseLeave={() => handleMouseLeaveOnTableHead()}
                      >
                        {m.Text}{" "}
                        {
                          m.Value ?
                            <>
                              <>
                                <ArrowUpwardIcon
                                  fontSize="small"
                                  onClick={() => handleUpArrow(m.Value)}
                                  sx={{visibility: orderByUsingArrow === StandardConst.OrderBy.DESC && hoveredRowIndex === key ? " " : "hidden"}}
                                  color="primary"
                                />
                              </>
                                  <>
                                    <ArrowDownwardIcon
                                      fontSize="small"
                                      onClick={() => handleDownArrow(m.Value)}
                                      color="primary"
                                      sx={{visibility: orderByUsingArrow === StandardConst.OrderBy.ASC && hoveredRowIndex === key ? " " : "hidden"}}
                                    />
                                  </>
                            </>
                            :
                            ""
                        }
                      </TableCell>
                    </React.Fragment>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(
                _.groupBy(dataSet, currentGroupByValue.value),
                (value, key) => {
                  return (
                    <React.Fragment key={key}>
                      {key !== "undefined" && (
                        <TableRow>
                          <TableCell
                            sx={{
                              width: "50px",
                              paddingLeft: "25px",
                            }}
                            colSpan={
                              _.filter(
                                columns,
                                (f) =>
                                  f.Text !== currentGroupByValue.text &&
                                  (f.isVisiable ?? true)
                              ).length +
                              (allowSerialNo ? 1 : 0) +
                              (isExpendable && isExpandButtonVisiable ? 1 : 0)
                            }
                          >
                            <div className="d-flex justify-content-between fw-bold">
                              <div>
                                <span>{currentGroupByValue.text} : </span>
                                <span>{key}</span>
                              </div>
                              {_.map(
                                _.filter(
                                  columns,
                                  (f) => (f.GroupByResult ?? "") !== ""
                                ),
                                (m, key) => {
                                  return (
                                    <div key={key}>
                                      <span>
                                        {m.Text} : </span>
                                      <span>{GroupBySumation(value, m)}</span>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                      {_.map(value, (dr, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            {isExpendable && isExpandButtonVisiable && (
                              <TableCell sx={{ width: "15px", padding: "0px" }}>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => expandClickEvent(dr)}
                                >
                                  {!itemExpendedStatus(dr) && (
                                    <KeyboardArrowRight fontSize="small" />
                                  )}
                                  {itemExpendedStatus(dr) && (
                                    <KeyboardArrowDown fontSize="small" />
                                  )}
                                </IconButton>
                              </TableCell>
                            )}
                            {allowSerialNo && (
                              <TableCell
                                sx={{
                                  width: "50px",
                                  paddingLeft: "25px",
                                }}
                              >
                                {dr.index + 1}
                              </TableCell>
                            )}
                            {_.map(
                              _.filter(
                                columns,
                                (f) =>
                                  f.Text !== currentGroupByValue.text &&
                                  (f.isVisiable ?? true)
                              ),
                              (col, key) => {
                                if ((col.Template || "") !== "") {
                                  return (
                                    <React.Fragment key={key}>
                                    <TableCell
                                      data-key={dr[col.key]}
                                      className={col.cssClass ?? ""}
                                      sx={col?.style ?? {}}
                                    >
                                      {col.Template}
                                    </TableCell>
                                    </React.Fragment>
                                  );
                                } else if (col.render != undefined) {
                                  return (
                                    <React.Fragment key={key}>
                                    <TableCell
                                      sx={col?.style ?? {}}
                                      className={col.cssClass ?? ""}
                                    >
                                      {col.render(dr)}
                                    </TableCell>
                                    </React.Fragment>
                                  );
                                }
                                return (
                                  <React.Fragment key={key}>
                                  <TableCell
                                    sx={col?.style ?? {}}
                                    className={col.cssClass ?? ""}
                                  >
                                    {dr[col.Value ?? col.Text]}{" "}
                                  </TableCell>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </TableRow>
                          {itemExpendedStatus(dr) && (
                            <>
                              <TableRow key={index + 0.1}>
                                <TableCell
                                  style={{ padding: "0 0 5px 0" }}
                                  colSpan={
                                    _.filter(
                                      columns,
                                      (f) =>
                                        f.Text !== currentGroupByValue.text &&
                                        (f.isVisiable ?? true)
                                    ).length +
                                    (allowSerialNo ? 1 : 0) +
                                    (isExpendable ? 1 : 0)
                                  }
                                >
                                  {children}
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  );
                }
              )}

              {(dataSet ?? []).length < 1 && (
                <TableRow>
                  <TableCell
                    sx={{
                      width: "50px",
                      paddingLeft: "25px",
                    }}
                    className={noRecordCss}
                    colSpan={
                      _.filter(
                        columns,
                        (f) =>
                          f.Text !== currentGroupByValue.text &&
                          (f.isVisiable ?? true)
                      ).length +
                      (allowSerialNo ? 1 : 0) +
                      (isExpendable && isExpandButtonVisiable ? 1 : 0)
                    }
                  >
                    {!isValidElement(noRecordFound) && <>{noRecordFound}</>}
                    {isValidElement(noRecordFound) && noRecordFound}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {rowsPerPage > 0 &&
          countOfTotalRecords > 0 &&
          countOfTotalRecords > rowsPerPage &&
          pagination}

        {(BackBtnReturnPath !== "") && (
          <div className="row d-flex justify-content-center">
            <div className="col-12 text-center p-2">
              <Button variant="outline-primary" onClick={() => {navigate(BackBtnReturnPath)}}>
                <ArrowBackIcon /> {(BackBtnReturnPath === StandardConst.ReturnPagePaths.ActionCenter) ? "Back to Action Center" : "Back"}
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
);

export const NoRecordTemplate = memo(
  ({ headerValue, subHeaderValue, imageUrl, actionButton, cssClass}) => {
    headerValue ??= "";
    subHeaderValue ??= "";
    imageUrl ??= "";
    actionButton ??= null;
    cssClass ??= "p-md-5";
    return (
      <>
        <Box
          className={cssClass}
          sx={{
            backgroundColor: "#F8F9FA",
          }}
        >
          <div className="error-template">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div>
                {imageUrl !== "" && (
                  <img
                    src={imageUrl}
                    className="norecord-image"
                    alt="logo"
                  />
                )}
              </div>
              <div>
                <h3>{headerValue}</h3>
                <div className="error-details">
                  {subHeaderValue !== "" && <span>{subHeaderValue}</span>}
                </div>
                <div className="error-actions">
                  {isValidElement(actionButton) && actionButton}
                </div>
              </div>
            </div>
          </div>
        </Box>

        {/* <Container sx={{ height: "200px" }}>
        <h1>{headerValue}</h1>
        {subHeaderValue !== "" && <h2>{subHeaderValue}</h2>}
        {imageUrl !== "" && (
          <img
            src={`${StandardConst.apiBaseUrl}/${imageUrl}`}
            className="logo"
            alt="logo"
          />
        )}
        {isValidElement(actionButton) && actionButton}
      </Container> */}
      </>
    );
  }
);

export default memo(TableComponent);

import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { WebService } from "../../Services/WebService";
import { useDispatch } from "react-redux";
import { NoRecordTemplate } from '../../Services/TableComponent';
import { chunk, map, range } from "underscore";
import BootstrapModal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { StandardConst } from '../../Services/StandardConst';

export const ModalShowImagesInCarousel = forwardRef(({}, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [stateChunk, setStateChunk] = useState([]);
    const [sliderCount, setSliderCount] = useState([]);
    const [data, setData] = useState([]);
    const [dbInfoObj, setDBInfoObj] = useState({});


    useImperativeHandle(ref, () => ({
        // {tableName: 'vehicleinsurancedocuments', fileFildName: 'DocumentPath', compareFildName: 'VehicleInsuranceDetailId', folderPath: 'uploads/VehicleInsuranceDocument' }
        openModal: async (dbInfoObj, id) => {
            setDBInfoObj(dbInfoObj);
            if(id > 0){
                await fetchData(dbInfoObj, id);
            }
            setShow(true);
        },
    }));
  
    const fetchData = async (dbInfoObj, id) => {
      await WebService({
        endPoint: `CommonUtility/Edit/${dbInfoObj.tableName}?${dbInfoObj.compareFildName}=${id}`,
        dispatch
      }).then((r) => {
        setData(
          map(r, (m, index) => {
            m.id = index + 1;
            m.alt = m.FullName;
            if ((m[dbInfoObj.fileFildName] ?? "") !== "")
            m[dbInfoObj.fileFildName] = `${dbInfoObj.folderPath}${m[dbInfoObj.fileFildName]}`;
  
            return m;
          })
        );
        setStateChunk(chunk(r, 3));
        if (parseInt(r.length) % 3 === 0) {
          setSliderCount(range(0, parseInt(r.length) / 3, 1));
        } else {
          var cnt = Math.floor(parseInt(r.length) / 3) + 1;
          setSliderCount(range(0, cnt, 1));
        }
      });
    };

    const handleClose = () => {
        setShow(false);
        setActiveIndex(0);
        setStateChunk([]);
        setSliderCount([]);
        setData([]);
        setDBInfoObj({});
    };

    const handleSelect = (selectedIndex) => {
          setActiveIndex(selectedIndex);
    };
    return (
        <BootstrapModal show={show} onHide={handleClose}>
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>View images</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>
                <div className="card card-cd mb-3">
                    {data.length > 0 && (
                    <>
                        {/* style={{ height: "160px" }} */}
                        <div className="row">
                            <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
                                {data.map((image) => (
                                    <Carousel.Item key={image.id}>
                                        {(image[dbInfoObj.fileFildName].split('.').pop() === "pdf") ? (
                                            <iframe src={image[dbInfoObj.fileFildName]} title="PDF Viewer" style={{ width: "100%", height: "500px" }}></iframe>
                                        ) : (
                                            <img src={image[dbInfoObj.fileFildName]} alt="View Image" />
                                        )}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </>
                    )}
            
                    {data.length <= 0 && (
                    <>
                        <NoRecordTemplate
                            headerValue="No images found"
                            subHeaderValue="Please upload images."
                            imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                        />
                    </>
                    )}
                </div>
            </BootstrapModal.Body>
        </BootstrapModal>
    );
});
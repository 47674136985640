import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { WSSuccessAlert } from "../../Services/WSAlert";

const apiUrl = "CommonUtility/staticleavetypes";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditLeaveType = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ LeaveType: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      setShow(true);
      if(id){
        setShow(false);
        // const data = {
        //   LeaveType: await WebService({
        //     endPoint: `${apiUrl}?where=LeaveId eq ${id || 0}`,
        //     dispatch,
        //   }).then((c) => (c.length > 0 ? c[0] : {})),
        // };
        const data = {
          LeaveType: await WebService({
            endPoint: `CommonUtility/Edit/staticleavetypes?LeaveId=${id || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {})),
        }
        setData(data);
        setShow(true);
      }
    },
  }));
  const handleClose = () => {
    setShow(false);
    setData({ LeaveType: {} });
  }
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      LeaveName: yup.string().trim().required(requiredMessage),
    })
    .required();
  const onSubmit = async (data) => {
    if (data.LeaveId === undefined) {
      await WebService({
        endPoint: apiUrl,
        body: data,
        dispatch,
      }).then(res => {
        WSSuccessAlert("Success", `Leve Type ${data.LeaveName} Added Successfully`);
      });
    } else {
      await WebService({
        endPoint: `${apiUrl}?LeaveId=${data.LeaveId}`,
        method: "PUT",
        body: data,
        dispatch,
      }).then(res => {
        WSSuccessAlert("Success", `Leve Type ${data.LeaveName} updated Successfully`);
      });
    }
    handleClose();
    prop.callBackEvent();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.LeaveType.LeaveId || 0) === 0
            ? "Add Leave Type"
            : "Edit Leave Type"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.LeaveType}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Leave Name"
                name="LeaveName"
                type="text"
                isRequired="true"
              />
            </div>
            <div className="col-md-12">
              <FormInputText
                name="LeaveDescription"
                label="Description"
                as="textarea"
                rows="2"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>          
          <Button id="btnleaveTypeSubmit"  variant="outline-primary" type="submit">  {(data.LeaveType.LeaveId || 0) === 0 ? "Submit" : "Save Changes"}</Button>
        </Modal.Footer>        
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditLeaveType);

import React, { forwardRef, useState, useRef, useImperativeHandle, useSelector, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormAutoCompleteDropdown, FormInputDropdown, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import { chain } from "underscore";
import { AssignAssetComponentModal } from "../ProfileComponent/EditProfileComponent";
import { format } from "date-fns";
import Swal from "sweetalert2";


const EditAssetAssignment = (props, ref) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ AssetAssign: {} });
    const [AssetsList, setAssetsList] = useState([]);
    const [WhomToAssignList, setWhomToAssignList] = useState([]);
    const AssignAssetModelRef = useRef();

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            ExcludeReturnStatus();
            if (id) {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchEditValue = async (id) => {
        const data = {
            AssetAssign: await WebService({
                endPoint: `CommonUtility/Edit/assetassignment?AssetAssignmentId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        if(data.AssetAssign.Status === StandardConst.AssetStatusDropdown[1].value){
            delete data.AssetAssign.Status;
        }
        setData(data);
    }

    const fetchAllEssentialInformation = async () => {
        const data = {
            Assets: await WebService({
                endPoint: `CommonUtility/fetchAll/assets`,
                dispatch,
            }),
            Employees: await WebService({
                endPoint: `CommonUtility/fetchAll/employees`,
                dispatch,
            }),
            Users: await WebService({
                endPoint: `CommonUtility/fetchAll/users`,
                dispatch,
            }),
        }

        const Assets = (data.Assets).map((r) => {
            return { text: r.AssetName, value: r.AssetId }
        })
        setAssetsList(Assets);

        const whomToAssignArray = [...data.Employees, ...data.Users];
        const modifiedArray = whomToAssignArray?.filter(item => item?.FullName !== null);
        setWhomToAssignList(modifiedArray);

    }

    useEffect(() => {
        fetchAllEssentialInformation();
    }, []);

    const handleClose = () => setShow(false);

    const onSubmit = async(data) => {
        const IssueDate = format(new Date(data.IssueDate),"yyyy-MM-dd");
        const ReturnDate = format(new Date(data.ReturnDate),"yyyy-MM-dd");
        data.Status = Number(data.Status);

        if(IssueDate > ReturnDate){
            Swal.fire({
                text: `Your return date should be greater than issue date`,
                icon: "warning"
            });
        }else {
            data.ReturnDate = ReturnDate;
            if(data.AssetAssignmentId){
                await WebService({
                    endPoint: `CommonUtility/assetassignment?AssetAssignmentId=${data.AssetAssignmentId}`,
                    method: "PUT",
                    body: data,
                    dispatch,
                });
                if (data.Status === StandardConst.AssetStatusDropdown[4].value) {
                    data.Status = StandardConst.AssetStatusDropdown[0].value;
                }
                await WebService({
                    endPoint: `CommonUtility/assets?AssetId=${data.AssetId}`,
                    method: "PUT",
                    body: data,
                    dispatch,
                });
                props.callBackEvent();
                handleClose();
            }
        }
    }

    const schema = yup
        .object()
        .shape({
            ReturnDate: yup
                .date()
                .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
                .required(StandardConst.ValidationMessages.RequiredFieldMsg),
            Status: yup.string().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg)
        })
        .required();
    
    const [statusArray, setStatusArray] = useState([]);
    const ExcludeReturnStatus = () => {
        const modifiedStatus = [{}].concat(StandardConst.AssetAssign?.filter(item => item?.value !== StandardConst.AssetAssign[0].value));
        setStatusArray(modifiedStatus);
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Asset Assignment
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.AssetAssign}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                name="ReturnDate"
                                label="Return Date"
                                type="date"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="Status"
                                ddOpt={statusArray}
                                label="Status"
                                isRequired="true"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-primary"
                        id="btnQRCode"
                        className="px-4 float-end"
                        style={{ marginRight: "10px" }}
                        onClick={async () => await AssignAssetModelRef.current.openModal(data.AssetAssign.EmployeeId, data.AssetAssign.UserId)}
                    >
                        Via QR Code
                    </Button>
                    <Button
                        id="btnSubmit"
                        variant="outline-primary"
                        type="submit"
                        disabled={data.AssetAssign.Status === StandardConst.AssetStatusDropdown[4].value}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>
            <AssignAssetComponentModal 
                ref={AssignAssetModelRef} 
                callBackEvent={() => { props.callBackEvent() } } 
            />
        </Modal>
    );
}

export default forwardRef(EditAssetAssignment);
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import _ from "underscore";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditFamilyDetails = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [EmployeeId, setEmployeeId] = useState();
  const [Info, setInfo] = useState({});

  const GenderData = [
    { value: "Male", text: "Male" },
    { value: "Female", text: "Female" },
    { value: "Other", text: "Other" },
  ];
  const BloodGroupData = [
    { value: "A+", text: "A+" },
    { value: "A-", text: "A-" },
    { value: "B+", text: "B+" },
    { value: "B-", text: "B-" },
    { value: "AB+", text: "AB+" },
    { value: "AB-", text: "AB-" },
    { value: "O+", text: "O+" },
    { value: "O-", text: "O-" },
  ];
  useImperativeHandle(ref, () => ({
    openModal: async (id, EmployeeId) => {
      setEmployeeId(EmployeeId);
      if(id > 0) {
        const data = await WebService({
          endPoint: `CommonUtility/Edit/employeefamily?FamilyId=${id}`,
          method: 'GET',
          dispatch,
        });
        if(data.length > 0) {
          setInfo(data[0]);
        }
      }
      setShow(true);
    },
  }));
  const handleClose = () => {
    setShow(false);
    setInfo({});
  };
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      Name: yup.string().trim().required(requiredMessage),
      Relation: yup.string().trim().required(requiredMessage),
      Age: yup
        .number()
        .integer("Age must be an integer")
        .label("Age")
        .typeError("Age must be a number")
        .positive("Please enter a positive number")
        .required(requiredMessage),
      Gender: yup.string().required("Gender Required"),
      BloodGroup: yup.string().required("BloodGroup Required"),
    })
    .required();
  const onSubmit = async (data) => {
    if (data.FamilyId === undefined) {
      await WebService({
        endPoint: `FamilyDetails/Create/${EmployeeId}`,
        body: data,
        dispatch,
      });
    } else {
      await WebService({
        endPoint: `FamilyDetails/Update/${data.FamilyId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }
    handleClose();
    prop.callBackEvent();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(Info.FamilyId || 0) === 0
            ? "Add Family Details"
            : "Edit Family Details"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={Info}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <div>
          <Modal.Body className="p-4">
            <div className="row">
              <div className="col-md-12">
                <FormInputText
                  label="Name"
                  name="Name"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  label="Relationship"
                  name="Relation"
                  type="text"
                  isRequired="true"
                />
              </div>
              <div className="col-md-12">
                <FormInputText
                  label="Age"
                  name="Age"
                  isRequired="true"
                  maxLength="2"
                />
              </div>
              <div className="col-md-12">
                <FormInputDropdown
                  name="Gender"
                  ddOpt={GenderData}
                  label="Gender"
                  isRequired="true"
                ></FormInputDropdown>
              </div>
              <div className="col-md-12">
                <FormInputDropdown
                  name="BloodGroup"
                  ddOpt={BloodGroupData}
                  label="BloodGroup"
                  isRequired="true"
                ></FormInputDropdown>
              </div>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer>          
          <Button id="btnJobFunctionSubmit" variant="outline-primary" type="submit">  {(Info.FamilyId || 0) === 0
                ? "Submit"
                : "Save Changes"}</Button>
        </Modal.Footer>       
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditFamilyDetails);

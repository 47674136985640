import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { map } from "underscore";

const requiredMessage = StandardConst.requiredMessage;
const { forwardRef, useState, useImperativeHandle } = React;

const AddEditConfigurationMaster = (prop, ref) => {
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ ConfigurationMaster: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      const data = {
        ConfigurationMaster: await WebService({
          endPoint: `CommonUtility/configuration_master?where=ConfigurationId eq ${
            id || 0
          }`,
          dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {})),

        users: await WebService({
          endPoint: `CommonUtility/configuration_master`,
          dispatch,
        }).then((c) =>
          map(c, (m) => ({
            value: m.ConfigurationId,
            text: m.ConfigurationName,
          }))
        ),
      };
      setData(data);
      setShow(true);
    },
  }));
  const handleClose = () => setShow(false);
  const schema = yup
    .object()
    .shape({
      ConfigurationName: yup.string().trim().required(requiredMessage),
    })
    .required();
  const onSubmit = async (data) => {
    data.IsRequired = checked == true ? 1 : 0;
    if (data.ConfigurationId === undefined) {
      await WebService({
        endPoint: "CommonUtility/configuration_master",
        body: data,
        dispatch,
      });
    } else {
      await WebService({
        endPoint: `CommonUtility/configuration_master?ConfigurationId=${data.ConfigurationId}`,
        method: "PUT",
        body: data,
        dispatch,
      });
    }
    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="px-2">
          {(data.ConfigurationMaster.ConfigurationId || 0) === 0
            ? "Add Configuration Master"
            : "Edit Configuration Master"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.ConfigurationMaster}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-md-12">
              <FormInputText
                label="Configuration Name"
                name="ConfigurationName"
                type="text"
                isRequired="true"
              />
            </div>
            <div className="col-md-12">
              <FormInputText
                label="Configuration Details"
                name="Details"
                as="textarea"
                rows="2"
              />
            </div>

            <div className="col-md-8 offset-md-4">
              <label
                style={{
                  float: "right",
                  "font-size": "14px",
                  color: "#524c4c",
                }}
              >
                <FormCheckInput
                  name="IsRequired"
                  type="checkbox"
                  defaultChecked={checked}
                  onChange={() => setChecked(!checked)}
                />{" "}
                Is Configuration Master required ?
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>          
          <Button id="btnSubmit" variant="outline-primary" type="submit">  {(data.ConfigurationMaster.ConfigurationId || 0) === 0
                ? "Submit"
                : "Save Changes"}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default forwardRef(AddEditConfigurationMaster);

import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { WebService } from "../../Services/WebService";
import { format } from "date-fns";
import "../Static/Static.css";
import AddEditOfficelocation from "./AddEditOfficelocation";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import { chain } from "underscore";
import Modal from "react-bootstrap/Modal";
import QRCodeGeneratorDownloadComponent from "../../Services/QRCodeGeneratorDownloadComponent";
import { Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AddEditContactForOfficeLocation from "./AddEditContactForOfficeLocation";


const ManageContactsForOfficeLocation = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Manage Contacts for Office Location" });
  const [records, setRecords] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const OfficeLocationId = state?.OfficeLocationId;

  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Office Location Contact - Add"),
      ManageEdit: ActionPermission("Office Location Contact - Edit"),
      ManageDelete: ActionPermission("Office Location Contact - Delete"),
    });
  }, [permissionList]);

  const fetchOfficelocationList = async () =>
    Promise.all([
      WebService({
        endPoint: `CommonUtility/fetchAll/officelocationcontactpoints?OfficeLocationId=${OfficeLocationId}`,
        dispatch,
      })
        .then((ContactsForOfficeLocation) =>{
          setRecords(ContactsForOfficeLocation)
        })
    ]);
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  const GenerateQRRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchOfficelocationList();
    }
    renderAfterCalled.current = true;
  }, []);
  const fnEdit = async (OfficeLocationContactPointId) => await addEditModalRef.current.openModal(OfficeLocationContactPointId || 0, OfficeLocationId);
  const onDelete = async (OfficeLocationContactPointId) => {
    await WebService({
      endPoint: `CommonUtility/officelocationcontactpoints?OfficeLocationContactPointId=${OfficeLocationContactPointId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchOfficelocationList();
  };

  const columns = [
    {
      Text: "Contact Person Name",
      Value: "ContactPersonName",
    },
    {
      Text: "ContactType",
      Value: "ContactType",
    },
    {
      Text: "Contact Numbers",
      Value: "ContactNumbers",
    },
    {
      Text: "Description",
      Value: "Description",
    },
    {
      Text: "Action",
      key: "OfficeLocationId",
      cssClass: "text-center td-width-100",
      isVisiable: permission.ManageEdit,
      render: (dr) => (
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(dr.OfficeLocationContactPointId)
            }
            disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnOfficelocationEdit"
          />
          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + dr.ContactType + " contact from this Office Location", // Message of Alert
                dr.OfficeLocationContactPointId // Endpoint to hit for delete
              )
            }
            disabled={!permission.ManageDelete}
            IconName="Delete"
            id="btnOfficelocationDelete"
          />
        </>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Manage Office Locations",
      hrefLink: "/officelocation",
    },
    {
      title: "Manage Contacts For Office Location",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Manage Contact for Office Location";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Contact for Office Location";
  return (
    <>
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.ManageContactForOfficeLocationheaderValueNoResults}
            subHeaderValue={StandardConst.ManageContactForOfficeLocationSubheaderValueNoResults}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditContactForOfficeLocation
        callBackEvent={() => fetchOfficelocationList()}
        ref={addEditModalRef}
      ></AddEditContactForOfficeLocation>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <GenerateQR ref={GenerateQRRef} />
    </>
  );
};

const GenerateQR = forwardRef(({}, ref) => {
  const [show, setShow] = useState(false);
  const [qrData, setQrData] = useState(false);

  const handleClose = () => setShow(false);
  useImperativeHandle(ref, () => ({
    openModal: async (QrData) => {
      setQrData(QrData);
      setShow(true);
    },
  }));

  return (
    <Modal
      size="xs"
      centered
      show={show}
      onHide={handleClose}
      className="container-fluid"
    >
      <Modal.Header closeButton>
        <Modal.Title>QR Code for office location</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
        <Alert severity="success">
          Download and paste this QR Code at the entrace of your office. Visitor
          can scan this QR Code and to self check in
        </Alert>
        <div className="p-3 m-0">
          <QRCodeGeneratorDownloadComponent data={qrData} />
        </div>
      </Modal.Body>
    </Modal>
  );
});
export default ManageContactsForOfficeLocation;

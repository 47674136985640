import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText, FormInputDropdown, FormAutoCompleteDropdown } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import SweetAlert from "sweetalert2";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import Swal from "sweetalert2";
const { forwardRef, useState, useImperativeHandle } = React;
const AddEditContactForOfficeLocation = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const [data, setData] = useState({ location: {} });
  const [contactDataForOfficeLocation, setContactDataForOfficeLocation] = useState({});
  const [personNameAndEmployeeId, setpersonNameAndEmployeeId] = useState({});
  const [companyEmployeeList, setCompanyEmployeeList] = useState([]);
  const [openModalCount, setOpenModalCount] = useState(0);
  useImperativeHandle(ref, () => ({
    openModal: async (OfficeLocationContactPointId, OfficeLocationId) => {
      setContactDataForOfficeLocation({OfficeLocationId: OfficeLocationId});
      const resEmployeeList = await fetchCompanyEmployeeList();
      if(OfficeLocationContactPointId > 0){
        await WebService({
          endPoint: `CommonUtility/Edit/officelocationcontactpoints?OfficeLocationContactPointId=${OfficeLocationContactPointId}`,
          method: "GET",
          dispatch,
        }).then((contactDetail) => 
        {
          if(contactDetail[0].EmployeeId !== null){
            contactDetail[0].EmployeeId = resEmployeeList.find(employee => employee.EmployeeId === contactDetail[0].EmployeeId)
                                        ? [{ value: contactDetail[0].EmployeeId, text: resEmployeeList.find(employee => employee.EmployeeId === contactDetail[0].EmployeeId).FullName }]
                                        : null;

            console.log(contactDetail);
          }else{
            contactDetail[0].EmployeeId = [contactDetail[0].ContactPersonName]
          }
          setContactDataForOfficeLocation(contactDetail[0]);
        });
      }
      setShow(true);
    },
  }));

  const fetchCompanyEmployeeList = async () => {
    var employeeList = await WebService({
      endPoint: `CommonUtility/employees`,
      dispatch,
    });
    employeeList = employeeList.filter((v) => v.FullName != null);
    setCompanyEmployeeList(employeeList.map((v) => {
      return {
        value: v.EmployeeId,
        text: v.FullName,
      };
    }));
    return employeeList;
  };

  const handleClose = () => {
    setShow(false);
    setContactDataForOfficeLocation({});
    setOpenModalCount(openModalCount + 1);
  };
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      ContactType: yup.string().trim().required(requiredMessage),
      ContactNumbers: yup.string().trim().required(requiredMessage),
    })
    .required();

  const onSubmit = async (data) => {

      if(Object.keys(personNameAndEmployeeId).length > 0) {
        data.ContactPersonName = personNameAndEmployeeId.ContactPersonName;
        data.EmployeeId = personNameAndEmployeeId.EmployeeId;
      }else{
        if (typeof data.EmployeeId[0] === 'object' && data.EmployeeId[0] !== null) {
          data.ContactPersonName =  data.EmployeeId[0]['text'];
          data.EmployeeId = data.EmployeeId[0]['value'];
        } else {
          data.ContactPersonName = data.EmployeeId[0];
          data.EmployeeId = null;
        }
      }
      console.log(data);

      if(data.OfficeLocationContactPointId !== undefined && data.OfficeLocationContactPointId > 0) {
        const insertResponseData = await WebService({
          endPoint: `CommonUtility/officelocationcontactpoints?OfficeLocationContactPointId=${data.OfficeLocationContactPointId}`,
          method: "PUT",
          body: data,
          dispatch,
        });
      }else{
        const updateResponseData = await WebService({
          endPoint: `CommonUtility/officelocationcontactpoints`,
          method: "POST",
          body: data,
          dispatch,
        });
      }
      
      // WSSuccessAlert("Office Location", responsedata);
      handleClose();
      prop.callBackEvent();
  };

  const handleEmployeeData = (selectedEmployee) => {
      console.log("selected Employee => ", selectedEmployee);

      if (typeof selectedEmployee[0] === 'object' && selectedEmployee[0] !== null) {
        setpersonNameAndEmployeeId((prevObject) => ({
          ...prevObject,
          ['ContactPersonName']: selectedEmployee[0]['text'],
          ['EmployeeId']: selectedEmployee[0]['value']
        }));
      } else {
        setpersonNameAndEmployeeId((prevObject) => ({
          ...prevObject,
          ['ContactPersonName']: selectedEmployee[0],
          ['EmployeeId']: null,
        }));
      }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(contactDataForOfficeLocation?.OfficeLocationContactPointId || 0) === 0
            ? "Add Contact For Office Location"
            : "Edit Contact For Office Location"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={contactDataForOfficeLocation}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row mb-2">
            <FormInputText
              name="ContactType"
              label="Contact Type"
              type="text"
              isRequired="true"
            />
          </div>
          <div className="row mb-2">
            <FormInputText
              name="ContactNumbers"
              label="Contact Numbers"
              type="text"
              isRequired="true"
            />
          </div>

          <div className="row mb-2">
            <FormAutoCompleteDropdown
                name="EmployeeId"
                data={companyEmployeeList}
                optionText="text"
                label="Contact Person Name"
                setValue={(val) => (
                    handleEmployeeData(val)
                )}
                // value={contactDataForOfficeLocation?.EmployeeId}
                freeText={true}
                autoSelectAttributeValue={true}
            />
          </div>

          <div className="row mb-2">
            <FormInputText
              label="Description"
              name="Description"
              type="text"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnlocationSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(contactDataForOfficeLocation.OfficeLocationContactPointId || 0) === 0
              ? "Submit"
              : "Save Changes"}{" "}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditContactForOfficeLocation);

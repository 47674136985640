import React from "react";
import { useSelector } from "react-redux";
import _ from "underscore";
import { store } from "../Services/Store";
const { useEffect } = React;
export const PageInfo = ({ pageTitle }) => {
  useEffect(() => {
    if (pageTitle !== null) document.title = `Wise: ${pageTitle ?? ""}`;
    else document.title = "Wise";
  }, [pageTitle]);
};
export const ActionPermission = (systemCode) => {
  // const permission = useSelector((s) => s.auth.PermissionList??[]);
  // const permissionCode = useSelector((s) => s.auth.PermissionCodeList??[]);
  const arrpermission = store.getState().auth.PermissionList;
  const permissionCode = store.getState().auth.PermissionCodeList ?? [];
  const permissioncheckData=(_.findWhere(arrpermission ?? [], {
    Code: _.findWhere(permissionCode ?? [], { SystemCode: systemCode })
      ?.DBCode,
  })?.PermissionId||null) !== null;

  // old code of response is here 
  // const res =  (_.findWhere(permission ?? [], {
  //   Code: _.findWhere(permissionCode ?? [], { SystemCode: systemCode })
  //     ?.DBCode,
  // })?.PermissionId||null) !== null;


  // const res = _.find(arrpermission, { Code: _.get(_.find(permissionCode, { SystemCode: systemCode }), 'DBCode') })?.PermissionId !== null;

  const res = !!_.find(arrpermission, { Permission  : systemCode });

  return res;
};

import React, { useEffect } from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import "./FlowComponent.css";
import { Box } from "@mui/material";
export const FlowDashboardButton = ({
  headerValue,
  headerColor,
  headerIcon,
  subHeaderValue,
  totalPendingAction,
  imageUrl,
  actionButton,
}) => {
  headerValue ??= "";
  headerIcon ??= "";
  totalPendingAction ??= 0;
  headerColor ??= "primary";
  subHeaderValue ??= "";
  imageUrl ??= "";
  actionButton ??= null;
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 0,
      top: 10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "5px 5px",
    },
  }));
  return (
    <>
      <div className="col-md-4">
        <div
          className={`card card-cd mb-3 border-0 card-left-border-${headerColor}`}
        >
          <div className="card-body m-0">
            <div className="card-title d-flex justify-content-between">
              <h4
                style={{ fontSize: "17px" }}
                className={`text-${headerColor} p-0 m-0`}
              >
                <i
                  className={`fa fa-${headerIcon == "" ? "" : headerIcon}`}
                ></i>
                {headerValue}
              </h4>
              <StyledBadge
                badgeContent={totalPendingAction}
                color="secondary"
              ></StyledBadge>
            </div>
            <div className="col p-0 m-0">
              <h6 className="card-subtitle text-muted">
                <span className="card-text text-muted small ">
                  {subHeaderValue !== "" && <span>{subHeaderValue}</span>}
                </span>
              </h6>
            </div>
          </div>

          <div className="card-footer bg-white px-0 m-1">
            <div className="row">
              <div
                className="d-flex justify-content-between"
                style={{ fontSize: "13px" }}
              >
                {actionButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlowDashboardButton;

import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/Button";
import { Form, FormAutoCompleteDropdownCard, FormInputText } from "../Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import * as yup from "yup";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import _, { map, sortBy } from "underscore";
import TableComponent from "../../Services/TableComponent";
import { ActionPermission, PageInfo } from "../PageInfo";
import { Box } from "@mui/material";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";

import { StandardConst } from "../../Services/StandardConst";
import { roundOff } from "../../Services/UtilityService";
import ActionButton from "../../Services/ActionButton";
import { WSSuccessAlert } from "../../Services/WSAlert";

const TimesheetVsLeaveReport = () => {
    const MasterPageName = "Timesheet Vs Leave Report";
    PageInfo({ pageTitle: "Timesheet Vs Leave Report" });
    const dispatch = useDispatch();

    const [bData, setBData] = React.useState([
        {
        title: "Report",
        hrefLink: "#",
        },
        {
        title: "Timesheet Vs Leave Report",
        hrefLink: "#",
        },
    ]);
    const [persons, setPersons] = useState([]);

    const [timesheetVsLeaveData, setTimesheetVsLeaveData] = useState([]);
    const [fromdate, setFromdate] = useState(null);
    const [todate, setTodate] = useState(null);
    const loggedUser = useSelector((s) => s.auth.LoggedUser ?? 0);
    const childUser = useSelector((s) => s.auth.ChildUsers ?? []);

    // Here all person List(LoggedIn person company Apprroved Employees List)
    const fetchPersonList = async () => {
        var opt = {
          endPoint: `Visitor/fetchAllEmployees`,
        //   dispatch,
        };
    
        let res = await WebService(opt)
          .then((r) => sortBy(r, "FullName"))
          .then((r) =>
            map(r, (m) => ({
              EmployeeId: m.EmployeeId,
              heading: m.FullName, 
              description: m.Designation, 
              avatar: `${StandardConst.apiBaseUrl}/uploads/${m.ProfileImage}`,
              text: m.FullName,
              value: m.EmployeeId
            }))
          );

        // const manageSearchAllUsers = ActionPermission("Attendance Report - Search All Users");
        // if (!manageSearchAllUsers) {
          const listOfUser = childUser.concat([loggedUser]);
          res = res.filter(
            (f) => listOfUser.filter((u) => u === f.value).length > 0
          );
        // }
        setPersons([{ EmployeeId: 0, heading: "All Person" }].concat(res));
    };

    const [filter, setFilter] = useState({
        FromDate: DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
        ToDate: DateTime.local().endOf('month').toFormat("yyyy-MM-dd"),
        EmployeeId: [{ EmployeeId: 0, heading: "All" }]
    });

    const fetchTimesheetVsLeaveReport = async (filter) => {
        filter ??= {
            FromDate: DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
            ToDate: DateTime.local().endOf('month').toFormat("yyyy-MM-dd"),
            EmployeeId: [{ EmployeeId: 0, heading: "All" }]
        };

        const Data = await WebService({
            endPoint: `Attendance/TimesheetVsLeaveReport?FromDate='${filter.FromDate}'&ToDate='${filter.ToDate}'&EmployeeId=${filter.EmployeeId[0].EmployeeId}`,
            method: 'GET',
            dispatch,
        });
        setTimesheetVsLeaveData(Data);
    }

    const onSubmit = (rec) => {
        setFilter(rec);
    };

    useEffect(() => {
        fetchPersonList();
    }, [loggedUser, childUser]);

    const sendMailToEmployee = async (data) => {
        await WebService({
            endPoint: `Attendance/SendEmailToEmployeeForTimesheetVsLeaveReport`,
            body: data,
            method: 'POST',
            dispatch,
        }).then(res => {
            if(res === "Success") {
                WSSuccessAlert("Success", "Mail successfully sent to employee")
            }
        })
    };

    const columns = [
        {
          Text: "Date",
          Value: "Date",
          DateFormat: "yyyy-MM-dd",
        },
        {
            Text: "Employee Name",
            Value: "EmployeeName",
        },
        {
            Text: "Timesheet Details",
            render: (dr) => {
                return <>{dr.Details.replace(/(<([^>]+)>)/gi, "")}</>
            }
        },
        {
            Text: "Action",
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => sendMailToEmployee(dr)}
                        // disabled={!permission.ManageEdit}
                        IconName="Mail"
                        IconTooltip={"Email Employee to correct the discrepancy"}
                    />
                </>
            )
        }
    ];

    return (
        <>
        <Container fluid className="base-container">
            <Box
            sx={{
                width: 1,
            }}
            >
            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
            <div className="ms-4">
                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
            </div>
            </Box>
                <TableComponent
                    ExcluedFieldFromColumns={["Details"]}
                    ExcelExportFieldNames={[
                        {ExcelColumnHeading: "Timesheet Details", DataColumnName: "Details"}
                    ]}
                    columns={columns}
                    data={timesheetVsLeaveData}
                    IsAddButtonVisible={false}
                    excelExportFileName={"TimesheetVsLeaveReport"}
                    noRecordCss="p-0"
                    noRecordFound={
                        <NoRecordTemplate
                        headerValue={StandardConst.AttendanceReportheaderValueNoResults}
                        subHeaderValue={StandardConst.AttendanceReportSubHeaderRole}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        // actionButton={
                        //     <>
                        //     <Button variant="outline-primary" onClick={filterChange}>
                        //         Change Filters
                        //     </Button>
                        //     </>
                        // }
                        />
                    }
                    allowSerialNo={true}
                    isSearchRequired={true}
                    filterData={[
                      {
                        FieldName: "FromDate",
                        DefaultDate : DateTime.local().startOf('month'),
                        FilterType: "DATE",
                        FilterPlaceholder: "Enter From Date",
                      },
                      {
                        FieldName: "ToDate",
                        DefaultDate: DateTime.local().endOf('month'),
                        FilterType: "DATE",
                        FilterPlaceholder: "Enter To Date",
                      },
                      {
                        FieldName: "EmployeeId",
                        FilterType: "AUTOCOMPLETE",
                        // DefaultAllValuesText: "All Persons",
                        OptionText: "heading",
                        // DefaultSelected: [{text: "Rejected", value: "Rejected"}],
                        FilterData: persons
                      }
                  ]}  
                  callBackEvent={fetchTimesheetVsLeaveReport}
                />
        </Container>
        </>
    );
};

export default TimesheetVsLeaveReport;

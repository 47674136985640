import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";
import AddEditManageSubscription from './AddEditManageSubscription';
import { useNavigate } from 'react-router-dom';

const ManageSubscription = () => {

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    PageInfo({ pageTitle: "Manage Subscription" });
    const navigate = useNavigate();

    useEffect(() => {
        SetPermission({
            ManageAdd: ActionPermission("Manage Subscription - Add"),
            ManageEdit: ActionPermission("Manage Subscription - Edit"),
            ManageDelete: ActionPermission("Manage Subscription - Delete"),
        });
    }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Manage Subscription";
    const addEditModalRef = useRef();

    const AddButtonText = "Manage Subscription";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);

    const fetchAllSubscriptions = async() => {
        const data = await WebService({
            endPoint: `Subscription/ManageSubscriptions`,
            dispatch,
        });

        const modifiedArray = await Promise.all(data.map(async(res) => {
            const DefaultName = StandardConst.YesOrNo.find((r) => r.value === res.IsDefault);
            res.IsDefault = DefaultName.text;

            const Status = StandardConst.StatusDropdown.find((r) => r.value === res.Status);
            res.Status = Status.text;
            if(res.CountryId === null){
                res.CountryName = StandardConst.AllCountries;
            }
            return res;
        }));
        setRecords(modifiedArray);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchAllSubscriptions();
    }, []);

    const [bData,] = React.useState([
        {
            title: "Master",
            hrefLink: "#",
        },
        {
            title: "Manage Subscription",
            hrefLink: "#",
        },
    ]);

    const onDelete = async (Details) => {
        const data = Details.split(',');
        const SubscriptionId = data[0];
        setDeletedName(data[1]);
        await WebService({
            endPoint: `CommonUtility/subscription?SubscriptionId=${SubscriptionId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAllSubscriptions();
    };

    const columns = [
        {
            Text: "Name",
            Value: "Name"
        },
        {
            Text: "Country",
            Value: "CountryName"
        },
        {
            Text: "Currency",
            Value: "Symbol"
        },
        {
            Text: "Validity From Date",
            Value: "ValidityFromDate",
            DateFormat: "dd-MM-yyyy",
        },
        {
            Text: "Validity To Date",
            Value: "ValidityToDate",
            DateFormat: "dd-MM-yyyy",
        },
        {
            Text: "SubscriptionType",
            Value: "SubscriptionTypeName"
        },
        {
            Text: "Is Default",
            Value: "IsDefault"
        },
        {
            Text: "Status",
            Value: "Status"
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            isVisiable: permission.ManageEdit || permission.ManageDelete,
            render: (dr) => (
                <>
                    <ActionButton
                        onClick={() => fnEdit(dr.SubscriptionId)}
                        disabled={!permission.ManageEdit}
                        IconName="Edit"
                        IconTooltip={"Edit Subscription"}
                        id={`btnEdit_${dr.SubscriptionId}`}
                    />
                    <ActionButton
                        onClick={(e) =>
                            ref.current.confirmAlert(
                                "Delete", //Confirm button text
                                "Are You Sure", // Text if Alert
                                "Do you want to delete " + dr.Name, // Message of Alert
                                `${dr.SubscriptionId},${dr.Name}`  // Endpoint to hit for delete
                            )}
                        disabled={!permission.ManageDelete}
                        IconName="Delete"
                        IconTooltip={"Delete Subscription"}
                        id={`btnDelete_${dr.SubscriptionId}`}
                    />
                </>
            ),
        },
    ];

    const fnEdit = async (id) => navigate("/AddEditManageSubscription", { state: { SubscriptionId: id}});
    
    return (
        <>
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Subscription ${(deletedName !== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={"Subscription"}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.ManageSubcriptionheaderValueNoResults}
                        subHeaderValue={StandardConst.ManageSubcriptionSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                        actionButton={
                            <>
                                {permission.ManageAdd && (
                                    <Button variant="outline-primary" onClick={() => fnEdit(0)} id={`no_record_${AddButtonText}`}>
                                        Add {AddButtonText}
                                    </Button>
                                )}
                            </>
                        }
                    />
                }
                onAddEvent={() => fnEdit()}
                IsAddButtonVisible={permission?.ManageAdd}
                isSearchRequired={true}
                allowSerialNo={true}
            ></StaticListComponent>
        </>
    )
}

export default ManageSubscription
import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import AddEditTask from "./AddEditTaskCategory";
import { useDispatch } from "react-redux";
import "./Static.css";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import "../Static/Static.css";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import Button from "react-bootstrap/esm/Button";
import { generateQueryStringByObject } from "../../Services/UtilityService";
import { useLocation } from "react-router-dom";

const Task = () => {
  const { state } = useLocation();
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Task List for Project" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const fetchTask = () =>
    Promise.all([
      WebService({
        endPoint: `CommonUtility/project_tasks?${generateQueryStringByObject({
          select:
            "projectmaster.ProjectName,TaskCategoryId,TaskCategoryName,tasktype.DisplayDescription",
          expand: "tasktype,projectmaster",
        })}`,
        dispatch,
      }).then(setRecords),
      dispatch(ConfigurationflowStatusUpdate()),
    ]);

  const onDelete = async (TaskCategoryId) => {
    await WebService({
      endPoint: `CommonUtility/project_tasks?TaskCategoryId=${TaskCategoryId}`,
      method: "DELETE",
      dispatch,
    }).then(_=>fetchTask());
    refSnackbar.current.setOpenSnackBar();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchTask();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    { Text: "Project", Value: "ProjectName" },
    { Text: "Task Name", Value: "TaskCategoryName" },
    { Text: "Type", Value: "DisplayDescription" },
    {
      Text: "Action",
      key: "TaskCategoryId",
      cssClass: "text-center td-width-100",
      //isVisiable: permission.ManageEdit || permission.ManageDelete,
      Template: (
        <>
          <ActionButton
            onClick={(e) =>
              fnEdit(e.currentTarget.parentElement.parentElement.getAttribute("data-key"))
            }
            // disabled={!permission.ManageEdit}
            IconName="Edit"
            id="btnTaskEdit"
          />

          <ActionButton
            onClick={(e) =>
              ref.current.confirmAlert(
                "Delete", //Confirm button text
                "Are You Sure", // Text if Alert
                "Do you want to delete " + MasterPageName, // Message of Alert
                e.currentTarget.parentElement.parentElement.getAttribute("data-key") // Endpoint to hit for delete
              )
            }
            // disabled={!permission.ManageDelete}
            IconName="Delete"
            id="btnTaskDelete"
          />
        </>
      ),
    },
  ];
  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  const [bData, setBData] = React.useState([
    {
      title: "Time Sheet",
      hrefLink: "#",
    },
    {
      title: "Task List for Project",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Task List for Project";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Project TimeSheet Tasks";

  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        BackBtnReturnPath={(state?.SourcePageName === StandardConst.ReturnPagePaths.ActionCenter) ? StandardConst.ReturnPagePaths.ActionCenter : ""}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.TeamTaskheaderValueNoResults}
            subHeaderValue={StandardConst.TeamTaskSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                
                  <Button id="NoRecordFoundAddProjectTimeSheetTask" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
               
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={true}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditTask
        callBackEvent={() => fetchTask()}
        ref={addEditModalRef}
      ></AddEditTask>
    </>
  );
};
export default Task;

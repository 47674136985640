import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../Services/WebService";
import { Form, FormInputText } from "../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { StandardConst } from "../../Services/StandardConst";
const { forwardRef, useState, useImperativeHandle } = React;

const AddEditSubscriptionFeature = (props, ref) => {
    
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ SubscriptionFeatures: {} });

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            if (id === 0) {
                setData({ SubscriptionFeatures: {} })
            } else {
                setShow(false);
                const data = {
                    SubscriptionFeatures: await WebService({
                        endPoint: `CommonUtility/Edit/subscription_features?SubscriptionFeatureId=${id || 0}`,
                        dispatch,
                    }).then((c) => (c.length > 0 ? c[0] : {}))
                }
                setData(data);
                setShow(true);
            }
        },
    }));

    const handleClose = () => setShow(false);

    const schema = yup
        .object()
        .shape({
            Feature: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        data.SubscriptionId = props.SubscriptionId
        if (data.SubscriptionFeatureId === undefined) {
            await WebService({
                endPoint: "CommonUtility/subscription_features",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/subscription_features?SubscriptionFeatureId=${data.SubscriptionFeatureId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    };
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data?.SubscriptionFeatures?.SubscriptionFeatureId || 0) === 0
                        ? "Add Subscription Type"
                        : "Edit Subscription Type"}
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.SubscriptionFeatures}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Feature"
                                name="Feature"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputText
                                name="Description"
                                label="Description"
                                as="textarea"
                                rows="2"
                                className="form-control"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id="btnSubmit"
                        variant="outline-primary"
                        type="submit"
                    >
                        {" "}
                        {(data?.SubscriptionFeatures?.SubscriptionFeatureId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default forwardRef(AddEditSubscriptionFeature);

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import StaticListComponent from '../../Services/StaticListComponent';
import { NoRecordTemplate } from '../../Services/TableComponent';
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import ActionButton from '../../Services/ActionButton';
import { StandardConst } from '../../Services/StandardConst';
import { WebService } from '../../Services/WebService';
import { ActionPermission, PageInfo } from '../PageInfo';
import { useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/esm/Button";
// import AddEditAssetType from './AddEditAssetType';
import { Avatar, Chip } from '@mui/material';
import { DateTime } from 'luxon';
import BootstrapModal from "react-bootstrap/Modal";
import RichTextBox from '../../Services/RichTextBox';
import { Form, FormInputDropdown } from '../Form';
import * as yup from "yup";
import { WSErrorAlert } from '../../Services/WSAlert';
import { addDays, format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { generateQueryStringByObject } from '../../Services/UtilityService';

const ScheduledInterview = () => {
    const navigate = useNavigate();

    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});

    const isCustomer = Roles.some(role => role === StandardConst.SystemRole.Customer);
    const isEmployee = Roles.some(role => role === StandardConst.SystemRole.Employee);
    const isOtherRole = Roles.some(role => 
        [StandardConst.SystemRole.Company, StandardConst.SystemRole.SuperAdmin, StandardConst.SystemRole.HR].includes(role)
    );

    PageInfo({ pageTitle: "Interview List" });

    // useEffect(() => {
    //     SetPermission({
    //         ManageAdd: ActionPermission("Asset Type - Add"),
    //         ManageEdit: ActionPermission("Asset Type - Edit"),
    //         ManageDelete: ActionPermission("Asset Type - Delete"),
    //     });
    // }, [permissionList]);

    const refSnackbar = useRef();
    const ref = useRef();
    const MasterPageName = "Interview List";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const feedbackInterviewModalRef = useRef();

    const AddButtonText = "Interview List";
    const dispatch = useDispatch();
    const [deletedName, setDeletedName] = useState(null);
    
    const onDelete = async (Details) => {
        const data = Details.split(',');
        const AssetTypeId = data[0];
        setDeletedName(data[1]);

        await WebService({
            endPoint: `CommonUtility/assettype?AssetTypeId=${AssetTypeId}`,
            method: "DELETE",
            dispatch,
        });
        refSnackbar.current.setOpenSnackBar();
        await fetchAllInterviews();
    };

    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

    const fetchAllInterviews = async(filter) => {
        filter ??= false;
        var EndPoint = `Interviews/fetchAllInterviews`;
        if(filter){
            EndPoint += `?${generateQueryStringByObject(filter)}`
        }

        const data = await WebService({
            // endPoint: `CommonUtility/Edit/interviews`,
            endPoint: EndPoint,
            dispatch,
        });
        const modifiedArray = data?.map((res) => {
            const foundStatus = StandardConst.InterviewStatusDropdown.find((r) => r.value === res.Status);
            res.Status = foundStatus?.text;
            if(res.EFullName){
                res.FullName = res.EFullName;
                res.Image = res.EImage;
            }
            if(res.UFullName){
                res.FullName = res.UFullName;
                res.Image = res.UImage;
            }
            return res;
        });
        setRecords(modifiedArray);
        dispatch(ConfigurationflowStatusUpdate());
    }

    useEffect(() => {
        fetchAllInterviews();
    }, []);

    const [bData,] = React.useState([
        {
            title: "Recruitment",
            hrefLink: "#",
        },
        {
            title: "Interview List",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Job",
            Value: "Title"
        },
        {
            Text: "Interviewer",
            Value: "FullName",
            render: (dr) => {
                return (
                    <>
                        <div>
                            <div className="assignee-details d-flex">
                                <div className="me-2">
                                    {
                                        dr.Image ?
                                            <Avatar
                                                alt={dr.FullName.charAt(0)}
                                                src={`${StandardConst.apiBaseUrl}/uploads/${dr.Image}`}
                                            />
                                            :
                                            dr.FullName ?
                                                <Avatar alt={dr.FullName.charAt(0)}>{dr.FullName.charAt(0)}</Avatar>
                                                :
                                                ""
                                    }
                                </div>
                                <div className="FName d-flex justify-content-center align-items-center">
                                    {dr.FullName}
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        },
        {
            Text: "Job Applicant",
            Value: "ApplicantName"
        },
        {
            Text: "Interview Date",
            DateFormat: "dd-MM-yyyy",
            Value: "InterviewDate"
        },
        {
            Text: "Interview Time",
            render: (dr) => {
                return (
                    <>
                    {DateTime.fromISO(dr.InterviewFromTime).toFormat("hh:mm a")} - 
                    {DateTime.fromISO(dr.InterviewToTime).toFormat("hh:mm a")}
                    </>
                )
            }
        },
        {
            Text: "Status",
            Value: "Status",
            render: (dr) => {
                return (
                    <>
                        <div className="ticket-status" style={{ float: "right", paddingRight: "5px" }}>
                            <Chip
                                label={dr.Status}
                                color={dr.Status === StandardConst.InterviewStatusDropdown[0].text ? "success" : dr.Status === StandardConst.InterviewStatusDropdown[1].text ? "warning" : "default"}
                                variant='filled'
                                sx={{ borderRadius: "3px" }}
                            />
                        </div>
                    </>
                )
            }
        },
        {
            Text: "Action",
            cssClass: "text-center td-width-100",
            render: (dr) => (
                <>
                    {(!isOtherRole && (isEmployee || isCustomer)) && (

                        <ActionButton
                            onClick={() => fnEdit(dr.InterviewId, {JobTitle: dr.Title, Interviewer: dr.EFullName, ApplicantName : dr.ApplicantName, InterviewDate : dr.InterviewDate, JobId: dr.JobId, JobApplicantId : dr.JobApplicantId})}
                            IconName="Feedback"
                            IconTooltip={"Provide Feedback"}
                            id={`btnFeedbackInterview_${dr.InterviewId}`}
                        />
                    )}

                        <ActionButton
                            onClick={() => navigate("/FixInterview", { state: { InterviewId: dr.InterviewId } })}
                            IconName="Edit"
                            IconTooltip={"Edit Interview"}
                            id={`btnEditInterview_${dr.InterviewId}`}
                        />
                </>
            )
        }

];


    const fnEdit = async (id, InterviewDetails) => await feedbackInterviewModalRef.current.openModal(id || 0, InterviewDetails);

    return (
        <>
            <FeedbackInterviewModal ref={feedbackInterviewModalRef} callBackEvent={fetchAllInterviews} />
            <SnackbarComponent ref={refSnackbar} confirmMessage={`Asset type ${(deletedName!== null) ? deletedName : ""} Deleted successfully`} />
            <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
            <StaticListComponent
                columns={columns}
                records={records}
                bData={bData}
                MasterPageName={MasterPageName}
                AddButtonText={AddButtonText}
                noRecordCss="p-0"
                noRecordFound={
                    <NoRecordTemplate
                        headerValue={StandardConst.ScheduledInterviewheaderValueNoResults}
                        subHeaderValue={StandardConst.ScheduledInterviewSubHeader}
                        imageUrl={StandardConst.imageNoRecordsFound}
                    />
                }
                onAddEvent={() => {} }
                IsAddButtonVisible={false}
                isSearchRequired={true}
                allowSerialNo={true}
                filterData={[
                    {
                        FieldName: "InterviewDate",
                        FilterType: "DATE",
                        FilterPlaceholder: "Enter Interview Date",
                    },
                    {
                        FieldName: "Status",
                        FilterType: "DROPDOWN",
                        DefaultAllValuesText: "All Status",
                        DefaultSelected: [{text: "Rejected", value: "Rejected"}],
                        FilterData: StandardConst.InterviewStatusDropdown.map(item => ({value: item.value, text: item.text}))
                    },
                    {
                        FieldName: "JobId",
                        FilterType: "DROPDOWN",
                        DefaultAllValuesText: "All Jobs",
                        FilterData: Array.from(new Map(records.map(record => [record.JobId, {value: record.JobId, text: record.Title}])).values())
                    },
                ]}  
                callBackEvent={fetchAllInterviews}
            ></StaticListComponent>
        </>
    )
}

const FeedbackInterviewModal = forwardRef(({callBackEvent}, ref) => {
    
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [interviewerFeedback, setInterviewerFeedback] = useState(null);
    const [InterviewData, setInterviewData] = useState({});
    const [interviewDetails, setInterviewDetails] = useState({});
    

    useImperativeHandle(ref, () => ({
        openModal: async (InterviewId, InterviewDetails) => {
            setInterviewDetails(InterviewDetails);
            await WebService({
                endPoint: `CommonUtility/Edit/interviews?InterviewId=${InterviewId}`,
                method : 'GET',
                dispatch
            }).then((res) => {
                if(res.length > 0) {
                    if(res[0].InterviewerFeedback !== null) {
                        setInterviewerFeedback(res[0].InterviewerFeedback);
                    }
                    setInterviewData(res[0]);
                }else {
                    setInterviewData({InterviewId});
                }
            });
            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setInterviewerFeedback(null);
        setInterviewData({});
    };


    const setNotificationAndMail = async (data) => {
        
        const fetchJobData = await WebService({
            endPoint: `CommonUtility/Edit/jobs?jobId=${interviewDetails.JobId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? res[0] : null);

        // // Here we send notification
        // if(fetchJobData !== null && Object.keys(interviewDetails).length > 0){
        //     await WebService({
        //         dispatch,
        //         endPoint: "Notification/sendSystemNotification",
        //         body: {
        //             Message: `Interview Feedback Received - ${interviewDetails.ApplicantName} - ${interviewDetails.JobTitle} from ${interviewDetails.Interviewer}`,
        //             SendTo: [fetchJobData.EmployeeId],
        //             Expire: addDays(new Date(), 30),
        //         },
        //     });
        // }

        // Here we send email
        if(fetchJobData !== null && fetchJobData.EmployeeId !== null){
            await WebService({
                dispatch,
                endPoint: "Recruitment/SendMailInterviewFeedbackReceived",
                method: "POST",
                body: {
                    ApplicantName : interviewDetails.ApplicantName,
                    JobTitle : interviewDetails.JobTitle,
                    Interviewer : interviewDetails.Interviewer,
                    InterviewDate: interviewDetails.InterviewDate,
                    Feedback : data.InterviewerFeedback,
                    InterviewStatus : StandardConst.InterviewStatusDropdown.find(item => parseInt(item.value) === parseInt(data.Status))?.text,
                    EmployeeId : fetchJobData.EmployeeId
                }
            });
        }
    }

    const updateApplicationStatus = async(StatusId) => {
        const { JobApplicantId, JobId } = interviewDetails;
        let data = {}
        if(StatusId === StandardConst.Status.Scheduled){
            data = { StatusId: StandardConst.Status.Scheduled }
        }else if(StatusId === StandardConst.Status.Completed || StatusId === StandardConst.Status.Selected || StatusId === StandardConst.Status.SelectedForNextRound || StatusId === StandardConst.Status.NotFitForPosition || StatusId === StandardConst.Status.OnHold || StatusId === StandardConst.Status.MakeAnOffer){
            data = { StatusId: StandardConst.Status.Interviewed }
        }

        if(Object.keys(data).length > 0){
            await WebService({
                endPoint: `CommonUtility/jobapplications?JobApplicantId=${JobApplicantId}&JobId=${JobId}`,
                body: data,
                method: 'PUT',
                // dispatch,
            });
        }
    };

    const onSubmit = async (data) => {
        if(interviewerFeedback !== null) {
            const feedbackData = {InterviewerFeedback : interviewerFeedback, Status : data.Status};
            await WebService({
                endPoint: `Interviews/giveInterviewFeedback?InterviewId=${data.InterviewId}&JobId=${interviewDetails.JobId}`,
                body: feedbackData,
                method : 'PUT',
                dispatch
            }).then(res => {
                updateApplicationStatus(parseInt(data.Status));
                setNotificationAndMail(feedbackData);
            });
            handleClose();
            callBackEvent();
        }else{
            WSErrorAlert("Error", "Please fill feedback field");
        }
    };

    const insuranceSchema = yup.object().shape({
        InsuranceCompany: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        PolicyNumber: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        // PolicyNumber: yup.number().typeError("type number").required(StandardConst.ValidationMessages.RequiredFieldMsg),
        InsuranceExpiryDate: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });
    
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose} size={"lg"}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>Interviewer Feedback</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={InterviewData}
                        onSubmit={onSubmit}
                        // validationSchema={insuranceSchema}
                    >
                        <div className="row">
                            
                            <div className="col-md-12">
                                <div className="row m-0 p-0">
                                    <div className="col-md-5 d-flex align-items-center justify-content-between p-0" style={{fontSize: "14px"}}>
                                        <label>Feedback</label>
                                    </div>
                                    <div className="col-md-7 p-0">
                                        <RichTextBox
                                            value={interviewerFeedback}
                                            setValue={setInterviewerFeedback}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-12 mt-2">
                                <FormInputDropdown
                                    label="Status"
                                    name="Status"
                                    ddOpt={StandardConst.InterviewStatusDropdown}
                                />
                            </div>
                                    
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="float-end mt-4 mb-1"
                                >
                                    Feedback
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

export default ScheduledInterview
import * as FileServer from "file-saver";
import XLSX from "sheetjs-style";

export const ExcelExport = (excelData, fileName, dispatch) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = "xlsx";

  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileServer.saveAs(data, `${fileName}.${fileExtension}`);
};

export const ExcelImport = async (uploadedFile, ColumnData) => {

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet);
      
      const updatedExcelData = excelData.filter(row =>
        ColumnData.some(value => row[value] !== undefined)
      );

      resolve(updatedExcelData);
    };
    reader.readAsArrayBuffer(uploadedFile);
  });
};


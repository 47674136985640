import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import { PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, Box, AppBar, Alert, Autocomplete, TextField, FormControlLabel, Switch } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Topbar from "../../Services/TopbarComponent";
import { StandardConst } from '../../Services/StandardConst';
import { Form, FormAutoCompleteDropdown, FormInputDropdown, FormInputFile, FormInputText } from '../Form';
import Button from "react-bootstrap/Button";
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent';
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from '../../Services/ActionButton';
import { WSErrorAlert, WSSuccessAlert } from '../../Services/WSAlert';
import BootstrapModal from "react-bootstrap/Modal";
import * as yup from "yup";
import { format } from 'date-fns';
import PersonIcon from '@mui/icons-material/Person';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { login, setAssignRole, setMenu, setPermission } from "../../Services/authSlice";
import RichTextBox from '../../Services/RichTextBox';

const JobApplicantForm = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const loggedApplicantUserId = useSelector((s) => s.auth.LoggedCustomer ?? 0);

    const [jobApplicantData, setJobApplicantData] = useState({});
    const [jobApplicantId, setJobApplicantId] = useState(null);

    PageInfo({ pageTitle: jobApplicantId !== null ? "Edit Profile" : "Create Profile" });

    const fetchJobApplicantsData = async () => {
        await WebService({
            endPoint: `Recruitment/fetchJobApplicantDetails?UserId=${loggedApplicantUserId}`,
            method: 'GET',
        }).then(res => {
            if(res.length > 0){
                setJobApplicantId(res[0].JobApplicantId);
                setJobApplicantData(res[0]);
            }
        });
    }

    useEffect(() => {
        if(loggedApplicantUserId > 0){
            fetchJobApplicantsData();
        }
    }, [loggedApplicantUserId]);

    useEffect(() => {
        if(jobApplicantId > 0){
            FetchJobApplicantEmploymentHistoryData(jobApplicantId);
            FetchJobApplicantEducationDetailsData(jobApplicantId);
            FetchJobApplicantCertificationData(jobApplicantId);
            FetchJobApplicantSkillData(jobApplicantId);
        }
    }, [jobApplicantId]);

    const [companyCurrencyData, setCompanyCurrencyData] = useState({});

    const [value, setValue] = useState(0);

    const [bData] = useState([
        {
            title: "Recruitment",
            hrefLink: "#",
        },
        {
            title: "Profile",
            hrefLink: "#",
        },
    ]);

    const handleChange = (event, newValue) => {
        if(jobApplicantId !== null){
            setValue(newValue);
        }else{
            WSErrorAlert("Error", "Please fill job applicant form first.");
            setValue(0);
        }
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0, mt: 0 }}>
                        <span>{children}</span>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const fetchCurrencyData = async () => {
        const companyInformation = await WebService({
            endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${companyInfo.CompanyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));
        const currencyInformation = await WebService({
            endPoint: `CommonUtility/Edit/staticcurrency?CurrencyId=${companyInformation.CurrencyId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        const currencyLocaleCode = await WebService({
            endPoint: `CommonUtility/Edit/countries?CountryId=${currencyInformation.CountryId || 0}`,
            dispatch,
        }).then((c) => (c.length > 0 ? c[0] : {}));

        currencyInformation.LocaleCode = currencyLocaleCode.LocaleCode;
        setCompanyCurrencyData(currencyInformation);
    };


    useEffect(() => {
        if(companyInfo?.CurrencyId){
            fetchCurrencyData();
        }
    }, [companyInfo]);

    // #region Job applicant employment history Tab - Functions -- START

    const JobApplicantEmploymentHistoryFormRef = useRef();
    const JobApplicantEmploymentHistoryDeleteRef = useRef();

    const [jobApplicantEmploymentHistoryData, setJobApplicantEmploymentHistoryData] = useState([]);

    const openJobApplicantEmploymentHistoryModal = async (JobApplicantEmploymentHistoryId) => await JobApplicantEmploymentHistoryFormRef.current.openModal(JobApplicantEmploymentHistoryId || 0);

    const FetchJobApplicantEmploymentHistoryData = async (JobApplicantId) => {
        await WebService({
            endPoint: `Recruitment/fetchJobApplicantEmploymentHistory?JobApplicantId=${JobApplicantId}`,
            dispatch,
        }).then((res) => {
            setJobApplicantEmploymentHistoryData(res);
        });
    }

    const onJobApplicantEmploymentHistoryDelete = async (JobApplicantEmploymentHistoryId) => {
        await WebService({
            endPoint: `Recruitment/fetchJobApplicantEmploymentHistoryDelete?JobApplicantEmploymentHistoryId=${JobApplicantEmploymentHistoryId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => {
            WSSuccessAlert('Success', 'Successfully deleted.');
            FetchJobApplicantEmploymentHistoryData(jobApplicantId);
        })
    };

    const JobApplicantEmploymentHistoryColumns = [
        {
            Text: "Company Name",
            Value: "CompanyName"
        },
        {
            Text: "Designation",
            Value: "Designation"
        },
        {
            Text: "From Date",
            DateFormat: "dd-MMM-yyyy",
            Value: "FromDate"
        },
        {
            Text: "To Date",
            DateFormat: "dd-MMM-yyyy",
            Value: "ToDate"
        },
        {
            Text: "Action",
            key: "JobApplicantEmploymentHistoryId",
            cssClass: "text-end",
            render: (dr) => (
            <>
                <ActionButton
                    onClick={() => openJobApplicantEmploymentHistoryModal(dr.JobApplicantEmploymentHistoryId)}
                    IconName="Edit"
                    id="btnEdit"
                />
                <ActionButton
                    onClick={(e) =>
                        JobApplicantEmploymentHistoryDeleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        `Do you want to delete ${dr.CompanyName} Employment History`, // Message of Alert
                        dr.JobApplicantEmploymentHistoryId // Endpoint to hit for delete
                        )}
                    IconName="Delete"
                    id="btnDelete"
                />
            </>
            ),
        },
    ];
    //#endregion Job applicant employment history Tab - Functions -- END

    // #region Job applicant Educational Details Tab - Functions -- START

    const JobApplicantEducationDetailsRef = useRef();
    const JobApplicantEducationDetailsDeleteRef = useRef();

    const [jobApplicantEducationDetailsData, setJobApplicantEducationDetailsData] = useState([]);

    const openJobApplicantEducationDetailsModal = async (JobApplicantEducationDetailId) => await JobApplicantEducationDetailsRef.current.openModal(JobApplicantEducationDetailId || 0);

    const FetchJobApplicantEducationDetailsData = async (JobApplicantId) => {
        await WebService({
            // endPoint: `Recruitment/fetchJobApplicantEducationDetails?JobApplicantId=${JobApplicantId}`,
            endPoint: `CommonUtility/Edit/jobapplicanteducationdetails?JobApplicantId=${JobApplicantId}`,
            dispatch,
        }).then((res) => {
            setJobApplicantEducationDetailsData(res);
        });
    }

    const onJobApplicantEducationDetailsDelete = async (JobApplicantEducationDetailId) => {
        await WebService({
            endPoint: `CommonUtility/jobapplicanteducationdetails?JobApplicantEducationDetailId=${JobApplicantEducationDetailId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => {
            WSSuccessAlert('Success', 'Successfully deleted.');
            FetchJobApplicantEducationDetailsData(jobApplicantId);
        })
    };

    const JobApplicantEducationDetailsColumns = [
        {
            Text: "Passing Month-Year",
            DateFormat: "MMM-yyyy",
            Value: "PassingMonthYear"
        },
        {
            Text: "Degree/Course",
            Value: "DegreeOrCourse"
        },
        {
            Text: "Percentage/Grade",
            Value: "PercentageOrGrade"
        },
        {
            Text: "Action",
            key: "JobApplicantEducationDetailId",
            cssClass: "text-end",
            render: (dr) => (
            <>
                <ActionButton
                    onClick={() => openJobApplicantEducationDetailsModal(dr.JobApplicantEducationDetailId)}
                    IconName="Edit"
                    id="btnEdit"
                />
                <ActionButton
                    onClick={(e) =>
                        JobApplicantEducationDetailsDeleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        `Do you want to delete this detail`, // Message of Alert
                        dr.JobApplicantEducationDetailId // Endpoint to hit for delete
                        )}
                    IconName="Delete"
                    id="btnDelete"
                />
            </>
            ),
        },
    ];
    //#endregion Job applicant Educational Details Tab - Functions -- END

    // #region Job applicant Certification Tab - Functions -- START

    const JobApplicantCertificationRef = useRef();
    const JobApplicantCertificationDeleteRef = useRef();

    const [jobApplicantCertificationData, setJobApplicantCertificationData] = useState([]);

    const openJobApplicantCertificationModal = async (JobApplicantCertificationId) => await JobApplicantCertificationRef.current.openModal(JobApplicantCertificationId || 0);

    const FetchJobApplicantCertificationData = async (JobApplicantId) => {
        await WebService({
            // endPoint: `Recruitment/fetchJobApplicantCertification?JobApplicantId=${JobApplicantId}`,
            endPoint: `CommonUtility/Edit/jobapplicantcertifications?JobApplicantId=${JobApplicantId}`,
            dispatch,
        }).then((res) => {
            setJobApplicantCertificationData(res);
        });
    }

    const onJobApplicantCertificationDelete = async (JobApplicantCertificationId) => {
        await WebService({
            endPoint: `CommonUtility/jobapplicantcertifications?JobApplicantCertificationId=${JobApplicantCertificationId}`,
            method: "DELETE",
            dispatch,
        }).then((res) => {
            WSSuccessAlert('Success', 'Successfully deleted.');
            FetchJobApplicantCertificationData(jobApplicantId);
        })
    };

    const JobApplicantCertificationColumns = [
        {
            Text: "Certification Month-Year",
            DateFormat: "MMM-yyyy",
            Value: "CertificationMonthYear"
        },
        {
            Text: "Certifiation Name",
            Value: "CertifiationName"
        },
        {
            Text: "Certification Body",
            Value: "CertificationBody"
        },
        {
            Text: "Action",
            key: "JobApplicantCertificationId",
            cssClass: "text-end",
            render: (dr) => (
            <>
                <ActionButton
                    onClick={() => openJobApplicantCertificationModal(dr.JobApplicantCertificationId)}
                    IconName="Edit"
                    id="btnEdit"
                />
                <ActionButton
                    onClick={(e) =>
                        JobApplicantCertificationDeleteRef.current.confirmAlert(
                        "Delete", //Confirm button text
                        "Are You Sure", // Text if Alert
                        `Do you want to delete this certification`, // Message of Alert
                        dr.JobApplicantCertificationId // Endpoint to hit for delete
                        )}
                    IconName="Delete"
                    id="btnDelete"
                />
            </>
            ),
        },
    ];
    //#endregion Job applicant Certification Tab - Functions -- END

     // #region Job applicant Skill Tab - Functions -- START

     const JobApplicantSkillRef = useRef();
     const JobApplicantSkillDeleteRef = useRef();
 
     const [jobApplicantSkillData, setJobApplicantSkillData] = useState([]);
 
     const openJobApplicantSkillModal = async (JobApplicantSkillId) => await JobApplicantSkillRef.current.openModal(JobApplicantSkillId || 0);
 
     const FetchJobApplicantSkillData = async (JobApplicantId) => {
        const SkillData = await WebService({
             // endPoint: `Recruitment/fetchJobApplicantSkill?JobApplicantId=${JobApplicantId}`,
             endPoint: `CommonUtility/Edit/jobapplicantskills?JobApplicantId=${JobApplicantId}`,
             dispatch,
        })
        if(SkillData.length > 0) {

            SkillData.map(async (item) => {
                await WebService({
                    endPoint : `CommonUtility/Edit/staticskill?SkillId=${item.SkillId}`,
                }).then((skill) => skill.length > 0 ? item.SkillName = skill[0].SkillName : null)
            })
            setJobApplicantSkillData(SkillData);
        }
     }
 
     const onJobApplicantSkillDelete = async (JobApplicantSkillId) => {
         await WebService({
             endPoint: `CommonUtility/jobapplicantskills?JobApplicantSkillId=${JobApplicantSkillId}`,
             method: "DELETE",
             dispatch,
         }).then((res) => {
             WSSuccessAlert('Success', 'Successfully deleted.');
             FetchJobApplicantSkillData(jobApplicantId);
         })
     };
 
     const JobApplicantSkillColumns = [
         {
            Text: "Skill",
            Value: "SkillName"
         },
         {
             Text: "Skill Type",
             Value: "SkillType"
         },
         {
             Text: "Skill Proficiency Level",
             Value: "SkillProficiencyLevel"
         },
         {
             Text: "Action",
             key: "JobApplicantSkillId",
             cssClass: "text-end",
             render: (dr) => (
             <>
                 <ActionButton
                     onClick={() => openJobApplicantSkillModal(dr.JobApplicantSkillId)}
                     IconName="Edit"
                     id="btnEdit"
                 />
                 <ActionButton
                     onClick={(e) =>
                         JobApplicantSkillDeleteRef.current.confirmAlert(
                         "Delete", //Confirm button text
                         "Are You Sure", // Text if Alert
                         `Do you want to delete this skill`, // Message of Alert
                         dr.JobApplicantSkillId // Endpoint to hit for delete
                         )}
                     IconName="Delete"
                     id="btnDelete"
                 />
             </>
             ),
         },
     ];
     //#endregion Job applicant Skill Tab - Functions -- END
 


    return (
        <>
                    <Container
                        fluid
                        className="base-container"
                    >
                        <Topbar bData={bData} HeadingText={jobApplicantId !== null ? "Edit Profile" : "Create Profile"}></Topbar>
                        <Box>
                            <AppBar
                                position="static"
                                color="info"
                                elevation={0}
                                className="Maintab_Custome mb-2"
                                style={{ maxHeight: "40px", height: "40px" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                    style={{
                                        maxHeight: "40px",
                                        height: "40px",
                                    }}
                                    id="tabs"
                                >
                                    <Tab
                                        icon={<PersonIcon />}
                                        sx={{ "min-width": "40px !important" }}
                                        aria-label="Basic Details"
                                        label={window.innerWidth <= 600 ? '' : "Basic Details"}
                                        iconPosition="start"
                                        className="tab_Custome pt-0"
                                        id="General"
                                    />
                                    <Tab
                                        icon={<ContactPageIcon />}
                                        sx={{ "min-width": "40px !important" }}
                                        aria-label="Job Applicant Employment History"
                                        label={window.innerWidth <= 600 ? '' : "Employment History"}
                                        iconPosition="start"
                                        className="tab_Custome pt-0"
                                        id="JobApplicantEmployment"
                                    />
                                    <Tab
                                        icon={<ContactPageIcon />}
                                        sx={{ "min-width": "40px !important" }}
                                        aria-label="Education Details"
                                        label={window.innerWidth <= 600 ? '' : "Education Details"}
                                        iconPosition="start"
                                        className="tab_Custome pt-0"
                                        id="JobApplicantEducationDetails"
                                    />
                                    <Tab
                                        icon={<ContactPageIcon />}
                                        sx={{ "min-width": "40px !important" }}
                                        aria-label="Certifications"
                                        label={window.innerWidth <= 600 ? '' : "Certifications"}
                                        iconPosition="start"
                                        className="tab_Custome pt-0"
                                        id="JobApplicantCertification"
                                    />
                                    <Tab
                                        icon={<ContactPageIcon />}
                                        sx={{ "min-width": "40px !important" }}
                                        aria-label="Skills"
                                        label={window.innerWidth <= 600 ? '' : "Skills"}
                                        iconPosition="start"
                                        className="tab_Custome pt-0"
                                        id="JobApplicantCertification"
                                    />
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <BasicJobApplicantForm JobApplicantData={jobApplicantData} setJobApplicantId={setJobApplicantId} CallBackFunction={fetchJobApplicantsData}/>
                                </TabPanel>
                                
                                {/* Employment History tab */}
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <DeleteConfirmAlert ref={JobApplicantEmploymentHistoryDeleteRef} confirmEvent={(v) => onJobApplicantEmploymentHistoryDelete(v)} />
                                    <ModalAddJobApplicantEmploymentHistory ref={JobApplicantEmploymentHistoryFormRef} JobApplicantId={jobApplicantId} callBackEvent={async () => await FetchJobApplicantEmploymentHistoryData(jobApplicantId)}/>
                                    <Container fluid className='mt-2'>
                                        <TableComponent
                                            columns={JobApplicantEmploymentHistoryColumns}
                                            data={jobApplicantEmploymentHistoryData}
                                            noRecordCss="p-0"
                                            noRecordFound={
                                            <NoRecordTemplate
                                                headerValue="No job applicant employment history found."
                                                subHeaderValue="Please fill out the employment history"
                                                imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                            />
                                            }
                                            onAddEvent={() => openJobApplicantEmploymentHistoryModal(0)}
                                            IsAddButtonVisible={true}
                                            isSearchRequired={true}
                                            // initialSearchContent={searchParams.get("search") ?? ""}
                                            allowSerialNo={true}
                                        />
                                    </Container>
                                </TabPanel>

                                {/* Education Details tab */}
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    <DeleteConfirmAlert ref={JobApplicantEducationDetailsDeleteRef} confirmEvent={(v) => onJobApplicantEducationDetailsDelete(v)} />
                                    <ModalAddJobApplicantEducationDetails ref={JobApplicantEducationDetailsRef} JobApplicantId={jobApplicantId} callBackEvent={async () => await FetchJobApplicantEducationDetailsData(jobApplicantId)}/>
                                    <Container fluid className='mt-2'>
                                        <TableComponent
                                            columns={JobApplicantEducationDetailsColumns}
                                            data={jobApplicantEducationDetailsData}
                                            noRecordCss="p-0"
                                            noRecordFound={
                                                <NoRecordTemplate
                                                    headerValue="No job applicant Educational Details found."
                                                    subHeaderValue="Please fill out the Educational Details"
                                                    imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                                />
                                            }
                                            onAddEvent={() => openJobApplicantEducationDetailsModal(0)}
                                            IsAddButtonVisible={true}
                                            isSearchRequired={true}
                                            // initialSearchContent={searchParams.get("search") ?? ""}
                                            allowSerialNo={true}
                                        />
                                    </Container>
                                </TabPanel>

                                {/* Certification tab */}
                                <TabPanel value={value} index={3} dir={theme.direction}>
                                    <DeleteConfirmAlert ref={JobApplicantCertificationDeleteRef} confirmEvent={(v) => onJobApplicantCertificationDelete(v)} />
                                    <ModalAddJobApplicantCertification ref={JobApplicantCertificationRef} JobApplicantId={jobApplicantId} callBackEvent={async () => await FetchJobApplicantCertificationData(jobApplicantId)}/>
                                    <Container fluid className='mt-2'>
                                        <TableComponent
                                            columns={JobApplicantCertificationColumns}
                                            data={jobApplicantCertificationData}
                                            noRecordCss="p-0"
                                            noRecordFound={
                                                <NoRecordTemplate
                                                    headerValue="No job applicant Certification found."
                                                    subHeaderValue="Please fill out the Certification"
                                                    imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                                />
                                            }
                                            onAddEvent={() => openJobApplicantCertificationModal(0)}
                                            IsAddButtonVisible={true}
                                            isSearchRequired={true}
                                            // initialSearchContent={searchParams.get("search") ?? ""}
                                            allowSerialNo={true}
                                        />
                                    </Container>
                                </TabPanel>

                                {/* Skill tab */}
                                <TabPanel value={value} index={4} dir={theme.direction}>
                                    <DeleteConfirmAlert ref={JobApplicantSkillDeleteRef} confirmEvent={(v) => onJobApplicantSkillDelete(v)} />
                                    <ModalAddJobApplicantSkill ref={JobApplicantSkillRef} JobApplicantId={jobApplicantId} callBackEvent={async () => await FetchJobApplicantSkillData(jobApplicantId)}/>
                                    <Container fluid className='mt-2'>
                                        <TableComponent
                                            columns={JobApplicantSkillColumns}
                                            data={jobApplicantSkillData}
                                            noRecordCss="p-0"
                                            noRecordFound={
                                                <NoRecordTemplate
                                                    headerValue="No job applicant Skill found."
                                                    subHeaderValue="Please fill out the Skill"
                                                    imageUrl={`${StandardConst.ProjectImagesInPublicAssets}/no-records-j.png`}
                                                />
                                            }
                                            onAddEvent={() => openJobApplicantSkillModal(0)}
                                            IsAddButtonVisible={true}
                                            isSearchRequired={true}
                                            // initialSearchContent={searchParams.get("search") ?? ""}
                                            allowSerialNo={true}
                                        />
                                    </Container>
                                </TabPanel>
                            </SwipeableViews>
                        </Box>
                    </Container>
        </>
    );
}

const BasicJobApplicantForm = ({JobApplicantData, setJobApplicantId, CallBackFunction}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [availabilityStatus, setAvailabilityStatus] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [jobApplicantData, setJobApplicantData] = useState({});
    const [staticSkillsData, setStaticSkillsData] = useState([]);
    const [autoErrorShow, setAutoErrorShow] = useState(false);
    const [applicantProfile, setApplicantProfile] = useState(null);

    const fetchStaticSkills = async () => {
        await WebService({
            endPoint: `Recruitment/fetchAllStaticSkills`,
            method: "GET",
            dispatch
        }).then((res) => res.length > 0 ? setStaticSkillsData(res.map(item => ({value: item.SkillId, text: item.SkillName}))) : []);
    };

    useEffect(() => {
        fetchStaticSkills();
    }, []);

    useEffect(() => {
        if(Object.keys(JobApplicantData).length > 0){
            if(JobApplicantData.AvailableForJobOpenings === StandardConst.YesNo.No){
                setAvailabilityStatus(false);
            }
            setJobApplicantData(JobApplicantData);
            setIsDataLoaded(true);
        }else{
            setIsDataLoaded(true);
        }
    }, [JobApplicantData]);

    const JobApplicantSchema = yup.object().shape({
      FullName: yup.string().label("Name").trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
      Email: yup
        .string()
        .label("Email")
        .trim()
        .required(StandardConst.ValidationMessages.RequiredFieldMsg)
        .email(),
    //   Phone: yup
    //     .number()
    //     .typeError(StandardConst.ValidationMessages.InvalidCharMsg)
    //     .required(StandardConst.ValidationMessages.RequiredFieldMsg)
    //     .min(1000000000, StandardConst.ValidationMessages.PhoneNoLengthMsg)
    //     .max(9999999999, StandardConst.ValidationMessages.PhoneNoLengthMsg),

      Phone: yup.string()
        .required(StandardConst.requiredMessage)
        .matches(StandardConst.PhoneNumberValidateRegex, StandardConst.ValidationMessages.PhoneNoIsNotValid),

      Address: yup.string().trim().notRequired(),
    })
    .required();

    const onSubmitJobApplicant = async (body) => {
        body.UserTypeId = StandardConst.UserTypeId.JobApplicant;
            const applicantUserData = await WebService({
                endPoint: `Recruitment/JobApplicantFormSubmission`,
                body,
                method: 'POST',
                dispatch,
            });

            if(applicantUserData.UserId !== undefined){

                const applicantData = {
                    UserId : applicantUserData.UserId,
                    Position : body.Position,
                    Age : body.Age,
                    YearOfExperience : body.YearOfExperience,
                    Location : body.Location,
                    ExpectedCTC : body.ExpectedCTC,
                    Description : body.Description,
                    AvailableForJobOpenings : availabilityStatus ? 1 : 0,
                    JobApplicantId : body.JobApplicantId ?? 0,
                };

                if(body.Attachment.length !== 0){
                    await WebService({
                      endPoint: "upload/File?destination=Recruitment/Resume",
                      dispatch,
                      body: body.Attachment,
                      isFile: true,
                    }).then((res) => {
                      applicantData.ResumeAttachment = res.filename;
                    });
                }
                if(body.AttachmentCoverLetter.length !== 0){
                    await WebService({
                      endPoint: "upload/File?destination=Recruitment/Resume",
                      dispatch,
                      body: body.AttachmentCoverLetter,
                      isFile: true,
                    }).then((res) => {
                      applicantData.CoverLetter = res.filename;
                    });
                }

                const JobApplicantId = await WebService({
                    endPoint : `Recruitment/JobApplicantFormSubmissionSec`,
                    body : applicantData,
                    method : 'POST',
                    dispatch,
                }).then((JobApplicantId) => {
                    setJobApplicantId(JobApplicantId)
                });

                if(applicantProfile !== null){
                    const applicantProfileFileName = await WebService({
                        endPoint: "upload/File",
                        dispatch,
                        body: applicantProfile,
                        isFile: true,
                    }).then((res) => res.filename);

                    if(applicantProfileFileName){
                        await WebService({
                            endPoint : `Recruitment/UpdateApplicantProfilePic?UserId=${applicantUserData.UserId}`,
                            body : {ProfileImage : applicantProfileFileName},
                            method : 'PUT',
                            dispatch,
                        })
                    }
                }

                if(body.UserId !== undefined, body.JobApplicantId !== undefined){
                    WSSuccessAlert("Success", "Your Profile is successfully updated.");
                }else{
                    WSSuccessAlert("Success", "You are successfully registered.");
    
                    const loginData = {Email: applicantUserData?.Email, Password: applicantUserData?.GeneratedPassword}
                    WebService({
                        endPoint: "LogIn/User/Validate/Unknown",
                        method: "POST",
                        body: loginData,
                        dispatch,
                    }).then((res) => {
                        dispatch(login(res));
                        navigate("/EditApplicantProfile");
                    });
                }
            }
            CallBackFunction();
    };

    const ApplicantProfileChange = (e) => {
        setApplicantProfile(e.target.files[0]);
    }

    // const handleSkillChange = (event, selectedOptions) => {
    //     (selectedOptions.length === 0) ? setAutoErrorShow(true) : setAutoErrorShow(false);
    //     setSelectedSkill(selectedOptions);
    // };

    const DeleteAttachmentOrCoverLetter = async (JobApplicantId, Path, IsResume) => {
        await WebService({
            endPoint: `Recruitment/deleteResumeAttachmentOrCoverLetter`,
            body: {JobApplicantId, Path, IsResume},
            method: 'DELETE',
            dispatch
        }).then(res => {
            CallBackFunction();
            WSSuccessAlert("Success", "Your file has been deleted successfully.");
        })
    };

    if(!isDataLoaded){
        return <></>;
    }


    return (
        <>
            <Container className='mt-2'>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div id="Vehicle-QRCode">
                            <div className="Heading mb-4">
                                <Alert severity="info">Profile Picture</Alert>
                            </div>
                            <div className='qr-code d-flex justify-content-center text-align-center'>
                                <div className="pic-holder">
                                    <img
                                    className="pic rounded-circle"
                                    src={
                                        // `${StandardConst.apiBaseUrl}/uploads/${dp ?? "user_placeholder.jpg"
                                        // }`
                                        
                                        applicantProfile != null
                                        ? URL.createObjectURL(applicantProfile)
                                        : 
                                        (jobApplicantData.ProfileImage !== undefined && jobApplicantData.ProfileImage !== null) ? `${StandardConst.apiBaseUrl}/uploads/${jobApplicantData.ProfileImage}` :
                                        `${StandardConst.apiBaseUrl}/uploads/user_placeholder.jpg`
                                    }
                                    alt=""
                                    />
                                    <input
                                    className="uploadProfileInput"
                                    type="file"
                                    name="File"
                                    id="newProfilePhoto"
                                    accept="image/*"
                                    style={{ opacity: "0", display: "contents" }}
                                    onChange={(e) => ApplicantProfileChange(e)}
                                    />

                                    {/* {(loggedUserDetails === records[0]?.EmployeeId || permission.ManageAllProfilePictureUpload) && ( */}
                                    <label
                                        htmlFor="newProfilePhoto"
                                        className="upload-file-block"
                                    >
                                        <div className="text-center">
                                        <div className="mb-1">
                                            <i className="fa fa-camera fa-2x"></i>
                                        </div>
                                        <div className="text-uppercase">
                                            Update <br /> Profile Photo
                                        </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-8">
                        <Form 
                            defaultValues={jobApplicantData}
                            onSubmit={onSubmitJobApplicant}
                            validationSchema={JobApplicantSchema}
                        >
                            <div className="card shadow-none footer-widget">
                                <Alert severity="info" sx={{ width: "95%" }}>Basic Information</Alert>
                                <div className="card-body">
                                    <div className="row mt-1">
                                        <div className="row">
                                            <div className="col-md-12 mt-1">
                                                <div className="row">
                                                    <div className="col-md-5 d-flex align-items-center justify-content-between"  style={{fontSize: "14px"}}>
                                                        <label>Avalability Status</label>
                                                    </div>
                                                    <div className="col-md-7" style={{paddingLeft: "0px"}}>
                                                        <FormControlLabel 
                                                            control={<Switch 
                                                                    checked={availabilityStatus}
                                                                    onChange={(event, val) => setAvailabilityStatus(val)}
                                                                />}
                                                            label={availabilityStatus ? "Available For Job Openings" : "Not Looking for Job"} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 mt-1">
                                                <FormInputText
                                                    label="Name"
                                                    name="FullName"
                                                    type="text"
                                                    isRequired="true"
                                                />
                                            </div>

                                            <div className="col-md-12 mt-1">
                                                <FormInputText
                                                    label="Position/Role"
                                                    name="Position"
                                                    type="text"
                                                    isRequired="true"
                                                    maxlength="100"
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <FormInputText
                                                    label="Email"
                                                    name="Email"
                                                    type="text"
                                                    isRequired="true"
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <FormInputText
                                                    label="Phone"
                                                    name="Phone"
                                                    type="text"
                                                    isRequired="true"
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <FormInputText
                                                    name="Address"
                                                    label="Address"
                                                    as="textarea"
                                                    rows="2"
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <FormInputText
                                                    name="Age"
                                                    label="Age"
                                                    type="text"
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <FormInputText
                                                    name="YearOfExperience"
                                                    label="Year Of Experience"
                                                    type="text"
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <FormInputText
                                                    name="Location"
                                                    label="Location"
                                                    type="text"
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <FormInputText
                                                    name="ExpectedCTC"
                                                    label="Expected CTC"
                                                    type="text"
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <FormInputText
                                                    name="Description"
                                                    label="Description"
                                                    as="textarea"
                                                    rows="2"
                                                />
                                            </div>
                                           
                                            {/* <div className="col-md-12 mt-1">
                                                <div className="row">
                                                    <div className="col-md-5 d-flex align-items-center justify-content-between"  style={{fontSize: "14px"}}>
                                                        <label>Skills</label>
                                                    </div>
                                                    <div className="col-md-7" style={{paddingLeft: "0px"}}>
                                                        <Autocomplete
                                                            multiple
                                                            id="grouped-demo"
                                                            options={staticSkillsData}
                                                            // groupBy={(option) => option.ParentId === null ? option.text : ""}
                                                            getOptionLabel={(option) => option?.text}
                                                            defaultValue={selectedSkill}
                                                            // defaultValue={selectedSkill?.map(selectedData => staticSkillsData[staticSkillsData?.findIndex(pitem => pitem.value === selectedData.value)])}

                                                            onChange={handleSkillChange}

                                                            filterSelectedOptions
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    // label="Choose Skills"
                                                                    placeholder="Choose Skills"
                                                                    error={autoErrorShow} // Set error state of TextField
                                                                    helperText={(autoErrorShow) && "Select atleast one Skill"} // Display error message if error is true
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-5 d-flex align-items-center justify-content-between"  style={{fontSize: "14px"}}>
                                                        <label>Attach Your Resume</label>
                                                    </div>
                                                    <div className="col-md-7 p-0 mt-2" style={{paddingLeft: "0px"}}>
                                                        <div className="row">
                                                            {(Object.keys(jobApplicantData).length > 0 &&  jobApplicantData?.ResumeAttachment !== null) && (
                                                                <>
                                                                    <div className="col-md-4">

                                                                        <a target='_blank' href={`${StandardConst.JobApplicantResume}/${jobApplicantData.ResumeAttachment}`}>
                                                                            {jobApplicantData.ResumeAttachment.endsWith('.docx') ? 
                                                                                <img src={`${StandardConst.ProjectImagesInPublicAssets}/wordFile.webp`} alt="" style={{ width: '100px', height: 'auto', border: 'none' }}/>
                                                                            :
                                                                                <iframe 
                                                                                    src={`${StandardConst.JobApplicantResume}/${jobApplicantData.ResumeAttachment}`} 
                                                                                    title="Job Applicant Resume" 
                                                                                    style={{ width: '100px', height: 'auto', border: 'none' }} 
                                                                                />
                                                                            }
                                                                        </a>
                                                                        <ActionButton
                                                                            IconName="Delete"
                                                                            onClick={() => DeleteAttachmentOrCoverLetter(jobApplicantData.JobApplicantId, `${jobApplicantData.ResumeAttachment}`, StandardConst.YesNo.Yes)}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className="col-md-8">
                                                                <FormInputFile
                                                                    name="Attachment"
                                                                    label="Resume Attachment"
                                                                    extensionsAccepted={StandardConst.AcceptExtensionsInUploads.JobApplicantFileExtension}
                                                                    id="InputResumeFile"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-5 d-flex align-items-center justify-content-between"  style={{fontSize: "14px"}}>
                                                        <label>Upload Cover Letter</label>
                                                    </div>
                                                    <div className="col-md-7 p-0 mt-2" style={{paddingLeft: "0px"}}>
                                                        <div className="row">
                                                            {(Object.keys(jobApplicantData).length > 0 &&  jobApplicantData?.CoverLetter !== null) && (
                                                                <>
                                                                    <div className="col-md-4">

                                                                        <a target='_blank' href={`${StandardConst.JobApplicantResume}/${jobApplicantData.CoverLetter}`}>

                                                                            {jobApplicantData.CoverLetter.endsWith('.docx') ? 
                                                                                <img src={`${StandardConst.ProjectImagesInPublicAssets}/wordFile.webp`} alt="" style={{ width: '100px', height: 'auto', border: 'none' }}/>
                                                                            :
                                                                            <iframe 
                                                                                src={`${StandardConst.JobApplicantResume}/${jobApplicantData.CoverLetter}`} 
                                                                                title="Job Cover Letter" 
                                                                                style={{ width: '100px', height: 'auto', border: 'none' }} 
                                                                            />
                                                                            }

                                                                            {/* <iframe 
                                                                                src={`${StandardConst.JobApplicantResume}/${jobApplicantData.CoverLetter}`} 
                                                                                title="Job Cover Letter" 
                                                                                style={{ width: '100px', height: 'auto', border: 'none' }} 
                                                                            /> */}
                                                                        </a>
                                                                        <ActionButton
                                                                            IconName="Delete"
                                                                            onClick={() => DeleteAttachmentOrCoverLetter(jobApplicantData.JobApplicantId, `${jobApplicantData.CoverLetter}`, StandardConst.YesNo.No)}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className="col-md-8">
                                                                <FormInputFile
                                                                    name="AttachmentCoverLetter"
                                                                    label="Upload Cover Letter"
                                                                    extensionsAccepted={StandardConst.AcceptExtensionsInUploads.JobApplicantFileExtension}
                                                                    id="InputCoverLetterFile"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            variant="outline-primary"
                                            type="submit"
                                            id="btnSubmit"
                                            className="px-4 float-end mt-4 mb-1"
                                            style={{ marginRight: "27px" }}
                                        >
                                            {(Object.keys(jobApplicantData).length > 0) ? "Save Changes" : "Save"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    
                </div>
                
            </Container>
        </>
    )
};

const ModalAddJobApplicantEmploymentHistory = forwardRef(({ JobApplicantId, callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [jobApplicantEmploymentHistoryData, setJobApplicantEmploymentHistoryData] = useState({});
    const [keyResponsibilities, setKeyResponsibilities] = useState(null);
    const [achievements, setAchievements] = useState(null);
    const [fromDate, setFromDate] = useState(null);

    useImperativeHandle(ref, () => ({
        openModal: async (JobApplicantEmploymentHistoryId) => {
            if(JobApplicantEmploymentHistoryId > 0){
                await WebService({
                    endPoint: `Recruitment/Edit/fetchJobApplicantEmploymentHistory?JobApplicantEmploymentHistoryId=${JobApplicantEmploymentHistoryId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        setKeyResponsibilities(res[0].KeyResponsibilities);
                        setAchievements(res[0].Achievements);
                        setJobApplicantEmploymentHistoryData(res[0]);
                    }
                });
            }

            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setJobApplicantEmploymentHistoryData({});
        setKeyResponsibilities(null);
        setAchievements(null);
        setFromDate(null);
    };

    const onSubmit = async (data) => {
        handleClose();
        data.FromDate = format(new Date(data.FromDate), "yyyy-MM-dd");
        data.ToDate = format(new Date(data.ToDate), "yyyy-MM-dd");

        if(keyResponsibilities){
            data.KeyResponsibilities = keyResponsibilities;
        }

        if(achievements){
            data.Achievements = achievements;
        }
        
        // let JobApplicantEmploymentHistoryId = data.JobApplicantEmploymentHistoryId;

        if(data.JobApplicantEmploymentHistoryId !== undefined){
            await WebService({
                endPoint: `Recruitment/JobApplicantEmploymentHistoryFormUpdate?JobApplicantEmploymentHistoryId=${data.JobApplicantEmploymentHistoryId}`,
                body: data,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Employment history successfully updated.");
            })
        }else{
            data.JobApplicantId = JobApplicantId;

            await WebService({
                endPoint: `Recruitment/JobApplicantEmploymentHistoryFormSubmission`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    // JobApplicantEmploymentHistoryId = result;
                    WSSuccessAlert("Success", "Employment history successfully saved.");
                }
            })
        }

        callBackEvent();
    };

    const Schema = yup.object().shape({
        CompanyName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        // PolicyNumber: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        // PolicyNumber: yup.number().typeError("type number").required(StandardConst.ValidationMessages.RequiredFieldMsg),
        FromDate: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
        ToDate: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });
    
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose} size={"lg"}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(jobApplicantEmploymentHistoryData).length > 0) ? "Edit Employment History" : "Add Employment History"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={jobApplicantEmploymentHistoryData}
                        onSubmit={onSubmit}
                        validationSchema={Schema}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <FormInputText
                                    label="Company Name"
                                    name="CompanyName"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Designation"
                                    name="Designation"
                                    type="text"
                                    // isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="From Date"
                                    name="FromDate"
                                    type="date"
                                    isRequired="true"
                                    setValue={(val) => setFromDate(val)}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="To Date"
                                    name="ToDate"
                                    type="date"
                                    isRequired="true"
                                    min={fromDate}
                                    disabled={fromDate !== null ? false : true}
                                />
                            </div>

                            <div className="col-md-12 mt-2">
                                <div className="row">
                                    <div className="col-md-5 d-flex align-items-center justify-content-between" style={{fontSize: "14px"}}>
                                        <label>Key Responsibilities</label>
                                    </div>
                                    <div className="col-md-7" style={{paddingLeft: "0px"}}>
                                        <RichTextBox
                                            value={keyResponsibilities}
                                            setValue={setKeyResponsibilities}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-2">
                                <div className="row">
                                    <div className="col-md-5 d-flex align-items-center justify-content-between" style={{fontSize: "14px"}}>
                                        <label>Achievements</label>
                                    </div>
                                    <div className="col-md-7" style={{paddingLeft: "0px"}}>
                                        <RichTextBox
                                            value={achievements}
                                            setValue={setAchievements}
                                        />
                                    </div>
                                </div>
                            </div>
                                    
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(jobApplicantEmploymentHistoryData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

const ModalAddJobApplicantEducationDetails = forwardRef(({ JobApplicantId, callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [jobApplicantEducationDetailsData, setJobApplicantEducationDetailsData] = useState({});


    useImperativeHandle(ref, () => ({
        openModal: async (JobApplicantEducationDetailId) => {
            if(JobApplicantEducationDetailId > 0){
                await WebService({
                    endPoint: `CommonUtility/Edit/jobapplicanteducationdetails?JobApplicantEducationDetailId=${JobApplicantEducationDetailId}`,
                    dispatch,
                }).then((res) => {
                    if(res.length > 0){
                        res[0].PassingMonthYear = (res[0].PassingMonthYear !== '' && res[0].PassingMonthYear !== null) ? format(new Date(res[0].PassingMonthYear), 'yyyy-MM') : null;
                        setJobApplicantEducationDetailsData(res[0]);
                    }
                });
            }

            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setJobApplicantEducationDetailsData({});
    };

    const onSubmit = async (data) => {

        handleClose();

        if(data.JobApplicantEducationDetailId !== undefined){
            const updatedData = {
                PassingMonthYear: data.PassingMonthYear, 
                DegreeOrCourse : data.DegreeOrCourse,
                PercentageOrGrade : data.PercentageOrGrade
            }

            await WebService({
                endPoint: `CommonUtility/jobapplicanteducationdetails?JobApplicantEducationDetailId=${data.JobApplicantEducationDetailId}`,
                body: updatedData,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Employment educational detail successfully updated.");
            });
        }else{
            data.JobApplicantId = JobApplicantId;

            await WebService({
                endPoint: `CommonUtility/jobapplicanteducationdetails`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    // JobApplicantEducationDetailsId = result;
                    WSSuccessAlert("Success", "Employment educational detail successfully saved.");
                }
            })
        }

        callBackEvent();
    };

    const Schema = yup.object().shape({
        PassingMonthYear: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        DegreeOrCourse: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        PercentageOrGrade: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });
    
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(jobApplicantEducationDetailsData).length > 0) ? "Edit Educational Detail" : "Add Educational Detail"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={jobApplicantEducationDetailsData}
                        onSubmit={onSubmit}
                        validationSchema={Schema}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <FormInputText
                                    label="Passing Month-Year"
                                    name="PassingMonthYear"
                                    type="MonthYear"
                                    isRequired="true"
                                    autocomplete="false"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Degree/Course"
                                    name="DegreeOrCourse"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Percentage/Grade"
                                    name="PercentageOrGrade"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(jobApplicantEducationDetailsData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

const ModalAddJobApplicantCertification = forwardRef(({ JobApplicantId, callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [jobApplicantCertificationData, setJobApplicantCertificationData] = useState({});


    useImperativeHandle(ref, () => ({
        openModal: async (JobApplicantCertificationId) => {
            if(JobApplicantCertificationId > 0){
                await fetchCertificationData(JobApplicantCertificationId);
            }

            setShow(true);
        },
    }));

    const fetchCertificationData = async (JobApplicantCertificationId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/jobapplicantcertifications?JobApplicantCertificationId=${JobApplicantCertificationId}`,
            dispatch,
        }).then((res) => {
            if(res.length > 0){
                res[0].CertificationMonthYear = (res[0].CertificationMonthYear !== '' && res[0].CertificationMonthYear !== null) ? format(new Date(res[0].CertificationMonthYear), 'yyyy-MM') : null;
                setJobApplicantCertificationData(res[0]);
            }
        });
    }

    const handleClose = () => {
        setShow(false);
        setJobApplicantCertificationData({});
    };

    const onSubmit = async (data) => {
        handleClose();

        if(data.Attachment.length !== 0){
            await WebService({
                endPoint: "upload/File?destination=Recruitment/Certification",
                dispatch,
                body: data.Attachment,
                isFile: true,
            }).then((res) => {
                data.CertificationAttachment = res.filename;
            });
        }

        let JobApplicantCertificationId = data.JobApplicantCertificationId;

        if(JobApplicantCertificationId !== undefined){
            const updatedData = {
                CertificationMonthYear: data.CertificationMonthYear,
                CertifiationName : data.CertifiationName,
                CertificationBody : data.CertificationBody,
                CertificationAttachment : data.CertificationAttachment !== undefined ? data.CertificationAttachment : null
            }

            await WebService({
                endPoint: `CommonUtility/jobapplicantcertifications?JobApplicantCertificationId=${JobApplicantCertificationId}`,
                body: updatedData,
                method: 'PUT',
                dispatch,
            }).then((res) => {
                WSSuccessAlert("Success", "Employment educational detail successfully updated.");
            });
        }else{
            data.JobApplicantId = JobApplicantId;

            JobApplicantCertificationId = await WebService({
                endPoint: `CommonUtility/jobapplicantcertifications`,
                body: data,
                method: 'POST',
                dispatch,
            }).then((result) => {
                if(result > 0){
                    // JobApplicantCertificationId = result;
                    WSSuccessAlert("Success", "Employment educational detail successfully saved.");
                }
            })

        }

        callBackEvent();
    };

    const Schema = yup.object().shape({
        // CertificationMonthYear: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        CertifiationName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        // CertificationBody: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
    });


    const DeleteAttachment = async (JobApplicantCertificationId, JobApplicantId, Path) => {
        await WebService({
            endPoint: `Recruitment/deleteCertificationAttachment`,
            body: {JobApplicantId, Path},
            method: 'DELETE',
            dispatch
        }).then(res => {
            if(JobApplicantCertificationId > 0){
                fetchCertificationData(JobApplicantCertificationId);
                jobApplicantCertificationData.CertificationAttachment = null;
            }
            WSSuccessAlert("Success", "Your file has been deleted successfully.");
        })
    };
    
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(jobApplicantCertificationData).length > 0) ? "Edit Certification" : "Add Certification"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={jobApplicantCertificationData}
                        onSubmit={onSubmit}
                        validationSchema={Schema}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <FormInputText
                                    label="Certification Month-Year"
                                    name="CertificationMonthYear"
                                    type="MonthYear"
                                    autocomplete="false"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Certifiation Name"
                                    name="CertifiationName"
                                    type="text"
                                    isRequired="true"
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputText
                                    label="Certification Body"
                                    name="CertificationBody"
                                    as="textarea"
                                    rows="2"
                                />
                            </div>
                            
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-5 d-flex align-items-center justify-content-between" style={{fontSize: "14px"}}>
                                        <label>Certification Attachment</label>
                                    </div>
                                    <div className="col-md-7 p-0 mt-2" style={{paddingLeft: "0px"}}>
                                        <FormInputFile
                                            name="Attachment"
                                            label="Certification Attachment"
                                            extensionsAccepted={StandardConst.AcceptExtensionsInUploads.JobApplicantFileExtension}
                                            id="InputCertificationFile"
                                        />
                                        {(Object.keys(jobApplicantCertificationData).length > 0 &&  jobApplicantCertificationData.CertificationAttachment !== null) && (
                                            <>
                                                <div className="col-md-4">

                                                    <a target='_blank' href={`${StandardConst.JobApplicantCertification}/${jobApplicantCertificationData.CertificationAttachment}`}>
                                                        <iframe 
                                                            src={`${StandardConst.JobApplicantCertification}/${jobApplicantCertificationData.CertificationAttachment}`} 
                                                            title="Job Applicant Resume" 
                                                            style={{ width: '100px', height: 'auto', border: 'none' }} 
                                                        />
                                                    </a>
                                                    <ActionButton
                                                        IconName="Delete"
                                                        onClick={() => DeleteAttachment(jobApplicantCertificationData?.JobApplicantCertificationId ?? 0, jobApplicantCertificationData.JobApplicantId, jobApplicantCertificationData.CertificationAttachment)}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(jobApplicantCertificationData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

const ModalAddJobApplicantSkill = forwardRef(({ JobApplicantId, callBackEvent }, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [jobApplicantSkillData, setJobApplicantSkillData] = useState({});
    const [staticSkillsData, setStaticSkillsData] = useState([]);

    useImperativeHandle(ref, () => ({
        openModal: async (JobApplicantSkillId) => {
            if(JobApplicantSkillId > 0){
                await fetchSkillData(JobApplicantSkillId);
            }

            setShow(true);
        },
    }));

    const fetchSkillData = async (JobApplicantSkillId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/jobapplicantskills?JobApplicantSkillId=${JobApplicantSkillId}`,
            dispatch,
        }).then((res) => {
            if(res.length > 0){
                res[0].SkillId = [staticSkillsData.find(item => item.value === res[0].SkillId)]
                setJobApplicantSkillData(res[0]);
            }
        });
    }

    const handleClose = () => {
        setShow(false);
        setJobApplicantSkillData({});
    };

    const onSubmit = async (data) => {

            handleClose();

            if(Array.isArray(data.SkillId)){
                data.SkillId = data.SkillId[0].value;
            }else{
                data.SkillId = data?.SkillId?.value;
            }

            if(data.JobApplicantSkillId !== undefined){
                const updatedData = {
                    SkillId: data.SkillId,
                    SkillType : data.SkillType,
                    SkillProficiencyLevel : data.SkillProficiencyLevel,
                }
    
                await WebService({
                    endPoint: `CommonUtility/jobapplicantskills?JobApplicantSkillId=${data.JobApplicantSkillId}`,
                    body: updatedData,
                    method: 'PUT',
                    dispatch,
                }).then((res) => {
                    WSSuccessAlert("Success", "Skill successfully updated.");
                });
            }else{
                data.JobApplicantId = JobApplicantId;
    
                await WebService({
                    endPoint: `CommonUtility/jobapplicantskills`,
                    body: data,
                    method: 'POST',
                    dispatch,
                }).then((result) => {
                    if(result > 0){
                        WSSuccessAlert("Success", "Skill successfully saved.");
                    }
                })
    
            }
            callBackEvent();
    };

    const Schema = yup.object().shape({
        SkillId: yup
            .array()
            .of(
                yup.object().shape({
                text: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
                })
            )
            .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
            .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
    });

    const fetchStaticSkills = async () => {
        await WebService({
            endPoint: `Recruitment/fetchAllStaticSkills`,
            method: "GET",
            dispatch
        }).then((res) => res.length > 0 ? setStaticSkillsData(res.map(item => ({value: item.SkillId, text: item.SkillName}))) : []);
    };

    useEffect(() => {
        fetchStaticSkills();
    }, []);
    
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(jobApplicantSkillData).length > 0) ? "Edit Skill" : "Add Skill"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={jobApplicantSkillData}
                        onSubmit={onSubmit}
                        validationSchema={Schema}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <FormAutoCompleteDropdown
                                    label="Select Skill"
                                    name="SkillId"
                                    data={staticSkillsData}
                                    optionText="text"
                                    autoSelectAttributeValue={true}
                                    isRequired={true}
                                />
                            </div>

                            <div className="col-md-12">
                                <FormInputDropdown
                                    label="Skill Type"
                                    name="SkillType"
                                    ddOpt={StandardConst.SkillType}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInputDropdown
                                    label="Skill Proficiency Level"
                                    name="SkillProficiencyLevel"
                                    ddOpt={StandardConst.SkillProficiencyLevel}
                                />
                            </div>
                            
                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    {(Object.keys(jobApplicantSkillData).length > 0) ? "Save Changes" : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});

export default JobApplicantForm;
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { WebService } from "../../../Services/WebService";
import { Form, FormInputText, FormInputDropdown } from "../../Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import SweetAlert from "sweetalert2";
import { WSInfoAlert } from "../../../Services/WSAlert";

const { forwardRef, useState, useImperativeHandle } = React;
const AddEditSalaryARInvoice = (prop, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ ARInvoice: {} });
  useImperativeHandle(ref, () => ({
    openModal: async (id) => {
      const data = await WebService({
        endPoint: `SalaryARInvoice/Fetch/${id || 0}`,
        dispatch,
      });
      data.ARType = data.ARType.map((v) => {
        return {
          value: v.ARTypeId,
          text: v.DisplayDescription,
        };
      });

      data.Employee = data.Employee.map((v) => {
        return {
          value: v.id,
          text: v.name,
        };
      });
      setData(data);
      setShow(true);
    },
  }));
  const handleClose = () => setShow(false);
  const requiredMessage = "This is a required field";
  const schema = yup
    .object()
    .shape({
      EmployeeId: yup.string().trim().required(requiredMessage),
      ARTypeId: yup.string().trim().required(requiredMessage),
      TransactionNo: yup
        .number()
        .integer("Transaction No must be a number")
        .label("Transaction No")
        .typeError("Transaction No must be a number")
        .positive("Please enter a  number")
        .required(requiredMessage),
      TransactionDate: yup.string().trim().required(requiredMessage),
      TransactionMode: yup.string().trim().required(requiredMessage),
      Amount: yup
        .number()
        .integer("Amount must be an integer")
        .label("Amount")
        .typeError("Amount must be a number")
        .positive("Please enter a  number")
        .required(requiredMessage),
    })
    .required();

  const onSubmit = async (data) => {
    //alert(JSON.stringify(data));
    const responsedata = await WebService({
      endPoint: "SalaryARInvoice/Submit",
      body: data,
      dispatch,
    });
    WSInfoAlert("Info", responsedata);
    handleClose();
    prop.callBackEvent();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {(data.ARInvoice.ARInvoiceId || 0) === 0
            ? "Add Salary Invoice"
            : "Edit Salary Invoice"}
        </Modal.Title>
      </Modal.Header>
      <Form
        defaultValues={data.ARInvoice}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        <Modal.Body className="p-4">
          <div className="row mt-1">
            <FormInputDropdown
              name="EmployeeId"
              ddOpt={data.Employee}
              label="Employee Name"
              isRequired="true"
            ></FormInputDropdown>
          </div>
          <div className="row mt-1">
            <FormInputDropdown
              name="ARTypeId"
              ddOpt={data.ARType}
              label="AR Type"
              isRequired="true"
            ></FormInputDropdown>
          </div>
          <div className="row mt-1">
            <FormInputText
              name="TransactionNo"
              label="Transaction No"
              isRequired="true"
              type="number"
            />
          </div>
          <div className="row mt-1">
            <FormInputText
              label="Transaction Date"
              name="TransactionDate"
              isRequired="true"
              type="date"
            />
          </div>
          <div className="row mt-1">
            <FormInputText
              label="Transaction Mode"
              name="TransactionMode"
              isRequired="true"
              type="text"
            />
          </div>
          <div className="row mt-1">
            <FormInputText
              label="Amount"
              isRequired="true"
              name="Amount"
              type="text"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btnARInvoiceSubmit"
            variant="outline-primary"
            type="submit"
          >
            {" "}
            {(data.ARInvoice.ARInvoiceId || 0) === 0
              ? "Submit"
              : "Save Changes"}{" "}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default forwardRef(AddEditSalaryARInvoice);

import React, { useState, useEffect, useRef } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
import AddEditVisitorType from "./AddEditVisitorType";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";
const apiUrl = "CommonUtility/static_visitor_type";
const VisitorTypeMaster = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Manage Visitor Type" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});
  const navigate = useNavigate();
  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Visitor Type - Add"),
      ManageEdit: ActionPermission("Visitor Type - Edit"),
      ManageDelete: ActionPermission("Visitor Type - Delete"),
    });
  }, [permissionList]);

  const fetchVisitorType = async () => {
    const data = await WebService({
      // endPoint: apiUrl,
      endPoint: `Visitor/VisitorTypeDetails?SendCompanySpecificData=${StandardConst.YesNo.Yes}`,
      dispatch,
    });
    setRecords(data);
    dispatch(ConfigurationflowStatusUpdate());
  };

  const onDelete = async (VisitorTypeId) => {
    await WebService({
      endPoint: `${apiUrl}?VisitorTypeId=${VisitorTypeId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchVisitorType();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchVisitorType();
    }
    renderAfterCalled.current = true;
  }, []);
  const columns = [
    {
      Text: "Visitor Type",
      Value: "TypeName",
      render: (dr) => {
        return <><span>{dr.TypeName}</span>  &nbsp;&nbsp; {(dr.IsEventVisitorType === StandardConst.YesNo.Yes) && (
          <Chip 
            label="Event"
            sx={{ color: "white", padding: "0px"}}
            color= "primary"
            size="small"
          />
        )}  &nbsp;&nbsp;
        {(dr.IsReserved === StandardConst.YesNo.Yes) && (
          <Chip 
              label="Reserved"
              sx={{ color: "white", padding: "0px"}}
              color= "primary"
              size="small"
          />
        )}
        </>
      }
    },
    {
      Text: "Visitor Badge Template",
      Value: "badge_template_name",
    },

    {
      Text: "Action",
      key: "VisitorTypeId",
      cssClass: "text-end",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          {(dr.IsReserved === 0 || (dr.IsReserved === 1 && Roles[0] === StandardConst.SystemRole.SuperAdmin)) && (
          <>
            <ActionButton
              onClick={() => fnEdit(dr.VisitorTypeId)}
              disabled={!permission.ManageEdit}
              IconName="Edit"
              id="btnVisitorTypeEdit"
            />

            <ActionButton
              onClick={(e) =>
                ref.current.confirmAlert(
                  "Delete", //Confirm button text
                  "Are You Sure", // Text if Alert
                  "Do you want to delete " + MasterPageName, // Message of Alert
                  dr.VisitorTypeId // Endpoint to hit for delete
                )
              }
              disabled={!permission.ManageDelete}
              IconName="Delete"
              id="btnVisitorTypeDelete"
            />
          </>
          )}
          <ActionButton
            IconTooltip={"Manage Badge Templates"}
            onClick={() => navigate("/ManageBadgeTemplates")}
            IconName="Badge"
            id="btnBadge"
          />
        </>
      ),
    },
  ];

  if (Roles[0] === StandardConst.SystemRole.SuperAdmin){
    columns.splice(1, 0, {
        Text: "Access",
        render: (dr) => {
          return (dr.CompanyName !== null) ? dr.CompanyName : "All Companies";
        }
    });
  }

  const [bData, setBData] = React.useState([
    {
      title: "Visitor Management",
      hrefLink: "#",
    },
    {
      title: "Visitor Type",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Manage Visitor Types";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "Visitor Type";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={StandardConst.ManageVisitorheaderValueNoResults}
            subHeaderValue={StandardConst.ManageVisitorSubHeaderRole}
            imageUrl={StandardConst.imageNoRecordsFound}
            actionButton={
              <>
                {permission.ManageAdd && (
                  <Button id="NoRecordFoundAddVisitorType" variant="outline-primary" onClick={() => fnEdit(0)}>
                    Add {AddButtonText}
                  </Button>
                )}
              </>
            }
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <AddEditVisitorType
        callBackEvent={() => fetchVisitorType()}
        ref={addEditModalRef}
      ></AddEditVisitorType>
    </>
  );
};
export default VisitorTypeMaster;

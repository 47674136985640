import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormInputDropdown, FormInputText } from '../Form';
import { WebService } from '../../Services/WebService';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StandardConst } from '../../Services/StandardConst';


const AddEditTicketCategory = (props, ref) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ TicketCategory: {} });
    const dispatch = useDispatch();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const isSuperAdmin = Roles?.some(element => element === StandardConst.SystemRole.SuperAdmin);
    const [ParentTicketCategory, setParentTicketCategory] = useState([]);

    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            setShow(true);
            fetchTicketCategories(id);
            if (id === 0) {
                const data = {
                    TicketCategory: {},
                };
                setData(data);
            } else {
                setShow(false);
                await fetchEditValue(id);
                setShow(true);
            }
        },
    }));

    const fetchTicketCategories = async (id) =>  {
        const data = await WebService({
            endPoint: `CommonUtility/Edit/ticketcategories?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
        });

        const modifiedData = data?.filter((item) => item.ParentId === null);

        let list = [{text: "", value: null}].concat(modifiedData?.map((res) => {
            return { text: res.CategoryName, value: res.TicketCategoryId}
        }));
        
        if(id){
            list = list.filter((obj) => obj.value !== id);
        }
        setParentTicketCategory(list);
    }

    const fetchEditValue = async (id) => {
        const data = {
            TicketCategory: await WebService({
                endPoint: `CommonUtility/Edit/ticketcategories?TicketCategoryId=${id || 0}`,
                dispatch,
            }).then((c) => (c.length > 0 ? c[0] : {})),
        };
        setData(data);
    }

    const handleClose = () => {
        setShow(false)
        setData({TicketCategory : {}});
    };

    const schema = yup
        .object()
        .shape({
            CategoryName: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
            // Status: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        })
        .required();

    const onSubmit = async (data) => {
        data.CompanyId = companyInfo.CompanyId;
        if (data.TicketCategoryId === undefined) {
            await WebService({
                endPoint: "CommonUtility/ticketcategories",
                body: data,
                dispatch,
            });
        } else {
            await WebService({
                endPoint: `CommonUtility/ticketcategories?TicketCategoryId=${data.TicketCategoryId}`,
                method: "PUT",
                body: data,
                dispatch,
            });
        }
        handleClose();
        props.callBackEvent();
    }

    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {(data && data.TicketCategory && data.TicketCategory.TicketCategoryId || 0) === 0
                        ? "Add Ticket Category"
                        : "Edit Ticket Category"
                    }
                </Modal.Title>
            </Modal.Header>
            <Form
                defaultValues={data.TicketCategory}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                <Modal.Body className="p-4">
                    <div className="row">
                        <div className="col-md-12">
                            <FormInputText
                                label="Category Name"
                                name="CategoryName"
                                type="text"
                                isRequired="true"
                            />
                        </div>
                        <div className="col-md-12">
                            <FormInputDropdown
                                name="ParentId"
                                ddOpt={ParentTicketCategory}
                                label="Parent"
                            />
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAccessLevelSubmit" variant="outline-primary" type="submit">
                        {" "}
                        {(data && data.TicketCategory && data.TicketCategory.TicketCategoryId || 0) === 0
                            ? "Submit"
                            : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default forwardRef(AddEditTicketCategory)
import React, { useEffect, useRef, useState } from 'react'
import SnackbarComponent from '../../Services/SnackbarComponent'
import { useDispatch, useSelector } from "react-redux"
import { PageInfo } from '../PageInfo'
import { WebService } from '../../Services/WebService'
import TableComponent, { NoRecordTemplate } from '../../Services/TableComponent'
import { StandardConst } from '../../Services/StandardConst'
import { useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { Box, Container, Typography, Stack, Avatar } from "@mui/material";
import { Form, FormInputDropdown } from '../Form'
import _ from "underscore";
import { DateTime } from 'luxon';


const AssetLocation = () => {

    const refSnackbar = useRef();
    const MasterPageName = "Asset Inventory Report";
    const confirmMessage = MasterPageName + " Deleted successfully";
    const AddButtonText = "Asset Inventory Report";
    const dispatch = useDispatch();
    const [records, setRecords] = useState([]);
    const [officeLocationIds, setOfficeLocationIds] = useState([]);
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const navigate = useNavigate();

    PageInfo({ pageTitle: "Asset Inventory Report" });

    const [bData] = useState([
        {
            title: "Asset Management",
            hrefLink: "#",
        },
        {
            title: "Asset Inventory Report",
            hrefLink: "#",
        },
    ]);

    const columns = [
        {
            Text: "Asset Number",
            Value: "AssetNumber"
        },
        {
            Text: "Asset Name",
            Value: "AssetName",
            render: (dr) => {
                return (
                    <>
                        <div className="d-flex">
                            <Avatar
                                alt='no-image'
                                src={dr.image ? `${StandardConst.apiBaseUrl}/uploads/assetImages/${dr.image}` :
                                    `${StandardConst.apiBaseUrl}/uploads/3720094.png`
                                }
                            />
                            <div className='px-2'>
                                <span onClick={() => navigate("/AddAssetInformation", { state: { AssetId: dr.AssetId } })} className='text-nowrap cursor-pointer'>{dr.AssetName}</span>
                                <div>
                                    <i className={`me-2 ${dr.AssetTypeIcon}`}></i>
                                    <span>
                                    {dr.AssetTypeName}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        },
        {
            Text: "Office Location",
            Value: "finalAddress",
        },
        {
            Text: "Description",
            Value: "Description",
            cssClass: "d-none"
        },
        {
            Text: "Asset Tag Id",
            Value: "AssetTagId"
        },
        {
            Text: "Person",
            Value: "PersonName",
            render: (dr) => {
                return (
                    <div>
                            <div className="assignee-details d-flex">
                                <div className="me-2">
                                    {
                                        dr.PersonImage ?
                                            <Avatar
                                                alt='no-image'
                                                src={`${StandardConst.apiBaseUrl}/uploads/${dr.PersonImage}`}
                                            />
                                            :
                                            dr.PersonName ?
                                                <Avatar alt='no-image'>{dr.PersonName.charAt(0)}</Avatar>
                                                :
                                                ""
                                    }
                                </div>
                                <div className="text-nowrap">
                                    {dr.PersonName}
                                    <div className='text-muted'>
                                    {dr.IssueDate && DateTime.fromISO(dr.IssueDate).toFormat("dd-MM-yyyy")}
                                </div>
                                </div>
                            </div>
                        </div>
                )
            }
        },
        {
            Text: "Status",
            Value: "Status"
        },
    ];

    useEffect(() => {
        fetchOfficeLocation();
    }, []);

    const fetchOfficeLocation = async () => {
        const data = await WebService({
            endPoint: `CommonUtility/fetchAll/officelocation?CompanyId=${companyInfo.CompanyId}`,
            dispatch,
        });
        let list = [{text: "All", value: 0}].concat(data.map((r) => {
            const words = r.Address.split(' ');
            const truncatedWords = words.slice(0, 3);
            const truncatedAddress = truncatedWords.join(' ');
            const finalAddress = truncatedAddress + ', ' + r.Location;
            return { text: finalAddress, value: r.OfficeLocationId }
        }));
        if (list.length === 0) {
            list = [{ text: "No Office Location" }]
        }
        setOfficeLocationIds(list);
    }

    const handleCurrentOfficeLocationId = async(currentOfficeLocationId) => {
        setRecords([]);
        let endPoint = ``;
        if(currentOfficeLocationId){
            endPoint = `AssetAssignment/fetchAllAssets?OfficeLocationId=${currentOfficeLocationId}`;
        }else {
            endPoint = `AssetAssignment/fetchAllAssets`;
        }
        const data = await WebService({
            endPoint: endPoint,
            dispatch,
        });
        const modifiedArray = await Promise.all(data.map(async(item) => {
            
            const foundStatus = StandardConst?.AssetStatusDropdown?.find(element => element?.value === item?.Status);
            item.Status = foundStatus?.text;
            if (item.Address || item.Location) {
                const words = item.Address && item.Address?.split(' ');
                const truncatedWords = words?.slice(0, 3);
                const truncatedAddress = truncatedWords?.join(' ');
                const finalAddress = truncatedAddress + ', ' + item?.Location;
                item.finalAddress = finalAddress;
            }
            
            const imageArray = await WebService({
                endPoint: `CommonUtility/assetimages?AssetId=${item.AssetId || 0}`,
                dispatch,
            });
            imageArray.map((img) => {
                if(img.AssetId === item.AssetId) {
                    _.extend(item, { image: img.AssetImageName });
                }
            });

            if(item.EmployeeId){
                item.PersonName = item.EmployeeName;
                item.PersonImage = item.EmpImage;
            }
            if(item.UserId){
                item.PersonName = item.UserName;
                item.PersonImage = item.UserImage;
            }

            return  item;
        }));
        setRecords(modifiedArray);
    }

    return (
        <>
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <h3 className="ms-4 mt-2">{MasterPageName}</h3>
                            <div className="ms-4">
                                <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                            </div>
                        </div>
                    </div>
                </Box>
                <Form>
                    <Stack spacing={2} alignItems="center" direction="row" sx={{ margin: "0.5rem 0 0.5rem 1.5rem" }}>
                        <Typography
                            sx={{ fontFamily: "sans-serif", fontSize: "14px" }}
                        >
                            Office Location
                        </Typography>
                        <Stack sx={{ width: '200px' }}>
                            <FormInputDropdown
                                name="OfficeLocationId"
                                ddOpt={officeLocationIds}
                                setValue={(currentOfficeLocationId) => { handleCurrentOfficeLocationId(Number(currentOfficeLocationId)) }}
                            />
                        </Stack>
                    </Stack>
                </Form>
                <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} /> 
                <TableComponent
                    columns={columns}
                    data={records}
                    noRecordCss="p-0"
                    noRecordFound={
                        <NoRecordTemplate
                            headerValue={StandardConst.AssetLocationheaderValueNoResults}
                            subHeaderValue={StandardConst.AssetLocationSubHeaderRole}
                            imageUrl={StandardConst.imageNoRecordsFound}
                        />
                    }
                    isSearchRequired={true}
                    onAddEvent={() => {}}
                    title={AddButtonText}
                    IsAddButtonVisible={false}
                    allowSerialNo={true}
                />
            </Container>
        </>
    )
}

export default AssetLocation
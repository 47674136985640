import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { WebService } from "../../Services/WebService";
import { ActionPermission, PageInfo } from "../PageInfo";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import { Box, Grid, Chip, CardHeader, IconButton, TablePagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Topbar from "../../Services/TopbarComponent";
import { StandardConst } from '../../Services/StandardConst';
import { Form, FormAutoCompleteDropdownCard, FormInputText, InputDropdown } from '../Form';
import Button from "react-bootstrap/Button";
import { NoRecordTemplate } from '../../Services/TableComponent';
import ActionButton from '../../Services/ActionButton';
import { chain, extend, filter, map } from "underscore";
import { WSSuccessAlert } from '../../Services/WSAlert';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format } from 'date-fns';
import JobViewModal from './JobViewModal';
import { CurrencyFormat } from '../../Services/DisplayCurrencyFormat';
import { dataClone } from '../../Services/UtilityService';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { Diversity1Sharp } from '@mui/icons-material';


const JobListing = () => {
    PageInfo({ pageTitle: "Job List" });

    const refJobViewModal = useRef();
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});
    const Roles = useSelector((s) => s.auth.AssignRole ?? {});
    const EmployeeId = useSelector((s) => s.auth.LoggedUser ?? {});
    const UserId = useSelector((s) => s.auth.LoggedCustomer ?? {});

    // ye original data ko store krti hai
    const [jobsData, setJobsData] = useState([]);
    // ye state filter state hai jo ki JobApplicant ke time me filter se or first time data get krne ki liye set hoti hai
    const [filteredJobData, setFilteredJobData] = useState([]);
    const [jobApplicantId, setJobApplicantId] = useState(null);
    const [value, setValue] = useState(0);
    const [isJobApplicantState, setIsJobApplicantState] = useState(false);


    const permissionList = useSelector((s) => s.auth.PermissionList ?? []);
    const [permission, SetPermission] = useState({});
    const [records, setRecords] = useState([]);
    const [appliedJobList, setAppliedJobList] = useState([]);
    
    const [selectedFilter, setSelectedFilter] = useState('All');

    const pageOptions = [20, 40, 60, 80];
    const pageSize = pageOptions[0];
    const [countOfTotalRecords, setCountOfTotalRecords] = useState(0);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);
    const [pageSizeOptions, setPageSizeOptions] = useState([]);

    useEffect(() => {
        setFilterDataWithPagination();
    }, [jobsData, selectedFilter, page]);

    useEffect(() => {
        if (page === 1) setFilterDataWithPagination();
      }, [rowsPerPage]);

    const setFilterDataWithPagination = () => {
        var data = jobsData;
        if(selectedFilter === 'Applied'){
            data = data.filter(item => appliedJobList.some((job) => job === item.JobId));
        }


        setCountOfTotalRecords(data?.length ?? 0);
        if (rowsPerPage > 0 && page > 0) {
            var skipValue = rowsPerPage * (page - 1);
            var takeValue = rowsPerPage * page;
            data = filter(
              map(data, (r, i) => extend(r, { index: i })),
              (f) => f.index > skipValue - 1 && f.index < takeValue
            );
        }
        setFilteredJobData(data);
    }

    useEffect(() => {
        var options = dataClone(pageOptions);
        if (countOfTotalRecords > 0) {
          options = filter(options, (f) => f <= countOfTotalRecords);
        }
        setPageSizeOptions(options);
      }, [countOfTotalRecords]);

    useEffect(() => {
        setTimeout(() => {
          if (
            (countOfTotalRecords ?? 0) > 0 &&
            (page ?? 1) > 1 &&
            (rowsPerPage ?? 0) > 0
          ) {
            const s1 = countOfTotalRecords % rowsPerPage;
            let s2 = (countOfTotalRecords - s1) / rowsPerPage;
            if (s1 > 0) s2 += 1;
            if (page > s2) setPage(s2);
          }
        });
    }, [countOfTotalRecords, page, rowsPerPage]);

    useEffect(() => {
        SetPermission({
            ManageAdd:  ActionPermission("Manage Job Posting - Add"),
            ManageEdit: ActionPermission("Manage Job Posting - Edit"),
            ManageDelete: ActionPermission("Manage Job Posting - Delete"),
            ViewJobApplicantList : ActionPermission("View Job Applicant List")
        });
    }, [permissionList]);

    const getAppliedJobList = async (jobApplicantId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/jobapplications?JobApplicantId=${jobApplicantId}`,
        }).then((res) => setAppliedJobList(res.map(item => (item.JobId))))
    }

    useEffect(() => {
        if(jobApplicantId !== null) {
            getAppliedJobList(jobApplicantId);
        }
    },[jobApplicantId]);


    const [bData] = useState([
        {
            title: "Recruitment",
            hrefLink: "/RecruiterDashboard",
        },
        {
            title: "Jobs",
            hrefLink: "#",
        },
    ]);

    const fetchAllJobPosting = async (SearchData) => {
        SearchData ??= null;
        let EndPoint = `Recruitment/fetchJobPostingData`;
        if(SearchData !== null) {
            EndPoint += `?TitleOrCompanySearch=${SearchData.TitleOrCompanySearch ?? ''}&CityOrStateSearch=${SearchData.CityOrStateSearch ?? ''}`;
        }
        
        await WebService({
            endPoint: EndPoint,
            method: "GET",
            dispatch
        }).then(response => {
            setJobsData(response);
        });
    }

    const fetchLoggedInUserJobPosting = async (SearchData) => {
        SearchData ??= null;

        let EndPoint = "Recruitment/fetchLoggedInJobPostingData";
        if(SearchData !== null) {
            EndPoint += `?TitleOrCompanySearch=${SearchData.TitleOrCompanySearch ?? ''}&CityOrStateSearch=${SearchData.CityOrStateSearch ?? ''}&SearchCustomerId=${SearchData?.CustomerId !== null ? SearchData?.CustomerId[0]?.uniqueId ?? '' : ''}`;
        }
        await WebService({
            endPoint: EndPoint,
            method: "GET",
            dispatch
        }).then(response => {
            setJobsData(response);
        });
    }

    const getJobApplicantIdByUserId = async (userId) => {
        await WebService({
            endPoint: `CommonUtility/Edit/jobapplicants?UserId=${userId}`,
        }).then(res => res.length > 0 ? setJobApplicantId(res[0].JobApplicantId) : setJobApplicantId(null));
    };

    const [customerList, setCustomerList] = useState([]);
    const FetchCustomerList = async() => {
        const data = await WebService({
          dispatch,
          endPoint: `CommonUtility/customerdetails`
        });
        const list = data?.filter((r) => r.FullName !== null)?.map((res) => {
          return {
              uniqueId: res.CustomerId,
              heading: res.Name, 
              description: '', 
              avatar: ''
            //   avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        setCustomerList(list);
      }

    useEffect(() => {
        if(Roles.length > 0){
            FetchCustomerList();
            fetchLoggedInUserJobPosting();
            const isJobApplicant = Roles.some((role) => role === StandardConst.SystemRole.JobApplicant);
            setIsJobApplicantState(isJobApplicant);
            if(isJobApplicant && UserId){
                getJobApplicantIdByUserId(UserId);
            }
        }else{
            fetchAllJobPosting(null);
        }
    }, [Roles]);

    const setNotificationAndMail = async (JobId, jobApplicantId) => {
        const fetchJobData = await WebService({
            endPoint: `CommonUtility/Edit/jobs?jobId=${JobId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? res[0] : null);

        const JobApplicantName = await WebService({
            endPoint: `Recruitment/fetchJobApplicantDetails?JobApplicantId=${jobApplicantId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? res[0].FullName : null);

        // Here we send notification
        // if(fetchJobData !== null && fetchJobData.EmployeeId !== null && JobApplicantName !== null){
        //     await WebService({
        //         dispatch,
        //         endPoint: "Notification/sendSystemNotification",
        //         body: {
        //           Message: `${format(new Date(), 'dd-MM-yyyy')} - ${JobApplicantName} applied for ${fetchJobData.Title}`,
        //           SendTo: [fetchJobData.EmployeeId],
        //           Expire: addDays(new Date(), 30),
        //         },
        //     });
        // }

        // Here we send email
        if(fetchJobData !== null && fetchJobData.EmployeeId !== null && JobApplicantName !== null){
            await WebService({
                dispatch,
                endPoint: "Recruitment/SendMailForRecruiter",
                method: "POST",
                body: {
                    ApplicationDate: format(new Date(), 'dd-MM-yyyy'),
                    ApplicantName: JobApplicantName,
                    JobTitle : fetchJobData.Title,
                    EmployeeId: fetchJobData.EmployeeId
                }
            });
        }
    }

    const jobApply = async(JobId) => {
        if(jobApplicantId !== null){
            const data = {
                JobId : JobId,
                JobApplicantId : jobApplicantId,
                StatusId : StandardConst.Status.Applied,
                ApplicationDate : format(new Date(), 'yyyy-MM-dd HH:mm:ss')
            }

            await WebService({
                endPoint : `Recruitment/insertJobApplication`,
                body : data,
                method : 'POST',
                dispatch
            }).then((res) => {
                getAppliedJobList(jobApplicantId);
                setNotificationAndMail(JobId, jobApplicantId);
                WSSuccessAlert("Successfully Appplied");
            });
        }else{
            navigate("/NonCompanyUserLogin");
        }
    }
      
    const onDelete = async (JobId) => {
        await WebService({
            endPoint : `CommonUtility/jobs?JobId=${JobId}`,
            method : 'DELETE',
            dispatch
        }).then((res) => {
            fetchLoggedInUserJobPosting();
            WSSuccessAlert("Success", "Successfully Deleted")
        });
    };

    const onSearchSubmit = async (data) => {
        if(Roles.length > 0){
            fetchLoggedInUserJobPosting(data);
        }else{
            fetchAllJobPosting(data);
        }
    };

    const fnViewModal = (JobId, isApplied) => refJobViewModal.current.openModal(JobId, isApplied);

    const renderSalary = (data) => {
        switch (data.SalaryRange) {
            case "Exact Salary":
                return <CurrencyFormat value={data.ExactSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} /> ;
            
            case "Starting Salary":
                return <CurrencyFormat value={data.MinimumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />;

            case "Maximum Salary":
                return <CurrencyFormat value={data.MaximumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />;

            case "Range":
                return (
                    <span>
                      <CurrencyFormat value={data.MinimumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} /> 
                      {' to '} 
                      <CurrencyFormat value={data.MaximumSalary} CurrencyCode={data.CurrencyCode} LocaleCode={data.LocaleCode} />
                    </span>
                  );
        }
      };

      const trimDescription = (str, length) => {
        length ??= 150;
        if (str.length <= length) {
          return str;
        }
        return str.substring(0, length) + '...';
      };
      

    return (
        <>
            <JobViewModal 
                ref={refJobViewModal}
                ApplyJob={jobApply}
            >
            </JobViewModal>
            
            {/* common carousel image for all tabs */}
            <Container
                fluid
                className="base-container"
                style={{minHeight: '800px'}}
            >
                {/* Job Header ======= START ======= */}
                <nav class="navbar navbar-expand-lg border-bottom p-1">
                    <div class="container-fluid">
                        <Link class="navbar-brand">
                            <img src="https://assets.wiseofficeday.com/wod/project_images/WiseOfficeDayLogo.png" class="img-responsive rounded mx-auto d-block" alt="logo" style={{width: "200px", height:"auto"}} />
                        </Link>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-lg-0">
                                <li class="nav-item">
                                    <Link class="nav-link disabled">Company reviews</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link disabled">Salary guide</Link>
                                </li>
                            </ul>
                            <div class="d-flex" role="search">
                                <ul class="navbar-nav me-auto mb-lg-0">
                                    <li class="nav-item border-end">
                                        <Link to={`/NonCompanyUserLogin`} class="nav-link" href="#">Sign in</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link to={`https://hrms.wiseofficeday.com`} class="nav-link" href="#">Employers/Post Job</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                {/* Job Header =======  END  ======= */}
                {(Roles.length > 0) && (
                    <Topbar bData={bData} HeadingText={isJobApplicantState ? "All Jobs" : "My Jobs"}></Topbar>
                )}

                <div className="row align-items-center m-0 p-0">
                    <div className="col-10">
                        <Form
                            // defaultValues={{ Token: token }}
                            onSubmit={onSearchSubmit}
                            // validationSchema={schema}
                        >
                            <div className="mx-2 py-2 d-flex justify-content-start text-align-center">
                                <div className="border card pt-2 pb-2 rounded m-0" style={{width: "100%"}}>
                                    <div className="row m-0 d-flex justify-content-center text-align-center">
                                        <div className="col-md-3">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <SearchIcon fontSize="small"/>
                                                <FormInputText
                                                    name="TitleOrCompanySearch"
                                                    placeholder="Job Title or Company"
                                                    type="text"
                                                    isRequired="true"
                                                    className="border-0 form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <LocationOnIcon fontSize="small"/>
                                                <FormInputText
                                                    name="CityOrStateSearch"
                                                    placeholder="City, State"
                                                    type="text"
                                                    isRequired="true"
                                                    className="border-0"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex align-items-center">
                                                <PersonIcon fontSize="small"/>
                                                <div className="w-100">
                                                    <FormAutoCompleteDropdownCard
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                width: '100%',
                                                                padding: "1px 8px",
                                                                '& fieldset': {
                                                                    border: 'none', // Remove the default border
                                                                },
                                                                '&:hover fieldset': {
                                                                    border: 'none', // Remove border on hover
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: 'none', // Remove border when focused
                                                                },
                                                            },
                                                        }}
                                                        name="CustomerId"
                                                        data={chain(customerList ?? [])}
                                                        optionText="heading"
                                                        isRequired="true"
                                                        placeholder="Company"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 d-flex justify-content-end text-align-center">
                                            <Button type="submit" style={{borderRadius: "5px", background: "#2557a7"}}>
                                                Find Jobs
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="col-2">
                        {(isJobApplicantState) && (
                            <div className="container">
                                    <InputDropdown
                                        name="filter"
                                        ddOpt={[{text: "All", value: "All"}, {text: "Applied", value: "Applied"}]}
                                        setValue={(val) => setSelectedFilter(val)}
                                        style={{height : "53px", borderRadius : "10px"}}
                                    />
                            </div>
                        )}
                    </div>
                </div>
                {(permission.ManageAdd) && (
                    <div className="container bg-primary px-4 py-1 d-flex justify-content-end">
                        <Button onClick={() => navigate("/AddEditJobPosting")}>Add Job Post</Button>
                    </div>
                )}

                <div className='m-4'>
                    <Box sx={{ flexGrow: 1 }}>
                        {filteredJobData.length > 0 ? (
                        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {filteredJobData.map((job, index) => (
                            <Grid item xs={12} sm={4} md={3} key={index}>
                                <Card
                                sx={{
                                    maxWidth: 345,
                                    height: "100%", // Make the card take up the full height
                                    display: "flex", // Flexbox for Card
                                    flexDirection: "column", // Ensure content stacks vertically
                                }}
                                >
                                <CardHeader
                                    // action={
                                    // <IconButton aria-label="settings">
                                    //     <MoreVertIcon />
                                    // </IconButton>
                                    // }
                                    title={job.Title}
                                    subheader={job.Location}
                                    className="pb-0"
                                />
                                <CardContent
                                    className="pt-2 pb-3"
                                    sx={{ flexGrow: 1 }} // This makes the content stretch and fill available space
                                >
                                    <Typography variant="body2" color="text.secondary">
                                    <b>{job?.CompanyName}</b>
                                    </Typography>
                                    {job.JobTypes?.split(",").map((jobType) => (
                                    <>
                                        <Chip
                                        label={jobType}
                                        sx={{
                                            color: "green",
                                            padding: "0px",
                                            borderRadius: "5px",
                                            fontWeight: "bold",
                                            backgroundColor: "rgba(0,125,0,0.1)",
                                            marginBottom: "8px",
                                        }}
                                        size="small"
                                        />
                                        &nbsp;
                                    </>
                                    ))}

                                    <Typography variant="body2" color="text.secondary">
                                    {renderSalary(job)}
                                    </Typography>
                                    <br />
                                    <Typography variant="body2" color="text.secondary">
                                    <div
                                        dangerouslySetInnerHTML={{
                                        __html:
                                            job.Description !== null && job.Description !== undefined
                                            ? trimDescription(job.Description)
                                            : "",
                                        }}
                                    />
                                    </Typography>

                                    {job?.Skills?.split(",").map((skill) => (
                                    <>
                                        <Chip
                                        label={skill}
                                        sx={{
                                            color: "green",
                                            padding: "0px",
                                            borderRadius: "5px",
                                            fontWeight: "bold",
                                            backgroundColor: "rgba(0,125,0,0.1)",
                                            marginBottom: "8px",
                                        }}
                                        size="small"
                                        />
                                        &nbsp;
                                    </>
                                    ))}
                                    <br />
                                    <Typography
                                    className="mt-2"
                                    variant="body2"
                                    color="text.secondary"
                                    >
                                    <div className="d-flex" style={{ maxHeight: "18px" }}>
                                        <span>Posted : </span>&nbsp;
                                        <CalculatePostedDate dateTime={job.PostedDate} />
                                    </div>
                                    </Typography>
                                </CardContent>

                                <div className="d-flex justify-content-between align-items-center p-2">
                                    <div>
                                    <ActionButton
                                        onClick={() =>
                                        fnViewModal(
                                            job.JobId,
                                            appliedJobList.some((item) => item === job.JobId)
                                        )
                                        }
                                        IconName="View"
                                        id="btnIdJobView"
                                    />

                                    {permission.ViewJobApplicantList && (
                                        <Link to={`/ApplicantsList?JobId=${job.JobId}`} target="_blank">
                                            <ActionButton
                                                IconName="View"
                                                id="btnIdViewApplicantList"
                                            />
                                        </Link>
                                    )}

                                    {permission.ManageEdit && (
                                        <ActionButton
                                        onClick={() =>
                                            navigate("/AddEditJobPosting", {
                                            state: { JobId: job.JobId },
                                            })
                                        }
                                        IconName="Edit"
                                        id="btnEditFuel"
                                        />
                                    )}

                                    {permission.ManageDelete && (
                                        <ActionButton
                                        onClick={() => onDelete(job.JobId)}
                                        IconName="Delete"
                                        id="btnDeleteFuel"
                                        />
                                    )}
                                    </div>

                                    <div>
                                    {Roles?.some(
                                        (item) => item === StandardConst.SystemRole.JobApplicant
                                    ) || Roles.length === 0 ? (
                                        appliedJobList.some((item) => item === job.JobId) ? (
                                        <Chip
                                            label="Applied"
                                            sx={{ color: "white", padding: "0px" }}
                                            color="success"
                                            size="small"
                                        />
                                        ) : (
                                        <Button size="small" onClick={() => jobApply(job.JobId)}>
                                            Apply
                                        </Button>
                                        )
                                    ) : null}
                                    </div>
                                </div>
                                </Card>
                            </Grid>
                            ))}

                            <Grid item xs={12} sm={12} md={12}>
                                {rowsPerPage > 0 && countOfTotalRecords > 0 && countOfTotalRecords > rowsPerPage && (
                                    <TablePagination
                                        component="div"
                                        count={countOfTotalRecords}
                                        page={page - 1}
                                        onPageChange={(event, newPage) => setPage(newPage + 1)}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={pageSizeOptions}
                                        onRowsPerPageChange={(event) => {
                                            setRowsPerPage(parseInt(event.target.value, 10));
                                            setPage(1);
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        ) : (
                        <NoRecordTemplate
                            headerValue={"No Jobs Posted"}
                            subHeaderValue={"There are no jobs opening"}
                            imageUrl={StandardConst.imageNoRecordsFound}
                            actionButton={
                            <>
                                {permission.ManageAdd && (
                                <Button
                                    id="NoRecordFoundAddAccessGroup"
                                    variant="outline-primary"
                                    onClick={() => navigate("/AddEditJobPosting")}
                                >
                                    Add Job Post
                                </Button>
                                )}
                            </>
                            }
                        />
                        )}
                    </Box>
                </div>
            </Container>
        </>
    );
}

// this is for fetch job posting time
export const CalculatePostedDate = ({ dateTime }) => {
    const [difference, setDifference] = useState('');
  
    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date();
        const targetDate = new Date(dateTime);
  
        const diffInMs = now - targetDate;
        const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        
        let differenceString = '';
        if (days > 0) {
          differenceString = `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
          differenceString = `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else {
          differenceString = 'Just now';
        }
  
        setDifference(differenceString);
      }, 1000);
  
      return () => clearInterval(interval);
    }, [dateTime]);
  
    return (
      <div>
        <p>{difference}</p>
      </div>
    );
}

export default JobListing;
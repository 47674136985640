import React, { useEffect, useState } from 'react'
import { Box } from "@mui/material";
import Container from "react-bootstrap/Container";
import BreadcrumbsComponent from "../../Services/BreadcrumbsComponent";
import { PageInfo } from '../PageInfo';
import AppbarComponent from '../../Services/AppbarComponent';
import Button from "react-bootstrap/Button";
import Alert from '@mui/material/Alert';
import { StandardConst } from '../../Services/StandardConst';
import { Form, FormAutoCompleteDropdownCard, FormInputDropdown, FormInputText } from '../Form';
import * as yup from "yup";
import { WebService } from '../../Services/WebService';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format } from 'date-fns';
import { WSSuccessAlert } from '../../Services/WSAlert';
import { useLocation, useNavigate } from 'react-router-dom';

const FixInterview = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [bData] = React.useState([
        {
            title: "Recruitment",
            hrefLink: "#",
        },
        {
            title: "Schedule Interview",
            hrefLink: "#",
        },
    ]);

    const MasterPageName = "Schedule Interview";
    PageInfo({ pageTitle: "Schedule Interview" });

    const [interviewData, setInterviewData] = useState({});
    const [UserOrEmployeeList, setUserOrEmployeeList] = useState([]);
    const dispatch = useDispatch();
    const companyInfo = useSelector(s => s.auth.CompanyInfo ?? {});

    const schema = yup.object().shape({

        InterviewFromTime: yup
            .mixed()
            .test('is-time-valid', 'Time is not valid', (value) => {
            return !!value; 
            })
            .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
            .required(StandardConst.ValidationMessages.RequiredFieldMsg),
        
        InterviewToTime: yup
            .mixed()
            .test('is-time-valid', 'Time is not valid', (value) => {
            return !!value; 
            })
            .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
            .required(StandardConst.ValidationMessages.RequiredFieldMsg)
            .test(
            'is-from-time-before-to-time', 
            'Interview From Time always greater than Interview To Time',
            function (value) {
                const { InterviewFromTime } = this.parent; 
                if (!InterviewFromTime || !value) {
                    return true; 
                }
                return InterviewFromTime <= value;
            }
            ),


        // InterviewFromTime: yup
        //     .mixed()
        //     .test('is-time-valid', 'Time is not valid', (value, { parent }) => {
        //         if (value) {
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     })
        //     .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
        //     .required(StandardConst.ValidationMessages.RequiredFieldMsg),
        // InterviewToTime: yup
        //     .mixed()
        //     .test('is-time-valid', 'Time is not valid', (value, { parent }) => {
        //         if (value) {
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     })
        //     .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
        //     .required(StandardConst.ValidationMessages.RequiredFieldMsg),

        Status: yup.string().required(StandardConst.ValidationMessages.RequiredFieldMsg),
        InterviewDate: yup.date().typeError(StandardConst.ValidationMessages.RequiredFieldMsg).required(StandardConst.ValidationMessages.RequiredFieldMsg),
        JobApplicantId: yup
            .array()
            .of(
                yup.object().shape({
                    heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
                })
            )
            .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
            .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
        Interviewer: yup
            .array()
            .of(
                yup.object().shape({
                    heading: yup.string().trim().required(StandardConst.ValidationMessages.RequiredFieldMsg),
                })
            )
            .typeError(StandardConst.ValidationMessages.RequiredFieldMsg)
            .min(1, StandardConst.ValidationMessages.RequiredFieldMsg),
    })
        .required();

    const fetchAllEssentialInformation = async () => {
        var [employeeList, UserList] = await Promise.all([
            await WebService({
                endPoint: `Visitor/fetchAllEmployees`,
                dispatch,
            }),
            await WebService({
                endPoint: `CommonUtility/fetchAll/users?CompanyId=${companyInfo.CompanyId || 0}`,
                dispatch,
            })
        ])
        
        const data = {
            Employees: employeeList,
            Users: UserList,
        }

        const emplist = data.Employees?.filter((r) => r.FullName !== null)?.map((res) => {
            return {
                ...res,
                EmployeeId: res.EmployeeId,
                heading: res.FullName,
                description: res.Designation,
                avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        const userslist = data.Users?.filter((r) => r.FullName !== null)?.map((res) => {
            return {
                ...res,
                UserId: res.UserId,
                heading: res.FullName,
                description: res.Designation,
                avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
            }
        });
        const combinedDetails = [...emplist, ...userslist];
        setUserOrEmployeeList(combinedDetails);
    }

    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const fetchInterviewDetails = async (InterviewId) => {
        await WebService({
            endPoint: `Recruitment/fetchInterviewDetailsByInterviewId?InterviewId=${InterviewId}`,
            method: 'GET',
            dispatch
        }).then(res => {
            if(res.length > 0){
                handleJobApplicantsAndCustomerUser(res[0].JobId);
                setInterviewData(res[0]);
                setIsDataLoaded(true);
            }
        });
    }
    const [jobApplicantsData, setJobApplicantsData] = useState([]);

    const SelectedJobId = state?.JobId ?? null;
    const SelectedJobApplicantId = state?.JobApplicantId ?? null;

    const selectJobApplicantFun = async () => {
        await handleJobApplicantsAndCustomerUser(SelectedJobId);
        setIsDataLoaded(true);
    }

    useEffect(async () => {
        if(state?.InterviewId){
            fetchInterviewDetails(state?.InterviewId);
        }else if(SelectedJobId !== null){
            selectJobApplicantFun();
        }else{
            setIsDataLoaded(true);
        }
    }, [state]);

    useEffect(() => {
        Promise.all([
            fetchAllEssentialInformation(),
            fetchAllJobs(),
            fetchAllStaticInterviewType()
        ])
    }, []);

    const [staticInterviewTypeData, setStaticInterviewTypeData] = useState([]);

    const fetchAllStaticInterviewType = async() => {
        await WebService({
            endPoint: `CommonUtility/staticinterviewtypes`,
            dispatch,
        }).then(res => res.length > 0 ? setStaticInterviewTypeData(res.map(item => ({text: item.InterviewType, value: item.StaticInterviewTypeId}))) : null);

        // const list = data?.map((r) => {
        //     return { text: r.InterviewType, value: r.StaticInterviewTypeId}
        // });
        // setStaticInterviewTypeData(list);
    }
    const [JobList, setJobList] = useState([]);

    const fetchAllJobs = async() => {
        const data = await WebService({
            // endPoint: `CommonUtility/Edit/jobs?CompanyId=${companyInfo.CompanyId || 0}`,
            endPoint: `CommonUtility/fetchRecordsByStatusList/jobs?CompanyId=${companyInfo.CompanyId || 0}&StatusId=${StandardConst.Status.Open}&StatusId=${StandardConst.Status.Reopened}`,
            dispatch,
        });
        const list = data?.map((r) => {
            return { text: r.Title, value: r.JobId}
        });
        setJobList(list);
    }


    const handleJobApplicantsAndCustomerUser = async (JobId) => {
        await WebService({
            endPoint: `Recruitment/fetchJobApplicantsForJob?JobId=${JobId}`,
            method: 'GET',
            dispatch
        }).then((response) => {
            setJobApplicantsData(response.map(item => ({
                JobApplicantId: item.JobApplicantId,
                heading: item.ApplicantName,
                description: 'Job Applicant',
                avatar: `${StandardConst.apiBaseUrl}/uploads/${item.ProfileImage}`
            })))
            if(SelectedJobApplicantId !== null){
                setInterviewData({JobApplicantId : response?.filter(item => item.JobApplicantId === SelectedJobApplicantId)});
            }
        }
    );

        const CustomerId = await WebService({
            endPoint: `CommonUtility/Edit/jobs?JobId=${JobId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? res[0].CustomerId ?? null : null);

        if(CustomerId !== null){
            await WebService({
                endPoint: `User/OfCustomer/Fetch?CustomerId=${CustomerId}`,
                dispatch,
            }).then(res => {
                const updatedData = [...UserOrEmployeeList, ...res?.filter((r) => r.FullName !== null)?.map((res) => {
                    return {
                        ...res,
                        UserId: res.UserId,
                        heading: res.FullName,
                        description: '',
                        avatar: `${StandardConst.apiBaseUrl}/uploads/${res.ProfileImage}`
                    }
                })]
                setUserOrEmployeeList(updatedData);
            });
        }
    };

    const setNotificationAndMailToInterviewer = async (data) => {
        const fetchJobData = await WebService({
            endPoint: `CommonUtility/Edit/jobs?jobId=${data.JobId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? res[0] : null);

        const JobApplicantData = await WebService({
            endPoint: `Recruitment/fetchJobApplicantDetails?JobApplicantId=${data.JobApplicantId}`,
            method: 'GET',
            dispatch
        }).then(res => res.length > 0 ? res[0] : null);

        // Here we send notification
        if(fetchJobData !== null && JobApplicantData !== null) {
            const companyName = await WebService({
                endPoint: `CommonUtility/Edit/companyinformation?CompanyId=${fetchJobData.CompanyId}`,
                method: 'GET',
            }).then(res => res.length > 0 ? res[0].CompanyName : null);

            // await WebService({
            //     dispatch,
            //     endPoint: "Notification/sendSystemNotification",
            //     body: {
            //         Message: `Interview of ${JobApplicantData.FullName} scheduled on ${format(new Date(data.InterviewDate), 'dd-MM-yyyy')} ${data.InterviewFromTime} for ${fetchJobData.Title}`,
            //         SendTo: [data.Interviewer[0].EmployeeId],
            //         Expire: addDays(new Date(), 30),
            //     },
            // });
            // if(companyName !== null){
            //     await WebService({
            //         dispatch,
            //         endPoint: "Notification/sendSystemNotificationForUser",
            //         body: {
            //             Message: `Interview scheduled for ${fetchJobData.Title} - ${companyName} - ${format(new Date(data.InterviewDate), 'dd-MM-yyyy')} ${data.InterviewFromTime}`,
            //             SendTo: [JobApplicantData.UserId],
            //             Expire: addDays(new Date(), 30),
            //         },
            //     });
            // }

            // Here we send email
            await WebService({
                dispatch,
                endPoint: `Recruitment/SendMailToInterviewerForScheduleInterview?InterviewerEmail=${data.Interviewer[0].Email}`,
                method: "POST",
                body: {
                    Interviewer : data.Interviewer[0].FullName,
                    JobTitle : fetchJobData.Title,
                    ApplicantName : JobApplicantData.FullName,
                    DateTime: `${format(new Date(data.InterviewDate), 'dd-MM-yyyy')} ${data.InterviewFromTime}`,
                    Date : format(new Date(data.InterviewDate), 'dd-MM-yyyy'),
                    Time : `${data.InterviewFromTime} - ${data.InterviewToTime}`
                }
            });

            await WebService({
                dispatch,
                endPoint: `Recruitment/SendMailToApplicantForScheduleInterview?ApplicantEmail=${JobApplicantData.Email}`,
                method: "POST",
                body: {
                    Interviewer : data.Interviewer[0].FullName,
                    JobTitle : fetchJobData.Title,
                    ApplicantName : JobApplicantData.FullName,
                    DateTime: `${format(new Date(data.InterviewDate), 'dd-MM-yyyy')} ${data.InterviewFromTime}`,
                    Date : format(new Date(data.InterviewDate), 'dd-MM-yyyy'),
                    Time : `${data.InterviewFromTime} - ${data.InterviewToTime}`,
                    EmployeeId : fetchJobData.EmployeeId,
                    UserId : fetchJobData.UserId,
                    CompanyName : companyName
                }
            });
        }
    }

    const updateApplicationStatus = async(StatusId, JobApplicantId, JobId) => {
        let data = {};
        if(StatusId === StandardConst.Status.Scheduled){
            data = { StatusId: StandardConst.Status.Scheduled };
        }else if(StatusId === StandardConst.Status.Completed || StatusId === StandardConst.Status.Selected || StatusId === StandardConst.Status.SelectedForNextRound || StatusId === StandardConst.Status.NotFitForPosition || StatusId === StandardConst.Status.OnHold || StatusId === StandardConst.Status.MakeAnOffer){
            data = { StatusId: StandardConst.Status.Interviewed };
        }

        if(Object.keys(data).length > 0){
            await WebService({
                endPoint: `CommonUtility/jobapplications?JobApplicantId=${JobApplicantId}&JobId=${JobId}`,
                body: data,
                method: 'PUT',
                // dispatch,
            });
        }
    };

    const onSubmit = async(data) => {
        data.JobApplicantId = data.JobApplicantId[0].JobApplicantId;
        const InterviewerKeys = Object.keys(data?.Interviewer[0]);
        const isEmployeeId = InterviewerKeys?.some(key => key === "EmployeeId");
        const isUserId = InterviewerKeys?.some(key => key === "UserId");
        data.InterviewDate = format(new Date(data.InterviewDate), "yyyy-MM-dd");
        if (isEmployeeId) {
            data.InterviewerEmployeeId = data?.Interviewer[0]?.EmployeeId
        }
        if (isUserId) {
            data.InterviewerUserId = data?.Interviewer[0]?.UserId
        }

        await WebService({
            endPoint: `Interviews/interviewSchedule`,
            body: data,
            method: "POST",
            dispatch,
        }).then(res => {
            updateApplicationStatus(parseInt(data.Status), data.JobApplicantId, data.JobId);
            WSSuccessAlert("Success", res);
            setNotificationAndMailToInterviewer(data);
        });
        navigate('/ScheduledInterview');
    }

    if (!isDataLoaded) {
        return <></>;
    }

    return (
        <>
            <Container fluid className="base-container">
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <h5 className="ms-4 mt-2">{MasterPageName}</h5>
                    <div className="ms-4">
                        <BreadcrumbsComponent bData={bData}></BreadcrumbsComponent>
                    </div>
                </Box>
                <AppbarComponent
                    title={MasterPageName}
                    isSearchRequired={false}
                    isAddButtonRequired={true}
                    Exception={true}
                />
                <div className="row p-0 mb-2 d-flex flex-row">
                    <div className="col-md-8">
                        <div>
                            <Form
                                defaultValues={interviewData}
                                onSubmit={onSubmit}
                                validationSchema={schema}

                            >
                                <div className="card shadow-none border-0  border-start footer-widget mt-2 ms-2">
                                    <Alert severity="info" sx={{ width: "95%" }}>Interview Information</Alert>
                                    <div className="card-body">
                                        <div className="row mt-1">
                                            <div className="row">
                                                <div className="col-md-12 pb-2">
                                                    <FormInputDropdown
                                                        name="JobId"
                                                        ddOpt={JobList}
                                                        label="Job"
                                                        setValue={(JobId) => handleJobApplicantsAndCustomerUser(JobId)}
                                                        value={SelectedJobId}
                                                    />
                                                </div>

                                                <div className="col-md-12 pb-2">
                                                    <FormInputDropdown
                                                        name="StaticInterviewTypeId"
                                                        ddOpt={staticInterviewTypeData}
                                                        label="Interview Type"
                                                    />
                                                </div>
                                                <div className="col-md-12 pb-2">
                                                    <FormAutoCompleteDropdownCard
                                                        name="JobApplicantId"
                                                        data={jobApplicantsData ?? []}
                                                        label="Job Applicant"
                                                        optionText="heading"
                                                        isRequired="true"
                                                        // placeholder="Choose a Applicant"
                                                        value={SelectedJobApplicantId !== null ? jobApplicantsData?.find(item => item.JobApplicantId === SelectedJobApplicantId) : interviewData?.JobApplicantId !== undefined ? interviewData?.JobApplicantId[0] : ""}
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-2">
                                                    <FormAutoCompleteDropdownCard
                                                        name="Interviewer"
                                                        data={UserOrEmployeeList ?? []}
                                                        label="Interviewer"
                                                        optionText="heading"
                                                        isRequired="true"
                                                        // placeholder="Choose a Person"
                                                        value={interviewData?.Interviewer !== undefined ? interviewData?.Interviewer[0] :""}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Interview Date"
                                                        name="InterviewDate"
                                                        type="date"
                                                        isRequired="true"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Interview From Time"
                                                        name="InterviewFromTime"
                                                        type="time"
                                                        isRequired="true"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <FormInputText
                                                        label="Interview To Time"
                                                        name="InterviewToTime"
                                                        type="time"
                                                        isRequired="true"
                                                    />
                                                </div>
                                                <div className="col-md-12 pb-2">
                                                    <FormInputDropdown
                                                        name="Status"
                                                        ddOpt={StandardConst.InterviewStatusDropdown}
                                                        label="Status"
                                                        isRequired="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Button
                                                variant="outline-primary"
                                                type="submit"
                                                id="btnSubmit"
                                                className="px-4 float-end mt-4 mb-1"
                                                style={{ marginRight: "27px" }}
                                            >
                                                Schedule Interview
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </Container>
        </>
    )
}

export default FixInterview
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { WebService } from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { ActionPermission, PageInfo } from "../PageInfo";
import SnackbarComponent from "../../Services/SnackbarComponent";
import DeleteConfirmAlert from "../../Services/AlertComponent";
import ActionButton from "../../Services/ActionButton";
import StaticListComponent from "../../Services/StaticListComponent";
import { NoRecordTemplate } from "../../Services/TableComponent";
import Button from "react-bootstrap/esm/Button";
import { StandardConst } from "../../Services/StandardConst";
import { statusUpdate as ConfigurationflowStatusUpdate } from "../../Services/ConfigurationFlow";
// import AddEditVisitorType from "./AddEditVisitorType";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";
import { WSErrorAlert, WSSuccessAlert } from "../../Services/WSAlert";
import BootstrapModal from "react-bootstrap/Modal";
import { Form, FormCheckRadioInput, FormInputDropdown, FormInputFile, FormInputText } from '../Form';

const apiUrl = "CommonUtility/static_visitor_type";
const SendMail = () => {
  const ref = useRef();
  const refSnackbar = useRef();
  PageInfo({ pageTitle: "Manage Visitor Type" });
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const Roles = useSelector((s) => s.auth.AssignRole ?? {});
  const navigate = useNavigate();
  const [permission, SetPermission] = useState({});
  const permissionList = useSelector((s) => s.auth.PermissionList??[]);
  useEffect(() => {
    SetPermission({
      ManageAdd: ActionPermission("Visitor Type - Add"),
      ManageEdit: ActionPermission("Visitor Type - Edit"),
      ManageDelete: ActionPermission("Visitor Type - Delete"),
    });
  }, [permissionList]);

  const companyInfo = useSelector((s) => s.auth.CompanyInfo ?? {});

  const fetchVisitorType = async () => {
    const data = await WebService({
      endPoint: `CommonUtility/sendmailtousers`,
      dispatch,
    });
    setRecords(data);
    dispatch(ConfigurationflowStatusUpdate());
  };

  const onDelete = async (VisitorTypeId) => {
    await WebService({
      endPoint: `${apiUrl}?VisitorTypeId=${VisitorTypeId}`,
      method: "DELETE",
      dispatch,
    });
    refSnackbar.current.setOpenSnackBar();
    await fetchVisitorType();
  };
  const renderAfterCalled = useRef(false);
  const addEditModalRef = useRef();
  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchVisitorType();
    }
    renderAfterCalled.current = true;
  }, []);

  const SendMailToUser = async (selectedObj) => {
    if(companyInfo?.CompanyId){
        // selectedObj.CompanyId = companyInfo.CompanyId;
        const mailSendMsg = await WebService({
            endPoint: `CommonUtility/SendMailToThisUser/SendMail`,
            body: selectedObj,
            method: "POST",
            dispatch
        })
        if(mailSendMsg === "Success"){
            fetchVisitorType();
            WSSuccessAlert("Success", `Mail sent successfully To ${selectedObj.UserName} user to this Email ${selectedObj.Email}`);
        }else{
            WSErrorAlert("Error", `Mail not sent`);
        }
    }else{
        WSErrorAlert("Error", "Logged In Company Not Found Please Login Again.");
    }
  }
  const columns = [
    {
        Text: "UserName",
        Value: "UserName",
    },
    {
        Text: "Email",
        Value: "Email",
    },
    {
      Text: "Action",
      key: "VisitorTypeId",
      cssClass: "text-end",
      isVisiable: permission.ManageEdit || permission.ManageDelete,
      render: (dr) => (
        <>
          {/* {(dr.IsReserved === 0 || (dr.IsReserved === 1 && Roles[0] === StandardConst.SystemRole.SuperAdmin)) && (
          <>
            <ActionButton
              onClick={() => fnEdit(dr.VisitorTypeId)}
              disabled={!permission.ManageEdit}
              IconName="Edit"
              id="btnVisitorTypeEdit"
            />

            <ActionButton
              onClick={(e) =>
                ref.current.confirmAlert(
                  "Delete", //Confirm button text
                  "Are You Sure", // Text if Alert
                  "Do you want to delete " + MasterPageName, // Message of Alert
                  e.currentTarget.parentElement.parentElement.getAttribute("data-key") // Endpoint to hit for delete
                )
              }
              disabled={!permission.ManageDelete}
              IconName="Delete"
              id="btnVisitorTypeDelete"
            />
          </>
          )} */}
          {/* <ActionButton
            IconTooltip={"Manage Badge Templates"}
            onClick={() => navigate("/ManageBadgeTemplates")}
            IconName="Badge"
            id="btnBadge"
          /> */}
          {(dr.StatusId === 1) ? (
              <Button onClick={() => SendMailToUser(dr)}>
                Send mail
              </Button>
          ) : (
            <span className="text-success">Mail Sent</span>
          )}
        </>
      ),
    },
  ];

  const [bData, setBData] = React.useState([
    {
      title: "Manage Users To Mail",
      hrefLink: "#",
    },
    {
      title: "Send Mail",
      hrefLink: "#",
    },
  ]);
  const MasterPageName = "Manage Users To Mail";
  const confirmMessage = MasterPageName + " Deleted successfully";
  const AddButtonText = "User To Mail";

  const fnEdit = async (id) => await addEditModalRef.current.openModal(id || 0);
  return (
    <>
      <SnackbarComponent ref={refSnackbar} confirmMessage={confirmMessage} />
      <DeleteConfirmAlert ref={ref} confirmEvent={(v) => onDelete(v)} />
      <StaticListComponent
        columns={columns}
        records={records}
        bData={bData}
        MasterPageName={MasterPageName}
        AddButtonText={AddButtonText}
        noRecordCss="p-0"
        noRecordFound={
          <NoRecordTemplate
            headerValue={"No mail data "}
            imageUrl={StandardConst.imageNoRecordsFound}
          />
        }
        onAddEvent={() => fnEdit()}
        IsAddButtonVisible={permission?.ManageAdd}
        isSearchRequired={true}
        allowSerialNo={true}
      ></StaticListComponent>
      <ModalAddNewUserToSendMail
        callBackEvent={() => fetchVisitorType()}
        ref={addEditModalRef}
      ></ModalAddNewUserToSendMail>
    </>
  );
};


const ModalAddNewUserToSendMail = forwardRef(({callBackEvent}, ref) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [documentDetailData, setVehicleCertificationData] = useState({});
    const [selectedDocumentFiles, setSelectedDocumentFiles] = useState([]);
    const [dbDocumentsFiles, setDbDocumentsFiles] = useState([]);
    const [vehicleCertificationDropdown, setVehicleCertificationDropdown] = useState([]);
    


    useImperativeHandle(ref, () => ({
        openModal: async (id) => {
            if(id > 0){
                // await WebService({
                //     endPoint: `CommonUtility/Edit/vehiclecomplianceandcertifications?VehicleComplianceAndCertificationId=${VehicleComplianceAndCertificationId}`,
                //     dispatch,
                // }).then((res) => {
                //     if(res.length > 0){
                //         setVehicleCertificationData(res[0]);
                //     }
                // })
            }

            setShow(true);
        },
    }));

    const handleClose = () => {
        setShow(false);
        setSelectedDocumentFiles([]);
        setVehicleCertificationData({});
        setDbDocumentsFiles([]);
    };

    // const onSubmitVehicleVehicleCertification = async (data) => {
    //     handleClose();

    //     let VehicleComplianceAndCertificationId = data.VehicleComplianceAndCertificationId;

    //     if(data.VehicleComplianceAndCertificationId !== undefined){
    //         await WebService({
    //             endPoint: `CommonUtility/vehiclecomplianceandcertifications?VehicleComplianceAndCertificationId=${data.VehicleComplianceAndCertificationId}`,
    //             body: data,
    //             method: 'PUT',
    //             dispatch,
    //         }).then((res) => {
    //             WSSuccessAlert("Success", "Vehicle certification detail successfully updated.");
    //         })
    //     }else{
    //         data.VehicleId = VehicleId;
    //         await WebService({
    //             endPoint: `CommonUtility/vehiclecomplianceandcertifications`,
    //             body: data,
    //             method: 'POST',
    //             dispatch,
    //         }).then((result) => {
    //             if(result > 0){
    //                 VehicleComplianceAndCertificationId = result;
    //                 WSSuccessAlert("Success", "Vehicle certification detail successfully saved.");
    //             }
    //         })
    //     }
    //     if(VehicleComplianceAndCertificationId > 0 && selectedDocumentFiles.length > 0){
    //         selectedDocumentFiles?.map(async (file) => {
    //             const documentFileName = await WebService({
    //                 endPoint: "upload/File?destination=VehicleCertificationDocuments",
    //                 dispatch,
    //                 body: file,
    //                 isFile: true,
    //             }).then((res) => res.filename);


    //             await WebService({
    //                 endPoint: "CommonUtility/vehiclecertificationfiles",
    //                 body: {
    //                         VehicleComplianceAndCertificationId : VehicleComplianceAndCertificationId,
    //                         FilePath : documentFileName
    //                     },
    //                 dispatch,
    //             });
    //         });
    //     }

    //     callBackEvent();
    // };
    
    const onSubmit = async (data) => {
        data.StatusId = 1;
        await WebService({
            endPoint: "CommonUtility/sendmailtousers",
            body: data,
            method: "POST",
            dispatch
        }).then(res => {
            WSSuccessAlert("success", "User successfully add for send mail.");
            handleClose();
            callBackEvent();
        });
    };
    
    return (
        <>
            <BootstrapModal show={show} onHide={handleClose}>
                <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{(Object.keys(documentDetailData).length > 0) ? "Edit Mail" : "Add Mail"}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Form 
                        defaultValues={documentDetailData}
                        onSubmit={onSubmit}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <FormInputText
                                    label="User Name"
                                    name="UserName"
                                    className="form-control"
                                />
                            </div>

                            <div className="col-md-12">
                                <FormInputText
                                    label="Email"
                                    name="Email"
                                    className="form-control"
                                />
                            </div>

                            <div className='col-md-12'>
                                <Button
                                    variant="outline-primary"
                                    type="submit"
                                    id="btnSubmit"
                                    className="px-4 float-end mt-4 mb-1"
                                    style={{ marginRight: "27px" }}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    );
});
export default SendMail;
